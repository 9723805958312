import { useSelector } from "react-redux";
import Interests from "../../components/Profile/Interests";
import Statistics from "../../components/Profile/Statistics";
import UserProfile from "../../components/Profile/UserProfile";
import { useEffect, useState } from "react";
import Loader from "../../components/Loader/Loader";

const Profile = () => {
  const [loading, setLoading] = useState(true);
  const profileinfo = useSelector((data) => data?.Profile_data?.ProfileData);
  const ConfigData = useSelector((state) => state?.config_data?.configData);
  const baseUrls = ConfigData?.base_urls;

  useEffect(() => {
    const isObjectEmpty = profileinfo && Object.keys(profileinfo)?.length == 0;
    if (isObjectEmpty) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [profileinfo]);

//  console.log("profileinfo",profileinfo) 

  return (<>
    {
      loading ? <Loader />
        :
        <div className="container py-2 py-md-4">
          <div className="pt-md-4">
            <UserProfile val={profileinfo} baseUrls={baseUrls} viewType={'user'}/>
          </div>

          <div className="py-5">
            <Interests interest={profileinfo?.interest} />
          </div>
          {/* <div className="pt-5">
            <Statistics val={profileinfo} />
          </div> */}
        </div >
    }
  </>)
};
export default Profile;