import { Link } from "react-router-dom"

const Sitemap = () => {
    return (
        <>
            <div className="">
                <div className="d-flex justify-content-center my-4">
                    <div className="sitemap-head">
                        <p className="landing-head-title">
                            Site Map</p>
                    </div>
                </div>

                <div className="d-flex flex-column flex-sm-row align-items-xl-start gap-4 justify-content-center mx-4 my-5">
                    <div>
                        <div className="sitemap-bottom">
                            <p className="landing-bottom-title">Home Page</p>
                        </div>

                        <ul className="sitemap-ul">
                            {/* <li className="sitemap-li">
                                <a href="#"></a>
                            </li> */}
                        </ul>
                    </div>
                    
                    <div className="d-flex flex-column flex-xl-row gap-4">
                    <div>
                        <div className="sitemap-bottom">
                            <p className="landing-bottom-title">Events</p>
                        </div>

                        <ul className="sitemap-ul">
                            <Link to="/events/all" className="a-li">
                                <li className="sitemap-li1">
                                    Discover
                                </li>
                            </Link>
                            <Link to="/CreateEvent/create" className="a-li">
                                <li className="sitemap-li1">
                                    Create
                                </li>
                            </Link>
                            <Link to="/myevents/all" className="a-li">
                                <li className="sitemap-li1">
                                    Post
                                </li>
                            </Link>
                            <Link to="/managerequest" className="a-li">
                                <li className="sitemap-li1">
                                    Manage
                                </li>
                            </Link>
                        </ul>
                    </div>

                    <div>
                        <div className="sitemap-bottom">
                            <p className="landing-bottom-title">Company</p>
                        </div>

                        <ul className="sitemap-ul">
                            <Link to={`/${"about_us"}`} className="a-li">
                                <li className="sitemap-li2">
                                    About us
                                </li>
                            </Link>
                            <Link to={`/${"careers"}`} className="a-li">
                                <li className="sitemap-li2">
                                    Carrer
                                </li>
                            </Link>
                            <Link to={`/${"terms_&_conditions"}`} className="a-li">
                                <li className="sitemap-li2">
                                    T & C
                                </li>
                            </Link>
                            <Link to={`/${"privacy_policy"}`} className="a-li">
                                <li className="sitemap-li2">
                                    Privacy Policy
                                </li>
                            </Link>
                        </ul>
                    </div>
                    </div>


                    <div className="d-flex flex-column flex-xl-row gap-4">
                    <div>
                        <div className="sitemap-bottom">
                            <p className="landing-bottom-title">SponsorRingUs</p>
                        </div>

                        <ul className="sitemap-ul">
                            <Link to="/howitworks" className="a-li">
                                <li className="sitemap-li3">
                                    How it Works
                                </li>
                            </Link>
                            <Link to="#" className="a-li">
                                <li className="sitemap-li3">
                                    Sponsor
                                </li>
                            </Link>
                            <Link to="#" className="a-li">
                                <li className="sitemap-li3">
                                    Host
                                </li>
                            </Link>
                            <Link to="#" className="a-li">
                                <li className="sitemap-li3">
                                    Champ
                                </li>
                            </Link>
                        </ul>
                    </div>

                    <div>
                        <div className="sitemap-bottom">
                            <p className="landing-bottom-title">Help/Support</p>
                        </div>

                        <ul className="sitemap-ul">
                            <Link to={`/${"faq"}`} className="a-li">
                                <li className="sitemap-li4">
                                    FAQ
                                </li>
                            </Link>
                            <Link to={`/${"contact_us"}`} className="a-li">
                                <li className="sitemap-li4">
                                    Contact Us
                                </li>
                            </Link>
                        </ul>
                    </div>
                    </div>

                    <div className="d-flex flex-column flex-xl-row gap-4">
                    <div>
                        <div className="sitemap-bottom">
                            <p className="landing-bottom-title">News Corner</p>
                        </div>

                        <ul className="sitemap-ul">
                            <Link to="/testimonial" className="a-li">
                                <li className="sitemap-li5">
                                    Testimonials
                                </li>
                            </Link>
                            <Link to="#" className="a-li">
                                <li className="sitemap-li5">
                                    Our Sponsors
                                </li>
                            </Link>
                            <Link to="#" className="a-li">
                                <li className="sitemap-li5">
                                    Social Feeds
                                </li>
                            </Link>
                            <Link to={`/${"our_blog"}`} className="a-li">
                                <li className="sitemap-li5">
                                    Our Blogs
                                </li>
                            </Link>
                            <Link to={`/${"newsroom"}`} className="a-li">
                                <li className="sitemap-li5">
                                    News room
                                </li>
                            </Link>
                        </ul>
                    </div>
                    <div>
                        <div className="sitemap-bottom">
                            <p className="landing-bottom-title">Sign in / Up</p>
                        </div>

                        {/* <ul className="sitemap-ul">
                            <li className="sitemap-li">
                                <a href="#"></a>
                            </li>
                        </ul> */}
                    </div>
                </div>
                </div>


            </div >
        </>
    )
}
export default Sitemap