import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { MdFilterListAlt } from "react-icons/md";
import { useState } from 'react';

const TestimonialRating=(props)=>{
    const {setOpenFilterBar,
           openFilterBar,
           setStarRating,
           setLateOlde,
           setTabValue,
           setTestimonialNameDrop,
           testimonialNameDrop,
           tabValue,
           setRatingDrop,
           ratingDrop,
           starRating
    } = props;
    const [expandAllBtn,setExpandAllBtn]=useState(true);
    
    const ClearAll=()=>{
      setStarRating('');
      setLateOlde('');
      setTabValue('all');
    };

    const handleExpandAll = () => {
        setTestimonialNameDrop(true)
        setRatingDrop(true)
        setExpandAllBtn(true)
      }
    
      const handleCollapseAll=()=>{
        setTestimonialNameDrop(false)
        setRatingDrop(false)
        setExpandAllBtn(false)
      }

    return(<>
              <div className="position-relative col-8 col-md-4 col-xl-3 mt-0">
                    <div className="d-flex gap-2">
                    <button className="side-filterbar-button d-block d-md-none" onClick={() => setOpenFilterBar(!openFilterBar)}>Filter <MdFilterListAlt/></button>
                    </div>
                    <div className={`testimonial-event-filter-sidebar ${openFilterBar ? ' open' : "d-none"}`}>
                        <div className="filter-sidebar">
                            <aside className="d-lg-block">
                                <div>
                                    <div className="filter-content-div d-flex justify-content-between align-items-center">
                                        <p className="filter-heading-text mb-0">Filters</p>
                                        <div className="d-flex gap-2">
                                        {expandAllBtn ?
                                         <button className="clear-filter-button" onClick={handleCollapseAll}>Collapse All</button>
                                          :
                                         <button className="clear-filter-button" onClick={handleExpandAll}>Expand All</button>
                                        }
                                        <button className="clear-filter-button" onClick={ClearAll}>Clear All</button>
                                      </div>
                                    </div>
                                    <hr className="m-0" />
                                </div>

                                <div className="card-group-item mt-3">
                                    <button className="filter-btn" onClick={() => setTestimonialNameDrop(!testimonialNameDrop)}>
                                        <div className="d-flex justify-content-between">
                                            <div className='d-flex align-items-center gap-1'>
                                               {testimonialNameDrop ? <IoIosArrowUp size={18} color="#888"/> : <IoIosArrowDown size={18} color="#888"/>}
                                               <p className={testimonialNameDrop ? "active-filter-heading" : "filter-heading mb-0"}>Type</p>
                                            </div>
                                         <div>
                                            <button type="button" className="filter-clear-btn" onClick={(e)=>{e.stopPropagation();setTabValue("all");}}>Clear</button>
                                          </div>
                                        </div>
                                    </button>

                                    {testimonialNameDrop && (
                                     <div className="filter-content">
                                      <div className="card-body bg-white">
                                         {['all', 'sponsor', 'host']?.map((val, idx) => {
                                           //'champ'
                                          return (
                                          <div key={idx}>
                                             <label className="form-check">
                                               <input
                                                 className="form-check-input"
                                                 type="radio"
                                                 name="typeFilter"
                                                 checked={tabValue == val}
                                                 value={val}
                                                 onClick={() => setTabValue(val)}
                                               />
                                              <span className="form-check-label text-capitalize">
                                                {val}
                                              </span>
                                             </label>
                                          </div>
                                         );
                                         })}
                                      </div>
                                     </div>
                                    )}
                                    <hr className="m-0" />
                                </div>

                                <div className="card-group-item mt-3">
                                    <button className="filter-btn" onClick={() => setRatingDrop(!ratingDrop)}>
                                        <div className="d-flex justify-content-between">
                                         <div className='d-flex align-items-center gap-1'>
                                           {ratingDrop ? <IoIosArrowUp size={18} color="#888"/> : <IoIosArrowDown size={18} color="#888"/>}
                                            <p className={ratingDrop ? "active-filter-heading" : "filter-heading mb-0"}>Rating</p>
                                         </div>
                                         <div>
                                           <button type="button" className="filter-clear-btn" onClick={(e)=>{e.stopPropagation();setStarRating("");}}>Clear</button>
                                         </div>
                                        </div>
                                    </button>
                                    {ratingDrop && (
                                        <div className="filter-content">
                                         <div className="card-body bg-white">
                                         {[1, 2, 3, 4, 5]?.map((val, idx) => {
                                            return (
                                            <div key={idx}>
                                             <label className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name="ratingFilter"
                                                checked={starRating == val}
                                                value={val}
                                                onClick={() => setStarRating(val)}
                                               />
                                                <span className="form-check-label text-capitalize">
                                                 {val} star
                                                </span>
                                             </label>
                                            </div>
                                            );
                                          })}
                                         </div>
                                        </div>
                                    )}
                                    <hr className="m-0" />
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
        </>)
};

export default TestimonialRating;