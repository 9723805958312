import { useEffect, useState, useRef, useMemo } from "react";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";

const MembershipOrderListCard = ({ val, currency,currencyIcn}) => {

  const [columnDefs, setColumnDefs] = useState([]);
  const gridRef = useRef();


  const handlesetValue = () => {
    setColumnDefs([
      {
        headerName: "Transaction Id",
        field: "transaction_id",
        cellRenderer: ({ value, data }) => {
          return <p className="fw-bold"> {value ? value : "-"}</p>;
        },
      },
      {
        headerName: "Plan Name",
        field: "",
        cellRenderer: ({ value, data }) => {
          return <p className="fw-bold"> {value ? value : "-"}</p>;
        },
      },
      {
        headerName: "Amount",
        field: "membership_amount",
        cellRenderer: ({ value, data }) => {
          return (
            <p className="order-list-text">
              {currency?.selectedCurrencyPrice
                ? `${(
                    data?.membership_amount * currency?.selectedCurrencyPrice
                  ).toFixed(2)} ${
                    currency?.currencySymbol
                      ? currency?.currencySymbol
                      : currencyIcn
                  }`
                : `${currencyIcn} ${data?.membership_amount} `}
            </p>
          );
        },
      },
      {
        headerName: "Payable Amount",
        field: "payable_amount",
        cellRenderer: ({ value, data }) => {
          return (
            <p className="order-list-text">
              {currency?.selectedCurrencyPrice
                ? `${(
                    data?.payable_amount * currency?.selectedCurrencyPrice
                  ).toFixed(2)} ${
                    currency?.currencySymbol
                      ? currency?.currencySymbol
                      : currencyIcn
                  }`
                : `${currencyIcn} ${data?.payable_amount}`}
            </p>
          );
        },
      },
      {
        headerName: "Status",
        field: "payable_amount",
        cellRenderer: ({ value, data }) => {
          return (
            <p
              className={
                val?.payment_status == "success"
                  ? "success-status-text text-capitalize p-0"
                  : val?.payment_status == "failed"
                  ? "failed-status-text text-capitalize p-0"
                  : "pending-status-text text-capitalize p-0"
              }
            >
              {data?.payment_status ? data?.payment_status : "-"}
            </p>
          );
        },
      },
      {
        headerName: "Validity",
        field: "",
        cellRenderer: ({ value, data }) => {
          return <p>-</p>;
        },
      },
      {
        headerName: "Date",
        field: "created_at",
        cellRenderer: ({ value, data }) => {
          return (
            <p className="order-list-text">
              {moment(val?.created_at).format("ll hh:mm A")}
            </p>
          );
        },
      },
      {
        headerName: "Expiry Date",
        field: "",
        cellRenderer: ({ value, data }) => {
          return <p className="order-list-text">-</p>;
        },
      },
    ]);
  };

  useEffect(() => {
    handlesetValue();
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      filter: "agTextColumnFilter",
      floatingFilter: true,
    };
  }, []);

  return (
    <>
      <div className="pt-2 pt-md-3 position-relative">
        {/* <button className="export-to-excel-btn" onClick={onBtExport}>
          Export to Excel
        </button> */}
        <div className="ag-theme-alpine" style={{ height: 600, width: "100%" }}>
          <AgGridReact
            ref={gridRef}
            // onGridReady={onGridReady}
            columnDefs={columnDefs}
            rowData={val}
            defaultColDef={defaultColDef}
            rowSelection="multiple"
          ></AgGridReact>
        </div>
      </div>

      {/* <div className=" order-list-div">
        <div className="row d-flex align-items-center">
          <div className="col-md-2">
            <p className="order-list-text fw-bold">
              {val?.transaction_id ? val?.transaction_id : "-"}
            </p>
          </div>

          <div className="col-md-1">
            <p className="order-list-text fw-bold">
              -
            </p>
          </div>

          <div className="col-md-1">
            <p className="order-list-text">
              {currency?.selectedCurrencyPrice
                ? `${(
                    val?.membership_amount *
                    currency?.selectedCurrencyPrice
                  ).toFixed(2)} ${
                    currency?.currencySymbol
                      ? currency?.currencySymbol
                      : currencyIcn
                  }`
                : `${currencyIcn} ${val?.membership_amount} `}
            </p>
          </div>


          <div className="col-md-2">
            <p className="order-list-text">
              {currency?.selectedCurrencyPrice
                ? `${(val?.payable_amount * currency?.selectedCurrencyPrice).toFixed(
                    2
                  )} ${
                    currency?.currencySymbol
                      ? currency?.currencySymbol
                      : currencyIcn
                  }`
                : `${currencyIcn} ${val?.payable_amount}`}
            </p>
          </div>

          <div className="col-md-1">
            <p
              className={
                val?.payment_status == "success"
                  ? "success-status-text text-capitalize p-0"
                  : val?.payment_status == "failed"
                  ? "failed-status-text text-capitalize p-0"
                  : "pending-status-text text-capitalize p-0"
              }
            >
              {val?.payment_status ? val?.payment_status : '-'}
            </p>
          </div>

          <div className="col-md-1">
            <p className="order-list-text">
               Monthly
            </p>
          </div>

          <div className="col-md-2">
            <p className="order-list-text">
              {moment(val?.created_at).format("ll hh:mm A")}
            </p>
          </div>

          <div className="col-md-2">
            <p className="order-list-text">
             {val?.expiry_date ? moment(val?.expiry_date).format("ll") : "-"} 
            </p>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default MembershipOrderListCard;
