import React, { useState,useEffect,useMemo ,useCallback } from "react";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import ReactQuill from "react-quill";
import moment from "moment";
import { MdDelete } from "react-icons/md";
import { FiPlus } from "react-icons/fi";

const stripHtmlTags = (html) => {
  const div = document.createElement('div');
  div.innerHTML = html;
  return div.textContent || div.innerText || '';
};

const CreateADetailedSchedule = ({
  created_Schedule,
  schedules,
  setSchedules,
  additionalSchedules,
  dates,
  setAdditionalSchedules,
  isEdit
}) => {

  // console.log("schedules",schedules)
  // console.log("additionalSchedules",additionalSchedules)
  
  // console.log("datess",dates)
  
  // const stripHtmlTags = (html) => {
  //   const div = document.createElement("div");
  //   div.innerHTML = html;
  //   return div.textContent || div.innerText || "";
  // };


  // const getDescriptionLength = () => {
  //   const cleanDescription = stripHtmlTags( schedules?.find((s) =>
  //     dates?.some((date) => s?.date === date))?.description);
  //   return cleanDescription.length;
  // };

  // const maxLength = 2500;
  // const currentLength = getDescriptionLength();
  // const remainingLength = maxLength - currentLength;

  const maxLength = 500;
    const handleScheduleChange = (value, date, field, index) => {
      let updatedValue = value;

        if (index !== undefined) {
          const cleanDescription = stripHtmlTags(value);
          if (cleanDescription.length > maxLength) {
            updatedValue = value.slice(0, maxLength);
            alert(`Description cannot exceed ${maxLength} characters.`);
          }else{
            setAdditionalSchedules((prevState) => {
              const updatedSchedules = [...(prevState[date] || [])];
              updatedSchedules[index] = { ...updatedSchedules[index], [field]: value };
              return {
                ...prevState,
                [date]: updatedSchedules,
              };
            });
          }
        } else {
          const cleanDescription = stripHtmlTags(value);         
          if (cleanDescription.length > maxLength) {
            updatedValue = value.slice(0, maxLength);
            alert(`Description cannot exceed ${maxLength} characters.`);
          }else{
            setSchedules((prevSchedules) => 
              prevSchedules.map((schedule) =>
                schedule.date == date
                  ? { ...schedule, [field]: value }
                  : schedule
              )
            );
          }
        }
      };

  const handleAddMore = (date) => {
    setAdditionalSchedules((prevState) => ({
      ...prevState,
      [date]: [
        ...(prevState[date] || []),
        {date:date, title: "", description: "", start_time: "", end_time: "" },
      ],
    }));
  };

  // const handleRemoveSchedule = (date, index) => {
  //   // Check if index is defined to determine if it’s an additional schedule
  //   if (index !== undefined) {
  //     setAdditionalSchedules((prevState) => {
  //       const updatedSchedules = (prevState[date] || [])?.filter(
  //         (_, idx) => idx !== index
  //       );
  //       return {
  //         ...prevState,
  //         [date]: updatedSchedules.length > 0 ? updatedSchedules : "",
  //       };
  //     });
  //   } else {
  //     setSchedules((prevSchedules) =>
  //       prevSchedules?.filter((s) => s.date !== date)
  //     );
  //   }
  // };

  const handleRemoveSchedule = (date, index) => {
    if (index !== undefined) {
      setAdditionalSchedules((prevState) => {
        const updatedSchedules = (prevState[date] || [])?.filter((_, idx) => idx !== index);
        const newState = { ...prevState };
        if (updatedSchedules.length > 0) {
          newState[date] = updatedSchedules;
        } else {
          delete newState[date];
        }
        return newState;
      });
    } else {
      setSchedules((prevSchedules) =>
        prevSchedules?.filter((s) => s.date !== date)
      );
    }
  };


  const handleFormSubmit = () => {
    const newSchedules = Object.values(additionalSchedules)?.flat();
    // setSchedules(prevSchedules => [...prevSchedules, ...newSchedules]);
    const mergedSchedules = [...schedules, ...newSchedules];
    // console.log("mergedSchedules",mergedSchedules)
  };

  // console.log("additionalSchedules",additionalSchedules)
  // console.log("schedules",schedules)

  const descriptionLengths = useMemo(() => {
    return dates?.map(date => {
      const schedule = schedules?.find((s) => s.date === date) || {};
      const cleanDescription = stripHtmlTags(schedule.description || '');
      return cleanDescription.length;
    });
  }, [schedules, dates]);


  return (
    <>
      { created_Schedule == "1" && dates.length > 0 && (
        <div>
        <h5 className="form-section-heading">Additional Dates</h5>
         <div className="schedule-main-div">
       {dates?.map((date,index) =>{        
        const schedule = schedules.find((s) => s.date === date) || {};
        const currentLength = descriptionLengths[index];
        const remainingLength = maxLength - currentLength;

        return(
            <div className="schedule-div" key={date}>
              <div className="">
                <label className="form-label date-title">
                  Date: {moment(date).format("D MMMM YYYY")}
                </label>
              </div>
              <div className="row">
                <div className="form-group col-6 col-md-4">
                  <label className="form-label">Title</label>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      placeholder="Enter Title"
                      value={schedules?.find((s) => s.date === date)?.title || ""
                      }
                      onChange={(e) =>
                        handleScheduleChange(e.target.value, date, "title")
                      }
                    />
                  </div>
                </div>

                <div className="form-group col-6 col-md-4">
                  <label className="form-label">Start Time</label>
                  <div>
                    <input
                      type="time"
                      className="form-control"
                      step="900"
                      pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$"
                      name="start_time"
                      value={
                        schedules?.find((s) => s?.date == date)?.start_time ||
                        ""
                      }
                      onChange={(e) =>
                        handleScheduleChange(e.target.value, date, "start_time")
                      }
                      onFocus={(e) => e.currentTarget?.showPicker()}
                    />
                  </div>
                </div>

                <div className="form-group col-6 col-md-4">
                  <label className="form-label">End Time</label>
                  <div>
                    <input
                      type="time"
                      className="form-control"
                      step="900"
                      pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$"
                      name="end_time"
                      value={
                        schedules?.find((s) => s?.date === date)?.end_time || ""
                      }
                      onChange={(e) =>
                        handleScheduleChange(e.target.value, date, "end_time")
                      }
                      onFocus={(e) => e.currentTarget?.showPicker()}
                    />
                  </div>
                </div>

                <div className="form-group col-6 col-md-12">
                  <div className="d-flex justify-content-between">
                  <label className="form-label">Description</label>
                    <p className="Character-0500">
                      Character:
                      <span className="text-style-1">
                        {currentLength}/{remainingLength}
                      </span>
                    </p>
                    </div>
                  <div style={{height:"130px", backgroundColor:"white",}}>
                    <ReactQuill
                      // value={schedules?.find((s) => s.date === date)?.description || "" }
                      value={schedule.description || ''}
                      onChange={(value) =>handleScheduleChange(value, date, "description")}
                      name="description"
                      theme="snow"
                      style={{ height: "90px" }}
                      placeholder="Description"
                    />
                  </div>
                  {/* <div>
                        <input
                          type="text"
                          className="form-control"
                          name="description"
                          value={schedules.find((s) => s.date == date)?.description || ''}
                          onChange={(e) => handleScheduleChange(e, date)}
                        />
                 </div> */}
                </div>
              </div>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => handleAddMore(date)}>
                <FiPlus size={18} /> Add More
              </button>
              
              <div className="position-relative ">
              {additionalSchedules[date] &&
                additionalSchedules[date]?.map((schedule, index) =>{
                  const currentLength = stripHtmlTags(schedule?.description || '').length;
                  const remainingLength = maxLength - currentLength;
                return (
                  <div className="row mt-3" key={index}>
                    <div className="form-group col-6 col-md-4">
                      <label className="form-label">Title</label>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          name="title"
                          placeholder="Enter Title"
                          value={schedule.title}
                          onChange={(e) =>
                            handleScheduleChange(
                              e.target.value,
                              date,
                              "title",
                              index
                            ) }
                        />
                      </div>
                    </div>

                    <div className="form-group col-6 col-md-4">
                      <label className="form-label">Start Time</label>
                      <div>
                        <input
                          type="time"
                          className="form-control"
                          step="900"
                          pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$"
                          name="start_time"
                          value={schedule?.start_time}
                          onChange={(e) =>
                            handleScheduleChange(
                             e.target.value,
                             date,
                             "start_time",
                             index)}
                          onFocus={(e) => e.currentTarget?.showPicker()}
                        />
                      </div>
                    </div>

                    <div className="form-group col-6 col-md-4">
                      <label className="form-label">End Time</label>
                      <div>
                        <input
                          type="time"
                          className="form-control"
                          step="900"
                          pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$"
                          name="end_time"
                          value={schedule.end_time}
                          onChange={(e) =>
                            handleScheduleChange(
                              e.target.value,
                              date,
                              "end_time",
                              index) }
                          onFocus={(e) => e.currentTarget?.showPicker()}
                        />
                      </div>
                    </div>

                    <div className="form-group col-12 col-md-12">
                    <div className="d-flex justify-content-between">  
                     <label className="form-label">Description</label>
                    <p className="Character-0500">
                      Character:
                      <span className="text-style-1">
                        {currentLength}/{remainingLength}
                      </span>
                    </p>
                      </div>
                      <div>
                        <div
                          style={{ height: "130px", backgroundColor: "white" }}>
                          <ReactQuill
                            // value={
                            //   schedules.find((s) => s.date == date)
                            //   ?.description || ""
                            // }
                            value={schedule?.description}
                            onChange={(value) =>
                              handleScheduleChange(
                                value,
                                date,
                                "description",
                                index ) }
                            name="description"
                            theme="snow"
                            style={{ height: "90px" }}
                            placeholder="Description"
                            //
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="schedule-remove-btn"
                        onClick={() => handleRemoveSchedule(date, index)}>
                       <MdDelete size={24}/>
                      </button>
                    </div>
                  </div>
                )})}
             </div>
            </div>
          )})}
          </div>
        </div>
      )}

     {/* <button className="btn btn-danger" type="button" onClick={handleFormSubmit}>Merge</button> */}
 
    </>
  );
};

export default CreateADetailedSchedule;
