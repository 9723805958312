
import { useEffect, useState, useMemo } from "react";
import { IoWarningOutline } from "react-icons/io5";
import WarningModal from "../Common/WarningModal";
import arrow from "../../assets/images/arrow2.svg";
import { Coupon_delete, Create_Coupon_On_Event, Created_Coupon_List, Edit_Coupon_Data, Get_Packages_List, Update_Coupon_Data } from "../../utils/couponsmanage/EditCoupon";
import moment from "moment/moment";
import Loader2 from "../Loader/Loader2";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { Get_SponsorUsers } from "../../utils/CreateEvents/createEvent";
import Select from "react-select";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import { IoMdInformationCircleOutline } from "react-icons/io";


const EditCreateCoupon = ({ setTabValue, id, Messages }) => {
  const [formData, setFormData] = useState({
    event_id: "",
    coupon_name: "",
    coupon_details: "",
    discount_type: "",
    min_amount: "",
    redeem_limit_user: "",
    redeem_limit_coupon: "",
    discount: "",
    event_package_id: "",
    start_date: "",
    end_date: "",
    sponsors: "",
    coupon_category: "",
    need_coupons: ""
  });
  const [errors, setErrors] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);
  const today = new Date().toISOString().split("T")[0];

  const [createdCouponList, setCreatedCouponList] = useState([]);

  const [dataLoader, setDataLoader] = useState(false);
  const [editStatus, setEditStatus] = useState(false);
  const [columnDefs, setColumnDefs] = useState([]);
  const [needCoupon, setNeedCoupon] = useState(0);
  const [coupon_category, setCoupon_Category] = useState("all");
  const [predefine_Sponsor, setPredefine_Sponsor] = useState([]);
  const [selectedSponsors, setSelectedSponsors] = useState([])
  const [createdPackages, setCreatedPackages] = useState()
  const [clickEditId, setClickEditId] = useState('')

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const CouponListPredifine = async () => {
    setDataLoader(true)
    try {
      await Created_Coupon_List(id)
        .then((response) => {
          if (response?.data?.success) {
            setCreatedCouponList(response?.data?.data)
            setDataLoader(false)
          }
        })
    } catch (err) {
      console.log(err)
    }

    try {
      await Get_Packages_List(id)
        .then((response) => {
          if (response?.data?.success) {
            setCreatedPackages(response?.data?.data)
            setDataLoader(false)
          }
        })
    } catch (err) {
      console.log(err)
    }
  }


  useEffect(() => {
    if (id) {
      CouponListPredifine();
    }
  }, [id])

  useEffect(() => {
    setFormData({
      ...formData,
      coupon_category: coupon_category,
      need_coupons: needCoupon
    })
  }, [needCoupon, coupon_category])

  const handledeleteCoupon = async (id) => {
    try {
      await Coupon_delete(id)
        .then((response) => {
          if (response?.data?.success) {
            Messages(response?.data?.message)
            CouponListPredifine();
          }
        })
    } catch (err) {
      console.log(err)
    }
  }

  const handleEditCoupon = async (id) => {
    setClickEditId(id)
    try {
      Edit_Coupon_Data(id)
        .then((response) => {
          if (response?.data?.success) {
            const data = response?.data?.data
            setFormData(data)
            setSelectedSponsors(data?.specific_sponsor?.map((item) => {
              return {
                value: item?.sponsor_id,
                label: `${item?.users.f_name} ${item.users.l_name}`

              }
            }))
            setNeedCoupon(data?.need_coupons)
            setCoupon_Category(data?.coupon_category)
            setEditStatus(true)
          }
        })
    } catch (err) {
      console.log(err)
    }
  }

  const handleCouponDetails = (value) => {
    const text = value.replace(/<[^>]*>?/gm, '');
    if (text.length <= 500) {
      setFormData((prevState) => ({
        ...prevState,
        coupon_details: value
      }));
      setErrors({
        ...errors,
        coupon_details: ""
      });
    } else {
      setErrors({
        ...errors,
        coupon_details: "Coupon Details cannot exceed 500 characters."
      });
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectSponsor = (selectedOption) => {
    setSelectedSponsors(selectedOption);
    const list = selectedOption?.map((item) => {
      return item.value;
    });
    setFormData({
      ...formData,
      sponsors: JSON?.stringify(list),
    });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (needCoupon == 1) {
      if (validateForm()) {
        setBtnLoading(true);
        setDataLoader(true);

        if (editStatus) {
          const fData = { ...formData, event_id: id, event_package_id: formData?.event_package_id }
          try {
            await Update_Coupon_Data(fData, clickEditId)
              .then((response) => {
                if (response?.data?.success) {
                  Messages(response?.data?.message)
                  CouponListPredifine();
                  setNeedCoupon(0);
                  setEditStatus(false)
                  EmptyState();
                }
              })
          } catch (error) {
            toast.error(error?.response?.data?.message, {
              position: 'top-center',
              autoClose: 3000
            })
          } finally {
            setBtnLoading(false);
            setDataLoader(false)
          }
        } else {
          const fDataCreate = { ...formData, event_id: id }
          try {
            await Create_Coupon_On_Event(fDataCreate, id)
              .then((response) => {
                if (response?.data?.success) {
                  Messages(response?.data?.data)
                  setNeedCoupon(0);
                  CouponListPredifine();
                  EmptyState();
                }
              })
          } catch (error) {
            console.log(error)
          } finally {
            setBtnLoading(false);
            setDataLoader(false)
          }
        }
      }
    }
  };

  const EmptyState = () => {
    setFormData({
      coupon_name: "",
      coupon_details: "",
      discount_type: "",
      min_amount: "",
      redeem_limit_user: "",
      redeem_limit_coupon: "",
      discount: "",
      event_package_id: "",
      start_date: "",
      end_date: "",
    })
    setSelectedSponsors([]);
  }

  const validateForm = () => {
    let tempErrors = {};
    if (!formData.coupon_name) tempErrors.coupon_name = "Coupon Name is required";

    const coupoundetail = formData.coupon_details?.replace(/<p><br><\/p>/g, '')
    if (!coupoundetail) tempErrors.coupon_details = "Coupon Details are required";
    if (!formData.discount_type) tempErrors.discount_type = "Coupon Type is required";
    if (!formData.min_amount) tempErrors.min_amount = "Minimum Amount is required";
    if (!formData.redeem_limit_user) tempErrors.redeem_limit_user = "Redeem Limit Per User is required";
    if (!formData.redeem_limit_coupon) tempErrors.redeem_limit_coupon = "Redeem Limit Per Coupon is required";
    if (!formData.discount) tempErrors.discount = "Discount is required";
    if (!formData.event_package_id) tempErrors.event_package_id = "Event Package is required";
    if (!formData.start_date) tempErrors.start_date = "Start Date is required";
    if (!formData.end_date) tempErrors.end_date = "End Date is required";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  useEffect(() => {
    setColumnDefs([
      {
        headerName: "Coupon Name",
        field: "coupon_name",
        cellRenderer: ({ value, data }) => {
          return <p className=""> {value ? value : "-"}</p>;
        },
      },
      {
        headerName: "Event Package Name",
        field: "event_package",
        cellRenderer: ({ value, data }) => {
          return <p className=""> {value?.title ? value?.title : "All"}</p>;
        },
      },
      {
        headerName: "Coupon Code",
        field: "coupon_code",
        cellRenderer: ({ value, data }) => {
          return <p className=""> {value ? value : "-"}</p>;
        },
      },
      {
        headerName: "Discount",
        field: "discount",
        cellRenderer: ({ value, data }) => {
          return data?.discount_type == "flat" ?
            <p className=""> {value ? `${value} flat` : "-"}</p> : <p> {value ? `${value} %` : "-"}</p>;
        },
      },
      {
        headerName: "Coupon Type",
        field: "discount_type",
        cellRenderer: ({ value, data }) => {
          return <p className=""> {value ? value : "-"}</p>;
        },
      },
      {
        headerName: "Min Amount",
        field: "min_amount",
        cellRenderer: ({ value, data }) => {
          return <p className=""> {value ? value : "-"}</p>;
        },
      },

      {
        headerName: "Redeem Limit Coupon",
        field: "redeem_limit_coupon",
        cellRenderer: ({ value, data }) => {
          return <p className=""> {value ? value : "-"}</p>;
        },
      },

      {
        headerName: "Redeem Limit User",
        field: "redeem_limit_user",
        cellRenderer: ({ value, data }) => {
          return <p className=""> {value ? value : "-"}</p>;
        },
      },


      {
        headerName: "Start Date",
        field: "start_date",
        cellRenderer: ({ value, data }) => {
          return (
            <p className="">
              {" "}
              {value ? moment(value).format("DD-MM-YY") : "-"}
            </p>
          );
        },
      },
      {
        headerName: "End Date",
        field: "end_date",
        cellRenderer: ({ value, data }) => {
          return (
            <p className="">
              {" "}
              {value ? moment(value).format("DD-MM-YY") : "-"}
            </p>
          );
        },
      },
      {
        headerName: "Specific User",
        field: "specific_sponsor",
        width: 350,
        cellRenderer: ({ value, data }) => {
          return <div className='Card-IntrestD'>
            {value?.length > 0 ? value?.map((item) => {
              return (
                <button key={item?.id} className="Card-IntrestP"
                >
                  #{item?.users?.name}
                </button>
              )
            }) : '-'}
          </div>;
        },
      },

      {
        headerName: "Coupon Detail",
        field: "coupon_details",
        cellRenderer: ({ value, data }) => {
          return <p className="" dangerouslySetInnerHTML={{ __html: value ? value : "-" }}></p>;
        },
      },
      {
        headerName: "Action",
        field: "id",
        cellRenderer: ({ value, data }) => {
          return (
            <div className="d-flex gap-2">
              <button
                className="create-Date-Time"
                type="button"
                onClick={() => handleEditCoupon(value)}>
                Edit
              </button>
              <button
                className="create-Date-Time"
                type="button"
                onClick={() => handledeleteCoupon(value)}>
                Remove
              </button>
            </div>
          );
        },
      },
    ]);
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      filter: "agTextColumnFilter",
      floatingFilter: true,
    };
  }, []);


  const GetSponsorUsers = async () => {
    try {
      const response = await Get_SponsorUsers();
      const getSponsor = response?.data?.data;
      const options = getSponsor?.map((topic) => ({
        value: topic.id,
        label: topic.name,
      }));
      setPredefine_Sponsor(options)
    } catch (err) {
      console.log("err", err);
    }
  }

  useEffect(() => {
    GetSponsorUsers();
  }, []);


  // rect quill length count
  const stripHtmlTags = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;

    let textWithNewlines = div.innerHTML

    if (/^<p><br><\/p>$/i.test(textWithNewlines)) {
      textWithNewlines = '';  // Remove the entire content if it matches <p><br></p>
    } else {
      // Otherwise, apply replacements
      textWithNewlines = textWithNewlines
        .replace(/<br\s*\/?>/gi, '\n')  // Replace <br> with newline
    }

    div.innerHTML = textWithNewlines;
    return div.textContent || div.innerText || "";
  };

  const getDescriptionLength = () => {
    const cleanDescription = stripHtmlTags(formData.coupon_details);
    return cleanDescription.length;
  };

  const maxLength = 500;
  const currentLength = getDescriptionLength();
  const remainingLength = maxLength - currentLength;
  //  
  return (
    <>
      {/* {packageList.length <= 0 && (
      <WarningModal setTabValue={setTabValue} tabVal={'packages'} msg={'Please create a package first.'} />
      )} */}

      <div className="basicInfo">
        <div className="d-flex">
          <div>
            {/* <h2 className="basicinfo-head">Create Coupon</h2> */}
            <div className="d-flex align-items-center gap-1 position-relative">
              <p className="createevent-head-p">
                This section allows you to create and manage discount coupons that Sponsor can use to get discounted offer on the selected event packages.
                It would be over and above the applicable offer price.
              </p>
              {/* <h2 className="event-heading mb-0 d-flex flex-column flex-md-row align-items-md-center gap-md-2">Create Coupon</h2>
              <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <IoMdInformationCircleOutline size={23} />
              </div>
              {isHovered && (
                <div className="info-popup" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                  <p className="info-popup-text">Cancellation period field is required on all Event pages where the same is provided by the Event host. After this date the cancellation would be chargeable. This would override on the platform general terms and conditions if mentioned here.
                  </p>
                </div>
              )} */}
            </div>
            <p className="basicinfo-p">Select a package, coupon name and other important information.</p>
          </div>
        </div>
        <div className="row col-md-12 justify-content-center mt-3">
          <form className="" onSubmit={handleSubmit}>
            <div className="d-flex align-items-center gap-3">
              <label className="mb-0">
                Do you need coupons ?
              </label>
              <div className="d-flex gap-3">
                <div className="form-check">
                  <label className="form-check-label mb-0">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="1"
                      checked={needCoupon == 1}
                      name="needCoupon"
                      onChange={(e) => setNeedCoupon(e?.target?.value)} />
                    Yes
                  </label>
                </div>
                <div className="form-check">
                  <label className="form-check-label mb-0">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="0"
                      checked={needCoupon == 0}
                      name="needCoupon"
                      onChange={(e) => setNeedCoupon(e?.target?.value)} />
                    No
                  </label>
                </div>
              </div>
            </div>

            {
              needCoupon == 1 &&
              <div>
                <div className="row mt-2">
                  <div className="col-md-6">
                    <label className="form-label">
                      Event package<span className="asterisk">*</span>
                    </label>
                    <IoMdInformationCircleOutline size={16} color="black" title='Select the event package that this coupon applies to.' />
                    <select
                      className="form-control"
                      name="event_package_id"
                      onChange={handleInputChange}>
                      <option value="" selected> Select Package </option>
                      <option value="0" selected={formData?.event_package_id == '0'}>All</option>
                      {createdPackages?.map((val) => {
                        return (
                          <option key={val?.id} value={val?.id} selected={formData?.event_package_id == val?.id}>
                            {val?.title}
                          </option>
                        );
                      })}
                    </select>
                    {errors.event_package_id && (
                      <span className="error">{errors.event_package_id}</span>
                    )}
                  </div>

                  <div className="col-md-6 form-group">
                    <label className="form-label">
                      Coupon Name<span className="asterisk">*</span>
                    </label>
                    <IoMdInformationCircleOutline size={16} color="black" title=' Enter a unique name for your coupon.' />

                    <input
                      type="text"
                      className="form-control"
                      value={formData?.coupon_name}
                      name="coupon_name"
                      placeholder="Enter Coupon Name"
                      onChange={handleInputChange}
                    />
                    {errors.coupon_name && (
                      <span className="error">{errors.coupon_name}</span>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-3">
                    <label className="form-label">
                      Start Date<span className="asterisk">*</span>
                    </label>
                    <IoMdInformationCircleOutline size={16} color="black" title='Enter the date when the coupon becomes valid' />

                    <input
                      type="date"
                      className="form-control"
                      name="start_date"
                      value={formData?.start_date}
                      min={today}
                      onChange={handleInputChange}
                    />
                    {errors.start_date && (
                      <span className="error">{errors.start_date}</span>
                    )}
                  </div>

                  <div className="col-md-3">
                    <label className="form-label">
                      End Date<span className="asterisk">*</span>
                    </label>
                    <IoMdInformationCircleOutline size={16} color="black" title='Enter the last date the coupon can be used' />

                    <input
                      type="date"
                      className="form-control"
                      name="end_date"
                      disabled={!formData?.start_date}
                      value={formData?.end_date}
                      min={formData?.start_date}
                      onChange={handleInputChange}
                    />
                    {errors.end_date && (
                      <span className="error">{errors.end_date}</span>
                    )}
                  </div>

                  <div className="col-md-3 form-group">
                    <label className="form-label">
                      Redeem Limit Coupon<span className="asterisk">*</span>
                    </label>
                    <IoMdInformationCircleOutline size={16} color="black" title='Set how many times this coupon can be used' />

                    <input
                      type="number"
                      min='1'
                      className="form-control"
                      value={formData?.redeem_limit_coupon}
                      name="redeem_limit_coupon"
                      placeholder="Enter Redeem Limit Coupon"
                      onChange={handleInputChange}
                    />
                    {errors.redeem_limit_coupon && (
                      <span className="error">{errors.redeem_limit_coupon}</span>
                    )}
                  </div>

                  <div className="col-md-3">
                    <label className="form-label">
                      Coupon Type<span className="asterisk">*</span>
                    </label>
                    <IoMdInformationCircleOutline size={16} color="black" title='Choose whether the discount is a fixed amount 
                    or a percentage of the regular price' />
                    <select
                      className="form-control"
                      name="discount_type"
                      onChange={handleInputChange}
                    >
                      <option selected >Select Discount Type</option>
                      <option value="flat" selected={formData?.discount_type == "flat"}> Flat</option>
                      <option value="Percentage" selected={formData?.discount_type == "Percentage"}  >Percentage</option>
                    </select>
                    {errors.discount_type && <span className="error">{errors.discount_type}</span>}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-3 form-group">
                    <label className="form-label">
                      Discount<span className="asterisk">*</span>
                    </label>
                    <IoMdInformationCircleOutline size={16} color="black" title="Specify the discount amount or percentage the coupon will provide" />

                    <input
                      type="number"
                      min='1'
                      max={formData?.discount_type == "Percentage" && "100"}
                      className="form-control"
                      value={formData?.discount}
                      name="discount"
                      placeholder="Enter Discount"
                      onChange={handleInputChange}
                    />
                    {errors.discount && (
                      <span className="error">{errors.discount}</span>
                    )}
                  </div>

                  <div className="col-md-3 form-group">
                    <label className="form-label">
                      Min Amount<span className="asterisk">*</span>
                    </label>
                    <input
                      type="number"
                      min="1"
                      className="form-control"
                      value={formData?.min_amount}
                      name="min_amount"
                      placeholder="Enter Min Amount"
                      onChange={handleInputChange}
                    />
                    {errors.min_amount && (
                      <span className="error">{errors.min_amount}</span>
                    )}
                  </div>

                  <div className="col-md-3 form-group">
                    <label className="form-label">
                      Redeem Limit User<span className="asterisk">*</span>
                    </label>
                    <input
                      type="number"
                      min='1'
                      className="form-control"
                      value={formData?.redeem_limit_user}
                      name="redeem_limit_user"
                      placeholder="Enter Redeem Limit User"
                      onChange={handleInputChange}
                    />
                    {errors.redeem_limit_user && (
                      <span className="error">{errors.redeem_limit_user}</span>
                    )}
                  </div>
                </div>

                <div className="row mb-2">
                  <div className="col-3">
                    <label className="form-label">
                      Coupon Applicable To<span className="asterisk">*</span>
                    </label>
                    <IoMdInformationCircleOutline size={16} color="black" title='Specify if the coupon is for everyone or just specific users.' />

                    <div className="d-flex gap-3">
                      <div className="form-check">
                        <label className="form-check-label mb-0">
                          <input
                            className="form-check-input"
                            type="radio"
                            value="all"
                            checked={coupon_category == "all"}
                            name="coupon_category"
                            onChange={(e) => setCoupon_Category(e?.target?.value)}
                          />
                          All
                        </label>
                      </div>
                      <div className="form-check">
                        <label className="form-check-label mb-0">
                          <input
                            className="form-check-input"
                            type="radio"
                            value="specific_user"
                            checked={coupon_category == "specific_user"}
                            name="coupon_category"
                            onChange={(e) => setCoupon_Category(e?.target?.value)}
                          />
                          Specific User
                        </label>
                      </div>
                    </div>
                  </div>

                  {coupon_category == "specific_user" &&
                    <div className="col-md-6">
                      <label className="form-label">
                        Specific User<span className="asterisk">*</span>
                      </label>
                      <Select
                        defaultValue={[]}
                        isMulti
                        name="specific_user"
                        value={selectedSponsors}
                        options={predefine_Sponsor}
                        onChange={handleSelectSponsor}
                        classNamePrefix="Select"
                        placeholder="Select Specific User"
                      />
                      {/* {errors.host_interests && (
                     <span className="error">{errors.host_interests}</span>
                   )} */}
                    </div>
                  }
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="col-md-12 form-group">
                      <div className="d-flex justify-content-between">
                        <label className="form-label">
                          Coupon Details<span className="asterisk">*</span>
                        </label>
                        <p className="Character-0500">
                          Character:<span className="text-style-1">
                            {/* remainingLength */}
                            {currentLength}/{maxLength}
                          </span>
                        </p>
                      </div>

                      <div style={{ height: '200px' }}>
                        <ReactQuill
                          value={formData?.coupon_details}
                          onChange={handleCouponDetails}
                          theme='snow'
                          style={{ height: '150px' }}
                          placeholder="Enter Coupon Details"
                        />
                      </div>
                      {errors.coupon_details && (
                        <span className="error">{errors.coupon_details}</span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-end gap-4">
                  <button className="change-champ-btn d-flex align-items-center gap-1" type="submit">
                    {
                      btnLoading &&
                      <div
                        className="spinner-border text-light"
                        role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    }
                    Submit
                  </button>
                </div>
              </div>
            }

          </form>



          {/* table*/}
          {
            !createdCouponList?.length <= 0 &&
            <div className="mt-4">
              <div
                className="ag-theme-alpine"
                style={{ height: 600, width: "100%" }} >
                <AgGridReact
                  // ref={gridRef}
                  // onGridReady={onGridReady}
                  columnDefs={columnDefs}
                  rowData={createdCouponList}
                  defaultColDef={defaultColDef}
                  rowSelection="multiple"
                ></AgGridReact>
              </div>
            </div>
          }

          <div className="d-flex justify-content-md-end align-items-center mt-3">
            <div className="d-flex flex-column flex-md-row gap-2">
              <button className="btn delete-event-btn" type="button" onClick={() => setTabValue('Event-Media-Hub')}>Skip</button>

              <button
                className="d-flex align-items-center gap-1 saveandcontinue-btn"
                type="button"
                onClick={() => setTabValue('Event-Media-Hub')}>
                Save and Continue
                <img className="arrow-img" src={arrow} alt="arrow" />
              </button>
            </div>
          </div>


        </div>
      </div >
    </>
  );
};

export default EditCreateCoupon;
