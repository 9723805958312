import { useState, useEffect,useRef,useMemo} from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";

const ReferencesList=({rowData})=>{
    const [columnDefs, setColumnDefs] = useState([]);
    const gridRef = useRef();

  const onGridReady = (params) => {
    gridRef.current = params.api;
  };

  const defaultColDef = useMemo(() => {
    return {
      filter: "agTextColumnFilter",
      floatingFilter: true,
    };
  }, []);

    useEffect(() => {
        setColumnDefs([
          {
            headerName: "Name",
            field: "name",
            // cellRenderer: AvatarRenderer,
            // filter: false,
          },
          {
            headerName: "Mobile No.",
            field: "mobile_no",
            // cellRenderer: AvatarRenderer,
            // filter: params => params.data.editable,
          },
          {
            headerName: "Email",
            field: "email",
            // cellRenderer: AvatarRenderer,
            // filter: params => params.data.editable,
          },
          {
            headerName: "Reference RelationShip",
            field: "relationship",
            // cellRenderer: AvatarRenderer,
            // filter: params => params.data.editable,
          },
          {
            headerName: "Reference Profession",
            field: "profession",
            // cellRenderer: AvatarRenderer,
            // filter: params => params.data.editable,
          },
          {
            headerName: "Reference Location",
            field: "location",
            // cellRenderer: AvatarRenderer,
            // filter: params => params.data.editable,
          },
          {
            headerName: "Reference For",
            field: "user_type",
            // cellRenderer: AvatarRenderer,
            // filter: params => params.data.editable,
          },
      
        ]);
      }, [rowData]);

    return(<>
            <div className="ag-theme-alpine"
              style={{ height: 300, width: "100%" }} >
              <AgGridReact
                ref={gridRef}
                onGridReady={onGridReady}
                columnDefs={columnDefs}
                rowData={rowData}
                defaultColDef={defaultColDef}
                rowSelection="multiple"
              ></AgGridReact>
            </div>
    </>)
};

export default ReferencesList;