
const Progressbar = ({ progress }) => {
    // ORANGE #ed9035
    // YELLOW #edc335
    // GREEN #93d570
    return (
        <>
            <div className="progress-bar mt-0 pt-0">
                <div className="success-progress" style={{width:`${progress}%` }}>
                    {progress}%
                </div>
            </div>
        </>
    )
}
export default Progressbar;