import { ApplyCoupon } from "../../Services/Apis"


export const Apply_Coupon = async (couponFD) => {
    try{
    return await ApplyCoupon(couponFD).then((data) => data)
    }
    catch(error){
        throw error
    }
}