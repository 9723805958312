import { ChangeChamp, Event_Sponsor_Transaction, GetChampDashboard, GetChamps, GetSponsorEventsForChamp, GetSponsorListForChamp, GetTransctionsListForChamp, Get_Profile_Detail, getChampHostEventsList, getChampReferListData, getChamphostListData } from "../../Services/Apis"

//get champ refer users
export const getChampReferList = async (submitSearchValue, sortValue, currentPage,dateFilterValue) => {
    try {
        return await getChampReferListData(submitSearchValue, sortValue, currentPage,dateFilterValue).then((data) => data)
    } catch (error) {
        console.log(error)
    }
}


//get champ host event
export const getChampHostList = async (submitSearchValue, sortValue, currentPage,dateFilterValue) => {
    try {
        return await getChamphostListData(submitSearchValue, sortValue, currentPage,dateFilterValue).then((data) => data)
    } catch (error) {
        console.log(error)
    }
}


// get champs host Events List
export const getChampHostEvents = async (params, eventMode, eventType, eventRange,categories, currentPage, search, sortVal, lat_long, eventCategory, status) => {
    return await getChampHostEventsList(params, eventMode, eventType, eventRange,categories, currentPage, search, sortVal, lat_long, eventCategory, status).then((data) => data)
}

// GetChamps
export const Get_Champs = async () => {
    try {
        const response = await GetChamps().then((data) => data);
        return response
    }
    catch (error) {
        console.log(error);
    }
}

// change champ
export const Change_Champ = async (Fdata) => {
    try {
        const response = await ChangeChamp(Fdata).then((data) => data);
        return response
    }
    catch (error) {
        console.log(error);
    }
}

// GetSponsorListForChamp
export const Get_SponsorListForChamp = async (submitSearchValue, sortValue, currentPage ,dateFilterValue) => {
    try {
        const response = await GetSponsorListForChamp(submitSearchValue, sortValue, currentPage ,dateFilterValue).then((data) => data);
        return response
    }
    catch (error) {
        console.log(error);
    }
}

// GetTransctionsListForChamp
export const Get_TransctionsListForChamp = async (submitSearchValue, statusValue, sortValue, currentPage) => {
    try {
        const response = await GetTransctionsListForChamp(submitSearchValue, statusValue, sortValue, currentPage).then((data) => data);
        return response
    }
    catch (error) {
        console.log(error);
    }
}

export const Get_SponsorEventsForChamp = async (id, searchValue, statusValue, sortValue, currentPage) => {
    try {
        const response = await GetSponsorEventsForChamp(id, searchValue, statusValue, sortValue, currentPage).then((data) => data);
        return response
    }
    catch (error) {
        console.log(error);
    }
}

export const Get_ChampDashboard = async () => {
    try {
        const response = await GetChampDashboard().then((data) => data);
        return response
    }
    catch (error) {
        console.log(error);
    }
}
 
export const Get_ProfileDetail = async (id) => {
    try {
        const response = await Get_Profile_Detail(id).then((data) => data);
        return response
    }
    catch (error) {
        console.log(error);
    }
}

export const Event_SponsorTransaction = async (id) => {
    try {
        const response = await Event_Sponsor_Transaction(id).then((data) => data);
        return response
    }
    catch (error) {
        console.log(error);
    }
}