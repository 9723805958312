import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    EventType: [],
}


export const Get_basicInfo_lists_data = createSlice({
    name: 'ProfileValues',
    initialState: initialState,
    reducers: {
        getEventType: (state, action) => {
            state.EventType = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { getEventType } = Get_basicInfo_lists_data.actions;

export default Get_basicInfo_lists_data.reducer;