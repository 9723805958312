import axios from "axios";
import {
  APPLY_COUPON, BANNER_URL, BASE_URL, CHAMP_REGISTER_URL, CHANGE_CHAMP, CHANGE_NUMBER,
  CHANGE_USERNAME, CREATE_COUNPON, CREATE_EVENT_PACKAGES, CREATE_PAST_EVENT_GALLERY, EDIT_EVENT,
  EDIT_INTEREST_EVENT_TYPES, EMAIL_VERIFY_OTP, VERIFY_EMAIL_OTP, EVENT_DETAIL, EVENT_PACKAGE_DELETE,
  EVENT_REVIEW, EVENT_TRANSACTION, GET_APPLY_COUPON, GET_AVATAR, GET_CHAMPS_LIST, GET_CHAMP_DASHBOARD,
  GET_CHAMP_DATA_LIST, GET_CHAMP_EVENTS_LIST, GET_CHAMP_REFER_USER_LIST, GET_CITY_STATE_COUNTRY, GET_CONFIG_DATA,
  GET_COUPONS, GET_CREATE_PACKAGES_LIST, GET_EDIT_PROFILE_LIST, GET_EVENTS_CREATE, GET_EVENT_LIST, GET_EVENT_PEOPLE_RANGE,
  GET_EVENT_REVIEW, GET_EVENT_SUB_TOPIC, GET_EVENT_TOPIC, GET_EVENT_TRANSACTIONS_LIST_FOR_CHAMP, GET_EVENT_TYPE, GET_HOME_EVENT,
  GET_MANAGE_REQUEST, GET_MESSAGE, GET_MY_EVENT, GET_ONLY_TESTIMONIAL_LIST, GET_ORDER_DETAIL, GET_ORGANIZER_FORM_EDIT, GET_PACKAGES_FORM_EDIT,
  GET_PITCH_FORM_EDIT, GET_POST_ORGANIZER_FORM_EDIT, GET_POST_PACKAGES_FORM_EDIT, GET_POST_PITCH_FORM_EDIT, GET_PREDEFINE_LIST,
  GET_PROFILE, GET_SPONSOR, GET_SPONSOR_EVENTS_FOR_CHAMP, GET_SPONSOR_LIST_FOR_CHAMP, GET_TESTIMONIAL, GET_PREDEFINE_PACKAGE,
  GET_TESTIMONIAL_LIST_RATING_NAME, MANAGE_REQ_MESAGE_SEND, MY_EVENT_TYPES, NUMBER_GENERATE_OTP, NUMBER_OTP_VERIFY,
  ORGANIZER_PITCH_FORM, PITCH_FORM, POST_QUERY, POST_TESTIMONIAL, POST_UPDATE_PROFILE, UPDATE_EMAIL, UPDATE_EVENT,
  UPDATE_PAYMENT, USER_LOGIN_URL, USER_REGISTER_URL, USER_SOCIAL_MEDIA_LOGIN, VERIFY_CHANGE_EMAIL, VERIFY_CHANGE_NUMBER,
  VERIFY_CHANGE_USERNAME, GENERATE_SIGNUP_NUMBER_OTP, VERIFY_SIGNUP_NUMBER_OTP, GENERATE_SIGNUP_EMAIL_OTP, VERIFY_SIGNUP_EMAIL_OTP,
  USER_SOCIAL_MEDIA_LOGIN_CHAMP, LOG_ACTIVITY, GET_USER_INTERESTS, GET_CREATED_COUPONS, DELETE_COUPON, EDIT_COUPON, UPDATE_COUPON,
  CREATE_PAST_EVENT_BASIC_INFO, CREATE_PAST_EVENT_PITCH, CREATE_PAST_EVENT_ORGANIZER, DELETE_EVENT, PAST_EVENT_DETAIL, GET_MY_PAST_EVENTS,
  GET_MYEVENT_TTRANSACTION, GET_MEMBERSHIP_PLANS, PUBLISHED_EVENT, GET_USERS_FOR_CHAMP, USER_LOGOUT_API, MEMBERSHIP_PLAN,
  UPDATE_MEMBERSHIP_PAYMENT_RESPONSE, FEATURED_EVENT, FEATURED_EVENT_PRICE, FEATURED_PAYMENT_RESPONSE,
  PITCH_IMAGE_DOCUMENT_DLT, MEMBERSHIP_TRANSACTIONS, GET_NOTIFICATION_LIST, GET_LANGUAGES_LIST,
  GET_HOME_AUTH_EVENT, GET_CITY_LIST, GET_REJECT_DATA, SEND_REJECTED_MSG, CHECK_SOCIAL_MEDIA_USER,
  TESTIMONIAL_DELETE, GET_LANGUAGE_LIST, GET_INTERESTS, CREATE_INTERESTS, SUPPORT_TICKET, GET_REQUEST_MESSAGES,
  GET_MESSAGES, SEND_MESSAGE, USER_CHANGE_PASSWORD, DELETE_SUPPORT_TICKET, SEARCH_REQUEST, SET_ARCHiVE,
  MANAGE_REQUEST_DELETE_CHAT, FORGOT_PASSWORD, GET_ABOUT_ME, EVENT_HOST, GET_GUEST_DATA_LIST, GET_ONLINE_MODE_LIST,
  CREATE_EVENT_ESSENTIALS, EVENT_SPONSOR_PACKAGE, CREATE_EVENT_GUEST_TAB, CREATE_EVENT_PITCH_TAB, GET_EVENT_ESSENTIALS_TYPE,
  CREATE_EVENT_SOCIAL_HUB_TAB, GET_PARTNERSHIPS_SERVICE_PROVIDER, CREATE_EVENT_PARTNERSHIP_TAB,
  REFUND_CHARGE,
  EDIT_EVENT_ESSENTIALS,
  GET_EDIT_EVENT,
  GET_EDIT_ESSENTIALS,
  GET_EDIT_GUEST,
  GET_EDIT_PITCH,
  GET_EDIT_HOST,
  GET_EDIT_PARTERSHIP,
  GET_EDIT_SPONSOR_PACKAGE,
  GET_EDIT_SOCIAL_HUB,
  GET_EDIT_CANCEL_REFUND,
  GET_EVENT_REFERENCE,
  EVENT_MEDIA_HUB,
  GET_SPONSOR_USERS,
  EVENT_REFERENCE,
  PUBLISH_EVENT,
  UNPUBLISH_EVENT,
  GET_EDIT_MEDIA_HUB,
  UPDATE_SPONSOR_PACKAGE,
  GET_EDIT_PUBLISHED_DATA,
  GET_PACKAGES_LIST,
  GET_SINGLE_PARTERSHIP,
  GET_PARTERSHIP_PARTNERS,
  UPDATE_PARTNERSHIP_PARTNER,
  DELETE_PARTERSHIP_PARTNERS,
  EMAIL_FORGOT_PASSWORD,
  EMAIL_FORGOT_VERIFY_OTP,
  MOBILE_FORGOT_PASSWORD,
  VERIFY_FORGOT_NUMBER_OTP,
  PROFILE_DETAIL,
  GET_HOUSEHOLD_INCOME,
  GET_EDUCATION_LIST,
  EVENT_SPONSOR_TRANSACTION,
  GET_MARK_AS_COMPLETE,
  MARK_AS_COMPLETE_DETAIL,
  CREATE_PASTEVENT_ESSENTIALS,
  CREATE_PASTEVENT_GUEST,
  PASTEVENT_PITCH,
  CREATE_PASTEVENT_HOST,
  CREATE_PASTEVENT_PARTNERSHIP,
  CREATE_PASTEVENT_SPONSORPACKAGE,
  CREATE_PASTEVENT_MEDIAHUB,
  CREATE_PASTEVENT_SOCIALHUB,
  CREATE_NEWS_LETTER,
  GET_ARCHIVE_EVENT_LIST,
  GET_PASTEVENT_SOCIALHUB,
  GET_PASTEVENT_MEDIAHUB,
  GET_PASTEVENT_SPONSORPACKAGE,
  GET_PASTEVENT_PARTNERSHIPS,
  GET_PASTEVENT_HOST,
  GET_PASTEVENT_PITCH,
  GET_PASTEVENT_GUEST,
  GET_PASTEVENT_ESSENTIALS,
  PASTEVENT_UPDATE_ESSENTIALS,
  PASTEVENT_MEDIA_IMAGE_DOCUMENT_DLT,
  GET_PASTEVENT_SPONSOR_PACKAGE,
  PAST_EVENT_UPDATE_SPONSOR_PACKAGE,
  GET_EVENT_BUDGET,
  PASTEVENT_PACKAGE_DELETE,
  DELETE_BANNER,
  GET_LOGIN_ACTIVITY_MSG,
  GET_PROFESSIONS,
  CONFIRMED_SPONSOR_DELETE,
  CONFIRMED_SPONSOR_UPDATE,
  GET_SINGLE_CONFIRMED_SPONSOR,
  PASTEVENT_BANNER_DELETE,
  PAST_EVENT_CONFIRMED_SPONSOR_DELETE,
  PAST_EVENT_GET_SINGLE_CONFIRMED_SPONSOR,
  PAST_EVENT_CONFIRMED_SPONSOR_UPDATE,
  PARTNER_WITH_HOST,
  PARTNER_WITH_SPONSOR,
  CREATE_EVENT_SCHEDULE,
  GET_EVENT_SCHEDULE,
  EVENT_SCHEDULE_UPLOAD_IMAGE,
  EVENT_SCHEDULE_IMAGE_DELETE,
  GET_HOST_ALL_EVENTS,
  GET_HOST_ALL_PAST_EVENTS,
  FEATURED_EVENT_LIST,
  FEATURED_SELECTED_DATE,
  CHECK_ADMIN,
  ADD_REASON_FOR_REJECTION,
  EXPRESS_EVENT_ESSENTIALS,
  EXPRESS_EVENT_GUEST,
  GET_EXPRESS_SPONSOR_PACKAGE,
  EXPRESS_EVENT_PITCH,
  EXPRESS_SPONSOR_PACKAGE,
  EXPRESS_EVENT_MEDIA_HUB,
  GET_EXPRESS_EVENT_MEDIA_HUB,
  EXPRESS_SPONSOR_DETAILS_UPDATE,
  EXPRESS_SPONSOR_PACKAGE_UPDATE,
  GET_EXPRESS_EVENT_ESSENTIALS,
  EXPRESS_EVENT_ESSENTIALS_UPDATE,
  GET_EVENT_BENEFIT,
  EXPRESS_EVENT_ADD_REASON_FOR_REJECTION,
  NEAR_BY_CUTTOFF_DATE,
  DELETE_PAST_EVENT,
  PAST_EVENT_ADD_REASON_FOR_REJECTION,
} from "./RequestUrl";
import Axiosapi from "./AxiosInterceptor";
import { isTokenValid } from "../AuthbyToken";

export const getBannerList = async () => {
  try {
    const response = await Axiosapi.get(`${BANNER_URL}`);
    return response;
  } catch (error) {
    console.log("error", error);
  }
};


// Login Register
export const userLogin = async (user) => {
  try {
    const response = await axios.post(`${BASE_URL}${USER_LOGIN_URL}`, user, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw error
  }
};

export const userRegister = async (user) => {
  try {
    const response = await axios.post(`${BASE_URL}${USER_REGISTER_URL}`, user, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  } catch (error) {
    throw error
  }
}

export const ChampRegister = async (champ_FD) => {
  try {
    const response = await axios.post(`${BASE_URL}${CHAMP_REGISTER_URL}`, champ_FD, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  } catch (error) {
    throw error
  }
}

// Basic Information
export const getEventType = async () => {
  try {
    const response = await Axiosapi.get(`${GET_EVENT_TYPE}`);
    return response;
  } catch (error) {
    console.log("error", error);
  }
}

export const getEventTopic = async () => {
  try {
    const response = await Axiosapi.get(`${GET_EVENT_TOPIC}`);
    return response;
  } catch (error) {
    console.log("error", error);
  }
}


export const getEventSubTopic = async () => {
  try {
    const response = await Axiosapi.get(`${GET_EVENT_SUB_TOPIC}`);
    return response;
  } catch (error) {
    console.log("error", error);
  }
}

export const getEventPeopleRange = async () => {
  try {
    const response = await Axiosapi.get(`${GET_EVENT_PEOPLE_RANGE}`);
    return response;
  } catch (error) {
    console.log("error", error);
  }
}

export const getEventsCreate = async (info) => {
  try {
    const response = await Axiosapi.post(`${GET_EVENTS_CREATE}`, info, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}


//Packages Form
export const getPredefinePackage = async () => {
  try {
    const response = await Axiosapi.get(`${GET_PREDEFINE_PACKAGE}`);
    return response;
  } catch (error) {
    console.log("error", error);
  }
}

export const createPackageEvent = async (info, event_id) => {
  try {
    const response = await Axiosapi.post(`${CREATE_EVENT_PACKAGES}/${event_id}`, info, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw (error);
  }
}

export const EventPackageListData = async (event_id) => {
  try {
    const response = await Axiosapi.get(`${GET_CREATE_PACKAGES_LIST}/${event_id}`);
    return response;
  }
  catch (error) {
    console.log("error", error)
  }
}




// pitch Form 

export const createPitchform = async (info, event_id) => {
  try {
    const response = await Axiosapi.post(`${PITCH_FORM}/${event_id}`, info, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw (error);
  }
}

// organizer pitch Form 

export const createOrganizerPitchform = async (info, event_id) => {
  try {
    const response = await Axiosapi.post(`${ORGANIZER_PITCH_FORM}/${event_id}`, info, {
      // headers: {
      //   "Content-Type": "text/html; charset=UTF-8",
      // },
    });
    return response;
  } catch (error) {
    throw error;
  }
}


// my event 
// Published?page=&latitude&longitude&search&sort_by&event_mode&event_type&min&max

export const getmyevent = async (event_status, eventMode, eventType, eventRange, curr_page, search, sortVal, lat_long, eventCategory, status, hastgg, no_Participants,
  srt_Evt_Date, selectedGenders, minMaxAge, distanceRange, prime_host, cityS, languages, SponsorshipClosingDate, durationValue, premier, archive_Type, selectHouse_Hold_Income,
  selectEducationValue, eventBudgetValue, guestAge, genderGroup, guestHouseIncome, guestEducation, guestMaritalStatus, guestProfession, guestIndustry, guestTechnologyProficiency, guestSocialMediaUsage) => {
  try {
    const response = await Axiosapi.get(`${GET_MY_EVENT}?page=${curr_page}&latitude=${lat_long.lat}&longitude=${lat_long.lng}&search=${search}&sort_by=${sortVal}
    &event_mode=${eventMode}&event_type=${eventType}&min=${eventRange.min}&max=${eventRange.max}&status=${event_status}&e_status=${status}&event_time_category=${eventCategory}
    &hash_tags=${hastgg}&max_participants=${no_Participants?.max}&min_participants=${no_Participants?.min}&start_date=${srt_Evt_Date?.start}&end_date=${srt_Evt_Date?.end}
    &gender=${selectedGenders}&min_age=${minMaxAge?.min}&max_age=${minMaxAge?.max}&distance_range=${distanceRange}&prime_host=${prime_host}&location=${cityS}&languages=${languages}
    &package_start_date=${SponsorshipClosingDate?.start}&package_end_date=${SponsorshipClosingDate?.end}&duration=${durationValue}&premire_host=${premier}&sponsored=${archive_Type}
    &house_hold_income=${guestHouseIncome}&education=${guestEducation}&total_budget_id=${eventBudgetValue}&age=${guestAge}&guest_gender=${genderGroup}&marital_status=${guestMaritalStatus}
    &profession=${guestProfession}&industry=${guestIndustry}&technology_proficiency=${guestTechnologyProficiency}&social_media_usage=${guestSocialMediaUsage}`);
    return response;
  }
  catch (error) {
    console.log("error", error)
  }
}

export const DeleteEvent = async (id) => {
  try {
    const response = await Axiosapi.get(`${DELETE_EVENT}/${id}`);
    return response;
  }
  catch (error) {
    throw error
  }
}

// event detail 
export const eventdatail = async (id) => {
  try {
    const response = await Axiosapi.get(`${EVENT_DETAIL}/${id}`);
    return response;
  }
  catch (error) {
    console.log("error", error)
  }
}

export const getSponsor = async () => {
  try {
    const response = await Axiosapi.get(`${GET_SPONSOR}`);
    return response;
  }
  catch (error) {
    console.log("error", error)
  }
}

// all confing data

export const getConfigData = async () => {
  try {
    const response = await Axiosapi.get(`${GET_CONFIG_DATA}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

export const getProfile = async () => {
  try {
    const response = await Axiosapi.get(`${GET_PROFILE}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

// get event list
export const getEventList = async (categories, eventMode, search, sort, range, eventType, curr_page, let_log, eventCategory, status, hastag, no_Participants, srt_Evt_Date, minMaxAge,
  distanceRange, prime_host, city, selectedGenders, languages, SponsorshipClosingDate, durationValue, premier, selectHouse_Hold_Income, selectEducationValue, category,
  eventBudgetValue, user_id, starRating, guestAge, genderGroup, guestHouseIncome, guestEducation, guestMaritalStatus, guestProfession, guestIndustry, guestTechnologyProficiency, guestSocialMediaUsage, host_id) => {
  try {
    const response = await Axiosapi.get(`${category == 'archivedevents' ? GET_ARCHIVE_EVENT_LIST : GET_EVENT_LIST}?search=${search}&min=${range.min}&max=${range.max}&event_type=${eventType}&event_mode=${eventMode}&page=${curr_page}
    &event_category=${categories}&sort_by=${sort}&latitude=${let_log?.lat}&longitude=${let_log?.lng}&e_status=${status}&event_time_category=${eventCategory}&hash_tags=${hastag}
    &max_guest=${no_Participants?.max}&min_guest=${no_Participants?.min}&start_date=${srt_Evt_Date?.start}&end_date=${srt_Evt_Date?.end}&min_age=${minMaxAge?.min}
    &max_age=${minMaxAge?.max}&distance_range=${distanceRange}&prime_host=${prime_host}&location=${city}&gender=${selectedGenders}&languages=${languages}&package_start_date=${SponsorshipClosingDate?.start}
    &package_end_date=${SponsorshipClosingDate?.end}&duration=${durationValue}&premire_host=${premier}&house_hold_income=${guestHouseIncome}&education=${guestEducation}&total_budget_id=${eventBudgetValue}
    &user_id=${user_id}&host_rating=${starRating}&age=${guestAge}&guest_gender=${genderGroup}&marital_status=${guestMaritalStatus}&profession=${guestProfession}&industry=${guestIndustry}
    &technology_proficiency=${guestTechnologyProficiency}&social_media_usage=${guestSocialMediaUsage}&host_id=${host_id}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

//edit event 
export const EditEvent = async (id) => {
  try {
    const response = await Axiosapi.get(`${EDIT_EVENT}/${id}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

// update event 
export const UpdateEvent = async (id, value) => {
  try {
    const response = await Axiosapi.post(`${UPDATE_EVENT}/${id}`, value, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    return response
  }
  catch (error) {
    console.log(error)
  }
}

// all evnts 
export const AuthGetHomeEvents = async (latlong) => {
  try {
    const response = await Axiosapi.get(`${GET_HOME_AUTH_EVENT}?lat=${latlong.lat}&long=${latlong.lng}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

export const GetHomeEvents = async (latlong) => {
  try {
    const response = await Axiosapi.get(`${GET_HOME_EVENT}?lat=${latlong.lat}&long=${latlong.lng}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

// GET_INTERESTS
export const GetInterests = async () => {
  try {
    const response = await Axiosapi.get(`${GET_USER_INTERESTS}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

// get my event type
export const GetMyEventType = async () => {
  try {
    const response = await Axiosapi.get(`${MY_EVENT_TYPES}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

// edit Intrest type
export const EditInterest = async (value) => {
  try {
    const response = await Axiosapi.post(`${EDIT_INTEREST_EVENT_TYPES}`, value, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    return response
  }
  catch (error) {
    throw error
  }
}

// get testimonial 

export const GetTestimonial = async () => {
  try {
    const response = await Axiosapi.get(`${GET_TESTIMONIAL}`)
    return response
  }
  catch (error) {
    throw error
  }
}

// post testimonial
export const PostTestimonial = async (value) => {
  try {
    const response = await Axiosapi.post(`${POST_TESTIMONIAL}`, value)
    return response
  }
  catch (error) {
    throw error
  }
}

// Post Query
export const PostQuery = async (id, discription) => {
  try {
    const response = await Axiosapi.post(`${POST_QUERY}/${id}`, discription)
    return response
    // get testimonial list data
  }
  catch (error) {
    throw error
  }
}

// get testimoonial data list with rating or testimonial name
export const GetTestimonialDataList = async (val, starRating, lateOlde, currentPage) => {
  try {
    const response = await Axiosapi.get(`${GET_TESTIMONIAL_LIST_RATING_NAME}?rating=${starRating}&type=${val}&sort_by=${lateOlde}&page=${currentPage}`)
    return response
  }
  catch (error) {
    throw error
  }
}

// get only testimonial Data list
export const GetOnlyTestimonialDataList = async () => {
  try {
    const response = await Axiosapi.get(`${GET_ONLY_TESTIMONIAL_LIST}`)
    return response
  }
  catch (error) {
    throw error
  }
}


// get request 
export const GetManageRequest = async (tabValue, searchValue, isArchiveChecked) => {
  try {
    const response = await Axiosapi.get(`${GET_MANAGE_REQUEST}/${tabValue}?search=${searchValue}&archive=${isArchiveChecked}`)
    return response
  }
  catch (error) {
    throw error
  }
}


// REQUEST MSG SEND
export const MReqMessageSend = async (id, msg) => {
  try {
    const response = await Axiosapi.post(`${MANAGE_REQ_MESAGE_SEND}/${id}`, msg)
    return response
    // get testimonial list data
  }
  catch (error) {
    throw error
  }
}

//get meesage
export const Get_Message = async (id, dateFilterValue) => {
  try {
    const response = await Axiosapi.get(`${GET_MESSAGE}/${id}?filter=${dateFilterValue}`)
    return response
  }
  catch (error) {
    throw error
  }
}

// event Review post 

export const EventReview = async (value) => {
  try {
    const response = await Axiosapi.post(`${EVENT_REVIEW}`, value, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
    return response
  }
  catch (error) {
    throw error
  }
}


// event Review get 
export const getEventReview = async (id) => {
  try {
    const response = await Axiosapi.get(`${GET_EVENT_REVIEW}/${id}`)
    return response
  }
  catch (error) {
    throw error
  }
}

//get Profile Predefine List 


export const getPredefineProfile = async () => {
  try {
    const response = await Axiosapi.get(GET_PREDEFINE_LIST)
    return response
  }
  catch (error) {
    throw error
  }
}
//get profile editDAtalist
export const getProfileEditDataList = async (id) => {
  try {
    const response = await Axiosapi.get(`${GET_EDIT_PROFILE_LIST}/${id}`)
    return response
  }
  catch (error) {
    throw error
  }
}
//post profile update


export const ProfileUpdateDataList = async (value, id) => {
  try {
    const response = await Axiosapi.post(`${POST_UPDATE_PROFILE}/${id}`, value, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
    return response
  }
  catch (error) {
    throw error
  }
}

// get city state country

export const CityStateCountry = async () => {
  try {
    const response = await Axiosapi.get(GET_CITY_STATE_COUNTRY)
    return response
  }
  catch (error) {
    throw error
  }
}

// get coupons
export const GetCoupons = async (id) => {
  try {
    const response = await Axiosapi.get(`${GET_COUPONS}/${id}`)
    return response
  }
  catch (error) {
    throw error
  }
}

// create coupons
export const CreateCoupon = async (data, id) => {
  try {
    const response = await Axiosapi.post(`${CREATE_COUNPON}/${id}`, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return response
  }
  catch (error) {
    throw error
  }
}

// get package list
export const getPackageList = async (id) => {
  try {
    const response = await Axiosapi.get(`${GET_PACKAGES_LIST}/${id}`)
    return response
  }
  catch (error) {
    throw error
  }
}

// apply coupons
export const ApplyCoupon = async (couponFD) => {
  try {
    const response = await Axiosapi.post(`${APPLY_COUPON}`, couponFD, {
      headers: {
        "Content-Type": "multipart/form-data",
        // 'Content-Type': 'application/json'
      }
    })
    return response
  }
  catch (error) {
    throw error
  }
}

// get apply coupons
export const Get_Apply_Coupon = async (id) => {
  try {
    const response = await Axiosapi.get(`${GET_APPLY_COUPON}/${id}`)
    return response
  }
  catch (error) {
    throw error
  }
}

// event_transaction
export const EventTransaction = async (createOrderIdFD) => {
  // console.log("co",createOrderIdFD)
  try {
    const response = await Axiosapi.post(`${EVENT_TRANSACTION}`, createOrderIdFD, {
      headers: {
        // "Content-Type": "multipart/form-data",
        'Content-Type': 'application/json'
      }
    })
    return response
  }
  catch (error) {
    throw error
  }
}

// payment
export const Update_Payment = async (payFD) => {
  try {
    const response = await Axiosapi.post(`${UPDATE_PAYMENT}`, payFD, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
    return response
  }
  catch (error) {
    throw error
  }
}


// packages form get list api for edit 
export const getPackagesFormEdit = async (id) => {
  try {
    const response = await Axiosapi.get(`${GET_PACKAGES_FORM_EDIT}/${id}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

// packages form post list api for edit 

export const postpackageFormEdit = async (value, id) => {
  try {
    const response = await Axiosapi.post(`${GET_POST_PACKAGES_FORM_EDIT}/${id}`, value, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
    return response
  }
  catch (error) {
    throw error
  }
}

// get pitch data list for edit event

export const getPitchFormEdit = async (id) => {
  try {
    const response = await Axiosapi.get(`${GET_PITCH_FORM_EDIT}/${id}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

export const postPitchFormEdit = async (value, id) => {
  try {
    const response = await Axiosapi.post(`${GET_POST_PITCH_FORM_EDIT}/${id}`, value, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
    return response
  }
  catch (error) {
    throw error
  }
}

//get oraganizer list api
export const getOrganizerFormEdit = async (id) => {
  try {
    const response = await Axiosapi.get(`${GET_ORGANIZER_FORM_EDIT}/${id}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

// organizer update or edit data
export const postOrganizerFormEdit = async (value, id) => {
  try {
    const response = await Axiosapi.post(`${GET_POST_ORGANIZER_FORM_EDIT}/${id}`, value, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
    return response
  }
  catch (error) {
    throw error
  }
}

// get order detail
export const GetOrderDetail = async (currentPage, sortValue, searchValue, statusValue) => {
  try {
    const response = await Axiosapi.get(`${GET_ORDER_DETAIL}?page=${currentPage}&search=${searchValue ? searchValue : ''}&sort_by=${sortValue}&status=${statusValue}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

// get_my_event_transaction
export const GetMyEventTransaction = async (currentPage, sortValue, searchValue, statusValue) => {
  try {
    const response = await Axiosapi.get(`${GET_MYEVENT_TTRANSACTION}?page=${currentPage}&search=${searchValue ? searchValue : ''}&sort_by=${sortValue}&status=${statusValue}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

// event packages Delete api 
export const eventPackageDlt = async (package_id) => {
  try {
    const response = await Axiosapi.delete(`${EVENT_PACKAGE_DELETE}/${package_id}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

//Get champ dashboard
export const GetChampDashboard = async () => {
  try {
    const response = await Axiosapi.get(`${GET_CHAMP_DASHBOARD}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

// get champ refer user list
export const getChampReferListData = async (submitSearchValue, sortValue, currentPage, dateFilterValue) => {
  try {
    const response = await Axiosapi.get(`${GET_CHAMP_REFER_USER_LIST}?sort_by=${sortValue}&search=${submitSearchValue}&page=${currentPage}&filter=${dateFilterValue}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}


//get champ host List data
export const getChamphostListData = async (submitSearchValue, sortValue, currentPage, dateFilterValue) => {
  try {
    const response = await Axiosapi.get(`${GET_CHAMP_DATA_LIST}?sort_by=${sortValue}&search=${submitSearchValue}&page=${currentPage}&filter=${dateFilterValue}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

// get champs host Events List
export const getChampHostEventsList = async (params, eventMode, eventType, eventRange, categories, currentPage, search, sortVal, lat_long, eventCategory, status) => {
  console.log(sortVal)
  try {
    // const response = await Axiosapi.get(`${GET_CHAMP_EVENTS_LIST}/${params}`)
    const response = await Axiosapi.get(`${GET_CHAMP_EVENTS_LIST}/${params}?category=${categories}&page=${currentPage}&latitude=${lat_long.lat}&longitude=${lat_long.lng}&search=${search}&sort_by=${sortVal}&event_mode=${eventMode}&event_type=${eventType}&min=${eventRange.min}&max=${eventRange.max}&status=&e_status=${status}&event_time_category=${eventCategory}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

//create past event data
export const CreatePastEventGallery = async (id, Fdata) => {
  try {
    const response = await Axiosapi.post(`${CREATE_PAST_EVENT_GALLERY}/${id}`, Fdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
    return response
  }
  catch (error) {
    throw error
  }
}

// get champ list
export const GetChamps = async (id) => {
  try {
    const response = await Axiosapi.get(`${GET_CHAMPS_LIST}`)
    return response
  }
  catch (error) {
    throw error
  }
}

//change champ
export const ChangeChamp = async (Fdata) => {
  try {
    const response = await Axiosapi.post(`${CHANGE_CHAMP}`, Fdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
    return response
  }
  catch (error) {
    throw error
  }
}


// genrate email  otp
export const postEmailVerifyOtp = async (val) => {
  try {
    const response = await Axiosapi.post(`${EMAIL_VERIFY_OTP}`, val, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

// email verification

export const emailOtpVerifyFinal = async (val) => {
  try {
    const response = await Axiosapi.post(`${VERIFY_EMAIL_OTP}`, val, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

//  generate phone otp 

export const numberGenerateOtp = async (val) => {
  try {
    const response = await Axiosapi.post(`${NUMBER_GENERATE_OTP}`, val, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

// verification phone

export const numberOtpVerifyFinal = async (val) => {
  try {
    const response = await Axiosapi.post(`${NUMBER_OTP_VERIFY}`, val, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}



// user Social Media Login api 

export const userSocialMediaLogin = async (val) => {
  try {
    const response = await Axiosapi.post(`${USER_SOCIAL_MEDIA_LOGIN}`, val, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

// userSocialMediaLoginChamp
export const userSocialMediaLoginChamp = async (val) => {
  try {
    const response = await Axiosapi.post(`${USER_SOCIAL_MEDIA_LOGIN_CHAMP}`, val, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

// update email
export const UpdateEmail = async (fdata) => {
  try {
    const response = await Axiosapi.post(`${UPDATE_EMAIL}`, fdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

// verify change email otp
export const VerifyChangeEmail = async (fdata) => {
  try {
    const response = await Axiosapi.post(`${VERIFY_CHANGE_EMAIL}`, fdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}


// NNN
// update email
export const UpdatePhone = async (fdata) => {
  try {
    const response = await Axiosapi.post(`${CHANGE_NUMBER}`, fdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

// verify change email otp
export const VerifyChangePhone = async (fdata) => {
  try {
    const response = await Axiosapi.post(`${VERIFY_CHANGE_NUMBER}`, fdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

// change_username_otp 
export const UpdateUserName = async (fdata) => {
  try {
    const response = await Axiosapi.post(`${CHANGE_USERNAME}`, fdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

// verify_change_username_otp
export const VerifyUpdateUserName = async (fdata) => {
  try {
    const response = await Axiosapi.post(`${VERIFY_CHANGE_USERNAME}`, fdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

// get_sponsor_list_for_champ
export const GetSponsorListForChamp = async (submitSearchValue, sortValue, currentPage, dateFilterValue) => {
  try {
    const response = await Axiosapi.get(`${GET_SPONSOR_LIST_FOR_CHAMP}?sort_by=${sortValue}&search=${submitSearchValue}&page=${currentPage}&filter=${dateFilterValue}`)
    return response
  }
  catch (error) {
    throw error
  }
}

// get_sponsor_list_for_champ
export const GetTransctionsListForChamp = async (submitSearchValue, statusValue, sortValue, currentPage) => {
  try {
    const response = await Axiosapi.get(`${GET_EVENT_TRANSACTIONS_LIST_FOR_CHAMP}?sort_by=${sortValue}&search=${submitSearchValue}&status=${statusValue}&page=${currentPage}`)
    return response
  }
  catch (error) {
    throw error
  }
}

// get_sponsor_events_for_champ
export const GetSponsorEventsForChamp = async (id, searchValue, statusValue, sortValue, currentPage) => {
  try {
    const response = await Axiosapi.get(`${GET_SPONSOR_EVENTS_FOR_CHAMP}/${id}?search=${searchValue ? searchValue : ''}&sort_by=${sortValue}&page=${currentPage}&status=${statusValue}`)
    return response
  }
  catch (error) {
    throw error
  }
}

// get avatar 
export const GetUserAvatar = async () => {
  try {
    const response = await Axiosapi.get(`${GET_AVATAR}`)
    return response
  }
  catch (error) {
    throw error
  }
}

// signup generate phone otp 

export const SignupNumberGenerateOtp = async (val) => {
  try {
    const response = await Axiosapi.post(`${GENERATE_SIGNUP_NUMBER_OTP}`, val, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

//signup verification phone

export const SignupNumberOtpVerifyFinal = async (val) => {
  try {
    const response = await Axiosapi.post(`${VERIFY_SIGNUP_NUMBER_OTP}`, val, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

// signup generate email otp 
export const SignupEmailGenerateOtp = async (val) => {
  try {
    const response = await Axiosapi.post(`${GENERATE_SIGNUP_EMAIL_OTP}`, val, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

//signup verification email
export const SignupEmailOtpVerifyFinal = async (val) => {
  try {
    const response = await Axiosapi.post(`${VERIFY_SIGNUP_EMAIL_OTP}`, val, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}


// get ip address log activity 3rd party api
export const system_ip = async () => {
  try {
    const response = await Axiosapi.get("https://ipv4.jsonip.com/")
    return response
  }
  catch (error) {
    throw error
  }
}

// pass ip address log activity 3rd party api

export const pass_system_ip = async (ip) => {
  try {
    const response = await Axiosapi.get(`https://ipapi.co/${ip}/json/`)
    return response
  }
  catch (error) {
    throw error
  }
}

// log activity local api
export const check_log_activity = async (val) => {
  try {
    const response = await Axiosapi.post(`${LOG_ACTIVITY}`, val, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

// get GET_USER_INTERESTS
export const GetUserInterest = async () => {
  try {
    const response = await Axiosapi.get(`${GET_USER_INTERESTS}`)
    return response;
  } catch (error) {
    throw error;
  }
}

// get Created Coupon_list 
export const CreatedCouponList = async (id) => {
  try {
    const response = await Axiosapi.get(`${GET_CREATED_COUPONS}/${id}`)
    return response;
  } catch (error) {
    throw error;
  }
}

// detele created coupon
export const Delete_Coupon_Delete = async (id) => {
  try {
    const response = await Axiosapi.delete(`${DELETE_COUPON}/${id}`)
    return response;
  } catch (error) {
    throw error;
  }
}

// get single Edit Coupon
export const editCouponData = async (id) => {
  try {
    const response = await Axiosapi.get(`${EDIT_COUPON}/${id}`)
    return response;
  } catch (error) {
    throw error;
  }
}

// Update coupon Data
export const updateCouponData = async (couponFD, id) => {
  try {
    const response = await Axiosapi.post(`${UPDATE_COUPON}/${id}`, couponFD, {
      headers: {
        "Content-Type": "application/json",

      }
    })
    return response
  }
  catch (error) {
    throw error
  }
}

// create past event basicinformation
export const CreatePastevent = async (data) => {
  try {
    const response = await Axiosapi.post(`${CREATE_PAST_EVENT_BASIC_INFO}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
    return response
  }
  catch (error) {
    throw error
  }
}

// CreatePast_eventpitch  create past event pitch  

export const CreatePast_eventpitch = async (data, id) => {
  try {
    const response = await Axiosapi.post(`${CREATE_PAST_EVENT_PITCH}/${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
    return response
  }
  catch (error) {
    throw error
  }
}

// CreatePast_eventorganizer create past event organizer  
export const CreatePast_eventorganizer = async (data, id) => {
  try {
    const response = await Axiosapi.post(`${CREATE_PAST_EVENT_ORGANIZER}/${id}`, data)
    return response
  }
  catch (error) {
    throw error
  }
}

export const get_my_past_events = async (eventMode, event_status, eventType, currentPage, search_event, sortVal, eventCategory, status,no_Participants,
  eventBudgetValue,srt_Evt_Date,durationValue,cityS,languages,SponsorshipClosingDate,distanceRange,hastgg,prime_host,premier) => {
  try {
    const response = await Axiosapi.get(`${GET_MY_PAST_EVENTS}?page=${currentPage}&search=${search_event}&sort_by=${sortVal}
    &event_mode=${eventMode}&event_type=${eventType}&status=${event_status}&e_status=${status}&event_time_category=${eventCategory}
    &max_participants=${no_Participants?.max}&min_participants=${no_Participants?.min}&total_budget_id=${eventBudgetValue}&start_date=${srt_Evt_Date?.start}
    &end_date=${srt_Evt_Date?.end}&duration=${durationValue}&location=${cityS}&languages=${languages}&package_start_date=${SponsorshipClosingDate?.start}
    &package_end_date=${SponsorshipClosingDate?.end}&distance_range=${distanceRange}&hash_tags=${hastgg}&prime_host=${prime_host}&premire_host=${premier}`);
    return response;
  }
  catch (error) {
    console.log("error", error);
  }
}

// past Event detail page
export const PastEventDetail = async (id) => {
  try {
    const response = await Axiosapi.get(`${PAST_EVENT_DETAIL}/${id}`);
    return response;
  }
  catch (error) {
    console.log("error", error)
  }
}

// past Event detail page
export const GetMembershipPlans = async (type) => {
  try {
    const response = await Axiosapi.get(`${GET_MEMBERSHIP_PLANS}?type=${type}`);
    return response;
  }
  catch (error) {
    console.log("error", error)
  }
}

// CreatePast_eventorganizer create past event organizer  
export const PublishedEvent = async (fdata) => {
  try {
    const response = await Axiosapi.post(`${PUBLISHED_EVENT}`, fdata)
    return response
  }
  catch (error) {
    throw error
  }
}

// get user for champ
export const GetUsersForChamp = async () => {
  try {
    const response = await Axiosapi.get(`${GET_USERS_FOR_CHAMP}`);
    return response;
  }
  catch (error) {
    console.log("error", error)
  }
}

//user logout api user_log_out  user_log_out
export const user_log_out = async (data) => {
  try {
    const response = await Axiosapi.post(`${USER_LOGOUT_API}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  }
  catch (error) {
    console.log("error", error)
  }
}

export const MembershipPlan = async (fData) => {
  try {
    const response = await Axiosapi.post(`${MEMBERSHIP_PLAN}`, fData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  }
  catch (error) {
    throw error
  }
}

export const UpdateMembershipPayment = async (fData) => {
  try {
    const response = await Axiosapi.post(`${UPDATE_MEMBERSHIP_PAYMENT_RESPONSE}`, fData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  }
  catch (error) {
    throw error
  }
}

// FeaturedEvent
export const FeatureEventPrice = async () => {
  try {
    const response = await Axiosapi.get(`${FEATURED_EVENT_PRICE}`);
    return response;
  }
  catch (error) {
    throw error
  }
}


export const FeaturedEvent = async (fData) => {
  try {
    const response = await Axiosapi.post(`${FEATURED_EVENT}`, fData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  }
  catch (error) {
    throw error
  }
}

export const FeaturedPaymentResponse = async (fData) => {
  try {
    const response = await Axiosapi.post(`${FEATURED_PAYMENT_RESPONSE}`, fData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  }
  catch (error) {
    throw error
  }
}


// event pitch image docs delete
export const evenImageDocsDlt = async (id) => {
  try {
    const response = await Axiosapi.delete(`${PITCH_IMAGE_DOCUMENT_DLT}${id}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

// https://ringus.idea2reality.tech/api/v1/membership_plans/get_membership_transactions
// FeaturedEvent
export const MembershipTransactions = async () => {
  try {
    const response = await Axiosapi.get(`${MEMBERSHIP_TRANSACTIONS}`);
    return response;
  }
  catch (error) {
    throw error
  }
}


//get notification data list
export const getNotificationList = async (currentPage) => {
  try {
    const response = await Axiosapi.get(`${GET_NOTIFICATION_LIST}?page=${currentPage}`);
    return response;
  }
  catch (error) {
    throw error
  }
}

//get languages list multi select
export const getLanguagesList = async () => {
  try {
    const response = await Axiosapi.get(`${GET_LANGUAGES_LIST}`);
    return response;
  }
  catch (error) {
    console.log("error", error)
  }
}

export const getCityList = async () => {
  try {
    const response = await Axiosapi.get(`${GET_CITY_LIST}`);
    return response;
  }
  catch (error) {
    console.log("error", error)
  }
}

export const getLanguageList = async () => {
  try {
    const response = await Axiosapi.get(`${GET_LANGUAGE_LIST}`);
    return response;
  }
  catch (error) {
    console.log("error", error)
  }
}


// get reject Data
export const GetRejectData = async (id) => {
  try {
    const response = await Axiosapi.get(`${GET_REJECT_DATA}/${id}`);
    return response;
  }
  catch (error) {
    console.log("error", error)
  }
}

// send rejected  data
export const SendRejectData = async (id, input) => {
  try {
    const response = await Axiosapi.post(`${SEND_REJECTED_MSG}/${id}`, input, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  }
  catch (error) {
    throw error
  }
}


// check_social_media_user
export const CheckSocialMediaUser = async (fdata) => {
  try {
    const response = await Axiosapi.post(`${CHECK_SOCIAL_MEDIA_USER}`, fdata, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  }
  catch (error) {
    throw error
  }
}

// delete Testimonial 

export const Dlt_testimonial = async (id) => {
  try {
    const response = await Axiosapi.delete(`${TESTIMONIAL_DELETE}/${id}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

// get reject Data
export const Get_interests = async () => {
  try {
    const response = await Axiosapi.get(`${GET_INTERESTS}`);
    return response;
  }
  catch (error) {
    console.log("error", error)
  }
}

// create interest
export const Create_Interest = async (fData) => {
  try {
    const response = await Axiosapi.post(`${CREATE_INTERESTS}`, fData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  }
  catch (error) {
    throw error
  }
}

// Support_Ticket
export const Support_Ticket = async (fData) => {
  try {
    const response = await Axiosapi.post(`${SUPPORT_TICKET}`, fData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  }
  catch (error) {
    throw error
  }
}

// get_request_messages
export const Get_Request_Messages = async () => {
  try {
    const response = await Axiosapi.get(`${GET_REQUEST_MESSAGES}`);
    return response;
  }
  catch (error) {
    console.log("error", error)
  }
}

// get_messages
export const Get_Messages = async (id) => {
  try {
    const response = await Axiosapi.get(`${GET_MESSAGES}/${id}`);
    return response;
  }
  catch (error) {
    console.log("error", error)
  }
}

// SEND_MESSAGE
export const Send_Message = async (fData) => {
  try {
    const response = await Axiosapi.post(`${SEND_MESSAGE}`, fData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  }
  catch (error) {
    throw error
  }
}
// user Change password
export const userChangePassword = async (Data) => {
  try {
    const response = await Axiosapi.post(`${BASE_URL}${USER_CHANGE_PASSWORD}`, Data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw error
  }
};

//  DELETE_SUPPORT_TICKET
export const Delete_Support_Ticket = async (id) => {
  try {
    const response = await Axiosapi.delete(`${DELETE_SUPPORT_TICKET}/${id}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

// get_messages
export const Search_REQUEST = async (search) => {
  try {
    const response = await Axiosapi.get(`${SEARCH_REQUEST}?search=${search}`);
    return response;
  }
  catch (error) {
    console.log("error", error)
  }
}

// REQUEST MSG SEND
export const Set_ChatArchive = async (fdata) => {
  try {
    const response = await Axiosapi.post(`${SET_ARCHiVE}`, fdata)
    return response
    // get testimonial list data
  }
  catch (error) {
    throw error
  }
}

// manage request delete Chat 
export const ManageRequest_DeleteChat = async (chat_id) => {
  try {
    const response = await Axiosapi.delete(`${MANAGE_REQUEST_DELETE_CHAT}?id=${chat_id}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}


export const Get_AboutMe = async (id) => {
  try {
    const response = await Axiosapi.get(`${GET_ABOUT_ME}/${id}`);
    return response
  }
  catch (err) {
    console.log("err", err)
  }
}
// create event essentials form
export const createEventessentials = async (Data) => {
  try {
    const response = await Axiosapi.post(`${CREATE_EVENT_ESSENTIALS}`, Data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

// get online mode list
export const get_online_mode = async () => {
  try {
    const response = await Axiosapi.get(`${GET_ONLINE_MODE_LIST}`);
    return response;
  }
  catch (error) {
    console.log("error", error)
  }
}

export const Event_Host = async (id, val) => {
  try {
    const response = await Axiosapi.post(`${EVENT_HOST}/${id}`, val, {
      headers: { "Content-Type": "multipart/form-data", },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

// get event type essentials
export const get_type_eventessentials = async () => {
  try {
    const response = await Axiosapi.get(`${GET_EVENT_ESSENTIALS_TYPE}`);
    return response;
  }
  catch (error) {
    console.log("error", error)
  }
}

// guest tab
export const get_guest_data = async () => {
  try {
    const response = await Axiosapi.get(`${GET_GUEST_DATA_LIST}`);
    return response;
  }
  catch (error) {
    console.log("error", error)
  }
}

// create sponsor package
export const Event_Sponsor_Package = async (id, val) => {
  try {
    const response = await Axiosapi.post(`${EVENT_SPONSOR_PACKAGE}/${id}`, val, {
      headers: { "Content-Type": "multipart/form-data", },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

// update sponsor package
export const Update_Sponsor_Package = async (id, val) => {
  try {
    const response = await Axiosapi.post(`${UPDATE_SPONSOR_PACKAGE}/${id}`, val, {
      headers: { "Content-Type": "multipart/form-data", },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

// create event guest form
export const createEventguests = async (data, event_id) => {
  try {
    const response = await Axiosapi.post(`${CREATE_EVENT_GUEST_TAB}/${event_id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

// Pitch tab
// create event Pitch 
export const createEventpitch = async (data, event_id) => {
  try {
    const response = await Axiosapi.post(`${CREATE_EVENT_PITCH_TAB}/${event_id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}


export const Refund_Charge = async (id, fData) => {
  try {
    const response = await Axiosapi.post(`${REFUND_CHARGE}/${id}`, fData, {
      headers: { "Content-Type": "multipart/form-data", },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

// create social media links 
export const createEventsocialmedialinks = async (data, event_id) => {
  try {
    const response = await Axiosapi.post(`${CREATE_EVENT_SOCIAL_HUB_TAB}/${event_id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

// Event partnerShips Tab 
//get looking for Service Provider 
export const Get_Service_provider = async () => {
  try {
    const response = await Axiosapi.get(`${GET_PARTNERSHIPS_SERVICE_PROVIDER}`);
    return response;
  }
  catch (error) {
    console.log("error", error)
  }
}

//create partnerShipForm tab
export const createEventpartnership = async (data, id) => {
  try {
    const response = await Axiosapi.post(`${CREATE_EVENT_PARTNERSHIP_TAB}/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}
// edit event apis

// get event essentials
export const GetEditEvent = async (id) => {
  try {
    const response = await Axiosapi.get(`${GET_EDIT_ESSENTIALS}/${id}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

// get event guest
export const GetEditEventGuest = async (id) => {
  try {
    const response = await Axiosapi.get(`${GET_EDIT_GUEST}/${id}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

//get event pitch 
export const GetEditEventPitch = async (id) => {
  try {
    const response = await Axiosapi.get(`${GET_EDIT_PITCH}/${id}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

//get event host
export const GetEditEventHost = async (id) => {
  try {
    const response = await Axiosapi.get(`${GET_EDIT_HOST}/${id}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

// get event parterShip
export const GetEditEventPartnerShip = async (id) => {
  try {
    const response = await Axiosapi.get(`${GET_EDIT_PARTERSHIP}/${id}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

// get event SponsorPackage
export const GetEditEventSponsorPackage = async (id) => {
  try {
    const response = await Axiosapi.get(`${GET_EDIT_SPONSOR_PACKAGE}/${id}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}
//get event media hub

//get social hub
export const GetEditEventSocialHub = async (id) => {
  try {
    const response = await Axiosapi.get(`${GET_EDIT_SOCIAL_HUB}/${id}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

//get media hub
export const GetEditEventMediaHub = async (id) => {
  try {
    const response = await Axiosapi.get(`${GET_EDIT_MEDIA_HUB}/${id}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}
// get event Cancel Refund
export const GetEditEventCancelRefund = async (id) => {
  try {
    const response = await Axiosapi.get(`${GET_EDIT_CANCEL_REFUND}/${id}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

//get event reference
export const GetEditEventReference = async (id) => {
  try {
    const response = await Axiosapi.get(`${GET_EVENT_REFERENCE}/${id}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}


//Update apis
export const EventessentialsEdit = async (id, Data) => {
  try {
    const response = await Axiosapi.put(`${EDIT_EVENT_ESSENTIALS}/${id}`, Data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

// MY WORK
//event references
export const Get_Sponsor_Users = async () => {
  try {
    const response = await Axiosapi.get(`${GET_SPONSOR_USERS}`);
    return response;
  }
  catch (error) {
    console.log("error", error)
  }
}


//event references
export const Event_Media_Hub = async (event_id, fdata) => {
  try {
    const response = await Axiosapi.post(`${EVENT_MEDIA_HUB}/${event_id}`, fdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export const Event_References = async (event_id, fdata) => {
  try {
    const response = await Axiosapi.post(`${EVENT_REFERENCE}/${event_id}`, fdata, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

// Publish_Event
export const Publish_Event = async (fdata) => {
  try {
    const response = await Axiosapi.post(`${PUBLISH_EVENT}`, fdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

// UNPUBLISH_EVENT 
export const UnPublish_Event = async (fdata) => {
  try {
    const response = await Axiosapi.post(`${UNPUBLISH_EVENT}`, fdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export const Get_Publish_Data = async (id) => {
  try {
    const response = await Axiosapi.get(`${GET_EDIT_PUBLISHED_DATA}/${id}`);
    return response;
  }
  catch (error) {
    console.log("error", error)
  }
}

// get single parterShip
export const getPartnershipPartner = async (id) => {
  try {
    const response = await Axiosapi.get(`${GET_PARTERSHIP_PARTNERS}/${id}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

// update single partner
export const UpdatePartnerData = async (id, Data) => {
  try {
    const response = await Axiosapi.post(`${UPDATE_PARTNERSHIP_PARTNER}/${id}`, Data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export const DeletePartnershipPartner = async (id) => {
  try {
    const response = await Axiosapi.delete(`${DELETE_PARTERSHIP_PARTNERS}/${id}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

export const Email_Forgot_Password = async (fData) => {
  try {
    const response = await Axiosapi.post(`${EMAIL_FORGOT_PASSWORD}`, fData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}


export const Email_Forgot_Verify_OTP = async (fData) => {
  try {
    const response = await Axiosapi.post(`${EMAIL_FORGOT_VERIFY_OTP}`, fData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export const Forgot_Password = async (val) => {
  try {
    const response = await Axiosapi.post(`${FORGOT_PASSWORD}`, val, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export const Mobile_Forgot_Password = async (fData) => {
  try {
    const response = await Axiosapi.post(`${MOBILE_FORGOT_PASSWORD}`, fData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}


export const Verify_Forgot_Number_OTP = async (fData) => {
  try {
    const response = await Axiosapi.post(`${VERIFY_FORGOT_NUMBER_OTP}`, fData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

// get Profile detail
export const Get_Profile_Detail = async (id) => {
  try {
    const response = await Axiosapi.get(`${PROFILE_DETAIL}/${id}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

// get Profile detail
export const Get_Household_Income = async () => {
  try {
    const response = await Axiosapi.get(`${GET_HOUSEHOLD_INCOME}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

// Get_Education_List
export const Get_Education_List = async () => {
  try {
    const response = await Axiosapi.get(`${GET_EDUCATION_LIST}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

// Get_Education_List
export const Event_Sponsor_Transaction = async (id) => {
  try {
    const response = await Axiosapi.get(`${EVENT_SPONSOR_TRANSACTION}/${id}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

// Get_Education_List
export const Get_Mark_as_Complete = async (id) => {
  try {
    const response = await Axiosapi.get(`${GET_MARK_AS_COMPLETE}/${id}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

export const Mark_as_Complete_Detail = async (id, fData) => {
  try {
    const response = await Axiosapi.post(`${MARK_AS_COMPLETE_DETAIL}/${id}`, fData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

// CREATE PAST EVENT EVENT ESSENTIALS
export const create_pastEvent_EventEssentials = async (data) => {
  try {
    const response = await Axiosapi.post(`${CREATE_PASTEVENT_ESSENTIALS}`, data, {
      headers: {
        "Content-Type": "application/json",
      }
    })
    return response
  }
  catch (error) {
    throw error
  }
}

// create_pastEvent_Guest
export const create_pastEvent_Guest = async (data, id) => {
  try {
    const response = await Axiosapi.post(`${CREATE_PASTEVENT_GUEST}/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
      }
    })
    return response
  }
  catch (error) {
    throw error
  }
}


export const create_pastEvent_Pitch = async (data, id) => {
  try {
    const response = await Axiosapi.post(`${PASTEVENT_PITCH}/${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    })
    return response
  }
  catch (error) {
    throw error
  }
}

export const create_pastEvent_Host = async (id, data) => {
  try {
    const response = await Axiosapi.post(`${CREATE_PASTEVENT_HOST}/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
      }
    })
    return response
  }
  catch (error) {
    throw error
  }
}

export const createPastEventpartnership = async (data, id) => {
  try {
    const response = await Axiosapi.post(`${CREATE_PASTEVENT_PARTNERSHIP}/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export const createPastEvent_SponsorPackage = async (data, id) => {
  try {
    const response = await Axiosapi.post(`${CREATE_PASTEVENT_SPONSORPACKAGE}/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export const createPastEvent_MediaHub = async (FData, id) => {
  try {
    const response = await Axiosapi.post(`${CREATE_PASTEVENT_MEDIAHUB}/${id}`, FData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export const createPastEvent_SocialHub = async (data, id) => {
  try {
    const response = await Axiosapi.post(`${CREATE_PASTEVENT_SOCIALHUB}/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

// CREATE_NEWS_LETTER

export const Create_News_Letter = async (data) => {
  try {
    const response = await Axiosapi.post(`${CREATE_NEWS_LETTER}`, data, {
      headers: {
        "Content-Type": "application/json",
      }
    })
    return response
  }
  catch (error) {
    throw error
  }
}


// get edit api past event 

export const PastEventEssentialsUpdate = async (id, Data) => {
  try {
    const response = await Axiosapi.post(`${PASTEVENT_UPDATE_ESSENTIALS}/${id}`, Data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export const Get_PastEvent_Essentials = async (id) => {
  try {
    const response = await Axiosapi.get(`${GET_PASTEVENT_ESSENTIALS}/${id}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

export const Get_PastEvent_GuestForm = async (id) => {
  try {
    const response = await Axiosapi.get(`${GET_PASTEVENT_GUEST}/${id}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

export const Get_PastEvent_Pitch = async (id) => {
  try {
    const response = await Axiosapi.get(`${GET_PASTEVENT_PITCH}/${id}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

export const Get_PastEvent_Host = async (id) => {
  try {
    const response = await Axiosapi.get(`${GET_PASTEVENT_HOST}/${id}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

export const Get_PastEvent_Partnerships = async (id) => {
  try {
    const response = await Axiosapi.get(`${GET_PASTEVENT_PARTNERSHIPS}/${id}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

export const Get_PastEvent_SponsorPackage = async (id) => {
  try {
    const response = await Axiosapi.get(`${GET_PASTEVENT_SPONSORPACKAGE}/${id}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

export const Get_PastEvent_MediaHub = async (id) => {
  try {
    const response = await Axiosapi.get(`${GET_PASTEVENT_MEDIAHUB}/${id}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

export const PastEvent_BannerDelete = async (id) => {
  try {
    const response = await Axiosapi.delete(`${PASTEVENT_BANNER_DELETE}?ids=${id}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

export const Get_PastEvent_SocialHub = async (id) => {
  try {
    const response = await Axiosapi.get(`${GET_PASTEVENT_SOCIALHUB}/${id}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

// delete past event image or docs 

export const PastEventImageDocsDlt = async (id) => {
  try {
    const response = await Axiosapi.delete(`${PASTEVENT_MEDIA_IMAGE_DOCUMENT_DLT}${id}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}


export const getPastEventSponsorPackages = async (id) => {
  try {
    const response = await Axiosapi.get(`${GET_PASTEVENT_SPONSOR_PACKAGE}/${id}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

export const PastEventUpdateSponsorPackage = async (id, val) => {
  try {
    const response = await Axiosapi.post(`${PAST_EVENT_UPDATE_SPONSOR_PACKAGE}/${id}`, val, {
      headers: { "Content-Type": "application/json", },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export const Get_Event_Budget = async () => {
  try {
    const response = await Axiosapi.get(`${GET_EVENT_BUDGET}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

export const PastEvent_delete_Package = async (package_id) => {
  try {
    const response = await Axiosapi.delete(`${PASTEVENT_PACKAGE_DELETE}/${package_id}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}


export const Delete_Banner = async (id) => {
  try {
    const response = await Axiosapi.delete(`${DELETE_BANNER}/${id}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}


// getloginActivityMsg

export const getloginActivityMsg = async (id, currentPage) => {
  try {
    const response = await Axiosapi.get(`${GET_LOGIN_ACTIVITY_MSG}/${id}?page=${currentPage}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

export const GetProfessions = async () => {
  try {
    const response = await Axiosapi.get(`${GET_PROFESSIONS}`)
    return response
  }
  catch (error) {
    console.log(error)
  }
}

export const DltConfirmedSponsor = async (id) => {
  try {
    const response = await Axiosapi.delete(`${CONFIRMED_SPONSOR_DELETE}/${id}`)
    return response;
  }
  catch (error) {
    console.log(error)
  }
}


export const UpdateConfirmedSponsor = async (id, val) => {
  try {
    const response = await Axiosapi.post(`${CONFIRMED_SPONSOR_UPDATE}/${id}`, val, {
      headers: { "Content-Type": "multipart/form-data", },
    });
    return response;
  } catch (error) {
    throw error;
  }
}


export const GetConfirmedSponsor = async (id) => {
  try {
    const response = await Axiosapi.get(`${GET_SINGLE_CONFIRMED_SPONSOR}/${id}`)
    return response;
  }
  catch (error) {
    console.log(error)
  }
}


export const PastEventDltConfirmedSponsor = async (id) => {
  try {
    const response = await Axiosapi.delete(`${PAST_EVENT_CONFIRMED_SPONSOR_DELETE}/${id}`)
    return response;
  }
  catch (error) {
    console.log(error)
  }
}


export const PastEventGetConfirmedSponsor = async (id) => {
  try {
    const response = await Axiosapi.get(`${PAST_EVENT_GET_SINGLE_CONFIRMED_SPONSOR}/${id}`)
    return response;
  }
  catch (error) {
    console.log(error)
  }
}


export const PastEvent_UpdateConfirmedSponsor = async (id, val) => {
  try {
    const response = await Axiosapi.post(`${PAST_EVENT_CONFIRMED_SPONSOR_UPDATE}/${id}`, val, {
      headers: { "Content-Type": "multipart/form-data", },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

// CREATE_EVENT_SCHEDULE
export const Create_Event_Schedule = async (id, val) => {
  try {
    const response = await Axiosapi.post(`${CREATE_EVENT_SCHEDULE}/${id}`, val, {
      headers: { "Content-Type": "application/json" },
    });
    return response;
  } catch (error) {
    throw error;
  }
}
// partner with host event detail 
export const CreatePartner_With_Host = async (id, input) => {
  try {
    const response = await Axiosapi.post(`${PARTNER_WITH_HOST}/${id}`, input, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  }
  catch (error) {
    throw error
  }
}

export const CreatePartner_With_Sponsor = async (id, input) => {
  try {
    const response = await Axiosapi.post(`${PARTNER_WITH_SPONSOR}/${id}`, input, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  }
  catch (error) {
    throw error
  }
}

export const Get_Event_Schedule = async (id) => {
  try {
    const response = await Axiosapi.get(`${GET_EVENT_SCHEDULE}/${id}`)
    return response;
  }
  catch (error) {
    console.log(error)
  }
}

// EVENT_SCHEDULE_UPLOAD_IMAGE
export const Event_Schedule_Upload_Image = async (id, fData) => {
  try {
    const response = await Axiosapi.post(`${EVENT_SCHEDULE_UPLOAD_IMAGE}/${id}`, fData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  }
  catch (error) {
    throw error
  }
}

export const Event_Schedule_Image_Delete = async (id) => {
  try {
    const response = await Axiosapi.delete(`${EVENT_SCHEDULE_IMAGE_DELETE}/${id}`)
    return response;
  }
  catch (error) {
    console.log(error)
  }
}


export const GetHostAllEvent = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}${GET_HOST_ALL_EVENTS}/${id}`);
    return response;
  }
  catch (error) {
    console.log("error", error)
  }
}

export const GetHostAllPastEvent = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}${GET_HOST_ALL_PAST_EVENTS}/${id}`);
    return response;
  }
  catch (error) {
    console.log("error", error)
  }
}

// get reject Data
export const Featured_Event_List = async () => {
  try {
    const response = await Axiosapi.get(`${FEATURED_EVENT_LIST}`);
    return response;
  }
  catch (error) {
    console.log("error", error)
  }
}

export const Featured_Selected_Date = async (FData) => {
  try {
    const response = await Axiosapi.post(`${FEATURED_SELECTED_DATE}`, FData, {
      headers: { "Content-Type": "application/json" },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export const Check_Admin = async (FData) => {
  try {
    const response = await Axiosapi.post(`${CHECK_ADMIN}`, FData, {
      headers: { "Content-Type": "application/json" },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export const Add_Reason_For_Rejection = async (FData,id) => {
  try {
    const response = await Axiosapi.post(`${ADD_REASON_FOR_REJECTION}/${id}`,FData,{
      headers: { "Content-Type": "application/json" },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export const Express_Event_Essentials = async (fData) => {
  try {
    const response = await Axiosapi.post(`${EXPRESS_EVENT_ESSENTIALS}`,fData,{
      headers: {
        "Content-Type":"multipart/form-data",
      },
    });
    return response;
  }
  catch (error) {
    throw error
  }
}

export const Express_Event_Essentials_Update = async (id,fData) => {
  try {
    const response = await Axiosapi.post(`${EXPRESS_EVENT_ESSENTIALS_UPDATE}/${id}`,fData,{
      headers: {
        "Content-Type":"multipart/form-data",
      },
    });
    return response;
  }
  catch (error) {
    throw error
  }
}


export const Get_Express_Event_Assentials = async (id) => {
  try {
    const response = await Axiosapi.get(`${GET_EXPRESS_EVENT_ESSENTIALS}/${id}`)
    return response;
  }
  catch (error) {
    console.log(error)
  }
}

export const Express_Event_Guest = async (id,fData) => {
  try {
    const response = await Axiosapi.post(`${EXPRESS_EVENT_GUEST}/${id}`,fData,{
      headers: {
        "Content-Type":"multipart/form-data",
      },
    });
    return response;
  }
  catch (error) {
    throw error
  }
}

export const Get_Express_Sponsor_Package = async (id) => {
  try {
    const response = await Axiosapi.get(`${GET_EXPRESS_SPONSOR_PACKAGE}/${id}`)
    return response;
  }
  catch (error) {
    console.log(error)
  }
}

export const Express_Event_Pitch = async (fData,id) => {

  try {
    const response = await Axiosapi.post(`${EXPRESS_EVENT_PITCH}/${id}`,fData,{
      headers: {
        "Content-Type":"multipart/form-data",
      },
    });
    return response;
  }
  catch (error) {
    throw error
  }
}

export const Express_Sponsor_Package = async (id,fData) => {
  try {
    const response = await Axiosapi.post(`${EXPRESS_SPONSOR_PACKAGE}/${id}`,fData,{
      headers: {
        "Content-Type":"multipart/form-data",
      },
    });
    return response;
  }
  catch (error) {
    throw error
  }
}

export const Express_Event_Media_Hub = async (id,fData) => {
  try {
    const response = await Axiosapi.post(`${EXPRESS_EVENT_MEDIA_HUB}/${id}`,fData,{
      headers: {
        "Content-Type":"multipart/form-data",
      },
    });
    return response;
  }
  catch (error) {
    throw error
  }
}

export const Get_Express_Event_Media_Hub = async (id) => {
  try {
    const response = await Axiosapi.get(`${GET_EXPRESS_EVENT_MEDIA_HUB}/${id}`)
    return response;
  }
  catch (error) {
    console.log(error)
  }
}

export const Express_Sponsor_Details_Update = async (id,fData) => {
  try {
    const response = await Axiosapi.post(`${EXPRESS_SPONSOR_DETAILS_UPDATE}/${id}`,fData,{
      headers: {
        "Content-Type":"multipart/form-data",
      },
    });
    return response;
  }
  catch (error) {
    throw error
  }
}

export const Express_Sponsor_Package_Update = async (id,fData) => {
  try {
    const response = await Axiosapi.post(`${EXPRESS_SPONSOR_PACKAGE_UPDATE}/${id}`,fData,{
      headers: {
        "Content-Type":"multipart/form-data",
      },
    });
    return response;
  }
  catch (error) {
    throw error
  }
}

export const Get_Event_Benefit = async () => {
  try {
    const response = await Axiosapi.get(`${GET_EVENT_BENEFIT}`)
    return response;
  }
  catch (error) {
    console.log(error)
  }
}

export const Express_Event_Add_Reason_For_Rejection = async (FData,id) => {
  try {
    const response = await Axiosapi.post(`${EXPRESS_EVENT_ADD_REASON_FOR_REJECTION}/${id}`,FData,{
      headers: { "Content-Type": "application/json" },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export const Near_By_Cuttoff_Date = async () => {
  try {
    const response = await Axiosapi.get(`${NEAR_BY_CUTTOFF_DATE}`)
    return response;
  }
  catch (error) {
    console.log(error)
  }
}

export const Delete_Past_Event = async (id) => {
  try {
    const response = await Axiosapi.delete(`${DELETE_PAST_EVENT}/${id}`)
    return response;
  }
  catch (error) {
    console.log(error)
  }
}

export const Past_Event_Add_Reason_For_Rejection = async (FData,id) => {
  try {
    const response = await Axiosapi.post(`${PAST_EVENT_ADD_REASON_FOR_REJECTION}/${id}`,FData,{
      headers: { "Content-Type": "application/json" },
    });
    return response;
  } catch (error) {
    throw error;
  }
}