import { EventReview, getEventReview } from "../../Services/Apis";

export const ReviewEvent = async (value) => {

    try {
        const response = await EventReview(value).then((data) => data?.data)
        return response;
    }
    catch (error) {
        throw error
    }
}


export const ReviewEventList = async (id) => {

    try {
        const response = await getEventReview(id).then((data) => data?.data)
        return response;
    }
    catch (error) {
        throw error
    }
}
