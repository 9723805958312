
import React, { useState,useEffect} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { Featured_EventListData } from "../../../utils/myevent/MyEvent";
import Loader2 from "../../Loader/Loader2";

const FeatureEventMdl=({totalPrice, setTotalPrice,selectedDates, setSelectedDates,event_data})=>{
    const [datePriceMap, setDatePriceMap] = useState({});
    const [disabledDates, setDisabledDates] = useState([]);
    const [loading,setLoading]=useState(false);
    
    const GetFeaturedEventList = async () => {
      setLoading(true)
      try {
        const response = await Featured_EventListData();
        const resData = response?.data?.data;
    
        const map = {};
        let fallbackPrice = null;
        resData?.forEach((item) => {
          const { booking_date, amount, is_slot_available, booked_slot, is_offer_available } = item;
          const dateStr = moment(booking_date).format("YYYY-MM-DD");
          const priceInfo = {
            price: `₹${amount}`,
            available_slot: is_slot_available,
            slot: booked_slot,
            is_offer_available: is_offer_available
          };
          map[dateStr] = priceInfo;
    
          if (!is_slot_available) {
            disabledDates?.push(dateStr);
          }
        });
    
        setDisabledDates(disabledDates);
        setDatePriceMap(map);
      } catch (err) {
        console.log(err);
      }finally{
        setLoading(false)
      }
    };

    useEffect(() => {
      GetFeaturedEventList();
    }, []);

    const handleDateChange = (date) => {
      const formattedDate = moment(date).format("YYYY-MM-DD");
      // Fetch the price for the selected date
      const priceString = datePriceMap[formattedDate]?.price || datePriceMap.fallbackPrice?.price || "₹0";
      const price = parseInt(priceString.replace(/[^\d]/g, ""), 10);
  
      if (selectedDates?.includes(formattedDate)) {
        // If the date is already selected, remove it and update the total price
        const updatedDates = selectedDates.filter((selectedDate) => selectedDate !== formattedDate);
        setSelectedDates(updatedDates);
        const newTotalPrice = updatedDates.reduce((acc, curr) => {
          const currentPriceString = datePriceMap[curr]?.price || datePriceMap.fallbackPrice?.price || "₹0";
          const currentPrice = parseInt(currentPriceString.replace(/[^\d]/g, ""), 10);
          return acc + currentPrice;
        }, 0);
        setTotalPrice(newTotalPrice);
      } else {
        // If the date is not selected, add it and update the total price
        const updatedDates = [...selectedDates, formattedDate];
        setSelectedDates(updatedDates);
  
        const newTotalPrice = updatedDates.reduce((acc, curr) => {
          const currentPriceString = datePriceMap[curr]?.price || datePriceMap.fallbackPrice?.price || "₹0";
          const currentPrice = parseInt(currentPriceString.replace(/[^\d]/g, ""), 10);
          return acc + currentPrice;
        }, 0);
        setTotalPrice(newTotalPrice);
      }
    };
  
    const renderDayContents = (day, date) => {
      const formattedDate = moment(date).format("YYYY-MM-DD");
      const dateData = datePriceMap[formattedDate] || {};

      const amountColor = dateData?.available_slot ? "black" : "red";
     
      const offerStyle = {
        color: dateData?.is_offer_available && dateData?.available_slot ? "green" : amountColor, // Apply offer color or amount color
        fontSize: "11px",
      };

      return (
        <div style={{ textAlign: "center" }}>
          <span>{day}</span>
          <br />
          {dateData?.price && (
            <>
              <span style={offerStyle}>
                {dateData?.price}
              </span>
            </>
          )}
        </div>
      );
    };

    const isDateDisabled = (date) => {
      const formattedDate = moment(date).format("YYYY-MM-DD");
      return disabledDates.includes(formattedDate);
    };

  return(<>
     <div>
      {
       loading ? <Loader2/> :      
      <div className="booking-container">
      <DatePicker
        selected={null}
        onChange={handleDateChange}
        inline
        filterDate={(date) => !isDateDisabled(date)}
        dayClassName={(date) => {
          const formattedDate = moment(date)?.format("YYYY-MM-DD");
          return disabledDates?.includes(formattedDate) ? "disabled-date" 
          : (selectedDates?.includes(formattedDate) ? "selected-date" : undefined);
        }}
        renderDayContents={renderDayContents}
        minDate={new Date()}
        maxDate={event_data?.published_end_date}
      />

      {/*<DatePicker
        selected={null}
        onChange={handleDateChange}
        inline
        dayClassName={(date) =>
          selectedDates?.includes(moment(date).format("YYYY-MM-DD"))
          ? "selected-date" : undefined }
        renderDayContents={renderDayContents}
        minDate={new Date()}
        maxDate={event_data?.published_end_date}
      /> */}
      </div> 
     }
    </div>
 
    </>)
 };

export default FeatureEventMdl;