import React, { useState, useEffect } from 'react';
import { Get_ChampDashboard } from '../../utils/champ/champAlldata';
import Loader2 from '../Loader/Loader2';

const Dashboard = ({ currency, setTabValue }) => {
  const [dashboardData, setDashboardData] = useState({})
  const [walletAmount, setWalletAmount] = useState(0);
  const [introductoryCurrencyIcn, setIntroductoryCurrencyIcn] = useState('');
  const [manage_hostCurrencyIcn, setManage_hostCurrencyIcn] = useState('');
  const [onboard_sponsorsCurrencyIcn, setOnboard_SponsorsCurrencyIcn] = useState('');
  const [total_earningsCurrencyIcn, setTotal_earningsCurrencyIcn] = useState('');
  const [loading, setLoading] = useState(true)

  // introductory_earnings_currency
  // manage_host_earnings_currency
  // onboard_sponsors_earnings_currency
  // total_earnings_currency
  const checkCurrency = (data) => {
    if (data?.introductory_earnings_currency == "INR") {
      setIntroductoryCurrencyIcn("₹");
    } else if (data?.introductory_earnings_currency == "USD") {
      setIntroductoryCurrencyIcn("$");
    } else if (data?.introductory_earnings_currency == "EUR") {
      setIntroductoryCurrencyIcn("€");
    } else if (data?.introductory_earnings_currency == "GBP") {
      setIntroductoryCurrencyIcn("€");
    }

    if (data?.manage_host_earnings_currency == "INR") {
      setManage_hostCurrencyIcn("₹");
    } else if (data?.manage_host_earnings_currency == "USD") {
      setManage_hostCurrencyIcn("$");
    } else if (data?.manage_host_earnings_currency == "EUR") {
      setManage_hostCurrencyIcn("€");
    } else if (data?.manage_host_earnings_currency == "GBP") {
      setManage_hostCurrencyIcn("€");
    }

    if (data?.onboard_sponsors_earnings_currency == "INR") {
      setOnboard_SponsorsCurrencyIcn("₹");
    } else if (data?.onboard_sponsors_earnings_currency == "USD") {
      setOnboard_SponsorsCurrencyIcn("$");
    } else if (data?.onboard_sponsors_earnings_currency == "EUR") {
      setOnboard_SponsorsCurrencyIcn("€");
    } else if (data?.onboard_sponsors_earnings_currency == "GBP") {
      setOnboard_SponsorsCurrencyIcn("€");
    }

    if (data?.total_earnings_currency == "INR") {
      setTotal_earningsCurrencyIcn("₹");
    } else if (data?.total_earnings_currency == "USD") {
      setTotal_earningsCurrencyIcn("$");
    } else if (data?.total_earnings_currency == "EUR") {
      setTotal_earningsCurrencyIcn("€");
    } else if (data?.total_earnings_currency == "GBP") {
      setTotal_earningsCurrencyIcn("€");
    }
  };

  const GetChampDashboard = async () => {
    try {
      setLoading(true)
      const response = await Get_ChampDashboard();
      setDashboardData(response?.data?.data);
      checkCurrency((response?.data?.data))
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    GetChampDashboard();
  }, []);


  return (

    loading ? <Loader2 /> :
      <div className="dashboard-container">

        <div className="overview">
          <h4 className="champ-text">Overview</h4>
          <div className="row ">

             <div className='col-sm-6 col-xl-4'>
            <button className='champ_dashboard-btn' onClick={() => setTabValue("Hosts")}>
              <div className='total-hosts-card'>
                <p className="total-hosts-text">Total Hosts </p>
                <p className="total-hosts-data-text">{dashboardData?.total_host}</p>
              </div>
            </button>
            </div>

            <div className='col-sm-6 col-xl-4 mt-4 mt-sm-0'>
            <button className='champ_dashboard-btn' onClick={() => setTabValue("Sponsors")}>
              <div className='total-sponsors-card'>
                <p className="total-hosts-text">Total Sponsors </p>
                <p className="total-hosts-data-text"> {dashboardData?.total_sponsor} </p>
                {/* {dashboardData?.total_sponsor} */}
              </div>
            </button>
            </div>

            <div className='col-sm-6 col-xl-4 mt-4 mt-xl-0'>
            <button className='champ_dashboard-btn' onClick={() => setTabValue("Transactions")}>
              <div className='total-earning-card'>
                <p className="total-hosts-text">Total Income</p>
                <p className="total-hosts-data-text">{currency?.currencySymbol ? currency?.currencySymbol : total_earningsCurrencyIcn}
                  {currency?.selectedCurrencyPrice ? (dashboardData?.total_earnings * currency?.selectedCurrencyPrice).toFixed(2) : dashboardData?.total_earnings.toFixed(2)}</p>
              </div>
            </button>
            </div>

          </div>
        </div>
        <hr className='w-100' />
    
        <div className="earnings-breakdown">
          <h4 className="champ-text">Earnings Breakdown</h4>
          <div className="row mt-2">
              
             <div className='col-xl-4 col-sm-6'> 
            <button className='champ_dashboard-btn' onClick={() => setTabValue("Users")}>
              <div className='introductory-card'>
                <p className="total-hosts-text">Introductory Income</p>
                <p className="total-hosts-data-text">{currency?.currencySymbol ? currency?.currencySymbol : introductoryCurrencyIcn}
                  {currency?.selectedCurrencyPrice ? (dashboardData?.introductory_earnings * currency?.selectedCurrencyPrice).toFixed(2) : dashboardData?.introductory_earnings.toFixed(2)}</p>
              </div>
            </button>
            </div>

            <div className='col-xl-4 col-sm-6 mt-4 mt-sm-0'>
            <button className='champ_dashboard-btn' onClick={() => setTabValue("Hosts")}>
              <div className='manage-host-card'>
                <p className="total-hosts-text">Income From Host Event</p>
                <p className="total-hosts-data-text">{currency?.currencySymbol ? currency?.currencySymbol : manage_hostCurrencyIcn}
                  {currency?.selectedCurrencyPrice ? (dashboardData?.manage_host_earnings * currency?.selectedCurrencyPrice).toFixed(2) : dashboardData?.manage_host_earnings.toFixed(2)}</p>
              </div>
            </button>
            </div>

            <div className='col-xl-4 col-sm-6 mt-4 mt-xl-0'>
            <button className='champ_dashboard-btn' onClick={() => setTabValue("Sponsors")}>
              <div className='onboard-sponsors-card'>
                <p className="total-hosts-text">Income From Sponsored Event</p>
                <p className="total-hosts-data-text">{currency?.currencySymbol ? currency?.currencySymbol : onboard_sponsorsCurrencyIcn}
                  {currency?.selectedCurrencyPrice ? (dashboardData?.onboard_sponsors_earnings * currency?.selectedCurrencyPrice).toFixed(2) : dashboardData?.onboard_sponsors_earnings.toFixed(2)}</p>
              </div>
            </button>
            </div>
          </div>
        </div>
        {/* <hr className='w-100' /> */}
        {/* <div className="wallet-amount">
                <h4 className="champ-text">Wallet Amount</h4>
                <div className="d-flex justify-content-between">
                    <p className="champ-p">Total Wallet Amount: {walletAmount}</p>
                </div>
            </div>
            <hr className='w-100'/> */}
        
        <hr className='w-100' />
        
        <div className="earnings-breakdown" >
          <div className="row">

            <div className="col-xl-4 col-sm-6">
            <button className='champ_dashboard-btn' onClick={() => setTabValue("Transactions")}>
              <div className='total_income_till_date'>
                <p className="total-hosts-text">Total Income till date</p>
                <p className="total-hosts-data-text">{currency?.currencySymbol ? currency?.currencySymbol : introductoryCurrencyIcn}
                  {currency?.selectedCurrencyPrice ? (dashboardData?.introductory_earnings * currency?.selectedCurrencyPrice).toFixed(2) : dashboardData?.introductory_earnings.toFixed(2)}</p>
              </div>
            </button>
            </div>
          
             <div className="col-xl-4 col-sm-6 mt-4 mt-sm-0">
            <button className='champ_dashboard-btn' onClick={() => setTabValue("Transactions")}>
              <div className='earning_received'>
                <p className="total-hosts-text">Earnings received</p>
                <p className="total-hosts-data-text">{currency?.currencySymbol ? currency?.currencySymbol : manage_hostCurrencyIcn}
                  {currency?.selectedCurrencyPrice ? (dashboardData?.manage_host_earnings * currency?.selectedCurrencyPrice).toFixed(2) : dashboardData?.manage_host_earnings.toFixed(2)}</p>
              </div>
            </button>
            </div>

            <div className="col-xl-4 col-sm-6 mt-4 mt-xl-0">
            <button className='champ_dashboard-btn' onClick={() => setTabValue("Transactions")}>
              <div className='earning_pending'>
                <p className="total-hosts-text">Earnings Pending</p>
                <p className="total-hosts-data-text">{currency?.currencySymbol ? currency?.currencySymbol : onboard_sponsorsCurrencyIcn}
                  {currency?.selectedCurrencyPrice ? (dashboardData?.onboard_sponsors_earnings * currency?.selectedCurrencyPrice).toFixed(2) : dashboardData?.onboard_sponsors_earnings.toFixed(2)}</p>
              </div>
            </button>
            </div>
          </div>
        </div>

        <div className="row earnings-breakdown">
          <div className="col-xl-4 col-sm-6">
            <button className='champ_dashboard-btn' onClick={() => setTabValue("Transactions")}>
              <div className='earning_not_due'>
                <p className="total-hosts-text">Earnings Not due</p>
                <p className="total-hosts-data-text">{currency?.currencySymbol ? currency?.currencySymbol : introductoryCurrencyIcn}
                  {currency?.selectedCurrencyPrice ? (dashboardData?.introductory_earnings * currency?.selectedCurrencyPrice).toFixed(2) : dashboardData?.introductory_earnings.toFixed(2)}</p>
              </div>
            </button>
          </div>
        </div>

      <div className='dashboard-signup-section mt-3'>
        <h4 className="dashboard-signup-head-text">Host</h4>
          <div className="row mt-2">
          <div className="col-xl-4 col-sm-6">
            <button className='champ_dashboard-btn' onClick={() => setTabValue("Hosts")}>
              <div className='host_total_user'>
                <p className="total-hosts-text">Total Events</p>
                <p className="total-hosts-data-text">{dashboardData?.total_event}</p>
              </div>
            </button>
          </div>

          <div className="col-xl-4 col-sm-6">
            <button className='champ_dashboard-btn' onClick={() => setTabValue("Hosts")}>
              <div className='host_total_user'>
                <p className="total-hosts-text">Total Sponsored event</p>
                <p className="total-hosts-data-text">{dashboardData?.total_sponsor}</p>
              </div>
            </button>
          </div>

          <div className="col-xl-4 col-sm-6">
            <button className='champ_dashboard-btn' onClick={() => setTabValue("Hosts")}>
              <div className='host_total_user'>
                <p className="total-hosts-text">Total Collection</p>
                <p className="total-hosts-data-text">{currency?.currencySymbol ? currency?.currencySymbol : introductoryCurrencyIcn}
                  {currency?.selectedCurrencyPrice ? (dashboardData?.total_received_collection * currency?.selectedCurrencyPrice).toFixed(2) : dashboardData?.total_received_collection.toFixed(2)}</p>
              </div>
            </button>
          </div>

          <div className="col-xl-4 col-sm-6 mt-3">
            <button className='champ_dashboard-btn' onClick={() => setTabValue("Hosts")}>
              <div className='host_total_user'>
                <p className="total-hosts-text">Total Income</p>
                <p className="total-hosts-data-text">{currency?.currencySymbol ? currency?.currencySymbol : introductoryCurrencyIcn}
                {currency?.selectedCurrencyPrice ? (dashboardData?.total_event_manage_commission * currency?.selectedCurrencyPrice).toFixed(2) : dashboardData?.total_event_manage_commission.toFixed(2)}</p>
              </div>
            </button>
          </div>
          </div>

          {/* <div className='row mt-4'>
          <div className="col-xl-4 col-sm-6">
            <button className='champ_dashboard-btn' onClick={() => setTabValue("Hosts")}>
              <div className='host_total_user'>
                <p className="total-hosts-text">Total Income</p>
                <p className="total-hosts-data-text">220</p>
              </div>
            </button>
          </div>
          </div> */}
        </div>

        <div className='dashboard-signup-section mt-3'>
        <h4 className="dashboard-signup-head-text">Sponsor</h4>
          <div className="row mt-2">
          <div className="col-xl-4 col-sm-6">
            <button className='champ_dashboard-btn' onClick={() => setTabValue("Sponsors")}>
              <div className='sponsor_total_user'>
                <p className="total-hosts-text">Total Events</p>
                <p className="total-hosts-data-text">{dashboardData?.total_sponsor}</p>
              </div>
            </button>
          </div>

          {/* <div className="col-xl-4 col-sm-6">
            <button className='champ_dashboard-btn' onClick={() => setTabValue("Sponsors")}>
              <div className='sponsor_total_user'>
                <p className="total-hosts-text">Total Sponsored</p>
                <p className="total-hosts-data-text">120</p>
              </div>
            </button>
          </div> */}

          <div className="col-xl-4 col-sm-6">
            <button className='champ_dashboard-btn' onClick={() => setTabValue("Sponsors")}>
              <div className='sponsor_total_user'>
                <p className="total-hosts-text">Total Sponsored Amount</p>
                <p className="total-hosts-data-text">{currency?.currencySymbol ? currency?.currencySymbol : introductoryCurrencyIcn}
                {currency?.selectedCurrencyPrice ? (dashboardData?.total_sponsored_collection * currency?.selectedCurrencyPrice).toFixed(2) : dashboardData?.total_sponsored_collection.toFixed(2) }</p>
              </div>
            </button>
          </div>

          <div className="col-xl-4 col-sm-6">
            <button className='champ_dashboard-btn' onClick={() => setTabValue("Sponsors")}>
              <div className='sponsor_total_user'>
                <p className="total-hosts-text">Total Income</p>
                <p className="total-hosts-data-text">{currency?.currencySymbol ? currency?.currencySymbol : introductoryCurrencyIcn}
                {currency?.selectedCurrencyPrice ? (dashboardData?.total_sponsorship_commission * currency?.selectedCurrencyPrice).toFixed(2) : dashboardData?.total_sponsorship_commission.toFixed(2) }</p>
              </div>
            </button>
          </div>
          </div>
        </div>

        <div className='dashboard-signup-section mt-3'>
        <h4 className="dashboard-signup-head-text">Sign Up</h4>
          <div className="row mt-2">
          <div className="col-xl-4 col-sm-6">
            <button className='champ_dashboard-btn' onClick={() => setTabValue("Users")}>
              <div className='signup_total_user'>
                <p className="total-hosts-text">Total Users</p>
                <p className="total-hosts-data-text">{dashboardData?.total_user}</p>
              </div>
            </button>
          </div>
          
          <div className="col-xl-4 col-sm-6">
            <button className='champ_dashboard-btn' onClick={() => setTabValue("Users")}>
              <div className='signup_total_user'>
                <p className="total-hosts-text">Total Income</p>
                <p className="total-hosts-data-text">{currency?.currencySymbol ? currency?.currencySymbol : introductoryCurrencyIcn}
                {currency?.selectedCurrencyPrice ? (dashboardData?.total_sign_up_commission * currency?.selectedCurrencyPrice).toFixed(2) : dashboardData?.total_sign_up_commission.toFixed(2) }</p>
              </div>
            </button>
          </div>
          </div>
        </div>

      </div>
  );
};

export default Dashboard;
