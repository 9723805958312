// import { Circles, ThreeCircles } from "react-loader-spinner";


// const Loader = () => {
//   return (<>
//     <div className="loader-main-div">
//       <div className="loader-div d-flex align-items-center justify-content-center">
//         <ThreeCircles
//           height="70"
//           width="70"
//           color="#f43254"
//           ariaLabel="circles-loading"
//           wrapperStyle={{}}
//           wrapperclassName=""
//           visible={true}
//         />
//       </div>
//     </div>
//   </>)
// };

// export default Loader;

import React from 'react';

// this logo css on bannerBullet.css
const Loader = () => {
  return (
    <div className="loaderr-containerr">
      <div className="loaderr-contentt">
        <img src={process.env.PUBLIC_URL + '/favicon.ico'} alt="Logo" className="loaderr-logoo" />

      </div>
    </div>
  )

}
export default Loader;
