import ReactPaginate from "react-paginate";
import { MdError, MdEventAvailable, MdOutlineWorkspacePremium } from "react-icons/md";
import { PiCrown, PiUserList } from "react-icons/pi";
import { LiaMedalSolid } from "react-icons/lia";
import { BiPurchaseTag, BiSolidEditAlt } from "react-icons/bi";
import { GrUserNew } from "react-icons/gr";
import { IoMdLogOut, IoMdLogIn } from "react-icons/io";
import { GiCheckMark } from "react-icons/gi";
import { LuUserCheck2 } from "react-icons/lu";
import { VscFeedback } from "react-icons/vsc";
import { SiAwsorganizations } from "react-icons/si";
import { RiFeedbackLine } from "react-icons/ri";
import { MdOutlineManageHistory } from "react-icons/md";
import { MdOutlinePendingActions } from "react-icons/md";
import { GiConfirmed } from "react-icons/gi";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { NotificationDatalist } from "../../utils/notification/notify";
import { useEffect, useState } from 'react'
import moment from "moment";
import Loader2 from "../../components/Loader/Loader2";
import { useSelector } from "react-redux";
import { TiArrowLeft, TiMessageTyping } from "react-icons/ti";


const Notification = () => {
    const profileData = useSelector((state) => state?.Profile_data?.ProfileData);

    const [showNotification, setShowNotification] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const location = useLocation();
    const navigate = useNavigate();

    const getData = async () => {
        setLoading(true)
        try {
            await NotificationDatalist(currentPage)
                .then((response) => {
                    if (response?.data?.success == true) {
                        setShowNotification(response?.data?.data)
                        setLoading(false)
                    }
                })
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getData();
    }, [currentPage]);

    const iconMap = {
        "membership_plan": { icon: <LiaMedalSolid size={23} color="#f43254" />, link: "/membership" },//
        "prime_member": { icon: <PiCrown size={23} color="#f43254" />, link: "/prime" },
        "sponsor-event-package-pay-now": { icon: <BiPurchaseTag size={23} color="#f43254" />, link: "/myeventransaction" },//
        "host-event-review": { icon: <VscFeedback size={23} color="#f43254" />, link: "/review-event" },
        "host-event-manage-request": { icon: <SiAwsorganizations size={23} color="#f43254" />, link: "/managerequest" },//
        "sponsor-event-manage-request": { icon: <SiAwsorganizations size={23} color="#f43254" />, link: "/managerequest" },//
        "create-event-under-review": { icon: <MdEventAvailable size={23} color="#f43254" />, link: "/myevents/Under_Review" },//
        "mark-us-complate-notification": { icon: <GiCheckMark size={23} color="#f43254" />, link: '#' },
        "update-event-notification": { icon: <MdEventAvailable size={23} color="#f43254" />, link: '#' },

        "Sign up": { icon: <GrUserNew size={23} color="#f43254" />, link: "/profile" },// 
        "Login": { icon: <IoMdLogIn size={23} color="#f43254" />, link: "#" },//
        "Logout": { icon: <IoMdLogOut size={23} color="#f43254" />, link: "#" },//
        "Feedback Submitted": { icon: <RiFeedbackLine size={23} color="#f43254" />, link: '#' },
        "Pending Profile": { icon: <PiUserList size={23} color="#f43254" />, link: `/editprofile/${profileData.id}` },//
        "Profile Completion": { icon: <LuUserCheck2 size={23} color="#f43254" />, link: "/profile" },//
        "Create Event": { icon: <MdEventAvailable size={23} color="#f43254" />, link: "/myevents/all" },//
        "Event in Draft": { icon: <MdEventAvailable size={23} color="#f43254" />, link: "/myevents/Draft" },//
        "Event Approved": { icon: <MdEventAvailable size={23} color="#f43254" />, link: "/myevents/Approved" },//
        "Event Rejected": { icon: <MdEventAvailable size={23} color="#f43254" />, link: "/myevents/Rejected" },//
        "Event Published": { icon: <MdEventAvailable size={23} color="#f43254" />, link: "/myevents/Published" },//
        "Event Status Change": { icon: <MdEventAvailable size={23} color="#f43254" />, link: "#" },//
        "Approved Events": { icon: <MdEventAvailable size={23} color="#f43254" />, link: "#" },//
        "Host Event Sponsor Pay Now": { icon: <BiPurchaseTag size={23} color="#f43254" />, link: "/myeventransaction" },//
        "Host Manage Request": { icon: <MdOutlineManageHistory size={23} color="#f43254" />, link: '#' },
        "Sponsor Manage Request": { icon: <MdOutlineManageHistory size={23} color="#f43254" />, link: '#' },
        "Host Event in Featured": { icon: <MdOutlineWorkspacePremium size={23} color="#f43254" />, link: "/events/Featured" },//
        "Pending Feedback": { icon: <MdOutlinePendingActions size={23} color="#f43254" />, link: "/events/Featured" },//
        "Payment Confirmation Sponsor": { icon: <GiConfirmed size={23} color="#f43254" />, link: "/events/Featured" },//
        "Payment Confirmation Host": { icon: <GiConfirmed size={23} color="#f43254" />, link: "/events/Featured" },//
        "Password Reset Confirmation": { icon: <BiSolidEditAlt size={23} color="#f43254" />, link: "#" },
        "Host Send Message": { icon: <TiMessageTyping size={23} color="#f43254" />, link: "#" },
        // "changeEmail": { icon: <BiSolidEditAlt size={23} color="#f43254" />, link: "#" },//
        // "changeNumber": { icon: <BiSolidEditAlt size={23} color="#f43254" />, link: "#" },//

        // Reminder for Upcoming Event To Sponsor
        // Event Status Change
        // Location-based  to sponsor
        // Last Minute Deals
        // Personalized
        // New Event Added To Sponsor
        // Deals & Promotions
        // Recommendations based on history
        // Subscription Renewal
        // Event Booking for Sponsorship Confirmation (Sponsor)
        // Event Booking for Sponsorship Confirmation (Host)
        // Subscription Expiry Alert
        // Payment Confirmation - Sponsor
        // Payment Confirmation - Host
        // Refund Processed
        // Sign up - Champ
        // Commission Payout - Champ
        // New Event Hosted by His Host - Champ
        // New Event Sponsored by His Sponsor - Champ
        // Event Coupon Created for Specific Sponsor
        // Referral Code Used - Champ
    };

    const IconComponent = ({ iconType }) => {
        const item = iconMap[iconType] || { icon: <div>No icon</div> };
        return <div>{item.icon}</div>;
    };

    const handlePageClick = (selectedPage) => {
        setCurrentPage(selectedPage.selected + 1);
    };
    return (
        <>
            <div className="container py-3">
                <div className="row">
                    {location?.pathname == "/notification" ?
                        <div className="col-md-12 p-0 d-flex justify-content-between">
                            <h3 className="all-notification-head">All Notification</h3>
                            <button className="go-back-btn" onClick={() => navigate(-1)} type="button">
                                <TiArrowLeft size={20} /> Go Back
                            </button>
                        </div> : ''}
                    <div className="col-md-12 msg-date-time-bar">
                        <span className="Message">Message</span>
                        <span className="Date-Time">Date & Time</span>
                    </div>
                    <div className="msg-datetime-header px-0">
                        {loading ? <Loader2 /> :
                            showNotification?.data?.map((val) => {
                                return (
                                    <Link className="col-md-12 msg-datetime-value" to={iconMap[val?.notification_message_type]?.link || "/"} >
                                        <span className="tradeShow d-flex gap-3"><IconComponent iconType={val?.notification_message_type} />{val?.message}</span>
                                        <span className="tradeDate-time d-flex justify-content-end"> {moment(val?.created_at)?.format("MMM DD, YYYY | hh:mm A")}</span>
                                    </Link>
                                )
                            })}
                    </div>
                </div>

                {showNotification?.data?.length < showNotification?.total && (
                    <div className="pagination-container">
                        <ReactPaginate
                            pageCount={Math.ceil(showNotification?.total / showNotification?.per_page)}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageClick}
                            containerClassName="pagination"
                            activeClassName="selected"
                            disabledClassName="disabled"
                        />
                    </div>
                )}
            </div >
        </>
    )
}
export default Notification