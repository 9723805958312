import { CreatePast_eventorganizer, CreatePast_eventpitch, CreatePastevent, Delete_Past_Event, Get_PastEvent_Essentials, Get_PastEvent_GuestForm, Get_PastEvent_Host, Get_PastEvent_MediaHub, Get_PastEvent_Partnerships, Get_PastEvent_Pitch, Get_PastEvent_SocialHub, Get_PastEvent_SponsorPackage, PastEventDltConfirmedSponsor, PastEventEssentialsUpdate, PastEventGetConfirmedSponsor, PastEventImageDocsDlt, PastEventUpdateSponsorPackage, PastEvent_BannerDelete, PastEvent_UpdateConfirmedSponsor, PastEvent_delete_Package, createPastEvent_MediaHub, createPastEvent_SocialHub, createPastEvent_SponsorPackage, createPastEventpartnership, create_pastEvent_EventEssentials, create_pastEvent_Guest, create_pastEvent_Host, create_pastEvent_Pitch, getPastEventSponsorPackages, get_my_past_events } from "../../Services/Apis";

// create past event basicinformation
export const createPastEventForm = async (info) => {
    try {
        return await CreatePastevent(info).then((data) => data)
    } catch (err) {
        throw err
    }
}

// create past event pitch
export const createPastEventPitch = async (info, event_id) => {
    try {
        return await CreatePast_eventpitch(info, event_id).then((data) => data)
    } catch (err) {
        throw err
    }
}

// create past event Organizer

export const createPastEventOrganizer = async (info, event_id) => {
    try {
        return await CreatePast_eventorganizer(info, event_id).then((data) => data)
    } catch (err) {
        throw err
    }
}

// get created pastEventlist
export const getMyPastEvent = async (eventMode, event_status, eventType, currentPage, search_event, sortVal, eventCategory, status,no_Participants,
    eventBudgetValue,srt_Evt_Date,durationValue,cityS,languages,SponsorshipClosingDate,distanceRange,hastgg,prime_host,premier,
) => {
    try {
        return await get_my_past_events(eventMode, event_status, eventType, currentPage, search_event, sortVal, eventCategory, status,no_Participants,
            eventBudgetValue,srt_Evt_Date,durationValue,cityS,languages,SponsorshipClosingDate,distanceRange,hastgg,prime_host,premier,
        ).then((data) => data)
    } catch (err) {
        throw err
    }

}


// create past event start -------------------------------------------

export const createPastEventEventEssentials = async (info) => {
    try {
        return await create_pastEvent_EventEssentials(info).then((data) => data)
    } catch (err) {
        throw err
    }
}

export const createPastEventGuest = async (info, event_id) => {
    try {
        return await create_pastEvent_Guest(info, event_id).then((data) => data)
    } catch (err) {
        throw err
    }
}

export const CreatePastEventPitch = async (info, event_id) => {
    try {
        return await create_pastEvent_Pitch(info, event_id).then((data) => data)
    } catch (err) {
        throw err
    }
}

export const CreatePastEventHost = async (event_id, info) => {
    try {
        return await create_pastEvent_Host(event_id, info).then((data) => data)
    } catch (err) {
        throw err
    }
}



export const Create_PastEventPartnerShip = async (event_id, info) => {
    try {
        return await createPastEventpartnership(event_id, info).then((data) => data)
    } catch (err) {
        throw err
    }
}


export const CreatePastEventSponsorPackage = async (event_id, info) => {
    try {
        return await createPastEvent_SponsorPackage(event_id, info).then((data) => data)
    } catch (err) {
        throw err
    }
}

export const CreatePastEventMediaHub = async (event_id, FData) => {
    try {
        return await createPastEvent_MediaHub(event_id, FData).then((data) => data)
    } catch (err) {
        throw err
    }
}

export const CreatePastEventSocialHub = async (event_id, info) => {
    try {
        return await createPastEvent_SocialHub(event_id, info).then((data) => data)
    } catch (err) {
        throw err
    }
}
// -----------------------------------


// Edit past event start Get Api ------------------------------------------

export const PastEventEssentialsEdit = async (id, item) => {
    try {
        return await PastEventEssentialsUpdate(id, item).then((data) => data)
    } catch (err) {
        throw err
    }
}


export const GetPastEventEssentials = async (id) => {
    try {
        return await Get_PastEvent_Essentials(id).then((data) => data)
    }
    catch (error) {
        console.log(error)
    }
}


export const GetPastEventGuestForm = async (id) => {
    try {
        return await Get_PastEvent_GuestForm(id).then((data) => data)
    }
    catch (error) {
        console.log(error)
    }
}


export const GetPastEventPitch = async (id) => {
    try {
        return await Get_PastEvent_Pitch(id).then((data) => data)
    }
    catch (error) {
        console.log(error)
    }
}

export const GetPastEventHost = async (id) => {
    try {
        return await Get_PastEvent_Host(id).then((data) => data)
    }
    catch (error) {
        console.log(error)
    }
}

export const GetPastEventPartnerships = async (id) => {
    try {
        return await Get_PastEvent_Partnerships(id).then((data) => data)
    } catch (err) {
        console.log(err)
    }
}


export const GetPastEventSponsorShipPackage = async (id) => {
    try {
        return await Get_PastEvent_SponsorPackage(id).then((data) => data)
    } catch (err) {
        console.log(err)
    }
}

export const GetPastEventMediaHub = async (id) => {
    try {
        return await Get_PastEvent_MediaHub(id).then((data) => data)
    } catch (err) {
        console.log(err)
    }
}

export const PastEventBannerDelete = async (id) => {
    try {
        return await PastEvent_BannerDelete(id).then((data) => data)
    } catch (err) {
        console.log(err)
    }
}

export const GetPastEventSocialHub = async (id) => {
    try {
        return await Get_PastEvent_SocialHub(id).then((data) => data)
    } catch (err) {
        console.log(err)
    }
}


// delete past event image or docs 

export const Delete_PastEvent_ImgDoc = async (id) => {
    try {
        return await PastEventImageDocsDlt(id).then((data) => data)
    }
    catch (error) {
        console.log(error)
    }
}

// sponsor package get single package
export const GetPastEventPackagesData = async (id) => {
    try {
        return await getPastEventSponsorPackages(id).then((data) => data)
    }
    catch (error) {
        console.log(error)
    }
}

//sponsor package update single 

export const PastEvent_Update_SponsorPackage = async (id, fdata) => {
    try {
        return await PastEventUpdateSponsorPackage(id, fdata).then((data) => data)
    } catch (error) {
        console.log(error)
    }
}

// sponsor delete single
export const DeletePastEventPackage = async (package_id) => {
    try {
      return await PastEvent_delete_Package(package_id).then((data) => data)
    }
    catch (error) {
      console.log(error)
    }
  }


  export const PastEvent_Confirmed_Sponsor_Dlt = async (id) => {
    try {
        return await PastEventDltConfirmedSponsor(id).then((data) => data)
    } catch (err) {
        console.log(err)
    }
}

export const PastEventGetSingleConfirmedSponsor = async (id) => {
    try {
        return await PastEventGetConfirmedSponsor(id).then((data) => data)
    } catch (err) {
        console.log(err)
    }
}

export const PastEvent_Confirmed_Sponsor_Update = async (id, val) => {
    try {
        return await PastEvent_UpdateConfirmedSponsor(id, val).then((data) => data)
    } catch (err) {
        console.log(err)
    }
}

export const Delete_PastEvent = async (id) => {
    try {
        return await Delete_Past_Event(id).then((data) => data)
    } catch (err) {
        console.log(err)
    }
}
