import React, { useEffect, useState } from "react";
import Pricing from "../../components/Membership/Pricing";
import { Get_MembershipPlans } from "../../utils/membershipevent/manageMembershipEvent";
import Loader2 from "../../components/Loader/Loader2";
import { Link } from "react-router-dom";

const MembershipEvent = () => {
  const [membershipPlanData, setMembershipPlanData] = useState([]);
  const [loading,setLoading]=useState(true);
  const [tabValue,setTabValue]=useState("Sponsor");

  const GetMembershipPlanData = async () => {
     setLoading(true);
    try {
      const response = await Get_MembershipPlans(tabValue);
      setMembershipPlanData(response?.data?.data);
      // console.log("res",response?.data?.data)
    } catch (error) {
      console.log(error);
    }finally{
      setLoading(false);
    }
  };

  useEffect(() => {
    GetMembershipPlanData();
  },[tabValue]);

  return (
    <>
      <section className="pricing-area ptb-120 ">
        <div className="container">
          <div className="section-title align-items-center ">
            {/* <span>Pricing Plan</span> */}
            <div className="row align-items-center">
              <div className="col-6 col-md-4">
            <h2 className=" mb-0">Membership</h2>
            </div>
              <div className="d-none d-md-block col-sm-5 ">
                    <div className="d-flex search-div">
                        <button onClick={() => setTabValue("Sponsor")} className={`${tabValue == "Sponsor" ? "active-tab-button" : "tab-button"} col-6 `}>Sponsor</button>
                        <button onClick={() => setTabValue("Host")} className={`${tabValue == "Host" ? "active-tab-button" : "tab-button"} col-6 `}>Host</button>
                    </div>
              </div>

               <div className="col-6 col-md-3 d-flex justify-content-end ">
                <Link className="interests-edit-btn" to="/membershiptransaction">Transactions</Link>
               </div>
              </div>

              <div className="">
               <div className="d-md-none mt-4 col-12 ">
                    <div className="d-flex search-div ">
                        <button onClick={() => setTabValue("Sponsor")} className={`${tabValue == "Sponsor" ? "active-tab-button" : "tab-button"} col-6 `}>Sponsor</button>
                        <button onClick={() => setTabValue("Host")} className={`${tabValue == "Host" ? "active-tab-button" : "tab-button"} col-6 `}>Host</button>
                    </div>
                 </div>
              </div>
          </div>
          
          {
            loading ? <Loader2/> :
            membershipPlanData.length <= 0 ?
            <h4 className="text-center">No Data Found</h4> :
            <div className="row justify-content-center justify-content-md-start">
            {membershipPlanData?.map((val) => {
             return (
             <Pricing val={val}/>
              );
            })}
          </div>
          }
        </div>
      </section>
    </>
  );
};
export default MembershipEvent;
