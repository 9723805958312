import { Link } from "react-router-dom";

const RefundAndCancellationPolicy = () => {
  return (
    <>
      <div className="refund-cancellation-policy">
        <h1 className="text-center">Refund and Cancellation Policy for SponsorRingUs Payments</h1>
        <p>
          At SponsorRingUs we prioritize transparency and customer satisfaction
          in our refund and cancellation policies to ensure a positive
          experience for all users. Our policy encompasses various scenarios and
          guidelines to address different circumstances effectively.
        </p>

        <h3>Refund Eligibility:</h3>
        <p>
          Full Refunds are granted under specific conditions, including technical errors resulting in overpayments or duplicate charges, event cancellations by the organizer, subject to our discretion or as per the Refund / Cancellation terms mentioned on the Events page by the Event host.
        </p>
        <p> For dissatisfaction with service quality or failure to deliver promised benefits by the event host/organizer, the outcome depends on the circumstances and may result in a full refund, partial refund, or no refund, subject to our discretion.</p>

        <p>The refund amount will be subject to deductions for any applicable processing fees or administrative charges.</p>

        <h3>Refund Requests:</h3>
        <p>
          Customers seeking a refund must promptly contact SponsorRingUs
          customer support at
          <Link
            className=""
            target="_blank"
            to="mailto:support@sponsorringus.com"
          >
            support@sponsorringus.com
          </Link>
          , providing detailed information about the payment and the reason for
          the refund request. Our team diligently reviews each request and
          strives to process refunds within 7-14 business days.
        </p>

        <h3>Cancellation by Sponsor Policy:</h3>
        <p>
          Sponsors wishing to cancel Sponsorship made on this must notify
          SponsorRingUs customer support at{" "}
          <Link
            className="px-1"
            target="_blank"
            to="mailto:support@sponsorringus.com"
          >
            support@sponsorringus.com
          </Link>
          or at <span className="htw-span">+91-8104443227</span> promptly within 45 days before the event date or the specified cancellation period on the event page by event host, whichever is earlier. The cancellation period and any associated penalties or fees will be outlined on the event page or in the sponsorship agreement between the sponsor and the organizer. The outcome of the cancellation, whether it results in a full refund, partial refund, or no refund, depends on the circumstances and is subject to our discretion. Refunds for cancelled sponsorships will be processed within 15 business days of receiving the cancellation request, minus any applicable processing fees or administrative charges.
        </p>

        <h3>Cancellation by Organizer Policy:</h3>
        <p>
          If an event organizer cancels the sponsored event for any reason, sponsors will be entitled to a full refund of their sponsorship fee. Refunds will be processed automatically by SponsorRingUs and will be issued within [15] business days of the event cancellation. The refund amount will be subject to deductions for any applicable processing fees or administrative charges
        </p>

        <h3>Payment Disputes:</h3>
        <p>
          Any suspected unauthorized or fraudulent charges must be reported to SponsorRingUs customer support at support@sponsorringus.com immediately for investigation. We are committed to resolving payment disputes fairly and expeditiously.
        </p>

        <h3>No Show Policy:</h3>
        <p>
          If a sponsor fails to arrive on time, experiences delays, or fails to fulfil their sponsorship activities without prior notification or valid reason, they will not be allowed to participate and will not be eligible for any refund. In the event of repeated no-shows by a sponsor, the organizer reserves the right to take appropriate action, which may include penalties or restrictions on future participation on the SponsorRingUs platform.
        </p>

        <h3>Force majeure policy:</h3>
        <p>
          Force majeure policies cover cancellations due to natural disasters or government restrictions, such as those during COVID. Force majeure cancellations due to aforementioned reasons are generally free. Though Brand promotion activities begin upon finalization of sponsorship, Refunds are subject to host discretion and the progress of promotional activities The refund amount will be subject to deductions for any applicable processing fees or administrative charges.
        </p>

        <h3>Method of Refund:</h3>
        <p>
          Refunds will typically be issued using the original method of payment.
          In cases where this is not feasible, alternative arrangements may be
          made at our discretion.
        </p>

        <h3>Disputes and Exceptions:</h3>
        <p>
          Any disputes regarding refunds or cancellations will be handled on a case-by-case basis. Exceptions to the refund policy may be considered in extenuating circumstances, subject to approval by us.
        </p>
        {/* Other sections go here */}

        <h3>Changes to Policy:</h3>
        <p>
          SponsorRingUs reserves the right to modify this refund and cancellation policy at any time without prior notice. The update policy shall be updated on the platform time to time.
        </p>

        <p>
          By utilizing SponsorRingUs services and making payments, users agree
          to adhere to this refund and cancellation policy. If there are any
          inquiries or concerns regarding this policy, customers are encouraged
          to contact us at
          <Link
            className="px-1"
            target="_blank"
            to="mailto:support@sponsorringus.com"
          >
            support@sponsorringus.com
          </Link>
          for clarification and assistance.
        </p>
      </div>
    </>
  );
};

export default RefundAndCancellationPolicy;
