import React from 'react';
import {
    isMobile,
    isTablet,
    isDesktop,
    isAndroid,
    isIOS,
    isBrowser,
} from 'react-device-detect';

const DeviceDetectComponent = () => {
    return (
        <div>
            {isTablet && 'Tablet'}
            {isDesktop && 'Desktop'}
            {isAndroid && 'Android Device'}
            {isIOS && 'IOS Device'}
        </div>
    );
};

export default DeviceDetectComponent;
