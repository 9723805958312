import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    ProfileData: {},
}


export const Profile_data = createSlice({
    name: 'ProfileValues',
    initialState: initialState,
    reducers: {
        profileval: (state, action) => {
            state.ProfileData = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { profileval } = Profile_data.actions;

export default Profile_data.reducer;