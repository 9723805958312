import { useSelector } from "react-redux";
import Interests from "../../components/Profile/Interests";
import Statistics from "../../components/Profile/Statistics";
import { useEffect, useState } from "react";
import Loader from "../../components/Loader/Loader";
import ChampProfileSection from "../../components/ChampProfile/ChampProfileSection";

const ChampProfile = () => {
  const [loading, setLoading] = useState(true);
  const profileinfo = useSelector((data) => data?.Profile_data?.ProfileData);
  const ConfigData = useSelector((state) => state?.config_data?.configData);
  const baseUrls = ConfigData?.base_urls;

  useEffect(() => {
    const isObjectEmpty = profileinfo && Object.keys(profileinfo)?.length === 0;
    if (isObjectEmpty) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [profileinfo]);


  return (<>
    {
      loading ? <Loader />
        :
        <div className="container py-4">
          <div className="pt-4">
            <ChampProfileSection val={profileinfo} baseUrls={baseUrls}/>
          </div>

          <div className="py-5">
            <Interests interest={profileinfo?.interest} />
          </div>
          {/* <div className="pt-5">
            <Statistics />
          </div> */}
        </div >
    }
  </>)
};
export default ChampProfile;