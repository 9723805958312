import React, {useState,useEffect} from 'react';
import { ChangeUserPassword, logActivityCheck, pass_systemIpAddress, systemIpAddress } from '../../utils/user/UserManager';
import { toast } from 'react-toastify';
import { Tooltip } from "react-tooltip";

const ChangePassword = () => {
    const [passwords, setPasswords] = useState({
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: ''
    });

    const [errors, setErrors] = useState({
        currentPasswordError: '',
        newPasswordError: '',
        confirmNewPasswordError: ''
    });
    const [informationIp, setInformationIp] = useState({});

    const validatePasswords = () => {
        const trimmedCurrentPassword = passwords.currentPassword.trim();
        const trimmedNewPassword = passwords.newPassword.trim();
        const trimmedConfirmNewPassword = passwords.confirmNewPassword.trim();

        let valid = true;
        let newErrors = {
            currentPasswordError: '',
            newPasswordError: '',
            confirmNewPasswordError: ''
        };

        if (!trimmedCurrentPassword) {
            newErrors.currentPasswordError = 'Current password cannot be empty or spaces only.';
            valid = false;
        }
        if (!trimmedNewPassword) {
            newErrors.newPasswordError = 'New password cannot be empty or spaces only.';
            valid = false;
        }
        if (trimmedNewPassword.length < 8) {
            newErrors.newPasswordError = 'New password must be at least 8 characters long.';
            valid = false;
        } else if(!/[A-Z]/.test(trimmedNewPassword)) {
            newErrors.newPasswordError = "New Password must contain at least one uppercase letter.";
            valid = false;
          }else if (!/[a-z]/.test(trimmedNewPassword)) {
            newErrors.newPasswordError = "New Password must contain at least one lowercase letter.";
            valid = false;
          } else if (!/[0-9]/.test(trimmedNewPassword)) {
            newErrors.newPasswordError = "New Password must contain at least one number.";
            valid = false;
          } else if (!/[!@#$%^&*]/.test(trimmedNewPassword)) {
            newErrors.newPasswordError = "New Password must contain at least one special character.";
            valid = false;
          }
        //   else if (/(.)\1{2,}/.test(trimmedNewPassword)) {
        //     newErrors.newPasswordError = "Your password must not contain more than 2 consecutive identical characters.";
        //     valid = false;
        //   } 
          else {
            newErrors.newPasswordError = "";
          }

        if (!trimmedConfirmNewPassword) {
            newErrors.confirmNewPasswordError = 'Confirm new password cannot be empty or spaces only.';
            valid = false;
        }
        if (!trimmedConfirmNewPassword) {
            newErrors.confirmNewPasswordError = 'Confirm new password is required';
            valid = false;
        }
        if (trimmedNewPassword !== trimmedConfirmNewPassword) {
            newErrors.confirmNewPasswordError = 'New password and confirm new password do not match.';
            valid = false;
        }
        setErrors(newErrors);
        return valid;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPasswords({
            ...passwords,
            [name]: value
        });
    };

    const SystemIp = async () => {
        try {
          await systemIpAddress()
            .then((response) => {
              passIpAddress(response?.data?.ip)
            })
        } catch (err) {
          console.log(err)
        }
      }
    
      const passIpAddress = async (ipv4) => {
        try {
          await pass_systemIpAddress(ipv4)
            .then((response) => {
              setInformationIp(response?.data)
            })
        } catch (err) {
          console.log(err)
        }
      }

      useEffect(() => {
        SystemIp();
      }, [])

    const loggedActivity = async () => {
        const data = {
          ip_address: informationIp?.ip,
          lat: informationIp?.latitude,
          long: informationIp?.longitude,
          log_type:'changePassword'
       }
        try {
          await logActivityCheck(data)
            .then((response) => {
                console.log("activity",response)
              // console.log(response)
              // if (response?.status == 200) {
              //   localStorage.setItem("LoginToken", loginToken);
              //   navigate("/");
              // }
            })
        } catch (err) {
          console.log(err)
        }
      }
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validatePasswords()) {
            const Data = {
                old_password: passwords?.currentPassword,
                new_password: passwords?.newPassword,
                confirm_password: passwords?.confirmNewPassword
            }
            try {
                await ChangeUserPassword(Data)
                    .then((response) => {
                        if (response.status == 200) {
                            toast.success(response?.data?.message, {
                                position: "top-center",
                                autoClose: 3000,
                            })
                            setPasswords({
                                currentPassword: '',
                                newPassword: '',
                                confirmNewPassword: ''
                            })
                            loggedActivity()
                        }
                    })
            } catch (err) {
                  toast.error(err?.response?.data?.error, {
                    position: "top-center",
                    autoClose: 3000,
             })
            }
    }};

    return (<>
    <Tooltip id="password-example-tooltip" className="tooltip_style">
     <div style={{ display: 'flex', flexDirection: 'column',gap:3}}>
     <span className="tooltip-password-head">Password must contain:</span>
     <span className="mt-2">-At least one uppercase letter </span>
     <span>-At least one lowercase letter</span>
     <span>-At least one special character</span>
     <span>-At least one number</span>
     <span>-Be at least 8 characters</span>
     {/* <span>-Your password must not contain more than 2 consecutive identical characters.</span> */}
     </div>
    </Tooltip>

        <div className="change-password-container">
            <h4 className=""> Change Password</h4>
            <form onSubmit={handleSubmit}>
                <div className="row gap-md-2">
                    <div className="form-group col-md-12">
                        <label className="form-label">
                            Current Password<span className="asterisk">*</span>
                        </label>
                        <input
                            type="password"
                            className="form-control"
                            name="currentPassword"
                            placeholder='Current Password'
                            value={passwords.currentPassword}
                            onChange={handleChange}
                        />
                        {errors.currentPasswordError && (
                          <span className="error">{errors.currentPasswordError}</span>
                        )}
                    </div>

                    <div className="form-group col-md-12">
                        <label className="form-label">
                            New Password<span className="asterisk">*</span>
                        </label>
                        <input
                            data-tooltip-id="password-example-tooltip"
                            type="password"
                            className="form-control"
                            name="newPassword"
                            placeholder='New Password'
                            value={passwords.newPassword}
                            onChange={handleChange}
                        />
                        {errors.newPasswordError && (
                            <span className="error">{errors.newPasswordError}</span>
                        )}
                    </div>

                    <div className="form-group col-md-12">
                        <label className="form-label">
                            Confirm Password<span className="asterisk">*</span>
                        </label>
                        <input
                            type="password"
                            className="form-control"
                            name="confirmNewPassword"
                            placeholder='Confirm New Password'
                            value={passwords.confirmNewPassword}
                            onChange={handleChange}
                        />
                        {errors.confirmNewPasswordError && (
                            <span className="error">{errors.confirmNewPasswordError}</span>
                        )}
                    </div>

                </div>
                {/* <div>
                    <label htmlFor="currentPassword">Current Password:</label>
                    <input
                        type="password"
                        id="currentPassword"
                        name="currentPassword"
                        placeholder='Current Password'
                        value={currentPassword}
                        onChange={handleChange}
                        required
                    />
                </div> */}
                {/* <div>
                    <label htmlFor="newPassword">New Password:</label>
                    <input
                        type="password"
                        id="newPassword"
                        name="newPassword"
                        placeholder='New Password'
                        value={newPassword}
                        onChange={handleChange}
                        required
                    />
                </div> */}
                {/* <div>
                    <label htmlFor="confirmNewPassword">Confirm New Password:</label>
                    <input
                        type="password"
                        id="confirmNewPassword"
                        name="confirmNewPassword"
                        placeholder='Confirm New Password'
                        value={confirmNewPassword}
                        onChange={handleChange}
                        required
                    />
                </div> */}
                <button type="submit">Change Password</button>
            </form>
        </div>
        </>
    );
};

export default ChangePassword;
