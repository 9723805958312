import { FaPlus } from "react-icons/fa6";
import arrow from "../../assets/images/arrow2.svg";
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md";
import { useEffect, useState } from "react";
import { predefinePackage } from "../../utils/packagesform/packagesFormManager";
import { getEventPeopleRangeData } from "../../utils/basicInformation/basicinformationManager";
import { useSelector } from "react-redux";
import ReactQuill from "react-quill";
import Loader from "../Loader/Loader";
import {
  Packages_get_event,
  Packages_post_event,
} from "../../utils/editevents/EditEvent";
import { toast } from "react-toastify";

const EditPackagesModelForm = ({ setOpen, packages_id }) => {
  const [predefineData, setPredefineData] = useState([]);
  const [eventPeopleRange, setEventPeopleRange] = useState([]);
  const allConfingData = useSelector((state) => state.config_data.configData);
  const [description, setDescription] = useState("");
  const [selectedCurrencySymbol, setSelectedCurrencySymbol] = useState(3);
  const [selectedPeople_id, setSelectedPeople_id] = useState();

  const [sponsorInput, setSponsorInput] = useState({
    title: "",
    deadline: "",
    amount: "",
    number_of_guests: "",
    currency_id: "",
    available_package: "",
    discription: "",
  });

  const [errors, setErrors] = useState({});

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  const [pageLoad, setPageLoad] = useState(true);

  const getPredefineData = async () => {
    try {
      await predefinePackage().then((response) => {
        setPredefineData(response?.data);
      });
    } catch (err) {
      console.log(err);
    } finally {
      setPageLoad(false);
    }

    try {
      await getEventPeopleRangeData().then((response) => {
        setEventPeopleRange(response?.data);
      });
    } catch (err) {
      console.log(err);
    }

    try {
      await Packages_get_event(packages_id).then((response) => {
        setSponsorInput(response?.data?.data);
        setDescription(response?.data?.data?.discription);
        setSelectedCurrencySymbol(response?.data?.data?.currency_id);
        setSelectedPeople_id(response?.data?.data?.people_range_id);
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getPredefineData();
  }, []);

  const handleEditorChange = (html) => {
    setDescription(html);
  };

  const handleChangeCurrencySymbol = (e) => {
    const item = e.target.value;
    setSelectedCurrencySymbol(item);
  };

  const handleChangePeople = (e) => {
    const item = e.target.value;
    setSelectedPeople_id(item);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name == "number_of_guests") {
      if (value.length <= 6) {
        setSponsorInput({
          ...sponsorInput,
          [name]: value.replace(/\D/g, ""), // This also ensures only numbers are allowed
        });
      }
    } else {
      setSponsorInput({
        ...sponsorInput,
        [name]: value,
      });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const frm = {
      ...sponsorInput,
      discription: description,
      currency_id: selectedCurrencySymbol,
    };

    if (validateForm()) {
      try {
        await Packages_post_event(frm, packages_id).then((result) => {
          if (result?.data?.success) {
            toast.success(result?.data?.message);
            setOpen(false);
          }
        });
      } catch (error) {
        console.log(error, "error");
      }
    }
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    if (
      !sponsorInput ||
      typeof sponsorInput.title !== "string" ||
      sponsorInput.title.trim() === ""
    ) {
      newErrors.title = "Name is required";
      valid = false;
    }

    if (
      !sponsorInput ||
      isNaN(sponsorInput.amount) ||
      typeof sponsorInput.amount !== "string" ||
      sponsorInput.amount.trim() === ""
    ) {
      newErrors.amount = "Price is required";
      valid = false;
    }

    // if (!sponsorInput || typeof sponsorInput.people_range_id !== 'number' || isNaN(sponsorInput.people_range_id) || sponsorInput.people_range_id <= 0) {
    //     newErrors.people_range_id = 'Audience is required';
    //     valid = false;
    // }

    if (
      !sponsorInput ||
      (typeof sponsorInput.number_of_guests !== "number" &&
        typeof sponsorInput.number_of_guests !== "string") ||
      isNaN(sponsorInput.number_of_guests) ||
      sponsorInput.number_of_guests <= 0
    ) {
      newErrors.number_of_guests = "Guests is required";
      valid = false;
    }

    if (
      !sponsorInput ||
      typeof sponsorInput.deadline !== "string" ||
      sponsorInput.deadline.trim() === ""
    ) {
      newErrors.deadline = "Date is required";
      valid = false;
    }

    if (
      !sponsorInput ||
      (typeof sponsorInput.available_package !== "number" &&
        typeof sponsorInput.available_package !== "string") ||
      isNaN(sponsorInput.available_package) ||
      sponsorInput.available_package <= 0
    ) {
      newErrors.available_package = "Available package is required";
      valid = false;
    }

    if (
      !description ||
      typeof description !== "string" ||
      description.trim() === ""
    ) {
      newErrors.description = "Description is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  return (
    <>
      <div className="package_form_overlay">
        <div className="package_form_popup">
          <div className="package_form_popup-content">
            <span className="package_form_close" onClick={() => setOpen(false)}>
              &times;
            </span>
            <div className="basicInfo ">
              <h2 className="basicinfo-head">
                What will you offer to the sponsor?
              </h2>
              <p className="basicinfo-p">What will you offer to the sponsor?</p>

              <div className="row ">
                <div className="col-md-4">
                  <div className="sidemenu">
                    <button className="btn add-new-packages">
                      Add New Package
                    </button>

                    <div className="select-pack-div">
                      <p className="select-pack-text">
                        or select from our predefined packages:
                      </p>
                    </div>
                    {predefineData?.map((val, index) => {
                      return (
                        <>
                          <div
                            className="d-flex align-items-center"
                            key={val?.id}>
                            <div className="plus-div">
                              <FaPlus color="#f43254" size={18} />
                            </div>
                            <button
                              className="package-btn"
                              onClick={() =>
                                setSponsorInput({ title: val?.name })
                              }
                               >
                              <p className="package-text">{val?.name}</p>
                            </button>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>

                <div className="row col mt-4 mt-md-0">
                  <h4 className="form-heading"> Sponsorship Package</h4>
                  <div className="col-md-8">
                    <form onSubmit={onSubmit}>
                      <div className="form-row">
                        <div className="form-group">
                          <label className="form-label">
                            Name<span className="asterisk">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={sponsorInput.title}
                            name="title"
                            placeholder="Name"
                            onChange={handleInputChange}
                          />
                          {errors.title && (
                            <span className="error">{errors.title}</span>
                          )}
                        </div>
                        <div
                          className="form-group"
                          style={{ position: "relative" }}
                        >
                          <label>
                            Price<span className="asterisk">*</span>
                          </label>
                          <input
                            type="number"
                            value={sponsorInput.amount}
                            name="amount"
                            className="form-control"
                            placeholder="Price"
                            onChange={handleInputChange}
                          />
                          <select
                            className="currency_tag"
                            name="currency_id"
                            value={selectedCurrencySymbol}
                            onChange={handleChangeCurrencySymbol}
                          >
                            {allConfingData?.currency_list?.map(
                              (val, index) => {
                                return (
                                  <>
                                    <option key={val?.id} value={val?.id}>
                                      {val?.code} {val?.symbol}
                                    </option>
                                  </>
                                );
                              }
                            )}
                          </select>
                          {errors.amount && (
                            <span className="error">{errors.amount}</span>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-md-6">
                          <label className="form-label">
                            Guests<span className="asterisk">*</span>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            name="number_of_guests"
                            placeholder="Guest"
                            value={sponsorInput?.number_of_guests}
                            onChange={handleInputChange}
                          />
                          {errors?.number_of_guests && (
                            <span className="error">
                              {errors?.number_of_guests}
                            </span>
                          )}
                        </div>
                        <div className="form-group col-md-6">
                          <label className="form-label">
                            Available Packages
                            <span className="asterisk">*</span>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            name="available_package"
                            value={sponsorInput.available_package}
                            onChange={handleInputChange}
                          />
                          {errors.available_package && (
                            <span className="error">
                              {errors.available_package}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="form-label">
                          Select Date<span className="asterisk">*</span>
                        </label>
                        <div>
                          <input
                            type="date"
                            className="form-control"
                            name="deadline"
                            value={sponsorInput.deadline}
                            onChange={handleInputChange}
                          />
                        </div>
                        {errors.deadline && (
                          <span className="error">{errors.deadline}</span>
                        )}
                      </div>

                      <div className="form-group">
                        <label>
                          Sponsorship Package includes:
                          <span className="asterisk">*</span>
                        </label>

                        <ReactQuill
                          className="reactQuill"
                          theme="snow"
                          value={description}
                          onChange={handleEditorChange}
                          formats={formats}
                          placeholder="Write something..."
                        />
                        {errors.description && (
                          <span className="error">{errors.description}</span>
                        )}
                      </div>

                      <div className="d-flex justify-content-end align-items-center mt-5">
                        <button className="delete-event-btn">Close</button>
                        <div className="">
                          <button
                            className="btn saveandcontinue-btn"
                            type="submit">
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditPackagesModelForm;
