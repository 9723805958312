import { useState } from "react";
import { Reject_Msg_Send } from "../../utils/eventdetail/eventdetail";
import { ToastContainer, toast } from "react-toastify";
import { IoClose } from "react-icons/io5";

const RejectPopup = ({ setRejectPopup, event, getRejected }) => {
  const [input, setInput] = useState({
    general_reason: "",
    event_essentials_reason: "",
    event_guests_reason: "",
    event_pitch_reason: "",
    event_host_reason: "",
    event_partnerships_reason: "",
    sponsor_packages_reason: "",
    event_coupons_reason: "",
    event_media_hub_reason: "",
    event_social_hub: "",
    event_cancellation_and_refund_reason: "",
    event_references_reason: "",
  });

  const handleChange = (e) => {
    const { value, name } = e.target;
    setInput({ ...input, [name]: value });
  };

  const HandleSave = async () => {
    const isAtLeastOneInputFilled = Object.values(input).some(
      (value) => value.trim() !== ""
    );

    if (!isAtLeastOneInputFilled) {
      toast.warn("Please fill at least one input field.", {
        position: "top-center",
        autoClose: 3000,
      });
      return;
    }

    try {
      const response = await Reject_Msg_Send(event?.id, input);
      console.log("response",response)
      if (response?.success) {
        toast.success(response?.message,{
            position:'top-center'
        });

        getRejected();
        setInput({
          general_reason: "",
          event_essentials_reason: "",
          event_guests_reason: "",
          event_pitch_reason: "",
          event_host_reason: "",
          event_partnerships_reason: "",
          sponsor_packages_reason: "",
          event_coupons_reason: "",
          event_media_hub_reason: "",
          event_social_hub: "",
          event_cancellation_and_refund_reason: "",
          event_references_reason: "",
        });
        setRejectPopup(false)
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <ToastContainer />
      <div>
        <div className="reject-popup">
          <div className="reject-popup-content">
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="basicinfo-head m-0 p-0">Rejected</h2>
              <button
                className="btn"
                onClick={() => setRejectPopup(false)}>
                <IoClose color="black" size={24} />
              </button>
            </div>
            <div className="form-group mt-3">
              <p className="rejected-form-p m-0 p-0">General</p>
              <i className="reject-p p-0 m-0">{event?.general_reason}</i>
              <input
                type="text"
                value={input?.general_reason}
                name="general_reason"
                className="form-control"
                placeholder="Enter Your Message Here..."
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <p className="rejected-form-p m-0 p-0">Essentials</p>
              <i className="reject-p p-0 m-0">
                {event?.event_essentials_reason}
              </i>
              <input
                type="text"
                value={input?.event_essentials_reason}
                name="event_essentials_reason"
                className="form-control"
                placeholder="Enter Your Message Here..."
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <p className="rejected-form-p m-0 p-0">Guests</p>
              <i className="reject-p p-0 m-0">{event?.event_guests_reason}</i>
              <input
                type="text"
                value={input?.event_guests_reason}
                name="event_guests_reason"
                className="form-control"
                placeholder="Enter Your Message Here..."
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <p className="rejected-form-p m-0 p-0">Pitch</p>
              <i className="reject-p p-0 m-0">{event?.event_pitch_reason}</i>
              <input
                type="text"
                value={input?.event_pitch_reason}
                name="event_pitch_reason"
                className="form-control"
                placeholder="Enter Your Message Here..."
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <p className="rejected-form-p m-0 p-0">Host</p>
              <i className="reject-p p-0 m-0">{event?.event_host_reason}</i>
              <input
                type="text"
                value={input?.event_host_reason}
                name="event_host_reason"
                className="form-control"
                placeholder="Enter Your Message Here..."
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <p className="rejected-form-p m-0 p-0">Partnerships</p>
              <i className="reject-p p-0 m-0">
                {event?.event_partnerships_reason}
              </i>
              <input
                type="text"
                value={input?.event_partnerships_reason}
                name="event_partnerships_reason"
                className="form-control"
                placeholder="Enter Your Message Here..."
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <p className="rejected-form-p m-0 p-0">Packages</p>
              <i className="reject-p p-0 m-0">
                {event?.sponsor_packages_reason}
              </i>
              <input
                type="text"
                value={input?.sponsor_packages_reason}
                name="sponsor_packages_reason"
                className="form-control"
                placeholder="Enter Your Message Here..."
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <p className="rejected-form-p m-0 p-0">Coupons</p>
              <i className="reject-p p-0 m-0">{event?.event_coupons_reason}</i>
              <input
                type="text"
                value={input?.event_coupons_reason}
                name="event_coupons_reason"
                className="form-control"
                placeholder="Enter Your Message Here..."
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <p className="rejected-form-p m-0 p-0">Media Hub</p>
              <i className="reject-p p-0 m-0">
                {event?.event_media_hub_reason}
              </i>
              <input
                type="text"
                value={input?.event_media_hub_reason}
                name="event_media_hub_reason"
                className="form-control"
                placeholder="Enter Your Message Here..."
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <p className="rejected-form-p m-0 p-0">Social Hub</p>
              <i className="reject-p p-0 m-0">{event?.event_social_hub}</i>
              <input
                type="text"
                value={input?.event_social_hub}
                name="event_social_hub"
                className="form-control"
                placeholder="Enter Your Message Here..."
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <p className="rejected-form-p m-0 p-0">
                Cancellation And Refund
              </p>
              <i className="reject-p p-0 m-0">
                {event?.event_cancellation_and_refund_reason}
              </i>
              <input
                type="text"
                value={input?.event_cancellation_and_refund_reason}
                name="event_cancellation_and_refund_reason"
                className="form-control"
                placeholder="Enter Your Message Here..."
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <p className="rejected-form-p m-0 p-0">References</p>
              <i className="reject-p p-0 m-0">
                {event?.event_references_reason}
              </i>
              <input
                type="text"
                value={input?.event_references_reason}
                name="event_references_reason"
                className="form-control"
                placeholder="Enter Your Message Here..."
                onChange={handleChange}
              />
            </div>

            <div className="d-flex justify-content-end gap-2">
              <button
                className="reject-close-btn"
                onClick={() => setRejectPopup(false)}
                type="button">
                Close
              </button>

              <button
                className="reject-save-btn"
                onClick={HandleSave}
                type="button">
                Send Messages
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default RejectPopup;
