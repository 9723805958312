// import eventimg from "../../assets/images/event3.jpg"
import React, { useState, useEffect } from "react";
import { FaLocationDot } from "react-icons/fa6";
import { FaStar } from "react-icons/fa";
import { FaRegCalendarAlt } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { RiLiveFill } from "react-icons/ri";
import dImage from '../../assets/images/default-image.jpg';
import ReactStars from "react-rating-stars-component";
import { BsFillTicketDetailedFill } from "react-icons/bs";

const EventsCard = ({ baseUrls, val, isValid, setHastag, setInterestDrop }) => {
  const navigate = useNavigate();
  const eventEndDate = new Date(`${val?.end_date} ${val?.end_time}`);
  const currentDate = new Date();
  const isEventExpired = currentDate > eventEndDate;
  const sumOfRatings = val?.event_reviews?.reduce((accumulator, currentValue) => accumulator + currentValue?.rating, 0);
  const SubRating = sumOfRatings / val?.event_reviews?.length;
  const [currencyIcn, setCurrencyIcn] = useState('');

  const cardImageError = (event) => {
    event.target.src = dImage;
    event.target.onError = null;
  };

  const event_mode = val?.event_mode?.replace('_', ' ');

  useEffect(() => {
    if (val?.currency_code == "INR") {
      setCurrencyIcn("₹");
    } else if (val?.currency_code == "USD") {
      setCurrencyIcn("$");
    } else if (val?.currency_code == "EUR") {
      setCurrencyIcn("€");
    } else if (val?.currency_code == "GBP") {
      setCurrencyIcn("€");
    }
  }, [val?.currency_code]);

  const minAmount = val?.min_amount ? parseFloat(val?.min_amount).toFixed(0) : null;
  const maxAmount = val?.max_amount ? parseFloat(val?.max_amount).toFixed(0) : null;

  // console.log("eventdata",minAmount)

  return (<>
    <div className="eventcard">
      <div className="card-image-div">
        {/* event mode */}
        {val?.event_mode && (
          <div className="event-online-div">{event_mode}</div>
        )}

        {val?.status ? (
          val?.status == "Live" ? (
            <div className="event-live-status-div ">
              <RiLiveFill />
              {val?.status}
            </div>
          ) : (
            <div className="home-event-status-div">{val?.status}</div>
          )
        ) : (
          ""
        )}

        {/* {val?.event_type?.name &&
            <div className="event-type-div">{val?.event_type?.name}</div>
          } */}

        {/* {isEventExpired && (
          <div className="past-event-head-div">Past Event</div>
        )} */}

        <Link className="navigate-detail-btn"
          to={`/eventdetail/${val?.id}`}
          state={{ type: "Events" }}>
          <img
            className="event-card-img"
            src={`${baseUrls?.logo_image_url}/${val?.logo}`}
            onError={cardImageError}
            alt="..."
          />
        </Link>
        {/* </button> */}
        <div className="event-rectangle">
          <FaRegCalendarAlt className="d-none d-sm-flex" size={18} />
          <p className="date-text">
            {moment(val?.start_date).format("ddd, DD MMM")}
          </p>
          <p className="date-text">To</p>
          <p className=" date-text">
            {moment(val?.end_date).format("ddd, DD MMM")}
          </p>
        </div>
      </div>

      <div className="mt-2">
        <Link
          className="navigate-detail-btn"
          to={`/eventdetail/${val?.id}`}
          state={{ type: "Events" }}>
          <h5 className="event-card-heading mb-0" title={val?.event_title}>{val?.event_title}</h5>

          {/* {
             val?.address && 
              <div className="d-flex gap-1">
              <div>
                <FaLocationDot className="mt-1" color="black" size={15} />
              </div>
              <p className="feature-card-address" title={val?.address}>
                {val?.address}
              </p>
            </div>
            } */}
          {val?.address ? (
            <div className="d-flex gap-1 mt-0">
              <div className="">
                <FaLocationDot className="mt-1" color="black" size={15} />
              </div>
              <p className="feature-card-address" title={`${val?.city?.city_name}, ${val?.state?.short_name}, ${val?.country?.short_name}`}>
                {/* {val?.address}, {val?.city?.city_name}, {val?.state?.short_name}, {val?.country?.short_name} */}
                {val?.city?.city_name}, {val?.state?.short_name}, {val?.country?.short_name}
              </p>
            </div>
          ) : (
            <div className="d-flex gap-1 mt-0">
              <div className=""> <FaLocationDot className="mt-1" color="black" size={15} /> </div>
              <p className="feature-card-address" title={`${val?.city?.city_name}, ${val?.state?.short_name}, ${val?.country?.short_name}`}>
                {val?.city?.city_name}, {val?.state?.short_name}, {val?.country?.short_name}
              </p>
            </div>
          )}
        </Link>

        {
         minAmount &&
        <div className="d-flex align-items-center gap-1">
          <BsFillTicketDetailedFill color="black" size={15} />
          <p className="feature-card-cutoff-p">
            {/* { minAmount == maxAmount ? ` ${currencyIcn}${maxAmount}` : `${currencyIcn}${minAmount} - ${currencyIcn}${maxAmount}`} */}
            {`From ${currencyIcn}${minAmount}`}
          </p>
         </div>
        }  

        {val?.published_date == null ||
          val?.published_date == "0000-00-00" ? (
          ""
        ) : (
          <div className="d-flex align-items-center gap-1">
            <FaRegCalendarAlt size={15} color="black" className="d-none d-md-flex" />
            <p className="feature-card-cutoff-p " title={moment(val?.published_date).format("ddd, DD MMM YYYY")}>
              Publish Date : {moment(val?.published_date).format("ddd, DD MMM YYYY")}
            </p>
          </div>
        )}

        {val?.published_end_date == null ||
          val?.published_end_date == "0000-00-00" ? (
          ""
        ) : (
          <div className="d-flex align-items-center gap-1 ">
            <FaRegCalendarAlt size={15} color="black" className="d-none d-md-flex" />
            <p className="feature-card-cutoff-p " title={moment(val?.published_end_date).format("ddd, DD MMM YYYY")}>
              CutOff Date : {moment(val?.published_end_date).format("ddd, DD MMM YYYY")}
            </p>
          </div>
        )}


        {val?.tags?.length > 0 &&
          <div className='Card-IntrestD'>
            {val?.tags?.length > 0 && val?.tags?.map((item) => {
              return (
                <button key={item?.id} className="Card-IntrestP"
                  onClick={() => {
                    setHastag(prevHastag => {
                      const newValue = item?.interest_id; // Get the new value
                      const updatedHastag = [...prevHastag]; // Copy the previous value
                      updatedHastag.push(newValue); // Add the new value
                      return updatedHastag; // Return the updated value
                    }
                    ); setInterestDrop(true)
                  }}>
                  #{item?.user_interests?.name}
                </button>
              )
            })}
          </div>
        }

        {isEventExpired && (
          sumOfRatings ?
            <div className="d-flex align-items-center mt-1">
              <FaStar color="black" size={15} />
              <p className="event-card-text">{SubRating?.toFixed(1)} / 5</p>
            </div> : ''
        )}

        <div>
          {
            parseInt(val?.rating) ?
              <ReactStars
                count={5}
                size={15}
                edit={false}
                //onChange={handleRatingChange}
                value={parseInt(val?.rating)}
                emptyIcon={<i className="far fa-star"></i>}
                fullIcon={<i className="fa fa-star"></i>}
                activeColor="#ffc601"
              />
              :
              ""
          }
        </div>
      </div>
    </div >
  </>
  );
};

export default EventsCard;
