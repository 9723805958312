import { useEffect, useState } from "react";
import preview from "../../assets/images/preview.svg";
import BasicInformation from "../../components/CreateEvents/BasicInfoForm";
import PackagesForm from "../../components/CreateEvents/PackagesForm";
import PitchForm from "../../components/CreateEvents/PitchForm";
import OrganizerForm from "../../components/CreateEvents/OrganizerForm";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import CreateCoupon from "../../components/CreateEvents/CreateCouponForm";
import { Get_Champs } from "../../utils/champ/champAlldata";
import { useDispatch, useSelector } from "react-redux";
import { getChamps } from "../../redux/BasicInfoFormLists/GetChamps";
import "rc-collapse/assets/index.css";
import EventGuestForm from "../../components/CreateEvents/EventGuestForm";
import EventPitchForm from "../../components/CreateEvents/EventPitchForm";
import EventHostForm from "../../components/CreateEvents/EventHostForm";
import EventPartnerShipForm from "../../components/CreateEvents/EventPartnerShipForm";
import EventSponsorPackage from "../../components/CreateEvents/EventSponsorPackage";
import EventSocialHub from "../../components/CreateEvents/EventSocialHub";
import EventCancellationRefund from "../../components/CreateEvents/EventCancellationRefund";
import EventReferences from "../../components/CreateEvents/EventReferences";
import EventEssentialsForm from "../../components/CreateEvents/EventEssentialsForm";
import EventMediaHub from "../../components/CreateEvents/EventMediaHubForm";
import { Get_Edit_Event } from "../../utils/EditEvent/editEvent";
import { RiArrowRightSFill } from "react-icons/ri";
import { TiArrowLeft } from "react-icons/ti";

const CreateEvent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState("Event-Essentials");
  const [event_Data, setEvent_Data] = useState({});
  const [packageList, setPackageList] = useState([]);
  const [EditData, setEditData] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [userData, setUserData] = useState([]);
  // const champsData = useSelector((data) => data?.Get_Champs?.champs);
  const params = useParams();
  const Eid = params?.id;

  const profileinfo = useSelector((data) => data?.Profile_data?.ProfileData);

  const Messages = (val) => {
    toast.success(val, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      theme: "light",
    });
  };

  const GetChampList = async () => {
    try {
      const res = await Get_Champs();
      dispatch(getChamps(await res?.data?.data));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (profileinfo?.original_champ != null) {
      GetChampList();
    }
  }, [profileinfo]);

  const EditEvents = async () => {
    try {
      const response = await Get_Edit_Event(Eid);
      setEditData(response?.data?.data);
      // console.log("EEEE",response?.data?.data)
      // dispatch(setEditdata(response?.data?.data));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (Eid == "create") {
      setEvent_Data(event_Data);
      setIsEdit(false);
    } else {
      // setEvent_Data({ ...event_Data, id:Eid });
      setIsEdit(true);
      EditEvents();
      // setTabValue("");
    }
  }, [tabValue]);

  const tabNames = {
    "Event-Essentials": "Event Essentials",
    "Event-Guest": "Event Guests",
    "Event-Pitch": "Event Pitch",
    "Event-Host": "Event Host",
    "Event-Partnerships": "Event Partnerships",
    "Sponsor-Packages": "Sponsor Packages",
    "coupons": "Coupons",
    "Event-Media-Hub": "Event Media Hub",
    "Social-Hub": "Social Hub",
    "Cancellation-Refund": "Cancellation / Refund",
    "References": "References",
  };

  return (
    <>
      <ToastContainer />
      <div className="newevent container fluid">
        <div className="row">
          <div className="col-6 d-flex align-items-center gap-1">
            <h5 className="create-event-heading m-0">Create New Event</h5> 
            <RiArrowRightSFill size={20}/>
            <h5 className="create-event-heading m-0">{tabNames[tabValue]}</h5>
          </div>
          <div className="col-6">
            <div className="d-flex justify-content-end gap-2">
              {Object.keys(EditData)?.length == 0 &&
              EditData?.constructor === Object ? (
                ""
              ) : (
                <button
                  className="btn d-flex align-items-center gap-1 preview-btn"
                  onClick={()=> navigate(`/preview/${EditData?.id}`, {
                      state: "CreateEvent",
                  })}>
                  <img src={preview} alt="pre" />
                  Preview
                </button>
              )}

              <button className="go-back-btn d-flex align-items-center" onClick={() => navigate(-1)} type="button">
              <TiArrowLeft size={20}/> Go Back
              </button>
            </div>
          </div>
        </div>

        <div className="row mb-5 mt-4">
          <div className="col-md-3 d-flex flex-column gap-2 create-main-tab">
            <button
              onClick={() => setTabValue("Event-Essentials")}
              className={`${
                tabValue == "Event-Essentials"
                  ? "active-create-event-tab"
                  : "create-event-tab"
              } `} >
              Event Essentials
            </button>

            <button
              onClick={() => setTabValue("Event-Guest")}
              className={`${
                tabValue == "Event-Guest"
                  ? "active-create-event-tab"
                  : "create-event-tab"
              } `}>
              Event Guests
            </button>

            <button
              onClick={() => setTabValue("Event-Pitch")}
              className={`${
                tabValue == "Event-Pitch"
                  ? "active-create-event-tab"
                  : "create-event-tab"
              } `}
            >
              Event Pitch
            </button>
            <button
              onClick={() => setTabValue("Event-Host")}
              className={`${
                tabValue == "Event-Host"
                  ? "active-create-event-tab"
                  : "create-event-tab"
              } `}
            >
              Event Host
            </button>

            <button
              onClick={() => setTabValue("Event-Partnerships")}
              className={`${
                tabValue == "Event-Partnerships"
                  ? "active-create-event-tab"
                  : "create-event-tab"
              } `}
            >
              Event Partnerships
            </button>

            <button
              onClick={() => setTabValue("Sponsor-Packages")}
              className={`${
                tabValue == "Sponsor-Packages"
                  ? "active-create-event-tab"
                  : "create-event-tab"
              } `}
            >
              Sponsor Packages
            </button>

            <button
              onClick={() => setTabValue("coupons")}
              className={`${
                tabValue == "coupons"
                  ? "active-create-event-tab"
                  : "create-event-tab"
              } `}
            >
              Coupons
            </button>

            <button
              onClick={() => setTabValue("Event-Media-Hub")}
              className={`${
                tabValue == "Event-Media-Hub"
                  ? "active-create-event-tab"
                  : "create-event-tab"
              } `}
            >
              Event Media Hub
            </button>

            <button
              onClick={() => setTabValue("Social-Hub")}
              className={`${
                tabValue == "Social-Hub"
                  ? "active-create-event-tab"
                  : "create-event-tab"
              } `}
            >
              Social Hub
            </button>

            <button
              onClick={() => setTabValue("Cancellation-Refund")}
              className={`${
                tabValue == "Cancellation-Refund"
                  ? "active-create-event-tab"
                  : "create-event-tab"
              } `}
            >
              Cancellation / Refund
            </button>

            <button
              onClick={() => setTabValue("References")}
              className={`${
                tabValue == "References"
                  ? "active-create-event-tab"
                  : "create-event-tab"
              } `}
            >
              References
            </button>
          </div>

          <div className="col-md-9">
            {tabValue == "Event-Essentials" ? (
              <div className="">
                <EventEssentialsForm
                  EditData={EditData}
                  setEvent_Data={setEvent_Data}
                  setTabValue={setTabValue}
                  Messages={Messages}
                  profileinfo={profileinfo}
                  isEdit={isEdit}
                  Eid={Eid}
                  tabValue={tabValue}
                  setUserData={setUserData}
                />
              </div>
            ) : tabValue == "Event-Guest" ? (
              <div className="">
                <EventGuestForm
                  setTabValue={setTabValue}
                  event_id={EditData?.id}
                  Messages={Messages}
                  isEdit={isEdit}
                  Eid={Eid}
                  tabValue={tabValue}
                />
              </div>
            ) : tabValue == "Event-Pitch" ? (
              <div className="">
                <EventPitchForm
                  setTabValue={setTabValue}
                  event_data={EditData}
                  Messages={Messages}
                  isEdit={isEdit}
                  Eid={Eid}
                  tabValue={tabValue}
                />
              </div>
            ) : tabValue == "Event-Host" ? (
              <div className="">
                <EventHostForm
                  setTabValue={setTabValue}
                  event_id={EditData?.id}
                  Messages={Messages}
                  Eid={Eid}
                  userData={userData}
                />
              </div>
            ) : tabValue == "Event-Partnerships" ? (
              <div className="">
                <EventPartnerShipForm
                  setTabValue={setTabValue}
                  event_data={EditData}
                  Messages={Messages}
                  isEdit={isEdit}
                  Eid={Eid}
                  tabValue={tabValue}
                />
              </div>
            ) : tabValue == "Sponsor-Packages" ? (
              <div className="">
                <EventSponsorPackage
                  setTabValue={setTabValue}
                  event_data={EditData}
                  Messages={Messages}
                />
              </div>
            ) : tabValue == "Event-Media-Hub" ? (
              <div className="">
                <EventMediaHub
                  event_id={EditData?.id}
                  setTabValue={setTabValue}
                  Messages={Messages}
                  isEdit={isEdit}
                  Eid={Eid}
                  tabValue={tabValue}
                />
              </div>
            ) : tabValue == "Social-Hub" ? (
              <div className="">
                <EventSocialHub
                  setTabValue={setTabValue}
                  event_id={EditData?.id}
                  Messages={Messages}
                  isEdit={isEdit}
                  Eid={Eid}
                  tabValue={tabValue}
                />
              </div>
            ) : tabValue == "Cancellation-Refund" ? (
              <div className="">
                <EventCancellationRefund
                  setTabValue={setTabValue}
                  event_id={EditData?.id}
                  Messages={Messages}
                  isEdit={isEdit}
                  Eid={Eid}
                  tabValue={tabValue}
                />
              </div>
            ) : tabValue == "coupons" ? (
              <div className="">
                <CreateCoupon
                  event_id={EditData?.id}
                  setTabValue={setTabValue}
                  packageList={packageList}
                  Messages={Messages}
                />
              </div>
            ) : tabValue == "References" ? (
              <div className="">
                <EventReferences
                  setTabValue={setTabValue}
                  event_id={EditData?.id}
                  Messages={Messages}
                  isEdit={isEdit}
                  Eid={Eid}
                  tabValue={tabValue}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div></div>
      </div>
    </>
  );
};

export default CreateEvent;
