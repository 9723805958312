import { configureStore } from '@reduxjs/toolkit'
import Login from './redux/Login'
import config_data from './redux/config'
import editEventSlice from './redux/EditEvent/editevent'
import Profile_data  from './redux/UserProfile/Profile'
import Get_basicInfo_lists from './redux/BasicInfoFormLists/GetBasicInfoLists'
import Get_Champs  from './redux/BasicInfoFormLists/GetChamps'
import CurrencyConvert from './redux/CurrencyConvert/CurrencyConvert'
import AuthCheck from './redux/Auth/AuthCheck'


export const store = configureStore({
  reducer: {
    login: Login,
    config_data:config_data,
    editEventSlice:editEventSlice,
    Profile_data:Profile_data,
    Get_basicInfo_lists:Get_basicInfo_lists,
    Get_Champs:Get_Champs,
    CurrencyConvert:CurrencyConvert,
    AuthCheck:AuthCheck
  },
})