import { UpdateUserName, VerifyUpdateUserName } from "../../Services/Apis"

export const Update_UserName = async (fData) => {
    try {
        return await UpdateUserName(fData).then((data) => data)
    } catch (err) {
        throw err
    }
} 


export const Verify_Change_UserName = async (fData) => {
    try {
        return await VerifyUpdateUserName(fData).then((data) => data)
    } catch (err) {
        throw err
    }
} 