import { EditEvent, evenImageDocsDlt, eventPackageDlt, getOrganizerFormEdit, getPackagesFormEdit, getPitchFormEdit, postOrganizerFormEdit, postPitchFormEdit, postpackageFormEdit } from "../../Services/Apis"


export const Edit_Event = async (id) => {
  try {
    return await EditEvent(id).then((data) => data)
  }
  catch (error) {
    console.log(error)
  }
}


// packages form get list api for edit 

export const Packages_get_event = async (id) => {
  try {
    return await getPackagesFormEdit(id).then((data) => data)
  }
  catch (error) {
    console.log(error)
  }
}

//packages form post edit api 

export const Packages_post_event = async (val, id) => {
  try {
    return await postpackageFormEdit(val, id).then((data) => data)
  }
  catch (error) {
    console.log(error)
  }
}


// pitch form get list api for edit

export const Pitch_get_event = async (id) => {
  try {
    return await getPitchFormEdit(id).then((data) => data)
  }
  catch (error) {
    console.log(error)
  }
}

// pitch Form post edit data api 

export const pitch_update_event = async (val, id) => {
  try {
    return await postPitchFormEdit(val, id).then((data) => data)
  }
  catch (error) {
    console.log(error)
  }
}

// get organizer list api

export const Organizer_get_event = async (id) => {
  try {
    return await getOrganizerFormEdit(id).then((data) => data)
  }
  catch (error) {
    console.log(error)
  }
}

// post organizer data list 


export const Organizer_update_event = async (val, id) => {
  try {
    return await postOrganizerFormEdit(val, id).then((data) => data)
  }
  catch (error) {
    console.log(error)
  }
}

// Event Packages Delete Api 
export const Event_packages_delete = async (package_id) => {
  try {
    return await eventPackageDlt(package_id).then((data) => data)
  }
  catch (error) {
    console.log(error)
  }
}

// event pitch image docs delete
export const Event_Image_Docs_delete = async (id) => {
  try {
    return await evenImageDocsDlt(id).then((data) => data)
  }
  catch (error) {
    console.log(error)
  }
}