import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Autoplay } from 'swiper/modules';
import Loader from '../Loader/Loader';
import { Link } from 'react-router-dom';

const BottomBanner = ({ bottomBannerList, loading, ConfigData }) => {

  const breakpoints = {
    300: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    1024: {
      slidesPerView: 2,
      spaceBetween: 30,
    },
    1200: {
      slidesPerView: 2,
      spaceBetween: 40,
    },
    1600: {
      slidesPerView: 2,
      spaceBetween: 40,
    },
  };

  return (<>
    <div className="bottom-banner">
      <div className=' d-flex justify-content-center'>
        <Swiper
          spaceBetween={100}
          breakpoints={breakpoints}
          modules={[Autoplay]}
          loop={true}
          autoplay={{
            delay: ConfigData?.banner_speed ? ConfigData?.banner_speed : 4000,
            disableOnInteraction: false,
          }}
          effect="fade"
          speed={1000}
          className='container py-3 banner-cards'>
          {bottomBannerList?.map((item) => {
            return (
              <SwiperSlide key={item?.id}>
                <div className='banner-card'>
                  <img className="eventposter-img" src={`${ConfigData?.base_urls?.banner_image_url}/${item?.photo}`} alt="img1" />

                  {item?.buttons?.map((val) => (
                    <Link to='/howitworks' className="mt-2 d-flex justify-content-center">
                      <div className="clear-filter-button d-flex align-items-center gap-2">
                        {/* <img height='18px' src={`${ConfigData?.base_urls?.banner_button_url}/${val?.icon}`} /> */}
                        <p className='clear-filter-button p-0'>{val?.name}</p>
                      </div>
                    </Link>
                  ))}

                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    </div >
  </>)
}

export default BottomBanner;