import { useEffect, useState, useMemo, useCallback, useRef } from "react";
import { Event_SponsorTransaction } from "../../utils/champ/champAlldata";
import ReactPaginate from "react-paginate";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import moment from "moment";
import { Link, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { MdVerified } from "react-icons/md";

const EventSponsorTransaction = () => {
  const [columnDefs, setColumnDefs] = useState([]);
  const [transactionData, setTransactionData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [currencyIcn, setCurrencyIcn] = useState("");
  const currency = useSelector((val) => val?.CurrencyConvert);
  const { state } = useLocation();
  const gridRef = useRef();
  const params = useParams();
  const id = params?.id;

  const checkCurrency = (data) => {
    let currency = "";
    data?.forEach((val) => {
      if (val?.currency === "INR") {
        currency = "₹";
      } else if (val?.currency === "USD") {
        currency = "$";
      } else if (val?.currency === "EUR") {
        currency = "€";
      } else if (val?.currency === "GBP") {
        currency = "£";
      }
    });
    setCurrencyIcn(currency);
  };

  const GetTransctionsList = async () => {
    try {
      setLoading(true);
      const response = await Event_SponsorTransaction(id);
      const transactionData = response?.data?.data;
      setTransactionData(transactionData);
      if (transactionData && transactionData.data) {
        checkCurrency(transactionData.data);
      }
      //   const specificFieldsData = response?.data?.data?.data?.map(item => {
      //     return {
      //       'Transaction Id': item?.transaction_id,
      //       'Event Name': item?.event_title,
      //       'Package Name': item?.title,
      //       'Package Amount': item?.package_amount,
      //       'Coupon Code': item?.coupon_code,
      //       'Sponsored Amount': item?.amount,
      //       'Status': item?.payment_status,
      //       'Date': moment(item?.created_at).format("ll hh:mm A"),
      //       'Coupon Discount': item?.discount,
      //     };
      //   });
      //   setPdfDownload(specificFieldsData)
      // checkCurrency(response?.data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    GetTransctionsList();
  }, []);
  const AvatarRenderer = ({ value, data }) => {
    return (
      <div className="">
        <Link to={`/profileview/${data?.id}`} state={{ name: `${value}` }}>
          <img
            className="listcard-img object-fit-cover"
            src={
              !data?.image_type
                ? `https://sponsorringus.com/admin/storage/app/public/profile/${data?.image}`
                : data?.image_type == "browse"
                ? `https://sponsorringus.com/admin/storage/app/public/profile/${data?.image}`
                : `https://sponsorringus.com/admin/storage/app/public/avatar/${data?.predefine_image}`
            }
            alt="img"
          />
        </Link>
      </div>
    );
  };

  useEffect(() => {
    setColumnDefs([
      // {
      //     headerName: "Image",
      //     field: "users.image",
      //     width: 100,
      //     cellRenderer: AvatarRenderer,
      //     filter: false,
      //   },

      // {
      //   headerName: "UserName",
      //   field: "users.name",
      //   cellRenderer: ({ value, data }) => {
      //     return (
      //       <div className="d-flex gap-1 align-items-center">
      //         {/* <Link to={`/champEvent/${data?.id}`} state={{ name: `${value}` }}> */}
      //         <Link
      //           to={`/profileview/${data?.id}`}
      //           state={{ name: `${value}` }}
      //         >
      //           <p className="">{value ? value : "-"}</p>
      //         </Link>
      //         {/* {data?.membership_plan_name == "Premier" ? <MdVerified color="#039be5" /> : ""} */}
      //       </div>
      //     );
      //   },
      // },
      {
        headerName: "Transaction Id",
        field: "transaction_id",
        cellRenderer: ({ value, data }) => {
          return <p className=""> {value ? value : "-"}</p>;
        },
      },
      {
        headerName: "Event Name",
        field: "event.event_title",
        width: 120,
        cellRenderer: ({ value, data }) => {
          return (
            <Link
              className="navigate-detail-btn"
              to={`/eventdetail/${data?.event_id}`}
              state={{ type: "Events" }}>
              <p className="order-list-text text-capitalize">{value}</p>{" "}
            </Link>
          );
        },
      },
      { headerName: "Package", field: "package.title", width: 120 },

      {
        headerName: "Package Amount",
        field: "package.amount",
        width: 150,
        cellRenderer: ({ value, data }) => {
          const package_amt = data?.package?.amount;
          const formattedAmount = package_amt
            ? `${
                currency?.currencySymbol
                  ? currency?.currencySymbol
                  : currencyIcn
              } ${
                currency?.selectedCurrencyPrice
                  ? (currency?.selectedCurrencyPrice * package_amt).toFixed(2)
                  : package_amt
              }`
            : 0;
          return <p className="">{formattedAmount}</p>;
        },
      },

      {
        headerName: "Coupon Code ",
        field: "coupons.coupon_code",
        // width: 120,
        valueGetter: function (params) {
          const couponCode = params.data?.coupons?.coupon_code;
          return couponCode ? couponCode : "-"; // return empty string if null or undefined
        },
      },
      {
        headerName: "Coupon Discount",
        field: "discount",
        // width: 120,
      },
      {
        headerName: "Sponsored Amount",
        width: 150,
        field: "amount",
        cellRenderer: ({ value, data }) => {
          const package_amt = data?.amount;
          const formattedAmount = package_amt
            ? `${
                currency?.currencySymbol
                  ? currency?.currencySymbol:currencyIcn
              } ${
                currency?.selectedCurrencyPrice
                  ? (currency?.selectedCurrencyPrice * package_amt).toFixed(2)
                  : package_amt
              }`
            : 0;
          return <p className="">{formattedAmount}</p>;
        },
      },
      {
        headerName: "Income",
        width: 150,
        field: "total_commission",
        cellRenderer: ({ value, data }) => {
          const package_amt = data?.total_commission;
          const formattedAmount = package_amt
            ? `${
                currency?.currencySymbol
                  ? currency?.currencySymbol
                  : currencyIcn
              } ${
                currency?.selectedCurrencyPrice
                  ? (currency?.selectedCurrencyPrice * package_amt).toFixed(2)
                  : package_amt
              }`
            : 0;
          return <p className="">{formattedAmount}</p>;
        },
      },
      {
        headerName: "Status",
        field: "payment_status",
        width: 150,
        cellRenderer: ({ value, data }) => {
          const status = data?.payment_status;
          return (
            <p className={
                value == "success"
                  ? "success-status-text text-capitalize p-0"
                  : value == "failed"
                  ? "failed-status-text text-capitalize p-0"
                  : "pending-status-text text-capitalize p-0"
              }
            >
              {value}
            </p>
          );
        },
      },
      {
        headerName: "Date",
        field: "created_at",
        cellRenderer: ({ value, data }) => {
          return moment(data?.created_at).format("ll hh:mm A");
        },
      },
    ]);
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      filter: "agTextColumnFilter",
      floatingFilter: true,
    };
  }, []);

  const onGridReady = useCallback((params) => {}, []);

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  return (
    <>
      <div className="container-fluid mt-4">
        <div className="d-flex justify-content-between align-items-center">
          <div>
          <h2 className="events-heading d-flex flex-column flex-md-row align-items-md-center gap-md-2 mb-0">
            Total Hosted Events
            <span className="host-username-heading" >
                ({transactionData?.total})
            </span>
          </h2>
            
              <div>
              <span className="host-username-heading" title={state?.name}>
                  ({state?.name})
               </span>
              </div>
        </div>
          <Link to="/dashboard" className="btn viewall-btn-p">
            Go Back
          </Link>
        </div>

        <div
          className="ag-theme-alpine py-3"
          style={{ height: 520, width: "100%" }} >
          <AgGridReact
            ref={gridRef}
            onGridReady={onGridReady}
            columnDefs={columnDefs}
            rowData={transactionData?.data}
            defaultColDef={defaultColDef}
            rowSelection="multiple"
          ></AgGridReact>
        </div>

        {transactionData?.data?.length < transactionData?.total && (
          <div className="pagination-container ">
            <ReactPaginate
              pageCount={Math.ceil(
                transactionData?.total / transactionData?.per_page
              )}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName="pagination"
              activeClassName="selected"
              disabledClassName="disabled"
            />
          </div>
        )}
      </div>
    </>
  );
};

export default EventSponsorTransaction;
