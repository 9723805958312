import { Delete_Support_Ticket, Get_Messages, Get_Request_Messages, Search_REQUEST, Send_Message, Support_Ticket } from "../../Services/Apis"


export const SupportTicket = async (fData) => {
    try {
        return await Support_Ticket(fData).then((data) => data)
    } catch (err) {
        throw err
    }
}

// get_request_messages
export const Get_RequestMessages = async () => {
  try {
    const response = await Get_Request_Messages();
    return response;
  }
  catch (error) {
    console.log("error", error)
  }
}

// get_messages
 export const GetMessages = async (id) => {
    try {
      const response = await Get_Messages(id);
      return response;
    }
    catch (error) {
      console.log("error", error)
    }
 }

 export const SendMessage = async (fData) => {
    try {
       return await Send_Message(fData).then((data) => data)
    } catch (err) {
       throw err
    }
}

export const Delete_SupportTicket = async (id) => {
  try {
     return await Delete_Support_Ticket(id).then((data) => data)
  } catch (err) {
     throw err
  }
}

export const SearchRequest = async (search) => {
  try {
     return await Search_REQUEST(search).then((data) => data)
  } catch (err) {
     throw err
  }
}