import moment from "moment";
import { Link } from "react-router-dom";
import { useEffect, useState, useMemo, useCallback, useRef } from "react";
import WarningModal from "../Common/WarningModal";
import DeleteWarningModal from "../Common/DeleteWarningModal";
import { Published_Event, UnPublishEvent } from "../../utils/myevent/MyEvent";
import { ToastContainer, toast } from "react-toastify";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import FeaturePaymentCardMdl from "../Common/FeaturePaymentCardPopup";
import FeatureYourEventPopup from "../Common/FeatureYourEventPopUp";
import defaultimage from "../../assets/images/default-image.jpg";
import PublishEventPopUp from "../Common/PublishEventPopUp";

const ApproveListCard = ({ baseUrls, val, someFilterHide, getMyEvent, profileData}) => {
  const [columnDefs, setColumnDefs] = useState([]);
  const [FeatureCardShow, setFeatureCardShow] = useState(false);
  const [showPaymentCardMdl, setShowPaymentCardMdl] = useState(false);
  const gridRef = useRef();
  const [event_Data, setEvent_Data] = useState({
    id: "",
    title: "",
    event_status: "",
  });
  const [publishMdl, setPublishMdl] = useState(false);
  const [unPublishBtnLoading, setUnPublishBtnLoading] = useState(false);
  const [publishBtnLoading, setpublishBtnLoading] = useState(false);
  const eventEndDate = new Date(`${val?.end_date} ${val?.end_time}`);
  const currentDate = new Date();
  const isEventExpired = currentDate > eventEndDate;


  const PublishedEvent = async (data, event_status) => {
    setEvent_Data({ ...data, event_status: event_status });
    setPublishMdl(!publishMdl);

    //   if(profileData?.is_premier){
    //     setEvent_Data({...data, event_status:event_status});
    //     setPublishMdl(!publishMdl);
    //    }
    //    else{
    //    setpublishBtnLoading(true);
    //    const fData = {
    //     event_id: data?.id,
    //    };
    //   try {
    //     const response = await Published_Event(fData);
    //     toast.success(response?.data?.message, {position: "top-center" });
    //     // setFeatureCardShow(true);
    //     getMyEvent();
    //   } catch (error) { 
    //     toast.error(error?.response?.data?.message, {position: "top-center" });
    //   }finally{
    //     setpublishBtnLoading(false);
    //   }
    // }
  };

  const cardImageError = (event) => {
    event.target.src = defaultimage;
    event.target.onError = null;
  };

  const AvatarRenderer = ({ value, data }) => {
    return (
      <div className="">
        <Link
          className="navigate-detail-btn"
          to={
            someFilterHide
              ? `/pasteventdetail/${data?.id}`
              : `/eventdetail/${data?.id}`}
          state={{ type: "My Events" }}>
          {/* https://sponsorringus.com/admin/storage/app/public/event_logo */}

          <img
            className="listcard-img"
            src={`${baseUrls?.logo_image_url}/${value}`}
            onError={cardImageError}
            alt="img"
          />
        </Link>
      </div>
    );
  };

  const CustomButtonComponent = (value, data) => {
    console.log("vvvaa", data);
    return (
      <div className="">
        {val?.created_by == val?.referral_id && isEventExpired ? (
          <button className="listevent-editbtn" disabled>-</button>
        ) : (
          <div className="d-flex gap-1">
            <button
              className="listevent-editbtn"
              onClick={() => PublishedEvent(val?.id)}>
              Publish
            </button>
          </div>
        )}
      </div>
    );
  };

  const handleUnPublish = async (EId) => {
    const fData = {
      id: EId
    }
    setUnPublishBtnLoading(true)
    try {
      const response = await UnPublishEvent(fData);
      //  if(response?.data?.success){
      toast.success(response?.data?.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        theme: "light",
      });
      getMyEvent()
      // }
    } catch (err) {
      console.log(err);
    } finally {
      setUnPublishBtnLoading(false);
    }
  }

  useEffect(() => {
    setColumnDefs([
      {
        headerName: "Image",
        field: "logo",
        cellRenderer: AvatarRenderer,
        filter: false,
        width: 120
      },
      {
        headerName: "Title",
        field: "event_title",
        width: 180,
        cellRenderer: ({ value, data }) => {
          return (
            <Link
              className="navigate-detail-btn "
              to={`/eventdetail/${data?.id}`}
              state={{ type: "Events" }}>
              <p className="order-list-text fw-bold text-capitalize">{data?.event_title}</p>
            </Link>
          );
        },
      },
      {
        headerName: "Event Created",
        field: "created_at",
        cellRenderer: ({ value, data }) => {
          return (
            <p className="listcard-title">
              {moment(value).format("ddd, DD MMM YYYY")}
            </p>
          );
        },
      },
      // approved_date
      {
        headerName: "Approved Date",
        field: "approved_date",
        cellRenderer: ({ value, data }) => {
          return (<>
            <p className="listcard-title">
              {value ? moment(value).format("ddd, DD MMM YYYY") : "-"}
            </p>
          </>
          );
        },
      },
      {
        headerName: "Start Date",
        field: "start_date",
        cellRenderer: ({ value, data }) => {
          return (
            <p className="listcard-title">
              {moment(value).format("ddd, DD MMM YYYY")}
            </p>
          );
        },
      },

      {
        headerName: "End Date",
        field: "end_date",
        cellRenderer: ({ value, data }) => {
          return (
            <p className="listcard-title">
              {moment(value).format("ddd, DD MMM YYYY")}
            </p>
          );
        },
      },
      {
        headerName: "Location",
        field: "address",
        width: 300,
        cellRenderer: ({ value, data }) => {
          return (
            <>
              {value ?
                <p className="text-dark" title={`${data?.city?.city_name}, ${data?.state?.short_name}, ${data?.country?.short_name}`}>
                  {data?.city?.city_name}, {data?.state?.short_name}, {data?.country?.short_name}
                </p>
                :
                <p className="text-dark" title={`${data?.city?.city_name}, ${data?.state?.short_name}, ${data?.country?.short_name}`}>
                  {data?.city?.city_name}, {data?.state?.short_name}, {data?.country?.short_name}
                </p>
              }
            </>
          );
        },
      },
      {
        headerName: "Status",
        field: "event_status",
        width: 140,
        cellRenderer: ({ value, data }) => {
          return (
            <p className="listcard-title">{value}</p>
          );
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        cellRenderer: ({ value, data }) => {
          return (
            data?.published_date ?
              <div className="">
                {data?.created_by == data?.referral_id && isEventExpired ? (
                  <button className="listevent-editbtn" disabled>-</button>
                ) : (
                  <div className="d-flex gap-2">
                    <button className="listevent-publishbtn"
                      onClick={() => handleUnPublish(data?.id)}>
                      {unPublishBtnLoading && (
                        <div className="spinner-border text-light " role="status">
                          <span className="sr-only">Loading...</span>
                        </div>)}
                      UnPublish
                    </button>
                    <button className="listevent-publishbtn"
                      onClick={() => PublishedEvent(data, "modify")}>
                      Modify
                    </button>
                  </div>
                )}
              </div>
              :
              <div className="">
                {data?.created_by == data?.referral_id && isEventExpired ? (
                  <button className="listevent-editbtn" disabled>-</button>
                ) : (
                  <div className="d-flex gap-1">
                    <button
                      className="listevent-publishbtn"
                      onClick={() => PublishedEvent(data, "publish")}>
                      {publishBtnLoading &&
                        <div className="spinner-border text-light " role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      }
                      Publish
                    </button>
                  </div>
                )}
              </div>
          );
        },
      },
    ]);
  }, [val]);

  const defaultColDef = useMemo(() => {
    return {
      filter: "agTextColumnFilter",
      floatingFilter: true,
    };
  }, []);

  const onGridReady = useCallback((params) => { }, []);

  const onBtExport = useCallback(() => {
    gridRef.current.api.exportDataAsExcel();
  }, []);


  return (
    <>
      {publishMdl && <PublishEventPopUp event_Data={event_Data} setEvent_Data={setEvent_Data} getMyEvent={getMyEvent}
        setPublishMdl={setPublishMdl} profileData={profileData} setFeatureCardShow={setFeatureCardShow} someFilterHide={someFilterHide}/>}

      <ToastContainer />
      {FeatureCardShow && (
        <FeatureYourEventPopup
          setFeatureCardShow={setFeatureCardShow}
          event_id={event_Data?.id}
          setShowPaymentCardMdl={setShowPaymentCardMdl}
        />
      )}

      {showPaymentCardMdl && (
        <FeaturePaymentCardMdl
          event_data={event_Data}
          setEvent_Data={setEvent_Data}
          setShowPaymentCardMdl={setShowPaymentCardMdl}
        />
      )}

      <div className="position-relative">
        {/* <button className="export-to-excel-btn" onClick={onBtExport}>
          Export to Excel
        </button> */}
        <div className="ag-theme-alpine" style={{ height: 600, width: "100%" }}>
          <AgGridReact
            ref={gridRef}
            onGridReady={onGridReady}
            columnDefs={columnDefs}
            rowData={val}
            defaultColDef={defaultColDef}
            rowSelection="multiple"
          ></AgGridReact>
        </div>
      </div>

      {/* <div className="listcard-div">  
      <div className="row justify-content-center align-items-center">
        <div className="col-md-2">
          <Link className="navigate-detail-btn" to={someFilterHide ? `/pasteventdetail/${val?.id}` : `/eventdetail/${val?.id}`} state={{ type: 'My Events' }}>
            <img className="listcard-img" src={`https://ringus.idea2reality.tech/storage/app/public/event_logo/${val?.logo}`} alt="img" />
          </Link>
        </div>

        <div className="col-md-2">
          <Link className="navigate-detail-btn" to={someFilterHide ? `/pasteventdetail/${val?.id}` : `/eventdetail/${val?.id}`} state={{ type: 'My Events' }}>
            <p className="listcard-title">{val?.event_title}</p>
          </Link>
        </div>

        <div className="col-md-2">
          <p className="listcard-title">{moment(val?.start_date).format('DD-MM-YYYY')}</p>
        </div>

        <div className="col-md-2">
          <p className="listcard-title" title={val?.address}>{val?.address}</p>
        </div>

        <div className="col-md-2">
          <p className="listcard-title">{val?.event_status}</p>
        </div>

        <div className="col-md-2">
           {val?.created_by == val?.referral_id && isEventExpired ? <button className="listevent-editbtn" disabled>-</button> :
           <div className="d-flex gap-1">
            <button className="listevent-editbtn" onClick={()=>PublishedEvent(val?.id)}>Publish</button>
            </div>
            }
        </div>
      </div>
    </div> */}
    </>
  );
};

export default ApproveListCard;
