import React from 'react';

const LiveSamplePopup = ({ sampleBtn, setSampleBtn }) => {

    return (
        <>
            {sampleBtn &&
                <div className="popup-container-host">
                    <div className="modal-host" tabIndex="-10">
                        <div className="modal-dialog-host">
                            <div className="modal-content-host">
                                <div className="modal-header-host">
                                    <h5 className="modal-title-host">Thank you for trying this option!</h5>
                                </div>
                                <div className="modal-body-host">
                                    The Pay Now button will be available only for Live Events.
                                </div>
                                <div className="modal-footer-host">
                                    <button className="btn btn-success-host" onClick={() => setSampleBtn(false)}>Ok</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
export default LiveSamplePopup;