import { UpdateEmail, VerifyChangeEmail } from "../../Services/Apis"


export const Update_Email = async (fData) => {
    try {
        return await UpdateEmail(fData).then((data) => data)
    } catch (err) {
        throw err
    }
} 


export const Verify_Change_Email = async (fData) => {
    try {
        return await VerifyChangeEmail(fData).then((data) => data)
    } catch (err) {
        throw err
    }
} 