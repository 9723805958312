import { PostQuery } from "../../Services/Apis"

export const Post_Query= async (id,discription) => {
    try{
    const response = await PostQuery(id,discription).then((data) => data)
    return response
    }
    catch(error){
       throw error
    }
  
}