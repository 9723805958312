import { DeleteEvent, Featured_Event_List, Featured_Selected_Date, Get_Event_Benefit, Get_Mark_as_Complete, Get_Publish_Data, Mark_as_Complete_Detail, Publish_Event, PublishedEvent, UnPublish_Event, getmyevent } from "../../Services/Apis"


export const get_my_event = async (event_status, eventMode, eventType, eventRange, curr_page, search, sortVal, lat_long, eventCategory,
    status, hastgg, no_Participants, srt_Evt_Date, selectedGenders, minMaxAge, distanceRange, prime_host, cityS, languages, SponsorshipClosingDate, durationValue, 
    premier, archive_Type,selectHouse_Hold_Income,selectEducationValue,eventBudgetValue,guestAge,genderGroup,guestHouseIncome,guestEducation,guestMaritalStatus,
    guestProfession,guestIndustry,guestTechnologyProficiency,guestSocialMediaUsage) => {
    try {
        return await getmyevent(event_status, eventMode, eventType, eventRange, curr_page, search, sortVal, lat_long, eventCategory
            , status, hastgg, no_Participants, srt_Evt_Date, selectedGenders, minMaxAge, distanceRange, prime_host, cityS, languages, SponsorshipClosingDate, durationValue,
             premier, archive_Type,selectHouse_Hold_Income,selectEducationValue,eventBudgetValue,guestAge,genderGroup,guestHouseIncome,guestEducation,guestMaritalStatus,
             guestProfession,guestIndustry,guestTechnologyProficiency,guestSocialMediaUsage).then((data) => data)
    } catch (error) {
        console.log(error)
    }
}

export const Delete_Event = async (id) => {
    try {
        return await DeleteEvent(id).then((data) => data)
    } catch (error) {
        throw error
    }
}

export const Published_Event = async (fdata) => {
    try {
        return await PublishedEvent(fdata).then((data) => data)
    } catch (error) {
        throw error
    }
}

export const PublishEvent = async (fdata) => {
    try {
        return await Publish_Event(fdata).then((data) => data)
    } catch (error) {
        throw error
    }
}


export const UnPublishEvent = async (fdata) => {
    try {
        return await UnPublish_Event(fdata).then((data) => data)
    } catch (error) {
        throw error
    }
}


export const Get_PublishData = async (id) => {
    try {
        return await Get_Publish_Data(id).then((data) => data)
    } catch (error) {
        throw error
    }
}

export const Get_Markas_Complete = async (id) => {
    try {
        return await Get_Mark_as_Complete(id).then((data) => data)
    } catch (error) {
        throw error
    }
}

export const Markas_Complete_Detail = async (id,fData) => {
    try {
        return await Mark_as_Complete_Detail(id,fData).then((data) => data)
    } catch (error) {
        throw error
    }
}

export const Featured_EventListData = async () => {
    try {
        return await Featured_Event_List().then((data) => data)
    } catch (error) {
        throw error
    }
}

export const Featured_SelectedDate = async (FData) => {
    try {
        return await Featured_Selected_Date(FData).then((data) => data)
    } catch (error) {
        throw error
    }
}

export const Get_EventBenefit= async () => {
    try {
        return await Get_Event_Benefit().then((data) => data)
    } catch (error) {
        throw error
    }
}
