import React, { useState } from 'react';

const FAQ = ({ faqData }) => {
    const [openIndex, setOpenIndex] = useState(null);

    const toggleAccordion = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <div className="cw-fix">
            <section className="cw-section">
                <div className="faq-top">
                    <h2 className="cw-section__title">FREQUENTLY ASKED QUESTIONS</h2>
                   
                </div>
                <div className="cw-accordion">
                    {faqData?.map((item, index) => (
                        <article
                            key={item.id}
                            className={`cw-accordion__item ${openIndex === index ? 'cw-open' : ''}`}
                            onClick={() => toggleAccordion(index)}
                        >
                            <span className="cw-label">{item?.question}</span>
                            <div className={`cw-accordion-content ${openIndex === index ? 'show' : 'hide'}`}>

                                {openIndex === index && <div className="cw-answer mt-2">{item?.answer}</div>}
                            </div>
                        </article>
                    ))}
                </div>
            </section>
        </div>
    );
};

export default FAQ;
