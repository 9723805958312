import { Get_Education_List, Get_Household_Income, Get_interests, getCityList, getEventList, getLanguageList } from "../../Services/Apis"

export const get_events_list = async (categories, eventMode, search, sort, range, eventType, curr_page, let_log, eventCategory,
  status, hastag, no_Participants, srt_Evt_Date, minMaxAge, distanceRange, prime_host, city, selectedGenders, languages, SponsorshipClosingDate,
  durationValue, premier, selectHouse_Hold_Income, selectEducationValue, category, eventBudgetValue, user_id, starRating, guestAge, genderGroup, guestHouseIncome,
  guestEducation, guestMaritalStatus, guestProfession, guestIndustry, guestTechnologyProficiency, guestSocialMediaUsage, host_id) => {
  try {
    return await getEventList(categories, eventMode, search, sort, range, eventType, curr_page, let_log, eventCategory,
      status, hastag, no_Participants, srt_Evt_Date, minMaxAge, distanceRange, prime_host, city, selectedGenders, languages, SponsorshipClosingDate,
      durationValue, premier, selectHouse_Hold_Income, selectEducationValue, category, eventBudgetValue, user_id, starRating, guestAge, genderGroup, guestHouseIncome,
      guestEducation, guestMaritalStatus, guestProfession, guestIndustry, guestTechnologyProficiency, guestSocialMediaUsage, host_id).then((data) => data)

  } catch (error) {
    console.log(error)
  }
}

export const Get_CityList = async () => {
  try {
    return await getCityList().then((data) => data)

  } catch (error) {
    console.log(error)
  }
}


export const Get_LanguageList = async () => {
  try {
    return await getLanguageList().then((data) => data)

  } catch (error) {
    console.log(error)
  }
}

export const Getinterests = async () => {
  try {
    return await Get_interests().then((data) => data)
  } catch (error) {
    console.log(error)
  }
}


export const Get_HouseholdIncome = async () => {
  try {
    return await Get_Household_Income().then((data) => data)
  } catch (error) {
    console.log(error)
  }
}

export const Get_EducationList = async () => {
  try {
    return await Get_Education_List().then((data) => data)
  } catch (error) {
    console.log(error)
  }
}