import { Get_Message, ManageRequest_DeleteChat } from "../../Services/Apis";


export const GetMessage = async (id,dateFilterValue) => {
    try {
        const response =  await Get_Message(id,dateFilterValue).then((data) => data)
        return response
    } catch (err) {
        throw err;
    }
}


export const ManageRequestDeleteChat = async (chat_id) => {
    try {
        const response =  await ManageRequest_DeleteChat(chat_id).then((data) => data)
        return response
    } catch (err) {
        throw err;
    }
}