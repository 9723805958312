import React, { useEffect, useState } from "react";
import arrow from "../../assets/images/arrow2.svg";
import upload from "../../assets/images/upload-icon.png";
import {
  Get_UserAvatar,
  get_predefine_list,
} from "../../utils/profile/profileEditAlldata";
import { ToastContainer, toast } from "react-toastify";
import { get_city_state_country } from "../../utils/citystatecountry/cityStateCountry";
import { SocialMediaLogin, SocialMediaLogin_champ, UserProfile } from "../../utils/user/UserManager";
import { profileval } from "../../redux/UserProfile/Profile";
import { useDispatch } from "react-redux";
import { IoMdSend } from "react-icons/io";
import { FaCircleCheck } from "react-icons/fa6";
import InputMask from "react-input-mask";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import PhoneInput from "react-phone-input-2";
import CreatableSelect from "react-select/creatable";
import { useAuth0 } from "@auth0/auth0-react";
import { IoCloseSharp } from "react-icons/io5";
import { Get_languages_list } from "../../utils/basicInformation/basicinformationManager";
import { useNavigate } from "react-router-dom";

const SocialMediaProfile = () => {
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [listing, setListing] = useState([]);
  const [countries, seCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [avatars, setAvatars] = useState([]);
  const [loading, setLoading] = useState(true);
  const [center, setCenter] = useState({ lat: 24.5980386, lng: 73.6866158 });
  const [marker, setMarker] = useState(null);
  const [zip_country, setZip_Country] = useState({});
  const [zip_state, setZip_State] = useState({});
  const [zip_city, setZip_City] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [listingLanguage, setListingLanguage] = useState([])

  const [input, setInput] = useState({
    f_name: "",
    l_name: "",
    image_type: "",
    predefine_image: "",
    aadhar_card: "",
    aadhar_front_image: "",
    aadhar_back_image: "",
    facebook_link: "",
    instagram_link: "",
    website_link: "",
    date_of_birth: "",
    gender: "",
    marital_status: "",
    anniversary: "",
    education_background: "",
    industry: "",
    occupation: "",
    house_hold_income: "",
    state_id: "",
    city_id: "",
    country_id: "",
    zip_code: "",
    house_no: "",
    landmark: "",
    address: "",
    image: "JSON.stringif(",
    language: [],
    phone: "",
    country_code: "",
    email: "",
    address_type: '',
  });

  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();
  // const navigate =useNavigate()

  const { user, isAuthenticated, logout } = useAuth0();
  const userType = localStorage.getItem('signUpUserType')


  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyA3BYK_Siw8ebziSskitaA5YMjbvcO6n4U",
  });

  const onLoad = React.useCallback(
    function callback(map) {
      const bounds = new window.google.maps.LatLngBounds(center);
      map.fitBounds(bounds);
      // setMapData(map);
    },
    [center]
  );

  const onUnmount = React.useCallback(function callback() {
    // setMapData(null);
  }, []);

  const handleMapClick = (event) => {
    const { latLng } = event;
    const lat = latLng.lat();
    const lng = latLng.lng();
    setCenter({ lat, lng });
    setMarker({ lat, lng });
    getAddressFromLatLng(lat, lng);
  };

  const handlePinCodeChange = async (val, type) => {
    const pinc = val;

    if (isNaN(pinc)) {
      console.error("Invalid pin code. Please enter a valid number.");
      // You may set an error state or handle it in another way
      return;
    }

    setInput((prevInput) => ({ ...prevInput, zip_code: pinc }));

    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${pinc}&key=AIzaSyA3BYK_Siw8ebziSskitaA5YMjbvcO6n4U`
      );
      const data = await response.json();
      if (data.results && data.results.length > 0) {
        const addressComponents = data.results[0].address_components;
        const latLong = data?.results[0]?.geometry?.location;
        const fullAddress = addressComponents
          ?.map((component) => component.long_name)
          .join(", ");
        if (addressComponents) {
          const cityComponent = addressComponents.find((component) =>
            component?.types?.includes("locality")
          );
          const stateComponent = addressComponents.find((component) =>
            component?.types?.includes("administrative_area_level_1")
          );
          const countryComponent = addressComponents.find((component) =>
            component?.types?.includes("country")
          );

          if (countryComponent) setZip_Country(countryComponent);
          if (stateComponent) setZip_State(stateComponent);
          if (cityComponent) setZip_City(cityComponent);

          const country = countries.find(
            (country) => country.country_name == countryComponent?.long_name
          );
          setStates(country ? country?.states : []);

          const state = country
            ? country.states.find(
              (state) => state?.state_name == stateComponent?.long_name)
            : null;

          const city = state
            ? state.cities.find(
              (city) => city?.city_name == cityComponent?.long_name
            )
            : null;

          if (state) setCities(state.cities);

          if (addressComponents && latLong) {
            if (type == "map") {
              setInput((prevInput) => ({
                ...prevInput,
                country_id: country?.country_id,
                state_id: state?.state_id,
                city_id: city?.city_id,
              }));
            } else {
              setInput((prevInput) => ({
                ...prevInput,
                country_id: country?.country_id,
                state_id: state?.state_id,
                city_id: city?.city_id,
                address: fullAddress,
                latitude: latLong?.lat,
                longitude: latLong?.lng,
              }));
              setCenter(latLong);
              setMarker(latLong);
            }
          }
        } else {
          console.error(
            "No address components found in the Geocoding API response."
          );
        }
      } else {
        console.error("No results found in the Geocoding API response.");
      }
    } catch (error) {
      console.error("Error fetching location:", error);
      // Handle the error (e.g., set an error state)
    }
  };

  const getAddressFromLatLng = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyA3BYK_Siw8ebziSskitaA5YMjbvcO6n4U`
      );
      if (response.ok) {
        const data = await response.json();
        if (data?.results && data?.results.length > 0) {
          const full_address = data?.results[0]?.address_components;
          const location = full_address.find((loc) =>
            loc?.types?.includes("postal_code")
          );
          const location1 = data?.results[0]?.formatted_address;
          const let_lng = data?.results[0]?.geometry?.location;
          setInput({
            ...input,
            latitude: let_lng?.lat,
            longitude: let_lng?.lng,
            zip_code: location?.long_name,
            address: location1,
          });
          handlePinCodeChange(location?.long_name, "map");
        } else {
          console.log("Address not found");
        }
      } else {
        console.log("Failed to fetch address");
      }
    } catch (error) {
      console.error("Error fetching address:", error);
    }
  };

  useEffect(() => {
    setInput({
      ...input,
      f_name: user?.name,
      email: user?.email,
      platform_id: user?.sub?.split("|")[1],
      platform: user?.sub?.split("|")[0].split("-")[0]
    });
  }, [isAuthenticated])

  const handleInputChange = async (e) => {
    const { name, value, files, type } = e.target;
    if (name === "image") {
      setInput({
        ...input,
        [name]: files[0],
        image_type: "browse",
        predefine_image: "",
      });
    } else if (type == "file") {
      setInput({
        ...input,
        [name]: files[0],
      });
    } else {
      setInput({
        ...input,
        [name]: value,
      });
    }
  };

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if(!input.predefine_image || input.predefine_image == ""){
      errors.predefine_image = "Avatar is required";
      isValid = false;  
    }

    if (!input.f_name || !input.f_name.trim()) {
      errors.f_name = "First Name is required";
      isValid = false;
    }

    if (!input.l_name || !input.l_name.trim()) {
      errors.l_name = "Last Name is required";
      isValid = false;
    }

    const aadhaarRegex = /^[0-9]{4}\s[0-9]{4}\s[0-9]{4}$/;
    if (input.aadhar_card == "") {
      errors.aadhar_card = 'Aadhaar Number Is Required"';
      isValid = false;
    } else if (!aadhaarRegex.test(input.aadhar_card)) {
      errors.aadhar_card = "Invalid Aadhaar Number";
      isValid = false;
    }

    if (!input.pan_card || input.pan_card == "") {
      errors.pan_card = "Pan Number Is Required";
      isValid = false;
    }

    if (!input?.aadhar_front_image) {
      errors.aadhar_front_image = "Aadhaar Front Image Required";
      isValid = false;
    }

    if (!input?.aadhar_back_image) {
      errors.aadhar_back_image = "Aadhaar Back Image Required";
      isValid = false;
    }

    if (!input?.pan_front_image) {
      errors.pan_front_image = "Aadhaar Front Image Required";
      isValid = false;
    }

    if (!input?.pan_back_image) {
      errors.pan_back_image = "Aadhaar Back Image Required";
      isValid = false;
    }

    if (!input.date_of_birth || !input.date_of_birth.trim()) {
      errors.date_of_birth = "DOB Is Required";
      isValid = false;
    }

    if (!input.gender || !input.gender.trim()) {
      errors.gender = "Gender Is Required";
      isValid = false;
    }

    if (!input.marital_status || !input.marital_status.trim()) {
      errors.marital_status = "Material Status Is Required";
      isValid = false;
    }

    if (!input.education_background || !input.education_background.trim()) {
      errors.education_background = "Education Background Is Required";
      isValid = false;
    }

    if (!input.industry || !input.industry.trim()) {
      errors.industry = "Industry Is Required";
      isValid = false;
    }

    if (!input.occupation || !input.occupation.trim()) {
      errors.occupation = "Occupation Is Required";
      isValid = false;
    }

    if (!input.house_hold_income || !input.house_hold_income.trim()) {
      errors.house_hold_income = "Income Is Required";
      isValid = false;
    }

    if (!input.state_id || !input.state_id.toString().trim()) {
      errors.state_id = "State Is Required";
      isValid = false;
    }

    if (!input.city_id || !input.city_id.toString().trim()) {
      errors.city_id = "City Is Required";
      isValid = false;
    }

    if (!input.country_id || !input.country_id.toString().trim()) {
      errors.country_id = "Country Is Required";
      isValid = false;
    }

    if (!input.zip_code || !input.zip_code.toString().trim()) {
      errors.zip_code = "Zip Code Is Required";
      isValid = false;
    }


    if (!input.address || !input.address.trim()) {
      errors.address = "Address Is Required";
      isValid = false;
    }

    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

    if (input?.facebook_link) {
      if (!urlRegex.test(input.facebook_link)) {
        errors.facebook_link = "Web Link Not Valid";
        isValid = false;
      }
    }

    if (input?.instagram_link) {
      if (!urlRegex.test(input.instagram_link)) {
        errors.instagram_link = "Web Link Not Valid";
        isValid = false;
      }
    }

    if (input?.website_link) {
      if (!urlRegex.test(input.website_link)) {
        errors.website_link = "Web Link Not Valid";
        isValid = false;
      }
    }

    if (!input.phone || !input.phone.trim() === "") {
      errors.phone = "Mobile is required";
      isValid = false;
    } else if (input.phone.trim().length < 10) {
      errors.phone =
        "Mobile should be at least 10 characters long";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (userType == 'champ') {
      if (validateForm()) {
        try {
          await SocialMediaLogin_champ(input)
            .then(async (response) => {
              if (response?.status == 200) {
                toast.success(response?.data?.message);
                localStorage.setItem('LoginToken', response?.data?.token);
                localStorage.setItem('signUpUserType', '')
                const upProfile = await UserProfile();
                dispatch(profileval(upProfile?.data?.user));
                logout();
                // navigate('/profile')
              }
            })
        } catch (err) {
          console.log(err)
        } finally {
          setBtnLoading(false)
        }
      }
    } else {
      if (validateForm()) {
        setBtnLoading(true)
        try {
          await SocialMediaLogin(input)
            .then(async (response) => {
              if (response?.status == 200) {
                toast.success(response?.data?.message);
                localStorage.setItem('LoginToken', response?.data?.token);
                localStorage.setItem('signUpUserType', '')
                const upProfile = await UserProfile();
                dispatch(profileval(upProfile?.data?.user));
                logout();
                // navigate('/profile')
              }
            });
        } catch (err) {
          console.log(err);
        } finally {
          setBtnLoading(false)
        }
      }
    }
  };

  const DataList = async () => {
    try {
      await get_predefine_list().then((response) => {
        setListing(response?.data?.data)
      });
    } catch (err) {
      console.log(err);
    }
  };

  const Languagelist = async () => {
    try {
      await Get_languages_list().then((response) => {
        const lan = response?.data?.data
        setListingLanguage(lan.map((item) => {
          return {
            value: item?.name,
            label: item?.name
          }
        }))
      });
    } catch (err) {
      console.log(err);
    }
  };

  const editAddress = (countries, cntryid, stateid, cityid) => {
    const country = countries?.find(
      (country) => country?.country_id == cntryid
    );
    setStates(country ? country?.states : []);
    if (states) {
      const country = countries.find(
        (country) => country?.country_id == cntryid
      );
      const state = country
        ? country.states.find((state) => state?.state_id == stateid)
        : null;
      setCities(state ? state.cities : []);
    } else {
      setCities([]);
    }
  };
  // country
  const getCountryCityState = async (cntryid, stateid, cityid) => {
    try {
      get_city_state_country().then((response) => {
        seCountries(response?.data?.data?.countries);
        editAddress(response?.data?.data?.countries, cntryid, stateid, cityid);
        // editState(response?.data?.data?.countries)
      });
    } catch (err) {
      console.log(err);
    }
  };

  const GetAvatars = async () => {
    try {
      const response = await Get_UserAvatar();
      // console.log(response?.data?.data)
      setAvatars(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetAvatars();
    DataList();
    getCountryCityState();
    Languagelist()
  }, []);

  const handleCountryChange = (e) => {
    const cntry = e.target.value;
    setInput({ ...input, country_id: cntry });
    setSelectedCountry(cntry);
    if (cntry) {
      const country = countries.find((country) => country.country_id == cntry);
      setStates(country ? country?.states : []);
    } else {
      setStates([]);
      setCities([]);
    }
  };

  const handleStateChange = (e) => {
    const sState = e.target.value;
    setInput({ ...input, state_id: sState });
    if (sState) {
      const country = countries.find(
        (country) => country?.country_id == selectedCountry
      );
      const state = country
        ? country.states.find((state) => state?.state_id == sState)
        : null;
      setCities(state ? state?.cities : []);
    } else {
      setCities([]);
    }
  };

  const handleCityChange = (e) => {
    const cityid = e.target.value;
    setInput({ ...input, city_id: cityid });
  };

  const handlePhoneNumberChange = (value, country) => {
    const phoneNum = value?.slice(2);
    const d_code = `${country.format.charAt(0)}${country?.dialCode}`;
    setInput({
      ...input,
      phone: phoneNum,
      country_code: d_code,
    });
  };

  const handleLanguageChange = (selectedOptions) => {
    const capitalizedLang = selectedOptions
      ?.map((item) => item?.value)
      .filter((value) => /^[a-zA-Z\s()\/]*$/.test(value))
      .map((value) => value.charAt(0).toUpperCase() + value.slice(1));
    setInput({ ...input, language: JSON.stringify(capitalizedLang) });
    // const lang = selectedOptions.map((item) => item?.value)
    // setInput({ ...input, language: JSON.stringify(lang) })

    const showInCretable = selectedOptions
      ?.filter((item) => /^[a-zA-Z\s()\/]*$/.test(item?.value))
      .map((item) => ({
        value: item?.value?.charAt(0).toUpperCase() + item?.value?.slice(1),
        label: item?.value?.charAt(0).toUpperCase() + item?.value?.slice(1),
      }));

    setLanguages(showInCretable);
    // setLanguages(selectedOptions);
  };


  const HandleLogout = () => {
    logout();
    localStorage.removeItem("LoginToken");
  }

  return (
    <>
      <ToastContainer />

      {/* {loading ? (
        <Loader />
      ) : ( */}
      <div className="container py-2 py-md-5">

        <div className="SocialMediaProfileHead">
          <div className="socialMediaCancelbtn" >
            <button className="btn delete-event-btn" onClick={HandleLogout}><IoCloseSharp size={35} />
            </button>
          </div>
        </div>
        <div className="d-flex justify-content-center py-4">
          <h3>Create Profile</h3>
        </div>
        <form onSubmit={onSubmit}>
          {/* <div className="row">
              <p className="Bannerimg-head">
                Profile Image<span className="asterisk">*</span>
              </p>
              <div className="col-6">
                <label className="form-label">
                  Upload Image Or Choose Avatar{" "}
                  <span className="asterisk">*</span>
                </label>
                <label className="bannerimg-div">
                  <input
                    className="d-none"
                    type="file"
                    name="image"
                    multiple
                    onChange={handleInputChange}
                  />
                  <div className="upload-image-content">
                    <img src={upload} alt="upload" />
                    <p className="uploadimg-font">Upload Profile Image</p>
                    <p className="img-format-text">
                      Image format: .jpg or .png
                    </p>
                    <p className="img-format-text">
                      Recommended size: w: 1440px, H: 600px
                    </p>
                  </div>
                </label>
              </div>
              <div className="col-6 d-flex align-items-center justify-content-center ">
                <div className="">
                  {!input?.image || input?.image == "def.png" ? (
                    ""
                  ) : (
                    <img
                      src={
                        input?.image instanceof Blob
                          ? URL.createObjectURL(input?.image)
                          : `https://ringus.idea2reality.tech/storage/app/public/profile/${input?.image}`
                      }
                      alt="noImge"
                      className=""
                      style={{
                        height: "200px",
                        width: "200px",
                        border: "1px solid black",
                        borderRadius: "50%",
                        objectFit: "fill",
                      }}
                    />
                  )}
                </div>
              </div>
            </div> */}
          <div className="row mt-3">

            <label className="form-label">
              Choose Avatar
              <span className="asterisk">*</span>
            </label>
            <div className="col-12">
              <div className="d-flex">
                {avatars?.map((val) => {
                  return (
                    <div className="position-relative" key={val?.id}>
                      <div className=""></div>

                      <button
                        className="avatar-select-btn"
                        type="button"
                        onClick={() =>
                          setInput({
                            ...input,
                            image: "",
                            predefine_image: val?.image_name,
                            image_type: "predefined",
                          })
                        }
                      >
                        {input?.predefine_image == val?.image_name && (
                          <FaCircleCheck
                            className="profile-image-check"
                            size={20}
                            color="green"
                          />
                        )}

                        <img
                          className="avatar-img"
                          src={`https://ringus.idea2reality.tech/storage/app/public/avatar/${val?.image_name}`}
                          alt="avatar"
                        />
                      </button>
                    </div>
                  );
                })}
              </div>
              {errors?.predefine_image && (
                <span className="error">{errors?.predefine_image}</span>
              )}
            </div>
          </div>

          <div className="row mt-2">
            <div className="form-group col-md-3 col-sm-6 ">
              <label className="form-label">
                First Name<span className="asterisk">*</span>
              </label>
              <input
                type=""
                value={input.f_name}
                name="f_name"
                onChange={handleInputChange}
                className="form-control"
                placeholder="first name"
              />
              {errors.f_name && (
                <span className="error">{errors.f_name}</span>
              )}
            </div>
            <div className="form-group col-md-3 ">
              <label className="form-label">
                Last Name<span className="asterisk">*</span>
              </label>
              <input
                type=""
                value={input.l_name}
                name="l_name"
                onChange={handleInputChange}
                className="form-control"
                placeholder="last name"
              />
              {errors.l_name && (
                <span className="error">{errors.l_name}</span>
              )}
            </div>
            <div className="form-group col-md-3 col-sm-6">
              <label className="form-label">
                DOB<span className="asterisk">*</span>
              </label>
              <div>
                <input
                  type="date"
                  className="form-control"
                  name="date_of_birth"
                  value={input.date_of_birth}
                  onChange={handleInputChange}
                />
                {errors.date_of_birth && (
                  <span className="error">{errors.date_of_birth}</span>
                )}
              </div>
            </div>
            <div className="form-group col-md-3 col-sm-6">
              <label className="form-label">
                Gender<span className="asterisk">*</span>
              </label>
              <select
                className="form-control"
                name="gender"
                onChange={handleInputChange}
              >
                <option selected>Select</option>
                <option value="Male" selected={input?.gender == "Male"}>
                  Male
                </option>
                <option value="Female" selected={input?.gender == "Female"}>
                  Female
                </option>
                <option value="Other" selected={input?.gender == "Other"}>
                  Other
                </option>
              </select>
              {errors.gender && (
                <span className="error">{errors.gender}</span>
              )}
            </div>
          </div>

          <div className="row">
            {/* <h5 className="my-3 form-section-heading">Profile details</h5> */}
            <div className="form-group col-md-3 col-sm-6">
              <label className="form-label">
                Marital Status<span className="asterisk">*</span>
              </label>
              <select
                className="form-control"
                name="marital_status"
                onChange={handleInputChange}
              >
                <option value="" selected>
                  Select
                </option>
                <option
                  value="married"
                  selected={input?.marital_status == "married"}
                >
                  Married
                </option>
                <option
                  value="single"
                  selected={input?.marital_status == "single"}
                >
                  Single
                </option>
                <option
                  value="divorced/separated"
                  selected={input?.marital_status == "divorced/separated"}
                >
                  Divorced / Separated
                </option>
              </select>
              {errors.marital_status && (
                <span className="error">{errors.marital_status}</span>
              )}
            </div>

            <div className="form-group col-md-3 col-sm-6">
              <label className="form-label">
                Anniversary
              </label>
              <input
                type="date"
                className="form-control"
                name="anniversary"
                value={input.anniversary}
                onChange={handleInputChange}
              />
              {errors.anniversary && (
                <span className="error">{errors.anniversary}</span>
              )}
            </div>
            <div className="form-group col-md-3 col-sm-6">
              <label className="form-label">
                Educational Background<span className="asterisk">*</span>
              </label>
              <select
                className="form-control"
                name="education_background"
                onChange={handleInputChange}
              >
                <option selected>Select</option>
                {listing?.educational_background?.map((val) => (
                  <option
                    key={val?.id}
                    value={val?.educations}
                    selected={
                      input?.education_background == val?.educations
                    }
                  >
                    {val?.educations}
                  </option>
                ))}
              </select>
              {errors.education_background && (
                <span className="error">{errors.education_background}</span>
              )}
            </div>

            <div className="form-group col-md-3 col-sm-6">
              <label className="form-label">
                Industry<span className="asterisk">*</span>
              </label>
              <select
                className="form-control"
                name="industry"
                onChange={handleInputChange}
              >
                {/* <option value={input?.industry} selected>
                  {input?.industry}
                </option> */}
                <option selected>Select</option>
                {listing?.industry?.map((val, index) => (
                  <option
                    key={index}
                    value={val?.industry}
                    selected={input?.industry == val?.industry}
                  >
                    {val?.industry}
                  </option>
                ))}
              </select>
              {errors.industry && (
                <span className="error">{errors.industry}</span>
              )}
            </div>
          </div>

          <div className="row">
            <div className="form-group col-md-3 col-sm-6">
              <label className="form-label">
                Occupation<span className="asterisk">*</span>
              </label>
              <select
                className="form-control"
                name="occupation"
                onChange={handleInputChange}
              >
                {/* <option value={input?.occupation} selected>
                  {input?.occupation}
                </option> */}
                <option selected>Select</option>
                {listing?.occupation?.map((val, index) => (
                  <option
                    key={index}
                    value={val?.occupations}
                    selected={input?.occupation == val?.occupations}>
                    {val?.occupations}
                  </option>
                ))}
              </select>
              {errors.occupation && (
                <span className="error">{errors.occupation}</span>
              )}
            </div>

            <div className="form-group col-md-3 col-sm-6">
              <label className="form-label">
                House Hold Income<span className="asterisk">*</span>
              </label>
              <select
                className="form-control"
                name="house_hold_income"
                onChange={handleInputChange}
              >
                {/* <option value={input?.house_hold_income} selected>
                  {input?.house_hold_income}
                </option> */}
                <option value="" selected>
                  Select
                </option>
                {listing?.house_hold_income?.map((val, index) => (
                  <option
                    key={index}
                    value={val?.house_hold_income}
                    selected={
                      input?.house_hold_income == val?.house_hold_income
                    }>
                    {val?.house_hold_income}
                  </option>
                ))}
              </select>
              {errors.house_hold_income && (
                <span className="error">{errors.house_hold_income}</span>
              )}
            </div>

            <div className=" form-group col-md-3 col-sm-6">
              <label className="form-label">
                Languages Known<span className="asterisk">*</span>
              </label>
              <CreatableSelect
                isMulti
                value={languages}
                classNamePrefix="select"
                placeholder="Type Language and press enter..."
                onChange={handleLanguageChange}
                options={listingLanguage}
              />
              {errors.languages && (
                <span className="error">{errors.languages}</span>
              )}
            </div>

            <div className="col-md-3 col-sm-6 form-group ">
              <label className="form-label">
                Phone<span className="asterisk">*</span>
              </label>
              <PhoneInput
                className=""
                country={"in"}
                value={`${input?.country_code}${input?.alternative_number}`}
                onChange={handlePhoneNumberChange}
              />
              {errors.phone && (
                <span className="error">{errors.phone}</span>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-md-3 col-sm-6 form-group ">
              <label className="form-label">
                Aadhaar Number<span className="asterisk">*</span>
              </label>
              <InputMask
                mask="9999 9999 9999"
                className="form-control "
                placeholder="Enter Aadhar Number"
                name="aadhar_card"
                value={input?.aadhar_card}
                onChange={handleInputChange}
              />
              {errors.aadhar_card && (
                <span className="error">{errors.aadhar_card}</span>
              )}
            </div>

            <div className="col-md-3 col-sm-6 form-group ">
              <label className="form-label">
                Pan Number<span className="asterisk">*</span>
              </label>

              <input
                type="text"
                className={
                  input?.pan_card
                    ? "form-control text-uppercase rounded-3"
                    : "form-control rounded-3"
                }
                placeholder="Enter Pan Number"
                name="pan_card"
                value={input.pan_card}
                onChange={handleInputChange}
              />
              {errors.pan_card && (
                <span className="" style={{ color: "red", fontSize: "13px" }}>
                  {errors.pan_card}
                </span>
              )}
            </div>

            <div className="form-group col-md-3 col-sm-6">
              <label className="form-label">
                Email<span className="asterisk">*</span>
              </label>
              <div className="position-relative d-flex align-items-center">
                <input
                  type="text"
                  value={input.email}
                  name="email"
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="email"
                />
              </div>
              {errors.email && (
                <span className="error">{errors.email}</span>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-md-3 col-sm-6 form-group">
              <label className="form-label">
                Upload Aadhaar Front<span className="asterisk">*</span>
              </label>
              <input
                type="file"
                className="form-control rounded-3"
                name="aadhar_front_image"
                onChange={handleInputChange}
              />
              {errors.aadhar_front_image && (
                <span className="" style={{ color: "red", fontSize: "13px" }}>
                  {errors.aadhar_front_image}
                </span>
              )}
              <div className="">
                {input?.aadhar_front_image && (
                  <div className="form-group">
                    <img
                      className="document-img"
                      src={
                        input?.aadhar_front_image instanceof Blob
                          ? URL.createObjectURL(input?.aadhar_front_image)
                          : `https://ringus.idea2reality.tech/storage/app/public/pan_aadhar_image/${input?.aadhar_front_image}`
                      }
                      alt="Aadharimg1"
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-3 col-sm-6 form-group ">
              <label className="form-label">
                Upload Aadhaar Back<span className="asterisk">*</span>
              </label>
              <input
                type="file"
                className="form-control rounded-3"
                name="aadhar_back_image"
                onChange={handleInputChange}
              />
              {errors.aadhar_back_image && (
                <span className="" style={{ color: "red", fontSize: "13px" }}>
                  {errors.aadhar_back_image}
                </span>
              )}
              <div className="">
                {input?.aadhar_back_image && (
                  <div className="form-group">
                    <img
                      className="document-img"
                      src={
                        input?.aadhar_back_image instanceof Blob
                          ? URL.createObjectURL(input?.aadhar_back_image)
                          : `https://ringus.idea2reality.tech/storage/app/public/pan_aadhar_image/${input?.aadhar_back_image}`
                      }
                      alt="Aadharimg2"
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-3 col-sm-6 form-group ">
              <label className="form-label">
                Upload Pan Front<span className="asterisk">*</span>
              </label>
              <input
                type="file"
                className="form-control rounded-3"
                name="pan_front_image"
                onChange={handleInputChange}
              />
              {errors.pan_front_image && (
                <span className="" style={{ color: "red", fontSize: "13px" }}>
                  {errors.pan_front_image}
                </span>
              )}
              <div className="">
                {input?.pan_front_image && (
                  <div className="form-group">
                    <img
                      className="document-img"
                      src={
                        input?.pan_front_image instanceof Blob
                          ? URL.createObjectURL(input?.pan_front_image)
                          : `https://ringus.idea2reality.tech/storage/app/public/pan_aadhar_image/${input?.pan_front_image}`
                      }
                      alt="Panimg1"
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-3 col-sm-6 form-group ">
              <label className="form-label">
                Upload Pan Back<span className="asterisk">*</span>
              </label>
              <input
                type="file"
                className="form-control rounded-3"
                name="pan_back_image"
                onChange={handleInputChange}
              />
              {errors.pan_back_image && (
                <span className="" style={{ color: "red", fontSize: "13px" }}>
                  {errors.pan_back_image}
                </span>
              )}

              <div className="">
                {input?.pan_back_image && (
                  <div className="form-group">
                    <img
                      className="document-img"
                      src={
                        input?.pan_back_image instanceof Blob
                          ? URL.createObjectURL(input?.pan_back_image)
                          : `https://ringus.idea2reality.tech/storage/app/public/pan_aadhar_image/${input?.pan_back_image}`
                      }
                      alt="Panimg2"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3 col-sm-6 form-group ">
              <label className="form-label">Facebook Link(Optional)</label>
              <input
                type="text"
                className="form-control rounded-3"
                placeholder="Enter Facebook Link"
                name="facebook_link"
                value={input?.facebook_link}
                onChange={handleInputChange}
              />
              {errors.facebook_link && (
                <span className="" style={{ color: "red", fontSize: "13px" }}>
                  {errors.facebook_link}
                </span>
              )}
            </div>
            <div className="col-md-3 col-sm-6 form-group ">
              <label className="form-label">Instagram Link(Optional)</label>
              <input
                type="text"
                className="form-control rounded-3"
                placeholder="Enter Instagram Link"
                name="instagram_link"
                value={input.instagram_link}
                onChange={handleInputChange}
              />
              {errors.instagram_link && (
                <span className="" style={{ color: "red", fontSize: "13px" }}>
                  {errors.instagram_link}
                </span>
              )}
            </div>

            <div className="col-md-3 col-sm-6 form-group ">
              <label className="form-label">WebSite Link(Optional)</label>
              <input
                type="text"
                className="form-control rounded-3"
                placeholder="Enter WebSite Link"
                name="website_link"
                value={input?.website_link}
                onChange={handleInputChange}
              />
              {errors.website_link && (
                <span className="" style={{ color: "red", fontSize: "13px" }}>
                  {errors.website_link}
                </span>
              )}
            </div>
          </div>

          <div className="row">
            <h5 className="my-3 form-section-heading">Address</h5>
            <div className="form-group col-md-3 col-sm-6 mt-2">
              <label className="form-label">
                Pin Code<span className="asterisk">*</span>
              </label>
              <input
                type="number"
                value={input.zip_code}
                name="zip_code"
                onChange={(e) => handlePinCodeChange(e.target.value)}
                className="form-control"
                placeholder="Pin Code..."
              />
              {errors.zip_code && (
                <span className="error">{errors.zip_code}</span>
              )}
            </div>

            <div className="form-group col-md-3 col-sm-6 mt-2">
              <label className="form-label">
                Country<span className="asterisk">*</span>
              </label>
              <select
                className="form-control"
                name="country_id"
                onChange={handleCountryChange}
              >
                <option value="" selected>
                  Select country...
                </option>
                {
                  <>
                    {countries &&
                      countries?.map((val) => {
                        return (
                          <>
                            <option
                              value={val?.country_id}
                              selected={
                                Object.keys(zip_country).length == 0
                                  ? val.country_id == input.country_id
                                  : zip_country?.long_name ==
                                  val?.country_name
                              }
                            >
                              {val?.country_name}
                            </option>
                          </>
                        );
                      })}
                  </>
                }
              </select>
              {errors.country_id && (
                <span className="error">{errors.country_id}</span>
              )}
            </div>

            <div className="form-group col-md-3 col-sm-6 mt-2">
              <label className="form-label">
                State<span className="asterisk">*</span>
              </label>
              <select
                className="form-control"
                name="state_id"
                onChange={handleStateChange}
              >
                <option value="" selected>
                  Select State...
                </option>
                {states &&
                  states?.map((val) => {
                    return (
                      <>
                        <option
                          value={val?.state_id}
                          selected={
                            Object.keys(zip_state).length == 0
                              ? val.state_id == input.state_id
                              : zip_state?.long_name == val?.state_name
                          }
                        >
                          {val?.state_name}
                        </option>
                      </>
                    );
                  })}
              </select>
              {errors.state_id && (
                <span className="error">{errors.state_id}</span>
              )}
            </div>

            <div className="form-group col-md-3 col-sm-6 mt-2">
              <label className="form-label">
                City<span className="asterisk">*</span>
              </label>
              <select
                className="form-control"
                name="city_id"
                onChange={handleCityChange}
              >
                <option selected>Select City...</option>
                {cities &&
                  cities?.map((val) => {
                    if (val.city_id == input.city_id) {
                      return (
                        <>
                          <option value={input?.city_id} selected>
                            {val?.city_name}
                          </option>
                        </>
                      );
                    }
                  })}

                {cities?.map((val) => {
                  return (
                    <>
                      <option
                        value={val?.city_id}
                        selected={
                          Object.keys(zip_city).length == 0
                            ? val.city_id == input.city_id
                            : zip_city?.long_name == val?.city_name
                        }>
                        {val?.city_name}
                      </option>
                    </>
                  );
                })}
              </select>
              {errors.city_id && (
                <span className="error">{errors.city_id}</span>
              )}
            </div>

          </div>

          <div className="row">
          <div className="form-group col-md-3 col-sm-6">
                <label className="form-label">
                  Flat / House No. / Apartment, Company<span className="asterisk">*</span>
                </label>
                <input
                  type="text"
                  value={input.house_no}
                  name="house_no"
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Flat / House No., Building / Apartment, Company"
                />
                {errors.house_no && (
                  <span className="error">{errors.house_no}</span>
                )}
          </div>

          <div className="form-group col-md-6 ">
              <label className="form-label">
                Area, Street, Sector, Village<span className="asterisk">*</span>
              </label>
              <input
                type="text"
                value={input.address}
                name="address"
                onChange={handleInputChange}
                className="form-control"
                placeholder="Area, Street, Sector, Village..."
              />
              {errors.address && (
                <span className="error">{errors.address}</span>
              )}
            </div>

            <div className="form-group col-md-3 col-sm-6">
                <label className="form-label">
                  Landmark<span className="asterisk">*</span>
                </label>
                <input
                  type="text"
                  value={input.landmark}
                  name="landmark"
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Landmark"
                />
                {errors.landmark && (
                  <span className="error">{errors.landmark}</span>
                )}
            </div>
          </div>
            
          <div className="row">
          <div className="form-group col-md-3 col-sm-6 ">
                <label className="form-label">
                  Address Type<span className="asterisk">*</span>
                </label>
                <select
                  className="form-control"
                  name="address_type"
                  onChange={handleInputChange}>
                  <option value='' selected>Select Address Type...</option>
                  <option value='Home' selected={input.address_type == 'Home'}>Home</option>
                  <option value='Office' selected={input.address_type == 'Office'}>Office</option>

                </select>
                {errors.address_type && (
                  <span className="error">{errors.address_type}</span>
                )}
            </div>
          </div>

          {/* <div className="row">
          <div>
              {isLoaded ? (
                <GoogleMap
                  mapContainerStyle={{ height: "400px", width: "100%" }}
                  center={center}
                  zoom={13}
                  // onLoad={onLoad}
                  onUnmount={onUnmount}
                  onClick={handleMapClick}
                >
                  {marker && <Marker position={marker} />}
                </GoogleMap>
              ) : (
                ""
              )}
            </div>
          </div> */}

          <div className="d-flex justify-content-end align-items-center mt-4">
            {/* <Link to="/profile" className="btn delete-event-btn">
              Back
            </Link> */}
            <div className="">
              <button
                className="d-flex align-items-center gap-1 btn saveandcontinue-btn "
                type="submit">
                {btnLoading ? (
                  <div className="spinner-border text-light " role="status">
                    <span className="sr-only">Loading...</span>
                  </div> ) : ("")} Save
                <img className="arrow-img" src={arrow} alt="arrow" />
              </button>
            </div>
          </div>
        </form>
      </div>
      {/* )} */}
    </>
  );
};
export default SocialMediaProfile;
