const EventCoupons = ({item,discountAmount,textToCopyRef,copyStatus,handleCopy}) => {
  return (
    <>
      <div className="offer-container-c mt-3">
        <h1 className="brand-c">{item?.coupon_name}</h1>
        {item?.discount_type == "Percentage" ? (
          <h2 className="offer-title-c">
            Get {item?.discount}% OFF up to {discountAmount.toFixed(2)}
          </h2>
        ) : (
          <h2 className="offer-title-c">
            Flat {item?.discount} OFF on minimum amount of{" "}
            {item?.min_amount.slice(0, -3)}
          </h2>
        )}
        <p className="offer-subtitle-c">
          Valid on total value of items worth {item?.min_amount.slice(0, -3)} or
          more.
        </p>
        <div className="code-container-c">
          <span className="code-c" ref={textToCopyRef}>
            {item?.coupon_code}
          </span>
          <button
            className={copyStatus ? "copied-button-c" : "copy-button-c"}
            onClick={handleCopy}>
            {copyStatus ? "Coupon Copied" : "TAP TO COPY CODE"}
          </button>
        </div>
        <ul className="offer-details-c">
          <li>This offer is personalised for you.</li>
          {item?.discount_type == "Percentage" ? (
            <li>Maximum instant discount of {discountAmount?.toFixed(2)}</li>
          ) : (
            <li>Maximum instant discount of {item?.discount}</li>
          )}
          <li>Applicable only on select restaurants.</li>
          <li>Applicable maximum 3 times in a day.</li>
          <li>Other T&C’s may apply.</li>
        </ul>
      </div>
    </>
  );
};
export default EventCoupons;
