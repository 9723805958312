import { useEffect, useState } from "react";
import React from "react";
import { ToastContainer, toast } from "react-toastify";
import {
  Membership_Plan,
  Update_MembershipPayment,
} from "../../utils/membershipevent/manageMembershipEvent";
import { useSelector } from "react-redux";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

const Pricing = ({ val }) => {
  const [currencyIcn, setCurrencyIcn] = useState("");
  const [payBtnLoading, setpayBtnLoading] = useState(false);
  const profileinfo = useSelector((data) => data?.Profile_data?.ProfileData);

  const checkCurrency = (val) => {
    if (val?.currency == "INR") {
      setCurrencyIcn("₹");
    } else if (val?.currency == "USD") {
      setCurrencyIcn("$");
    } else if (val?.currency == "EUR") {
      setCurrencyIcn("€");
    } else if (val?.currency == "GBP") {
      setCurrencyIcn("€");
    }
  };

  useEffect(() => {
    checkCurrency(val);
  }, []);

  const handleBuyNow = async (id) => {
    const fData = {
      membership_id: id,
    };
    try {
      setpayBtnLoading(true);
      const response = await Membership_Plan(fData);
      const orderid = response?.data?.order_id;
      const options = {
        key: "rzp_test_IcgTNsIE5vV4DM",
        key_secret: "yHf8cImq5DtzflsmjgsuJApS",
        order_id: orderid,
        handler: async (response) => {
          const payres = {
            razorpay_order_id: response?.razorpay_order_id,
            razorpay_payment_id: response?.razorpay_payment_id,
            razorpay_signature: response?.razorpay_signature,
          };
          const update_pay_FD = {
            payment_id: response?.razorpay_payment_id,
            order_id: orderid,
            payment_response: JSON.stringify(payres),
            status: "success",
          };
          try {
            const response = await Update_MembershipPayment(update_pay_FD);
            if (response?.data?.success) {
              toast.success(response?.data?.message,{
                position:'top-center'
              });
            }
          } catch (error) {
            console.log(error);
          }
        },
        modal: {
          ondismiss:async()=>{
            const payres = {
              razorpay_order_id: response?.razorpay_order_id,
              razorpay_payment_id: response?.razorpay_payment_id,
              razorpay_signature: response?.razorpay_signature,
            };
            const update_pay_FD = {
              payment_id: response?.razorpay_payment_id,
              order_id: orderid,
              payment_response: JSON.stringify(payres),
              status: "failed",
            };
            try {
              const UpdatePay =await Update_MembershipPayment(update_pay_FD);
              console.log("paaayyy", UpdatePay);
            } catch (error) {
              console.log(error);
            }
            // finally{
            //   setPayNowBtnLoading(false)
            // }
          },
        },
      };
      const razorpayInstance = new window.Razorpay(options);
      razorpayInstance.open();
    } catch (error) {
      console.log("err", error);
    } finally {
      setpayBtnLoading(false);
    }
  };

const jPrice = parseInt(val?.amount);
  return (
    <>
      <div className="col-xs-8 col-sm-9 col-lg-4 col-md-6 mt-3">
      <ToastContainer />
        <div className="pricing-plan">
        <div className="pricing-plan-top">
        {
          val?.is_popular ?
            <div className="popular_plan_div">
            <p className="popular-plan-text">Popular</p>
          </div>
          :""
        }
          <h3>
            {val?.name}
            <span>
              {/* <sup>{currencyIcn}</sup> */}
               {val?.amount == null || 0 ? 'free' : `${currencyIcn} ${val?.amount}`  }
              {/* {currencyIcn} {val?.amount} */}
            </span>
            {val?.amount == null || 0 ? "" : <p>Per Month</p>}
          </h3>
          </div>
          <ul className="pricing-content">
            {val?.benefits &&
              val?.benefits?.map((ben) => {
                return (
                  <>
                  <div className="d-flex gap-1" key={ben?.id}>
                   <div>
                  <IoMdCheckmarkCircleOutline color="green" size={20}/>
                  </div>
                  <li title={ben?.benefits}>{ben?.benefits}</li>
                  </div>
                  </>
                );
              })}
               {/* <li><IoMdCheckmarkCircleOutline color="green" size={20}/> 10% Discount</li> */}
          </ul>

         {
          // profileinfo?.is_prime_user ? 
          //  <button className="btn btn-success px-5 rounded" disabled>
          //   Active
          // </button> 
          //  :
           val?.amount == null || 0 ?   
           <button className="btn btn-success px-5 rounded" disabled>
           Free
         </button> :
          <button
          className="btn btn-primary rounded"
          onClick={() => handleBuyNow(val?.id)}>
          {payBtnLoading ? (
            <div className="spinner-border text-light " role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : ("")}
          Buy Ticket Now
        </button> }
        </div>
      </div>
    </>
  );
};

export default Pricing;
