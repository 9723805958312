import { useEffect, useState } from "react";
import EditInterests from "./EditInterestsModal";
import { useDispatch, useSelector } from "react-redux";
import { getEventType } from "../../redux/BasicInfoFormLists/GetBasicInfoLists";
import { Get_MyEvent_Type } from "../../utils/profile/GetMyEventType";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserProfile } from "../../utils/user/UserManager";
import { profileval } from "../../redux/UserProfile/Profile";
import Loader2 from "../Loader/Loader2";
import { Get_UserInterest } from "../../utils/basicInformation/basicinformationManager";

const Interests = ({ interest }) => {
  const [editModal, setEditModal] = useState(false);
  const [eventType, setEventType] = useState([]);
  const [loader, setLoading] = useState(false);
  const dispatch = useDispatch();

  // const EventType = async () => {
  //   try {
  //     await Get_MyEvent_Type().then((response) => {
  //       setEventType(response?.data);
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // console.log("inn",eventType)

  const GetUserInt = async () => {
    try {
      await Get_UserInterest().then((response) => {
        // console.log("ree",response?.data)
        setEventType(response?.data);
      });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    GetUserInt()
    // EventType();
  }, []);

  const getProgile = async () => {
    setLoading(true);
    try {
      await UserProfile().then((data) => {
        dispatch(profileval(data?.data?.user));
      });
    } catch (error) {
      console.log("profile", error);
    } finally {
      setLoading(false);
    }
  };

  const EditMessage = (msg) => {
    getProgile();
    toast.success(msg, {
      position: "top-right",
      autoClose: 2000,
    });
  };

  return (
    <>
      <ToastContainer />
      <div>
        <div>
          <div className="d-flex justify-content-between">
            <h2 className="Interests-title">My Interests</h2>
            <button
              className="profile-editbtn"
              onClick={() => setEditModal(!editModal)} >
              Edit My Interests
            </button>
          </div>
        </div>

        {loader ? (
          <Loader2 />
        ) : (
          <div className="content-button-div mt-3">
            {interest &&
              interest?.map((data, index) => {
                return (
                  <div key={index}>
                    <div className="hashtag-div">
                      <p className="hashtag-text" title={data}>
                        {data}
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
      <EditInterests
        interest={interest}
        EditMessage={EditMessage}
        setEventType={setEventType}
        types={eventType}
        setEditModal={setEditModal}
        GetUserInt={GetUserInt}
        editModal={editModal}
      />
    </>
  );
};

export default Interests;
