import React, { useState } from "react";
import ReactStars from "react-rating-stars-component";
import imageicon from "../../assets/images/image-upload-icon.svg";
import { ReviewEvent } from "../../utils/review/Review";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import "swiper/css/navigation";
import 'swiper/css/autoplay';
import 'swiper/css/grid';
import { Navigation, Grid } from 'swiper/modules'
import { toast } from "react-toastify";


const StarRating = ({ eventId, userReview }) => {
    const [images, setImages] = useState([]);
    const [thoughts, setThoughts] = useState("");
    const [rating, setRating] = useState(0);
    const [errorRating, setErrorRating] = useState("");
    const [errorThoughts, setErrorThoughts] = useState("");
    const [btnloading,setBtnLoading]=useState(false)


    const handleThoughtsChange = (event) => {
        setThoughts(event.target.value);
        setErrorThoughts("");
    };

    const handleFileChangeImage = (event) => {
        const files = event.target.files;
        const imageArray = [];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            imageArray.push(file)
        }
        setImages([...images, ...imageArray]);
    }

    const handleRatingChange = (newRating) => {
        setRating(newRating);
        setErrorRating("");
    };

    const handleSendClick = async () => {
        if (rating === 0) {
            setErrorRating("Please provide a rating before sending.");
        }
        if (thoughts.trim() === "") {
            setErrorThoughts("Please enter your thoughts before sending.");
        }
        if (rating === 0 || thoughts.trim() === "") {
            return;
        }
        setErrorRating("");
        setErrorThoughts("");

        const data = {
            fileUpload: images,
            comment: thoughts,
            rating: rating,
            event_id: eventId
        };

        try {
            setBtnLoading(true)
            await ReviewEvent(data)
                .then((response) => {
                    if (response?.success) {
                        userReview()
                        toast.success(response?.message)
                        setImages([])
                        setThoughts("")
                        setRating(0)
                    }
                })
        } catch (err) {
            toast.error(err?.response?.data?.message,{
                position: "top-center",
            })
        }finally{
            setBtnLoading(false)
        }

        setRating(0);
        setThoughts("");
    };

    return (
        <>
            <div className="container social-media-div py-3">
                <div className="d-flex align-items-center gap-2">
                    <h2 className="social-m-head">Give Review</h2>
                    <hr className="w-100" />
                </div>

                <div className="main-thoughts">
                    <div>
                        <div className="row">
                            <h4 className="form-heading">Select Image</h4>
                            <div className="d-flex flex-column align-items-center col-md-12">
                                <label>
                                    <img className='upload-icon' src={imageicon} alt='img-upload' />
                                    <input className='d-none' type='file' multiple onChange={handleFileChangeImage} />
                                </label>
                                <div className=''>The maximunm size of an image can be 1 MB</div>
                            </div>
                            <div className="d-flex justify-content-center mt-3 ms-0">
                                <div className="swiper-div">
                                    <Swiper
                                        spaceBetween={20}
                                        slidesPerView={4}
                                        modules={[Navigation, Grid]}
                                        navigation={true}
                                        loop={false}
                                        grid={{
                                            rows: 2,
                                            fill: "row"
                                        }}
                                        autoplay={{
                                            delay: 2000,
                                            disableOnInteraction: true,
                                        }}
                                        className='mySwiper py-3'>
                                        {images?.map((file, index) => (
                                            <SwiperSlide key={index}>
                                                <div className="p-0 mt-0" key={index}>
                                                    <img
                                                        className="event-gellary-img"
                                                        src={URL.createObjectURL(file)}
                                                        alt={`selected-${index}`}
                                                    />
                                                </div>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                            </div>

                        </div>
                        <div className="my-3">
                            <ReactStars
                                count={5}
                                size={30}
                                onChange={handleRatingChange}
                                value={rating}
                                emptyIcon={<i className="far fa-star"></i>}
                                fullIcon={<i className="fa fa-star"></i>}
                                activeColor="#f43254"
                            />
                            {errorRating && <p className="text-danger">{errorRating}</p>}
                        </div>
                        <div className="mt-3">
                            <h6>Do you have any thoughts you’d like to share?</h6>
                            <textarea
                                className="form-control"
                                placeholder="Write your query here…"
                                name="query"
                                rows="4"
                                cols="50"
                                value={thoughts}
                                onChange={handleThoughtsChange}
                            ></textarea>
                            {errorThoughts && <p className="text-danger">{errorThoughts}</p>}
                        </div>
                    </div>
                      <div className="d-flex justify-content-end">
                        <button className=" become-sponsor mt-3 w-25" onClick={handleSendClick}>
                            {
                             btnloading &&    
                           <div
                             className="spinner-border text-light "
                             role="status">
                             <span className="sr-only">Loading...</span>
                           </div>
                            }
                            Send
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default StarRating;
