import React from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
// import HomeDefault from './components/pages/HomeDefault';
import Navigation from './components/Navigation/Navigation';
// import HomeTwo from './components/pages/HomeTwo';
// import HomeThree from './components/pages/HomeThree';
// import HomeFive from './components/pages/HomeFive';
// import AboutOne from './components/pages/AboutOne';
// import AboutTwo from './components/pages/AboutTwo';
// import AboutThree from './components/pages/AboutThree';
// import PricingOne from './components/pages/PricingOne';
// import PricingTwo from './components/pages/PricingTwo';
// import SpeakersOne from './components/pages/SpeakersOne';
// import SpeakersTwo from './components/pages/SpeakersTwo';
// import SpeakersThree from './components/pages/SpeakersThree';
// import ScheduleOne from './components/pages/ScheduleOne';
// import ScheduleTwo from './components/pages/ScheduleTwo';
// import ScheduleThree from './components/pages/ScheduleThree';
// import ScheduleFour from './components/pages/ScheduleFour';
import Login from './pages/LoginPage/Login';
import Signup from './pages/SignUpPage/Signup';
import ComingSoon from './pages/ComingSoonPage/ComingSoon';
import { useEffect } from 'react';
// import NotFound from './components/pages/NotFound';
// import Faq from './components/pages/Faq';
// import Contact from './components/pages/Contact';
// import BlogOne from './components/pages/BlogOne';
// import BlogTwo from './components/pages/BlogTwo';
// import BlogThree from './components/pages/BlogThree';
// import BlogFour from './components/pages/BlogFour';
// import BlogDetails from './components/pages/BlogDetails';
// import Sponsor from './components/pages/Sponsor';
import { useState } from 'react';
import EventDetail from './pages/EventDetail/EventDetail';
import Home from './pages/HomePage/Home';
import ForgotPassword from './pages/ForgotPasswordPage/ForgotPassword';
import Footer from './components/Common/Footer';
import Preview from './pages/Preview/Preview';
import ScrollToTopOnPageChange from './components/Common/ScrollToTopPageChange';
import Events from './pages/Events/Events';
import EditEvent from './pages/EditEvent/EditEvent';
import HowitWorks from './pages/HowitWorks/HowitWorks';
import Profile from './pages/Profile/Profile';
import Notification from './pages/Notification/Notification';
import Chat from './pages/Chat/Chat';
import PrivateRoute from './PrivateRoutes';
import Setting from './pages/setting/Setting';
import ManageRequest from './pages/ManageRequest/ManageRequest';
import CreateEvent from './pages/CreateEventPage/CreateEvent';
import Testimonial from './pages/Testiminal/Testimonial';
import SupportPages from './pages/FooterPages/SupportPages';
import PageNotFound from './pages/404page/PageNotFound';
import SocialMediaProfile from './pages/Profile/SocialMediaProfile';
import PayNow from './pages/Pay/PayNow';
import MyOrders from './pages/MyOrders/MyOrders';
import MyEvents from './pages/MyEventsPage/MyEvents';
import ChampDashboard from './pages/champ/ChampDashboard';
import ChampEvent from './pages/champEvent/ChampEvent';
import { useDispatch, useSelector } from 'react-redux';
import ChampProfile from './pages/Profile/ChampProfile';
import EditChampProfile from './pages/Profile/EditChampProfile';
import SponsorEventList from './pages/champ/SponsorEventList';
import EditProfile from './pages/Profile/EditProfile';
import { useAuth0 } from '@auth0/auth0-react';
import Loader from './components/Loader/Loader';
import CreatePastEvent from './pages/CreatePassEvent/CreatePastEvent';
import MyPastEvents from './pages/MyPastEvent/MyPastEvents';
import PastEventDetail from './pages/EventDetail/PastEventDetail';
import MyEvenTransaction from './pages/MyEventTransaction/MyEventTransaction';
import MembershipEvent from './pages/MembershipEvent/MembershipEvent';
import MembershipTransaction from './pages/MembershipEvent/MembershipTransaction';
import MainBannerPages from './pages/MainBannerBtnpage/BannerBtnPages';
import axios from "axios";
import { profileval } from './redux/UserProfile/Profile';
import { Check_SocialMediaUser, UserProfile } from './utils/user/UserManager';
import Home2 from './pages/HomePage/Home2';
import { isTokenValid } from './AuthbyToken';
import ScrollButton from './components/Common/ScrollButton';
import ProfileView from './pages/UserProfileView/ProfileView';
import EventSponsorTransaction from './pages/champ/EventSponsorTransactions';
import EditPastEvent from './pages/EditPastEvent/EditPastEvent';
import { toast } from 'react-toastify';
import PastEventPreview from './pages/PastEventPreview/PastEventPreview';
import EventApproveAndReject from './pages/EventApprove&Reject/EventApproveAndReject';
import CreateExpressEvent from './pages/CreateExpressEventPage/CreateExpressEvent';
import ExpressEventDetail from './pages/EventDetail/ExpressEventDetail';
import ExpressPreview from './pages/Preview/ExpressPreview';
import PastEventApproveAndReject from './pages/EventApprove&Reject/PastEventApproveAndReject';
// Conditionally render Navigation
// const renderNavigation = () => {
//     if (
//         !(
//             window.location.pathname === '/login' ||
//             window.location.pathname === '/signup' ||
//             window.location.pathname === '/coming-soon' ||
//             window.location.pathname === '/error-404'
//         )
//     ) {
//         return <Navigation />;
//     }
// };


const AppRouter = () => {
  const profileinfo = useSelector((data) => data?.Profile_data?.ProfileData);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showNavbar, setShowNavbar] = useState(true);
  const isValid = isTokenValid();

  useEffect(() => {
    setShowNavbar(!['/login', '/signup', "/forgotpassword", "/socialmediaprofile"].includes(location.pathname));
  }, [location.pathname]);

  const renderNavigation = () => {
    if (!(window.location.pathname === '/login' || window.location.pathname === '/signup' ||
      window.location.pathname === '/coming-soon' || window.location.pathname === '/error-404')) {
      return <Navigation />;
    }
  }

  const { user, logout, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const getProgile = async () => {
    try {
      await UserProfile().then((data) => {
        dispatch(profileval(data?.data?.user));
      });
    } catch (error) {
      console.log("profile", error);
    }
  };

  const check_social_media_user = async () => {
    // const fdata = {
    //   email: user?.email
    // }

    const fdata = {
      f_name: user?.name,
      email: user?.email,
      platform_id: user?.sub?.split("|")[1],
      platform: user?.sub?.split("|")[0].split("-")[0]
    }

    try {
      const response = await Check_SocialMediaUser(fdata);
      const token = response?.data?.token;
      if (token) {
        toast.success(response?.data?.message, {
          position: 'top-center',
          autoClose: 3000
        })
        localStorage.setItem('LoginToken', token);
        getProgile();
        logout();
      } else {
        // navigate('/socialmediaprofile')
        navigate("/")
      }
    } catch (error) {
      console?.log("err", error);
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      const fetchAccessToken = async () => {
        try {
          const token = await getAccessTokenSilently();
          if (token != null) {
            user?.email && check_social_media_user()
            // navigate('/socialmediaprofile')
          }
        } catch (err) {
          console.error('Error fetching access token:', err);
        }
      };
      fetchAccessToken();
    }
  }, [isAuthenticated]);

  // useEffect(() => {
  //   setInput({
  //     ...input,
  //     f_name: user?.name,
  //     email: user?.email,
  //     platform_id: user?.sub?.split("|")[1],
  //     platform: user?.sub?.split("|")[0].split("-")[0]
  //   });
  // }, [isAuthenticated])



  return (
    <>
      <div className={`${!showNavbar && "d-none"}`}><Navigation /></div>
      <ScrollToTopOnPageChange />

      <Routes basename={process.env.PUBLIC_URL}>
        {/* <Route path="/" element={profileinfo?.user_type ? <ChampDashboard /> : <Home />} />  */}
        {/* <Route path="/" element={isValid ? <Home /> : <Home2 />} /> */}
        <Route path="/" element={<Home />} />
        <Route path="/dashboard" element={<PrivateRoute Component={ChampDashboard} />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        {/* <Route path="/coming-soon" element={<ComingSoon />} />  */}
        <Route path='/eventdetail/:id' element={<EventDetail />} />
        <Route path='/forgotpassword' element={<ForgotPassword />} />
        <Route path='/:path' element={<SupportPages />} />
        <Route path='/events/:category' element={<Events />} />
        <Route path='/howitworks' element={<HowitWorks />} />
        <Route path='/setting' element={<PrivateRoute Component={Setting} />} />
        <Route path='/MainBannerPage/:path' element={<PrivateRoute Component={MainBannerPages} />} />
        <Route path='/managerequest' element={<PrivateRoute Component={ManageRequest} />} />
        <Route path='/profile' element={profileinfo?.user_type ? <PrivateRoute Component={ChampProfile} /> : <PrivateRoute Component={Profile} />} />
        <Route path='/editprofile/:id' element={profileinfo?.user_type ? <PrivateRoute Component={EditChampProfile} /> : <PrivateRoute Component={EditProfile} />} />
        <Route path='/socialmediaprofile' element={<PrivateRoute Component={SocialMediaProfile} />} />
        <Route path='/myevents/:event_status' element={<PrivateRoute Component={MyEvents} />} />
        <Route path='/CreateEvent/:id' element={<PrivateRoute Component={CreateEvent} />} />
        <Route path='/EditEvent/:id' element={<PrivateRoute Component={EditEvent} />} />
        <Route path='/EditPastEvent/:id' element={<PrivateRoute Component={EditPastEvent} />} />
        <Route path='/chat' element={<PrivateRoute Component={Chat} />} />
        <Route path='/notification' element={<PrivateRoute Component={Notification} />} />
        <Route path="/testimonial" element={<PrivateRoute Component={Testimonial } />} />
        <Route path='/preview/:id' element={<Preview />} />
        <Route path='/pasteventpreview/:id' element={<PastEventPreview />} />
        <Route path='/paynow' element={<PrivateRoute Component={PayNow} />} />
        <Route path='/myorders' element={<PrivateRoute Component={MyOrders} />} />
        <Route path='/sponsor/:id' element={<PrivateRoute Component={SponsorEventList} />} />
        <Route path='/mypastevents/:event_status' element={<PrivateRoute Component={MyPastEvents} />} />
        <Route path='/createpastevent/:id' element={<PrivateRoute Component={CreatePastEvent} />} />
        <Route path='/myeventransaction' element={<PrivateRoute Component={MyEvenTransaction} />} />
        <Route path='/membership' element={<PrivateRoute Component={MembershipEvent} />} />
        <Route path='/membershiptransaction' element={<PrivateRoute Component={MembershipTransaction} />} />
        <Route path='/profileview/:id' element={<PrivateRoute Component={ProfileView} />} />
        <Route path='/pasteventdetail/:id' element={<PastEventDetail />} />

        <Route path='/createexpressevent/:id' element={<PrivateRoute Component={CreateExpressEvent} />}/>
        <Route path='/expresseventdetail/:id' element={<ExpressEventDetail />} />
        <Route path='/expresseventpreview/:id' element={<ExpressPreview/>} />
    
        <Route path='/eventsponsoredtransaction/:id' element={<EventSponsorTransaction/>} />
        
        <Route path='/eventapproveandreject/:id/:uuid' element={<EventApproveAndReject/>}/>

        <Route path='/pasteventapproveandreject/:id/:uuid' element={<PastEventApproveAndReject/>}/>
        
        {/* <Route path="/champdashboard" element={<ChampDashboard />} /> */}
        <Route path="/champEvent/:id" element={<ChampEvent />} />

        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <ScrollButton />
      { showNavbar && <Footer /> }

    </>
  );
};

export default AppRouter;