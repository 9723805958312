import arrow from "../../assets/images/page-navi-arrow.svg";
import { FaEye, FaRegCalendarAlt, FaStar } from "react-icons/fa";
import {
  FaLocationDot,
} from "react-icons/fa6";
import sponsorringus from "../../assets/images/sponsor logo 3.png";
import { IoCloseSharp } from "react-icons/io5";
import PackageCard from "../../components/EventDetail/PackageCard";
import linkedinImg from "../../assets/images/linkedin-logo.png";
import eventtwitterImg from "../../assets/images/ic-twitter.png";
import eventgoogleimg from "../../assets/images/event-google-icon.svg";
import eventinstaimg from "../../assets/images/ic-instagram-copy.png";
import eventfacebookimg from "../../assets/images/event-facebook-img.png";
import { useEffect, useState, useRef } from "react";
import {
  Event_detail,
  Reject_Msg_Send,
  get_Host_All_Event,
  get_Reject_Data,
} from "../../utils/eventdetail/eventdetail";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import YouTube from "react-youtube";
import Loader from "../../components/Loader/Loader";
import pdfimg from "../../assets/images/pdf.png";
import start from "../../assets/images/star2.svg";
import DocViewer from "@cyntler/react-doc-viewer";
import GotItModal from "../../components/EventDetail/GotItModal";
import ImageGlr from "../../components/EventDetail/ImageGallery";
import { isTokenValid } from "../../AuthbyToken";
import moment from "moment";
import { useSelector } from "react-redux";
import StarRating from "../../components/starRating/StarRating";
import ReactStars from "react-rating-stars-component";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import "swiper/css/grid";
import {
  Grid,
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { ReviewEventList } from "../../utils/review/Review";
import DateFormat from "../../components/Common/DateFormat";
import CreatePastEventGallery from "../../components/EventDetail/CreatePastEventGallery";
import dImage from "../../assets/images/default-image.jpg";
import RejectPopup from "../../components/Common/RejectPopup";
import { RiLiveFill, RiShieldCheckFill } from "react-icons/ri";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappIcon,
  WhatsappShareButton,
  EmailShareButton,
  EmailIcon,
} from "react-share";
import { FaShareAlt } from "react-icons/fa";
import CopyToClipboard from "react-copy-to-clipboard";
import { MdOutlineContentCopy, MdVerified } from "react-icons/md";
import { LuFileCheck } from "react-icons/lu";
import SponsoredReview from "../../components/Common/SponsoredReview";
import PartnerWithHostForm from "../../components/Common/EventDetail/PartnerWithHostForm";
import PartnerWithSponsorRingusForm from "../../components/Common/EventDetail/PartnerWithSponsorRingus";
// import peopleIcon from "../../assets/images/Guests Count.png"
import peopleIcon from "../../assets/images/Guests Count copy.png"
import maleIcon from "../../assets/images/Guests Gender Male.png"
import femaleIcon from "../../assets/images/Guests Gender female.png"
import otherIcon from "../../assets/images/Guest other.png"
import GuestAge from "../../assets/images/Guests Age.png"
import GuestMarital from "../../assets/images/Guests Marital Status.png"
import GuestLanguage from "../../assets/images/Guests Language.png";
import GuestInterest from "../../assets/images/Guests Interest.png";
import TimeCountDown from "../../components/Common/EventDetail/TimeCountDown";
import EventDateTimeTabs from "../../components/Common/EventDetail/EventDateTimeTabs";
import EventHostUi from "../../components/Common/EventDetail/EventHostUi";
import CancellationRefund from "../../components/Common/EventDetail/CancellationRefund";
import { IoIosShareAlt } from "react-icons/io";
import EventCoupons from "../../components/Common/EventDetail/EventCoupons";
import { TiArrowLeft } from "react-icons/ti";
import EventScheduleImageDocumentShow from "../../components/Common/EventDetail/EventScheduleImageDocumentShow";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { toast } from "react-toastify";
import PdfView from "../../components/Common/EventDetail/PdfView";
import EventMediaHub from "../../components/EventDetail/EventMediaHub";
import ReviewCards from "../../components/Common/EventDetail/ReviewCards";


const ExpressEventDetail = () => {
  const params = useParams();
  const id = params?.id;
  const isValid = isTokenValid();
  const navigate = useNavigate();

  const [event, setEvent] = useState({});
  const [userAllEvent, setUserAllEvent] = useState([])
  const [pdfFile, setPdfFile] = useState("");
  const [loading, setLoading] = useState(true);
  const [gotItValue, setGotItValue] = useState({});
  const [gotitModal, setItModal] = useState(false);
  const [reviewUser, setReviewUser] = useState([]);
  const [messages, setMessages] = useState([]);
  const [rejectPopup, setRejectPopup] = useState(false);
  const [pastEventGalleryMdl, setpastEventGalleryMdl] = useState(false);
  const [partnerWithHostMdl, setPartnerWithHostMdl] = useState(false);
  const [partnerWithSponsorRingusMdl, setPartnerWithSponsorRingusMdl] = useState(false);

  const profileinfo = useSelector((data) => data?.Profile_data?.ProfileData);
  const userId = profileinfo?.id;
  const location = useLocation();
  const label = location?.state?.type;
  const ConfigData = useSelector((state) => state?.config_data?.configData);
  const baseUrls = ConfigData?.base_urls;

  const eventEndDate = new Date(`${event?.end_date} ${event?.end_time}`);
  const currentDate = new Date();
  const isEventExpired = currentDate > eventEndDate;

  const eventStartDate = new Date(`${event?.start_date} ${event?.start_time}`);
  const isEventStarted = currentDate > eventStartDate;

  const currency = useSelector((val) => val?.CurrencyConvert);
  const [copied, setCopied] = useState(false);
  const [reviewMdl, setReviewMdl] = useState(true);

  const textToCopyRef = useRef(null);
  const [copyStatus, setCopyStatus] = useState('');
  const [isGreaterThanNow, setIsGreaterThanNow] = useState(false);

  const firstBannerImage = event?.event_banner?.[0];
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const images = event?.event_banner?.map(
    (item) => `${baseUrls?.event_banner_image_url}/${item?.filename}` || dImage
  );

  // console.log("pdfFile",pdfFile)
  const openLightbox = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  useEffect(() => {
    const start_date = event?.start_date;
    const start_time = event?.start_time;

    const startDateTime = new Date(`${start_date}T${start_time}:00`);
    const now = new Date();

    if (startDateTime > now) {
      setIsGreaterThanNow(true);
    } else {
      setIsGreaterThanNow(false);
    }
  }, [event?.start_date]);


  const evetdetail = async () => {
    try {
      const response = await Event_detail(id);
      setEvent(response);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const userReview = async () => {
    try {
      await ReviewEventList(id).then((response) =>
        setReviewUser(response?.data)
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    evetdetail();
    if (isValid) {
      userReview();
    }
  }, [id]);

  const hostAllEvent = async (Uid) => {
    try {
      await get_Host_All_Event(Uid)
        .then((response) =>
          setUserAllEvent(response)
        );
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (event?.user_id) {
      hostAllEvent(event?.user_id);
    }
  }, [event?.user_id])


  const opts = {
    height: "350",
    width: "640",
    playerVars: {
      autoplay: 1,
      mute: 1,
    },
  };

  const getitfunc = (val) => {
    setGotItValue(val);
  };

  const sumOfRatings = reviewUser?.reduce(
    (accumulator, currentValue) => accumulator + currentValue?.rating,
    0
  );
  const SubRating = sumOfRatings / reviewUser?.length;

  const getYouTubeVideoId = (videoLink) => {
    const regex =
      /(?:youtu\.be\/|youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/;
    const match = videoLink?.match(regex);
    if (match && match[1]) {
      return match[1];
    } else {
      console.error("Invalid YouTube URL");
      return null;
    }
  };

  const breakpoints = {
    300: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    500: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    1024: {
      slidesPerView: 4,
      spaceBetween: 20,
    },
    1200: {
      slidesPerView: 4,
      spaceBetween: 30,
    },
    1600: {
      slidesPerView: 4,
      spaceBetween: 30,
    },
  };

  const cardImageError = (event) => {
    event.target.src = dImage;
    event.target.onError = null;
  };

  const getRejected = async () => {
    try {
      const response = await get_Reject_Data(id);
      setMessages(response?.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (event && event.is_active == 0 && isValid) {
      getRejected();
    }
  }, [event]);

  // const title = "Check out this awesome website!";

  const PagePath = `${window.location.origin}${location.pathname}`;

  // ------------------

  const handleCopy = () => {
    const textToCopy = textToCopyRef.current.innerText;
    navigator.clipboard.writeText(textToCopy).then(() => {
      setCopyStatus('Coupon copied to clipboard!');
      setTimeout(() => {
        setCopyStatus('');
      }, 60000);
    }).catch((err) => {
      console.error('Failed to copy Coupon: ', err);
    });
  };


  // --------------------
  const renderGenderStat = (genderType, icon) => (
    <div className="">
      <div className="stat-guest-2">
        <img src={icon} className="icon-guest" />
        <div className="text-guest">
          <span className="label-guest mt-4">{genderType}</span>
        </div>
      </div>
    </div>
  );

  const onButtonClick = () => {
    window.print();
  };

  const PartnerWithHost = () => {
    if (isValid) {
      setPartnerWithHostMdl(!partnerWithHostMdl)
    } else {
      toast.warn("Please login first", {
        position: "top-center",
        autoClose: 3000
      })
    }
  }

  const PartnerWithSponsor = () => {
    if (isValid) {
      setPartnerWithSponsorRingusMdl(!partnerWithSponsorRingusMdl)
    } else {
      toast.warn("Please login first", {
        position: "top-center",
        autoClose: 3000
      })
    }
  }

  return (
    <>
      {
        pdfFile !== "" && <PdfView baseUrls={baseUrls} pdfFile={pdfFile} setPdfFile={setPdfFile} />
      }

      {partnerWithHostMdl && (
        <PartnerWithHostForm setPartnerWithHostMdl={setPartnerWithHostMdl} Event_id={id} />
      )}

      {partnerWithSponsorRingusMdl && (
        <PartnerWithSponsorRingusForm
          setPartnerWithSponsorRingusMdl={setPartnerWithSponsorRingusMdl} Event_id={id}
        />
      )}

      {pastEventGalleryMdl && (
        <CreatePastEventGallery
          eventId={event?.id}
          setMdl={setpastEventGalleryMdl} />
      )}

      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="position-relative">
            <div className="handle-print-pdf">
              {event?.event_banner?.length > 0 && (
                <Swiper
                  spaceBetween={50}
                  slidesPerView={1}
                  modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
                  navigation={event?.event_banner?.length > 1}
                  loop={true}
                  autoplay={{
                    delay: ConfigData?.banner_speed
                      ? ConfigData?.banner_speed
                      : 4000,
                    disableOnInteraction: false,
                  }}
                  effect="fade"
                  speed={1000}
                // onSlideChange={() => console.log('slide change')}
                // onSwiper={(swiper) => console.log(swiper)}
                >
                  {event?.event_banner?.map((item, index) => {
                    return (
                      <div key={item.id}>
                        <SwiperSlide key={item.id}>
                          <div
                            className="detail-banner position-relative"
                            style={{
                              backgroundImage: item?.filename
                                ? `url(${baseUrls?.event_banner_image_url}/${item?.filename})`
                                : `url(${dImage})`,
                              cursor: "pointer"
                            }}
                            onClick={() => openLightbox(index)} // Open gallery on click
                          ></div>
                        </SwiperSlide>
                      </div>
                    );
                  })}
                </Swiper>
              )}
              {isOpen && (
                <Lightbox
                  mainSrc={images[photoIndex]}
                  nextSrc={images[(photoIndex + 1) % images?.length]}
                  prevSrc={images[(photoIndex + images?.length - 1) % images?.length]}
                  onCloseRequest={() => setIsOpen(false)}
                  onMovePrevRequest={() =>
                    setPhotoIndex((photoIndex + images?.length - 1) % images?.length)
                  }
                  onMoveNextRequest={() =>
                    setPhotoIndex((photoIndex + 1) % images?.length)
                  }
                />
              )}
            </div>


            <div className={
              event?.event_banner?.length <= 0
                ? "detail-banner position-relative"
                : ""}
              style={{
                backgroundImage:
                  event?.event_banner?.length <= 0
                    ? event?.banner
                      ? `url(${baseUrls?.event_banner_old_image_url}/${event?.banner}')`
                      : `url(${dImage})`
                    : "",
              }}>

              <div>
                <div className="banner-top-section">
                  <div className="pt-4 d-flex justify-content-between align-items-center container-fluid">
                    <div className="detail-page-info">
                      <Link
                        to={label == "Events"
                          ? "/events/all"
                          : label == "My Events"
                            ? "/myevents/all"
                            : label == "MyOrder"
                              ? "/myorders"
                              : label == "ManageRequest"
                                ? "/managerequest"
                                : "/"}>
                        <p className="navigate-p mb-0">
                          {label == null ? "Home" : label}
                        </p>
                      </Link>
                      <img src={arrow} alt="arw" />
                      <p className="navigate-p text-light">Event Details</p>
                    </div>

                    <div className="set-center">
                      {event?.status == "Sample" ? (
                        <div className="sample-head-div d-none d-lg-block">
                          <p className="sample-head-text ">
                            “****SAMPLE - Experience our event platform’s
                            capabilities with this sample event****”
                          </p>
                        </div>
                      ) : (
                        <div className="sample-head-div d-none d-lg-block">
                          <p className="sample-head-text ">
                            “****LIVE - Open for Sponsorships Now!****”
                          </p>
                        </div>
                      )}
                    </div>

                    {/* <div className="attendees-btn">
                  <p className="Attendees-text">
                    Attendees :
                    <span className="text-style-1 px-1">
                      {event?.guests_count_min && event?.guests_count_max ?
                        `${event?.guests_count_min} - ${event?.guests_count_max}`
                        : "-"}
                    </span>
                  </p>
                  {event?.no_of_participants && (
                    <p className="Attendees-text">
                      Attended :
                      <span className="text-style-1 px-1">
                        {event?.no_of_participants}
                      </span>
                    </p>
                  )}
                  </div> */}

                    <button
                      className="go-back-btn d-flex align-items-center"
                      onClick={() =>
                        navigate(label == "Events"
                          ? "/events/all"
                          : label == "My Events"
                            ? "/myevents/all"
                            : label == "MyOrder"
                              ? "/myorders"
                              : label == "ManageRequest"
                                ? "/managerequest"
                                : "/")}>
                      <TiArrowLeft size={20} /> Go Back
                    </button>
                  </div>



                  <div className="d-flex justify-content-center container-fluid mt-2">
                    {event?.status == "Sample" ? (
                      <div className="sample-head-div d-lg-none">
                        <p className="sample-head-text text-center">
                          “****SAMPLE - Experience our event platform’s capabilities with this sample event****”
                        </p>
                      </div>
                    ) : (
                      <div className="sample-head-div d-lg-none">
                        <p className="sample-head-text text-center">
                          “****LIVE - Open for Sponsorships Now!****”
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="NoOfView-div d-none d-md-block">
              <div className="attendees-btn">
                <p className="Attendees-text">
                  No.of Views :
                  <span className="text-style-1 px-1">
                    {event?.views}
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className="print-only-banner">
            {firstBannerImage && (
              <img className="single-banner" src={`${baseUrls?.event_banner_image_url}/${firstBannerImage?.filename}`} alt="Banner Image" />
            )}
          </div>

          <div className="detail-page-container mb-3">
            <div className="container-sm-fluid container-xl pb-5">
              <div className="row">
                <div className="col-lg-8 col-12 ">
                  <div className="row detailevent-card">
                    <div className="col-xl-4 col-md-5 col-sm-4 col-12 d-flex justify-content-center d-md-block ">
                      <div className="col-7 col-sm-12">
                        <div className="detail-img-div">
                          <div className="position-relative">
                            <img
                              className="cardimg1"
                              src={`${baseUrls?.logo_image_url}/${event?.logo}`}
                              onError={cardImageError}
                              alt="img"
                            />
                            {event?.event_mode ? (
                              <div className="event-mode-div">
                                {event?.event_mode?.replace("_", " ")}
                              </div>
                            ) : (
                              ""
                            )}
                            {event?.status ? (
                              event?.status == "Live" ? (
                                <div className="event-live-status-div">
                                  <RiLiveFill /> {event?.status}
                                </div>
                              ) : (
                                <div className="detail-event-status-div">
                                  {event?.status}
                                </div>
                              )
                            ) : (
                              ""
                            )}
                            {event?.event_type?.name && (
                              <div className="eventDetail-type-div">
                                {event?.event_type?.name}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="detail-text-div col-xl-8 col-md-7 col-sm-8 position-relative">
                      {/* <div className="container-fluid"> */}
                      <div className=" d-flex justify-content-between">
                        <div className="attendees-btn attendees-div d-none d-md-block">
                          <p className="Attendees-text">
                            Attendees :
                            <span className="text-style-1 px-1">
                              {event?.guests_count_min && event?.guests_count_max
                                ? `${event?.guests_count_min} - ${event?.guests_count_max}` : "-"}
                            </span>
                          </p>
                          {event?.no_of_participants && (
                            <p className="Attendees-text">
                              Attended :
                              <span className="text-style-1 px-1">
                                {event?.no_of_participants}
                              </span>
                            </p>
                          )}
                        </div>
                      </div>
                      {/*</div>*/}
                      <h2 className="event-pro-head m-0">{event?.event_title}</h2>

                      <div className="detail-location-section mt-2">
                        <div className="d-flex align-items-center gap-1">
                          <FaRegCalendarAlt size={15} />
                          <div className="d-flex flex-xl-row flex-column gap-xl-2">
                            <div className="d-flex gap-1">
                              <p className="detail-p-text mb-0">
                                <DateFormat date={event?.start_date} />
                              </p>
                              {event?.start_time == null ? (
                                ""
                              ) : (
                                <p className="detail-p-text mb-0">
                                  {moment(event?.start_time, "HH:mm").format("hh:mm A")}
                                </p>
                              )}
                            </div>

                            <p className="detail-p-text d-none d-xl-flex mb-0">To</p>

                            <div className="d-flex gap-1">
                              <p className="detail-p-text mb-0">
                                <DateFormat date={event?.end_date} />
                              </p>
                              {event?.end_time == null ? (
                                ""
                              ) : (
                                <p className="detail-p-text mb-0">
                                  {moment(event?.end_time, "HH:mm").format("hh:mm A")}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="detail-location-section">
                        {event?.address ? (
                          <div className="d-flex align-items-center gap-1 py-0" title={`${event?.pin_code}, ${event?.address}, ${event?.city?.city_name}, ${event?.state?.short_name}, ${event?.country?.short_name}`}>
                            <div> <FaLocationDot color="#f43254" size={15} /> </div>
                            <p className="detail-p-text gap-1 text-capitalize">{event?.pin_code}, {event?.address}, {event?.city?.city_name}, {event?.state?.short_name}, {event?.country?.short_name}</p>
                          </div>
                        ) : (
                          <div className="d-flex align-items-center gap-1 py-0" title={`${event?.pin_code}, ${event?.city?.city_name}, ${event?.state?.short_name}, ${event?.country?.short_name}`}>
                            <div> <FaLocationDot color="#f43254" size={15} /></div>
                            <p className="detail-p-text gap-1 text-capitalize">{event?.pin_code}, {event?.city?.city_name}, {event?.state?.short_name}, {event?.country?.short_name}</p>
                          </div>
                        )}
                      </div>

                      {event?.online_mode &&
                        <div className="d-flex align-items-center detail-location-section gap-2">
                          <div>
                            <RiLiveFill color="#f43254" size={15} />
                          </div>
                          <p className="detail-p-text gap-1">{event?.online_mode?.mode_name}</p>
                        </div>
                      }

                      <div className="">
                        {(event?.published_date == null &&
                          event?.published_end_date == null) ||
                          (event?.published_date == "0000-00-00" &&
                            event?.published_end_date == "0000-00-00") ? (
                          ""
                        ) : (
                          <div className="detail-location-section d-flex flex-column flex-xl-row align-items-xl-center gap-2 ">
                            {/* <FaRegCalendarAlt size={18} /> */}
                            <div className="">
                              <p className="mb-0 detail-location-section-head ">Publish Date:</p>
                              <div className="d-flex align-items-center gap-1">
                                <p className="font-weight-light detail-p-text  mb-0">
                                  <DateFormat date={event?.published_date} />
                                  {/* {moment(event?.published_start_time, "HH:mm").format("hh:mm A")} */}
                                </p>
                                {
                                  event?.published_start_time &&
                                  <p className="detail-p-text mb-0">
                                    {moment(event?.published_start_time, "HH:mm").format("hh:mm A")}
                                  </p>
                                }
                              </div>
                            </div>

                            <div className="ms-xl-3">
                              {/* <FaRegCalendarAlt size={18} /> */}
                              <p className="mb-0 detail-location-section-head">
                                Sponsorship Cutoff Date:
                              </p>
                              <div className="d-flex align-items-center gap-1">
                                <p className="font-weight-light detail-p-text mb-0">
                                  <DateFormat date={event?.published_end_date} />
                                  {/* {moment(event?.published_end_time,"HH:mm").format("hh:mm A")} */}
                                </p>
                                {
                                  event?.published_end_time &&
                                  <p className="detail-p-text mb-0">
                                    {moment(event?.published_end_time, "HH:mm").format("hh:mm A")}
                                  </p>
                                }
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      {isValid &&
                        <div className="d-flex gap-4 mb-3">
                          <div>
                            <button onClick={onButtonClick} className="clear-filter-button">
                              Print / Download PDF
                            </button>
                          </div>

                          <div>
                            {(event?.event_status == "Past_Event" && event?.users?.id == userId) && (
                              <button
                                className="clear-filter-button"
                                onClick={() => setpastEventGalleryMdl(true)} >
                                Upload Past Event Images
                              </button>
                            )}
                          </div>
                        </div>
                      }

                      {!reviewUser?.length <= 0 && (
                        <div className="d-flex align-items-center gap-2">
                          <div>
                            <FaStar color="#f43254" size={18} />
                          </div>
                          <p className="detail-p-text">
                            {SubRating.toFixed(1)} / 5
                          </p>
                        </div>
                      )}


                      <div className=" ">
                        <div className="d-block d-md-none">
                          <div className="d-flex gap-1">
                            <p className="mb-0 detail-location-section-head ">
                              Attendees :
                            </p>
                            <p className="fw-bold ">
                              <span className="text-style-1 px-1">
                                {event?.guests_count_min && event?.guests_count_max
                                  ? `${event?.guests_count_min} - ${event?.guests_count_max}` : "-"}
                              </span>
                            </p>
                          </div>
                          {event?.no_of_participants && (
                            <div className="d-flex gap-1">
                              <p className="mb-0 detail-location-section-head ">
                                Attended :
                              </p>
                              <p className="">
                                <span className="text-style-1 px-1">
                                  {event?.no_of_participants}
                                </span>
                              </p>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="d-block d-md-none">
                        <div className="d-flex gap-1">
                          <p className="mb-0 detail-location-section-head ">
                            No.of Views :
                          </p>
                          <p className="Attendees-text">
                            <span className="text-style-1 px-1">
                              {event?.views}
                            </span>
                          </p>
                        </div>
                      </div>


                      <div className="hide-social-icon">
                        <div>
                          <FaShareAlt size={15} />
                        </div>

                        <div className="d-flex flex-column">
                          <CopyToClipboard
                            text={PagePath}
                            onCopy={() => setCopied(true)} >
                            <button className={copied ? "copied-link-btn" : "copy-link-btn"} >
                              {copied ? (
                                <LuFileCheck
                                  size={14}
                                  color="white"
                                  title="Copied"
                                />
                              ) : (
                                <MdOutlineContentCopy
                                  size={14}
                                  color="white"
                                  title="Copy Link"
                                />
                              )}
                            </button>
                          </CopyToClipboard>
                          {/* {copied && <span className="copy-path-text">Copied!</span>} */}
                        </div>

                        <EmailShareButton url={PagePath} body={PagePath}>
                          <EmailIcon size={25} round />
                        </EmailShareButton>

                        <WhatsappShareButton url={PagePath}>
                          <WhatsappIcon size={25} round />
                        </WhatsappShareButton>

                        <FacebookShareButton url={PagePath}>
                          <FacebookIcon size={25} round />
                        </FacebookShareButton>

                        <TwitterShareButton url={PagePath}>
                          <TwitterIcon size={25} round />
                        </TwitterShareButton>

                        <LinkedinShareButton url={PagePath}>
                          <LinkedinIcon size={25} round />
                        </LinkedinShareButton>
                      </div>
                    </div>
                  </div>

                  <div className="mt-3 row">
                    <div className="col-12 col-sm-5 col-xxl-6">
                      <div className="hosted-by-div">
                        <b className="label-guest">Event Organizer</b>
                        <div className="d-flex align-items-center gap-3 mt-2">
                          <div className="position-relative">
                            <img
                              className="event-detail-profile"
                              src={
                                event?.users?.image_type == "browse"
                                  ? `${baseUrls?.customer_image_url}/${event?.users?.image}`
                                  : `${baseUrls?.avatar_image_url}/${event?.users?.predefine_image}`}
                              alt="profile"
                            />
                            <button className="event-detail-shield-btn">
                              <RiShieldCheckFill color="white" size={16} />
                            </button>
                          </div>
                          <div className="d-flex align-items-center gap-2 mt-2">
                            <div>
                              <p className="detail-p-text p-0 m-0">
                                {event?.users?.f_name}{" "}
                                {event?.users?.is_prime_user ? (
                                  <MdVerified color="#039be5" />
                                ) : (
                                  ""
                                )}
                              </p>
                              <ReactStars
                                count={5}
                                size={15}
                                value={event?.rating}
                                edit={false}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {
                      !isEventStarted &&
                      <div className="col-12 mt-3 mt-sm-0 col-sm-7 col-xxl-6">
                        <div className=" hosted-by-div">
                          <b className="label-guest"> Event Starts In </b>
                          <div className="mt-2">
                            <TimeCountDown event={event} />
                          </div>
                        </div>
                      </div>
                    }
                  </div>

                  {/* <div className="mt-3 row">
                    {event?.languages?.length > 0 &&
                      <div className="col-12 col-sm-5 col-xxl-6">
                        <div className="hosted-by-div">
                          <div className="d-flex gap-2 align-items-center">
                            <img src={GuestLanguage} style={{ height: '50px', width: '50px', objectFit: 'contain' }} alt="Guest language" />
                            <b className="label-guest">Language</b>
                          </div>
                          <div className="mt-2">
                            {event?.languages?.length > 0 && (
                              <div className="intrest-detail-page mt-1">
                                {event?.languages?.map((item, index) => {
                                  return (
                                    <div className="d-flex language-show" key={index}>
                                      {item?.languages}
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>}

                    {event?.tags?.length > 0 &&
                      <div className="col-12 mt-3 mt-sm-0 col-sm-7 col-xxl-6">
                        <div className="hosted-by-div">
                          <div className="d-flex gap-2 align-items-center">
                            <img src={GuestInterest} style={{ height: '50px', width: '50px', objectFit: 'contain' }} alt="Guest intrest" />
                            <b className="label-guest">Interest</b>
                          </div>
                          <div className="mt-2">
                            <div className="intrest-detail-page mt-1">
                              {event?.tags?.length > 0 &&
                                event?.tags?.map((item) => {
                                  return (
                                    <Link
                                      className="d-flex"
                                      to={`/events/${item?.interest_id}`}
                                      state={{ type: "Interest" }}
                                      key={item?.id}>
                                      <p className="IntrestP">
                                        #{item?.user_interests?.name}
                                      </p>
                                    </Link>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      </div>}
                  </div> */}

                  <div className="row mt-3 mb-2">
                    <div className="col-12 mt-3 mt-sm-0 col-sm-5 col-xxl-6">
                      <div className="hosted-by-div">
                        <div className="d-flex gap-2 align-items-center">
                          {/* <img src={EventbudgetImg} style={{ height: '50px', width: '50px', objectFit: 'contain' }} alt="Guest Age" /> */}
                          <b className="label-guest">Event Budget</b>
                        </div>
                        <div className="mt-2">
                          <p className="detail-p-text">{event?.event_budget?.budget}</p>
                        </div>
                      </div>
                    </div>

                  </div>


                  {event?.event_sponsor_details?.length > 0 && (
                    <div className="social-media-div">
                      <div className="d-flex align-items-center gap-2">
                        <h2 className="social-m-head m-0">Confirmed Sponsors</h2>
                        <hr className="w-100 d-none d-xl-flex" />
                      </div>

                      <div className="confirmed-sponsors-normal-mode">
                        <div className="social-media-div parter-banner d-flex justify-content-center">
                          <Swiper
                            spaceBetween={0}
                            breakpoints={breakpoints}
                            modules={[Autoplay]}
                            effect="fade"
                            speed={800}
                            className="container d-flex justify-content-center p-0"
                          >
                            {event?.event_sponsor_details?.map((item) => {
                              return (
                                <SwiperSlide
                                  className="parter_slide"
                                  key={item?.id}>
                                  <div>
                                    <img
                                      className="partnerposter-img"
                                      loading="lazy"
                                      src={`${baseUrls?.sponsors_brand_logo}/${item?.sponsors_brand_logo}`}
                                      alt="Sponsors"
                                    />
                                    <p className="text-center mt-1">
                                      {item?.sponsor_name}
                                    </p>
                                  </div>
                                </SwiperSlide>
                              );
                            })}
                          </Swiper>
                        </div>
                      </div>

                      <div className="confirmed-sponsors-print-mode">
                        {event?.event_sponsor_details?.map((item) => {
                          return (
                            <div key={item?.id}>
                              <img
                                loading="lazy"
                                src={`${baseUrls?.sponsors_brand_logo}/${item?.sponsors_brand_logo}`}
                                alt="Sponsors"
                              />
                              <p className="mt-1">
                                {item?.sponsor_name}
                              </p>
                            </div>
                          );
                        })}
                      </div>


                    </div>
                  )}

                  {event?.why_partner_with_us && (
                    <div className="social-media-div">
                      <div className="d-flex align-items-center gap-2">
                        <h2 className="social-m-head m-0">Why Partner With Us</h2>
                        <hr className="w-100 d-none d-xl-flex" />
                      </div>

                      <div>
                        <p className="t-paragraph1"
                          dangerouslySetInnerHTML={{ __html: event?.why_partner_with_us }}
                        ></p>
                      </div>
                    </div>
                  )}

                  {event?.event_theme && (
                    <div className="social-media-div">
                      <div className="d-flex align-items-center gap-2">
                        <h2 className="social-m-head m-0">Event Theme</h2>
                        <hr className="w-100 d-none d-xl-flex" />
                      </div>

                      <div>
                        <p
                          className="t-paragraph1"
                          dangerouslySetInnerHTML={{ __html: event?.event_theme }}
                        ></p>
                      </div>
                    </div>
                  )}

                  <div className="social-media-div">
                    <div className="d-flex align-items-center gap-2">
                      <h2 className="social-m-head m-0">Event Description</h2>
                      <hr className="w-100 d-none d-xl-flex" />
                    </div>

                    <div>
                      <p
                        className="t-paragraph1"
                        dangerouslySetInnerHTML={{ __html: event?.description }}
                      ></p>
                    </div>
                  </div>

                  {event?.guests_profile_summary && (
                    <div className="social-media-div">
                      <div className="d-flex align-items-center gap-2">
                        <h2 className="social-m-head m-0">Event Guests</h2>
                        <hr className="w-100 d-none d-xl-flex" />
                      </div>
                      <div>
                        <p
                          className="t-paragraph1"
                          dangerouslySetInnerHTML={{
                            __html: event?.guests_profile_summary,
                          }}
                        ></p>
                      </div>
                    </div>
                  )}

                  <div className="attendee-stats-guest my-3">
                    <div className="stat-guest">
                      <img src={peopleIcon} className="icon-guest" />
                      <div className="text-guest">
                        <span className="count-guest"> {event?.guests_count_min && event?.guests_count_max
                          ? `${event?.guests_count_min} - ${event?.guests_count_max}`
                          : "-"}</span>
                        <span className="label-guest">Attendees expected</span>
                      </div>
                    </div>

                    <div className="guest-statistics">
                      {event?.guest_gender?.map((guest) => {
                        const genderType = guest?.guest_gender.gender_type;
                        if (genderType == 'Male') {
                          return renderGenderStat('Male', maleIcon);
                        } else if (genderType == 'Female') {
                          return renderGenderStat('Female', femaleIcon);
                        } else if (genderType == 'Genderqueer') {
                          return renderGenderStat('Genderqueer', otherIcon);
                        }
                        return null;
                      })}
                    </div>
                  </div>

                  <div className="row mt-3">
                    {event?.languages?.length > 0 &&
                      <div className="col-12 mt-3 mt-sm-0 col-sm-6 col-xxl-6">
                        <div className="">
                          <div className="d-flex gap-2 align-items-center">
                            <img src={GuestLanguage} style={{ height: '50px', width: '50px', objectFit: 'contain' }} alt="Guest Age" />
                            <b className="label-guest">Guest Language</b>
                          </div>
                          <div className="mt-0">
                            <div className="display-event-curve-div mt-1">
                              {event?.languages?.map((item) => {
                                return (
                                  <div className="display-event-curve-show" key={item?.id}>
                                    {item?.languages}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    }

                    {event?.tags?.length > 0 &&
                      <div className="col-12 mt-3 mt-sm-0 col-sm-6 col-xxl-6">
                        <div className="">
                          <div className="d-flex gap-2 align-items-center">
                            <img src={GuestInterest} style={{ height: '50px', width: '50px', objectFit: 'contain' }} alt="Guest Age" />
                            <b className="label-guest">Guest Interest</b>
                          </div>
                          <div className="mt-0">
                            <div className="display-event-curve-div mt-1">
                              {event?.tags?.map((item) => {
                                return (
                                  <div className="display-event-curve-show" key={item?.id}>
                                    #{item?.user_interests?.name}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </div>

                  <div className="row mt-3">
                    {event?.guest_age?.length > 0 &&
                      <div className="col-12 mt-3 mt-sm-0 col-sm-6 col-xxl-6">
                        <div className="">
                          <div className="d-flex gap-2 align-items-center">
                            <img src={GuestAge} style={{ height: '50px', width: '50px', objectFit: 'contain' }} alt="Guest Age" />
                            <b className="label-guest">Guest Age</b>
                          </div>
                          <div className="mt-0">
                            <div className="display-event-curve-div mt-1">
                              {event?.guest_age?.map((item) => {
                                return (
                                  <div className="display-event-curve-show" key={item?.id}>
                                    {item?.guest_age?.age_group}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    }

                    {event?.guest_marital_status?.length > 0 &&
                      <div className="col-12 mt-3 mt-sm-0 col-sm-6 col-xxl-6">
                        <div className="">
                          <div className="d-flex gap-2 align-items-center">
                            <img src={GuestMarital} style={{ height: '50px', width: '50px', objectFit: 'contain' }} alt="Guest Marital" />
                            <b className="label-guest">Guest Marital status</b>
                          </div>
                          <div className="mt-0">
                            <div className="display-event-curve-div mt-1">
                              {event?.guest_marital_status?.map((item) => {
                                return (
                                  <div className="display-event-curve-show" key={item?.id}>
                                    {item?.merital_status?.marital_status}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </div>

                  {(event?.event_gellary?.length > 0 ||
                    event?.past_event_gellary?.length > 0 ||
                    event?.video_links?.length > 0) && (
                      <div className="social-media-div">
                        <div className="d-flex align-items-center gap-2">
                          <h2 className="social-m-head m-0">Event Media Hub</h2>
                          <hr className="w-100 d-none d-xl-flex" />
                        </div>

                        <EventMediaHub
                          event={event}
                          baseUrls={baseUrls}
                          pdfFile={pdfFile}
                          setPdfFile={setPdfFile}
                        />


                        {event?.video_links?.length > 0 && (
                          <div className="social-media-div video-div">
                            <div className="d-flex align-items-center gap-2">
                              <h2 className="social-child-heading m-0">Video</h2>
                              {/* <hr className="w-100 d-none d-xl-flex" /> */}
                            </div>
                            <div className="d-flex flex-column flex-xl-row gap-4 mt-3">
                              <div className="row">
                                {event?.video_links?.map((video, index) => (
                                  <div className="col-md-6 " key={index}>
                                    <iframe
                                      width={"auto"}
                                      height="260"
                                      src={`https://www.youtube.com/embed/${getYouTubeVideoId(
                                        video?.video_link
                                      )}?autoplay=1`}
                                      title={`Video ${index + 1}`}
                                      frameBorder="0"
                                      allowFullScreen
                                      muted
                                      style={{ borderRadius: '8px' }}
                                    ></iframe>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                  <div className="social-media-div">
                    <div className="d-flex align-items-center gap-2">
                      <h2 className="social-m-head m-0">Event Host</h2>
                      <hr className="w-100 d-none d-xl-flex mt-3" />
                    </div>
                    <EventHostUi userAllEvent={userAllEvent} event={event} id={id} />
                  </div>

                  {userId == event?.user_id &&
                    event?.event_status == "Rejected" && (
                      <div className="social-media-div">
                        <div className="d-flex align-items-center gap-2">
                          <h2 className="social-m-head m-0">Rejected</h2>
                          <hr className="w-100 d-none d-xl-flex" />
                        </div>
                        <div className="chat-box mt-3">
                          <div className="message-section">
                            <div className="message-container">
                              <div className="chat-section-div bot pb-0">
                                {event?.general_reason == null ? (
                                  ""
                                ) : (
                                  <div>
                                    <h5 className="chat-h5-head">General</h5>
                                    <div className="message">
                                      {event?.general_reason}
                                    </div>
                                  </div>
                                )}

                                {event?.basic_info_reason == null ? (
                                  ""
                                ) : (
                                  <div>
                                    <h5 className="chat-h5-head">
                                      Basic Information
                                    </h5>
                                    <div className="message">
                                      {event?.basic_info_reason}
                                    </div>
                                  </div>
                                )}

                                {event?.package_reason == null ? (
                                  ""
                                ) : (
                                  <div>
                                    <h5 className="chat-h5-head">Package</h5>
                                    <div className="message">
                                      {event?.package_reason}
                                    </div>
                                  </div>
                                )}

                                {event?.coupon_reason == null ? (
                                  ""
                                ) : (
                                  <div>
                                    <h5 className="chat-h5-head">Coupon</h5>
                                    <div className="message">
                                      {event?.coupon_reason}
                                    </div>
                                  </div>
                                )}

                                {event?.pitch_reason == null ? (
                                  ""
                                ) : (
                                  <div>
                                    <h5 className="chat-h5-head">Pitch</h5>
                                    <div className="message">
                                      {event?.pitch_reason}
                                    </div>
                                  </div>
                                )}

                                {event?.organizer_reason == null ? (
                                  ""
                                ) : (
                                  <div>
                                    <h5 className="chat-h5-head">Organizer</h5>
                                    <div className="message">
                                      {event?.organizer_reason}
                                    </div>
                                  </div>
                                )}
                              </div>

                              {messages?.map((message, index) => {
                                // console.log("message", message)
                                return (
                                  <>
                                    <div
                                      className={`chat-section-div ${message?.sender_id == userId
                                        ? "user" : "bot"
                                        }`}>
                                      {message?.general_reason == null ? (
                                        ""
                                      ) : (
                                        <div>
                                          <h5 className="chat-h5-head">
                                            General
                                          </h5>
                                          {/* <i className='reject-p p-0 m-0'>{event?.general_reason}</i> */}
                                          <div key={index} className="message">
                                            {message?.general_reason}
                                          </div>
                                        </div>
                                      )}

                                      {message?.event_essentials_reason ==
                                        null ? (
                                        ""
                                      ) : (
                                        <div>
                                          <h5 className="chat-h5-head">
                                            Essentials
                                          </h5>
                                          {/* <i className='reject-p p-0 m-0'>{event?.event_essentials_reason}</i> */}
                                          <div key={index} className="message">
                                            {message?.event_essentials_reason}
                                          </div>
                                        </div>
                                      )}

                                      {message?.event_guests_reason == null ? (
                                        ""
                                      ) : (
                                        <div>
                                          <h5 className="chat-h5-head">Guests</h5>
                                          {/* <i className='reject-p p-0 m-0'>{event?.event_guests_reason}</i> */}
                                          <div key={index} className="message">
                                            {message?.event_guests_reason}
                                          </div>
                                        </div>
                                      )}

                                      {message?.event_pitch_reason == null ? (
                                        ""
                                      ) : (
                                        <div>
                                          <h5 className="chat-h5-head">Pitch</h5>
                                          {/* <i className='reject-p p-0 m-0'>{event?.event_pitch_reason}</i> */}
                                          <div key={index} className="message">
                                            {message?.event_pitch_reason}
                                          </div>
                                        </div>
                                      )}

                                      {message?.event_host_reason == null ? (
                                        ""
                                      ) : (
                                        <div>
                                          <h5 className="chat-h5-head">Host</h5>
                                          {/* <i className='reject-p p-0 m-0'>{event?.event_host_reason}</i> */}
                                          <div key={index} className="message">
                                            {message?.event_host_reason}
                                          </div>
                                        </div>
                                      )}

                                      {message?.event_partnerships_reason ==
                                        null ? (
                                        ""
                                      ) : (
                                        <div>
                                          <h5 className="chat-h5-head">
                                            Partnerships
                                          </h5>
                                          {/* <i className='reject-p p-0 m-0'>{event?.event_partnerships_reason}</i> */}
                                          <div key={index} className="message">
                                            {message?.event_partnerships_reason}
                                          </div>
                                        </div>
                                      )}

                                      {message?.sponsor_packages_reason ==
                                        null ? (
                                        ""
                                      ) : (
                                        <div>
                                          <h5 className="chat-h5-head">
                                            Packages
                                          </h5>
                                          {/* <i className='reject-p p-0 m-0'>{event?.sponsor_packages_reason}</i> */}
                                          <div key={index} className="message">
                                            {message?.sponsor_packages_reason}
                                          </div>
                                        </div>
                                      )}

                                      {message?.event_coupons_reason == null ? (
                                        ""
                                      ) : (
                                        <div>
                                          <h5 className="chat-h5-head">
                                            Coupons
                                          </h5>
                                          {/* <i className='reject-p p-0 m-0'>{event?.event_coupons_reason}</i> */}
                                          <div key={index} className="message">
                                            {message?.event_coupons_reason}
                                          </div>
                                        </div>
                                      )}

                                      {message?.event_media_hub_reason == null ? (
                                        ""
                                      ) : (
                                        <div>
                                          <h5 className="chat-h5-head">
                                            Media Hub
                                          </h5>
                                          {/* <i className='reject-p p-0 m-0'>{event?.event_media_hub_reason}</i> */}
                                          <div key={index} className="message">
                                            {message?.event_media_hub_reason}
                                          </div>
                                        </div>
                                      )}

                                      {message?.event_social_hub == null ? (
                                        ""
                                      ) : (
                                        <div>
                                          <h5 className="chat-h5-head">
                                            Social Hub
                                          </h5>
                                          {/* <i className='reject-p p-0 m-0'>{event?.event_social_hub}</i> */}
                                          <div key={index} className="message">
                                            {message?.event_social_hub}
                                          </div>
                                        </div>
                                      )}

                                      {message?.event_cancellation_and_refund_reason ==
                                        null ? (
                                        ""
                                      ) : (
                                        <div>
                                          <h5 className="chat-h5-head">
                                            Cancellation And Refund
                                          </h5>
                                          {/* <i className='reject-p p-0 m-0'>{event?.event_cancellation_and_refund_reason}</i> */}
                                          <div key={index} className="message">
                                            {
                                              message?.event_cancellation_and_refund_reason
                                            }
                                          </div>
                                        </div>
                                      )}

                                      {message?.event_references_reason ==
                                        null ? (
                                        ""
                                      ) : (
                                        <div>
                                          <h5 className="chat-h5-head">
                                            References
                                          </h5>
                                          {/* <i className='reject-p p-0 m-0'>{event?.event_references_reason}</i> */}
                                          <div key={index} className="message">
                                            {message?.event_references_reason}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </div>
                          <div className="input-container d-flex justify-content-end">
                            <button onClick={() => setRejectPopup(!rejectPopup)}>
                              Reply
                            </button>
                          </div>
                        </div>
                      </div>
                    )}

                  {(event?.instagram ||
                    event?.facebook ||
                    event?.linkedin ||
                    event?.twitter) &&
                    <div className="social-media-div d-none d-xl-block">
                      <div className="d-flex align-items-center gap-2">
                        <h2 className="social-m-head">Social Media</h2>
                        <hr className="w-100 d-none d-xl-flex" />
                      </div>
                      <div className="d-flex justify-content-center">
                        <div className="social-back-img mt-4">
                          <div className="d-flex justify-content-between mt-3">
                            <div className=" wow animate__animated animate__slower animate__bounceIn animate__infinite infinite">
                              {event?.instagram ? (
                                <ul class="example-2">
                                  <li class="icon-content">
                                    <Link
                                      to={event?.instagram}
                                      aria-label="Instagram"
                                      data-social="instagram">
                                      <div class="filled"></div>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-instagram"
                                        viewBox="0 0 16 16">
                                        <path
                                          d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"
                                          fill="currentColor"
                                        ></path>
                                      </svg>
                                    </Link>
                                    <div class="tooltip">Instagram</div>
                                  </li>
                                </ul>
                              ) : (
                                // <Link to={event?.instagram}>
                                //   <img src={eventinstaimg} alt="google" />
                                // </Link>
                                <div className="google-div">
                                  <img
                                    className="disable-link-img"
                                    src={eventinstaimg}
                                    alt="google"
                                  />
                                </div>
                              )}
                            </div>

                            <div className=" wow animate__animated animate__slower animate__bounceIn animate__infinite infinite">
                              {event?.facebook ? (
                                <ul class="example-2">
                                  <div className="detail-social-div">
                                    <li class="icon-content">
                                      <Link
                                        to={event.facebook}
                                        aria-label="Facebook"
                                        data-social="facebook">
                                        <div class="filled"></div>
                                        <svg
                                          viewBox="0 0 320 512"
                                          height="16"
                                          fill="currentColor"
                                          xmlns="http://www.w3.org/2000/svg">
                                          <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path>
                                        </svg>
                                      </Link>
                                      <div class="tooltip">Facebook</div>
                                    </li>
                                  </div>
                                </ul>
                              ) : (
                                // <Link to={event?.facebook}>
                                //   <img src={eventfacebookimg} alt="facebook" />
                                // </Link>
                                <div className="linkedin-div">
                                  <img
                                    className="disable-link-img"
                                    src={eventfacebookimg}
                                    alt="facebook"
                                  />
                                </div>
                              )}
                            </div>

                            <div className="rounded-ringus-img">
                              <div className="rounded-ringus-main">
                                <div className="rounded-ringus-div">
                                  <img src={sponsorringus} alt="ringus-img" />
                                </div>
                              </div>
                            </div>

                            <div className=" wow animate__animated animate__slower animate__bounceIn animate__infinite infinite">
                              {event?.linkedin ? (
                                <ul class="example-2">
                                  <div className="detail-social-div">
                                    <li class="icon-content ">
                                      <Link
                                        to={event.linkedin}
                                        aria-label="LinkedIn"
                                        data-social="linkedin" >
                                        <div class="filled"></div>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          class="bi bi-linkedin"
                                          viewBox="0 0 16 16">
                                          <path
                                            d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"
                                            fill="currentColor"
                                          ></path>
                                        </svg>
                                      </Link>
                                      <div class="tooltip">LinkedIn</div>
                                    </li>
                                  </div>
                                </ul>
                              ) : (
                                // <Link to={event?.linkedin}>
                                //   <img src={linkedinImg} alt="linkedin" />
                                // </Link>
                                <div className="linkedin-div">
                                  <img
                                    className="disable-link-img"
                                    src={linkedinImg}
                                    alt="linkedin"
                                  />
                                </div>
                              )}
                            </div>

                            <div className=" wow animate__animated animate__slower animate__bounceIn animate__infinite infinite">
                              {event?.twitter ? (
                                <ul class="example-2">
                                  <li class="icon-content">
                                    <Link
                                      to={event.twitter}
                                      aria-label="Twitter"
                                      data-social="twitter">
                                      <div class="filled"></div>
                                      <svg
                                        viewBox="0 0 512 512"
                                        height="1.7em"
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="bi bi-linkedin="
                                        fill="currentColor">
                                        <path
                                          d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"
                                          fill="currentColor"
                                        ></path>
                                      </svg>
                                    </Link>
                                    <div class="tooltip">X</div>
                                  </li>
                                </ul>
                              ) : (
                                // <Link to={event?.twitter}>
                                //   <img src={eventtwitterImg} alt="x" />
                                // </Link>
                                <div className="twitter-div">
                                  <img
                                    className="disable-link-img"
                                    src={eventtwitterImg}
                                    alt="x"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }

                  {
                    event?.created_schedule == '1' && event?.event_timing?.length > 0 ||
                    event?.events_shedule?.length > 0 &&
                    <div className="social-media-div">
                      <div className="d-flex align-items-center gap-2">
                        <h2 className="social-m-head">Event Schedule</h2>
                        <hr className="w-100 d-none d-xl-flex " />
                      </div>
                      <div>
                        <div>
                          {event?.event_timing?.length > 0 && <EventDateTimeTabs val={event?.event_timing} />}
                        </div>

                        <div>
                          <EventScheduleImageDocumentShow events_shedule={event?.events_shedule} baseUrls={baseUrls} setPdfFile={setPdfFile} />
                        </div>
                      </div>
                    </div>
                  }
                </div>

                <div className="col-lg-4">
                  <h3 className="event-pro-head m-0">Packages</h3>
                  <div className="packages-div">
                    {event?.events_packages?.map((val, index) => (
                      <PackageCard
                        key={index}
                        val={val}
                        ind={index}
                        userId={userId}
                        getitfunc={getitfunc}
                        isEventExpired={isEventExpired}
                        setItModal={setItModal}
                        currency={currency}
                        profileinfo={profileinfo}
                        event={event}
                      />
                    ))}
                  </div>

                  <div className="m-2">
                    <hr className="w-100" />
                    <div>
                      <button
                        className="partner-btn"
                        onClick={PartnerWithHost}
                      // onClick={() => setPartnerWithHostMdl(!partnerWithHostMdl)}
                      >
                        Partner With Host
                      </button>
                      <button
                        className="partner-btn mt-3"
                        onClick={PartnerWithSponsor}
                      // onClick={() =>
                      //   setPartnerWithSponsorRingusMdl(
                      //     !partnerWithSponsorRingusMdl
                      //   )} 
                      >
                        Partner With SponsorRingUs
                      </button>
                    </div>
                  </div>
                </div>
              </div>



              {/* Modal */}
              <GotItModal
                currency={currency}
                gotitModal={gotitModal}
                gotItValue={gotItValue}
                setItModal={setItModal}
                event={event}
              />
            </div>

            {!reviewUser.length <= 0 && (
              <div className="container reviews-div mt-5 mb-5">
                <div className="d-flex align-items-center gap-5">
                  <h2 className="d-flex gap-2 social-m-head ">
                    <img src={start} alt="str" />
                    {SubRating?.toFixed(1)} | Reviews
                  </h2>
                  <hr className="w-100 d-none d-xl-flex" />
                </div>

                <ReviewCards reviewUser={reviewUser} baseUrls={baseUrls} />
              </div>
            )}

            <CancellationRefund />

            {rejectPopup && (
              <RejectPopup
                setRejectPopup={setRejectPopup}
                event={event}
                getRejected={getRejected}
              />
            )}

            {event?.is_transactions == "1" && reviewMdl && isValid && (
              <SponsoredReview
                eventId={event?.id}
                userReview={userReview}
                evetdetail={evetdetail}
                setReviewMdl={setReviewMdl}
              />
            )}

            {/* {isEventExpired && (
            <StarRating eventId={event?.id} userReview={userReview} />
          )} */}

          </div>
        </>
      )
      }
    </>
  )
};

export default ExpressEventDetail;