
import React, { useState,useEffect,useRef} from 'react';
import { IoClose } from "react-icons/io5";

const TermsAndPrivacyPolicy=({pageData,setTermsPolicyPopUp,loading,setPrivacyPolicyPopUp})=>{
  const [isBottom, setIsBottom] = useState(false);
  const contentRef = useRef(null);

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
    // const atBottom = scrollTop + clientHeight == scrollHeight;
    // setIsBottom(atBottom);
    const scrollPercentage = (scrollTop + clientHeight) / scrollHeight;
    setIsBottom(scrollPercentage >= 0.99);
  };

  useEffect(() => {
    const element = contentRef.current;
    element.addEventListener('scroll', handleScroll);
    return () => {
    element.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
   return(<>
     <div className="warningModal">
      <div className="TermsAndPrivacyPolicy-mdl position-relative" >
      <h4 className="text-center terms-head-text">Terms of use policy</h4> 
        <button className='terms-mdl-close-btn' onClick={()=>setTermsPolicyPopUp(false)}><IoClose size={24}/></button>
      <div className="termsandprivacypolicy-content" ref={contentRef}>
          <div className="" style={{ fontFamily: 'poppins' }}>
             <h3 dangerouslySetInnerHTML={{ __html: pageData }}></h3>
         </div>
      </div>
      <div className="d-flex gap-2 justify-content-end">
        <button
          className={isBottom ? "warning-mdl-btn mt-3" : "warning-mdl-disable-btn mt-3"}
          disabled={!isBottom}
          onClick={()=> {setPrivacyPolicyPopUp(true); setTermsPolicyPopUp(false)}}
          // onClick={usertype == "user" ? handleUserSignUp : handleChampSignUp}
          >
       {
        loading && 
        <div className="spinner-border text-light " role="status">
        <span className="sr-only">Loading...</span>
       </div> 
        }
          Accept Terms and Conditions
        </button>
      </div> 
    </div>

        </div>  
    </>)
};

export default TermsAndPrivacyPolicy;