import React from "react";
import { useEffect, useState } from "react";
import arrow from "../../assets/images/arrow2.svg";
import upload from "../../assets/images/upload-icon.png";
import {
  Get_Professions,
  Get_UserAvatar,
  get_predefine_list,
  get_profile_edit_data,
  update_prodile_data,
} from "../../utils/profile/profileEditAlldata";
import { Link, useNavigate, useParams, useRouteError } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { get_city_state_country } from "../../utils/citystatecountry/cityStateCountry";
import { UserProfile } from "../../utils/user/UserManager";
import { profileval } from "../../redux/UserProfile/Profile";
import { useDispatch, useSelector } from "react-redux";
import { IoMdSend } from "react-icons/io";
import Loader from "../../components/Loader/Loader";
import { FaCircleCheck } from "react-icons/fa6";
import InputMask from "react-input-mask";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import PhoneInput from "react-phone-input-2";
import CreatableSelect from "react-select/creatable";
import { Get_UserInterest, Get_languages_list } from "../../utils/basicInformation/basicinformationManager";
import AadharExample from '../../assets/images/AadharExample.jpg';
import PanExample from '../../assets/images/pancardExample.jpeg';
import Select from "react-select";
import ReactQuill from "react-quill";
import { IoClose } from "react-icons/io5";
import { TiArrowLeft } from "react-icons/ti";

const EditProfile = () => {
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [listing, setListing] = useState([]);
  const [countries, seCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [otherCities, setOtherCities] = useState([]);
  const [otherStates, setOtherStates] = useState([]);
  const [avatars, setAvatars] = useState([]);
  const [loading, setLoading] = useState(true);
  const [center, setCenter] = useState({ lat: 24.5980386, lng: 73.6866158 });
  const [marker, setMarker] = useState(null);
  const [zip_country, setZip_Country] = useState({});
  const [zip_state, setZip_State] = useState({});
  const [zip_city, setZip_City] = useState({});
  const [ohter_Zip_country, setOther_Zip_Country] = useState({});
  const [ohter_Zip_state, setOther_Zip_State] = useState({});
  const [ohter_Zip_city, setOther_Zip_City] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [listingLanguage, setListingLanguage] = useState([]);
  const [predefineIntrest, setPredefineIntrest] = useState([]);
  const [selectEdInterest, setSelectedInterest] = useState([]);
  const [professionData, setProfessionData] = useState([]);
  const [addAddress, setAddAddress] = useState(0)
  const profileinfo = useSelector((data) => data?.Profile_data?.ProfileData);
  const ConfigData = useSelector((state) => state?.config_data?.configData);
  const baseUrls = ConfigData?.base_urls;

  const dispatch = useDispatch();

  const [input, setInput] = useState({
    f_name: "",
    l_name: "",
    image_type: "",
    predefine_image: "",
    aadhar_card: "",
    aadhar_front_image: "",
    aadhar_back_image: "",
    facebook_link: "",
    instagram_link: "",
    website_link: "",
    date_of_birth: "",
    gender: "",
    marital_status: "",
    anniversary: "",
    education_background: "",
    industry: "",
    occupation: "",
    house_hold_income: "",
    state_id: "",
    city_id: "",
    country_id: "",
    zip_code: "",
    house_no: "",
    landmark: "",
    address_type: '',
    address: "",
    other_state_id: "",
    other_city_id: "",
    other_country_id: "",
    other_zip_code: "",
    other_house_no: "",
    other_landmark: "",
    other_address: "",
    image: "",
    languages: [],
    alternative_number: "",
    country_code: "",
    is_host: '',
    is_sponsor: '',
    i_want_to: '',
    account_type: '',
    cin_number: '',
    gst_number: '',
    gst_file: '',
    cin_file: '',

    profession: "",
    family_details: "",
    known_for: "",
    tech_proficiency: "",
    industry_related_topics: "",
    social_media_usage: "",
    type_ids: "",
    my_journey: ""

  });
  const [aboutAddress, setAboutAddress] = useState({
    city: "",
    state: ""
  });
  const [errors, setErrors] = useState({});
  const params = useParams();
  const navigate = useNavigate();
  const id = params?.id;
  const [maxDate, setMaxDate] = useState('');
  const [minAnniversaryDate, setMinAnniversaryDate] = useState('');

  const today = new Date();
  const formattedTodayDate = today.toISOString().split('T')[0];
  useEffect(() => {
    const today = new Date();
    const tenYearsAgo = new Date(today.setFullYear(today.getFullYear() - 10));
    const formattedDate = tenYearsAgo.toISOString().split('T')[0];
    setMaxDate(formattedDate);
    if (input?.date_of_birth) {
      const dob = new Date(input?.date_of_birth);
      const eighteenYearsLater = new Date(dob.getFullYear() + 18, dob.getMonth(), dob.getDate());
      const formattedMinAnniversaryDate = eighteenYearsLater.toISOString().split('T')[0];
      setMinAnniversaryDate(formattedMinAnniversaryDate);
    }
  }, [input?.date_of_birth]);

  // console.log("minAnniversaryDate",minAnniversaryDate)

  useEffect(() => {
    // const list = profileinfo?.interest?.map((item) => ({
    //   label: item,
    //   value: item,
    // }));



    //  const list = profileinfo?.interest?.map((item) => ({
    //   label: item,
    //   value: item,
    // }));   

    // setSelectedInterest(list);

    // const interests = list?.map((val) => val?.value)
    // console.log("interests",predefineIntrest)
    // const changeIntrests = JSON?.stringify(interests);
  }, [profileinfo]);

  const GetUserInt = async () => {
    try {
      await Get_UserInterest().then((response) => {
        const getInterest = response?.data;
        const options = getInterest?.map((topic) => ({
          value: topic.id,
          label: topic.name,
        }));
        setPredefineIntrest(options);

        const sData = getInterest?.filter((val) => {
          const isInterestIncluded = profileinfo?.interest?.includes(val?.name);
          return isInterestIncluded;
        });

        const list = sData?.map((item) => ({
          label: item?.name,
          value: item?.id,
        }));
        setSelectedInterest(list);

        const allValues = list?.map((val) => val.value) || [];

        setInput(currentInput => ({
          ...currentInput,
          type_ids: JSON.stringify(allValues),
        }));

      });
    } catch (error) {
      console.log(error);
    }
  }

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyA3BYK_Siw8ebziSskitaA5YMjbvcO6n4U",
  });

  const onLoad = React.useCallback(
    function callback(map) {
      const bounds = new window.google.maps.LatLngBounds(center);
      map.fitBounds(bounds);
      // setMapData(map);
    },
    [center]
  );

  const onUnmount = React.useCallback(function callback() {
    // setMapData(null);
  }, []);

  const handleMapClick = (event) => {
    const { latLng } = event;
    const lat = latLng.lat();
    const lng = latLng.lng();
    setCenter({ lat, lng });
    setMarker({ lat, lng });
    getAddressFromLatLng(lat, lng);
  };

  const handlePinCodeChange = async (val, type) => {
    const pinc = val;

    if (isNaN(pinc)) {
      console.error("Invalid pin code. Please enter a valid number.");
      // You may set an error state or handle it in another way
      return;
    }

    setInput((prevInput) => ({ ...prevInput, zip_code: pinc }));

    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${pinc}&key=AIzaSyA3BYK_Siw8ebziSskitaA5YMjbvcO6n4U`
      );
      const data = await response.json();
      if (data.results && data.results.length > 0) {
        const addressComponents = data.results[0].address_components;
        const latLong = data?.results[0]?.geometry?.location;
        const fullAddress = addressComponents
          ?.map((component) => component.long_name)
          .join(", ");
        if (addressComponents) {
          const cityComponent = addressComponents.find((component) =>
            component?.types?.includes("locality")
          );
          const stateComponent = addressComponents.find((component) =>
            component?.types?.includes("administrative_area_level_1")
          );
          const countryComponent = addressComponents.find((component) =>
            component?.types?.includes("country")
          );

          if (countryComponent) setZip_Country(countryComponent);
          if (stateComponent) setZip_State(stateComponent);
          if (cityComponent) setZip_City(cityComponent);

          const country = countries.find(
            (country) => country.country_name == countryComponent?.long_name
          );
          setStates(country ? country?.states : []);

          const state = country
            ? country.states.find((state) => state?.state_name == stateComponent?.long_name
            )
            : null;

          const city = state
            ? state.cities.find((city) => city?.city_name == cityComponent?.long_name
            )
            : null;
          setAboutAddress({ state: state?.state_name, city: city?.city_name });
          if (state) setCities(state.cities);

          if (addressComponents && latLong) {
            if (type == "map") {
              setInput((prevInput) => ({
                ...prevInput,
                country_id: country?.country_id,
                state_id: state?.state_id,
                city_id: city?.city_id,
              }));
            } else {
              setInput((prevInput) => ({
                ...prevInput,
                country_id: country?.country_id,
                state_id: state?.state_id,
                city_id: city?.city_id,
                // address: fullAddress,
                latitude: latLong?.lat,
                longitude: latLong?.lng,
              }));
              setCenter(latLong);
              setMarker(latLong);
            }
          }
        } else {
          console.error(
            "No address components found in the Geocoding API response."
          );
        }
      } else {
        console.error("No results found in the Geocoding API response.");
      }
    } catch (error) {
      console.error("Error fetching location:", error);
      // Handle the error (e.g., set an error state)
    }
  };


  const handleOfficePinCodeChange = async (val, type) => {
    const pinc = val;

    if (isNaN(pinc)) {
      console.error("Invalid pin code. Please enter a valid number.");
      // You may set an error state or handle it in another way
      return;
    }

    setInput((prevInput) => ({ ...prevInput, other_zip_code: pinc }));

    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${pinc}&key=AIzaSyA3BYK_Siw8ebziSskitaA5YMjbvcO6n4U`
      );
      const data = await response.json();
      if (data.results && data.results.length > 0) {
        const addressComponents = data.results[0].address_components;
        const latLong = data?.results[0]?.geometry?.location;
        const fullAddress = addressComponents
          ?.map((component) => component.long_name)
          .join(", ");
        if (addressComponents) {
          const cityComponent = addressComponents.find((component) =>
            component?.types?.includes("locality")
          );
          const stateComponent = addressComponents.find((component) =>
            component?.types?.includes("administrative_area_level_1")
          );
          const countryComponent = addressComponents.find((component) =>
            component?.types?.includes("country")
          );

          if (countryComponent) setOther_Zip_Country(countryComponent);
          if (stateComponent) setOther_Zip_State(stateComponent);
          if (cityComponent) setOther_Zip_City(cityComponent);

          const country = countries.find(
            (country) => country.country_name == countryComponent?.long_name
          );
          setOtherStates(country ? country?.states : []);

          const state = country
            ? country.states.find((state) => state?.state_name == stateComponent?.long_name
            )
            : null;

          const city = state
            ? state.cities.find((city) => city?.city_name == cityComponent?.long_name
            )
            : null;
          // setAboutAddress({ state: state?.state_name, city: city?.city_name });
          if (state) setOtherCities(state.cities);

          if (addressComponents && latLong) {
            if (type == "map") {
              setInput((prevInput) => ({
                ...prevInput,
                other_country_id: country?.country_id,
                other_state_id: state?.state_id,
                other_city_id: city?.city_id,
              }));
            } else {
              setInput((prevInput) => ({
                ...prevInput,
                other_country_id: country?.country_id,
                other_state_id: state?.state_id,
                other_city_id: city?.city_id,
                // address: fullAddress,
                latitude: latLong?.lat,
                longitude: latLong?.lng,
              }));
              setCenter(latLong);
              setMarker(latLong);
            }
          }
        } else {
          console.error(
            "No address components found in the Geocoding API response."
          );
        }
      } else {
        console.error("No results found in the Geocoding API response.");
      }
    } catch (error) {
      console.error("Error fetching location:", error);
      // Handle the error (e.g., set an error state)
    }
  };


  const getAddressFromLatLng = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyA3BYK_Siw8ebziSskitaA5YMjbvcO6n4U`
      );
      if (response.ok) {
        const data = await response.json();
        if (data?.results && data?.results.length > 0) {
          const full_address = data?.results[0]?.address_components;
          const location = full_address.find((loc) =>
            loc?.types?.includes("postal_code")
          );
          const location1 = data?.results[0]?.formatted_address;
          const let_lng = data?.results[0]?.geometry?.location;
          setInput({
            ...input,
            latitude: let_lng?.lat,
            longitude: let_lng?.lng,
            zip_code: location?.long_name,
            address: location1,
          });
          handlePinCodeChange(location?.long_name, "map");
        } else {
          console.log("Address not found");
        }
      } else {
        console.log("Failed to fetch address");
      }
    } catch (error) {
      console.error("Error fetching address:", error);
    }
  };

  const handleMyjourny = (value) => {
    const text = value.replace(/<[^>]*>?/gm, '');
    if (text.length <= 500) {
      setInput((prevState) => ({
        ...prevState,
        my_journey: value
      }));
      setErrors({
        ...errors,
        my_journey: ""
      });
    } else {
      setErrors({
        ...errors,
        my_journey: "My Journey cannot exceed 500 characters."
      });
    }
  }

  const handleInputChange = async (e) => {
    const { name, value, files, type } = e.target;
    if (name === "image") {
      setInput({
        ...input,
        [name]: files[0],
        image_type: "browse",
        predefine_image: "",
      });
    } else if (type == "file") {
      setInput({
        ...input,
        [name]: files[0],
      });
    } else {
      setInput({
        ...input,
        [name]: value,
      });
    }
  };


  const validateForm = () => {
    let errors = {};
    let isValid = true;

    // if (!input.predefine_image || input.predefine_image == "") {
    //   errors.predefine_image = "Avatar is required";
    //   isValid = false;
    // }

    if (!input.f_name || !input.f_name.trim()) {
      errors.f_name = "First Name is required";
      isValid = false;
    }

    if (!input.l_name || !input.l_name.trim()) {
      errors.l_name = "Last Name is required";
      isValid = false;
    }

    if (!input.image && !input?.predefine_image) {
      errors.image = "Image Is Required"
      isValid = false;
    }

    if (!input.pan_card || input.pan_card == "") {
      errors.pan_card = "Pan Number Is Required";
      isValid = false;
    }

    if (!input?.pan_front_image) {
      errors.pan_front_image = "Pan Image Required";
      isValid = false;
    } else if (input?.pan_front_image?.size > 1 * 1024 * 1024) {
      errors.pan_front_image = "Pan image size exceeds the maximum allowed size (1MB).";
      isValid = false;
    }

    // if (!input?.pan_back_image) {
    //   errors.pan_back_image = "Pan Back Image Required";
    //   isValid = false;
    // }

    if (input.account_type == 'Individual_account') {

      const aadhaarRegex = /^[0-9]{4}\s[0-9]{4}\s[0-9]{4}$/;
      if (input.aadhar_card == "") {
        errors.aadhar_card = 'Aadhar Number Is Required"';
        isValid = false;
      } else if (!aadhaarRegex?.test(input.aadhar_card)) {
        errors.aadhar_card = "Invalid Aadhar Number";
        isValid = false;
      }

      if (!input?.aadhar_front_image) {
        errors.aadhar_front_image = "Aadhar Image Required";
        isValid = false;
      } else if (input?.aadhar_front_image.size > 1 * 1024 * 1024) {
        errors.aadhar_front_image = "Aadhar image size exceeds the maximum allowed size (1MB).";
        isValid = false;
      }
      // if (!input?.aadhar_back_image) {
      //   errors.aadhar_back_image = "Aadhaar Back Image Required";
      //   isValid = false;
      // }
    } else {
      if (!input.gst_number || input.gst_number == "") {
        errors.gst_number = "GST Number Is Required";
        isValid = false;
      }
      if (!input.cin_number || input.cin_number == "") {
        errors.cin_number = "CIN Number Is Required";
        isValid = false;
      }

      if (!input?.gst_file) {
        errors.gst_file = "GST Image Required";
        isValid = false;
      } else if (input?.gst_file.size > 1 * 1024 * 1024) {
        errors.gst_file = "GST Image size exceeds the maximum allowed size (1MB).";
        isValid = false;
      }

      if (!input?.cin_file) {
        errors.cin_file = "CIN Image Required";
        isValid = false;
      } else if (input?.cin_file.size > 1 * 1024 * 1024) {
        errors.cin_file = "CIN Image size exceeds the maximum allowed size (1MB).";
        isValid = false;
      }
    }


    if (!input.date_of_birth || !input.date_of_birth?.trim()) {
      errors.date_of_birth = "DOB Is Required";
      isValid = false;
    }

    if (!input.gender || !input.gender?.trim()) {
      errors.gender = "Gender Is Required";
      isValid = false;
    }

    if (!input.marital_status || !input.marital_status?.trim()) {
      errors.marital_status = "Marital Status Is Required";
      isValid = false;
    }

    if (!input.education_background || !input.education_background?.trim()) {
      errors.education_background = "Education Background Is Required";
      isValid = false;
    }

    if (!input.industry || !input.industry?.trim()) {
      errors.industry = "Industry Is Required";
      isValid = false;
    }

    if (!input.occupation || !input.occupation?.trim()) {
      errors.occupation = "Occupation Is Required";
      isValid = false;
    }

    if (!input.house_hold_income || !input.house_hold_income?.trim()) {
      errors.house_hold_income = "Income Is Required";
      isValid = false;
    }

    if (!input.state_id || !input.state_id.toString()?.trim()) {
      errors.state_id = "State Is Required";
      isValid = false;
    }

    if (!input.city_id || !input.city_id.toString()?.trim()) {
      errors.city_id = "City Is Required";
      isValid = false;
    }

    if (!input.country_id || !input.country_id.toString()?.trim()) {
      errors.country_id = "Country Is Required";
      isValid = false;
    }

    if (!input.zip_code || !input.zip_code.toString()?.trim()) {
      errors.zip_code = "Zip Code Is Required";
      isValid = false;
    }

    if (!input.phone || !input.phone?.trim()) {
      errors.phone = "Phone Is Required";
      isValid = false;
    }

    if (!input.address || !input.address?.trim()) {
      errors.address = "Area, Street, Sector, Village Is Required";
      isValid = false;
    }

    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

    if (input?.facebook_link) {
      if (!urlRegex?.test(input.facebook_link)) {
        errors.facebook_link = "Facebook Link Not Valid";
        isValid = false;
      }
    }

    if (input?.instagram_link) {
      if (!urlRegex?.test(input.instagram_link)) {
        errors.instagram_link = "Instagram Link Not Valid";
        isValid = false;
      }
    }

    if (input?.website_link) {
      if (!urlRegex?.test(input.website_link)) {
        errors.website_link = "Web Link Not Valid";
        isValid = false;
      }
    }

    if (!input.alternative_number || !input.alternative_number?.trim() === "") {
      errors.alternative_number = "Mobile is required";
      isValid = false;
    } else if (input.alternative_number?.trim().length < 10) {
      errors.alternative_number =
        "Mobile should be at least 10 characters long";
      isValid = false;
    }

    if (!input.house_no || !input.house_no?.trim()) {
      errors.house_no = "Flat/House No., Building is required";
      isValid = false;
    }

    // if (!input.landmark || !input.landmark?.trim()) {
    //   errors.landmark = "Landmark Is Required";
    //   isValid = false;
    // }

    if (!input.address_type || !input.address_type?.trim()) {
      errors.address_type = "Address Type is required";
      isValid = false;
    }

    if (!input?.family_details) {
      errors.family_details = "Family Details is required";
      isValid = false;
    }

    if (!input?.profession) {
      errors.profession = "Profession is required";
      isValid = false;
    }

    if (!input?.tech_proficiency) {
      errors.tech_proficiency = "Tech Proficiency is required";
      isValid = false;
    }

    if (!input?.known_for) {
      errors.known_for = "Known For is required";
      isValid = false;
    }

    if (!input?.industry_related_topics) {
      errors.industry_related_topics = "Industry Related Topics is required";
      isValid = false;
    }

    if (!input?.social_media_usage) {
      errors.social_media_usage = "Social Media Usage is required";
      isValid = false;
    }

    const jour = input?.my_journey?.replace(/<p><br><\/p>/g, '')
    if (!jour) {
      errors.my_journey = "My Journey is required";
      isValid = false;
    }


    // if (!input.image?.trim() {
    //   errors.image = "Image is required";
    //   isValid = false;
    // }

    // if (!input.email?.trim()) {
    //   errors.email = "Email is required";
    //   isValid = false;
    // }

    setErrors(errors);
    return isValid;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const data = { ...input, is_office_address: addAddress }
      try {
        setBtnLoading(true);
        await update_prodile_data(data, id).then(async (response) => {
          if (response?.data.success == true) {
            toast.success(response?.data?.message, {
              position: "top-center",
            });
            navigate("/profile");
            const upProfile = await UserProfile();
            dispatch(profileval(upProfile?.data?.user));
          }
        });
      } catch (err) {
        toast.error(err?.response?.data?.message, {
          position: "top-center",
        }
        );
        console.log(err);
      } finally {
        setBtnLoading(false);
      }
    }
  };

  const DataList = async () => {
    try {
      await get_predefine_list().then((response) => {
        setListing(response?.data?.data)
      });
    } catch (err) {
      console.log(err);
    }
  };

  const Languagelist = async () => {
    try {
      await Get_languages_list().then((response) => {
        const lan = response?.data?.data
        setListingLanguage(lan?.map((item) => {
          return {
            value: item?.name,
            label: item?.name
          }
        }))
      });
    } catch (err) {
      console.log(err);
    }
  };


  const editAddress = (countries, cntryid, stateid, cityid) => {
    const country = countries?.find((country) => country?.country_id == cntryid);
    setStates(country ? country?.states : []);
    if (states) {
      const country = countries.find((country) => country?.country_id == cntryid);
      const state = country ? country.states.find((state) => state?.state_id == stateid)
        : null;
      setCities(state ? state.cities : []);
    } else {
      setCities([]);
    }
  };

  const OtherEditAddress = (countries, otherCntryid, otherStateid, otherCityid) => {
    const country = countries?.find((country) => country?.country_id == otherCntryid);
    setOtherStates(country ? country?.states : []);
    if (states) {
      const country = countries.find((country) => country?.country_id == otherCntryid);
      const state = country ? country.states.find((state) => state?.state_id == otherStateid)
        : null;
      setOtherCities(state ? state.cities : []);
    } else {
      setOtherCities([]);
    }
  };

  // country
  const getCountryCityState = async (cntryid, stateid, cityid, otherCntryid, otherStateid, otherCityid) => {
    try {
      get_city_state_country().then((response) => {
        seCountries(response?.data?.data?.countries);
        editAddress(response?.data?.data?.countries, cntryid, stateid, cityid);
        OtherEditAddress(response?.data?.data?.countries, otherCntryid, otherStateid, otherCityid)
        // editState(response?.data?.data?.countries)
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getprofileEditData = async () => {
    setAboutAddress({
      city: profileinfo?.city_name,
      state: profileinfo?.state_name
    })
    setLoading(true);
    try {
      await get_profile_edit_data(id).then((response) => {
        const resData = response?.data?.data
        const cntryid = response?.data?.data?.country_id;
        const stateid = response?.data?.data?.state_id;
        const cityid = response?.data?.data?.city_id;
        const otherCntryid = response?.data?.data?.other_country_id
        const otherStateid = response?.data?.data?.other_state_id
        const otherCityid = response?.data?.data?.other_city_id
        setAddAddress(resData?.is_office_address)
        getCountryCityState(cntryid, stateid, cityid, otherCntryid, otherStateid, otherCityid);
        // 
        setSelectedCountry(cntryid)
        const languages = response?.data?.data?.languages
        setLanguages(languages?.map((item) => {
          return {
            value: item?.language,
            label: item?.language
          }
        }))
        const lang = languages?.map((item) => item?.language)

        setInput(currentInput => ({
          ...currentInput,
          ...response?.data?.data,
          my_journey: resData?.my_journey == null ? "" : resData?.my_journey,
          languages: JSON.stringify(lang),
        }));
        GetUserInt();
      });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const GetAvatars = async () => {
    try {
      const response = await Get_UserAvatar();
      // console.log(response?.data?.data)
      setAvatars(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const GetProfession = async () => {
    try {
      const response = await Get_Professions();
      setProfessionData(response?.data?.data);
    } catch (err) {
      console.log("err", err)
    }
  }

  useEffect(() => {
    GetAvatars();
    DataList();
    getprofileEditData();
    getCountryCityState();
    Languagelist();
    GetProfession();
  }, []);

  const handleCountryChange = (e) => {
    const cntry = e.target.value;
    setInput({ ...input, country_id: cntry });
    setSelectedCountry(cntry);
    if (cntry) {
      const country = countries.find((country) => country.country_id == cntry);
      setStates(country ? country?.states : []);
    } else {
      setStates([]);
      setCities([]);
    }
  };

  const handleStateChange = (e) => {
    const sState = e.target.value;
    setInput({ ...input, state_id: sState });
    setSelectedState(sState);
    if (sState) {
      const country = countries.find((country) => country?.country_id == selectedCountry);
      const state = country ? country.states.find((state) => state?.state_id == sState) : null;
      setAboutAddress({ ...aboutAddress, state: state?.state_name })

      setCities(state ? state?.cities : []);
    } else {
      setCities([]);
    }
  };

  const handleCityChange = (e) => {
    const cityid = e.target.value;
    const city = cities.find((city) => city?.city_id == cityid);

    setAboutAddress({ ...aboutAddress, city: city?.city_name });

    setInput({ ...input, city_id: cityid });
    setSelectedCity(cityid);
  };


  const handleOfficeCountryChange = (e) => {
    const cntry = e.target.value;
    setInput({ ...input, other_country_id: cntry });
    setSelectedCountry(cntry);
    if (cntry) {
      const country = countries.find((country) => country.country_id == cntry);
      setOtherStates(country ? country?.states : []);
    } else {
      setOtherStates([]);
      setOtherCities([]);
    }
  };

  const handleOfficeStateChange = (e) => {
    const sState = e.target.value;
    setInput({ ...input, other_state_id: sState });
    setSelectedState(sState);
    if (sState) {
      const country = countries.find((country) => country?.country_id == selectedCountry);
      const state = country ? country.states.find((state) => state?.state_id == sState) : null;
      // setAboutAddress({ ...aboutAddress, state: state?.state_name })

      setOtherCities(state ? state?.cities : []);
    } else {
      setOtherCities([]);
    }
  };

  const handleOfficeCityChange = (e) => {
    const cityid = e.target.value;
    const city = cities.find((city) => city?.city_id == cityid);

    // setAboutAddress({ ...aboutAddress, city: city?.city_name });

    setInput({ ...input, other_city_id: cityid });
    setSelectedCity(cityid);
  };

  const handlePhoneNumberChange = (value, country) => {
    const phoneNum = value?.slice(2);
    const d_code = `${country.format.charAt(0)}${country?.dialCode}`;
    setInput({
      ...input,
      alternative_number: phoneNum,
      country_code: d_code,
    });
  };

  const handleLanguageChange = (selectedOptions) => {
    const capitalizedLang = selectedOptions
      ?.map((item) => item?.value)
      .filter((value) => /^[a-zA-Z\s()\/]*$/.test(value))
      ?.map((value) => value.charAt(0).toUpperCase() + value.slice(1));
    setInput({ ...input, languages: JSON.stringify(capitalizedLang) });


    const showInCretable = selectedOptions
      ?.filter((item) => /^[a-zA-Z\s()\/]*$/.test(item?.value))
      ?.map((item) => ({
        value: item?.value?.charAt(0).toUpperCase() + item?.value?.slice(1),
        label: item?.value?.charAt(0).toUpperCase() + item?.value?.slice(1),
      }));
    setLanguages(showInCretable);
  };

  const handleBecomeType = (event) => {
    const { name, checked } = event.target;
    const value = checked ? 1 : 0;
    setInput({
      ...input,
      [name]: value,
    });
  };

  const handleSelectIntrest = (selectedOption) => {
    setSelectedInterest(selectedOption);
    const list = selectedOption?.map((item) => {
      return item.value;
    });
    setInput({
      ...input,
      type_ids: JSON?.stringify(list),
    })
  };

  // rect quill length count
  const stripHtmlTags = (html) => {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  };

  const getDescriptionLength = () => {
    const cleanDescription = stripHtmlTags(input.my_journey);
    return cleanDescription.length;
  };

  const maxLength = 500;
  const currentLength = getDescriptionLength();
  const remainingLength = maxLength - currentLength;
  //

  return (
    <>
      <ToastContainer />
      {loading ? (
        <Loader />
      ) : (
        <div className="container-md py-2 py-md-5">
          {/* <div className="py-4 d-flex justify-content-center py-5">
            <h3>Edit Profile</h3>
          </div> */}
          <div className="col-md-12 d-flex justify-content-between">
            <h3 className="all-notification-head">Edit Profile</h3>
            {/* <Link to="/profile" className="btn viewall-btn-p">Go Back</Link> */}
            <button className="go-back-btn" onClick={() => navigate(-1)} type="button">
              <TiArrowLeft size={20}/> Go Back
              </button>
          </div>
          <form onSubmit={onSubmit}>
            <div className="row">
              <p className="Bannerimg-head">
                Profile Image
                {/* <span className="asterisk">*</span> */}
              </p>
              <div className="col-6">
                <label className="form-label">
                  Upload Image Or Choose Avatar
                  <span className="asterisk">*</span>
                </label>
                <label className="bannerimg-div">
                  <input
                    className="d-none"
                    type="file"
                    name="image"
                    multiple
                    onChange={handleInputChange}
                  />
                  <div className="upload-image-content">
                    <img src={upload} alt="upload" />
                    <p className="uploadimg-font">Upload Profile Image</p>
                    <p className="img-format-text">
                      Image format: .jpg or .png
                    </p>
                    <p className="img-format-text">
                      Recommended size: w: 1440px, H: 600px
                    </p>
                  </div>
                </label>
              </div>
              <div className="col-6 d-flex align-items-center justify-content-center">
                <div className="">
                  {!input?.image || input?.image == "def.png" ? (
                    ""
                  ) : (
                    <img
                      src={
                        input?.image instanceof Blob
                          ? URL.createObjectURL(input?.image) : `${baseUrls?.customer_image_url}/${input?.image}`}
                      alt="noImge"
                      className=""
                      style={{
                        height: "200px",
                        width: "200px",
                        border: "1px solid black",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                    />
                  )}
                </div>
              </div>
              {errors?.image && (
                <span className="error mt-2">{errors?.image}</span>
              )}
            </div>
            <div className="row mt-3">

              <label className="form-label">
                Choose Avatar
                <span className="asterisk">*</span>
              </label>
              <div className="col-12">
                <div className="d-flex gap-2 gap-md-4">
                  {avatars?.map((val) => {
                    return (
                      <div className="position-relative" key={val?.id}>
                        <div className=""></div>

                        <button
                          className="avatar-select-btn"
                          type="button"
                          onClick={() =>
                            setInput({
                              ...input,
                              image: "",
                              predefine_image: val?.image_name,
                              image_type: "predefined",
                            })
                          }
                        >
                          {input?.predefine_image == val?.image_name && (
                            <FaCircleCheck
                              className="profile-image-check"
                              size={20}
                              color="green"
                            />
                          )}

                          <img
                            className="avatar-img"
                            // `${baseUrls?.avatar_image_url}/${val?.predefine_image}`
                            src={`${baseUrls?.avatar_image_url}/${val?.image_name}`}
                            alt="avatar"
                          />
                        </button>
                      </div>
                    );
                  })}
                </div>
                {errors?.predefine_image && (
                  <span className="error">{errors?.predefine_image}</span>
                )}
              </div>
            </div>

            <div className="row mt-4">
              <div className="form-group col-md-3 col-sm-6">
                <label className="form-label">
                  First Name<span className="asterisk">*</span>
                </label>
                <input
                  type=""
                  value={input.f_name}
                  name="f_name"
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="first name"
                />
                {errors.f_name && (
                  <span className="error">{errors.f_name}</span>
                )}
              </div>
              <div className="form-group col-md-3 col-sm-6">
                <label className="form-label">
                  Last Name<span className="asterisk">*</span>
                </label>
                <input
                  type=""
                  value={input.l_name}
                  name="l_name"
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="last name"
                />
                {errors.l_name && (
                  <span className="error">{errors.l_name}</span>
                )}
              </div>
              <div className="form-group col-md-3 col-sm-6">
                <label className="form-label">
                  DOB<span className="asterisk">*</span>
                </label>
                <div>
                  <input
                    type="date"
                    className="form-control"
                    name="date_of_birth"
                    value={input.date_of_birth}
                    onChange={handleInputChange}
                    max={maxDate}
                  />
                  {errors.date_of_birth && (
                    <span className="error">{errors.date_of_birth}</span>
                  )}
                </div>
              </div>
              <div className="form-group col-md-3 col-sm-6">
                <label className="form-label">
                  Gender<span className="asterisk">*</span>
                </label>
                <select
                  className="form-control"
                  name="gender"
                  onChange={handleInputChange}>
                  <option selected disabled>Select</option>
                  {listing?.gender?.map((val) => (
                    <option
                      key={val?.id}
                      value={val?.gender_type}
                      selected={input.gender == val?.gender_type}
                    >{val?.gender_type}</option>
                  ))}

                </select>
                {errors.gender && (
                  <span className="error">{errors.gender}</span>
                )}
              </div>
            </div>

            <div className="row">
              {/* <h5 className="my-3 form-section-heading">Profile details</h5> */}
              <div className="form-group col-md-3 col-sm-6">
                <label className="form-label">
                  Marital Status<span className="asterisk">*</span>
                </label>
                <select
                  className="form-control"
                  name="marital_status"
                  onChange={handleInputChange}>
                  <option disabled selected>
                    Select
                  </option>
                  {listing?.guests_marital_status?.map((val) => (
                    <option
                      key={val?.id}
                      value={val?.marital_status}
                      selected={input.marital_status == val?.marital_status}
                    >{val?.marital_status}</option>
                  ))}

                </select>
                {errors.marital_status && (
                  <span className="error">{errors.marital_status}</span>
                )}
              </div>

              <div className="form-group col-md-3 col-sm-6">
                <label className="form-label">
                  Anniversary<span className="asterisk"></span>
                </label>
                <input
                  type="date"
                  className="form-control"
                  name="anniversary"
                  value={input?.anniversary}
                  min={minAnniversaryDate}
                  max={formattedTodayDate}
                  onChange={handleInputChange}
                />
                {errors?.anniversary && (
                  <span className="error">{errors?.anniversary}</span>
                )}
              </div>
              <div className="form-group col-md-3 col-sm-6">
                <label className="form-label">
                  Educational Background<span className="asterisk">*</span>
                </label>
                <select
                  className="form-control"
                  name="education_background"
                  onChange={handleInputChange}>
                  <option selected>Select</option>
                  {listing?.educational_background?.map((val) => (
                    <option
                      key={val?.id}
                      value={val?.educations}
                      selected={input?.education_background == val?.educations}>
                      {val?.educations}
                    </option>
                  ))}
                </select>
                {errors.education_background && (
                  <span className="error">{errors.education_background}</span>
                )}
              </div>

              <div className="form-group col-md-3 col-sm-6">
                <label className="form-label">
                  Industry<span className="asterisk">*</span>
                </label>
                <select
                  className="form-control"
                  name="industry"
                  onChange={handleInputChange}
                >
                  {/* <option value={input?.industry} selected>
                  {input?.industry}
                </option> */}
                  <option selected>Select</option>
                  {listing?.industry?.map((val, index) => (
                    <option
                      key={index}
                      value={val?.industry}
                      selected={input?.industry == val?.industry}>
                      {val?.industry}
                    </option>
                  ))}
                </select>
                {errors.industry && (
                  <span className="error">{errors.industry}</span>
                )}
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-3 col-sm-6">
                <label className="form-label">
                  Occupation<span className="asterisk">*</span>
                </label>
                <select
                  className="form-control"
                  name="occupation"
                  onChange={handleInputChange}
                >
                  {/* <option value={input?.occupation} selected>
                  {input?.occupation}
                </option> */}
                  <option selected>Select</option>
                  {listing?.occupation?.map((val, index) => (
                    <option
                      key={index}
                      value={val?.occupations}
                      selected={input?.occupation == val?.occupations}
                    >
                      {val?.occupations}
                    </option>
                  ))}
                </select>
                {errors.occupation && (
                  <span className="error">{errors.occupation}</span>
                )}
              </div>

              <div className="form-group col-md-3 col-sm-6">
                <label className="form-label">
                  House Hold Income<span className="asterisk">*</span>
                </label>
                <select
                  className="form-control"
                  name="house_hold_income"
                  onChange={handleInputChange}
                >
                  {/* <option value={input?.house_hold_income} selected>
                  {input?.house_hold_income}
                </option> */}
                  <option value="" selected>
                    Select
                  </option>
                  {listing?.house_hold_income?.map((val, index) => (
                    <option
                      key={index}
                      value={val?.house_hold_income}
                      selected={
                        input?.house_hold_income == val?.house_hold_income
                      }
                    >
                      {val?.house_hold_income}
                    </option>
                  ))}
                </select>
                {errors.house_hold_income && (
                  <span className="error">{errors.house_hold_income}</span>
                )}
              </div>

              <div className="form-group col-md-3 col-sm-6">
                <label className="form-label">
                  Languages Known<span className="asterisk">*</span>
                </label>
                <CreatableSelect
                  isMulti
                  value={languages}
                  classNamePrefix="select"
                  placeholder="Type Language and press enter"
                  onChange={handleLanguageChange}
                  options={listingLanguage}
                />
                {errors.languages && (
                  <span className="error">{errors.languages}</span>
                )}
              </div>

              <div className="col-md-3 form-group col-sm-6">
                <label className="form-label">
                  Alternative Mobile Number<span className="asterisk">*</span>
                </label>
                <PhoneInput
                  className=""
                  country={"in"}
                  value={`${input?.country_code}${input?.alternative_number}`}
                  onChange={handlePhoneNumberChange}
                />
                {errors.alternative_number && (
                  <span className="error">{errors.alternative_number}</span>
                )}
              </div>
            </div>

            <div className="row">
              {/* <div className="form-group col-md-3">
                <label className="form-label">I Want To Become a :</label>
                <div className="d-flex gap-3 mt-2">
                  <div className="form-check">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="i_want_to"
                        value="is_host"
                        checked={input.i_want_to == 'is_host'}
                        // onChange={handleBecomeType}
                        onChange={handleInputChange}
                      />
                      Host
                    </label>
                  </div>
                  <div class="form-check">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="i_want_to"
                        value="is_sponsor"
                        checked={input.i_want_to == 'is_sponsor'}
                        // onChange={handleBecomeType}
                        onChange={handleInputChange}
                      />
                      Sponsor
                    </label>
                  </div>
                </div>
              </div> */}

              <div className="form-group col-md-3">
                <label className="form-label">Account Type :</label>

                <div className="d-flex gap-3 mt-2">
                  <div className="form-check">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="account_type"
                        value="Individual_account"
                        checked={input.account_type == 'Individual_account'}
                        onChange={handleInputChange}
                      />
                      Individual
                    </label>

                  </div>
                  <div class="form-check">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="account_type"
                        value="Company_account"
                        checked={input.account_type == 'Company_account'}
                        onChange={handleInputChange}
                      />
                      Company
                    </label>
                  </div>
                </div>
              </div>
            </div>

            {input.account_type == 'Individual_account' ?
              <div>
                <div className="row">
                  <h5 className="mt-3 form-section-heading">
                    Individual Details<span className="asterisk">*</span>
                  </h5>
                  <div className="col-md-6 form-group col-sm-6">
                    <label className="form-label">
                      Aadhar Number<span className="asterisk">*</span>
                    </label>
                    <InputMask
                      mask="9999 9999 9999"
                      className="form-control"
                      placeholder="Enter Aadhar Number"
                      name="aadhar_card"
                      value={input?.aadhar_card}
                      onChange={handleInputChange}
                    />
                    {errors.aadhar_card && (
                      <span className="error">{errors.aadhar_card}</span>
                    )}
                  </div>

                  <div className="col-md-6 form-group col-sm-6">
                    <label className="form-label">
                      Pan Number<span className="asterisk">*</span>
                    </label>

                    <input
                      type="text"
                      className={
                        input?.pan_card
                          ? "form-control text-uppercase rounded-3"
                          : "form-control rounded-3"
                      }
                      placeholder="Enter Pan Number"
                      name="pan_card"
                      value={input.pan_card}
                      onChange={handleInputChange}
                    />
                    {errors.pan_card && (
                      <span className="" style={{ color: "red", fontSize: "13px" }}>
                        {errors.pan_card}
                      </span>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-3 form-group col-sm-6">
                    <label className="form-label">
                      Upload Aadhar<span className="asterisk">*</span>
                    </label>
                    <input
                      type="file"
                      className="form-control rounded-3"
                      name="aadhar_front_image"
                      onChange={handleInputChange}
                    />
                    {errors.aadhar_front_image && (
                      <span className="" style={{ color: "red", fontSize: "13px" }}>
                        {errors.aadhar_front_image}
                      </span>
                    )}
                    <div className="mt-2">
                      {input?.aadhar_front_image !== null && (
                        <div className="d-flex justify-content-end">
                          <button className="img-close-btn" type="button">
                            <IoClose
                              size={24}
                              onClick={() => setInput({ ...input, aadhar_front_image: null })}
                            />
                          </button>
                        </div>
                      )}
                      {input?.aadhar_front_image ?
                        <div className="form-group mt-2">
                          <img className="aadhar-example-img"
                            src={input?.aadhar_front_image instanceof Blob
                              ? URL.createObjectURL(input?.aadhar_front_image)
                              : `${baseUrls?.pan_aadhar_image_url}/${input?.aadhar_front_image}`} alt="Aadharimg1" />
                        </div>
                        :
                        <div className="form-group mt-2">
                          <img className="aadhar-example-img" src={AadharExample} alt="Aadharimg1" />
                        </div>
                      }
                    </div>
                  </div>

                  {/* <div className="col-md-3 form-group col-sm-6">
                    <label className="form-label">
                      Upload Aadhaar Back<span className="asterisk">*</span>
                    </label>
                    <input
                      type="file"
                      className="form-control rounded-3"
                      name="aadhar_back_image"
                      onChange={handleInputChange}
                    />
                    {errors.aadhar_back_image && (
                      <span className="" style={{ color: "red", fontSize: "13px" }}>
                        {errors.aadhar_back_image}
                      </span>
                    )}

                    <div className="mt-2">
                      {input?.aadhar_back_image && (
                        <div className="form-group">
                          <img
                            className="document-img"
                            src={
                              input?.aadhar_back_image instanceof Blob
                                ? URL.createObjectURL(input?.aadhar_back_image)
                                : `https://ringus.idea2reality.tech/storage/app/public/pan_aadhar_image/${input?.aadhar_back_image}`
                            }
                            alt="Aadharimg2"
                          />
                        </div>
                      )}
                    </div>
                  </div> */}

                  <div className="col-md-3 form-group col-sm-6">
                    <label className="form-label">
                      Upload Pan<span className="asterisk">*</span>
                    </label>
                    <input
                      type="file"
                      className="form-control rounded-3"
                      name="pan_front_image"
                      onChange={handleInputChange}
                    />
                    {errors.pan_front_image && (
                      <span className="" style={{ color: "red", fontSize: "13px" }}>
                        {errors.pan_front_image}
                      </span>
                    )}

                    <div className="mt-2">
                      {input?.pan_front_image !== null && (
                        <div className="d-flex justify-content-end">
                          <button className="img-close-btn" type="button">
                            <IoClose
                              size={24}
                              onClick={() => setInput({ ...input, pan_front_image: null })}
                            />
                          </button>
                        </div>
                      )}
                      {input?.pan_front_image ?
                        <div className="form-group mt-2">
                          <img className="aadhar-example-img" src={input?.pan_front_image instanceof Blob
                            ? URL.createObjectURL(input?.pan_front_image)
                            : `${baseUrls?.pan_aadhar_image_url}/${input?.pan_front_image}`
                          } alt="Panimg1" />
                        </div>
                        :
                        <div className="form-group mt-2">
                          <img className="aadhar-example-img"
                            src={PanExample} alt="Panimg1" />
                        </div>
                      }
                    </div>
                  </div>

                  {/* <div className="col-md-3 form-group col-sm-6">
                    <label className="form-label">
                      Upload Pan Back<span className="asterisk">*</span>
                    </label>
                    <input
                      type="file"
                      className="form-control rounded-3"
                      name="pan_back_image"
                      onChange={handleInputChange}
                    />
                    {errors.pan_back_image && (
                      <span className="" style={{ color: "red", fontSize: "13px" }}>
                        {errors.pan_back_image}
                      </span>
                    )}
                    <div className="mt-2">
                      {input?.pan_back_image && (
                        <div className="form-group">
                          <img
                            className="document-img"
                            src={
                              input?.pan_back_image instanceof Blob
                                ? URL.createObjectURL(input?.pan_back_image)
                                : `https://ringus.idea2reality.tech/storage/app/public/pan_aadhar_image/${input?.pan_back_image}`
                            }
                            alt="Panimg2"
                          />
                        </div>
                      )}
                    </div>
                  </div> */}
                </div>
              </div>
              : <div>
                <div className="row">
                  <h5 className="mt-3 form-section-heading">
                    Company
                    {/* <span className="asterisk">*</span> */}
                  </h5>

                  <div className="col-md-3 form-group col-sm-6">
                    <label className="form-label">
                      GST Number<span className="asterisk">*</span>
                    </label>

                    <input
                      type="text"
                      className={
                        input?.gst_number
                          ? "form-control text-uppercase rounded-3"
                          : "form-control rounded-3"
                      }
                      placeholder="Enter GST Number"
                      name="gst_number"
                      value={input?.gst_number}
                      onChange={handleInputChange}
                    />
                    {errors.gst_number && (
                      <span className="" style={{ color: "red", fontSize: "13px" }}>
                        {errors.gst_number}
                      </span>
                    )}
                  </div>

                  <div className="col-md-3 form-group col-sm-6">
                    <label className="form-label">
                      CIN Number<span className="asterisk">*</span>
                    </label>

                    <input
                      type="text"
                      className={
                        input?.cin_number
                          ? "form-control text-uppercase rounded-3"
                          : "form-control rounded-3"
                      }
                      placeholder="Enter CIN Number"
                      name="cin_number"
                      value={input?.cin_number}
                      onChange={handleInputChange}
                    />
                    {errors.cin_number && (
                      <span className="" style={{ color: "red", fontSize: "13px" }}>
                        {errors.cin_number}
                      </span>
                    )}
                  </div>


                  <div className="col-md-3 form-group col-sm-6">
                    <label className="form-label">
                      Pan Number<span className="asterisk">*</span>
                    </label>
                    <input
                      type="text"
                      className={
                        input?.pan_card
                          ? "form-control text-uppercase rounded-3"
                          : "form-control rounded-3"
                      }
                      placeholder="Enter Pan Number"
                      name="pan_card"
                      value={input.pan_card}
                      onChange={handleInputChange}
                    />
                    {errors.pan_card && (
                      <span className="" style={{ color: "red", fontSize: "13px" }}>
                        {errors.pan_card}
                      </span>
                    )}
                  </div>
                </div>

                <div className="row">

                  <div className="col-md-3 form-group col-sm-6">
                    <label className="form-label">
                      GST Document<span className="asterisk">*</span>
                    </label>
                    <input
                      type="file"
                      className="form-control rounded-3"
                      name="gst_file"
                      onChange={handleInputChange}
                    />
                    {errors.gst_file && (
                      <span className="" style={{ color: "red", fontSize: "13px" }}>
                        {errors.gst_file}
                      </span>
                    )}

                    <div className="mt-2">
                      {input?.gst_file !== null && (
                        <div className="d-flex justify-content-end">
                          <button className="img-close-btn" type="button">
                            <IoClose
                              size={24}
                              onClick={() => setInput({ ...input, gst_file: null })}
                            />
                          </button>
                        </div>
                      )}
                      {input?.gst_file && (
                        <div className="form-group mt-2">
                          <img
                            className="document-img"
                            src={
                              input?.gst_file instanceof Blob
                                ? URL.createObjectURL(input?.gst_file)
                                : `${baseUrls?.gst_cin_image_url}/${input?.gst_file}`
                            }
                            alt="Panimg1"
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-3 form-group col-sm-6">
                    <label className="form-label">
                      CIN Document<span className="asterisk">*</span>
                    </label>
                    <input
                      type="file"
                      className="form-control rounded-3"
                      name="cin_file"
                      onChange={handleInputChange}
                    />
                    {errors.cin_file && (
                      <span className="" style={{ color: "red", fontSize: "13px" }}>
                        {errors.cin_file}
                      </span>
                    )}

                    <div className="mt-2">
                      {input?.cin_file !== null && (
                        <div className="d-flex justify-content-end">
                          <button className="img-close-btn" type="button">
                            <IoClose
                              size={24}
                              onClick={() => setInput({ ...input, cin_file: null })}
                            />
                          </button>
                        </div>
                      )}
                      {input?.cin_file && (
                        <div className="form-group mt-2">
                          <img
                            className="document-img"
                            src={
                              input?.cin_file instanceof Blob
                                ? URL.createObjectURL(input?.cin_file)
                                : `${baseUrls?.gst_cin_image_url}/${input?.cin_file}`
                            }
                            alt="Panimg1"
                          />
                        </div>
                      )}
                    </div>
                  </div>


                  <div className="col-md-3 form-group col-sm-6">
                    <label className="form-label">
                      Upload Pan<span className="asterisk">*</span>
                    </label>
                    <input
                      type="file"
                      className="form-control rounded-3"
                      name="pan_front_image"
                      onChange={handleInputChange}
                    />
                    {errors.pan_front_image && (
                      <span className="" style={{ color: "red", fontSize: "13px" }}>
                        {errors.pan_front_image}
                      </span>
                    )}

                    <div className="mt-2">
                      {input?.pan_front_image !== null && (
                        <div className="d-flex justify-content-end">
                          <button className="img-close-btn" type="button">
                            <IoClose
                              size={24}
                              onClick={() => setInput({ ...input, pan_front_image: null })}
                            />
                          </button>
                        </div>
                      )}

                      {input?.pan_front_image ?
                        <div className="form-group mt-2">
                          <img
                            className="document-img"
                            src={
                              input?.pan_front_image instanceof Blob
                                ? URL.createObjectURL(input?.pan_front_image)
                                : `${baseUrls?.pan_aadhar_image_url}/${input?.pan_front_image}`
                            }
                            alt="Panimg1"
                          />
                        </div>
                        :
                        <div className="form-group mt-2">
                          <img className="aadhar-example-img"
                            src={PanExample} alt="Panimg1" />
                        </div>
                      }



                    </div>
                  </div>

                  {/* <div className="col-md-3 form-group col-sm-6">
                    <label className="form-label">
                      Upload Pan Back<span className="asterisk">*</span>
                    </label>
                    <input
                      type="file"
                      className="form-control rounded-3"
                      name="pan_back_image"
                      onChange={handleInputChange}
                    />
                    {errors.pan_back_image && (
                      <span className="" style={{ color: "red", fontSize: "13px" }}>
                        {errors.pan_back_image}
                      </span>
                    )}
                    <div className="mt-2">
                      {input?.pan_back_image && (
                        <div className="form-group">
                          <img
                            className="document-img"
                            src={
                              input?.pan_back_image instanceof Blob
                                ? URL.createObjectURL(input?.pan_back_image)
                                : `https://ringus.idea2reality.tech/storage/app/public/pan_aadhar_image/${input?.pan_back_image}`
                            }
                            alt="Panimg2"
                          />
                        </div>
                      )}
                    </div>
                  </div> */}
                </div>
              </div>
            }

            <div className="row">
              <div className="col-md-3 form-group col-sm-6">
                <label className="form-label">Facebook Link</label>
                <input
                  type="text"
                  className="form-control rounded-3"
                  placeholder="Enter Facebook Link"
                  name="facebook_link"
                  value={input?.facebook_link}
                  onChange={handleInputChange}
                />
                {errors.facebook_link && (
                  <span className="" style={{ color: "red", fontSize: "13px" }}>
                    {errors.facebook_link}
                  </span>
                )}
              </div>
              <div className="col-md-3 form-group col-sm-6">
                <label className="form-label">Instagram Link</label>
                <input
                  type="text"
                  className="form-control rounded-3"
                  placeholder="Enter Instagram Link"
                  name="instagram_link"
                  value={input.instagram_link}
                  onChange={handleInputChange}
                />
                {errors.instagram_link && (
                  <span className="" style={{ color: "red", fontSize: "13px" }}>
                    {errors.instagram_link}
                  </span>
                )}
              </div>

              <div className="col-md-3 form-group col-sm-6">
                <label className="form-label">WebSite Link</label>
                <input
                  type="text"
                  className="form-control rounded-3"
                  placeholder="Enter WebSite Link"
                  name="website_link"
                  value={input?.website_link}
                  onChange={handleInputChange}
                />
                {errors.website_link && (
                  <span className="" style={{ color: "red", fontSize: "13px" }}>
                    {errors.website_link}
                  </span>
                )}
              </div>
            </div>

            <div className="row">
              <h5 className="mt-3 form-section-heading">Address</h5>
              <div className="form-group col-md-3 col-sm-6 mt-2">
                <label className="form-label">
                  Pin Code<span className="asterisk">*</span>
                </label>
                <input
                  type="number"
                  value={input.zip_code}
                  name="zip_code"
                  onChange={(e) => handlePinCodeChange(e.target.value)}
                  className="form-control"
                  placeholder="Pin Code"
                />
                {errors.zip_code && (
                  <span className="error">{errors.zip_code}</span>
                )}
              </div>
              <div className="form-group col-md-3 col-sm-6 mt-2">
                <label className="form-label">
                  Country<span className="asterisk">*</span>
                </label>
                <select
                  className="form-control"
                  name="country_id"
                  onChange={handleCountryChange}
                >
                  <option value="" selected>
                    Select Country
                  </option>
                  {
                    <>
                      {countries &&
                        countries?.map((val) => {
                          return (
                            <>
                              <option
                                value={val?.country_id}
                                selected={
                                  Object.keys(zip_country).length == 0
                                    ? val.country_id == input.country_id
                                    : zip_country?.long_name ==
                                    val?.country_name
                                }
                              >
                                {val?.country_name}
                              </option>
                            </>
                          );
                        })}
                    </>
                  }
                </select>
                {errors.country_id && (
                  <span className="error">{errors.country_id}</span>
                )}
              </div>

              <div className="form-group col-md-3 col-sm-6 mt-2">
                <label className="form-label">
                  State<span className="asterisk">*</span>
                </label>
                <select
                  className="form-control"
                  name="state_id"
                  onChange={handleStateChange}>
                  <option value="" selected>
                    Select State
                  </option>
                  {states &&
                    states?.map((val) => {
                      return (
                        <>
                          <option
                            value={val?.state_id}
                            selected={
                              Object.keys(zip_state).length == 0
                                ? val.state_id == input.state_id
                                : zip_state?.long_name == val?.state_name
                            }>
                            {val?.state_name}
                          </option>
                        </>
                      );
                    })}
                </select>
                {errors.state_id && (
                  <span className="error">{errors.state_id}</span>
                )}
              </div>

              <div className="form-group col-md-3 col-sm-6 mt-2">
                <label className="form-label">
                  City<span className="asterisk">*</span>
                </label>
                <select
                  className="form-control"
                  name="city_id"
                  onChange={handleCityChange}>
                  <option selected>Select City</option>
                  {cities &&
                    cities?.map((val) => {
                      if (val.city_id == input.city_id) {
                        return (
                          <>
                            <option value={input?.city_id} selected>
                              {val?.city_name}
                            </option>
                          </>
                        );
                      }
                    })}
                  {cities?.map((val) => {
                    return (<>
                      <option value={val?.city_id}
                        selected={
                          Object.keys(zip_city).length == 0
                            ? val.city_id == input.city_id
                            : zip_city?.long_name == val?.city_name}>
                        {val?.city_name}
                      </option>
                    </>
                    );
                  })}
                </select>
                {errors.city_id && (
                  <span className="error">{errors.city_id}</span>
                )}
              </div>

              <div className="form-group col-md-3 col-sm-6">
                <label className="form-label">
                  Flat/House No., Building<span className="asterisk">*</span>
                </label>
                <input
                  type="text"
                  value={input.house_no}
                  name="house_no"
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Flat/House No., Building"
                />
                {errors.house_no && (
                  <span className="error">{errors.house_no}</span>
                )}
              </div>

              <div className="form-group col-md-6">
                <label className="form-label">
                  Area, Street, Sector, Village<span className="asterisk">*</span>
                </label>
                <input
                  type="text"
                  value={input.address}
                  name="address"
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Area, Street, Sector, Village"
                />
                {errors.address && (
                  <span className="error">{errors.address}</span>
                )}
              </div>

              <div className="form-group col-md-3 col-sm-6">
                <label className="form-label">
                  Landmark
                  {/* <span className="asterisk">*</span> */}
                </label>

                <input
                  type="text"
                  value={input?.landmark}
                  name="landmark"
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="Landmark"
                />
                {errors.landmark && (
                  <span className="error">{errors.landmark}</span>
                )}
              </div>

              <div className="form-group col-md-3 col-sm-6">
                <label className="form-label">
                  Address Type<span className="asterisk">*</span>
                </label>
                <select
                  className="form-control"
                  name="address_type"
                  onChange={handleInputChange}>
                  <option value='' selected>Select Address Type</option>
                  <option value='Home' selected={input.address_type == 'Home'}>Home</option>
                  <option value='Office' selected={input.address_type == 'Office'}>Office</option>
                </select>
                {errors.address_type && (
                  <span className="error">{errors.address_type}</span>
                )}
              </div>
            </div>

            <div className="row mt-3">
              <div className="d-flex align-items-center gap-3">
                <label className="mb-0">
                  Do you want to add {input.address_type == "Office" ? "Home" : "Office"} Address?
                </label>
                <div className="d-flex gap-3">
                  <div className="form-check">
                    <label className="form-check-label m-0">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="1"
                        checked={addAddress == "1"}
                        name="addAddress"
                        onChange={(e) =>
                          setAddAddress(e?.target?.value)
                        }
                      />
                      Yes
                    </label>
                  </div>
                  <div className="form-check">
                    <label className="form-check-label m-0">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="0"
                        checked={addAddress == "0"}
                        name="addAddress"
                        onChange={(e) =>
                          setAddAddress(e?.target?.value)
                        }
                      />
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>

            {addAddress == "1" && (
              <div className="row">
                <h5 className="mt-3 form-section-heading">{input.address_type == "Office" ? "Home" : "Office"} Address</h5>
                <div className="form-group col-md-3 col-sm-6 mt-2">
                  <label className="form-label">
                    Pin Code<span className="asterisk">*</span>
                  </label>
                  <input
                    type="number"
                    value={input.other_zip_code}
                    name="other_zip_code"
                    onChange={(e) => handleOfficePinCodeChange(e.target.value)}
                    className="form-control"
                    placeholder="Pin Code"
                  />
                  {/* {errors.zip_code && (
                    <span className="error">{errors.zip_code}</span>
                  )} */}
                </div>
                <div className="form-group col-md-3 col-sm-6 mt-2">
                  <label className="form-label">
                    Country<span className="asterisk">*</span>
                  </label>
                  <select
                    className="form-control"
                    name="other_country_id"
                    onChange={handleOfficeCountryChange}
                  >
                    <option value="" selected>
                      Select Country
                    </option>
                    {
                      <>
                        {countries &&
                          countries?.map((val) => {
                            return (
                              <>
                                <option
                                  value={val?.country_id}
                                  selected={
                                    Object.keys(ohter_Zip_country).length == 0
                                      ? val.country_id == input.other_country_id
                                      : ohter_Zip_country?.long_name ==
                                      val?.country_name
                                  }
                                >
                                  {val?.country_name}
                                </option>
                              </>
                            );
                          })}
                      </>
                    }
                  </select>
                  {/* {errors.country_id && (
                    <span className="error">{errors.country_id}</span>
                  )} */}
                </div>

                <div className="form-group col-md-3 col-sm-6 mt-2">
                  <label className="form-label">
                    State<span className="asterisk">*</span>
                  </label>
                  <select
                    className="form-control"
                    name="other_state_id"
                    onChange={handleOfficeStateChange}>
                    <option value="" selected>
                      Select State
                    </option>
                    {otherStates &&
                      otherStates?.map((val) => {
                        return (
                          <>
                            <option
                              value={val?.state_id}
                              selected={
                                Object.keys(ohter_Zip_state).length == 0
                                  ? val.state_id == input.other_state_id
                                  : ohter_Zip_state?.long_name == val?.state_name
                              }>
                              {val?.state_name}
                            </option>
                          </>
                        );
                      })}
                  </select>
                  {/* {errors.state_id && (
                    <span className="error">{errors.state_id}</span>
                  )} */}
                </div>
                <div className="form-group col-md-3 col-sm-6 mt-2">
                  <label className="form-label">
                    City<span className="asterisk">*</span>
                  </label>
                  <select
                    className="form-control"
                    name="other_city_id"
                    onChange={handleOfficeCityChange}>
                    <option selected>Select City</option>
                    {otherCities &&
                      otherCities?.map((val) => {
                        if (val.other_city_id == input.other_city_id) {
                          return (
                            <>
                              <option value={input?.other_city_id} selected>
                                {val?.city_name}
                              </option>
                            </>
                          );
                        }
                      })}
                    {otherCities?.map((val) => {
                      return (<>
                        <option value={val?.city_id}
                          selected={
                            Object.keys(ohter_Zip_city).length == 0
                              ? val.city_id == input.other_city_id
                              : ohter_Zip_city?.long_name == val?.city_name}>
                          {val?.city_name}
                        </option>
                      </>
                      );
                    })}
                  </select>

                </div>

                <div className="form-group col-md-3 col-sm-6">
                  <label className="form-label">
                    Flat/House No., Building<span className="asterisk">*</span>
                  </label>
                  <input
                    type="text"
                    value={input.other_house_no}
                    name="other_house_no"
                    onChange={handleInputChange}
                    className="form-control"
                    placeholder="Flat/House No., Building"
                  />

                </div>

                <div className="form-group col-md-6">
                  <label className="form-label">
                    Area, Street, Sector, Village<span className="asterisk">*</span>
                  </label>
                  <input
                    type="text"
                    value={input.other_address}
                    name="other_address"
                    onChange={handleInputChange}
                    className="form-control"
                    placeholder="Area, Street, Sector, Village"
                  />

                </div>

                <div className="form-group col-md-3 col-sm-6">
                  <label className="form-label">
                    Landmark<span className="asterisk">*</span>
                  </label>

                  <input
                    type="text"
                    value={input.other_landmark}
                    name="other_landmark"
                    onChange={handleInputChange}
                    className="form-control"
                    placeholder="Landmark"
                  />

                </div>
              </div>
            )}
            {/* <div>
                {isLoaded ? (
                  <GoogleMap
                    mapContainerStyle={{height:"400px",width:"100%"}}
                    center={center}
                    zoom={13}
                    // onLoad={onLoad}
                    onUnmount={onUnmount}
                    onClick={handleMapClick}>
                    {marker && <Marker position={marker} />}
                  </GoogleMap>
                ) : (
                  ""
                )}
              </div> */}

            <div className="">
              <h4 className="mt-3 form-section-heading">About Me</h4>
              <div className="about-me-div gap-2 mt-2 ">
                <p className="">Meet</p>
                <div className="d-flex flex-column">
                  <input
                    className=""
                    value={input.f_name}
                    name="f_name"
                    placeholder="Name"
                    disabled
                    onChange={handleInputChange}
                  />
                  {/* {errors.host_name && (
                  <span className="error">{errors.host_name}</span>
                )} */}
                </div>

                <p>from</p>
                <div className="d-flex flex-column">
                  <input
                    className="address-input"
                    value={`${aboutAddress?.city ? aboutAddress?.city : ""}, ${aboutAddress?.state ? aboutAddress?.state : ""}`}
                    name="address"
                    placeholder="Location"
                    disabled
                    onChange={handleInputChange}
                  />
                  {/* {errors.host_location && (
                  <span className="error">{errors.host_location}</span>
                )} */}
                </div>,
                <p>a passionate </p>
                <div className="d-flex flex-column">
                  <select
                    className="aboutme-select-input"
                    name="profession"
                    onChange={handleInputChange}>
                    <option value="" selected>Select</option>
                    {professionData?.map((val, index) => (
                      <option
                        key={index}
                        value={val?.profession}
                        selected={input?.profession == val?.profession}>
                        {val?.profession}
                      </option>
                    ))}
                  </select>

                  {errors.profession && (
                    <span className="error">{errors.profession}</span>
                  )}
                </div>
                <p>in the</p>
                <div className="d-flex flex-column">
                  <select
                    className="aboutme-select-input"
                    name="industry"
                    onChange={handleInputChange}>
                    <option value="" selected>Select</option>
                    {listing?.industry?.map((val, index) => (
                      <option
                        key={index}
                        value={val?.industry}
                        selected={input?.industry == val?.industry}>
                        {val?.industry}
                      </option>
                    ))}
                  </select>
                  {errors.industry && (
                    <span className="error">{errors.industry}</span>
                  )}
                </div>,
                <p>I am</p>
                <div className="d-flex flex-column">
                  <select
                    className="aboutme-select-input"
                    name="marital_status"
                    onChange={handleInputChange}>
                    <option value="" selected>
                      Select
                    </option>
                    <option
                      value="married"
                      selected={input?.marital_status == "married"}>
                      Married
                    </option>
                    <option
                      value="single"
                      selected={input?.marital_status == "single"}>
                      Single
                    </option>
                    <option
                      value="divorced/separated"
                      selected={input?.marital_status == "divorced/separated"}>
                      Divorced / Separated
                    </option>

                    <option
                      value="widowed"
                      selected={input?.marital_status == "widowed"}>
                      Widowed
                    </option>

                    <option
                      value="prefer not to say"
                      selected={input?.marital_status == "prefer not to say"}>
                      Prefer not to say
                    </option>

                    <option
                      value="other"
                      selected={input?.marital_status == "other"}>
                      Other
                    </option>
                  </select>
                  {errors.marital_status && (
                    <span className="error">{errors.marital_status}</span>
                  )}
                </div>
                <p>living with</p>
                <div className="d-flex flex-column">
                  <input
                    className=""
                    value={input?.family_details}
                    name="family_details"
                    placeholder="Family Details"
                    onChange={handleInputChange}
                  />
                  {errors.family_details && (
                    <span className="error">{errors.family_details}</span>
                  )}
                </div>,
                <p>Educated in</p>
                <div className="d-flex flex-column">
                  {/* <select
                    className="aboutme-select-input"
                    name="education_background"
                    onChange={handleInputChange}>
                    <option value="" selected>Select</option>
                    {listing?.educational_background?.map((val) => (
                      <option
                        key={val?.id}
                        value={val?.educations}
                        selected={
                          input?.education_background == val?.educations
                        }
                      >
                        {val?.educations}
                      </option>
                    ))}
                  </select> */}
                  <input
                    className=""
                    value={input?.education_background}
                    name="education_background"
                    placeholder="Education Background"
                    onChange={handleInputChange}
                  />
                  {errors.education_background && (
                    <span className="error">{errors.education_background}</span>
                  )}
                </div>,
                <p>I am known for </p>
                <div className="d-flex flex-column">
                  <textarea
                    className=""
                    value={input?.known_for}
                    name="known_for"
                    placeholder="Known For"
                    cols={20}
                    onChange={handleInputChange}
                  >
                  </textarea>
                  {errors.known_for && (
                    <span className="error">{errors.known_for}</span>
                  )}
                </div>
                <p>and I use my </p>

                <div className="d-flex flex-column">
                  {/* <input
                    className=""
                    value={input?.tech_proficiency}
                    name="tech_proficiency"
                    placeholder="Tech Proficiency"
                    onChange={handleInputChange}
                  /> */}
                  <select
                    className="aboutme-select-input"
                    name="tech_proficiency"
                    disabled={profileinfo?.user_type}
                    onChange={handleInputChange}>
                    <option selected>Select</option>
                    {listing?.guest_technology_proficiency?.map((val) => (
                      <option
                        key={val?.id}
                        value={val?.proficiency_level}
                        selected={input?.tech_proficiency == val?.proficiency_level}>
                        {val?.proficiency_level}
                      </option>
                    ))}
                  </select>
                  {errors.tech_proficiency && (
                    <span className="error">{errors.tech_proficiency}</span>
                  )}
                </div>
                <p>to</p>
                <div className="d-flex flex-column">

                  <input
                    className=""
                    value={input?.industry_related_topics}
                    name="industry_related_topics"
                    placeholder="Industry-related Topics"
                    onChange={handleInputChange}
                  />
                  {errors.industry_related_topics && (
                    <span className="error">{errors.industry_related_topics}</span>
                  )}
                </div>,
                <p> I actively engage on Social Media Platforms with</p>

                <div className="d-flex flex-column">
                  {/* <textarea
                    value={input?.social_media_usage}
                    name="social_media_usage"
                    className=""
                    placeholder="Social Media Usage"
                    cols={20}
                    onChange={handleInputChange}
                  >
                  </textarea> */}
                  <select
                    className="aboutme-select-input"
                    name="social_media_usage"
                    disabled={profileinfo?.user_type}
                    onChange={handleInputChange}>
                    <option selected>Select</option>
                    {listing?.guests_social_media_usage?.map((val) => (
                      <option
                        key={val?.id}
                        value={val?.usage_level}
                        selected={input?.social_media_usage == val?.usage_level}>
                        {val?.usage_level}
                      </option>
                    ))}
                  </select>
                  {errors.social_media_usage && (
                    <span className="error">{errors.social_media_usage}</span>
                  )}
                </div>
                <p>Social Media Usage, discussing topics related to</p>
                <div className="form-group col-md-6">
                  <Select
                    defaultValue={[]}
                    isMulti
                    name="host_interests"
                    value={selectEdInterest}
                    options={predefineIntrest}
                    onChange={handleSelectIntrest}
                    classNamePrefix="Select"
                    placeholder="Select Interest"
                  />
                  {/* {errors.host_interests && (
                  <span className="error">{errors.host_interests}</span>
                )} */}
                </div>
              </div>
              <div className="mt-3">
                <div className="d-flex justify-content-between align-items-center">
                  <h4 className="form-heading mb-0">My Journey <span className="asterisk">*</span></h4>
                  <p className="Character-0500">
                    Character:<span className="text-style-1">
                      {/* remainingLength */}
                      {currentLength}/{maxLength}
                    </span>
                  </p>
                </div>
                <p className="reactquill-tooltip-p mb-1">(Note: Tell us about your event hosting experience! How many and what types of events have you hosted? Share key successes like how many people attended or
                  what the sponsors gained from them or if you're new, no worries! Just let us know how you plan to make your event awesome and valuable for sponsors. Sponsors are eager to hear your story!)</p>
                <div style={{ height: '200px' }}>
                  <ReactQuill
                    value={input?.my_journey}
                    onChange={handleMyjourny}
                    name="my_journey"
                    theme='snow'
                    style={{ height: '150px' }}
                  />
                </div>
                {errors.my_journey && (
                  <span className="error">{errors.my_journey}</span>
                )}
              </div>
            </div>



            <div className="d-flex justify-content-end align-items-center mt-4">
              <Link to="/profile" className="btn delete-event-btn">
                Back
              </Link>
              <div className="">
                <button
                  className="d-flex align-items-center gap-1  saveandcontinue-btn "
                  type="submit">
                  {btnLoading ? (
                    <div className="spinner-border text-light " role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    ""
                  )}
                  Save
                  <img className="arrow-img" src={arrow} alt="arrow" />
                </button>
              </div>
            </div>
          </form >
        </div >
      )}
    </>
  );
};
export default EditProfile;
