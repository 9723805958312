import { useEffect, useState, useMemo, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import { getChampHostList } from "../../utils/champ/champAlldata";
import Loader2 from "../Loader/Loader2";
import moment from "moment";
import ReactPaginate from "react-paginate";
import ReactStars from "react-rating-stars-component";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { MdVerified } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";
import { IoMdArrowDropdown } from "react-icons/io";
import linking from "../../assets/images/linking.png";

const HostList = ({
  currency,
  submitSearchValue,
  sortValue,
  setTotalCount,
}) => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currencyIcn, setCurrencyIcn] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [columnDefs, setColumnDefs] = useState([]);
  const [totalIncome,setTotalInCome]=useState("");
  const [dateDropdownOpen, setDateDropdownOpen] = useState(false);
  const [dateFilterValue, setDateFilterValue] = useState("");
  const [totalData,setTotalData]=useState({
    total_event_collection:"",
    total_events:"",
    total_my_income:"",
    total_sponsor:"",
    my_income:"",
  });

  const gridRef = useRef();

  const checkCurrency = (data) => {
    data?.map((val) => {
      if (val?.currency == "INR") {
        setCurrencyIcn("₹");
      } else if (val?.currency == "USD") {
        setCurrencyIcn("$");
      } else if (val?.currency == "EUR") {
        setCurrencyIcn("€");
      } else if (val?.currency == "GBP") {
        setCurrencyIcn("€");
      }
    });
  };

  const getData = async () => {
    setLoading(true);
    try {
      getChampHostList(submitSearchValue, sortValue, currentPage,dateFilterValue).then(
        (response) => {
          if (response?.data?.success) {
            setList(response?.data?.data);
            setTotalCount(response?.data?.data?.total);
            checkCurrency(response?.data?.data?.data);
            const TotalIncIs=response?.data?.my_income?.replace(/,/g, '');
            setTotalInCome(TotalIncIs)

            setTotalData({
              total_event_collection:response?.data?.total_event_collection,
              total_events:response?.data?.total_events,
              total_my_income:response?.data?.total_my_income,
              total_sponsor:response?.data?.total_sponsor,
              my_income:response?.data?.my_income,
            });

            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, [submitSearchValue, sortValue, currentPage,dateFilterValue]);

  const AvatarRenderer = ({ value, data }) => {
    return (
      <div className="">
       <Link to={`/profileview/${data?.id}`} state={{name:`${value}` }}>
          <img
            className="listcard-img object-fit-cover"
            src={
              !data?.image_type
                ? `https://sponsorringus.com/admin/storage/app/public/profile/${data?.image}`
                : data?.image_type == "browse"
                ? `https://sponsorringus.com/admin/storage/app/public/profile/${data?.image}`
                : `https://sponsorringus.com/admin/storage/app/public/avatar/${data?.predefine_image}`
            }
            alt="img"
          />
        </Link>
      </div>
    );
  };

  useEffect(() => {
    setColumnDefs([
      {
        headerName: "Image",
        field: "image",
        width: 100,
        cellRenderer: AvatarRenderer,
        filter: false,
      },
      
      {
        headerName: "UserName",
        field: "name",
        cellRenderer: ({ value, data }) => {
          return (<div className="d-flex gap-1 align-items-center">
            {/* <Link to={`/champEvent/${data?.id}`} state={{ name: `${value}` }}> */}
            <Link to={`/profileview/${data?.id}`} state={{name:`${value}` }}> 
              <p className="">{value ? value : "-"}</p>
            </Link>
             {data?.membership_plan_name == "Premier" ? <MdVerified color="#039be5" /> : ""}
             </div>
          );
        },
      },
      {
        headerName: "Host Rating",
        field: "host_rating",
        width: 140,
        cellRenderer: ({ value, data }) => {
          return (<div className="d-flex gap-1 align-items-center">
                   <ReactStars
                     count={5}
                     size={14}
                     edit={false}
                     //onChange={handleRatingChange}
                     value={parseInt(value)}
                     emptyIcon={<i className="far fa-star"></i>}
                     fullIcon={<i className="fa fa-star"></i>}
                     activeColor="#ffc601"
                     />
             </div>
          );
        }
      },
      { headerName: "Total Events", field: "event_count", width: 150,
        cellRenderer: ({ value, data }) => {
        return  <Link className="d-flex align-items-center gap-1" to={value == 0 ? `#` : `/champEvent/${data?.id}`} state={{name:`${data?.name}`}}>
         {value !== 0 && <img style={{height:12}} className="" src={linking}/>}  <p className="text-dark">{value}</p></Link> ;
      }},

      { headerName: "Sponsor Count", field: "sponsor_count", width: 150 },
      
      {
        headerName: "Total Event Collection",
        field: "event_collection",
        width: 150,
        cellRenderer: ({value,data}) => {
          const sponsoredAmount = data?.event_collection;
          const formattedAmount = sponsoredAmount
            ? `${
                currency?.currencySymbol
                  ? currency?.currencySymbol
                  : currencyIcn
              } ${ currency?.selectedCurrencyPrice
                  ? (currency?.selectedCurrencyPrice * sponsoredAmount).toFixed(2): sponsoredAmount
              }`
            : "-";
          return <p className="">{formattedAmount}</p>;
        },
        // valueFormatter: ({value, data }) => `${data.currency} ${data?.sponsored_collection}`
      },
      {
        headerName: `Income (${totalIncome ? `${currency?.currencySymbol ? currency?.currencySymbol:currencyIcn
            } ${
              currency?.selectedCurrencyPrice
                ? (currency?.selectedCurrencyPrice*totalIncome).toFixed(0)
                : totalIncome }` : 0})`,
        field: "total_commission",
        width: 180,
        cellRenderer: ({ value, data }) => {
          const signupCommision = data?.total_commission;
          const formattedAmount = signupCommision
            ? `${
                 currency?.currencySymbol
                  ? currency?.currencySymbol
                  : currencyIcn
              } ${
                 currency?.selectedCurrencyPrice
                  ? (currency?.selectedCurrencyPrice * signupCommision).toFixed(2)
                  : signupCommision
              }`  
            : 0;
          return <p className="">{formattedAmount}</p>;
        },
      },
      {headerName: "User Category", field: "membership_plan_name",width:150,
      cellRenderer: ({ value, data }) => {
      return <p className={value == 'Basic' ? "basic-membership-section": "premium-membership-section"}> {value ? value : "-"}</p>
      }},
      {
        headerName: "State",
        field: "state_name",
        width: 120,
        cellRenderer: ({ value, data }) => {
          return <p> {value ? value : "-"}</p>;
        },
      },
      {
        headerName: "City",
        field: "city_name",
        width: 120,
        cellRenderer: ({ value, data }) => {
          return <p> {value ? value : "-"}</p>;
        },
      },

      {
        headerName: "Status",
        width: 120,
        field: "is_active",
        cellRenderer: ({ value, data }) => {
          const checkActive = value;
          return checkActive ? (
            <p className="user-active-div">Active </p>
          ) : (
            <p className="user-inactive-div">Inactive</p>
          );
        },
      },
      {
        headerName: "Last Logged In",
        field: "last_activity",
        cellRenderer: ({ value, data }) => {
          const checkActive = value;
          return checkActive ? (
            moment(checkActive).format("ll hh:mm A")
          ) : (
            <p>-</p>
          );
        },
      },
      {
        headerName: "Last Activity",
        field: "event_activity",
        cellRenderer: ({ value, data }) => {
          const checkActive = value;
          return checkActive ? (
            moment(checkActive).format("ll hh:mm A")
          ) : (
            <p>-</p>
          );
        },
      },
      {
        headerName: "Date Of Joining",
        field: "created_at",
        cellRenderer: ({ value, data }) => {
          return moment(data?.created_at).format("ll hh:mm A");
        },
      },
      //Define other columns here
    ]);
  }, [currency, list?.data]);

  const defaultColDef = useMemo(() => {
    return {
      filter: "agTextColumnFilter",
      floatingFilter: true,
    };
  }, []);

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage?.selected + 1);
  };

  const onGridReady = useCallback((params) => {}, []);

  const onBtExport = useCallback(() => {
    gridRef.current.api.exportDataAsExcel();
  }, []);

  return (
    <>
    {
       loading ? <Loader2/>
    :
    <div>
    <div className="user-host-section">
    <div className="row">
      <div className="col-xl-3 col-sm-4">
            <button className='champ_dashboard-btn'>
              <div className='user-total-event'>
                <p className="total-hosts-text text-danger ">Total Events</p>
                <p className="total-hosts-data-text text-danger">{totalData?.total_events}</p>
              </div>
            </button>
          </div>

          <div className="col-xl-3 col-sm-4">
            <button className='champ_dashboard-btn'>
              <div className='user-total-event'>
                <p className="total-hosts-text text-danger ">Total Sponsored Events</p>
                <p className="total-hosts-data-text text-danger">{totalData?.total_sponsor}</p>
              </div>
            </button>
          </div>

          <div className="col-xl-3 col-sm-4">
            <button className='champ_dashboard-btn'>
              <div className='user-total-event'>
                <p className="total-hosts-text text-danger ">Total Collection</p>
                <p className="total-hosts-data-text text-danger">{totalData?.total_event_collection}</p>
              </div>
            </button>
          </div>

          <div className="col-xl-3 col-sm-4">
            <button className='champ_dashboard-btn'>
              <div className='user-total-event'>
                <p className="total-hosts-text text-danger ">Total Income</p>
                <p className="total-hosts-data-text text-danger">{totalData?.my_income}</p>
              </div>
            </button>
          </div>
      </div>
      </div>

    <div className="filter-section ">
         <div className=" d-flex align-items-center justify-content-end gap-2 ">
            {/* <p className="m-0 order-list-text">Filter:</p> */}
            <button
              className="d-flex order-dropdown-button"
              onClick={() => {setDateDropdownOpen(!dateDropdownOpen) }}>
              Filter
              <IoMdArrowDropdown size={24} />
            </button>

            {dateDropdownOpen && (
              <div className="dropdown-menu-list">
                
                <div
                  className="dropdown-item"
                  onClick={() => {setDateFilterValue("current_week");setDateDropdownOpen(false) }}>
                  Current Week
                </div>

                <div
                  className="dropdown-item"
                  onClick={() => {setDateFilterValue("last_week");setDateDropdownOpen(false) }}>
                  Last Week
                </div>

                <div
                  className="dropdown-item"
                  onClick={() => {setDateFilterValue("current_month");setDateDropdownOpen(false) }}>
                  Current Month
                </div>

                <div
                  className="dropdown-item"
                  onClick={() => {setDateFilterValue("last_month");setDateDropdownOpen(false) }}>
                  Last Month
                </div>

                <div
                  className="dropdown-item"
                  onClick={() => {setDateFilterValue("quarter");setDateDropdownOpen(false) }}>
                  Quarter
                </div>

                <div
                  className="dropdown-item"
                  onClick={() => {setDateFilterValue("last_6_months");setDateDropdownOpen(false) }}>
                  6 Months
                </div>
                <div
                  className="dropdown-item"
                  onClick={() => {setDateFilterValue("annual");setDateDropdownOpen(false) }}>
                   Annual
                </div>
              </div>
            )}
         <div className="d-flex justify-content-end">
          {dateFilterValue && (
            <div className="d-flex justify-content-end ">
              <div className="d-flex gap-2 align-items-center">
                <p className="m-0 order-list-text">Filter By :</p>
                <div className="sort-clear-div ">
                  <p className="m-0 order-list-text text-light text-capitalize p-0">{dateFilterValue}</p>
                  <button
                    className="sort-clear-btn"
                    onClick={() => setDateFilterValue("")}>
                    <IoCloseOutline color="white" size={20} />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        </div>
       </div>
      <button className="export-to-excel-btn" onClick={onBtExport}>
        Export to Excel
      </button>
      <div className="ag-theme-alpine mt-3" style={{ height: 520, width: "100%" }}>
        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          columnDefs={columnDefs}
          rowData={list?.data}
          defaultColDef={defaultColDef}
          rowSelection="multiple"
                    // groupIncludeFooter={true} 
          // groupIncludeTotalFooter={true}
        ></AgGridReact>
      </div>

      {list?.data?.length < list?.total && (
        <div className="d-flex align-items-end pagination-container mt-1">
          <ReactPaginate
            pageCount={Math.ceil(list?.total / list?.per_page)}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            onPageChange={handlePageClick}
            containerClassName="pagination"
            activeClassName="selected"
            disabledClassName="disabled"
          />
        </div>
      )}
    </div>
    }

      {/* <div className="col-12">
                <div>
                    <div className="list-head-div">
                        <div className="row d-flex align-items-center">
                            <div className="col-md-1">
                                <p className="order-head-text">Image</p>
                            </div>
                            <div className="col-md-2">
                                <p className="order-head-text">Name</p>
                            </div>

                            <div className="col-md-2">
                                <p className="order-head-text">Total Events</p>
                            </div>
                            <div className="col-md-2">
                                <p className="order-head-text">Total Collection</p>
                            </div>
                            <div className="col-md-2">
                                <p className="order-head-text">Total Commision</p>
                            </div>
                            <div className="col-md-1">
                                <p className="order-head-text">Date</p>
                            </div>
                        </div>
                    </div>

                    <div className="champ-list-main-div">
                        {loading ? <Loader2 /> :
                            list?.data && list?.data?.map((item, index) => (
                                <div className="mt-2" key={index}>
                                    <div className="listcard-div d-grid">
                                        <div className="row align-items-center">
                                            <div className="col-md-1">
                                                <Link to={`/champEvent/${item.id}`} state={{ name: `${item?.f_name}` }}>

                                                    <img className="listcard-img object-fit-cover" src={!item?.image_type ?
                                                        `https://ringus.idea2reality.tech/storage/app/public/profile/${item?.image}` :
                                                        item?.image_type == "browse" ?
                                                            `https://ringus.idea2reality.tech/storage/app/public/profile/${item?.image}` :
                                                            `https://ringus.idea2reality.tech/storage/app/public/avatar/${item?.predefine_image}`
                                                    } alt="img" />

                                                </Link>
                                            </div>
                                            <div className="col-md-2" title={`${item?.f_name} ${item?.l_name}`}>
                                                <Link
                                                    to={`/champEvent/${item?.id}`} state={{ name: `${item?.f_name}` }} className="text-black">{item?.f_name} {item?.l_name}</Link>
                                            </div>


                                            <div className="col-md-2">
                                                <p className="listcard-title">{item?.event_count}</p>
                                            </div>

                                            <div className="col-md-2">
                                                <p className="listcard-title">{item?.event_collection ? `${currency?.currencySymbol ? currency?.currencySymbol : currencyIcn} 
                                            ${currency?.selectedCurrencyPrice ? (currency?.selectedCurrencyPrice * item?.event_collection).toFixed(2) : item?.event_collection} ` : '-'} </p>
                                            </div>
                                            <div className="col-md-2">
                                                <p className="listcard-title">{item?.total_commission ? `${currency?.currencySymbol ? currency?.currencySymbol : currencyIcn} 
                                            ${currency?.selectedCurrencyPrice ? (currency?.selectedCurrencyPrice * item?.total_commission).toFixed(2) : item?.total_commission}` : '-'} </p>
                                            </div>

                                            <div className="col-md">
                                                <p className="order-list-text">
                                                    {moment(item?.created_at).format("ll hh:mm A")}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className="list-head-div">
                        <p className="text-white">Total Number Hosts : {list?.total}</p>
                    </div>
                </div>
            </div>

            {list?.data?.length < list?.total && (
                <div className="d-flex align-items-end pagination-container mt-1">
                    <ReactPaginate
                        pageCount={Math.ceil(list?.total / list?.per_page)}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName="pagination"
                        activeClassName="selected"
                        disabledClassName="disabled"
                    />
                </div>
             )} */}
    </>
  );
};
export default HostList;
