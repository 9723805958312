import React from 'react';

 const IsHostSponsorPopup = ({ type, isShow, setIsShow }) => {
    return (<>
            { isShow &&
                <div className="popup-container-host">
                  <div className="modal-host" tabIndex="-1">
                    <div className="modal-dialog-host">
                        <div className="modal-content-host">
                            <div className="modal-header-host">
                                <h5 className="modal-title-host">Thank you for trying this option!</h5>
                            </div>
                                <div className="modal-body-host">
                                  Thank you for trying this option! To avail this feature you need to become a {type}.
                                  {/* from edit profile section. */}
                                </div>
                              <div className="modal-footer-host">
                              {/*<button className="btn btn-danger-host" onClick={()=>setIsShow(false)}>Close</button>*/}
                              <button className="btn btn-success-host" onClick={() => setIsShow(false)}>Ok</button>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
            }
        </>)
    }

 export default IsHostSponsorPopup;