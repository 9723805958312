const NewUserSignupPopup = () => {

    return (
        <div>

            <div className="newuser-popup-overlay">
                <div className="newuser-popup-content" onClick={(e) => e.stopPropagation()}>
                    <h2>Popup Title</h2>
                    <p>This is a basic, responsive popup.</p>
                    <button className="newuser-close-popup-button">
                        Close
                    </button>
                </div>
            </div>
        </div>
    )
}
export default NewUserSignupPopup