import { useNavigate } from "react-router-dom";


const FeatureYourEventPopup=({setFeatureCardShow,setShowPaymentCardMdl,event_id})=>{
  const navigate = useNavigate();


  const handleFeatureayCardCancel=()=>{
    setFeatureCardShow(false)
    // event_id && navigate(`/preview/${event_id}`,{state:"CreateEvent" });
  }

  const handleFeatureayCard=async ()=>{
    setShowPaymentCardMdl(true);
    setFeatureCardShow(false)
  }

    return(<>
        <div className="warningModal">
          <div className="warning-mdl">
            {/* <span className="warning-close-btn" >&times;</span> */}
            <div className="d-flex justify-content-center mb-3">
              {/* <IoWarningOutline color="#ffcc00" size={40} /> */}
            </div>
            <h4 className="text-center warning-text">
               Do you want to feature your event?
            </h4>
            <div className="d-flex gap-2 justify-content-end">
            <button className="cancel-champ-btn mt-2"
              onClick={handleFeatureayCardCancel}>
              No
            </button>
            <button className="warning-mdl-btn mt-2"
              onClick={handleFeatureayCard}>
              Yes
            </button>
            </div>
          </div>
        </div>  
    </>)
};

export default FeatureYourEventPopup;