import { IoWarningOutline } from "react-icons/io5";


const MarkAsWarningMdl=({setMarkasWarningMdl})=>{
  return(<>
        <div className="warningModal">
          <div className="warning-mdl">
            {/* <span className="warning-close-btn" >&times;</span> */}
            <div className="d-flex justify-content-center mb-3">
              <IoWarningOutline color="#ffcc00" size={40} />
            </div>
            <h4 className="text-center warning-text">
              This Button Enable In Event End Date Complete
            </h4>
            <div className="d-flex justify-content-end">
              <button
                className="warning-mdl-btn mt-2"
                onClick={()=>setMarkasWarningMdl(false)}>
                Ok
              </button>
            </div>
          </div>
        </div>
  </>)
};

export default MarkAsWarningMdl;