import { IoClose } from "react-icons/io5";
import { BiSolidDonateHeart } from "react-icons/bi";
import downarrrow from "../../../assets/images/ic-downarrow.png";
import uparrow from "../../../assets/images/ic-uparrow.png";
import { useState } from "react";
import Documenticon from "../../../assets/images/document-upload-icon.svg";
import pdfimg from "../../../assets/images/pdf.png";
import { MdDelete } from "react-icons/md";
import { useEffect } from "react";
// import { Link } from "react-router-dom";
// import { isTokenValid } from "../../AuthbyToken";
import { ToastContainer, toast } from "react-toastify";
import Loader2 from "../../Loader/Loader2";
import moment from "moment";
import { Get_Markas_Complete, Markas_Complete_Detail } from "../../../utils/myevent/MyEvent";
// import 'react-toastify/dist/ReactToastify.css';
// import EditPackagesModelForm from "../EditEventForm/EditPackagesModelForm";
// import moment from "moment";
// import DateFormat from "../Common/DateFormat";
// import IsHostSponsorPopup from "../Common/IsHostSponsorPopup";

const MarkasCompleteForm = ({
  markasLoading,
  markasEventData,
  setMarkasFormMdl,
  setMarkasLoading,
  markasCompleteData,
  currency,
  setMarkasCompleteData
}) => {
  const [packageDropdown, setPackageDropdown] = useState(false);
  const [sponsorDrop, setSponsorDrop] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [multipleVideoLink, setMultipleVideoLink] = useState([]);
  const [currencyIcn, setCurrencyIcn] = useState("");
  const [fData, setFData] = useState({
    descriptions: "",
    documents: [],
  });
  const [errors, setErrors] = useState({});

  const checkCurrency = (data) => {
    data?.map((val) => {
      if (val?.currencies?.code == "INR") {
        setCurrencyIcn("₹");
      } else if (val?.currencies?.code == "USD") {
        setCurrencyIcn("$");
      } else if (val?.currencies?.code == "EUR") {
        setCurrencyIcn("€");
      } else if (val?.currencies?.code == "GBP") {
        setCurrencyIcn("€");
      }
    });
  };

  const checkFormData = (data) => {
     data?.map((val) => {
      val?.payment_transaction?.map((val) => {
          setFData({
            descriptions:val?.mark_as_complete_details?.descriptions ? val?.mark_as_complete_details?.descriptions :"",
            // documents: val?.mark_as_complete_docs,
            documents:[]
          });
        });
    });
  };


  useEffect(() => {
    checkCurrency(markasCompleteData);
    // checkFormData(markasCompleteData)
  },[markasCompleteData]);

  const handleFileChangeDocument = (event) => {
    const files = event.target.files;
    const documentArray = [];

    // if (documents.length + files.length > 3) {
    //   toast.warn("You can only add up to 3 documents", {
    //     position: "top-center",
    //     autoClose: 3000,
    //     hideProgressBar: false,
    //     theme: "light",
    //   });
    //   return;
    // }

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      documentArray.push(file);
    }
    setDocuments([...documents, ...documentArray]);
  };

  const handleRemoveDocument = (idx) => {
    const val = documents.filter((item, index) => index !== idx);
    setDocuments(val);
  };

  const validateForm = () => {
    const errors = {};

    if (!fData?.descriptions) {
      errors.description = "Descriptions is required";
    }

    if (documents.length == 0) {
      errors.documents = "Please add at least one document.";
    }

    setErrors(errors);
    return Object.keys(errors)?.length === 0;
  };

  const handleFormSubmit=async(e,id)=>{
    e.preventDefault();
    const formdata={
       sponsor_id:id,
       documents:documents,
       descriptions:fData?.descriptions
    }

    // console?.log("fData",formdata) 
    if (validateForm()) {
      try{
        const response = await Markas_Complete_Detail("417",formdata)
        console.log("resss",response)
        try{
          setMarkasLoading(true);
          const response = await Get_Markas_Complete('417')
          //console.log("ress",response?.data?.packages)
          setMarkasCompleteData(response?.data?.packages)
          }catch(err){
          console.log(err)
        }finally{
          setMarkasLoading(false);
        }
      }catch(err){
        console.log("err",err)
      }
    }
  }
    
  return (
    <>
      <div className="warningModal">
        <div className="markasForm-mdl position-relative">
          <h4 className="text-capitalize">{markasEventData?.title}</h4>

          <button
            className="terms-mdl-close-btn"
            onClick={() => setMarkasFormMdl(false)}>
            <IoClose size={24} />
          </button>

          <div className="termsandprivacypolicy-content">
            {markasLoading ? (
              <Loader2 />
              ) : (
              <div className={`marksas-card ${packageDropdown ? "open" : ""}`}>
                {markasCompleteData?.map((val) => {
                  return (
                    <>
                      <button
                        className="card-drop-btn"
                        onClick={() => setPackageDropdown(!packageDropdown)}>
                        <div className="d-flex justify-content-between pe-3 cursor-pointer">
                          <div className="d-flex gap-2 align-items-center">
                            <div className="d-flex align-items-center gap-3">
                              <h5 className="pack-card-heading text-capitalize p-0 m-0">
                                {val?.title}
                              </h5>
                            </div>
                          </div>
                          <div>
                            <img
                              src={packageDropdown ? uparrow : downarrrow}
                              alt="img"
                            />
                          </div>
                        </div>
                      </button>

                      {/* Package Detail  */}
                      <div className="mt-4">
                        <hr className="w-100"/> 
                        <h4 className="markus-mdl-head">Package Detail</h4>
                        <div>
                          <p className="col-md-5 pack-price-text d-flex ">
                            {currency?.currencySymbol
                              ? currency?.currencySymbol
                              : currencyIcn}
                            {currency?.selectedCurrencyPrice
                              ? Math.ceil(val?.amount * currency?.selectedCurrencyPrice)
                              : Math.ceil(val?.amount)}
                            {/* 500 */}
                          </p>
                        </div>

                        <div className="row">
                          <p className="col-md-7 pack-content-text m-0">Last Date</p>
                          <p className="col-md-5 pack-bold-text m-0">{moment(val?.cut_off_date).format("ddd, DD MMM YYYY")}</p>
                        </div>

                        <div className="row">
                          <p className="col-md-7 pack-content-text m-0">
                            Available Packages:
                          </p>
                          <p className="col-md-5 pack-bold-text m-0">
                            {val?.available_package}
                          </p>
                        </div>
                        <div className="row">
                          <p className="col-md-7 pack-content-text">
                            No of Guest:
                          </p>
                          <p className="col-md-5 pack-bold-text">{val?.guests_covered}</p>
                          {/* <p className="col-md-5 pack-bold-text">{val?.number_of_guests}</p> */}
                        </div>

                        <div
                          className="pack-content-text"
                          // dangerouslySetInnerHTML={{ __html: val?.discription }}
                        ></div>
                      </div>

                     <h4 className="markus-mdl-head m-0 p-0 mt-3">Sponsors</h4>                     
                      {val?.payment_transaction?.map((val) => {
                        return (
                          <>
                            <div className="drop-content mt-3">
                              {/* package detail */}
                              <div className="">
                                <div className="sponsor-content-div">
                                    <div className="d-flex justify-content-between p-3">
                                      <div>
                                        <p className="filter-heading mb-0">
                                          {val?.users?.name}
                                        </p>
                                      </div>
                                    </div>
                                     {
                                        val?.mark_as_complete_details?.mark_as_complete_docs ? 
                                        val?.mark_as_complete_details?.mark_as_complete_docs.map((file, index) =>{
                                          return (
                                          <div className="px-3 pb-2 col-3" key={index}>
                                            <label className="form-label mb-1 ">Docs</label>
                                              <div className="markas-pdf-div position-relative">
                                                <img
                                                  className="markas-pdf-img"
                                                  src={pdfimg}
                                                  alt="pdf"/>
                                                <p className="pdf-title">
                                                  {file?.file_name}
                                                </p>
                                              </div>
                                            </div>
                                          )})
                                        :  
                                      <div className="px-3">
                                        <label className="form-label">
                                          Add Docs<span className="asterisk">*</span>
                                        </label>
                                        <div className="">
                                          <div className="row mt-2">
                                            <div className="d-flex flex-column col-md-12">
                                              <label className="col-3">
                                                <img
                                                  className="markus-upload-docs"
                                                  src={Documenticon}
                                                  //accept=".pdf,.doc,.docx,.txt"
                                                  alt="img-upload" 
                                                  />
                                                <input
                                                  className="d-none"
                                                  type="file"
                                                  name="documents"
                                                  // value={documents}
                                                  multiple
                                                  onChange={handleFileChangeDocument}/>
                                              </label>
                                            </div>
                                            <div className="row">
                                            
                                           {documents?.map((file, index) =>{
                                                return (
                                                  <div
                                                    className="px-1 py-1 col-3 "
                                                    key={index}>
                                                   
                                                    <div className="markas-pdf-div position-relative">
                                                      <img
                                                        className="markas-pdf-img"
                                                        src={pdfimg}
                                                        alt="pdf"/>
                                                      <p className="pdf-title">{file?.name}</p>

                                                    <button className="image-remove-icon"
                                                      onClick={()=>handleRemoveDocument(index)}>
                                                      <MdDelete
                                                        size={20}
                                                        color="white" />
                                                    </button>
                                                    </div>
                                                  </div>
                                                )}
                                              )}
                                            </div>
                                            {errors.documents && (
                                              <span className="error">
                                                {errors.documents}
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      }
                                       <div className="p-3">
                                        <div className="">
                                         
                                        {
                                          val?.mark_as_complete_details?.descriptions ?
                                          <div>
                                          <label className="form-label m-0 ">
                                            Description
                                          </label>
                                          <p className="p-0 m-0 text-dark">{val?.mark_as_complete_details?.descriptions}</p>
                                          </div>
                                            :
                                          <div>
                                          <label className="form-label m-0">
                                           Description
                                          <span className="asterisk">
                                              *
                                          </span>
                                           </label>   
                                           <textarea
                                            className="form-control"
                                            type="text"
                                            name="description"
                                            placeholder="Enter Description"
                                            value={fData?.descriptions}
                                            onChange={(e)=>setFData({descriptions:e?.target?.value})}
                                            rows="2"
                                          ></textarea>
                                          {errors.description && (
                                            <span className="error">
                                              {errors.description}
                                            </span>
                                          )}
                                          </div>
                                          }    
                                        </div>
                                        {
                                          val?.mark_as_complete_details?.descriptions && val?.mark_as_complete_details?.mark_as_complete_docs ?
                                          "" :
                                          <div className="d-flex justify-content-end ">
                                          <button
                                            className="range-submit-btn mt-3"
                                            onClick={(e)=>handleFormSubmit(e,val?.users?.id)}>
                                            Submit
                                          </button>
                                        </div>
                                        }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </>
                  );
                })}

                {/* <hr className="w-100 mt-2 " /> */}
                {/* {dropdown ? "h-0" : ""} */}
              </div>
            )}
          </div>
          <div className="d-flex gap-2 justify-content-end"></div>
        </div>
      </div>
    </>
  );
};

export default MarkasCompleteForm;
