import { Link } from "react-router-dom";
import { getChampReferList } from "../../utils/champ/champAlldata";
import Loader2 from "../Loader/Loader2";
import { useEffect } from "react";
import { useState, useMemo , useCallback, useRef} from "react";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { AgGridReact } from "ag-grid-react";
// Import AG Grid styles
// import 'ag-grid-community/dist/styles/ag-grid.css';
// import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import 'ag-grid-enterprise';
import { MdVerified } from "react-icons/md";
import { IoMdArrowDropdown } from "react-icons/io";
import { IoCloseOutline } from "react-icons/io5";
import linking from "../../assets/images/linking.png";
import ReactStars from "react-rating-stars-component";

const UserList = ({
  currency,
  sortValue,
  submitSearchValue,
  setSubmitSearchValue,
  setTotalCount
}) => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currencyIcn, setCurrencyIcn] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [columnDefs, setColumnDefs] = useState([]);
  const [dateDropdownOpen, setDateDropdownOpen] = useState(false);
  const [dateFilterValue, setDateFilterValue] = useState("");
  const [totalData,setTotalData]=useState({
    total_event:"",
    total_event_manage_commission:"",
    total_my_income:"",
    total_received_collection:"",
    total_sign_up_commission:"",
    total_sponsor:"",
    total_sponsored_collection:"",
    total_sponsorship_commission:"",
    total_user:""
  });

  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);
  
  const checkCurrency = (data) => {
    data?.map((val) => {
      if (val?.currency == "INR") {
        setCurrencyIcn("₹");
      } else if (val?.currency == "USD") {
        setCurrencyIcn("$");
      } else if (val?.currency == "EUR") {
        setCurrencyIcn("€");
      } else if (val?.currency == "GBP") {
        setCurrencyIcn("€");
      }
    });
  };

  const AvatarRenderer = ({ value, data }) => {
    return <div className="">
       <Link to={`/profileview/${data?.id}`} state={{name:`${data?.f_name}` }}>
        <img
          className="listcard-img object-fit-cover"
          src={
            !data?.image_type
              ? `https://sponsorringus.com/admin/storage/app/public/profile/${data?.image}`
              : data?.image_type == "browse"
              ? `https://sponsorringus.com/admin/storage/app/public/profile/${data?.image}`
              : `https://sponsorringus.com/admin/storage/app/public/avatar/${data?.predefine_image}`
          }
          alt="img"
        />
      </Link>
    </div>;
  };

  const getData = async () => {
    try {
      setLoading(true);
      getChampReferList(submitSearchValue,sortValue,currentPage,dateFilterValue).then(
        (response) => {
          if (response?.data?.success) {
            // console.log("daaa",response?.data?.data)
            setList(response?.data?.data);
            setTotalCount(response?.data?.data?.total);
            checkCurrency(response?.data?.data?.data);
            setTotalData({
              total_event: response?.data?.total_event,
              total_event_manage_commission: response?.data?.total_event_manage_commission,
              total_my_income: response?.data?.total_my_income,
              total_received_collection: response?.data?.total_received_collection,
              total_sign_up_commission: response?.data?.total_sign_up_commission,
              total_sponsor: response?.data?.total_sponsor,
              total_sponsored_collection: response?.data?.total_sponsored_collection,
              total_sponsorship_commission: response?.data?.total_sponsorship_commission,
              total_user:response?.data?.data?.total
            })
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      );
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [submitSearchValue, sortValue, currentPage, dateFilterValue]);

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage?.selected + 1);
  };

  useEffect(() => {
    setColumnDefs([
      {
        headerName: "Image",
        field: "image",
        width: 100,
        cellRenderer: AvatarRenderer,
        filter: false,
      },
      { headerName: "UserName", field: "name", width: 230,      
         cellRenderer:({ value, data })=> {
         return <div className="d-flex gap-1 align-items-center">
         <Link to={`/profileview/${data?.id}`} state={{name:`${value}` }}> 
         <p className="listcard-title">{value ? value :"-"}</p> </Link>
         {data?.membership_plan_name == "Premier" ? <MdVerified color="#039be5" /> : ""}
         </div>
      }}, 

    { headerName: "Total Hosted Event", field: "event_count",width:150,
      cellStyle:{backgroundColor:'#104e8b',color:'white'},
      cellRenderer: ({ value, data }) => {
        return  <Link className="d-flex align-items-center gap-1" to={value == 0 ? `#` : `/champEvent/${data?.id}` } state={{name:`${data?.name}`}}>
        {value !== 0 && <img style={{height:12}} className="" src={linking}/>} <p className="text-light">{value}</p></Link> ;
      }
    },
      
     { headerName: "Total Received Amount", field: "received_collection", width:150,
       cellRenderer: ({ value, data }) => {
        const sponsoredAmount = data?.received_collection;
        const formattedAmount = sponsoredAmount ? `${
              currency?.currencySymbol
                ? currency?.currencySymbol : currencyIcn
            } ${currency?.selectedCurrencyPrice
                ? (currency?.selectedCurrencyPrice * sponsoredAmount).toFixed(2) : sponsoredAmount}` : 0;
        return <p className="text-light">{formattedAmount}</p>;
      },
      cellStyle:{backgroundColor:'#104e8b',color:'white'}},

      {headerName: "Total Income", field: "total_event_manage_commission", width:150,
       cellRenderer: ({ value, data }) => {
        const sponsoredAmount = data?.total_event_manage_commission;
        const formattedAmount = sponsoredAmount
          ? `${currency?.currencySymbol
                ? currency?.currencySymbol : currencyIcn
            } ${ currency?.selectedCurrencyPrice
                ? (currency?.selectedCurrencyPrice * sponsoredAmount).toFixed(2) : sponsoredAmount}`: 0;
        return <p className="text-light">{formattedAmount}</p>;
      },
      cellStyle:{backgroundColor:'#104e8b',color:'white'}},

      {headerName: "Host Rating", field: "host_rating", width:150,
        cellRenderer: ({ value, data }) => {
         return(<div className="d-flex gap-1 align-items-center">
          <ReactStars
            count={5}
            size={14}
            edit={false}
            //onChange={handleRatingChange}
            value={parseInt(value)}
            emptyIcon={<i className="far fa-star"></i>}
            fullIcon={<i className="fa fa-star"></i>}
            activeColor="#ffc601"
            />
    </div>) ;
       },
       cellStyle:{backgroundColor:'#104e8b',color:'white'}},

      {headerName: "Total Sponsored Events", field: "sponsor_count", width:150,
       cellStyle:{backgroundColor:'#8b2323',color:'white'}},

      {
        headerName: "Sponsored Amount",
        field: "sponsored_collection",
        width:150,
        cellRenderer: ({ value, data }) => {
          const sponsoredAmount = data?.sponsored_collection;
          const formattedAmount = sponsoredAmount
            ? `${
                currency?.currencySymbol
                  ? currency?.currencySymbol : currencyIcn
              } ${
                currency?.selectedCurrencyPrice
                  ? (currency?.selectedCurrencyPrice * sponsoredAmount).toFixed(2) : sponsoredAmount }` : 0;
          return <p className="text-light">{formattedAmount}</p>;
        },
        cellStyle: {backgroundColor:'#8b2323'} 
        // valueFormatter: ({value, data }) => `${data.currency} ${data?.sponsored_collection}`
      },
      {
        headerName: "Total Income",
        width:120,
        field: "total_sponsorship_commission",
        cellRenderer: ({ value, data }) => {
          const sponsoredAmount = data?.total_sponsorship_commission;
          const formattedAmount = sponsoredAmount
            ? `${
                currency?.currencySymbol
                  ? currency?.currencySymbol : currencyIcn
              } ${
                currency?.selectedCurrencyPrice
                  ? (currency?.selectedCurrencyPrice * sponsoredAmount).toFixed(2) : sponsoredAmount
              }` : 0;
          return <p className="text-light">{formattedAmount}</p>;
        },
        cellStyle: {backgroundColor:'#8b2323'} 
        // valueFormatter: ({value, data }) => `${data.currency} ${data?.sponsored_collection}`
      },

      {
        headerName: "SignUp Commision",
        width:120,
        field: "total_commission",      
        cellRenderer: ({ value, data }) => {
          const signupCommision = data?.total_commission;
          const formattedAmount = signupCommision
            ? `${
                currency?.currencySymbol
                  ? currency?.currencySymbol : currencyIcn
              } ${
                currency?.selectedCurrencyPrice
                  ? (currency?.selectedCurrencyPrice * signupCommision).toFixed(2) : signupCommision
              }` : 0;
          return <p className="text-light">{formattedAmount}</p>;
        },
        cellStyle: {backgroundColor: '#661a79'} 
      },

      {
        headerName: "My Income",
        width:120,
        field: "my_income",      
        cellRenderer: ({ value, data }) => {
          const signupCommision = data?.my_income;
          const formattedAmount = signupCommision
            ? `${
                currency?.currencySymbol
                  ? currency?.currencySymbol : currencyIcn
              } ${
                currency?.selectedCurrencyPrice
                  ? (currency?.selectedCurrencyPrice * signupCommision).toFixed(2) : signupCommision
              }` : 0;
          return <p className="">{formattedAmount}</p>;
        },
        // cellStyle: {backgroundColor: '#f43254'} 
      },

     {headerName: "User Category", field: "membership_plan_name",width:150,
      cellRenderer: ({ value, data }) => {
      return <p className={value == 'Basic' ? "basic-membership-section": "premium-membership-section"}> {value ? value : "-"}</p>
      }},

      // {headerName: "Durations", field: "durations",width:150,
      // cellRenderer: ({ value, data }) => {
      // return <p> {value ? value : "-"}</p>
      // }},

    {headerName: "State", field: "state_name",width:120,
      cellRenderer: ({ value, data }) => {
      return <p> {value ? value : "-"}</p>
      }},

     {headerName: "City", field: "city_name",width:120,
     cellRenderer: ({ value, data }) => {
     return <p> {value ? value : "-"}</p>
     }},
     {
        headerName: "Status",
        width:120,
        field: "is_active",
        cellRenderer:({ value, data })=> {
         const checkActive = value;
         return  checkActive ? <p className="user-active-div">Active </p> :
         <p className="user-inactive-div">Inactive</p>
        },
      },

     {
      headerName: "Last Logged In",
      field: "last_activity",
      cellRenderer:({ value, data })=> {
      const checkActive = value;
      return  checkActive ?  moment(checkActive).format("ll hh:mm A") : <p>-</p> 
      },
     },

      {
        headerName: "Last Activity",
        field: "event_activity",
        cellRenderer:({ value, data })=> {
        const checkActive = value;
        return  checkActive ?  moment(checkActive).format("ll hh:mm A") : <p>-</p> 
        },
      },
      {
        headerName: "Date Of Joining",
        field: "created_at",
        cellRenderer:({ value, data })=> {
          return moment(data?.created_at).format("ll hh:mm A");
        },
      },
       //Define other columns here
    ]);
  }, [currency,list?.data]);
  const defaultColDef = useMemo(() => {
    return {
      filter: "agTextColumnFilter",
      floatingFilter: true,
    };
  }, [])

  
  // setup the grid after the page has finished loading

  const onGridReady = useCallback((params) => {
    // fetch("https://www.ag-grid.com/example-assets/small-olympic-winners.json")
    //   .then((resp) => resp.json())
    //   .then((data) => {
    //     console.log("ress",data)
    //   });
  }, []);

  const onBtExport = useCallback(() => {
    gridRef.current.api.exportDataAsExcel();
  }, []);
  

  return (
    <>
    {
      loading ? <Loader2/> :
    
    <div className="">
    <div className="user-host-section">
    <h4 className="dashboard-signup-head-text">Host</h4>
    <div className="row">
      <div className="col-xl-3 col-sm-4">
            <button className='champ_dashboard-btn'>
              <div className='user-total-event'>
                <p className="total-hosts-text text-danger">Total Host Events</p>
                <p className="total-hosts-data-text text-danger">{totalData?.total_event}</p>
              </div>
            </button>
          </div>

          <div className="col-xl-3 col-sm-4">
            <button className='champ_dashboard-btn'>
              <div className='user-total-event'>
                <p className="total-hosts-text text-danger ">Total Received Amount</p>
                <p className="total-hosts-data-text text-danger">{totalData?.total_received_collection}</p>
              </div>
            </button>
          </div>

          <div className="col-xl-3 col-sm-4">
            <button className='champ_dashboard-btn'>
              <div className='user-total-event'>
                <p className="total-hosts-text text-danger ">Total Income</p>
                <p className="total-hosts-data-text text-danger">{totalData?.total_event_manage_commission}</p>
              </div>
            </button>
          </div>
      </div>
      </div>

      <div className="user-host-section mt-3">
    <h4 className="dashboard-signup-head-text">Sponsor</h4>
    <div className="row">
      <div className="col-xl-3 col-sm-4">
            <button className='champ_dashboard-btn'>
              <div className='user-total-event'>
                <p className="total-hosts-text text-danger">Total Sponsor Events</p>
                <p className="total-hosts-data-text text-danger">{totalData?.total_sponsor}</p>
              </div>
            </button>
          </div>

          <div className="col-xl-3 col-sm-4">
            <button className='champ_dashboard-btn'>
              <div className='user-total-event'>
                <p className="total-hosts-text text-danger ">Total Sponsored Amount</p>
                <p className="total-hosts-data-text text-danger">{totalData?.total_sponsored_collection}</p>
              </div>
            </button>
          </div>

          <div className="col-xl-3 col-sm-4">
            <button className='champ_dashboard-btn'>
              <div className='user-total-event'>
                <p className="total-hosts-text text-danger ">Total Income</p>
                <p className="total-hosts-data-text text-danger">{totalData?.total_sponsorship_commission}</p>
              </div>
            </button>
          </div>
      </div>
      </div>

      <div className="user-host-section mt-3">
    <h4 className="dashboard-signup-head-text">SignUp</h4>
    <div className="row">
      <div className="col-xl-3 col-sm-4">
            <button className='champ_dashboard-btn'>
              <div className='user-total-event'>
                <p className="total-hosts-text text-danger">Total User</p>
                <p className="total-hosts-data-text text-danger">{totalData?.total_user}</p>
              </div>
            </button>
          </div>

          <div className="col-xl-3 col-sm-4">
            <button className='champ_dashboard-btn'>
              <div className='user-total-event'>
                <p className="total-hosts-text text-danger">Total Income</p>
                <p className="total-hosts-data-text text-danger">{totalData?.total_sign_up_commission}</p>
              </div>
            </button>
          </div>
      </div>
      </div>

      <div className="user-host-section mt-3">
    <h4 className="dashboard-signup-head-text">Total</h4>
    <div className="row">
      <div className="col-xl-3 col-sm-4">
            <button className='champ_dashboard-btn'>
              <div className='user-total-event'>
                <p className="total-hosts-text text-danger">Total Income</p>
                <p className="total-hosts-data-text text-danger">{totalData?.total_my_income}</p>
              </div>
            </button>
          </div>
      </div>
      </div>

    <div className="transctions-list-div mt-3">       
       <div className="filter-section">
         <div className=" d-flex align-items-center justify-content-end gap-2 ">
            {/* <p className="m-0 order-list-text">Filter:</p> */}
            <button
              className="d-flex order-dropdown-button"
              onClick={() => {setDateDropdownOpen(!dateDropdownOpen) }}>
              Filter
              <IoMdArrowDropdown size={24} />
            </button>

            {dateDropdownOpen && (
              <div className="dropdown-menu-list">
                <div
                  className="dropdown-item"
                  onClick={() => {setDateFilterValue("current_week");setDateDropdownOpen(false) }}>
                  Current Week
                </div>
                <div
                  className="dropdown-item"
                  onClick={() => {setDateFilterValue("last_week");setDateDropdownOpen(false) }}>
                  Last Week
                </div>

                <div
                  className="dropdown-item"
                  onClick={() => {setDateFilterValue("current_month");setDateDropdownOpen(false) }}>
                  Current Month
                </div>

                <div
                  className="dropdown-item"
                  onClick={() => {setDateFilterValue("last_month");setDateDropdownOpen(false) }}>
                  Last Month
                </div>

                <div
                  className="dropdown-item"
                  onClick={() => {setDateFilterValue("quarter");setDateDropdownOpen(false) }}>
                  Quarter
                </div>

                <div
                  className="dropdown-item"
                  onClick={() => {setDateFilterValue("last_6_months");setDateDropdownOpen(false) }}>
                  6 Months
                </div>
                <div
                  className="dropdown-item"
                  onClick={() => {setDateFilterValue("annual");setDateDropdownOpen(false) }}>
                  Annual
                </div>
              </div>
            )}
         <div className="d-flex justify-content-end">
          {dateFilterValue && (
            <div className="d-flex justify-content-end ">
              <div className="d-flex gap-2 align-items-center">
                <p className="m-0 order-list-text">Filter By :</p>
                <div className="sort-clear-div ">
                  <p className="m-0 order-list-text text-light text-capitalize p-0">{dateFilterValue}</p>
                  <button
                    className="sort-clear-btn"
                    onClick={() => setDateFilterValue("")}>
                    <IoCloseOutline color="white" size={20} />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        </div>
       </div>

      <div className="">
        <button className="export-to-excel-btn" onClick={onBtExport}>
          Export to Excel
        </button>
      <div className="ag-theme-alpine" style={{ height:520, width: "100%" }}>
        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          columnDefs={columnDefs}
          rowData={list?.data}
          defaultColDef={defaultColDef}
          rowSelection="multiple" 
          // Include group footer at the bottom
          // pagination={true}
          // paginationPageSize={10}
          // paginationPageSizeSelector={[10, 25, 50]}
        ></AgGridReact>
      </div>
      {list?.data?.length < list?.total && (
        <div className="d-flex align-items-end pagination-container mt-1">
          <ReactPaginate
            pageCount={Math.ceil(list?.total / list?.per_page)}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            onPageChange={handlePageClick}
            containerClassName="pagination"
            activeClassName="selected"
            disabledClassName="disabled"
          />
        </div>
      )}   
      </div>
    </div>
    </div>
   }
      {/* <div className="col-12 transctions-list-div ">
        
        <div className="list-head-div">
          <div className="row d-flex align-items-center">
            <div className="col-md-1">
              <p className="order-head-text">Image</p>
            </div>
            <div className="col-md-2">
              <p className="order-head-text">Name</p>
            </div>

            <div className="col-md-2">
              <p className="order-head-text">
                Total Events
              </p>
            </div>
            <div className="col-md-2">
              <p className="order-head-text">
                Sponsored Amount
              </p>
            </div>
            <div className="col-md-2">
              <p className="order-head-text">
                SignUp Commision
              </p>
            </div>
            <div className="col-md-2">
              <p className="order-head-text">Date</p>
            </div>
          </div>
        </div>

        <div className="champ-list-main-div">
          {loading ? (
            <Loader2 />
          ) : list?.data?.length < 1 ? (
            <h4 className="p-3">Data Not Found</h4>
          ) : (
            list?.data &&
            list?.data?.map((item, index) => (
              <div className="mt-2" key={index}>
                <div className="listcard-div d-grid">
                  <div className="row align-items-center">
                    <div className="col-md-1">
                      <Link to="#">
                        <img
                          className="listcard-img object-fit-cover"
                          src={
                            !item?.image_type
                              ? `https://ringus.idea2reality.tech/storage/app/public/profile/${item?.image}`
                              : item?.image_type == "browse"
                                ? `https://ringus.idea2reality.tech/storage/app/public/profile/${item?.image}`
                                : `https://ringus.idea2reality.tech/storage/app/public/avatar/${item?.predefine_image}`
                          }
                          alt="img"
                        />
                      </Link>
                    </div>
                    <div className="col-md-2">
                      <Link
                        to={`/champEvent/${item?.id}`} state={{ name: `${item?.f_name}` }}
                        className="text-black"
                        title={`${item?.f_name} ${item?.l_name}`}
                      >
                        {item?.f_name} {item?.l_name}
                      </Link>
                    </div>


                    <div className="col-md-2">
                      <p className="listcard-title">
                        {item?.event_count ? item?.event_count : "-"}
                      </p>
                    </div>

                    <div className="col-md-2">
                      <p className="listcard-title">
                        {item?.sponsored_collection
                          ? `${currency?.currencySymbol
                            ? currency?.currencySymbol
                            : currencyIcn
                          }
                            ${currency?.selectedCurrencyPrice
                            ? (
                              currency?.selectedCurrencyPrice *
                              item?.sponsored_collection
                            ).toFixed(2)
                            : item?.sponsored_collection
                          } `
                          : "-"}
                      </p>
                    </div>
                    <div className="col-md-2">
                      <p className="listcard-title">
                        {item?.total_commission
                          ? ` ${currency?.currencySymbol
                            ? currency?.currencySymbol
                            : currencyIcn
                          }
                            ${currency?.selectedCurrencyPrice
                            ? (
                              currency?.selectedCurrencyPrice *
                              item?.total_commission
                            ).toFixed(2)
                            : item?.total_commission
                          }`
                          : "-"}
                      </p>
                    </div>
                    <div className="col-md">
                      <p className="order-list-text">
                        {moment(item?.created_at).format("ll hh:mm A")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      {list?.data?.length < list?.total && (
        <div className="d-flex align-items-end pagination-container mt-1">
          <ReactPaginate
            pageCount={Math.ceil(list?.total / list?.per_page)}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            onPageChange={handlePageClick}
            containerClassName="pagination"
            activeClassName="selected"
            disabledClassName="disabled"
          />
        </div>
      )}  */}

      {/* tbl */}
      {/* <div className="transctions-list-div ">
   <div class="">
	 <div class=" panel-default">
		<div class="center-block fix-width scroll-inner">
			<table class="table table-striped">
				<thead className="champ-list-head py-3">
					<tr className="">
						<th className="order-head-text text-white">Image</th>
						<th className="order-head-text text-white">Name</th>
						<th className="order-head-text text-white">Sponsored Events</th>
						<th className="order-head-text text-white">Sponship Received</th>
						<th className="order-head-text text-white">Signup Commision</th>
						<th className="order-head-text text-white">Date </th>
           	<th className="order-head-text text-white">City</th>
						<th className="order-head-text text-white">State</th>
						<th className="order-head-text text-white">Status</th>
						<th className="order-head-text text-white">State</th>
					</tr>
				</thead>
				<tbody>
          {
             list?.data?.map((item, index) => {
              return(<>
      		<tr className="listcard-div">
          <td>
          <div className="">
                      <Link to="#">
                        <img
                          className="listcard-img object-fit-cover"
                          src={
                            !item?.image_type
                              ? `https://ringus.idea2reality.tech/storage/app/public/profile/${item?.image}`
                              : item?.image_type == "browse"
                              ? `https://ringus.idea2reality.tech/storage/app/public/profile/${item?.image}`
                              : `https://ringus.idea2reality.tech/storage/app/public/avatar/${item?.predefine_image}`
                           }
                          alt="img"
                        />
                      </Link>
                    </div>
            </td>        
						<td>
                <div className="">
                      <Link
                        to={`/champEvent/${item?.id}`} state={{ name: `${item?.f_name}` }}
                        className="text-black"
                        title={`${item?.f_name} ${item?.l_name}`}
                      >
                        {item?.f_name} {item?.l_name}
                      </Link>
                    </div>
            </td>
						<td>
            <p className="listcard-title">
              {item?.event_count ? item?.event_count : "-"}
            </p>
            </td>
						<td>
            <p className="listcard-title">
                        {item?.sponsored_collection
                          ? `${currency?.currencySymbol
                            ? currency?.currencySymbol
                            : currencyIcn
                          }
                            ${currency?.selectedCurrencyPrice
                            ? (
                              currency?.selectedCurrencyPrice *
                              item?.sponsored_collection
                            ).toFixed(2)
                            : item?.sponsored_collection
                          } `
                          : "-"}
                </p>
            </td>
						  <th>  
               <p className="listcard-title">
                        {item?.total_commission
                          ? ` ${currency?.currencySymbol
                            ? currency?.currencySymbol
                            : currencyIcn
                          }
                            ${currency?.selectedCurrencyPrice
                            ? (
                              currency?.selectedCurrencyPrice *
                              item?.total_commission
                            ).toFixed(2)
                            : item?.total_commission
                          }`
                          : "-"}
               </p></th>
					   	<th> <p className="order-list-text">
                     {moment(item?.created_at).format("ll hh:mm A")}
                    </p>
               </th>
            <td>udaipur</td>
						<td>rajastdan</td>
            <td>Online</td>
						<th>1</th>
					</tr>          
              </>)
             }
          )}
				</tbody>
			</table>
		</div>
	</div>
</div>
</div>
     {list?.data?.length < list?.total && (
        <div className="d-flex align-items-end pagination-container mt-1">
          <ReactPaginate
            pageCount={Math.ceil(list?.total / list?.per_page)}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            onPageChange={handlePageClick}
            containerClassName="pagination"
            activeClassName="selected"
            disabledClassName="disabled"
          />
        </div>
      )}   */}
    </>
  );
};
export default UserList;
