import { useEffect, useRef, useState } from "react";
import FilterIcon from "../../assets/images/filter-icon.svg";
import sorticon from "../../assets/images/sort-icon.svg";
import EventsCard from "../../components/Events/EventsCard";
import { get_events_list } from "../../utils/event/GetEventList";
import Loader from "../../components/Loader/Loader";
import { BsFillGridFill } from "react-icons/bs";
import {
  FaListUl,
  FaLongArrowAltDown,
  FaLongArrowAltUp,
  FaRegCalendarAlt,
} from "react-icons/fa";
import EventsListCard from "../../components/Events/EventsListCard";
import { getEventTypeData } from "../../utils/basicInformation/basicinformationManager";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import ReactPaginate from "react-paginate";
import Loader2 from "../../components/Loader/Loader2";
import downarrrow from "../../assets/images/ic-downarrow.png";
import uparrow from "../../assets/images/ic-uparrow.png";
import { isTokenValid } from "../../AuthbyToken";
import { Link, useLocation, useParams } from "react-router-dom";
import EventSortSection from "../../components/Events/EventSortSection";
import EventFilterSection from "../../components/Events/EventFilterSection";
import { CiFilter } from "react-icons/ci";
import { MdFilterListAlt, MdOutlineSort } from "react-icons/md";
import { getChampHostEvents } from "../../utils/champ/champAlldata";
import HostEventListTable from "../../components/ChamplistData/HostEventListTable";
import { useSelector } from "react-redux";

const ChampEvent = () => {
  const [cardView, setCardView] = useState("grid");
  const [eventList, setEventList] = useState({});
  const [Loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [searchError, setSearchError] = useState("");
  const [type, setType] = useState([]);
  const [eventRange, setEventRange] = useState({ min: "", max: "" });
  const [eventType, setEventType] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [sortVal, setSortVal] = useState("");
  const [eventMode, setEventMode] = useState("");
  const [eventLoading, setEventLoading] = useState(true);
  const [eventCategory, setEventCategory] = useState("");
  const [status, setStatus] = useState("");
  // FilterDrop
  const [rangeDrop, setRangeDrop] = useState(false);
  const [typeDrop, setTypeDrop] = useState(false);
  const [categoriesDrop, setCategoriesDrop] = useState(true);
  const [loadingCard, setLoadingCard] = useState(false);
  const [eventModeDrop, setEventModeDrop] = useState(false);
  const [openFilterBar, setOpenFilterBar] = useState(false);
  const [openSortBar, setOpenSortBar] = useState(false);
  const [eventCategoryDrop, setEventCategoryDrop] = useState(false);
  const [statusDrop, setStatusDrop] = useState(false);
  //
  const [interestDrop, setInterestDrop] = useState(false);
  const [hastag, setHastag] = useState([]);
  const [predefineHastag, setPredefineHastag] = useState([]);
  const [selectedHastags, setSelectedHastag] = useState([]);

  const [predefineCities, setPredefineCities] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [no_ParticipantsDrop, setNo_ParticipantsDrop] = useState(false);
  const [srt_Evt_DateDrop, setSrt_Evt_DateDrop] = useState(false);
  const [genderDrop, setGenderDrop] = useState(false);
  const [minAgeDrop, setMinAgeDrop] = useState(false);
  const [distanceDrop, setDistanceDrop] = useState(false);
  const [primeHostDrop, setPrimeHostDrop] = useState(false);
  const [cityDrop, setCityDrop] = useState(false);
  const [selectedGenders, setSelectedGenders] = useState({
    male: "",
    female: "",
    other: "",
  });
  const [no_Participants, setNo_Participants] = useState({ min: "", max: "" });
  const [srt_Evt_Date, setSrt_Evt_Date] = useState({ start: "", end: "" });
  const [minMaxAge, setMinMaxAge] = useState({ min: "", max: "" });
  const [distanceRange, setDistanceRange] = useState("");
  const [prime_host, setPrime_host] = useState(false);

  const { state } = useLocation();

  const isValid = isTokenValid();
  const params = useParams();
  const category = params?.category;
  const [categories, setCategories] = useState(category);

  const ConfigData = useSelector((state) => state?.config_data?.configData);
  const baseUrls = ConfigData?.base_urls;

  const GetEvent = async () => {
    const get_lat_long = localStorage.getItem("let_long");
    const lat_long = JSON.parse(get_lat_long);

    getChampHostEvents(
      params?.id,
      eventMode,
      eventType,
      eventRange,
      categories,
      currentPage,
      search,
      sortVal,
      lat_long,
      eventCategory,
      status
    ).then((response) => {
      if (response?.data?.success) {

        setEventList(response?.data?.data);
        setLoading(false);
        setEventLoading(false);
      }
    });
  };

  useEffect(() => {
    GetEvent();
  }, [
    params?.id,
    categories,
    eventMode,
    eventType,
    eventRange,
    currentPage,
    search,
    sortVal,
    eventCategory,
    status,
  ]);


  const handleSearch = (e) => {
    e.preventDefault();
    if (!search || !search.trim() === "") {
      setSearchError("Invalid search input");
    } else {
      setSearchError("");
      setSearch("");
    }
  };

  const getEventType = async () => {
    try {
      await getEventTypeData().then((response) => {
        setType(response?.data);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getEventType();
  }, []);

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  return (
    <>
      {Loading ? (
        <Loader />
      ) : (
        <div className="events container-fluid">
          <div className="row align-items-center">
            <div className="col-5 col-sm-6 col-md-5">
              <h2 className="champ-events-heading d-flex flex-column flex-md-row align-items-md-center gap-2 mb-0">
                Total Hosted Events
                <span className="host-username-heading">
                  ({eventList?.total})
                </span>

              </h2>
              <div>
                <span className="host-username-heading" title={state?.name}>
                  {state?.name == null ? "" : `(${state?.name})`}
                </span>
              </div>
            </div>

            {/* <div className="d-flex justify-content-center d-md-none col-md-6 mt-4 col-md-5">
              <div className="d-flex search-div">
                <input
                  className="form-control search-event-input"
                  name="search"
                  value={search}
                  placeholder="Search Events..."
                  onChange={(e) => setSearch(e.target.value)}
                />
                <button
                  className="d-flex align-items-center gap-1 event-search-btn"
                  onClick={handleSearch}>
                  Search
                </button> 
              </div>
              {searchError && (
                <p className="error-message text-danger ms-1">{searchError}</p>
              )}
            </div> */}
          </div>

          {/* Filter  Section */}
          <div className="mt-4">
            <hr className="w-100" />
            <div className="row">
              {/* <div className="position-relative col-md-4 col-xl-3">
                <div className="d-flex gap-2">
                  <button
                    className="side-filterbar-button d-block d-md-none"
                    onClick={() => setOpenFilterBar(!openFilterBar)}>
                    Filter <MdFilterListAlt />
                  </button>
                  <button
                    className="side-filterbar-button d-block d-md-none"
                    onClick={() => setOpenSortBar(!openSortBar)}>
                    Sort by <MdOutlineSort />
                  </button>
                </div>

                <div
                  className={`event-filter-sidebar${openFilterBar ? "open" : ""}`}>
                  <aside className="d-lg-block">
                    <EventFilterSection
                      setCategoriesDrop={setCategoriesDrop}
                      categoriesDrop={categoriesDrop}
                      setCategories={setCategories}
                      categories={categories}
                      setEventModeDrop={setEventModeDrop}
                      eventMode={eventMode}
                      setEventMode={setEventMode}
                      eventModeDrop={eventModeDrop}
                      setTypeDrop={setTypeDrop}
                      typeDrop={typeDrop}
                      eventType={eventType}
                      setEventType={setEventType}
                      type={type}
                      setEventRange={setEventRange}
                      eventRange={eventRange}
                      GetEvent={GetEvent}
                      rangeDrop={rangeDrop}
                      setRangeDrop={setRangeDrop}
                      setSortVal={setSortVal}
                      setEventCategory={setEventCategory}
                      eventCategory={eventCategory}
                      eventCategoryDrop={eventCategoryDrop}
                      setEventCategoryDrop={setEventCategoryDrop}
                      status={status}
                      setStatus={setStatus}
                      statusDrop={statusDrop}
                      setStatusDrop={setStatusDrop}
                      // 
                      interestDrop={interestDrop}
                      setInterestDrop={setInterestDrop}
                      category={categories}
                      setHastag={setHastag}
                      predefineHastag={predefineHastag}
                      hastag={hastag}
                      selectedHastags={selectedHastags}
                      no_Participants={no_Participants}
                      setNo_Participants={setNo_Participants}
                      no_ParticipantsDrop={no_ParticipantsDrop}
                      setNo_ParticipantsDrop={setNo_ParticipantsDrop}
                      srt_Evt_Date={srt_Evt_Date}
                      setSrt_Evt_Date={setSrt_Evt_Date}
                      srt_Evt_DateDrop={srt_Evt_DateDrop}
                      setSrt_Evt_DateDrop={setSrt_Evt_DateDrop}
                      minMaxAge={minMaxAge}
                      setMinMaxAge={setMinMaxAge}
                      minAgeDrop={minAgeDrop}
                      setMinAgeDrop={setMinAgeDrop}
                      distanceRange={distanceRange}
                      setDistanceRange={setDistanceRange}
                      distanceDrop={distanceDrop}
                      setDistanceDrop={setDistanceDrop}
                      prime_host={prime_host}
                      setPrime_host={setPrime_host}
                      primeHostDrop={primeHostDrop}
                      setPrimeHostDrop={setPrimeHostDrop}
                      //
                      genderDrop={genderDrop}
                      setGenderDrop={setGenderDrop}
                     
                      cityDrop={cityDrop}
                      setCityDrop={setCityDrop}
                      predefineCities={predefineCities}  
                      cities={cities} 
                      setCities={setCities}
                      selectedCities={selectedCities}
                      selectedGenders={selectedGenders} 
                      setSelectedGenders={setSelectedGenders}
                    
                    />
                  </aside>
                </div>
              </div> */}

              <div className="col-sm-12 col-md-12 col-xl-12 ">
                {/* <div>
                  <div className="d-none d-md-flex gap-3 align-items-center justify-content-end">
                    <EventSortSection
                      sortVal={sortVal}
                      setSortVal={setSortVal}
                    />
                  </div>
                  <hr className="mt-4 d-none d-xl-none" />
                </div> */}
                {eventList?.data?.length <= 0 && (
                  <h3 className="mt-3">No Data Found</h3>
                )}
                <div className="row">
                  {eventLoading ? (
                    <Loader2 />
                  ) : (
                    eventList?.data && (
                      <div className="col-md-12 p-0">
                        <HostEventListTable
                          baseUrls={baseUrls}
                          val={eventList?.data}
                          isValid={isValid}
                          username={state?.name}
                        />
                      </div>
                    )
                  )}

                  <div>
                    {eventList?.data?.length < eventList?.total && (
                      <div className="pagination-container">
                        <ReactPaginate
                          pageCount={Math.ceil(
                            eventList?.total / eventList?.per_page
                          )}
                          pageRangeDisplayed={3}
                          marginPagesDisplayed={2}
                          onPageChange={handlePageClick}
                          containerClassName="pagination"
                          activeClassName="selected"
                          disabledClassName="disabled"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default ChampEvent;
