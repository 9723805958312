import React, { useState } from 'react';

const CancellationRefund = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [requestDescription, setRequestDescription] = useState('');
    const [selectedConcern, setSelectedConcern] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);

    const togglePopup = () => {
        setIsOpen(!isOpen);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setShowConfirmation(true);
        setRequestDescription('');
    };

    return (
        <div>
            <div className="d-flex justify-content-end mt-2">
                <button onClick={togglePopup} className="cancellation-refund-button">Report a Problem</button>
            </div>
            {isOpen && (
                <div className="cancellation-refund-popup">
                    <div className="cancellation-refund-popup-content">
                        <div className="d-flex justify-content-between">
                            <span className="cancellation-refund-problem">Report Problem</span>
                            <span className="cancellation-refund-close" onClick={togglePopup}>&times;</span>
                        </div>

                        <div className="mt-1">
                            <label>What is your concern about this event?</label>
                            <div className="cancellation-refund-radio-group">
                                {/* <label>
                                    <input
                                        type="radio"
                                        value="cancelled"
                                        checked={selectedConcern === 'cancelled'}
                                        onChange={(e) => setSelectedConcern(e.target.value)}
                                    />
                                    This event has been cancelled
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        value="postponed"
                                        checked={selectedConcern === 'postponed'}
                                        onChange={(e) => setSelectedConcern(e.target.value)}
                                    />
                                    This event has been postponed
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        value="adult_content"
                                        checked={selectedConcern === 'adult_content'}
                                        onChange={(e) => setSelectedConcern(e.target.value)}
                                    />
                                    This event has adult content
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        value="fake"
                                        checked={selectedConcern === 'fake'}
                                        onChange={(e) => setSelectedConcern(e.target.value)}
                                    />
                                    This event is fake/Inappropriate/Duplicate
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        value="spam"
                                        checked={selectedConcern === 'spam'}
                                        onChange={(e) => setSelectedConcern(e.target.value)}
                                    />
                                    This event is a spam
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        value="remove"
                                        checked={selectedConcern === 'remove'}
                                        onChange={(e) => setSelectedConcern(e.target.value)}
                                    />
                                    Please remove this event
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        value="private"
                                        checked={selectedConcern === 'private'}
                                        onChange={(e) => setSelectedConcern(e.target.value)}
                                    />
                                    This event is private
                                </label> */}
                                <table className="refund-table-customer">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        value="cancelled"
                                                        checked={selectedConcern === 'cancelled'}
                                                        onChange={(e) => setSelectedConcern(e.target.value)}
                                                    />
                                                    This event has been cancelled
                                                </label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        value="postponed"
                                                        checked={selectedConcern === 'postponed'}
                                                        onChange={(e) => setSelectedConcern(e.target.value)}
                                                    />
                                                    This event has been postponed
                                                </label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        value="adult_content"
                                                        checked={selectedConcern === 'adult_content'}
                                                        onChange={(e) => setSelectedConcern(e.target.value)}
                                                    />
                                                    This event has adult content
                                                </label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        value="fake"
                                                        checked={selectedConcern === 'fake'}
                                                        onChange={(e) => setSelectedConcern(e.target.value)}
                                                    />
                                                    This event is fake/Inappropriate/Duplicate
                                                </label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        value="spam"
                                                        checked={selectedConcern === 'spam'}
                                                        onChange={(e) => setSelectedConcern(e.target.value)}
                                                    />
                                                    This event is a spam
                                                </label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        value="remove"
                                                        checked={selectedConcern === 'remove'}
                                                        onChange={(e) => setSelectedConcern(e.target.value)}
                                                    />
                                                    Please remove this event
                                                </label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        value="private"
                                                        checked={selectedConcern === 'private'}
                                                        onChange={(e) => setSelectedConcern(e.target.value)}
                                                    />
                                                    This event is private
                                                </label>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <hr />


                        {showConfirmation ? (
                            <div>Thank you for your request! We will review your submission and contact you.</div>
                        ) : (
                            <form onSubmit={handleSubmit}>
                                <div>
                                    <label htmlFor="request-description">Please describe your cancellation or refund request:</label>
                                    <textarea
                                        className="cancellation-refund-textarea"
                                        value={requestDescription}
                                        onChange={(e) => setRequestDescription(e.target.value)}
                                        required
                                    />
                                </div>
                                <button type="submit" className='cancellation-refund-button'>Send</button>
                            </form>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default CancellationRefund;