import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import "swiper/css/grid";
import { Grid, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import ReactStars from "react-rating-stars-component";
import DateFormat from "../DateFormat";
import { useState } from "react"
import Lightbox from "react-image-lightbox";

const ReviewCards = ({ reviewUser, baseUrls }) => {

    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [viewImages, setViewImages] = useState([]);


    const openLightbox = (val, ImgReview, index) => {
        {
            if (val == "ReviewImg") {
                const images = ImgReview?.map(
                    (item) => `${baseUrls?.event_review_image_url}/${val}`
                );
                setViewImages(images);
            }
        }

        setPhotoIndex(index);
        setIsOpen(true);
    };

    return (
        <>
            {isOpen && (
                <Lightbox
                    mainSrc={viewImages[photoIndex]}
                    nextSrc={viewImages[(photoIndex + 1) % viewImages?.length]}
                    prevSrc={viewImages[(photoIndex + viewImages?.length - 1) % viewImages?.length]}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex((photoIndex + viewImages?.length - 1) % viewImages?.length)
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % viewImages?.length)
                    }
                />
            )}

            <div className="">
                <Swiper
                    spaceBetween={30}
                    slidesPerView={3}
                    modules={[Navigation, Grid]}
                    navigation={true}
                    loop={false}
                    grid={{
                        rows: 2,
                        fill: "row",
                    }}
                    autoplay={{
                        delay: 2000,
                        disableOnInteraction: false,
                    }}
                    className="mySwiper"
                >
                    {reviewUser?.map((val, index) => {
                        const ImgReview = JSON.parse(val?.attachment);
                        return (
                            <>
                                <SwiperSlide key={index}>
                                    <div className="reviews-card">
                                        <div className="d-flex align-items-center">
                                            <img
                                                className="reviews-img"
                                                src={`${baseUrls?.avatar_image_url}/${val?.user?.predefine_image}`}
                                                alt="user"
                                            />
                                            <div className="ms-3 mt-2">
                                                <h6 className="m-0 reviews-name-text">
                                                    {val?.user?.f_name} {val?.user?.l_name}
                                                </h6>
                                                <p className="reviews-text">{val?.type}</p>
                                            </div>
                                        </div>

                                        <div className="mt-2">
                                            <p>
                                                <DateFormat date={val?.created_at} />
                                            </p>

                                            <div className="rating-content-div">
                                                <p
                                                    className="reviews-paragraph "
                                                // style={{ height: "90px" }}
                                                >
                                                    {val?.comment}
                                                </p>
                                            </div>

                                            <div className="d-flex gap-1">
                                                <ReactStars
                                                    count={5}
                                                    size={30}
                                                    value={val?.rating}
                                                    edit={false}
                                                    emptyIcon={<i className="far fa-star"></i>}
                                                    fullIcon={<i className="fa fa-star"></i>}
                                                    activeColor="#f43254"
                                                />
                                            </div>
                                        </div>

                                        <div className="mt-0">
                                            <Swiper
                                                spaceBetween={0}
                                                slidesPerView={5}
                                                modules={[Navigation]}
                                                navigation={false}
                                                loop={false}
                                                className="mySwiper pt-1"
                                            >
                                                {ImgReview?.map((val, index) => (
                                                    <SwiperSlide key={index}>
                                                        <img
                                                            src={`${baseUrls?.event_review_image_url}/${val}`}
                                                            className="reviewImges"
                                                            onClick={() => openLightbox("ReviewImg", ImgReview, index)}
                                                        />
                                                    </SwiperSlide>
                                                ))}
                                            </Swiper>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </>
                        );
                    })}
                </Swiper>
            </div>
        </>
    )
}
export default ReviewCards