
import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { CallTestimonial, deleteTestimonial, getTestimonialdataList, postTestimonial } from '../../utils/testimonialdata/testimonialdata';
import { ToastContainer, toast } from 'react-toastify';
import { FaLongArrowAltDown, FaLongArrowAltUp, FaRegCalendarAlt } from 'react-icons/fa';
import ReactStars from "react-rating-stars-component";
import TestimonialReview from '../../components/testimoinial/TestimonialReview';
import Loader2 from '../../components/Loader/Loader2';
import { MdClear, MdFilterListAlt } from 'react-icons/md';
import { useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { TiArrowLeft } from 'react-icons/ti';
import { useNavigate } from 'react-router-dom';
import TestimonialRating from '../../components/testimoinial/TestimonialRating';


const Testimonial = () => {
    const [open, setOpen] = useState(false)
    const [openFilterBar, setOpenFilterBar] = useState(false);
    const [selectedOption, setSelectedOption] = useState();

    const [list, setList] = useState([])
    const [loader, setLoader] = useState(true);

    //filter  or sorting
    const [lateOlde, setLateOlde] = useState('')
    const [tabValue, setTabValue] = useState('all')
    const [starRating, setStarRating] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate()
    //input data
    const [formData, setFormData] = useState({
        testimonials: "",
        type: "",
        rating: "",
        heading: ""
    })

    const [rating, setRating] = useState(0);
    const [error, setError] = useState({});

    //filter drop
    const [testimonialNameDrop, setTestimonialNameDrop] = useState(true);
    const [ratingDrop, setRatingDrop] = useState(true);


    const ConfigData = useSelector((state) => state?.config_data?.configData);
    const baseUrls = ConfigData?.base_urls;
    const ProfileData = useSelector((state) => state?.Profile_data?.ProfileData);

    useEffect(() => {
        if (ProfileData?.i_want_to == "is_host") {
            setSelectedOption("Host")
        } else {
            setSelectedOption("Sponsor")
        }
    }, [ProfileData?.i_want_to])



    const datalist = async () => {
        setLoader(true)
        try {
            await getTestimonialdataList(tabValue, starRating, lateOlde, currentPage)
                .then((res) => {
                    if (res?.success) {
                        setList(res?.data)
                        setLoader(false)
                    }
                })
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        datalist();
    }, [tabValue, starRating, lateOlde, currentPage])



    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "heading") {
            if (value.length <= 40) {
                setFormData({ ...formData, heading: value });
            } else {
                setFormData({ ...formData, heading: value.slice(0, 40) });
                setError({ heading: "Heading is Maximum 40 character only" });
            }
        }
    };

    const handleEditorChange = (value) => {
        const text = value.replace(/<[^>]*>?/gm, "");
        if (text.length <= 250) {
            setFormData((prevState) => ({
                ...prevState,
                testimonials: value,
            }));
            setError({
                ...error,
                testimonials: "",
            });
        } else {
            setError({
                ...error,
                testimonials: "The entered text exceeds the maximum allowed length of 250 characters.",
            });
        }
    };

    const handleOptionClick = (value) => {
        setSelectedOption(value);
    };

    const handleRatingChange = (newRating) => {
        setRating(newRating);
    };

    const handleSubmitData = async () => {
        if (validateForm()) {
            const fdata = { ...formData, type: selectedOption, rating: rating }
            try {
                await postTestimonial(fdata)
                    .then((res) => {
                        if (res?.success) {
                            toast.success(res.message, {
                                position: "top-center",
                                autoClose: 3000,
                            })
                            datalist();
                            setOpen(false)
                            // setSelectedOption('sponsor')
                            setFormData({
                                testimonials: '',
                                heading: '',
                                rating: 0,

                            })
                        }
                    })
            } catch (err) {
                if (err.response.data.success == false) {
                    toast.error(err.response.data.message, {
                        position: "top-center",
                        autoClose: 3000,
                    })
                }
            }
        }
    }

    const DeleteTestimonial = (id) => {
        deleteTestimonial(id)
            .then((response) => {
                if (response?.success) {
                    datalist();
                    toast.success(response?.message, {
                        position: "top-center",
                        autoClose: 3000,
                    })
                } else {
                    toast.warn(response?.message, {
                        position: "top-center",
                        autoClose: 3000,
                    })
                }
            })
    }




    const handlePageClick = (selectedPage) => {
        setCurrentPage(selectedPage.selected + 1);
    };


    // rect quill length count
    const stripHtmlTags = (html) => {
        const div = document.createElement('div');
        div.innerHTML = html;
        return div.textContent || div.innerText || '';
    };

    const getDescriptionLength = () => {
        const cleanDescription = stripHtmlTags(formData.testimonials);
        return cleanDescription.length;
    };

    const maxLength = 250;
    const currentLength = getDescriptionLength();
    const remainingLength = maxLength - currentLength;
    //


    const validateForm = () => {
        let valid = true;
        const newErrors = {};

        if (formData?.heading?.trim() == "") {
            newErrors.heading = "Heading is required";
            valid = false;
        }

        const event_des = formData.testimonials?.replace(/<p><br><\/p>/g, '')
        if (event_des?.trim() == "") {
            newErrors.testimonials = "Testimonial Review is required";
            valid = false;
        }

        if (rating == 0) {
            newErrors.rating = "Rating is required";
            valid = false;
        }

        setError(newErrors);
        return valid;
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setOpenFilterBar(false);
            } else {
                setOpenFilterBar(true);
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (<>
        <ToastContainer />
        <div className="container py-2 py-md-5">

            <div className="row align-items-center">
                <div className="col-4 col-md-4">
                    <h2 className="events-heading m-0">Testimonials</h2>
                </div>

                <div className="gap-3 col-8 col-6 col-md-8 d-flex justify-content-end gap-2 align-items-center">
                    <button className="d-none d-md-block go-back-btn" onClick={() => navigate(-1)} type="button">
                        <TiArrowLeft size={20} /> Go Back
                    </button>
                    <div className="d-flex">
                        <div>
                            <button
                                className="btn Create-new-Event"
                                onClick={() => setOpen(!open)}>
                                <FaRegCalendarAlt size={18} /> Create Testimonial
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row mt-1'>
                {/* Filter  */}
                <TestimonialRating 
                setOpenFilterBar={setOpenFilterBar}
                openFilterBar={openFilterBar} 
                setStarRating={setStarRating}
                setLateOlde={setLateOlde}
                setTabValue={setTabValue}
                setTestimonialNameDrop={setTestimonialNameDrop}
                testimonialNameDrop={testimonialNameDrop}
                tabValue={tabValue}
                setRatingDrop={setRatingDrop}
                ratingDrop={ratingDrop}
                starRating={starRating}
                />

                <div className='col-md-8 col-xl-9'>
                    <div className="d-flex align-items-center justify-content-start justify-content-md-end mt-2">
                        <div>
                            <p className="d-none d-md-block sortby-text mx-2">Sort By</p>
                        </div>
                        <div>
                            <div className="d-flex flex-row flex-md-row gap-2">
                                <div className="d-flex gap-2">
                                    <button
                                        className="d-flex align-items-center sort-btn"
                                        onClick={() =>
                                            setLateOlde(
                                                lateOlde == "rating lowest to height"
                                                    ? "rating height to lowest"
                                                    : "rating lowest to height"
                                            )
                                        }
                                    >
                                        Rating of Testimonial
                                        {lateOlde == "rating lowest to height" ? (
                                            <FaLongArrowAltUp />
                                        ) : (
                                            <FaLongArrowAltDown />
                                        )}
                                    </button>
                                </div>
                                <div className="d-flex gap-2">
                                    <button
                                        className="d-flex align-items-center sort-btn"
                                        onClick={() =>
                                            setLateOlde(
                                                lateOlde == "date oldest to latest"
                                                    ? "date latest to oldest"
                                                    : "date oldest to latest"
                                            )
                                        }
                                    >
                                        Date of Testimonial
                                        {lateOlde == "date oldest to latest" ? (
                                            <FaLongArrowAltUp />
                                        ) : (
                                            <FaLongArrowAltDown />
                                        )}
                                    </button>
                                </div>
                            </div>
                            <div className="mt-2">
                                {lateOlde !== "" && (
                                    <>
                                        <div className="d-flex align-items-center sort-clear-div">
                                            {(lateOlde == "rating height to lowest" && (
                                                <p className="sort-select-bold-text m-0">Rating :</p>
                                            )) ||
                                                (lateOlde == "rating lowest to height" && (
                                                    <p className="sort-select-bold-text m-0">Rating :</p>
                                                ))}

                                            {(lateOlde == "date oldest to latest" && (
                                                <p className="sort-select-bold-text m-0">Date :</p>
                                            )) ||
                                                (lateOlde == "date latest to oldest" && (
                                                    <p className="sort-select-bold-text m-0">Date :</p>
                                                ))}

                                            <p className="sort-select-text ms-1 m-0">{lateOlde}</p>
                                            <div>

                                                <button
                                                    className="sort-clear-btn ms-1"
                                                    onClick={() => setLateOlde("")}
                                                >
                                                    <MdClear />
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    
                    {loader ? <Loader2 /> :
                        list?.data && list?.data?.length > 0 ? (
                            <div className="row mt-3">
                                {list?.data?.map((val) => (
                                    <div className="col-md-6 col-xl-4 p-2" key={val.id}>
                                        <TestimonialReview val={val} DeleteTestimonial={DeleteTestimonial} baseUrls={baseUrls} />
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="mt-3 d-flex ">
                                <h4>Record not found</h4>
                            </div>
                        )
                    }
                    <div>
                        {list?.data?.length < list?.total && (
                            <div className="pagination-container">
                                <ReactPaginate
                                    pageCount={Math.ceil(list?.total / list?.per_page)}
                                    pageRangeDisplayed={1}
                                    marginPagesDisplayed={2}
                                    onPageChange={handlePageClick}
                                    containerClassName="pagination"
                                    activeClassName="selected"
                                    disabledClassName="disabled"
                                />
                            </div>
                        )}
                    </div>

                </div>
            </div>
            {open && (
                <div className='overlay'>
                    <div className="popup">
                        <div className="popup-content">
                            <div className="basicInfo">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h2 className="basicinfo-head">Create Testimonial</h2>

                                    <span className="close" onClick={() => setOpen(!open)}>
                                        &times;
                                    </span>
                                </div>

                                <div className='reactQuill-div'>
                                    <div>
                                        {/* <div className='py-3'>
                                            <div className="d-flex gap-4">
                                                <div className="mt-3">
                                                    <button
                                                        className={selectedOption == 'sponsor' ? "active-hashtag-div" : "hashtag-div"}
                                                        onClick={() => handleOptionClick('sponsor')}
                                                    >
                                                        <p className={selectedOption == 'sponsor' ? "active-hashtag-text" : "hashtag-text"}>
                                                            Sponsor
                                                        </p>
                                                    </button>
                                                </div>

                                                <div className="mt-3">
                                                    <button
                                                        className={selectedOption == 'host' ? "active-hashtag-div" : "hashtag-div"}
                                                        onClick={() => handleOptionClick('host')} >
                                                        <p className={selectedOption == 'host' ? "active-hashtag-text" : "hashtag-text"}>
                                                            Host
                                                        </p>
                                                    </button>
                                                </div>
                                            </div>
                                        </div> */}

                                        <div className='row py-3'>
                                            <div className="form-group col-6">
                                                <label className="form-label">
                                                    Add a Heading<span className="asterisk">*</span>
                                                </label>

                                                <div>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="heading"
                                                        value={formData.heading}
                                                        onChange={handleInputChange}
                                                        placeholder='Heading'
                                                    />
                                                </div>
                                                {error.heading && <span className="error">{error.heading}</span>}
                                            </div>

                                            <div className="form-group col-6">
                                                <label className="form-label">
                                                    Select Rateing<span className="asterisk">*</span>
                                                </label>

                                                <div className="">
                                                    <ReactStars
                                                        count={5}
                                                        size={30}
                                                        onChange={handleRatingChange}
                                                        value={rating}
                                                        emptyIcon={<i className="far fa-star"></i>}
                                                        fullIcon={<i className="fa fa-star"></i>}
                                                        activeColor="#f43254"
                                                    />
                                                </div>
                                                {error.rating && <span className="error">{error.rating}</span>}
                                            </div>
                                        </div>
                                        <div>
                                            <div className="d-flex justify-content-between">
                                                <label className="form-label">
                                                    Add a Testimonial Review<span className="asterisk">*</span>
                                                </label>
                                                <p className="Character-0500">
                                                    Character:<span className="text-style-1">
                                                        {/* remainingLength */}
                                                        {currentLength}/{maxLength}
                                                    </span>
                                                </p>
                                            </div>

                                            <div style={{ height: "180px" }}>
                                                <ReactQuill
                                                    value={formData.testimonials}
                                                    onChange={handleEditorChange}
                                                    theme="snow"
                                                    className="responsive-element-richtextbox"
                                                    // style={{ maxHeight: "130px", maxWidth: '710px' }}
                                                    placeholder="Write something"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {error.testimonials && <span className="error">{error.testimonials}</span>}
                                </div>

                                <div className="d-flex justify-content-end align-items-center organize-bottom-btn pt-5 mt-5">
                                    <div className="mt-5">
                                        <button className="btn saveandcontinue-btn" onClick={handleSubmitData}>
                                            Submit for Validation
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div >
    </>)
};

export default Testimonial;