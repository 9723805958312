import {getBannerList } from "../../Services/Apis";

export const Bannerdata = async () => {
  try {
    const constant = await getBannerList().then((data) => data?.data);
    return constant;
  } catch (error) {
    console.error(error);
  }
};


