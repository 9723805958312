import { useEffect, useState, useMemo, useCallback, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { Link } from "react-router-dom";
import moment from "moment";
import defaultimage from "../../assets/images/default-image.jpg";
import linking from "../../assets/images/linking.png";
import ReactStars from "react-rating-stars-component";

const HostEventListTable=({baseUrls,val,isValid,username})=>{
    const [columnDefs, setColumnDefs] = useState([]);
    const gridRef = useRef();

    const cardImageError = (event) => { 
        event.target.src = defaultimage;
        event.target.onError = null;
      };

    useEffect(() => {
        setColumnDefs([
          {
            headerName: "Image",
            field: "logo",
            width:120,
            cellRenderer:({ value, data }) => {
               return <div className="">
                <Link
                  className="navigate-detail-btn"
                  to={`/eventdetail/${data?.id}`}
                  state={{ type: "My Events" }}>
                  {/* https://sponsorringus.com/admin/storage/app/public/event_logo */}
                  
                  <img
                    className="listcard-img"
                    src={`${baseUrls?.logo_image_url}/${value}`}
                    onError={cardImageError}
                    alt="img"
                  />
                </Link>
              </div>
            },
            filter: false,
          },
          {
            headerName: "Event Name",
            field: "event_title",
            cellRenderer: ({ value, data }) => {
              return (
                <Link
                  className="navigate-detail-btn"
                  to={`/eventdetail/${data?.id}`}
                  state={{ type: "Events" }}>
                  <p className="order-list-text text-capitalize">{data?.event_title}</p>
                </Link>
              );
            },
          },
          {
            headerName: "Host Rating",
            field: "rating",
            width: 140,
            cellRenderer: ({ value, data }) => {
              return (<div className="d-flex gap-1 align-items-center">
                       <ReactStars
                         count={5}
                         size={14}
                         edit={false}
                         //onChange={handleRatingChange}
                         value={parseInt(value)}
                         emptyIcon={<i className="far fa-star"></i>}
                         fullIcon={<i className="fa fa-star"></i>}
                         activeColor="#ffc601"
                         />
                 </div>
              );
            }
          },
          {
            headerName: "Collection Received",
            field: "received_collection",
            // width:150,
            cellRenderer: ({ value, data }) => {
              const signupCommision = data?.received_collection;
              const formattedAmount = signupCommision
              //   ? `${
              //       currency?.currencySymbol
              //         ? currency?.currencySymbol
              //         : currencyIcn
              //     } ${
              //       currency?.selectedCurrencyPrice
              //         ? (currency?.selectedCurrencyPrice * signupCommision).toFixed(2)
              //         : signupCommision}`
              //   : "-";
              return  <Link className="navigate-detail-btn d-flex align-items-center gap-1"
              to={value == 0 ? '#' : `/eventsponsoredtransaction/${data?.id}`} state={{name:`${username}`}}>
                 {value !== 0 && <img style={{height:12}} className="" src={linking}/>} <p className="m-0 p-0">{formattedAmount}</p> </Link>;
            },
          },
          {
            headerName: "Income",
            field: "total_commission",
            width:150,
            cellRenderer: ({ value, data }) => {
              const signupCommision = data?.total_commission;
              const formattedAmount = signupCommision
              //   ? `${
              //       currency?.currencySymbol
              //         ? currency?.currencySymbol
              //         : currencyIcn
              //     } ${
              //       currency?.selectedCurrencyPrice
              //         ? (currency?.selectedCurrencyPrice * signupCommision).toFixed(2)
              //         : signupCommision}`
              // : "-";
              return <p className="">{formattedAmount}</p>;
            },
          },
          {
            headerName: "Date",
            field: "start_date",
            width:150,
            cellRenderer: ({ value, data }) => {
              return (
                <p className="listcard-title">
                  {moment(value).format("ll")}
                </p>
              );
            },
          },
          {
            headerName: "State",
            field: "state",
          },
          {
            headerName: "City",
            field: "city",
          },
          {
            headerName: "Location",
            field: "address",
          },
          {
            headerName: "Status",
            field: "event_status",
          },
        //   {
        //     field: "actions",
        //     headerName: "Actions",
        //     cellRenderer: ({ value, data }) => {
        //       return (
        //         <div className="">
        //           {data?.created_by == data?.referral_id && isEventExpired ? (
        //             <button className="listevent-editbtn" disabled>
        //               -
        //             </button>
        //           ) : (
        //             <div className="d-flex gap-1">
        //               <button
        //                 className="listevent-publishbtn"
        //                 onClick={() => PublishedEvent(data?.id, data?.event_title)}
        //               >
        //                 Publish
        //               </button>
        //             </div>
        //           )}
        //         </div>
        //       );
        //     },
        //   },
        ]);
      }, [val]);
    
      const defaultColDef = useMemo(() => {
        return {
          filter: "agTextColumnFilter",
          floatingFilter: true,
        };
      }, []);
    
      const onGridReady = useCallback((params) => {}, []);
    
      const onBtExport = useCallback(() => {
        gridRef.current.api.exportDataAsExcel();
      }, []);
   
   return(<>
     <div className="position-relative">
      
        <div className="ag-theme-alpine" style={{ height: 600, width: "100%" }}>
          <AgGridReact
            ref={gridRef}
            onGridReady={onGridReady}
            columnDefs={columnDefs}
            rowData={val}
            defaultColDef={defaultColDef}
            rowSelection="multiple"
          ></AgGridReact>
        </div>
      </div> 
    </>)
};

export default HostEventListTable;