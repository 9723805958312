import {FeatureEventPrice, FeaturedEvent, FeaturedPaymentResponse, GetUserInterest, GetUsersForChamp, getEventPeopleRange, getEventSubTopic, getEventTopic, getEventType, getEventsCreate, getLanguagesList } from "../../Services/Apis"


export const getEventTypeData = async () => {
 try{
    return await getEventType().then((data) => data.data)
 }catch(error){
    console.log(error)
 }
    
}

export const getEventTopicData = async () => {
    try{
        return await getEventTopic().then((data) => data)

    }catch(error){
        console.log(error)
    }
}

export const getEventSubTopicData = async () => {
    try{
        return await getEventSubTopic().then((data) => data)
    }catch(error){
        console.log(error)
    }
}

export const getEventPeopleRangeData = async () => {
    try{
        return await getEventPeopleRange().then((data) => data)

    }catch(error){
        console.log(error)
    }
}

export const getEventsCreateInfo = async (item) => {
    try {
        return await getEventsCreate(item).then((data) => data)
    } catch (err) {
        throw err
    }
}

export const Get_UserInterest = async () => {
    try{
        return await GetUserInterest().then((data) => data)

    }catch(error){
        console.log(error)
    }
}


export const Get_UsersForChamp = async () => {
    try{
        return await GetUsersForChamp().then((data) => data)

    }catch(error){
        console.log(error)
    }
}

export const Feature_EventPrice = async () => {
    try{
        return await FeatureEventPrice().then((data) => data)
    }catch(error){
        throw error
    }
}

export const Featured_Event = async (fdata) => {
    try{
        return await FeaturedEvent(fdata).then((data) => data)
    }catch(error){
        throw error
    }
}


export const Featured_PaymentResponse = async (fdata) => {
    try{
        return await FeaturedPaymentResponse(fdata).then((data) => data)
    }catch(error){
        throw error
    }
}

export const Get_languages_list = async () => {
    try{
        return await getLanguagesList().then((data) => data)

    }catch(error){
        console.log(error)
    }
}

