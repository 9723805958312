import { CountdownCircleTimer } from 'react-countdown-circle-timer';


const TimeCountDown=({event})=>{

    const renderTime = (dimension, time) => {
        return (
          <div className="time-wrapper">
            <div className="time time-text">{time}</div>
            <div className='time-text'>{dimension}</div>
          </div>
        );
      };
      
      const minuteSeconds = 60;
      const hourSeconds = 3600;
      const daySeconds = 86400;

      const getTimeSeconds = (time) => (minuteSeconds - time) | 0;
      const getTimeMinutes = (time) => ((time % hourSeconds) / minuteSeconds) | 0;
      const getTimeHours = (time) => ((time % daySeconds) / hourSeconds) | 0;
      const getTimeDays = (time) => (time / daySeconds) | 0;
      
      const timerProps = {
        isPlaying: true,
        size: 120,
        strokeWidth: 6
      };

      const stratTime = Date.now() / 1000; // use UNIX timestamp in seconds
      const startDateTime = new Date(`${event?.start_date}T${event?.start_time}`);
      const endTime = startDateTime.getTime() / 1000; // use UNIX timestamp in seconds
      const remainingTime = endTime - stratTime;
      const days = Math.ceil(remainingTime / daySeconds);
      const daysDuration = days * daySeconds; 
      
    return(<>
     <div className="d-flex gap-2 gap-md-3">
      <CountdownCircleTimer
        {...timerProps}
        colors="#7E2E84"
        duration={daysDuration}
        initialRemainingTime={remainingTime}
        size={70}>
        {({ elapsedTime, color }) => (
          <span style={{ color }}>
            {renderTime("days", getTimeDays(daysDuration - elapsedTime))}
          </span>
        )}
      </CountdownCircleTimer>
      <CountdownCircleTimer
        {...timerProps}
        colors="#D14081"
        duration={daySeconds}
        initialRemainingTime={remainingTime % daySeconds}
        onComplete={(totalElapsedTime) => ({
          shouldRepeat: remainingTime - totalElapsedTime > hourSeconds
        })}
        size={70}>
        {({ elapsedTime, color }) => (
          <span style={{ color }}>
            {renderTime("hours", getTimeHours(daySeconds - elapsedTime))}
          </span>
        )}
      </CountdownCircleTimer>
      <CountdownCircleTimer
        {...timerProps}
        colors="#EF798A"
        duration={hourSeconds}
        initialRemainingTime={remainingTime % hourSeconds}
        onComplete={(totalElapsedTime) => ({
          shouldRepeat: remainingTime - totalElapsedTime > minuteSeconds
        })}
        size={70}>
        {({ elapsedTime, color }) => (
          <span style={{ color }}>
            {renderTime("minutes", getTimeMinutes(hourSeconds - elapsedTime))}
          </span>
        )}
      </CountdownCircleTimer>
      <CountdownCircleTimer
        {...timerProps}
        colors="#218380"
        duration={minuteSeconds}
        initialRemainingTime={remainingTime % minuteSeconds}
        onComplete={(totalElapsedTime) => ({
          shouldRepeat: remainingTime - totalElapsedTime > 0
        })}
        size={70}>
        {({ elapsedTime, color }) => (
          <span style={{ color }}>
            {renderTime("seconds", getTimeSeconds(elapsedTime))}
          </span>
        )}
      </CountdownCircleTimer>
    </div>
    </>)
}

export default TimeCountDown;