import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    currencySymbol:'',
    selectedCurrencyPrice: 0, 
}


export const CurrencyConvert = createSlice({
    name: 'currencyconvert',
    initialState: initialState,
    reducers: {
        CurrencyCon: (state, action) => {
            const { currencySymbol, selectedCurrencyPrice } = action.payload;
            state.currencySymbol = currencySymbol;
            state.selectedCurrencyPrice = selectedCurrencyPrice
        },
    },
})

// Action creators are generated for each case reducer function
export const { CurrencyCon } = CurrencyConvert.actions;

export default CurrencyConvert.reducer;