import WhySponsorRingUsCard from "./WhySponsorRinguscard";
import arrow from "../../assets/images/arrow2.svg";
import { Link } from "react-router-dom";
import { Token } from "../../utils/Token";
import { useEffect, useState } from "react";

const WhySponsorRingUsComp = () => {
  const [token, setToken] = useState("");

  const gettoken = () => {
    const tkn = Token();
    setToken(tkn);
  };

  useEffect(() => {
    gettoken();
  }, []);

  return (
    <>
      <div className="SponsorRingUs mt-2">
        <div className="container ">
          <h2 className="Why-SponsorRingUs">
            Why<span className="Why-SponsorRingUs-text-1"> Sponsor</span>RingUs?
          </h2>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <p className="event-planners-head text-center mb-0">
              Struggling to find sponsors or get sponsored for your event?
            </p>
            <p className="Lorem-ipsum-dolor-si">
              SponsorRingUs makes it easy! SponsorRingUs is a one-stop shop for
              finding and securing sponsorships for diverse range of events,
              from concerts to local gatherings. It simplifies sponsorship and
              helps in reaching to target audience with cost-effective marketing
              and measurable results. It's the perfect platform to expand your
              market reach and achieve your marketing goals.
            </p>
          </div>

          <div className="cards">
            <WhySponsorRingUsCard />
          </div>

          {token ? null : (
            <div className="d-flex justify-content-center">
              <Link className="ringus-startbutton btn" to="/login">
                <div>GET STARTED NOW</div>
                <img className="arrow-img ps-2" src={arrow} alt="arrow" />
              </Link>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default WhySponsorRingUsComp;
