import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isSessionExpired: false,
};

export const AuthCheck = createSlice({
  name: 'AuthCheck',
  initialState: initialState,
  reducers: {
    setSessionExpired: (state, action) => {
      state.isSessionExpired = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSessionExpired } = AuthCheck.actions;

export default AuthCheck.reducer;