import { Add_Reason_For_Rejection, Check_Admin, Express_Event_Add_Reason_For_Rejection, Past_Event_Add_Reason_For_Rejection } from "../../Services/Apis"

 
 export const CheckAdmin = async (fData) => {
    try {
      return await Check_Admin(fData).then((data) => data)
  
    } catch (error) {
        throw error;
    }
 }

 export const  Add_ReasonForRejection = async (fData,id) => {
    try {
      return await Add_Reason_For_Rejection(fData,id).then((data) => data)

    } catch (error) {
        throw error;
    }
 }

 export const  Express_Event_Add_Reason_ForRejection = async (fData,id) => {
  try {
    return await Express_Event_Add_Reason_For_Rejection(fData,id).then((data) => data)

  } catch (error) {
      throw error;
  }
}

export const  PastEvent_Add_Reason_For_Rejection = async (fData,id) => {
  try {
    return await Past_Event_Add_Reason_For_Rejection(fData,id).then((data) => data)

  } catch (error) {
      throw error;
  }
}

 