import { EventPackageListData, createPackageEvent, getPredefinePackage } from "../../Services/Apis"


export const predefinePackage = async () => {
    return await getPredefinePackage().then((data) => data)
}


export const CratePackageEvent = async (info, event_id) => {
    try {
        return await createPackageEvent(info, event_id).then((data) => data)
    } catch (err) {
        throw err;
    }
}


export const getEventCreatePackageList = async (event_id) => {
    try {
        return await EventPackageListData(event_id).then((data) => data)
    } catch (err) {
        throw err;
    }
} 