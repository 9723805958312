import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import imageicon from "../../assets/images/image-upload-icon.svg";
import { Create_Past_Event_Gallery } from "../../utils/createpasteventgallery/CreatePastEventGallery";

const CreatePastEventGallery = ({ eventId, setMdl }) => {
  const [participants, setParticipants] = useState("");
  const [images, setImages] = useState([]);
  const [participantsError, setParticipantsError] = useState("");


  const handleFileChangeImage = (event) => {
    const files = event.target.files;
    const imageArray = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      imageArray.push(file);
    }
    setImages([...images, ...imageArray]);
  };

  const handlePastEventGallery = async (e) => {
    e.preventDefault();
    const FData = {
      no_of_participants: participants,
      images: images,
    };
    if (!participants || isNaN(participants) || parseInt(participants) <= 0) {
      setParticipantsError("Please enter a valid number of participants");
    } else {
      try{
        const createpastgallery = await Create_Past_Event_Gallery(eventId, FData);
        if(createpastgallery?.data?.success){
          setImages([]);
          setParticipants("");
          toast.success(createpastgallery?.data?.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
          setMdl(false);
        }
      }catch(err){
        console.log(err)
      }
      setParticipantsError("");
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="custom-modal show">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <div className="d-flex">
                <h5 className="modal-title">Create Event Gallery</h5>
              </div>
              <button
                type="button"
                className="btn-close"
                onClick={() => setMdl(false)}
              ></button>
            </div>
            <div className="modal-body mt-3">
              <form onSubmit={handlePastEventGallery}>
                <div className="row">
                  <div className="form-group col-8">
                    <label className="">
                      People Attended<span className="asterisk">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control rounded-3"
                      placeholder="Enter people attended"
                      name="participants"
                      value={participants}
                      onChange={(e) => setParticipants(e.target.value)}/>
                    {participantsError && (
                      <div className="error-text mt-2">
                        {participantsError}
                      </div>
                    )}
                  </div>
                </div>

                <div className="">
                  <div className="row">
                    <h4 className="form-heading">Images</h4>
                    <div className="d-flex flex-column align-items-center col-md-12">
                      <label>
                        <img
                          className="upload-icon"
                          src={imageicon}
                          alt="img-upload"
                        />
                        <input
                          className="d-none"
                          type="file"
                          multiple
                          onChange={handleFileChangeImage} />
                      </label>
                      <div className="">
                        The maximunm size of an image can be 1 MB
                      </div>
                      <div className="pt-3">
                        {/* <button className="btn Addlink-btn ms-4">Add Images</button> */}
                      </div>
                    </div>
                    {images.length > 0 && (
                      <div className="row gellary-sel-img-div mt-3">
                        {images?.map((file, index) => (
                          <div key={index} className="col-3 py-1 px-1">
                            <img
                              src={URL.createObjectURL(file)}
                              alt={`selected-${index}`}
                              className="event-gellary-sel-img"
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                <div className="d-flex justify-content-end gap-3 mt-5">
                  <button
                    className="btn text-dark"
                    type="button"
                    onClick={() => setMdl(false)}>
                    Close
                  </button>

                  <button className="send-Query-btn" type="submit">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreatePastEventGallery;
