

import { IoWarningOutline } from "react-icons/io5";


const CreateEventWarningMdl=({setCreateWarningMdl,setTabValue})=>{
    
    const handleSubmit=()=>{
      setCreateWarningMdl(false)
      setTabValue("Event-Guest")
    }

     return(<>
        <div className="warningModal">
          <div className="warning-mdl">
            {/* <span className="warning-close-btn" >&times;</span> */}
            <div className="d-flex justify-content-center mb-3">
              <IoWarningOutline color="#ffcc00" size={40} />
            </div>
            <h4 className="text-center warning-text">
              Important: Your event is scheduled less than 60 days in advance. This may limit the number of sponsor responses. For best results,
              we recommend scheduling events at least 90 days out
            </h4>
            <div className="d-flex justify-content-end">
              <button className="warning-mdl-btn mt-2" onClick={handleSubmit}> Ok </button>
            </div>
          </div>
        </div>
  </>)
};

export default CreateEventWarningMdl;