import React, { memo, useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import star from "../../assets/images/star.svg";
import cale from "../../assets/images/calendar.svg";
import Loader from "../Loader/Loader";
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import 'swiper/css/autoplay';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Link } from "react-router-dom";
import { FaRegCalendarAlt, FaStar } from "react-icons/fa";

const MainBanner = ({ mainBannerList, loading, ConfigData }) => {

  return (
    <>
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
        navigation
        loop={true}
        autoplay={{
         delay: ConfigData?.banner_speed ? ConfigData?.banner_speed : 4000,
         disableOnInteraction: false,
        }}
        effect="fade"
        speed={1000}
      // onSlideChange={() => console.log('slide change')}
      // onSwiper={(swiper) => console.log(swiper)}
      >
        {mainBannerList?.map((item, index) => {
          const startingText = item?.sub_heading?.split(' ')[0];
          const remainingText = item?.sub_heading?.slice(startingText?.length);
          return (
            <div key={item.id}>
              <SwiperSlide key={item.id}>
                <div className="main-banner" style={{backgroundImage: `url('${ConfigData?.base_urls?.banner_image_url}/${item?.photo}')`}}>
                  <div className="d-table">
                    <div className="d-table-cell position-relative">
                      <div className="container">
                        <VisibilitySensor>
                          {({ isVisible }) => (
                            <div>
                           {/* <div className={`main-banner-content ${index === 1 ? "banner-content-center" : "" }`}>
                              <h1 className={`${isVisible ? "animated fadeInUp" : ""} `}>
                                {item?.heading}
                              </h1>

                              <h2 className="SponsoRingUs-brings text-light mt-2 py-4">
                                <span className="text-#f43254">{startingText}</span> {remainingText}
                              </h2>
                              <div className="d-flex flex-column flex-sm-row align-items-center justify-content-center gap-3 gap-md-5 mt-md-4">
                                
                                <div className="d-flex justify-content-center gap-3 gap-md-5">
                                  {item?.buttons?.map((item, idx) => {
                                    const btnPage = item?.name.split('-')[0]
                                    return (
                                      <Link to={`/MainBannerPage/${btnPage}`} key={item?.id}
                                        className={`${index === idx ? `event-sponsor-btn d-none d-lg-block` : 'event-organisor-btn d-none d-lg-block'} ${isVisible ? "animated fadeInDown" : ""}`}>
                                        <img className="star-icon" src={`${index === idx ? star : cale}`} alt="img" />{item?.name}</Link>
                                    )
                                    })}

                                  <div className="d-flex flex-wrap justify-content-center gap-3">
                                    {item?.buttons?.map((item, idx) => {
                                      const BtnName = item?.name.split(' ')[0];
                                      return (
                                        <button key={item?.id} className={`${index === idx ? `event-sponsor-btn d-lg-none ` : 'event-organisor-btn d-lg-none'} ${isVisible ? "animated fadeInDown" : ""}`}>
                                          <img className="star-icon" src={`${index === idx ? star : cale}`} alt="img" />{BtnName}</button>
                                      )
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div> */}
                              
                            <div className="d-flex justify-content-center">
                              <div className="main-banner-bottons">
                               {
                                 //console.log("items",item)
                               }
                               <div className="d-flex justify-content-center pb-4">
                                 <div className=" main-banner-heading-div" style={{backgroundColor:item?.color}}>
                                 <h5 className={`${isVisible ? "animated fadeInUp" : ""} main-banner-heading mb-0`} 
                                   style={{color:item?.font_color}}>
                                   {item?.heading}
                                 </h5>
                                 </div>
                                </div>
                               
                                <div className="d-flex justify-content-center gap-3 gap-md-5 ">
                                   {item?.buttons?.map((item, idx) => {
                                    const btnPage = item?.name?.split('-')[0];
                                    return (
                                      <Link to={`/MainBannerPage/${btnPage}`} key={item?.id}
                                       className={`${index === idx ? `event-sponsor-btn d-none d-lg-flex align-items-center` : 'event-organisor-btn d-none d-lg-flex align-items-center'} ${isVisible ? "animated fadeInDown" : ""}`} 
                                       style={{backgroundColor:item?.color,color:item?.font_color}}>
                                       {index === idx ? <FaStar className="me-2" size={16} color={item?.font_color}/> :
                                        <FaRegCalendarAlt className="me-2" size={16} color={item?.font_color}/>} 
                                       {/* <img className="star-icon" src={`${index === idx ? star : cale}`} alt="img"/> */}
                                       {item?.name}</Link>
                                     )
                                    })}
                               </div>

                                   {/* Mobile Screen */}
                                  <div className="d-flex flex-wrap justify-content-center gap-3">
                                    {item?.buttons?.map((item, idx) => {
                                      const BtnName = item?.name?.split(' ')[0];
                                      return (
                                        <button key={item?.id} className={`${index === idx ? `event-sponsor-btn d-lg-none ` : 'event-organisor-btn d-lg-none'} ${isVisible ? "animated fadeInDown" : ""}`}
                                         style={{backgroundColor:item?.color, color:item?.font_color}}>
                                        {index === idx ? <FaStar className="me-2" size={14} color={item?.font_color}/> :
                                        <FaRegCalendarAlt className="me-2" size={14} color={item?.font_color}/>} 
                                        {/* <img className="star-icon" src={`${index === idx ? star : cale}`} alt="img" /> */}
                                        {BtnName}</button>
                                      )
                                    })}
                                  </div>
                                  </div>
                                </div>
                            </div>
                          )}
                        </VisibilitySensor>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </div>
          )
        })}
      </Swiper>

    </>
  );
};

export default memo(MainBanner);
