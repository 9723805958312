import { Create_News_Letter } from "../../Services/Apis";


export const Create_NewsLetter = async (data) => {
    try {
        const response = await Create_News_Letter(data).then((data) => data?.data);
        return response
    } catch (error) {
        throw error
    }
}