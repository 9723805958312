import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTopOnPageChange = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };

    scrollToTop();
  }, [pathname]);

  return null; // This component doesn't render anything
};

export default ScrollToTopOnPageChange;