import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Get_ProfileDetail } from "../../utils/champ/champAlldata";
import UserProfile from "../../components/Profile/UserProfile";
import Loader from "../../components/Loader/Loader";
import { useSelector } from "react-redux";

const ProfileView = () => {
  const [profileValue, setProfileValue] = useState({});
  const [loading, setLoading] = useState(true);
  const ConfigData = useSelector((state) => state?.config_data?.configData);
  const baseUrls = ConfigData?.base_urls;

  const params = useParams();
  const id = params?.id;

  const GetProfile = async () => {
    setLoading(true);
    try {
      const response = await Get_ProfileDetail(id);
      setProfileValue(response?.data?.user);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    GetProfile();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="container py-2 py-md-4">
          <div className="pt-md-4">
            <UserProfile val={profileValue} baseUrls={baseUrls} viewType={"champ"}/>
          </div>
        </div>
      )}
    </>
  );
};
export default ProfileView;
