import { Link } from "react-router-dom";
import emailicon from "../../assets/images/ic-email.png";
import phoneicon from "../../assets/images/ic-phone.png";
import moment from "moment";
import { MdClose, MdEdit, MdVerified } from "react-icons/md";
import { useState } from "react";
import EditPhoneNumberPopup from "./EditPhoneNumberPopup";
import EditEmailPopup from "./EditEmailPopup";
import EditUserNamePopup from "./EditUserNamePopup";
import { RiShieldCheckFill } from "react-icons/ri";
import { FaCheck } from "react-icons/fa6";
import { IoCloseSharp } from "react-icons/io5";
import Progressbar from "../Common/ProgressBar";
import DeviceDetectComponent from "../Common/DeviceDetect";
import { GoDotFill } from "react-icons/go";
import { Get_UserInterest } from "../../utils/basicInformation/basicinformationManager";
import dImage from "../../assets/images/default-image.jpg";

const UserProfile = ({ val, baseUrls, viewType, eventType }) => {
  const [emailPopup, setEmailPopup] = useState(false);
  const [phonePopup, setPhonePopup] = useState(false);
  const [userNamePopup, setUserNamePopup] = useState(false);
  const [showShieldSection, setShowShieldSection] = useState(false);

  const cardImageError = (event) => {
    event.target.src = dImage;
    event.target.onError = null;
  };


  const maskAlternativeNumber = (countryCode, phoneNumber) => {
    if (!phoneNumber) return '';

    const maskedSection = phoneNumber.slice(3, 8).replace(/\d/g, 'x');
    return `${countryCode}${phoneNumber.slice(0, 3)}${maskedSection}${phoneNumber.slice(8)}`;
  }

  const maskNumber = (countryCode, phoneNumber) => {
    if (!phoneNumber) return '';

    const maskedSection = phoneNumber.slice(3, 8).replace(/\d/g, 'x');
    return `${countryCode}${phoneNumber.slice(0, 3)}${maskedSection}${phoneNumber.slice(8)}`;
  }

  const maskEmail = (email) => {
    if (!email) return '';

    const [localPart, domain] = email.split('@');
    const maskedLocalPart = localPart.slice(0, 2) + '*****' + localPart.slice(-1);
    return `${maskedLocalPart}@${domain}`;
  }

  const maskAadharNumber = (aadhar) => {
    if (!aadhar) return 'None';
    const digits = aadhar.replace(/\D/g, '');
    if (digits.length <= 8) {
      return digits.padEnd(8, 'X');
    } else {
      return digits.slice(0, digits.length - 8) + 'XXXXXXXX';
    }
  };

  const maskPanNumber = (pan) => {
    if (!pan) return 'None';
    const visiblePart = pan.slice(0, 4); // First 5 characters
    const maskedPart = 'XXXXXX'; // Mask last 5 characters
    return `${visiblePart}${maskedPart}`;
  };


  return (<>
    {emailPopup && <EditEmailPopup setEmailPopup={setEmailPopup} val={val} />}
    {phonePopup && <EditPhoneNumberPopup setPhonePopup={setPhonePopup} val={val} />}
    {userNamePopup && <EditUserNamePopup setUserNamePopup={setUserNamePopup} val={val} />}

    <div className="mb-3">
      <div className="">
        <h2 className="profile-title">Profile</h2>

        <div className="row">
          <div className="col-12 col-xl-3  profile-avatar-div">
            <div className="col-md-10 col-xxl-8 ">
              <div className=" position-relative">
              <img className="userprofile-img" src={val?.image_type == "browse" ?
                `${baseUrls?.customer_image_url}/${val?.image}` :
                `${baseUrls?.avatar_image_url}/${val?.predefine_image}`
              } alt="profile" onError={cardImageError} />
              <button className="profile-shield-btn" onClick={() => setShowShieldSection(!showShieldSection)}>
                <RiShieldCheckFill color="white" size={20} />
              </button>
              </div>
            </div>
           
           <div>
            {val?.profile_completions == null || val?.profile_completions == '' ?
              '' :
              <div className="mt-3">
                <p className="shield-p-text p-0 m-0">Profile Completions</p>
                <Progressbar progress={val?.profile_completions} />
              </div>
            }
            </div>

            {
              showShieldSection &&
              <div className="shield-section">
                <div className="">
                  <h4 className="shield-heading text-capitalize">{val?.f_name} Confirmed Information</h4>
                  <div className="d-flex flex-column gap-1 mt-3">
                    <div className="d-flex gap-1 align-items-center">
                      {val?.verify_phone ? <GoDotFill size={20} color="green" /> : <GoDotFill size={20} color="orange" />}
                      <p className="shield-p-text">Phone Number</p>
                    </div>

                    <div className="d-flex gap-1 align-items-center">
                      {val?.verify_email ? <GoDotFill size={20} color="green" /> : <GoDotFill size={20} color="orange" />}
                      <p className="shield-p-text">Email Address</p>
                    </div>

                    <div className="d-flex gap-1 align-items-center">
                      {val?.verify_aadhar ? <GoDotFill size={20} color="green" /> : <GoDotFill size={20} color="orange" />}
                      <p className="shield-p-text">Aadhaar Card</p>
                    </div>

                    <div className="d-flex gap-1 align-items-center">
                      {val?.verify_pan ? <GoDotFill size={20} color="green" /> : <GoDotFill size={20} color="orange" />}
                      <p className="shield-p-text">Pan Card</p>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>

          <div className="col-12 col-xl-9 mt-3">
            {
              viewType !== "champ" &&
              <div className="d-flex justify-content-between">
                <h3 className="profile-name-title text-capitalize">{val?.f_name} {val?.l_name} {val?.is_prime_user ? <MdVerified color="#039be5" /> : ""}  <span> | Joined on: {moment(val?.created_at).format('LLL')}</span></h3>
                <Link className="profile-editbtn" to={`/editprofile/${val?.id}`}>Edit</Link>
              </div>
            }

            <div className="d-flex flex-column flex-md-row align-items-md-center gap-3">
              <div className="d-flex gap-1">
                <p className="d-flex mb-0 gap-2 align-items-center profile-mail-text"> <img className="" src={emailicon} />{maskEmail(val?.email)}</p>
                {
                  viewType !== "champ" &&
                  <button className="edit-email-btn" onClick={() => setEmailPopup(!emailPopup)}><MdEdit size={16} /></button>
                }
              </div>
              {/* <p className="mb-0">|</p> */}

              <div className="d-flex gap-1 align-items-center">
                <p className="d-flex gap-2 align-items-center profile-mail-text mb-0"> <img className="" src={phoneicon} />{maskNumber(val?.country_code, val?.phone)}</p>
                {
                  viewType !== "champ" &&
                  <button className="edit-email-btn" onClick={() => setPhonePopup(!phonePopup)}><MdEdit size={16} /></button>
                }
              </div>
            </div>

            <div className="mt-4">
              <div className="row">

                <div className="col-6 col-md-3">
                  <p className="userinfo-head-text mb-0">Username</p>
                  {/* <p className="userinfo-text">{val?.f_name} {val?.l_name}</p> */}
                  <div className="d-flex gap-1 align-items-center">
                    <p className="userinfo-text mb-0">{val?.name ? val?.name : 'None'}</p>
                    {/* <button className="edit-email-btn" onClick={()=>setUserNamePopup(!userNamePopup)}><MdEdit size={20}/></button> */}
                  </div>
                </div>

                <div className="col-6 col-md-3">
                  <p className="userinfo-head-text mb-0">Date of Birth</p>
                  <p className="userinfo-text">{val?.date_of_birth ? moment(val?.date_of_birth).format('DD/MM/YYYY') : 'None'} </p>
                </div>

                <div className="col-6 col-md-3">
                  <p className="userinfo-head-text mb-0">Gender</p>
                  <p className="userinfo-text">{val?.gender} </p>
                </div>

                <div className="col-6 col-md-3">
                  <p className="userinfo-head-text mb-0">Age</p>
                  <p className="userinfo-text">{val?.age} Years</p>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-6 col-md-3">
                  <p className="userinfo-head-text mb-0">Marital Status</p>
                  <p className="userinfo-text text-capitalize">{val?.marital_status}</p>
                </div>

                <div className="col-6 col-md-3">
                  <p className="userinfo-head-text mb-0">Anniversary</p>
                  <p className="userinfo-text">{val?.anniversary ? moment(val?.anniversary).format('DD/MM/YYYY') : 'None'}</p>
                </div>

                <div className="col-6 col-md-3">
                  <p className="userinfo-head-text mb-0">Educational Background</p>
                  <p className="userinfo-text">{val?.education_background ? val?.education_background : 'None'}</p>
                </div>

                <div className="col-6 col-md-3">
                  <p className="userinfo-head-text mb-0">Industry</p>
                  <p className="userinfo-text">{val?.industry ? val?.industry : 'None'}</p>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-6 col-md-3">
                  <p className="userinfo-head-text mb-0">Occupation</p>
                  <p className="userinfo-text">{val?.occupation ? val?.occupation : 'None'}</p>
                </div>

                <div className="col-6 col-md-3">
                  <p className="userinfo-head-text mb-0">House Hold Income</p>
                  <p className="userinfo-text">{val?.house_hold_income ? val?.house_hold_income : 'None'}</p>
                </div>

                <div className="col-6 col-md-3">
                  <p className="userinfo-head-text mb-0">Language</p>
                  {/* <p className="userinfo-text">{val?.language ? val?.language : 'N/A'}</p> */}
                  <div className="d-flex gap-1 lang-div">
                    {val?.languages?.map((item, index) => (
                      <p className="userinfo-text lang-text d-flex m-0 p-0" key={item.id}>{item?.language}
                        {index < val?.languages?.length - 1 && <span>,</span>}</p>
                    ))}
                  </div>
                </div>

                <div className="col-6 col-md-3">
                  <p className="userinfo-head-text mb-0">Alternative Mobile Number</p>
                  <p className="userinfo-text">{val?.alternative_number ? maskAlternativeNumber(val?.country_code, val?.alternative_number) : 'None'}</p>
                </div>

              </div>

              <div className="row mt-3">
                <div className="col-6 col-md-3">
                  <p className="userinfo-head-text mb-0">Aadhaar Number</p>
                  <p className="userinfo-text ">{val?.aadhar_card ? maskAadharNumber(val?.aadhar_card) : 'None'}</p>
                </div>

                <div className="col-6 col-md-3">
                  <p className="userinfo-head-text mb-0">Pan Number</p>
                  <p className="userinfo-text text-uppercase">{val?.pan_card ? maskPanNumber(val?.pan_card) : 'None'}</p>
                </div>

                <div className="col-6 col-md-3">
                  <p className="userinfo-head-text mb-0"> I am</p>
                  <div className="d-flex gap-1 m-0 p-0">
                    <p className="userinfo-text m-0 p-0">{val?.i_want_to == 'is_host' ? 'Host' : 'Sponsor'}</p>
                    {/* <p className="userinfo-text m-0 p-0">{val?.is_host ? 'Host' : ''}</p>
                    {val?.is_host && val?.is_sponsor ? "&" : ''}
                    <p className="userinfo-text m-0 p-0">{val?.is_sponsor ? 'Sponsor' : ''}</p> */}
                  </div>
                </div>

                {/* <div className="col-6 col-md-3">
                  <p className="userinfo-head-text mb-0">Last Login</p>
                  <p className="userinfo-text">{val?.last_login ? moment(val?.last_login
                  ).format("MMM DD, YYYY | hh:mm A") : 'None'}</p>
                </div> */}
                <div className="col-6 col-md-3">
                  <p className="userinfo-head-text mb-0">Login Device Type</p>
                  <p className="userinfo-twoline-text text-capitalize"><DeviceDetectComponent /></p>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-3">
                  <p className="userinfo-head-text mb-0">Logged Count</p>
                  <p className="userinfo-twoline-text text-capitalize">{val?.today_login_activity_count ? val?.today_login_activity_count : 'None'}</p>
                </div>
              </div>

              <div className="mt-3">
                <div className="row">
                  <div className="col-md-3">
                    <p className="userinfo-head-text mb-0">Country</p>
                    <p className="userinfo-twoline-text text-capitalize">{val?.country_name ? val?.country_name : 'None'}</p>
                  </div>

                  <div className="col-md-3">
                    <p className="userinfo-head-text mb-0">State</p>
                    <p className="userinfo-twoline-text text-capitalize">{val?.state_name ? val?.state_name : 'None'}</p>
                  </div>

                  <div className="col-md-3">
                    <p className="userinfo-head-text mb-0">City/Town</p>
                    <p className="userinfo-twoline-text text-capitalize">{val?.city_name ? val?.city_name : 'None'}</p>
                  </div>

                  <div className="col-md-3">
                    <p className="userinfo-head-text mb-0">Pin Code</p>
                    <p className="userinfo-twoline-text text-capitalize">{val?.zip_code ? val?.zip_code : 'None'}</p>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-md-3">
                    <p className="userinfo-head-text mb-0">Flat/House No., Building</p>
                    <p className="userinfo-twoline-text text-capitalize">{val?.house_no ? val?.house_no : 'None'}</p>
                  </div>

                  <div className="col-md-6">
                    <p className="userinfo-head-text mb-0">Area/Village/Street</p>
                    <p className="userinfo-twoline-text text-capitalize" title={val?.address}>{val?.address ? val?.address : 'None'}</p>
                  </div>

                  <div className="col-md-3">
                    <p className="userinfo-head-text mb-0">Landmark</p>
                    <p className="userinfo-twoline-text text-capitalize" title={val?.landmark}>{val?.landmark ? val?.landmark : 'None'}</p>
                  </div>
                </div>
              </div>

              <hr className="w-100" />
              <div className="row mt-3">
                <div className="col-md-4">
                  <p className="userinfo-head-text mb-0">Facebook Link</p>
                  <p className="userinfo-text" title={val?.facebook_link}>{val?.facebook_link ? <Link to={val?.facebook_link}> {val?.facebook_link} </Link> : 'None'}</p>
                </div>

                <div className="col-md-4">
                  <p className="userinfo-head-text mb-0">Instagram Link</p>
                  <p className="userinfo-text" title={val?.instagram_link}>{val?.instagram_link ? <Link to={val?.instagram_link}> {val?.instagram_link} </Link> : 'None'}</p>
                </div>

                <div className="col-md-4">
                  <p className="userinfo-head-text mb-0">WebSite Link</p>
                  <p className="userinfo-text" title={val?.website_link}>{val?.website_link ? <Link to={val?.website_link}> {val?.website_link} </Link> : 'None'} </p>
                </div>
              </div>

              {
                viewType !== "champ" &&
                  val?.champ?.f_name ? <div className="mt-4">
                  <h2 className="profile-mail-text fw-bold">Champ Info</h2>
                  <div className="row">
                    <div className="col-6 col-md-3">
                      <p className="userinfo-head-text mb-0">Name</p>
                      <p className="userinfo-text">{val?.champ?.f_name ? `${val?.champ?.f_name} ${val?.champ?.l_name}` : 'None'}</p>
                    </div>
                    <div className="col-6 col-md-3">
                      <p className="userinfo-head-text mb-0">Referral Code</p>
                      <p className="userinfo-text">{val?.champ?.referral_code ? val?.champ?.referral_code : 'None'}</p>
                    </div>
                  </div>
                </div> : ''}

            </div>
          </div>
        </div>
      </div >
    </div >
  </>)
};
export default UserProfile;