import React, { useState, useRef, useEffect } from "react";
import HostList from "../../components/ChamplistData/HostList";
import UserList from "../../components/ChamplistData/UserList";
import Transaction from "../../components/ChamplistData/Transaction";
import Dashboard from "../../components/ChamplistData/Dashboard";
import SponsorList from "../../components/ChamplistData/SponsorList";
import { useDispatch, useSelector } from "react-redux";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { IoCloseCircleOutline, IoCloseOutline } from "react-icons/io5";
import { FaFileDownload } from "react-icons/fa";
import { CSVLink } from "react-csv";
import { ToastContainer, toast } from 'react-toastify';
import { loginmessage } from "../../redux/Login";
import ConfettiExplosion from 'react-confetti-explosion';

const ChampDashboard = () => {
  const [tabValue, setTabValue] = useState("Dashboard");
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [amountDropdownOpen, setAmountDropdownOpen] = useState(false);
  const [commisionDropdownOpen, setCommisionDropdownOpen] = useState(false);
  const [eventDropdownOpen, setEventDropdownOpen] = useState(false);
  const [sponsorDropdownOpen, setSponsorDropdownOpen] = useState(false);
  const [statusValue, setStatusValue] = useState("");
  const [sortValue, setSortValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [submitSearchValue, setSubmitSearchValue] = useState("");
  const [searchError, setSearchError] = useState("");
  const [pdfDownload, setPdfDownload] = useState([]);
  const [dateDropdownOpen, setDateDropdownOpen] = useState(false);
  const [dateFilterValue, setDateFilterValue] = useState("");
  const profileinfo = useSelector((data) => data?.Profile_data?.ProfileData);

  // console.log("profileinfo",profileinfo)
  const [totalCount,setTotalCount]=useState('');
  const currency = useSelector((val) => val?.CurrencyConvert);

  const statusDropdownRef = useRef(null);
  const amountDropdownRef = useRef(null);
  const dateDropdownRef = useRef(null);
  const commisionDropdownRef = useRef(null);
  const dispatch = useDispatch();
  const loginData = useSelector((state) => state?.login);
  const [hurrayAnimation, setHurrayAnimation] = useState(false)
  
  const handleItemClick = (item) => {
    setStatusDropdownOpen(false);
    setStatusValue(item);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (!searchValue || !searchValue.trim() === "") {
      setSearchError("Invalid search input");
    } else {
      setSearchError("");
      setSubmitSearchValue(searchValue);
      setSearchValue("");
    }
  };

  // console.log("sbb",submitSearchValue)

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const statusDropdownNode = statusDropdownRef.current;
      const amountDropdownNode = amountDropdownRef.current;
      const dateDropdownNode = dateDropdownRef.current;
      const commisionDropdownNode = commisionDropdownRef.current;
      if (
        statusDropdownNode &&
        !statusDropdownNode.contains(event.target) &&
        amountDropdownNode &&
        !amountDropdownNode.contains(event.target) &&
        dateDropdownNode &&
        !dateDropdownNode.contains(event.target) &&
        commisionDropdownNode &&
        !commisionDropdownNode.contains(event.target)
      ) {
        //setStatusDropdownOpen(false);
        setAmountDropdownOpen(false);
        setDateDropdownOpen(false);
        setCommisionDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
    document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleTabChange = (value) => {
    setTabValue(value)
    setSortValue("");
    setSubmitSearchValue("");
    setStatusValue("")
    setTotalCount("")
  }

  useEffect(() => {
    if (loginData?.message !== "") {
        toast.success(loginData?.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            theme: "light",
        });
        dispatch(loginmessage({userType:loginData?.userType, message:""}));
        setHurrayAnimation(true);
    }
}, [loginData]);

  return (
    <>
 <ToastContainer />
 <div className="ConfettiExplosion-container">
    {hurrayAnimation && (
        <div className="ConfettiExplosion-center">
            <ConfettiExplosion blast={true} duration={4000} numberOfPieces={5000} width={1600} />
        </div>
    )}
   </div>

 <div className="champ-dashboard">
    <div className="container-fluid py-5">

      <div className="row d-flex align-items-center ">
          <div className="col-md-9 d-flex align-items-center gap-3">
          <div>
          <h3 className="your-events-heading m-0 d-flex gap-2 align-items-center"> 
          {tabValue == "Dashboard" ? (<>{tabValue} <span className="champ-dashboard-username">{`(${profileinfo?.name && profileinfo?.name})`}</span></>) : (
           tabValue
          )} {totalCount ? `(${totalCount})` :''} </h3>
        </div>
        {
          tabValue == "Transactions" ? 
          <div>
         <div className="d-flex gap-1 discliamer-div">
          <div className="your-events-p ">Discliamer :</div>
          <span className="your-events-p ">All income and payments are subject to realization of the payment
            and completion of the event deliverables</span>
         </div>   
         </div>    
          : "" }    
        </div>
      </div>



      {/* <div className="row d-flex align-items-center">
        {tabValue != "Dashboard" && (
          <>
            <div className="col-md-4">
              <form onSubmit={handleSearch}>
                <div className="d-flex search-div">
                  <input
                    className="form-control search-event-input"
                    name="searchValue"
                    value={searchValue}
                    placeholder="Search Events..."
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                  <button className="event-search-btn" type="submit">
                    Search
                  </button>
                </div>
              </form>
              {searchError && (
                <p className="error-message text-danger ms-1">{searchError}</p>
              )}
            </div>

            <div className="col-md-6 d-flex justify-content-end align-items-center gap-2">
              <p className="m-0 order-list-text">Sort :</p>

              {
                tabValue == "Transaction" ? "" :
                  tabValue == "Sponsor" ?
                    <div className="position-relative" ref={amountDropdownRef}>
                      <button
                        className="d-flex order-dropdown-button"
                        onClick={() => {
                          setSponsorDropdownOpen(!sponsorDropdownOpen)
                          setAmountDropdownOpen(false);
                          setStatusDropdownOpen(false);
                          setDateDropdownOpen(false);
                          setCommisionDropdownOpen(false);
                        }}
                      >
                        Sponsored
                        {sponsorDropdownOpen ? (
                          <IoMdArrowDropup size={24} />
                        ) : (
                          <IoMdArrowDropdown size={24} />
                        )}
                      </button>
                      {sponsorDropdownOpen && (
                        <div className="dropdown-menu-data">
                          <div
                            className="dropdown-item"
                            onClick={() => {
                              setSortValue("sponsor low to high");
                              setSponsorDropdownOpen(false);
                            }}>
                            Low To High
                          </div>
                          <div
                            className="dropdown-item"
                            onClick={() => {
                              setSortValue("sponsor high to low");
                              setSponsorDropdownOpen(false);
                            }}>
                            High To Low
                          </div>
                        </div>
                      )}
                    </div>
                    :
                    <div className="position-relative">
                      <button
                        className="d-flex order-dropdown-button"
                        onClick={() => {
                          setEventDropdownOpen(!eventDropdownOpen)
                          setAmountDropdownOpen(false);
                          setStatusDropdownOpen(false);
                          setDateDropdownOpen(false);
                          setCommisionDropdownOpen(false);
                          setSponsorDropdownOpen(false);
                        }}
                      >
                        Events
                        {eventDropdownOpen ? (
                          <IoMdArrowDropup size={24} />
                        ) : (
                          <IoMdArrowDropdown size={24} />
                        )}
                      </button>
                      {eventDropdownOpen && (
                        <div className="dropdown-menu-data">
                          <div
                            className="dropdown-item"
                            onClick={() => {
                              setSortValue("event low to high");
                              setEventDropdownOpen(false);
                            }}>
                            Low To High
                          </div>
                          <div
                            className="dropdown-item"
                            onClick={() => {
                              setSortValue("event high to low");
                              setEventDropdownOpen(false);
                            }}>
                            High To Low
                          </div>
                        </div>
                      )}
                    </div>
              }


              <div className="position-relative" ref={amountDropdownRef}>
                <button
                  className="d-flex order-dropdown-button"
                  onClick={() => {
                    setAmountDropdownOpen(!amountDropdownOpen);
                    setStatusDropdownOpen(false);
                    setDateDropdownOpen(false);
                    setCommisionDropdownOpen(false);
                    setEventDropdownOpen(false);
                    setSponsorDropdownOpen(false);
                  }}
                >
                  {tabValue == "User" || tabValue == "Transaction" ? 'Amount' : 'Collection'}
                  {amountDropdownOpen ? (
                    <IoMdArrowDropup size={24} />
                  ) : (
                    <IoMdArrowDropdown size={24} />
                  )}
                </button>
                {amountDropdownOpen && (
                  <div className="dropdown-menu-data">
                    <div
                      className="dropdown-item"
                      onClick={() => {
                        setSortValue(tabValue == "User" || tabValue == "Transaction" ? "amount low to high" : "collection low to high");
                        setAmountDropdownOpen(false);
                      }}>
                      Low To High
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={() => {
                        setSortValue(tabValue == "User" || tabValue == "Transaction" ? "amount high to low" : "collection high to low");
                        setAmountDropdownOpen(false);
                      }}>
                      High To Low
                    </div>
                  </div>
                )}
              </div>

              <div>
                {tabValue == "Transaction" ? (
                  <div className="position-relative" ref={statusDropdownRef}>
                    <button
                      className="d-flex order-dropdown-button"
                      onClick={() => {
                        setStatusDropdownOpen(!statusDropdownOpen);
                        setAmountDropdownOpen(false);
                        setDateDropdownOpen(false);
                        setCommisionDropdownOpen(false);
                        setEventDropdownOpen(false);
                        setSponsorDropdownOpen(false);
                      }}
                    >
                      Status
                      {statusDropdownOpen ? (
                        <IoMdArrowDropup size={24} />
                      ) : (
                        <IoMdArrowDropdown size={24} />
                      )}
                    </button>
                    {statusDropdownOpen && (
                      <div className="dropdown-menu-data">
                        <div
                          className="dropdown-item"
                          onClick={() => handleItemClick("success")}
                        >
                          Success
                        </div>
                        <div
                          className="dropdown-item"
                          onClick={() => handleItemClick("Pending")}
                        >
                          Pending
                        </div>
                        <div
                          className="dropdown-item"
                          onClick={() => handleItemClick("failed")}
                        >
                          Failed
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="position-relative" ref={commisionDropdownRef}>
                    <button
                      className="d-flex order-dropdown-button"
                      onClick={() => {
                        setCommisionDropdownOpen(!commisionDropdownOpen);
                        setStatusDropdownOpen(false);
                        setAmountDropdownOpen(false);
                        setDateDropdownOpen(false);
                        setEventDropdownOpen(false);
                        setSponsorDropdownOpen(false);
                      }}
                    >
                      Commision
                      {commisionDropdownOpen ? (
                        <IoMdArrowDropup size={24} />
                      ) : (
                        <IoMdArrowDropdown size={24} />
                      )}
                    </button>
                    {commisionDropdownOpen && (
                      <div className="dropdown-menu-data">
                        <div
                          className="dropdown-item"
                          onClick={() => {
                            setSortValue("commission low to high");
                            setCommisionDropdownOpen(false);
                          }}
                        >
                          Low To High
                        </div>
                        <div
                          className="dropdown-item"
                          onClick={() => {
                            setSortValue("commission high to low");
                            setCommisionDropdownOpen(false);
                          }}
                        >
                          High To Low
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div className="position-relative" ref={dateDropdownRef}>
                <button
                  className="d-flex order-dropdown-button"
                  onClick={() => {
                    setDateDropdownOpen(!dateDropdownOpen);
                    setStatusDropdownOpen(false);
                    setAmountDropdownOpen(false);
                    setCommisionDropdownOpen(false);
                    setEventDropdownOpen(false);
                    setSponsorDropdownOpen(false);
                  }}
                >
                  Date
                  {dateDropdownOpen ? (
                    <IoMdArrowDropup size={24} />
                  ) : (
                    <IoMdArrowDropdown size={24} />
                  )}
                </button>
                {dateDropdownOpen && (
                  <div className="dropdown-menu-data">
                    <div
                      className="dropdown-item"
                      onClick={() => {
                        setSortValue("date Latest to oldest");
                        setDateDropdownOpen(false);
                      }}
                    >
                      Latest To Oldest
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={() => {
                        setSortValue("date oldest to latest");
                        setDateDropdownOpen(false);
                      }}
                    >
                      Oldest To Latest
                    </div>
                  </div>
                )}
              </div>

              <div className="position-relative mx-3">
                {tabValue == 'Transaction' &&
                  <CSVLink data={pdfDownload}>
                    <FaFileDownload color='#f43254' size={25} />
                  </CSVLink>
                }
              </div>
            </div>
          </>
        )}
      </div>

      <div className="d-flex gap-2 justify-content-end ">
        {sortValue && (
          <div className="d-flex justify-content-end mt-3">
            <div className="d-flex gap-2 align-items-center">
              <p className="m-0 order-list-text">Sort By :</p>
              <div className="sort-clear-div ">
                <p className="m-0 order-list-text text-light text-capitalize p-0">
                  {sortValue}
                </p>
                <button
                  className="sort-clear-btn"
                  onClick={() => setSortValue("")}
                >
                  <IoCloseOutline color="white" size={20} />
                </button>
              </div>
            </div>
          </div>
        )}

        {statusValue && (
          <div className="d-flex justify-content-end mt-3">
            <div className="d-flex gap-2 align-items-center">
              <p className="m-0 order-list-text">Status:</p>
              <div className="sort-clear-div ">
                <p className="m-0 order-list-text text-light text-capitalize p-0">
                  {statusValue}
                </p>
                <button
                  className="sort-clear-btn"
                  onClick={() => setStatusValue("")}
                >
                  <IoCloseOutline color="white" size={20} />
                </button>
              </div>
            </div>
          </div>
        )}
      </div> */}
      <div className="row gap-3 gap-md-0 py-5 position-relative">
        <div className="col-md-3 col-xl-2 p-sm-0 ">
        <div className=" champ-dashboard-list">
          <div className="btn-champ-div">
            <button
              className={`${tabValue == "Dashboard"
                ? "active-btn-champ"
                : "deactive-btn-champ" }`}
              onClick={() => handleTabChange("Dashboard")}>
              Dashboard 
            </button>
          </div>
          <div className="btn-champ-div">
            <button
              className={`${tabValue == "Users" ? "active-btn-champ" : "deactive-btn-champ"}`}
              onClick={() => handleTabChange("Users")}>
              Users
            </button>
          </div>
          <div className="btn-champ-div">
            <button
              className={`${tabValue == "Hosts" ? "active-btn-champ" : "deactive-btn-champ"}`}
              onClick={() => handleTabChange("Hosts")}>
              Hosts
            </button>
          </div>
          <div className="btn-champ-div">
            <button
              className={`${tabValue == "Sponsors"
                ? "active-btn-champ"
                : "deactive-btn-champ" }`}
              onClick={() => handleTabChange("Sponsors")}
            >
              Sponsors
            </button>
          </div>
          <div className="btn-champ-div">
            <button
              className={`${tabValue == "Transactions"
                ? "active-btn-champ"
                : "deactive-btn-champ"}`}
              onClick={() => handleTabChange("Transactions")}>
              Transactions
            </button>
          </div>
          <div className="btn-champ-div">
            {/* <button
              className={`${
                tabValue == "Setting"
                  ? "active-btn-champ"
                  : "deactive-btn-champ"
              }`}
              onClick={() => setTabValue("Setting")}
            >
              Setting
            </button> */}
          </div>
          </div>
        </div>

        <div className="col-md-9 col-xl-10 champ-dashboard-view">
          <div>
            {tabValue == "Dashboard" ? (
              <Dashboard currency={currency} setTabValue={setTabValue} />
            ) : tabValue == "Users" ? (
              <UserList
                currency={currency}
                submitSearchValue={submitSearchValue}
                sortValue={sortValue}
                setTotalCount={setTotalCount}
              />
            ) : tabValue == "Hosts" ? (
              <HostList
                currency={currency}
                submitSearchValue={submitSearchValue}
                sortValue={sortValue}
                setTotalCount={setTotalCount}
              />
            ) : tabValue == "Sponsors" ? (
              <div className="">
                <SponsorList
                  currency={currency}
                  submitSearchValue={submitSearchValue}
                  sortValue={sortValue}
                  setTotalCount={setTotalCount}
                />
              </div>
            ) : tabValue == "Transactions" ? (
              <Transaction 
                 currency={currency} 
                 submitSearchValue={submitSearchValue}
                 statusValue={statusValue} 
                 sortValue={sortValue}
                 setPdfDownload={setPdfDownload}
                 setTotalCount={setTotalCount} />
            ) : tabValue == "Setting" ? (
              <div className="">
                <h4>{tabValue}</h4>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      </div>
    </div>
    </>);
};
export default ChampDashboard;
