import { Link } from "react-router-dom";



const Contact_Us = () => {
  return (<>

    <div className="contact-us-page">
      <div>
        <h2 className="text-center ">Contact me</h2>
      </div>

      <div className="mt-4">
        <p className="htw-span">Get in Touch: Feel free to reach out to me with any questions, inquiries, or just to say hello! <br /> I'm always happy to hear from you.</p>

        <div className="mt-4">
          <p className="m-0 "> Email: support@sponsorringus.com </p>
          <p className="  mt-1 ">Mob: +91-8104443227</p>
        </div>
        <div className="mt-4">
          <h5 className="">Connect with me on social media -</h5>
          <p className="m-0">Instagram: <Link target="_blank" to='https://www.instagram.com/sponsorringus'>https://www.instagram.com/sponsorringus</Link> </p>
          <p className="m-0">Facebook: <Link target="_blank" to='https://www.facebook.com/profile.php?id=61557181199441'>https://www.facebook.com/profile.php?id=61557181199441</Link> </p>
          <p className="m-0">LinkedIn: <Link target="_blank" to='https://www.linkedin.com/company/sponsorringus'>https://www.linkedin.com/company/sponsorringus</Link> </p>
          <p className="m-0">Twitter: <Link target="_blank" to='https://twitter.com/sponsorringus'>https://twitter.com/sponsorringus</Link></p>
        </div>

        <div className="mt-4">
          <h5 className="">Address:</h5>
          <p className="mb-0 text-dark"> </p>
          <p className="m-0">2516, The Solus <br /> Hiranandani Estate, Thane (West)- 400607 <br />Maharashtra</p>
        </div>

        <div className="mt-4">
          <h5 className="">Powered By:</h5>
          <p className="mb-0 text-dark">  RIGHTKLICK CONSULTANTS PRIVATE LIMITED  </p>

        </div>


      </div>
    </div>
  </>)
};

export default Contact_Us;