
import React, { useState, useEffect, useRef } from "react";
import "react-quill/dist/quill.snow.css";
import imageicon from "../../assets/images/image-upload-icon.svg";
import Documenticon from "../../assets/images/document-upload-icon.svg";
import arrow from "../../assets/images/arrow2.svg";
import pdfimg from "../../assets/images/pdf.png";
import SelectChampModal from "./SelectChampsModal";
import WarningModal from "../Common/WarningModal";
import { useSelector } from "react-redux";
import { IoClose } from "react-icons/io5";
import { toast } from "react-toastify";
import { MdDelete } from "react-icons/md";
import { FaRegCircleCheck } from "react-icons/fa6";
import upload from "../../assets/images/upload-icon.png";
import { Event_MediaHub } from "../../utils/CreateEvents/createEvent";
import { DeleteBanner, Get_Edit_Event_Media_Hub } from "../../utils/EditEvent/editEvent";
import { Event_Image_Docs_delete } from "../../utils/editevents/EditEvent";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { Link } from "react-router-dom";

const EventMediaHub = ({ event_id, setTabValue, Messages, isEdit, Eid, tabValue }) => {
  const [video_link, setVideo_link] = useState('');
  const [images, setImages] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [selectChampMdl, setSelectChampMdl] = useState(false);
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
  const [multipleVideoLink, setMultipleVideoLink] = useState([]);
  const [showPdf, setShowPdf] = useState([]);

  const profileinfo = useSelector((data) => data?.Profile_data?.ProfileData);
  const ConfigData = useSelector((state) => state?.config_data?.configData);
  const baseUrls = ConfigData?.base_urls;
  const [formData, setFormData] = useState({
    banner: null,
    logo: null,
    website_link: ""
  });
  const [selectedBannerFiles, setSelectedBannerFiles] = useState([]);
  const [errors, setErrors] = useState({
    multipleVideoLink: "",
    imagess: "",
    documentss: "",
  });
  const [predefine_Banner, setPredefine_Banner] = useState([]);
  const [showImages, setShowImages] = useState([]);
  const [isHovered, setIsHovered] = useState(false);

  const refs = {
    banner: useRef(null),
    logo: useRef(null),
    website_link: useRef(null)
  };

  const EventmediaData = async () => {
    try {
      Get_Edit_Event_Media_Hub(Eid).then((response) => {
        const data = response?.data?.data;
        setFormData(data);
        setMultipleVideoLink(data?.video_links.map((item) => item?.video_link));
        setShowImages(
          data?.event_gellary.filter(
            (item) => item?.mime_type == "jpg" || item?.mime_type == "png" || item?.mime_type == "webp"
          )
        );
        setShowPdf(
          data?.event_gellary.filter(
            (item) => item?.mime_type == "pdf" || item?.mime_type == "doc"
          )
        );
        //setSelectedBannerFiles([]);
        setPredefine_Banner(data?.event_banner);
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (isEdit && tabValue == 'Event-Media-Hub') {
      EventmediaData();
    }
  }, []);

  const handleAddMultipleURL = () => {
    if (video_link.trim() !== '') {
      const updatedLinks = [...multipleVideoLink, video_link];
      if (updatedLinks.length <= 5) {
        setMultipleVideoLink(updatedLinks);
        setVideo_link('');
      } else {
        toast.warn("You can only add up to 5 video links.", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          theme: "light",
        });
      }
    }
  };

  const handleDeleteURL = (index) => {
    const updatedVideoLinks = multipleVideoLink.filter((item, idx) => idx !== index);
    setMultipleVideoLink(updatedVideoLinks);
  };

  const handleFileChangeImage = (event) => {
    const files = event.target.files;
    const imageArray = [];

    if (images.length + files.length > 6) {
      toast.warn("You can only add up to 6 images", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        theme: "light",
      });
      return;
    }
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      imageArray.push(file);
    }
    setImages([...images, ...imageArray]);
  };


  const handleRemoveImage = (idx) => {
    const val = images.filter((item, index) => index !== idx)
    setImages(val)
  }


  const handleFileChangeDocument = (event) => {
    const files = event.target.files;
    const documentArray = [];

    if (documents.length + files.length > 3) {
      toast.warn("You can only add up to 3 documents", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        theme: "light",
      });
      return;
    }

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      documentArray.push(file);
    }
    setDocuments([...documents, ...documentArray]);
  };

  const handleRemoveDocument = (idx) => {
    const val = documents.filter((item, index) => index !== idx)
    setDocuments(val)
  }


  const submitData = async () => {
    if (!validateForm()) {
      return;
    }
    const FData = {
      banner_images: selectedBannerFiles,
      logo: formData?.logo,
      video_links: multipleVideoLink,
      images: images,
      website_link: formData?.website_link,
      documents: documents,
    };
    try {
      setSubmitBtnLoading(true);
      await Event_MediaHub(event_id, FData).then((result) => {
        if (result?.data?.success) {
          Messages(result?.data?.message);
        }
        setTabValue("Social-Hub")
      });
    } catch (error) {
      console.log(error);
    }
    finally {
      setSubmitBtnLoading(false);
    }
  };

  const validateForm = () => {
    const errors = {};

    // if (multipleVideoLink.length === 0) {
    //   errors.multipleVideoLink = "Please provide at least one video link.";
    // } 

    // if (!formData.banner) {
    //   errors.banner = "Banner is required";
    // } else if (formData?.banner?.size > 1 * 1024 * 1024) {
    //   errors.banner = "Banner size exceeds the maximum allowed size (2MB).";
    // }

    if (selectedBannerFiles?.length == 0 && predefine_Banner?.length == 0) {
      errors.banner = "Please add at least one banner.";
    }

    if (!formData?.logo) {
      errors.logo = "Logo is required";
    } else if (formData?.logo?.size > 1 * 1024 * 1024) {
      errors.logo = "Logo size exceeds the maximum allowed size (2MB).";
    }

    // if (images.length === 0) {
    //   errors.imagess = "Please add at least one image.";
    // }

    // if (documents.length === 0) {
    //   errors.documentss = "Please add at least one document.";
    // }

    setErrors(errors);
    return Object.keys(errors)?.length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "logo") {
      setFormData({
        ...formData,
        [name]: files[0],
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  }

  const handleBannerFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedBannerFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const handleBannerRemoveImage = (idx) => {
    const val = selectedBannerFiles?.filter((item, index) => index !== idx)
    setSelectedBannerFiles(val)
  }

  const handleDltImageDocs = async (id) => {
    await Event_Image_Docs_delete(id).then((response) => {
      if (response?.data?.success == true) {
        EventmediaData();
        Messages(response?.data?.message);
      }
    });
  };

  const handleRemoveBanner = async (id) => {
    try {
      const response = await DeleteBanner(id);
      Messages(response?.data?.message);
      EventmediaData();
    } catch (err) {
      console?.log(err)
    }
  }

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  // console.log("selectedBannerFiles",selectedBannerFiles.length)
  useEffect(() => {
    const firstErrorKey = Object.keys(errors)[0];
    if (firstErrorKey && refs[firstErrorKey] && refs[firstErrorKey].current) {
      refs[firstErrorKey].current.focus();
    }
  }, [errors]);

  return (<>
    {selectChampMdl && (
      <SelectChampModal setSelectChampMdl={setSelectChampMdl} />
    )}

    <div className="basicInfo ">
      <div className="d-flex align-items-center gap-1 position-relative">
        <p className="createevent-head-p">This section allows you to upload and manage various media and documents related to your event to enhance your event's
          presentation and provide sponsors with essential information.</p>
        {/* <h2 className="event-heading mb-0 d-flex flex-column flex-md-row align-items-md-center gap-md-2">Event Media Hub</h2>
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <IoMdInformationCircleOutline size={23} />
        </div>
        {isHovered && (
          <div className="info-popup" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <p className="info-popup-text">This section allows you to upload and manage various media and documents related to your event to enhance your event's
              presentation and provide sponsors with essential information.</p>
          </div>
        )} */}
      </div>

      <div className="row">
        <div className="col-md-6">
          <p className="Bannerimg-head mb-0">
            Banner Image<span className="asterisk">*</span>
            <IoMdInformationCircleOutline size={16} color="black" title="Upload a main banner image for your event." />
          </p>
          <label className="bannerimg-div mt-2">
            <input
              className="d-none"
              type="file"
              name="banner"
              accept="image/*"
              multiple
              onChange={handleBannerFileChange}
              ref={refs?.banner}
            />
            <div className="upload-image-content">
              <img src={upload} alt="upload" />
              <p className="uploadimg-font">Upload Banner Image</p>
              <p className="img-format-text">
                Image format: .jpg or .png
              </p>
              <p className="img-format-text">
                Recommended size: w: 1440px, H: 600px
              </p>
              <div>
                {formData?.banner && (
                  <FaRegCircleCheck color="green" size={20} />
                )}
              </div>
            </div>
          </label>
          {errors?.banner && (
            <span className="error">{errors?.banner}</span>
          )}
        </div>

        <div className="col-md-6">
          <p className="Bannerimg-head">
            Logo<span className="asterisk">*</span>
            <IoMdInformationCircleOutline size={16} color="black" title="Upload a logo image for your event, this is the first image attendees will see. " />
          </p>
          <label className="bannerimg-div mt-2">
            <input
              className="d-none"
              type="file"
              name="logo"
              accept="image/*"
              onChange={handleInputChange}
              ref={refs?.logo}
            />
            <div className="upload-image-content">
              <img src={upload} alt="upload" />
              <p className="uploadimg-font">Upload Logo Image</p>
              <p className="img-format-text">
                Image format: .jpg or .png
              </p>
              <p className="img-format-text">
                Recommended size: w: 274px, H: 330px
              </p>
              {formData?.logo && (
                <FaRegCircleCheck color="green" size={20} />
              )}
            </div>
          </label>
          {errors?.logo && <span className="error">{errors?.logo}</span>}
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">

          {selectedBannerFiles?.length > 0 &&
            <div className="mt-2">
              <div className="banner-main-div">
                <div className="row">

                  {
                    selectedBannerFiles?.map((val, index) => {
                      return (<>
                        <div className="position-relative mt-2 col-6 d-flex justify-content-center">
                          {val instanceof File &&
                            <img
                              className="banner-image"
                              src={URL?.createObjectURL(val)}
                              alt="updateimg" />
                          }
                          <button className="image-remove-icon" onClick={() => handleBannerRemoveImage(index)}>
                            <MdDelete size={20} color="white" />
                          </button>
                        </div>
                      </>)
                    })
                  }
                </div>
              </div>
            </div>
          }
          <div className="row">
            {predefine_Banner?.map((val) => {
              return (
                <>
                  <div className="position-relative mt-2 col-6 d-flex justify-content-center">
                    <img
                      className="banner-image"
                      src={`${baseUrls?.event_banner_image_url}/${val?.filename}`}
                      alt="updateimg"
                    />
                    <button
                      className="image-remove-icon"
                      onClick={() => handleRemoveBanner(val?.id)}>
                      <MdDelete size={20} color="white" />
                    </button>
                  </div>
                </>
              );
            })}
          </div>
        </div>


        <div className="col-md-6">
          {formData?.logo && (
            <div className="mt-2">
              <div className="d-flex justify-content-end">
                {/* <button className="img-close-btn">
                  <IoClose
                    size={24}
                    onClick={() => setFormData({ ...formData, logo: "" })}
                  />
                </button> */}
              </div>

              <div className="row mt-2 d-flex justify-content-center">
                {formData?.logo instanceof File ? (
                  <div className="col-6 position-relative">
                    <img className="event-logo-img"
                      src={URL.createObjectURL(formData?.logo)}
                      alt="updateimg" />
                    <button
                      className="image-remove-icon"
                      onClick={() => setFormData({ ...formData, logo: "" })}>
                      <MdDelete size={20} color="white" />
                    </button>
                  </div>
                ) : (
                  <div className="col-6 position-relative">
                    <img className="event-logo-img"
                      src={`${baseUrls?.logo_image_url}/${formData?.logo}`}
                      alt="updateimg" />

                    <button className="image-remove-icon"
                      onClick={() => setFormData({ ...formData, logo: "" })}>
                      <MdDelete size={20} color="white" />
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="mt-4">
        <div className="row">
          <h4 className="form-heading">Images (Maximum 6) <IoMdInformationCircleOutline size={16} color="black" title="Add additional images to showcase different aspects of your event." /></h4>
          <div className="d-flex flex-column align-items-center col-md-12">
            <label>
              <img className="upload-icon" src={imageicon} alt="img-upload" />
              <input
                className="d-none"
                type="file"
                multiple
                accept="image/*"
                onChange={handleFileChangeImage}
              />
            </label>
            <div className="">The maximunm size of an image can be 1 MB</div>
            {/* <div className="pt-3">
                <button className="btn Addlink-btn ms-4">Add Images</button>
              </div> */}
          </div>
          <div className="row mt-3">
            {images?.map((file, index) => (
              <div key={index} className="col-2 position-relative py-1 px-1">
                <button className="image-remove-icon" onClick={() => handleRemoveImage(index)}>
                  <MdDelete size={20} color="white" />
                </button>
                {file instanceof File &&
                  <img
                    src={URL?.createObjectURL(file)}
                    alt={`selected-${index}`}
                    className="event-gellary-img"
                  />
                }
              </div>
            ))}
            {showImages?.map((val, index) => {
              return (
                <>
                  <div
                    className="col-2 position-relative px-1 py-1"
                    key={index}>
                    <button
                      className="image-remove-icon"
                      onClick={() => handleDltImageDocs(val?.id)}>
                      <MdDelete size={20} color="white" />
                    </button>
                    <img
                      className="event-gellary-img"
                      src={`${baseUrls?.event_image_url}/${val?.filename}`}
                      alt="img1"
                    />
                  </div>
                </>
              );
            })}

          </div>
          {/* {errors.imagess && <span className="error">{errors.imagess}</span>} */}
        </div>
      </div>


      <div className="">
        <div className="row mt-4">
          <h4 className="form-heading">Documents (Maximum 3) <IoMdInformationCircleOutline size={16} color="black" title="Upload any documents related to the event." /></h4>
          <div className="d-flex flex-column align-items-center col-md-12">
            <label className="">
              <img
                className="upload-icon"
                src={Documenticon}
                alt="img-upload"
              />
              <input
                className="d-none"
                type="file"
                accept=".pdf,.doc,.docx,.xls,.xlsx,.txt"
                multiple
                onChange={handleFileChangeDocument}
              />
            </label>
            <div className="">The maximunm size of a file can be 4 MB</div>
            {/* <div className="pt-3">
                <button className="btn Addlink-btn ms-4">Add Documents</button>
              </div> */}
          </div>
          <div className="row mt-3 mb-3">
            {documents?.map((file, index) =>{ 
              return (
              <div className="px-1 py-1 col-2 position-relative" key={index}>
                <button className="image-remove-icon" onClick={() => handleRemoveDocument(index)}>
                  <MdDelete size={20} color="white" />
                </button>
                <div className="pdf-div">
                  <img src={pdfimg} alt="pdf" />
                  <p className="pdf-title">{file?.name}</p>
                </div>
              </div>
            )}
            )}
            {showPdf?.map((val, index) => {
              return (
                <div
                  className="px-1 position-relative py-1 col-2"
                  key={index}>
                  <button
                    className="image-remove-icon"
                    onClick={() => handleDltImageDocs(val?.id)}>
                    <MdDelete size={20} color="white" />
                  </button>
                  <div className="pdf-div">
                    <img src={pdfimg} alt="pdf" />
                    <p className="pdf-title">{val?.filename}</p>
                  </div>
                </div>
              );
            })}
          </div>
          {/* {errors.documentss && (
              <span className="error">{errors.documentss}</span>
            )} */}
        </div>
      </div>

      <div className="mt-3">
        <h4 className="form-heading">Videos <IoMdInformationCircleOutline size={16} color="black" title="Upload any videos related to the event" /></h4>
        <div className="row">
          <div className="col-md-8">
            <label className="form-label ">Video URL</label>
            <div>
              <div className="d-flex form-group ">
                <div className="col-8">
                  <input
                    type="url"
                    value={video_link}
                    onChange={(e) => setVideo_link(e.target.value)}
                    className="form-control"
                    placeholder="Enter Video URL"
                  />
                </div>
                <div>
                  <button className="saveandcontinue-btn ms-4" onClick={handleAddMultipleURL}>Add Link</button>
                </div>
              </div>
              <div className="col-md-10">
                {multipleVideoLink?.map((item, idxx) => {
                  return (
                    <div className="create-msg-date-time-bar d-flex align-items-center">
                      <span className="create-Message">{item}</span>
                      <div className="">
                        <button className="create-Date-Time" onClick={() => handleDeleteURL(idxx)}>Remove</button>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
            {/* {errors.multipleVideoLink && (
                <span className="error">{errors.multipleVideoLink}</span>
              )} */}
          </div>
          {/* <YouTube videoId="wZeHdXlJx4M?si=SxSV9l17ABlJvX1d" opts={opts} /> */}
        </div>
      </div>

      <div className="row mt-3">
        <h5 className="form-section-heading">Event Website <IoMdInformationCircleOutline size={16} color="black" title="Enter your event’s official website for more detailed information" /></h5>
        <div className="form-group col-md-8">
          <label className="form-label mb-0">Website URL</label>
          <div className="col-8">
            <input
              type="text"
              value={formData.website_link}
              name="website_link"
              onChange={handleInputChange}
              className="form-control"
              placeholder="Enter Website URL"
            />
          </div>
          {/* {errors.website_link && (
                  <span className="error">{errors.website_link}</span>
                 )} */}
        </div>
      </div>

      <div className="row mt-3">
        <h5 className="form-section-heading">Host</h5>
        <div className="col-4 row">
          <div className="col-md-7 ">
            <div className="position-relative event-profile-img">
              <label className="form-label">Logo<span className="asterisk">*</span></label>
              <img
                className="event-profile-img"
                src={profileinfo?.image_type == "browse" ?
                  `${baseUrls?.customer_image_url}/${profileinfo?.image}` :
                  `${baseUrls?.avatar_image_url}/${profileinfo?.predefine_image}`}
                alt="profile"
              />
            </div>
          </div>
        </div>

        <div className="col-4 row">
          <div className="col-md-7 ">
            <div className="position-relative event-profile-img">
              {
                profileinfo?.website_link &&
                <div>
                  <label className="form-label">Website<span className="asterisk">*</span></label>
                  <Link to={profileinfo?.website_link}>{profileinfo?.website_link}</Link>
                </div>
              }
            </div>
          </div>
        </div>
      </div>


      <div className="d-flex justify-content-end align-items-center mt-4">
        {/* <button className="btn delete-event-btn">Delete Event</button> */}
        <div className="d-flex gap-2">
          {/* {!profileinfo?.user_type &&
              <button className="d-flex align-items-center gap-1 btn saveandcontinue-btn" 
                onClick={()=>setSelectChampMdl(!selectChampMdl)} type="button">Change Champ</button>} */}

          <button className="saveandcontinue-btn d-flex align-items-center gap-2" onClick={submitData}>
            {submitBtnLoading && (
              <div className="spinner-border text-light " role="status">
                <span className="sr-only">Loading...</span>
              </div>
            )}
            Save and Continue
            <img className="arrow-img" src={arrow} alt="arrow" />
          </button>
        </div>
      </div>
    </div>
  </>
  );
};

export default EventMediaHub;
