import ReactQuill from "react-quill";
import React from "react";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import moment from "moment";
import { MdDelete } from "react-icons/md";
import { FiPlus } from "react-icons/fi";

// Utility function to group schedules by date
const groupSchedulesByDate = (schedules) => {
    return schedules?.reduce((acc, schedule) => {
        const date = schedule?.date;
        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date]?.push(schedule);
        return acc;
    }, {});
};

const stripHtmlTags = (html) => {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  };

const EditDetailedSchedule = ({ 
    created_Schedule,
    schedules,
    setSchedules,
    additionalSchedules,
    setAdditionalSchedules,
    dates,
    isEdit
}) => {

    
    const groupedSchedules = groupSchedulesByDate(schedules);
    const maxLength = 500;

    const handleScheduleChange = (value, date, field, index, isAdditional = false) => {
        const cleanDescription = stripHtmlTags(value);
        let updatedValue = value;      
        if (isAdditional) {
            if (cleanDescription.length > maxLength) {
                updatedValue = value.slice(0, maxLength);
                alert(`Description cannot exceed ${maxLength} characters.`);
              }else{
                setAdditionalSchedules((prevState) => {
                    const updatedSchedules = [...(prevState[date] || [])];
                    updatedSchedules[index] = { ...updatedSchedules[index], [field]: value };
                    return {
                        ...prevState,
                        [date]: updatedSchedules,
                    };
                });
              }
        } else {
            if (cleanDescription.length > maxLength) {
                updatedValue = value?.slice(0, maxLength);
                alert(`Description cannot exceed ${maxLength} characters.`);
              }else{
              setSchedules((prevSchedules) =>
                prevSchedules.map((schedule) =>
                    schedule.date === date && schedule.id === index
                     ? { ...schedule, [field]: value }
                     : schedule )
            );
        }
     }
    };

    const handleAddMore = (date) => {
        setAdditionalSchedules((prevState) => ({
            ...prevState,
            [date]: [
                ...(prevState[date] || []),
                { date: date, title: "", description: "", start_time: "", end_time: "" },
            ],
        }));
    };

    const handleRemoveSchedule = (date, index, isAdditional = false) => {
        if (isAdditional) {
            setAdditionalSchedules((prevState) => {
                const updatedSchedules = (prevState[date] || []).filter((_, idx) => idx !== index);
                const newState = { ...prevState };
                if (updatedSchedules.length > 0) {
                    newState[date] = updatedSchedules;
                } else {
                    delete newState[date];
                }
                return newState;
            });
        } else {
            setSchedules((prevSchedules) =>
                prevSchedules.filter((s, idx) => !(s.date === date && idx === index))
            );
        }
    };
    const getCurrentLength = (value) => stripHtmlTags(value)?.length;
    return (
        <>
        {created_Schedule == "1"  && (
        <div>
         <h5 className="form-section-heading">Additional Dates</h5>
        <div className="schedule-main-div">
         {Object.keys(groupedSchedules).map((date) => (
          <div className="schedule-div" key={date}>
              <div>
                  <label className="form-label date-title">
                      Date: {moment(date).format("D MMMM YYYY")}
                  </label>
              </div>
              {groupedSchedules[date].map((schedule, idx) => (
                  <div className="row" key={schedule.id}>
                      <div className="form-group col-6 col-md-4">
                          <label className="form-label">Title</label>
                          <input
                              type="text"
                              className="form-control"
                              name="title"
                              placeholder="Enter Title"
                              value={schedule.title || ""}
                              onChange={(e) => handleScheduleChange(e.target.value, date, "title", schedule.id)}
                          />
                      </div>
                      <div className="form-group col-6 col-md-4">
                          <label className="form-label">Start Time</label>
                          <input
                              type="time"
                              className="form-control"
                              step="900"
                              name="start_time"
                              value={schedule.start_time || ""}
                              onChange={(e) => handleScheduleChange(e.target.value, date, "start_time", schedule.id)}
                              onFocus={(e) => e.currentTarget.showPicker()}
                          />
                      </div>
                      <div className="form-group col-6 col-md-4">
                          <label className="form-label">End Time</label>
                          <input
                              type="time"
                              className="form-control"
                              step="900"
                              name="end_time"
                              value={schedule.end_time || ""}
                              onChange={(e) => handleScheduleChange(e.target.value, date, "end_time", schedule.id)}
                              onFocus={(e) => e.currentTarget.showPicker()}
                          />
                      </div>
                      <div className="form-group col-12 col-md-12">
                      <div className="d-flex justify-content-between">
                        <label className="form-label">Description</label>
                         <p className="Character-0500">
                          Character:
                         <span className="text-style-1">
                          {getCurrentLength(schedule?.description)}/{maxLength}
                         </span>
                         </p>
                         </div>

                          <div style={{ height: "130px", backgroundColor: "white" }}>
                              <ReactQuill
                                  value={schedule.description || ""}
                                  onChange={(value) => handleScheduleChange(value, date, "description", schedule.id)}
                                  theme="snow"
                                  placeholder="Description"
                                  style={{ height: "90px" }}
                              />
                          </div>
                      </div>
                  </div>
              ))}
              <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => handleAddMore(date)}>
                  <FiPlus size={18}/> Add More
              </button>

              <div className="position-relative">
                  {additionalSchedules[date]?.map((schedule, index) => (
                      <div key={index}>
                          <div className="row mt-3">
                              <div className="form-group col-6 col-md-4">
                                  <label className="form-label">Title</label>
                                  <input
                                      type="text"
                                      className="form-control"
                                      name="title"
                                      placeholder="Enter Title"
                                      value={schedule.title}
                                      onChange={(e) => handleScheduleChange(e.target.value, date, "title", index, true)}
                                  />
                              </div>
                              <div className="form-group col-6 col-md-4">
                                  <label className="form-label">Start Time</label>
                                  <input
                                      type="time"
                                      className="form-control"
                                      step="900"
                                      name="start_time"
                                      value={schedule.start_time}
                                      onChange={(e) => handleScheduleChange(e.target.value, date, "start_time", index, true)}
                                      onFocus={(e) => e.currentTarget.showPicker()}
                                  />
                              </div>
                              <div className="form-group col-6 col-md-4">
                                  <label className="form-label">End Time</label>
                                  <input
                                      type="time"
                                      className="form-control"
                                      step="900"
                                      name="end_time"
                                      value={schedule.end_time}
                                      onChange={(e) => handleScheduleChange(e.target.value, date, "end_time", index, true)}
                                      onFocus={(e) => e.currentTarget.showPicker()}
                                  />
                              </div>
                              <div className="form-group col-12 col-md-12">
                                <div className="d-flex justify-content-between">
                                  <label className="form-label">Description</label>
                                  <p className="Character-0500">
                                   Character:
                                <span className="text-style-1">
                                 {getCurrentLength(schedule.description)}/{maxLength}
                               </span>
                                </p>
                                </div>
                                  <div style={{ height: "130px", backgroundColor: "white" }}>
                                      <ReactQuill
                                          value={schedule?.description}
                                          onChange={(value) => handleScheduleChange(value, date, "description", index, true)}
                                          theme="snow"
                                          placeholder="Description"
                                          style={{ height: "90px" }}
                                      />
                                  </div>
                              </div>
                          </div>
                          <button
                              type="button"
                              className="schedule-remove-btn"
                              onClick={() => handleRemoveSchedule(date, index, true)}>
                              <MdDelete size={24} />
                          </button>
                      </div>
                  ))}
              </div>
          </div>
      ))}
  </div>
</div>
            )}
        </>
    );
};

export default EditDetailedSchedule;