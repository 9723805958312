import { useParams } from "react-router-dom";

const MainBannerPages = () => {
    const pathname = useParams();
    return (
        <section className="container">
            <div className="policy-main mt-5 mb-5">
                {
                    pathname.path === "Host" ? (
                        <div className="d-flex justify-content-center mt-5 mb-5 p-5">

                            <h4>{pathname.path} - Event Organizer </h4>
                        </div>
                    ) : pathname.path === "Champ" ? (
                        <div className="d-flex justify-content-center mt-5 mb-5 p-5">

                            <h4>{pathname.path} - Event Explorer </h4>
                        </div>
                    ) : (
                        <div className="d-flex justify-content-center mt-5 mb-5 p-5">
                            <h4>{pathname.path} - Event Sponsor </h4>
                        </div>
                    )
                }
            </div>
        </section>
    )
}
export default MainBannerPages