import { useEffect, useState } from "react";
import { Get_Order_Detail } from "../../utils/myorders/GetOrderDetail";
import Loader from "../../components/Loader/Loader";
import { useSelector } from "react-redux";
import moment from "moment";
import OrderListCard from "../../components/MyOrders/OrderListCard";
import Loader2 from "../../components/Loader/Loader2";
import ReactPaginate from "react-paginate";
import { RiArrowDropDownLine } from "react-icons/ri";
import { IoMdArrowDropdown } from "react-icons/io";
import { IoCloseOutline } from "react-icons/io5";
import OrderListTable from "../../components/MyOrders/OrderListTable";
import { TiArrowLeft } from "react-icons/ti";
import { useNavigate } from "react-router-dom";

const MyOrders = () => {
  const [orderDetail, setOrderDetail] = useState([]);
  const [loading, setLoading] = useState(true);
  const currency = useSelector((val) => val?.CurrencyConvert);
  const [searchError, setSearchError] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [amountDropdownOpen, setAmountDropdownOpen] = useState(false);
  const [dateDropdownOpen, setDateDropdownOpen] = useState(false);
  const [sortValue, setSortValue] = useState("");
  const [statusValue, setStatusValue] = useState("");
  const navigate = useNavigate();

  const handleItemClick = (item) => {
    setStatusDropdownOpen(false); // Close the dropdown after selecting an item
  };

  const GetOrder = async (searchValue) => {
    setLoading(true);
    try {
      const response = await Get_Order_Detail(currentPage, sortValue, searchValue, statusValue);
      setOrderDetail(response?.data?.data);
    } catch (error) {
      setLoading(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    GetOrder();
  }, [currentPage, sortValue, statusValue]);

  const handleSearch = (e) => {
    e.preventDefault();
    if (!searchValue || !searchValue.trim() === "") {
      setSearchError("Invalid search input");
    } else {
      GetOrder(searchValue)
      setSearchError("");
    }
  };

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage?.selected + 1);
  };

  return (
    <>
      <div className="your-events container-fluid">
        <div className="d-flex align-items-center justify-content-between">
          <div className="">
            <h2 className="your-orders-heading m-0">My Sponsorings</h2>
          </div>
          <button className="go-back-btn" onClick={() => navigate(-1)} type="button">
              <TiArrowLeft size={20}/> Go Back
          </button>
          {/* <div className="col-md-6">
            <form onSubmit={handleSearch}>
              <div className="d-flex search-div">
                <input
                  className="form-control search-event-input"
                  name="searchValue"
                  value={searchValue}
                  placeholder="Search Order..."
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <button className="event-search-btn" type="submit">
                  Search
                </button>
              </div>
            </form>
            {searchError && (
              <p className="error-message text-danger ms-1">{searchError}</p>
            )}
          </div> */}
          {/* <div className="col-md-4 d-flex justify-content-end">
            <div className="d-flex gap-2">
              <div className="d-flex align-items-center position-relative gap-1 ">
                <p className="m-0 order-list-text">Sort :</p>
                <button
                  className="d-flex order-dropdown-button"
                  onClick={() => {
                    setAmountDropdownOpen(!amountDropdownOpen);
                    setStatusDropdownOpen(false);
                    setDateDropdownOpen(false)
                  }}>
                  Amount
                  <IoMdArrowDropdown size={24} />
                </button>
                {amountDropdownOpen && (
                  <div className="dropdown-menu-data">
                    <div
                      className="dropdown-item"
                      onClick={() => {setSortValue("amount low to high"); setAmountDropdownOpen(false) }}>
                      Low To High
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={() => {setSortValue("amount high to low"); setAmountDropdownOpen(false)}}>
                      High To Low
                    </div>
                  </div>
                )}
              </div>

              <div className="d-flex align-items-center position-relative gap-1 ">
              <button
                  className="d-flex order-dropdown-button"
                  onClick={() => {
                    setStatusDropdownOpen(!statusDropdownOpen);
                    setAmountDropdownOpen(false);
                    setDateDropdownOpen(false)
                  }}>
                  Status
                  <IoMdArrowDropdown size={24} />
                </button>
                {statusDropdownOpen && (
                  <div className="dropdown-menu-data">
                    <div
                      className="dropdown-item"
                      onClick={() =>{setStatusValue("success");setStatusDropdownOpen(false)}}>
                      Success
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={() =>{setStatusValue("Pending");setStatusDropdownOpen(false)}}>
                      Pending
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={() =>{setStatusValue("failed");setStatusDropdownOpen(false)}}
                    >
                      Failed
                    </div>
                  </div>
                )}

              </div>

              <div className="d-flex align-items-center position-relative gap-1">
              <button
                  className="d-flex order-dropdown-button"
                  onClick={() => {
                    setDateDropdownOpen(!dateDropdownOpen)
                    setStatusDropdownOpen(false);
                    setAmountDropdownOpen(false);
                  }}>
                  Date
                  <IoMdArrowDropdown size={24} />
                </button>
                {dateDropdownOpen && (
                  <div className="dropdown-menu-data">
                    <div
                      className="dropdown-item"
                      onClick={() => {setSortValue("date Latest to oldest");setDateDropdownOpen(false) }}>
                       Latest To Oldest
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={() => {setSortValue("date oldest to latest"); setDateDropdownOpen(false) }}>
                       Oldest To Latest
                    </div>

                  </div>
                )}
              </div>
            </div>
          </div> */}
        </div>


        <div className="d-flex gap-2 justify-content-end ">
          {sortValue && (
            <div className="d-flex justify-content-end mt-3">
              <div className="d-flex gap-2 align-items-center">
                <p className="m-0 order-list-text">Sort By :</p>
                <div className="sort-clear-div ">
                  <p className="m-0 order-list-text text-light text-capitalize p-0">
                    {sortValue}
                  </p>
                  <button
                    className="sort-clear-btn"
                    onClick={() => setSortValue("")}
                  >
                    <IoCloseOutline color="white" size={20} />
                  </button>
                </div>
              </div>
            </div>
          )}

          {statusValue && (
            <div className="d-flex justify-content-end mt-3">
              <div className="d-flex gap-2 align-items-center">
                <p className="m-0 order-list-text">Status:</p>
                <div className="sort-clear-div ">
                  <p className="m-0 order-list-text text-light text-capitalize p-0">
                    {statusValue}
                  </p>
                  <button
                    className="sort-clear-btn"
                    onClick={() => setStatusValue("")}
                  >
                    <IoCloseOutline color="white" size={20} />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>


        {/* <div className="order-head-div mt-5">
          <div className="row d-flex align-items-center">
            <div className="col-2">
              <p className="order-head-text">Transaction Id</p>
            </div>

            <div className="col-2">
              <p className="order-head-text">Event /</p>
              <p className="order-head-text">Package Name</p>
            </div>

            <div className="col-1">
              <p className="order-head-text">Package Amount</p>
            </div>

            <div className="col-2">
              <p className="order-head-text">Coupon Code /</p>
              <p className="order-head-text">Coupon Discount </p>
            </div>

            <div className="col-2">
              <p className="order-head-text">Payable Amount</p>
            </div>

            <div className="col-1">
              <p className="order-head-text">Status</p>
            </div>

            <div className="col-2">
              <p className="order-head-text">Date</p>
            </div>
          </div>
        </div> */}

        {/* <div>
          {loading ? (
            <Loader2 />
          ) : (
            <div className="order-list-main-div">
              {orderDetail?.data?.map((val) => {
                return (
                  <>
                    <OrderListCard val={val} currency={currency} />
                  </>
                );
              })}
            </div>
          )}
        </div> */}
        {loading ? (<>
          <Loader />
        </>
        ) : (
          orderDetail?.data?.length <= 0 ? <h3 className="text-center mt-4">No Order Data Found</h3> :
            <div className="">
              <OrderListTable val={orderDetail?.data} currency={currency} />
            </div>

        )}
        {orderDetail?.data?.length < orderDetail?.total && (
          <div className="pagination-container mt-3">
            <ReactPaginate
              pageCount={Math.ceil(orderDetail?.total / orderDetail?.per_page)}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName="pagination"
              activeClassName="selected"
              disabledClassName="disabled"
            />
          </div>
        )}
      </div>
    </>
  );
};

export default MyOrders;
