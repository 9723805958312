import ReactPaginate from "react-paginate";
import { IoMdLogOut, IoMdLogIn } from "react-icons/io";
import { BiSolidEditAlt } from "react-icons/bi";
import { Link } from "react-router-dom";
import { NotificationDatalist, GetLoginActivityMsg } from "../../utils/notification/notify";
import { useEffect, useState } from 'react'
import moment from "moment";
import Loader2 from "../../components/Loader/Loader2";
import { useSelector } from "react-redux";
import DeviceDetectComponent from "../Common/DeviceDetect";
import { GrUserNew } from "react-icons/gr";


const AcountLoginActivity = () => {
    const profileData = useSelector((state) => state?.Profile_data?.ProfileData);

    const [showNotification, setShowNotification] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);

    const getData = async () => {
        setLoading(true)
        try {
            await GetLoginActivityMsg(profileData?.id, currentPage)
                .then((response) => {
                    if (response?.data?.success) {
                        setShowNotification(response?.data?.data)
                        setLoading(false)
                    }
                })
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getData();
    }, [currentPage]);

    const iconMap = {
        "signup": { icon: <GrUserNew size={23} color="#f43254" />, link: "/profile" },//
        "login": { icon: <IoMdLogIn size={23} color="#f43254" />, link: "#" },//
        "logout": { icon: <IoMdLogOut size={23} color="#f43254" />, link: "#" },//
        "changePassword": { icon: <BiSolidEditAlt size={23} color="#f43254" />, link: "#" },//
        "changeEmail": { icon: <BiSolidEditAlt size={23} color="#f43254" />, link: "#" },//
        "changeNumber": { icon: <BiSolidEditAlt size={23} color="#f43254" />, link: "#" },//
        // socialLogin: { icon: <IoMdLogIn size={23} color="#f43254" />, link: "#" },//
        "forgetPassword": { icon: <BiSolidEditAlt size={23} color="#f43254" />, link: "#" },//
    };

    const IconComponent = ({ iconType }) => {
        const item = iconMap[iconType] || { icon: <div>No icon</div> };
        return <div>{item.icon}</div>;
    };

    const handlePageClick = (selectedPage) => {
        setCurrentPage(selectedPage.selected + 1);
    };

    return (
        <>
            <div className="container py-2">
                <div className="row msg-date-time-bar-ac m-0">
                    <span className="col-4 Message">Message</span>
                    <span className="col-4 Date-Time">Login Date & Time</span>
                    <span className="col-4 Date-Time">Login Device</span>
                </div>
                <div className="msg-datetime-header overflow-hidden px-0">
                    {loading ? <Loader2 /> :
                        showNotification?.data?.map((val) => {
                            const DeviceType = JSON.parse(val?.device_type)
                            return (

                                <Link className="row msg-datetime-value-ac" to={iconMap[val.log_type]?.link || "/"} >
                                    <span className="col-4 tradeShow d-flex gap-3"><IconComponent iconType={val?.log_type
                                    } /> {val?.description}</span>

                                    <span className="col-4 tradeDate-time"> {moment(val?.created_at
                                    ).format("MMM DD, YYYY | hh:mm A")}</span>
                                    <div className="col-4 tradeDate-time">
                                        <span>{DeviceType?.type == 'Mobile' ? <>{`${DeviceType?.os} ${DeviceType.type}`}</> : DeviceType?.type}</span>
                                        {val?.city == null ? <p className="">-</p> : <p className="m-0">{`${val?.city},${val?.region_code},${val?.country_code} `}</p>}
                                        <p>IP Address: {val?.ip_address}</p>
                                    </div>
                                </Link>

                            )
                        })}
                </div>
                {showNotification?.data?.length < showNotification?.total && (
                    <div className="pagination-container">
                        <ReactPaginate
                            pageCount={Math.ceil(showNotification?.total / showNotification?.per_page)}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageClick}
                            containerClassName="pagination"
                            activeClassName="selected"
                            disabledClassName="disabled"
                        />
                    </div>
                )}
            </div >
        </>
    )
}
export default AcountLoginActivity;