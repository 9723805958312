import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import "swiper/css/grid";
import { useState } from 'react'
import { Grid, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import pdfimg from "../../assets/images/pdf.png";
import Lightbox from "react-image-lightbox";

const EventMediaHub = ({
    event,
    baseUrls,
    setPdfFile,
    pdfFile
}) => {

    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [viewImages, setViewImages] = useState([]);


    const openLightbox = (val, index) => {
        {
            const gImage = event?.event_gellary?.filter((val) => {
                return (
                    val?.mime_type?.toLowerCase() == "jpg" ||
                    val?.mime_type?.toLowerCase() == "png" ||
                    val?.mime_type?.toLowerCase() == "jpeg" ||
                    val?.mime_type?.toLowerCase() == "webp"
                );
            });
            if (val == "ImageGallery") {
                const images = gImage?.map(
                    (item) => `${baseUrls?.event_image_url}/${item?.filename}`
                );
                setViewImages(images);
            }
            else if (val == "PastEventGallery") {
                const images = gImage?.map(
                    (item) => `${baseUrls?.past_event_gellary}/${item?.filename}`
                );
                setViewImages(images);

            }
        }

        setPhotoIndex(index);
        setIsOpen(true);
    };


    const breakpoints = {
        300: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        500: {
            slidesPerView: 3,
            spaceBetween: 20,
        },
        768: {
            slidesPerView: 3,
            spaceBetween: 20,
        },
        1024: {
            slidesPerView: 4,
            spaceBetween: 20,
        },
        1200: {
            slidesPerView: 4,
            spaceBetween: 30,
        },
        1600: {
            slidesPerView: 4,
            spaceBetween: 30,
        },
    };


    return (
        <>

            {isOpen && (
                <Lightbox
                    mainSrc={viewImages[photoIndex]}
                    nextSrc={viewImages[(photoIndex + 1) % viewImages?.length]}
                    prevSrc={viewImages[(photoIndex + viewImages?.length - 1) % viewImages?.length]}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex((photoIndex + viewImages?.length - 1) % viewImages?.length)
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % viewImages?.length)
                    }
                />
            )}

            {/* Event Images ------------------------------------------------------- */}

            {event?.event_gellary
                ?.filter((val) => {
                    return (
                        val?.mime_type?.toLowerCase() === "jpg" ||
                        val?.mime_type?.toLowerCase() === "png" ||
                        val?.mime_type?.toLowerCase() === "jpeg" ||
                        val?.mime_type?.toLowerCase() === "webp"
                    );
                })?.length > 0 && (
                    <div className="social-media-div mt-0 pt-0">
                        <div className="d-flex align-items-center gap-2">
                            <h4 className="social-child-heading m-0">Images</h4>
                            {/* <hr className="w-100 d-none d-xl-flex" /> */}
                        </div>

                        <div className="event-gallery-image-normal-mode mt-3 ms-0">
                            <Swiper
                                spaceBetween={20}
                                // slidesPerView={4}
                                breakpoints={breakpoints}
                                modules={[Navigation, Grid]}
                                navigation={true}
                                loop={false}
                                grid={{
                                    rows: 3,
                                    fill: "row",
                                }}
                                className="mySwiper"
                            >
                                {event?.event_gellary
                                    ?.filter((val) => {
                                        return (
                                            val?.mime_type?.toLowerCase() == "jpg" ||
                                            val?.mime_type?.toLowerCase() == "png" ||
                                            val?.mime_type?.toLowerCase() == "jpeg" ||
                                            val?.mime_type?.toLowerCase() == "webp"
                                        );
                                    })
                                    ?.map((val, index) => {
                                        return (
                                            <SwiperSlide key={index} className="">
                                                <div className="p-0 " key={index}>
                                                    <img
                                                        className="event-gellary-img"
                                                        role="button"
                                                        src={`${baseUrls?.event_image_url}/${val?.filename}`}
                                                        alt="img1"
                                                        onClick={() => openLightbox('ImageGallery', index)}
                                                    />
                                                </div>
                                            </SwiperSlide>
                                        );
                                    })}
                            </Swiper>
                        </div>

                        <div className="event-gallery-image-print-mode mt-3">
                            {event?.event_gellary
                                ?.filter((val) => {
                                    return (
                                        val?.mime_type?.toLowerCase() == "jpg" ||
                                        val?.mime_type?.toLowerCase() == "png" ||
                                        val?.mime_type?.toLowerCase() == "jpeg" ||
                                        val?.mime_type?.toLowerCase() == "webp"
                                    );
                                })
                                ?.map((val, index) => {
                                    return (
                                        <div className="p-0" key={index}>
                                            <img
                                                className="event-gellary-img"
                                                role="button"
                                                src={`${baseUrls?.event_image_url}/${val?.filename}`}
                                                alt="img1"
                                            />
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                )}

            {/* Past Event Images ------------------------------------------------------- */}

            {event?.past_event_gellary?.length > 0 && (
                <div className="social-media-div">
                    <div className="d-flex align-items-center gap-2">
                        <h2 className="social-child-heading m-0">
                            Past Event Images
                        </h2>
                    </div>

                    <div className="mt-3 ms-0">
                        <Swiper
                            spaceBetween={20}
                            slidesPerView={4}
                            modules={[Navigation, Grid]}
                            navigation={true}
                            loop={false}
                            grid={{
                                rows: 3,
                                fill: "row",
                            }}
                            autoplay={{
                                delay: 2000,
                                disableOnInteraction: false,
                            }}
                            className="mySwiper"
                        >
                            {event?.past_event_gellary?.map((val, index) => (
                                <SwiperSlide key={index}>
                                    <div className="p-0 ">
                                        <img
                                            className="event-gellary-img"
                                            src={`${baseUrls?.past_event_image_url}/${val?.filename}`}
                                            alt="img1"
                                            onClick={() => openLightbox("PastEventGallery", index)}
                                        />
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            )}

            {/* Pdf------------------------------------------------------- */}

            {event?.event_gellary
                ?.filter((val) => {
                    return (
                        val?.mime_type?.toLowerCase() === "pdf" ||
                        val?.mime_type?.toLowerCase() === "docx" ||
                        val?.mime_type?.toLowerCase() === "pptx"
                    );
                })?.length > 0 && (
                    <div className="social-media-div">
                        <div className="d-flex align-items-center gap-2">
                            <h2 className="social-child-heading m-0">
                                Attached Documents
                            </h2>
                        </div>
                        <div className="event-gellary-document-normal-mode">
                            <div className="mt-3 pdf-main-div ms-0">
                                <Swiper
                                    spaceBetween={30}
                                    //slidesPerView={4}
                                    breakpoints={breakpoints}
                                    modules={[Navigation, Grid]}
                                    navigation={true}
                                    loop={false}
                                    grid={{
                                        rows: 3,
                                        fill: "row",
                                    }}
                                    autoplay={{
                                        delay: 2000,
                                        disableOnInteraction: false,
                                    }}
                                    className="mySwiper"
                                >
                                    {event?.event_gellary
                                        ?.filter((val) => {
                                            return (
                                                val?.mime_type?.toLowerCase() == "pdf" ||
                                                val?.mime_type?.toLowerCase() == "docx" ||
                                                val?.mime_type?.toLowerCase() == "pptx"
                                            );
                                        })
                                        ?.map((val, index) => {
                                            return (
                                                <SwiperSlide key={index}>
                                                    <div className="p-0 cursor-pointer">
                                                        <div className="pdf-div" role="button"
                                                            onClick={() =>
                                                                setPdfFile(val?.filename)
                                                            }>
                                                            <img
                                                                src={pdfimg}
                                                                alt="pdf"
                                                            />
                                                            <p className="pdf-title">
                                                                {val?.filename}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            );
                                        })}
                                </Swiper>
                            </div>
                        </div>

                        <div className="mt-3 event-gellary-document-print-mode">
                            {event?.event_gellary
                                ?.filter((val) => {
                                    return (
                                        val?.mime_type?.toLowerCase() == "pdf" ||
                                        val?.mime_type?.toLowerCase() == "docx" ||
                                        val?.mime_type?.toLowerCase() == "pptx"
                                    );
                                })
                                ?.map((val, index) => {
                                    return (
                                        <div className="document-item-print p-0 cursor-pointer" key={index}>
                                            <div className="pdf-div" role="button">
                                                <img
                                                    src={pdfimg}
                                                    alt="pdf"
                                                />
                                                <p className="pdf-title">
                                                    {val?.filename}
                                                </p>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                )}
        </>
    )
}
export default EventMediaHub;