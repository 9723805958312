import arrow from "../../assets/images/arrow2.svg";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import React, { useEffect, useState, useRef } from "react";
import {
  GoogleMap,
  Marker,
  StandaloneSearchBox,
  useJsApiLoader,
} from "@react-google-maps/api";
import {
  Get_UsersForChamp,
  getEventsCreateInfo,
} from "../../utils/basicInformation/basicinformationManager";
import { ToastContainer } from "react-toastify";
import Loader from "../Loader/Loader";
import { get_city_state_country } from "../../utils/citystatecountry/cityStateCountry";
import SelectChampModal from "./SelectChampsModal";
import Loader2 from "../Loader/Loader2";
import {
  Create_EventSchedule,
  CreateEventEssentials,
  Event_Schedule_UploadImage,
  Get_EventSchedule,
  GetEventBudget,
  GetOnlineMode,
  GetTypeEventEssentials,
} from "../../utils/CreateEvents/createEvent";
import { Tooltip } from "react-tooltip";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CreateEventWarningMdl from "../Common/CreateEvent/CreateEventWarningMdl";
import { useNavigate } from "react-router-dom";
import {
  EventEssentialsEdit,
  Get_Edit_Event,
} from "../../utils/EditEvent/editEvent";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { Get_ProfileDetail } from "../../utils/champ/champAlldata";
import moment from "moment";
import MakeADefaultSchedule from "../Common/CreateEvent/CreateADetailedSchedule";
import UploadEventSchedule from "../Common/CreateEvent/UploadEventSchedule";
import EditDetailedSchedule from "../Common/CreateEvent/EditDetailedSchedule";
import { useSelector } from "react-redux";

const EventEssentialsForm = ({
  EditData,
  profileinfo,
  setEvent_Data,
  setTabValue,
  Messages,
  isEdit,
  Eid,
  tabValue,
  setUserData,
}) => {
  const navigate = useNavigate();
  const [type, setType] = useState([]);
  const [eventBudget, setEventBudget] = useState([]);
  const [onlineMode, setOnlineMode] = useState([]);
  const [champUser, setChampUser] = useState([]);
  const [selectChampMdl, setSelectChampMdl] = useState(false);
  const latlong = JSON.parse(localStorage.getItem("let_long"));
  const [center, setCenter] = useState({
    lat: latlong?.lat ? latlong?.lat : 19.0759837,
    lng: latlong?.lng ? latlong?.lng : 72.8776559,
  });
  const [marker, setMarker] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [createWarningMdl, setCreateWarningMdl] = useState(false);
  // const liveSample = localStorage.getItem("LiveSample");
  // liveSample == 0 ? "Live" : ""
  const [eventDateErrors, setEventDateErrors] = useState({});
  const [images, setImages] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [showPdf, setShowPdf] = useState([]);
  const [showImages, setShowImages] = useState([]);
  const ConfigData = useSelector((state) => state?.config_data?.configData);
  const baseUrls = ConfigData?.base_urls;

  const getTodayDate = () => {
    return new Date().toISOString().split("T")[0];
  };

  const [eventSchedules, setEventSchedules] = useState([
    {
      start_date: "",
      start_time: "",
      end_date: "",
      end_time: "",
    },
  ]);

  const [input, setInput] = useState({
    host_id: "",
    event_title: "",
    event_type: "",
    address: "",
    country_id: "101",
    state_id: "",
    city_id: "",
    pin_code: "",
    start_date: getTodayDate(),
    start_time: "",
    end_date: getTodayDate(),
    end_time: "",
    latitude: 0.0,
    longitude: 0.0,
    description: "",
    event_mode: "",
    event_time_category: "",
    status: "",
    total_budget_id: "",
    house_no: "",
    landmark: "",
    address_type: "",
    online_mode: "",
    others_online_mode: "",
    meeting_passcode: "",
    access_code: "",
    meeting_link: "",
  });

  const [schedules, setSchedules] = useState([]);
  const [additionalSchedules, setAdditionalSchedules] = useState({});

  const refs = {
    event_title: useRef(null),
    event_type: useRef(null),
    event_mode: useRef(null),
    total_budget_id: useRef(null),
    event_time_category: useRef(null),
    meeting_link: useRef(null),
    meeting_passcode: useRef(null),
    access_code: useRef(null),
    online_mode: useRef(null),
    pin_code: useRef(null),
    country_id: useRef(null),
    state_id: useRef(null),
    city_id: useRef(null),
    house_no: useRef(null),

    start_date: useRef(null),
    start_time: useRef(null),
    end_date: useRef(null),
    end_time: useRef(null),
    description: useRef(null),
    // status: useRef(null),
  };

  const [created_Schedule, setCreated_Schedule] = useState("0");
  // const [upload_Schedule, setUpload_Schedule] = useState("0")
  const [dates, setDates] = useState([]);

  //   useEffect(()=>{
  //    const isEmpty = (obj) => Object.keys(obj).length === 0 && obj.constructor === Object;
  //  if (!isEmpty(EditData)) {
  //   setInput(EditData)
  //   } else {
  //   setInput(input);
  //   }
  //  },[])

  const EditEvents = async () => {
    try {
      const response = await Get_Edit_Event(Eid);
      const data = response?.data?.data;
      setInput((prevInput) => ({
        ...prevInput,
        ...data,
        // status: liveSample == 0 ? "Live" : prevInput?.status,
      }));
      // dispatch(setEditdata(response?.data?.data));
      const selectedDate = new Date(EditData?.start_date);
      const todayDate = new Date();
      const ninetyDaysFromToday = new Date(
        todayDate.setDate(todayDate.getDate() + 60)
      );
      if (selectedDate < ninetyDaysFromToday) {
        setShowTooltip(true);
      } else {
        setShowTooltip(false);
      }
    } catch (err) {
      console.log(err);
    }
  };


  const GetEventSchedule = async () => {
    try {
      const response = await Get_EventSchedule(Eid);
      const fetchedScheduleData = response?.data?.timing;
      const fetchedImgDocsData = response?.data?.document;

      setDates(fetchedScheduleData?.length > 0 ? fetchedScheduleData?.map(item => item?.date)
        : response?.data?.date);

      const initialSchedules = response?.data?.date?.map((date) => ({
        date,
        title: "",
        description: "",
        start_time: "",
        end_time: "",
      }));

      setSchedules(fetchedScheduleData?.length > 0 ? fetchedScheduleData : initialSchedules);

      setCreated_Schedule(response?.data?.created_schedule);
      setShowImages(
        fetchedImgDocsData?.filter((item) => item?.mime_type == "jpg" || item?.mime_type == "png"));

      setShowPdf(
        fetchedImgDocsData?.filter((item) => item?.mime_type == "pdf" || item?.mime_type == "doc"));
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (tabValue == "Event-Essentials" && isEdit) {
      EditEvents();
      GetEventSchedule();
    }
  }, [isEdit, tabValue]);

  const [loading, setLoading] = useState(false);
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const [pageLoad, setPageLoad] = useState(true);
  const [errors, setErrors] = useState({});
  const [countries, seCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [zip_country, setZip_Country] = useState({});
  const [zip_state, setZip_State] = useState({});
  const [zip_city, setZip_City] = useState({});
  const [mapData, setMapData] = React.useState(null);
  const [userListLoading, setUserListLoading] = useState(true);
  const today = new Date().toISOString().split("T")[0];
  const searchBoxRef = useRef(null);

  const [isHovered, setIsHovered] = useState(false);

  const onPlacesChanged = () => {
    const places = searchBoxRef.current.getPlaces();
    const place = places[0];
    if (place) {
      const location = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      setCenter(location);
      setMarker(location);
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      // console?.log("latlong", lat, lng)
      getAddressFromLatLng(lat, lng);
    }
  };

  // const EditAddress = (cntry) => {
  //   const country = cntry?.find((country) => country?.country_name == "India");
  //   setStates(country ? country?.states : []);
  //   setCities([]);
  // };

  const EditAddress = (cntry, cntryid, stateid, cityid) => {
    // const country = cntry?.find((country) => country?.country_name == "India");
    // setStates(country ? country?.states : []);
    // setCities([]);
    const country = cntry?.find((country) => country?.country_id == cntryid);
    setStates(country ? country?.states : []);

    if (states) {
      const country = cntry.find((country) => country?.country_id == cntryid);
      const state = country
        ? country.states.find((state) => state?.state_id == stateid)
        : null;
      setCities(state ? state.cities : []);
    } else {
      setCities([]);
    }
  };

  const DefaulStateAddress = (cntry) => {
    const country = cntry?.find((country) => country?.country_name == "India");
    setStates(country ? country?.states : []);
    setCities([]);
  };

  const getCountryCityState = async () => {
    try {
      await get_city_state_country().then((response) => {
        seCountries(response?.data?.data?.countries);
        // EditAddress(response?.data?.data?.countries);
       if(isEdit){
        EditAddress(
          response?.data?.data?.countries,
          EditData?.country_id,
          EditData?.state_id,
          EditData?.city_id
        )}else{
          DefaulStateAddress(response?.data?.data?.countries);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getCountryCityState();
  }, [EditData?.country_id]);

  // Map
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyA3BYK_Siw8ebziSskitaA5YMjbvcO6n4U",
  });

  const onLoad = React.useCallback(
    function callback(map) {
      const bounds = new window.google.maps.LatLngBounds(center);
      map?.fitBounds(bounds);
      setMapData(map);
    },
    [center]
  );

  const onUnmount = React.useCallback(function callback() {
    setMapData(null);
  }, []);

  const getStaticDatafunction = async () => {
    try {
      await GetTypeEventEssentials().then((response) => {
        setType(response?.data);
      });
    } catch (errr) {
      console.log(errr);
    } finally {
      setPageLoad(false);
    }

    if (profileinfo?.user_type) {
      try {
        setUserListLoading(true);
        Get_UsersForChamp().then((response) => {
          setChampUser(response?.data?.data);
        });
      } catch (err) {
        console.log(err);
      } finally {
        setUserListLoading(false);
      }
    }

    try {
      await GetOnlineMode().then((response) => {
        setOnlineMode(response?.data?.data);
      });
    } catch (errr) {
      console.log(errr);
    } finally {
      setPageLoad(false);
    }

    try {
      await GetEventBudget().then((response) => {
        setEventBudget(response?.data?.data);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const get_lat_lng = () => {
    const lat_lng = localStorage.getItem("let_long");
    const convert_lat_lng = JSON?.parse(lat_lng);
    if (convert_lat_lng.lat > 0 && convert_lat_lng.lng > 0) {
      setCenter(convert_lat_lng);
    } else {
      const default_lat_lng = { lat: 19.076, lng: 72.8777 };
      setCenter(default_lat_lng);
    }
  };

  const checkStartDate = () => {
    const selectedDate = new Date(input?.start_date);
    const todayDate = new Date();
    const ninetyDaysFromToday = new Date(
      todayDate.setDate(todayDate.getDate() + 60)
    );
    if (selectedDate < ninetyDaysFromToday) {
      setShowTooltip(true);
    } else {
      setShowTooltip(false);
    }
  };

  useEffect(() => {
    checkStartDate();
    get_lat_lng();
    getStaticDatafunction();
  }, []);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "event_title") {
      if (value.length <= 100) {
        setInput({ ...input, event_title: value });
      } else {
        setInput({ ...input, event_title: value?.slice(0, 100) });
        setErrors({ event_title: "Title name is Maximum 100 character only" });
      }
    } else {
      setInput({
        ...input,
        [name]: value,
      });
    }
  };

  const handlePinCodeChange = async (val, type) => {
    const pinc = val;

    if (isNaN(pinc)) {
      console.error("Invalid pin code. Please enter a valid number.");
      // You may set an error state or handle it in another way
      return;
    }
    setInput((prevInput) => ({ ...prevInput, pin_code: pinc }));
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${pinc}&key=AIzaSyA3BYK_Siw8ebziSskitaA5YMjbvcO6n4U`
      );
      const data = await response.json();
      if (data.results && data.results.length > 0) {
        const addressComponents = data.results[0].address_components;
        const latLong = data?.results[0]?.geometry?.location;
        const fullAddress = addressComponents
          ?.map((component) => component.long_name)
          .join(", ");

        // const selctedAddress3 = fullAddress.split(',')[1]
        // const selctedAddress4 = fullAddress.split(',')[2]
        // `${selctedAddress3}${selctedAddress4}`

        if (addressComponents) {
          const cityComponent = addressComponents.find((component) =>
            component?.types?.includes("locality")
          );
          const stateComponent = addressComponents.find((component) =>
            component?.types?.includes("administrative_area_level_1")
          );
          const countryComponent = addressComponents.find((component) =>
            component?.types?.includes("country")
          );

          if (countryComponent) setZip_Country(countryComponent);
          if (stateComponent) setZip_State(stateComponent);
          if (cityComponent) setZip_City(cityComponent);

          const country = countries.find(
            (country) => country.country_name == countryComponent?.long_name
          );
          setStates(country ? country?.states : []);

          const state = country
            ? country.states.find(
              (state) => state?.state_name == stateComponent?.long_name
            )
            : null;

          const city = state
            ? state.cities.find(
              (city) => city?.city_name == cityComponent?.long_name
            )
            : null;

          if (state) setCities(state.cities);
          // console.log("latLong",latLong)
          if (addressComponents && latLong) {
            if (type == "map") {
              setInput((prevInput) => ({
                ...prevInput,
                country_id: country?.country_id,
                state_id: state?.state_id,
                city_id: city?.city_id,
              }));
            } else {
              setInput((prevInput) => ({
                ...prevInput,
                country_id: country?.country_id,
                state_id: state?.state_id,
                city_id: city?.city_id,
                // address: fullAddress,
                latitude: latLong?.lat,
                longitude: latLong?.lng,
              }));
              setCenter(latLong);
              setMarker(latLong);
            }
          }
        } else {
          console.error(
            "No address components found in the Geocoding API response."
          );
        }
      } else {
        console.error("No results found in the Geocoding API response.");
      }
    } catch (error) {
      console.error("Error fetching location:", error);
    }
  };

  const CreateEventSchedule = async (id) => {
    const newSchedules = Object.values(additionalSchedules)?.flat();
    // setSchedules(prevSchedules => [...prevSchedules, ...newSchedules]);
    const mergedSchedules = [...schedules, ...newSchedules];
    const fData = {
      event_id: id,
      created_schedule: created_Schedule,
      schedules: mergedSchedules
    }
    try {
      await Create_EventSchedule(id, fData).then((response) => {
        console.log("response", response)
      });
    } catch (err) {
      console.log("err", err)
    }
  }

  //  console.log("img docs",showImages, showPdf)

  const Event_ScheduleUploadImage = async (id) => {
    const Imgfilenames = showImages?.map(image => image?.file);
    const Docsfilenames = showImages?.map(document => document?.file);
    const combinedData = [...images, ...documents];
    const fData = {
      upload_schedule: combinedData,
      date: dates
    }
    try {
      await Event_Schedule_UploadImage(id, fData).then((response) => {
        console.log("response", response)
      });
    } catch (err) {
      console.log("err", err)
    }
  }

  const onEditSubmit = async (e) => {
    setLoading(true);
    try {
      await EventEssentialsEdit(Eid, input).then((results) => {
        if (results?.data?.success) {
          setTabValue("Event-Guest");
          Messages(results?.data?.message);
          if (created_Schedule == '1') {
            CreateEventSchedule(Eid)
          } else {
            Event_ScheduleUploadImage(Eid)
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (isEdit) {
        onEditSubmit();
      } else {
        setLoading(true);
        try {
          await CreateEventEssentials(input).then((results) => {
            if (results?.data?.success) {
              if (showTooltip) {
                setCreateWarningMdl(!createWarningMdl);
              } else {
                setTabValue("Event-Guest");
              }
              Messages(results?.data?.message);
              setEvent_Data(results?.data?.data);
              if (created_Schedule == '1') {
                CreateEventSchedule(results?.data?.data?.id)
              } else {
                Event_ScheduleUploadImage(results?.data?.data?.id)
              }
              navigate(`/CreateEvent/${results?.data?.data?.id}`);
            }
          });
        } catch (error) {
          console.log(error);
        }
        setLoading(false);
      }
    }
  };

  const handleMapClick = (event) => {
    const { latLng } = event;
    const lat = latLng.lat();
    const lng = latLng.lng();
    setCenter({ lat, lng });
    setMarker({ lat, lng });
    getAddressFromLatLng(lat, lng);
  };

  const getAddressFromLatLng = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyA3BYK_Siw8ebziSskitaA5YMjbvcO6n4U`
      );
      if (response.ok) {
        const data = await response.json();
        if (data?.results && data?.results.length > 0) {
          const full_address = data?.results[0]?.address_components;
          const location = full_address.find((loc) =>
            loc?.types?.includes("postal_code")
          );
          const location1 = data?.results[0]?.formatted_address;
          const let_lng = data?.results[0]?.geometry?.location;
          // const selctedAddress1 = location1.split(',')[1]
          // const selctedAddress2 = location1.split(',')[2]
          //  `${selctedAddress1},${selctedAddress2}`
          setInput({
            ...input,
            latitude: let_lng?.lat,
            longitude: let_lng?.lng,
            pin_code: location.long_name,
            // address: location1
          });
          handlePinCodeChange(location?.long_name, "map");
        } else {
          console.log("Address not found");
        }
      } else {
        console.log("Failed to fetch address");
      }
    } catch (error) {
      console.error("Error fetching address:", error);
    }
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    if (input?.event_title?.trim() == "") {
      newErrors.event_title = "Event Name is required";
      valid = false;
    }

    if (input?.event_type == "") {
      newErrors.event_type = "Event Type is required";
      valid = false;
    }

    if (input?.event_mode?.trim() == "") {
      newErrors.event_mode = "Event Mode is required";
      valid = false;
    }

    // if (
    //   input?.event_mode == "Offline_Indoor" ||
    //   input?.event_mode == "Offline_Outdoor"
    // ) {
    if (!input.event_time_category || input.event_time_category?.trim() == "") {
      newErrors.event_time_category = "Time Category is required";
      valid = false;
    }
    // }

    if (input.start_date.toString()?.trim() == "") {
      newErrors.start_date = "Start Date is required";
      valid = false;
    }

    if (input.start_time.toString()?.trim() == "") {
      newErrors.start_time = "Start Time is required";
      valid = false;
    }

    if (input.end_date.toString()?.trim() == "") {
      newErrors.end_date = "End Date is required";
      valid = false;
    }

    if (input.end_time.toString()?.trim() == "") {
      newErrors.end_time = "End Time is required";
      valid = false;
    }

    const event_des = input.description?.replace(/<p><br><\/p>/g, "");
    if (event_des?.trim() == "") {
      newErrors.description = "Description is required";
      valid = false;
    }

    if (input.status?.trim() == "") {
      newErrors.status = "Event Status is required";
      valid = false;
    }

    if (input.total_budget_id == "") {
      newErrors.total_budget_id = "Event Budget is required";
      valid = false;
    }

    if (
      input.event_mode == "Hybrid" ||
      input.event_mode == "Offline_Indoor" ||
      input.event_mode == "Offline_Outdoor"
    ) {
      if (input.address.trim() == "") {
        newErrors.address = "Area, Street, Sector, Village is required";
        valid = false;
      }

      if (!input.country_id || input.country_id.toString()?.trim() == "") {
        newErrors.country_id = "Country is required";
        valid = false;
      }

      if (!input.state_id || input.state_id.toString()?.trim() == "") {
        newErrors.state_id = "State is required";
        valid = false;
      }

      if (!input.city_id || input.city_id.toString() == "") {
        newErrors.city_id = "City is required";
        valid = false;
      }

      if (input.pin_code?.trim() == "") {
        newErrors.pin_code = "Pin Code is required";
        valid = false;
      }

      if (!input.house_no || !input.house_no.toString()?.trim()) {
        newErrors.house_no = "Flat/House No, Building is required";
        valid = false;
      }

      // if (!input.landmark || !input.landmark.trim()) {
      //   newErrors.landmark = "Landmark Is Required";
      //   valid = false;
      // }

      // if (!input.address_type || !input.address_type.trim()) {
      //   newErrors.address_type = "Address Type Is Required";
      //   valid = false;
      // }
    }

    if (input.event_mode == "Online" || input.event_mode == "Hybrid") {
      if (!input.online_mode || !input.online_mode?.trim()) {
        newErrors.online_mode = "Online Mode is required";
        valid = false;
      }
      if (
        !input.meeting_passcode ||
        !input.meeting_passcode.toString()?.trim()
      ) {
        newErrors.meeting_passcode = "Meeting PassCode is required";
        valid = false;
      }
      if (!input.access_code || !input.access_code.toString()?.trim()) {
        newErrors.access_code = "Access Code is required";
        valid = false;
      }
      if (!input.meeting_link || !input.meeting_link?.trim()) {
        newErrors.meeting_link = "Meeting Link is required";
        valid = false;
      }
    }

    // if (created_Schedule == 0) {

    //   if (images?.length == 0 && showImages?.length == 0) {
    //     newErrors.images = "Please add at least one image.";
    //     valid = false;
    //   }

    //   if (documents?.length == 0 && showPdf?.length == 0) {
    //     newErrors.documents = "Please add at least one document.";
    //     valid = false;
    //   }
    // }

    setErrors(newErrors);
    return valid;
  };
  // console.log("input...",input)

  const handleCountryChange = (e) => {
    const cntry = e?.target?.value;
    setInput({
      ...input,
      country: cntry,
      country_id: cntry,
      state: "",
      city: "",
    });
    if (cntry) {
      const country = countries.find((country) => country.country_id == cntry);
      setStates(country ? country?.states : []);
    } else {
      const country = countries.find((country) => country.country_id == "101");
      setStates(country ? country?.states : []);
      setCities([]);
    }
  };

  const handleStateChange = (e) => {
    const sState = e.target.value;
    setInput({ ...input, state: sState, state_id: sState, city: "" });

    if (sState) {
      const state = states
        ? states.find((state) => state?.state_id == sState)
        : null;
      setCities(state ? state.cities : []);
    } else {
      setCities([]);
    }
  };

  const handleCityChange = (e) => {
    const cityid = e.target.value;
    setInput({ ...input, city_id: cityid });
  };

  const handleSaveData = async () => {
    let valid = true;
    const newErrors = {};
    if (input.event_title.trim() == "") {
      newErrors.event_title = "Event Name is required";
      valid = false;
    } else {
      setSaveBtnLoading(true);
      try {
        await CreateEventEssentials(input).then((results) => {
          if (results?.data?.success) {
            setTabValue("Event-Guest");
            Messages(results?.data?.message);
            setEvent_Data(results?.data?.data);
            navigate(`/CreateEvent/${results?.data?.data?.id}`);
          }
        });
      } catch (error) {
        console.log(error);
      } finally {
        setSaveBtnLoading(false);
      }
    }
    setErrors(newErrors);
    return valid;
  };

  const handleStartDate = (e) => {
    const selectedDate = new Date(e.target.value);
    const todayDate = new Date();
    const ninetyDaysFromToday = new Date(
      todayDate.setDate(todayDate.getDate() + 60)
    );
    if (selectedDate < ninetyDaysFromToday) {
      setShowTooltip(true);
    } else {
      setShowTooltip(false);
    }
    setInput({ ...input, start_date: e.target.value });
  };

  const handleDescriptionChange = (value) => {
    const text = value.replace(/<[^>]*>?/gm, "");

    if (text.length <= 2500) {
      setInput((prevState) => ({
        ...prevState,
        description: value,
      }));
      setErrors({
        ...errors,
        description: "",
      });
    } else {
      setErrors({
        ...errors,
        description: "Description cannot exceed 2500 characters.",
      });
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  // rect quill length count
  const stripHtmlTags = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;

    let textWithNewlines = div.innerHTML

    if (/^<p><br><\/p>$/i.test(textWithNewlines)) {
      textWithNewlines = '';  // Remove the entire content if it matches <p><br></p>
    } else {
      // Otherwise, apply replacements
      textWithNewlines = textWithNewlines
        .replace(/<br\s*\/?>/gi, '\n')  // Replace <br> with newline
    }

    div.innerHTML = textWithNewlines;
    return div.textContent || div.innerText || "";
  };

  const getDescriptionLength = () => {
    const cleanDescription = stripHtmlTags(input?.description);
    return cleanDescription.length;
  };

  const maxLength = 2500;
  const currentLength = getDescriptionLength();
  const remainingLength = maxLength - currentLength;
  //
  useEffect(() => {
    const firstErrorKey = Object.keys(errors)[0];
    if (firstErrorKey && refs[firstErrorKey] && refs[firstErrorKey]?.current) {
      refs[firstErrorKey]?.current?.focus();
    }
  }, [errors]);

  const GetProfile = async (id) => {
    // setLoading(true);
    try {
      const response = await Get_ProfileDetail(id);
      setUserData(response?.data?.user);
      console.log("ress", response?.data?.user);
    } catch (err) {
      console.log(err);
    } finally {
      // setLoading(false);
    }
  };

  const handleUserChange = (e) => {
    const userId = e.target.value;
    setInput({ ...input, host_id: userId });
    GetProfile(userId);
  };

  // const validateSchedules = (schedules) => {
  //   const errors = {};
  //   schedules.forEach((schedule, i) => {
  //     schedules.forEach((otherSchedule, j) => {
  //       if (i !== j) {
  //         const isOverlap = (schedule.start_date < otherSchedule.end_date ||
  //                           (schedule.start_date === otherSchedule.end_date && schedule.start_time < otherSchedule.end_time)) &&
  //                           (schedule.end_date > otherSchedule.start_date ||
  //                           (schedule.end_date === otherSchedule.start_date && schedule.end_time > otherSchedule.start_time));
  //         if (isOverlap) {
  //           errors[i] = "The selected schedule overlaps with another schedule.";
  //         }
  //       }
  //     });
  //   });
  //   setEventDateErrors(errors);
  //   return Object.keys(errors).length === 0;
  // };

  // const handleEventDateChange = (index, e) => {
  //   const { name, value } = e.target;
  //   const newSchedules = [...eventSchedules];
  //   newSchedules[index] = { ...newSchedules[index], [name]: value };

  //   if (validateSchedules(newSchedules)) {
  //      setEventSchedules(newSchedules);
  //    }

  // };

  // console.log("schedule",eventSchedules)

  const addSchedule = () => {
    setEventSchedules([
      ...eventSchedules,
      { start_date: "", start_time: "", end_date: "", end_time: "" },
    ]);
  };

  const handleEndDate = (e) => {
    const { name, value } = e.target;
    setInput((prevState) => ({ ...prevState, [name]: value }));
    if (input?.start_date) {
      generateDates(input?.start_date, value);
    }
  };

  const generateDates = (startDate, endDate) => {
    let dateArray = [];
    let currentDate = new Date(startDate);
    const lastDate = new Date(endDate);
    while (currentDate <= lastDate) {
      dateArray?.push(currentDate.toISOString().split("T")[0]);
      currentDate?.setDate(currentDate.getDate() + 1);
    }
    // console.log("dateArray",dateArray);

    // const initialSchedules = dateArray?.map((date) => ({
    //   date,
    //   title: "",
    //   description: "",
    //   start_time: "",
    //   end_time: "",
    // }));


    const newSchedules = dateArray?.map((date) => ({
      date,
      title: "",
      description: "",
      start_time: "",
      end_time: "",
    }));

    if (isEdit) {
      const updatedSchedules = [...schedules];
      newSchedules?.forEach((schedule) => {
        const existingSchedule = updatedSchedules?.find((s) => s?.date == schedule?.date);
        if (existingSchedule) {
          // Update existing schedule
          Object.assign(existingSchedule, schedule);
        } else {
          // Add new schedule
          updatedSchedules?.push(schedule);
        }
      });
      setSchedules(updatedSchedules);
    } else {
      setSchedules(newSchedules);
    }
    setDates(dateArray);
  };


  return (
    <>
      {createWarningMdl && (
        <CreateEventWarningMdl
          setTabValue={setTabValue}
          setCreateWarningMdl={setCreateWarningMdl}
        />
      )}
      {showTooltip && (
        <Tooltip id="startdate-tooltip" className="tooltip_style" />
      )}

      {showTooltip && (
        <Tooltip id="submit-btn-tooltip" className="tooltip_style" />
      )}

      {selectChampMdl && (
        <SelectChampModal
          input={input}
          setInput={setInput}
          setSelectChampMdl={setSelectChampMdl}
        />
      )}

      {pageLoad ? (
        <Loader />
      ) : (
        <>
          <ToastContainer />
          <div className="basicInfo">
            {/* <h2 className="basicinfo-head">Event Essentials</h2> */}

            <div className="d-flex align-items-center gap-1 position-relative ">
              <p className="createevent-head-p">
                This section is designed for you to enter the core details
                about your event. It captures everything from the event's
                name and type to specific logistics like the venue and
                timing, ensuring all fundamental aspects are clearly
                outlined and easily accessible.
              </p>
              {/* <h2 className="event-heading mb-0 d-flex flex-column flex-md-row align-items-md-center gap-md-2">
                Event Essentials
              </h2>
              <div
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}>
                <IoMdInformationCircleOutline size={23} />
              </div>
              {isHovered && (
                <div
                  className="info-popup"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}>
                  <p className="info-popup-text">
                    This section is designed for you to enter the core details
                    about your event. It captures everything from the event's
                    name and type to specific logistics like the venue and
                    timing, ensuring all fundamental aspects are clearly
                    outlined and easily accessible.
                  </p>
                </div>
              )} */}
            </div>

            {profileinfo?.user_type ? (
              userListLoading ? (
                <Loader2 />
              ) : (
                <div className="row">
                  <div className="form-group col-md-4">
                    <label className="form-label">
                      User<span className="asterisk">*</span>
                    </label>
                    <select
                      className="form-control"
                      name="host_id"
                      onChange={handleUserChange}>
                      <option value="" selected>
                        Select User
                      </option>
                      {champUser &&
                        champUser?.map((val, idx) => {
                          return (
                            <option
                              className="text-capitalize"
                              value={val?.id}
                              key={idx}>
                              {val?.f_name} {val?.l_name}
                            </option>
                          );
                        })}
                    </select>
                    {/* {errors.host_id && <span className="error">{errors.host_id}</span>} */}
                  </div>
                </div>
              )
            ) : (
              ""
            )}
            <div className="row mt-2">
              <form onSubmit={onSubmit}>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label className="form-label">
                      Event Name<span className="asterisk">*</span>
                    </label>
                    <IoMdInformationCircleOutline
                      size={16}
                      title="Enter the official name or Clear and catchy title of the event as it will appear in all communications."
                    />
                    <input
                      type="text"
                      value={input.event_title}
                      name="event_title"
                      className="form-control"
                      placeholder="Enter Event Name"
                      onChange={handleInputChange}
                      ref={refs?.event_title}
                    />
                    {errors.event_title && (
                      <span className="error">{errors.event_title}</span>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label className="form-label">
                      Event Type<span className="asterisk">*</span>
                    </label>
                    <IoMdInformationCircleOutline
                      size={16}
                      title="Select the Event type"
                    />
                    <select
                      className="form-control"
                      name="event_type"
                      onChange={handleInputChange}
                      ref={refs?.event_type}>
                      <option value="" selected disabled>
                        Select Event Type
                      </option>
                      {type &&
                        type?.map((val) => {
                          return (
                            <option
                              key={val?.id}
                              value={val?.id}
                              selected={input?.event_type == val?.id}
                            >
                              {val?.name}
                            </option>
                          );
                        })}
                    </select>
                    {errors.event_type && (
                      <span className="error">{errors.event_type}</span>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-6 col-md-3">
                    <label className="form-label">
                      Event Mode<span className="asterisk">*</span>
                    </label>
                    <IoMdInformationCircleOutline
                      size={16}
                      title="Choose the mode of the event"
                    />
                    <select
                      className="form-control"
                      name="event_mode"
                      onChange={handleInputChange}
                      ref={refs?.event_mode}
                    >
                      <option value="" selected>
                        Select Event Mode
                      </option>
                      <option
                        value="Online"
                        selected={input?.event_mode == "Online"}
                      >
                        Online
                      </option>
                      <option
                        value="Hybrid"
                        selected={input?.event_mode == "Hybrid"}
                      >
                        Hybrid
                      </option>
                      <option
                        value="Offline_Indoor"
                        selected={input?.event_mode == "Offline_Indoor"}
                      >
                        Offline Indoor
                      </option>
                      <option
                        value="Offline_Outdoor"
                        selected={input?.event_mode == "Offline_Outdoor"}
                      >
                        Offline Outdoor
                      </option>
                    </select>
                    {errors.event_mode && (
                      <span className="error">{errors.event_mode}</span>
                    )}
                  </div>

                  <div className="form-group col-6 col-md-3">
                    <label className="form-label">
                      Event Budget<span className="asterisk">*</span>
                    </label>
                    <IoMdInformationCircleOutline
                      size={16}
                      title="State the planned budget for the event, including all expected expenses."
                    />
                    <select
                      className="form-control"
                      name="total_budget_id"
                      onChange={handleInputChange}
                      ref={refs?.total_budget_id}>
                      <option selected disabled>
                        Select Event Budget
                      </option>
                      {eventBudget?.map((val) => (
                        <option
                          value={val?.id}
                          key={val?.id}
                          selected={input?.total_budget_id == val?.id}>
                          {val?.budget}
                        </option>
                      ))}
                    </select>
                    {errors.total_budget_id && (
                      <span className="error">{errors.total_budget_id}</span>
                    )}
                  </div>

                  {/* {input.event_mode == "Offline_Indoor" ||
                    input.event_mode == "Offline_Outdoor" ? ( */}
                  <div className="form-group col-6 col-md-3">
                    <label className="form-label">
                      Time Category<span className="asterisk">*</span>
                    </label>
                    <select
                      className="form-control"
                      name="event_time_category"
                      onChange={handleInputChange}
                      ref={refs?.event_time_category}>
                      <option selected disabled>
                        Select Time Category
                      </option>
                      <option
                        value="Day_event"
                        selected={input?.event_time_category == "Day_event"}>
                        Day Event
                      </option>
                      <option
                        value="Night_event"
                        selected={input?.event_time_category == "Night_event"}>
                        Night Event
                      </option>
                      <option
                        value="Full_day"
                        selected={input?.event_time_category == "Full_day"}>
                        Full Day
                      </option>
                    </select>
                    {errors.event_time_category && (
                      <span className="error">
                        {errors.event_time_category}
                      </span>
                    )}
                  </div>
                  {/* ) : (
                    ""
                  )} */}

                  {/* {liveSample == 0 ? (
                    ""
                  ) : ( */}
                  <div className="form-group col-6 col-md-3">
                    <label className="form-label">
                      Event Status<span className="asterisk">*</span>
                    </label>

                    <div className="d-flex gap-4 mt-3">
                      <div className="form-check">
                        <label className="form-check-label">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="status"
                            value="Live"
                            checked={input.status == "Live"}
                            onChange={handleInputChange}
                          />
                          Live
                        </label>
                      </div>
                      <div class="form-check">
                        <label className="form-check-label">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="status"
                            value="Sample"
                            checked={input.status == "Sample"}
                            onChange={handleInputChange}
                          />
                          Sample
                        </label>
                      </div>
                    </div>
                    {errors.status && (
                      <span className="error">{errors.status}</span>
                    )}
                  </div>
                  {/* )} */}
                </div>
                {!input?.event_mode == "" && (
                  <>
                    {input?.event_mode == "Online" ||
                      input?.event_mode == "Hybrid" ? (
                      <div>
                        <div className="row">
                          <h5 className="my-3 form-section-heading">
                            Online Event Details
                            <span className="asterisk">*</span>
                          </h5>
                          <div className="form-group col-6 col-md-6">
                            <label className="form-label">
                              Meeting Link<span className="asterisk">*</span>
                            </label>
                            <input
                              type="url"
                              className="form-control"
                              name="meeting_link"
                              value={input.meeting_link}
                              onChange={handleInputChange}
                              placeholder="Meeting Link"
                            />
                            {errors.meeting_link && (
                              <span className="error">
                                {errors.meeting_link}
                              </span>
                            )}
                          </div>
                          <div className="form-group col-6 col-md-6">
                            <label className="form-label">
                              Meeting PassCode
                              <span className="asterisk">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="meeting_passcode"
                              value={input.meeting_passcode}
                              onChange={handleInputChange}
                              placeholder="Meeting PassCode"
                            />
                            {errors.meeting_passcode && (
                              <span className="error">
                                {errors.meeting_passcode}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-group col-6 col-md-6">
                            <label className="form-label">
                              Access Code<span className="asterisk">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="access_code"
                              value={input.access_code}
                              onChange={handleInputChange}
                              placeholder="Access Code"
                            />
                            {errors.access_code && (
                              <span className="error">
                                {errors.access_code}
                              </span>
                            )}
                          </div>

                          <div className="form-group col-md-6 col-sm-6">
                            <label className="form-label">
                              Online Mode<span className="asterisk">*</span>
                            </label>
                            <select
                              className="form-control"
                              name="online_mode"
                              onChange={handleInputChange}
                            >
                              <option selected>Select Online Mode</option>
                              {onlineMode?.map((val, idx) => (
                                <option value={val?.id} key={idx}>
                                  {val?.mode_name}
                                </option>
                              ))}
                              <option value="Others">Others</option>
                            </select>
                            {errors.online_mode && (
                              <span className="error">
                                {errors.online_mode}
                              </span>
                            )}
                          </div>
                        </div>
                        {input.online_mode == "Others" && (
                          <div className="row">
                            <div className="form-group col-md-6 col-sm-6">
                              <label className="form-label">
                                Other Online Mode
                                <span className="asterisk">*</span>
                              </label>
                              <textarea
                                className="form-control"
                                type="text"
                                name="others_online_mode"
                                placeholder="Other Online Mode"
                                value={input.others_online_mode}
                                onChange={handleInputChange}
                                rows="4"
                              ></textarea>
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      ""
                    )}

                    {input?.event_mode == "Online" ? (
                      ""
                    ) : (
                      <div>
                        <div className="row">
                          <h5 className="my-3 form-section-heading">
                            Venue Details<span className="asterisk">*</span>
                            <IoMdInformationCircleOutline
                              size={16}
                              title="Enter the physical location or address of the event venue including virtual platform details if applicable."
                            />
                          </h5>

                          <div className="form-group col-6 col-md-3 mt-md-2">
                            <label className="form-label">
                              Pin Code<span className="asterisk">*</span>
                            </label>
                            <input
                              type="number"
                              min="0"
                              value={input?.pin_code}
                              name="pin_code"
                              onChange={(e) =>
                                handlePinCodeChange(e.target.value)
                              }
                              className="form-control"
                              placeholder="Pin Code"
                              autocomplete="false"
                              ref={refs?.pin_code}
                            />
                            {errors?.pin_code && (
                              <span className="error">{errors?.pin_code}</span>
                            )}
                          </div>

                          <div className="form-group col-6 col-md-3 mt-md-2">
                            <label className="form-label">
                              Country<span className="asterisk">*</span>
                            </label>
                            <select
                              className="form-control"
                              name="country_id"
                              onChange={handleCountryChange}
                              ref={refs?.country_id}
                            >
                              <option selected>Select Country</option>
                              {countries &&
                                countries?.map((val, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={val?.country_id}
                                      selected={
                                        Object?.keys(zip_country)?.length == 0
                                          ? input?.country_id
                                            ? val?.country_id ==
                                            input?.country_id
                                            : val?.country_name == "India"
                                          : zip_country?.long_name ==
                                          val?.country_name
                                      }
                                    >
                                      {val?.country_name}
                                    </option>
                                  );
                                })}
                            </select>
                            {errors.country_id && (
                              <span className="error">{errors.country_id}</span>
                            )}
                          </div>

                          <div className="form-group col-6 col-md-3 mt-md-2">
                            <label className="form-label">
                              State<span className="asterisk">*</span>
                            </label>
                            <select
                              className="form-control"
                              name="state_id"
                              onChange={handleStateChange}
                              ref={refs?.state_id}
                            >
                              <option selected>Select State</option>
                              {states &&
                                states?.map((val, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={val?.state_id}
                                      selected={
                                        Object.keys(zip_state).length == 0
                                          ? val?.state_id == input?.state_id
                                          : zip_state?.long_name ==
                                          val?.state_name
                                      }
                                    >
                                      {val?.state_name}
                                    </option>
                                  );
                                })}
                            </select>
                            {errors.state_id && (
                              <span className="error">{errors.state_id}</span>
                            )}
                          </div>

                          <div className="form-group col-6 col-md-3 mt-md-2">
                            <label className="form-label">
                              City<span className="asterisk">*</span>
                            </label>
                            <select
                              className="form-control"
                              name="city_id"
                              onChange={handleCityChange}
                              ref={refs?.city_id}>
                              <option selected>Select City</option>
                              {cities &&
                                cities?.map((val, index) => {
                                  return (
                                    <>
                                      <option
                                        key={index}
                                        value={val?.city_id}
                                        selected={
                                          Object.keys(zip_city).length == 0
                                            ? val?.city_id == input?.city_id
                                            : zip_city?.long_name ==
                                            val?.city_name
                                        }
                                      >
                                        {val?.city_name}
                                      </option>
                                    </>
                                  );
                                })}
                            </select>
                            {errors.city_id && (
                              <span className="error">{errors.city_id}</span>
                            )}
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-group col-md-3 col-sm-6">
                            <label className="form-label">
                              Flat/House No, Building
                              <span className="asterisk">*</span>
                            </label>

                            <input
                              type="text"
                              value={input.house_no}
                              name="house_no"
                              onChange={handleInputChange}
                              className="form-control"
                              placeholder="Flat/House No, Building"
                              ref={refs?.house_no}
                            />
                            {errors.house_no && (
                              <span className="error">{errors.house_no}</span>
                            )}
                          </div>

                          <div className="form-group col-md-6">
                            <label className="form-label">
                              Area,Street,Sector,Village
                              <span className="asterisk">*</span>
                            </label>
                            <input
                              type="text"
                              value={input?.address}
                              name="address"
                              onChange={handleInputChange}
                              className="form-control"
                              placeholder="Area,Street,Sector,Village..."
                            />
                            {errors.address && (
                              <span className="error">{errors.address}</span>
                            )}
                          </div>

                          <div className="form-group col-md-3 col-sm-6">
                            <label className="form-label">
                              Landmark<span className="asterisk"></span>
                            </label>

                            <input
                              type="text"
                              value={input.landmark}
                              name="landmark"
                              onChange={handleInputChange}
                              className="form-control"
                              placeholder="Landmark"
                            />
                            {errors.landmark && (
                              <span className="error">{errors.landmark}</span>
                            )}
                          </div>
                        </div>

                        {/* <div className="row">
                          <div className="form-group col-md-3 col-sm-6">
                            <label className="form-label">
                              Address Type<span className="asterisk">*</span>
                            </label>
                            <select
                              className="form-control"
                              name="address_type"
                              onChange={handleInputChange}
                            >
                              <option value="" selected>
                                Select Address Type
                              </option>
                              <option
                                value="Home"
                                selected={input.address_type == "Home"}
                              >
                                Home
                              </option>
                              <option
                                value="Office"
                                selected={input.address_type == "Office"}
                              >
                                Office
                              </option>
                            </select>
                            {errors.address_type && (
                              <span className="error">
                                {errors.address_type}
                              </span>
                            )}
                          </div>
                        </div> */}

                        <div className="row mt-2 position-relative"
                          title="Provide a Google Maps link to the venue for easy navigation">
                          {isLoaded ? (
                            <div>
                              <StandaloneSearchBox
                                onLoad={(ref) => (searchBoxRef.current = ref)}
                                onPlacesChanged={onPlacesChanged}>
                                <input
                                  type="text"
                                  placeholder="Search for places"
                                  className="map-search-input"
                                />
                              </StandaloneSearchBox>
                              <GoogleMap
                                mapContainerStyle={{
                                  height: "400px",
                                  width: "100%",
                                }}
                                center={center}
                                zoom={13}
                                // onLoad={onLoad}
                                onUnmount={onUnmount}
                                onClick={handleMapClick}>
                                {marker && <Marker position={marker} />}
                              </GoogleMap>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    )}
                  </>
                )}
                <div className="row">
                  <h5 className="my-3 form-section-heading">
                    When is the event happening?
                    <IoMdInformationCircleOutline
                      size={16}
                      title="Specify the start and end date and time for the event"
                    />
                  </h5>

                  <div className="form-group col-6 col-md-3">
                    <label className="form-label">
                      Start Date<span className="asterisk">*</span>
                    </label>

                    <div>
                      <input
                        data-tooltip-id="startdate-tooltip"
                        data-tooltip-content="💡Quick Tip: To maximize sponsor responses, consider scheduling your event at least 90 days from today. 
                                                This gives sponsors adequate time to review and decide on their participation."
                        data-tooltip-place="top"
                        type="date"
                        className="form-control"
                        name="start_date"
                        value={input.start_date}
                        onChange={handleStartDate}
                        // onFocus={(e) => e.currentTarget?.showPicker()}
                        min={today}
                        ref={refs?.start_date}
                      />
                    </div>
                    {errors.start_date && (
                      <span className="error">{errors.start_date}</span>
                    )}
                  </div>

                  <div className="form-group col-6 col-md-3">
                    <label className="form-label">
                      Start Time<span className="asterisk">*</span>
                    </label>
                    <div>
                      <input
                        type="time"
                        className="form-control"
                        step="900"
                        pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$"
                        name="start_time"
                        value={input?.start_time}
                        onChange={handleInputChange}
                        // onFocus={(e) => e.currentTarget?.showPicker()}
                        ref={refs?.start_time}
                      />
                    </div>
                    {errors.start_time && (
                      <span className="error">{errors.start_time}</span>
                    )}
                  </div>

                  <div
                    className="form-group mb-0 col-6 col-md-3"
                  // title="Specify the end date for the event"
                  >
                    <label className="form-label">
                      End Date<span className="asterisk">*</span>
                    </label>
                    <div>
                      <input
                        type="date"
                        className="form-control"
                        name="end_date"
                        disabled={!input?.start_date}
                        value={input.end_date}
                        onChange={handleEndDate}
                        // onFocus={(e) => e.currentTarget?.showPicker()}
                        min={input?.start_date ? input?.start_date : today}
                        ref={refs?.end_date}
                      />
                    </div>
                    {errors.end_date && (
                      <span className="error">{errors.end_date}</span>
                    )}
                  </div>

                  <div className="col-6 col-md-3">
                    <label className="form-label">
                      End Time<span className="asterisk">*</span>
                    </label>
                    <div className=" ">
                      <input
                        type="time"
                        className="form-control"
                        step="900"
                        pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$"
                        name="end_time"
                        value={input.end_time}
                        onChange={handleInputChange}
                        // onFocus={(e) => e.currentTarget?.showPicker()}
                        ref={refs?.end_time}
                      />
                    </div>
                    {errors.end_time && (
                      <span className="error">{errors.end_time}</span>
                    )}
                  </div>
                </div>

                <div className="d-flex align-items-center gap-3 mt-2">
                  {/* <button className="add-schedule-btn " type="button" onClick={addSchedule}></button> */}
                  <div className="form-check">
                    <label className="form-check-label add-schedule-btn m-0">
                      <input
                        className="form-check-input tab-radio-btn"
                        type="radio"
                        value="1"
                        checked={created_Schedule == "1"}
                        name="schedule_type"
                        onChange={(e) => { setCreated_Schedule(e?.target?.value); }} />
                      Create a Detailed Schedule
                    </label>
                  </div>
                  {/* created_Schedule, setCreated_Schedule */}
                  <div className="form-check">
                    <label className="form-check-label add-schedule-btn m-0">
                      <input
                        className="form-check-input tab-radio-btn"
                        type="radio"
                        value="0"
                        checked={created_Schedule == "0"}
                        name="schedule_type"
                        onChange={(e) => { setCreated_Schedule(e?.target?.value); }} />
                      Upload Event Schedule
                    </label>
                  </div>
                </div>

                {/* schedule */}
                <div className="mt-3">
                  {isEdit ?
                    <div className="">
                      <EditDetailedSchedule created_Schedule={created_Schedule}
                        schedules={schedules}
                        setSchedules={setSchedules}
                        additionalSchedules={additionalSchedules}
                        setAdditionalSchedules={setAdditionalSchedules}
                        dates={dates}
                        isEdit={isEdit} />
                    </div>
                    :
                    <MakeADefaultSchedule created_Schedule={created_Schedule}
                      schedules={schedules}
                      setSchedules={setSchedules}
                      additionalSchedules={additionalSchedules}
                      setAdditionalSchedules={setAdditionalSchedules}
                      dates={dates}
                      isEdit={isEdit}
                    />
                  }

                  {
                    created_Schedule == '0' &&
                    <UploadEventSchedule images={images}
                      setImages={setImages}
                      documents={documents}
                      setDocuments={setDocuments}
                      showPdf={showPdf}
                      setShowPdf={setShowPdf}
                      showImages={showImages}
                      setShowImages={setShowImages}
                      baseUrls={baseUrls}
                      GetEventSchedule={GetEventSchedule}
                      errors={errors}
                    />
                  }
                </div>

                <div className="row mt-3">
                  <div className="d-flex justify-content-between">
                    <label className="form-label mb-0">
                      Event Description<span className="asterisk">*</span>
                      {/* <IoMdInformationCircleOutline
                        size={16}
                        title="Provide a overview describing the purpose and what the event is all about and its main attractions."
                        color="black"
                      /> */}
                    </label>
                    <p className="Character-0500">
                      Character:
                      <span className="text-style-1">
                        {/* remainingLength */}
                        {currentLength}/{maxLength}
                      </span>
                    </p>
                  </div>

                  <p className="reactquill-tooltip-p mb-1">(Note: Provide a overview describing the purpose and what the event is all about and its main attractions.)</p>
                  <div style={{ height: "200px" }}>
                    <ReactQuill
                      value={input?.description}
                      onChange={handleDescriptionChange}
                      theme="snow"
                      style={{ height: "150px" }}
                      placeholder="Enter Event Description"
                      ref={refs?.description}
                    />
                  </div>
                  {errors?.description && (
                    <span className="error">{errors?.description}</span>
                  )}
                </div>

                <div className="d-flex justify-content-md-end align-items-center  mt-3">
                  <div className="d-flex flex-column flex-md-row gap-3">
                    {!profileinfo?.user_type &&
                      profileinfo?.original_champ != null ? (
                      <button
                        className="d-flex align-items-center gap-1 saveandcontinue-btn"
                        onClick={() => setSelectChampMdl(!selectChampMdl)}
                        type="button">
                        Change Champ
                      </button>
                    ) : (
                      ""
                    )}

                    <button
                      className="d-flex align-items-center gap-1 saveandcontinue-btn"
                      type="button"
                      data-tooltip-id="submit-btn-tooltip"
                      data-tooltip-content="Planning Ahead Pays Off: Scheduling your event 90 days in advance enhances sponsor engagement and increases your chances of securing more and better sponsorships."
                      data-tooltip-place="top"
                      onClick={handleSaveData} >
                      {saveBtnLoading ? (
                        <div
                          className="spinner-border text-light "
                          role="status" >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        ""
                      )}
                      Save
                    </button>

                    <button
                      className="d-flex align-items-center gap-1 saveandcontinue-btn"
                      type="submit"
                      data-tooltip-id="submit-btn-tooltip"
                      data-tooltip-content="Planning Ahead Pays Off: Scheduling your event 90 days in advance enhances sponsor engagement and increases your chances of securing more and better sponsorships."
                      data-tooltip-place="top">
                      {loading ? (
                        <div
                          className="spinner-border text-light"
                          role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        ""
                      )}
                      Save and Continue
                      <img className="arrow-img" src={arrow} alt="arrow" />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default EventEssentialsForm;
