import { GetManageRequest, Set_ChatArchive } from "../../Services/Apis";

export const Get_ManageRequest = async (tabValue,searchValue,isArchiveChecked) => {
    try {
        const response =  await GetManageRequest(tabValue,searchValue,isArchiveChecked).then((data) => data)
        return response
    } catch (err) {
        throw err;
    }
} 

export const Set_Chat_Archive = async (fdata) => {
    try {
        const response =  await Set_ChatArchive(fdata).then((data) => data)
        return response
    } catch (err) {
        throw err;
    }
} 

