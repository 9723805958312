import { useState, useEffect,useRef,useMemo} from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";


const PartnersList=({rowData})=>{
    const [columnDefs, setColumnDefs] = useState([]);
    const gridRef = useRef();

  const onGridReady = (params) => {
    gridRef.current = params.api;
  };

  const defaultColDef = useMemo(() => {
    return {
      filter: "agTextColumnFilter",
      floatingFilter: true,
    };
  }, []);

  useEffect(() => {
    setColumnDefs([
      {
        headerName: "Partner Name",
        field: "partner_name",
        // cellRenderer: AvatarRenderer,
        // filter: false,
      },
      {
        headerName: "Partner Mobile no.",
        field: "partner_number",
        // cellRenderer: AvatarRenderer,
        // filter: params => params.data.editable,
      },
      {
        headerName: "Partner Email",
        field: "partner_email",
        // cellRenderer: AvatarRenderer,
        // filter: params => params.data.editable,
      },
      {
        headerName: "Nature of Business",
        field: "nature_of_business",
        // cellRenderer: AvatarRenderer,
        // filter: params => params.data.editable,
      },
      {
        headerName: "Existing Relationship",
        field: "relationship",
        // cellRenderer: AvatarRenderer,
        // filter: params => params.data.editable,
      },
    ]);
  }, [rowData]);

    return(<>
      <div className="ag-theme-alpine"
              style={{ height: 300, width: "100%" }} >
              <AgGridReact
                ref={gridRef}
                onGridReady={onGridReady}
                columnDefs={columnDefs}
                rowData={rowData}
                defaultColDef={defaultColDef}
                rowSelection="multiple"
              ></AgGridReact>
            </div>
    </>)
};

export default PartnersList;