import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { useParams } from "react-router"
import FAQ from "../../components/faq/Faq";
import Sitemap from "./Sitemap";
import NewsRoom from "./NewsRoom";
import Contact_Us from "./ContactUs";
import RefundAndCancellationPolicy from "./RefundAndCancellationPolicy";


const SupportPages = () => {
    const pathname = useParams();
    const counter = useSelector(state => state.config_data.configData)
    const [faqData, setFaqData] = useState([])
    const [pagesData, setPagesData] = useState('')


    useEffect(() => {
        if (pathname.path == 'privacy_policy') {
            setPagesData(counter.privacy_policy)
        } else if (pathname.path == "terms_&_conditions") {
            setPagesData(counter['terms_&_conditions']);
        } else if (pathname.path == "faq") {
            setFaqData(counter?.faq)
        }  else if (pathname.path == "about_us") {
            setPagesData(counter?.about_us)
        } else if (pathname.path == "our_blog") {
            setPagesData('Our blog page')
        } else if (pathname.path == "careers") {
            setPagesData('Careers page')
        } else if (pathname.path == "sitemap") {
            setPagesData(' Sitemap page')
        } else if (pathname.path == "post") {
            setPagesData('Post page')
        } else if (pathname.path == "discover") {
            setPagesData('Discover page')
        } else if (pathname.path == "champ") {
            setPagesData('Champ page')
        } else if (pathname.path == "affiliate") {
            setPagesData('Affiliate page')
        } else if (pathname.path == "prime") {
            setPagesData('Prime page')
        } else if (pathname.path == "premier") {
            setPagesData('Premier page')
        }
    }, [pathname])



    return (
        <section className="container">
            <div className="mt-5 mb-5 ">
                {
                    pathname.path === "faq" ? (
                        <div className="mt-5 mb-5 p-5">
                            <FAQ faqData={faqData} />
                        </div>
                    ) : pathname.path === "sitemap" ? (
                        <Sitemap />
                    ) : pathname.path === "newsroom" ? (
                        <NewsRoom />
                    ) : pathname.path === "contact_us" ? 
                        <Contact_Us/> :
                        pathname.path === "refund_and_cancellation_policy" ?  
                        <RefundAndCancellationPolicy/>
                    : (
                        <div>
                            <div className="" style={{ fontFamily: 'poppins' }}>
                                <h3 dangerouslySetInnerHTML={{ __html: pagesData }}></h3>
                            </div>
                        </div>
                    ) 
                }
            </div>
        </section>
    )
}

export default SupportPages