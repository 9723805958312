import { useEffect, useState } from "react";
import { IoMdSend } from "react-icons/io";
import { IoWarningOutline } from "react-icons/io5";
import {
  Verify_Change_Email,
} from "../../utils/profile/UpdateEmail";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Update_UserName, Verify_Change_UserName } from "../../utils/profile/UpdateUserName";

const EditUserNamePopup = ({ setUserNamePopup, val }) => {
  const [showSendEmailBtn, setSendEmailBtn] = useState(false);
  const [usernameOtpSection, setUserNameOtpSection] = useState(false);
  const [usernameVerifyBtn, setUserNameVerifyBtn] = useState(false);
  const [username, setUserName] = useState({
    f_name:'',
    l_name:''
  });
  const [fullName,setFullName]=useState('')
  const [sendBtnLoading, setSendBtnLoading] = useState(false);
  const [verifyBtnLoading,setVerifyBtnLoading] = useState(false);
  const [otp, setOtp] = useState("");

  useEffect(() => {
    setUserName({f_name:val?.f_name,l_name:val?.l_name});
  }, [val]);

  const handleUserNameChange = (e) => {
    const { value,name } = e.target;
    setUserName({...username,[name]:value});
    // if (username?.f_name != val?.f_name || username?.l_name !== val?.l_name) {
    //   setSendEmailBtn(true);
    //   console.log("trrr")
    // } else {
    //     console.log("fff")
    //   setSendEmailBtn(false);
    // }
  };

  const handleSendOtp = async () => {
    const fdata = {
      f_name:username?.f_name,
      l_name:username?.l_name,
      id: val?.id,
    };
    try {
      setSendBtnLoading(true);
      const response = await Update_UserName(fdata);
      const res_otp = response?.data?.otp;
      setFullName(response?.data?.name);
      setOtp(res_otp);
    //   setFullName()
      setUserNameOtpSection(true);
      if (res_otp) {
        setUserNameVerifyBtn(true);
      } else {
        setUserNameVerifyBtn(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error, {
        position: "top-right",
        autoClose: 2000,
      });
    } finally {
      setSendBtnLoading(false);
    }
  };

  const handleUserNameChangeOtp = (e) => {
    const emailOtp = e.target.value;
    setOtp(emailOtp);
    if (emailOtp?.length > 5 && emailOtp?.length < 7) {
      setUserNameVerifyBtn(true);
    } else {
      setUserNameVerifyBtn(false);
    }
  };

  const handleOtp = async () => {
    const fData = {
      otp: otp,
      username: fullName,
    };
    try {
      setVerifyBtnLoading(true)
      const response = await Verify_Change_UserName(fData);
      console.log("ree", response);
    } catch (error) {
      console.log(error);
    }finally{
      setVerifyBtnLoading(false);
      setUserNamePopup(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="warningModal">
        <div className="warning-mdl">
          {/* <span className="warning-close-btn" >&times;</span> */}

          <h4 className="edit-email-heading">Edit UserName </h4>
          <div className="form-group ">
            <label className="form-label">
              UserName<span className="asterisk">*</span>
            </label>
            <div className="position-relative d-flex align-items-center">
              <input
                type="text"
                value={username?.f_name}
                name="f_name"
                onChange={handleUserNameChange}
                className="form-control"
                placeholder="first name"
                required
              />
            </div>
            
        <div className="mt-2">
            <label className="form-label">
              Last Name<span className="asterisk">*</span>
            </label>
            <div className="position-relative d-flex align-items-center">
              <input
                type="text"
                value={username?.l_name}
                name="l_name"
                onChange={handleUserNameChange}
                className="form-control"
                placeholder="last name"
                required
              />

            </div>
            {usernameOtpSection && (
              <div className="position-relative d-flex align-items-center mt-2">
                <input
                  type="text"
                  value={otp}
                  name="otp"
                  className="form-control"
                  placeholder="Otp"
                  onChange={handleUserNameChangeOtp}
                />
                {usernameVerifyBtn && (
                  <button
                    className="verify-otp"
                    type="button"
                    onClick={handleOtp}
                  >
                    {
                      verifyBtnLoading &&
                      <div className="spinner-border text-light" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    }
                    Verify
                  </button>
                )}
              </div>
            )}
          </div>
         </div>
          

          <div className="d-flex gap-2 justify-content-end">
            <button
              className="mdl-cancel-btn mt-2"
              onClick={() => setUserNamePopup(false)}>
              Cancel
            </button>
            <button
              className="warning-mdl-btn mt-2"
              onClick={handleSendOtp}>
              Send
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditUserNamePopup;
