
const SessionExpiredMdl = ({ handleClose }) => {
  return (<>
    <div className="logout-popup-overlay">
      <div className="logout-popup">
        <p>Your account is logged in on another system, so your session with the current system has been logged out.</p>
        <button
          onClick={handleClose}
          className="ok-button">OK</button>
      </div>
    </div>
  </>)
};

export default SessionExpiredMdl;