import { Link } from "react-router-dom";
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Email_ForgotPassword } from "../../utils/user/UserManager";

const ForgotWithEmail = ({ setOtpSection, setEmailOtp,email,setEmail}) => {
  // const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [sendEmailOtpBtnLoading, setSendEmailOtpBtnLoading] = useState(false);

  const onEmailFormSubmit = async (e) => {
    e.preventDefault();
    if (!email || !email.trim() === "") {
      setEmailError("Email is required");
    } else {
      setEmailError("");
      const data = {
        email: email,
      };
      try {
        setSendEmailOtpBtnLoading(true);
        await Email_ForgotPassword(data).then((response) => {
          if (response.data.success) {
            // setEmailOtp(response?.data?.otp);
            setOtpSection(true);
            // setVerifiedEmailOtp(false);
            // setShowEmailBtn(false);
            toast.success(response?.data?.message, {
              position: "top-center",
              autoClose: 3000,
            });
          }
        });
      } catch (err) {
        toast.error(err?.response?.data?.error, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          theme: "light",
        });
      } finally {
        setSendEmailOtpBtnLoading(false);
      }
    }
  };

  return (
    <>
      <form className="m-0 p-0" onSubmit={onEmailFormSubmit}>
        <>
          <div className="form-group ">
            <label className="login-label">
              Email <span className="asterisk">*</span>
            </label>
            <input
              type="email"
              className="form-control  rounded-3"
              placeholder="Enter Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {emailError && (
              <span className="" style={{ color: "red", fontSize: "13px" }}>
                {emailError}
              </span>
            )}
          </div>
        </>
        <div className="d-flex flex-column">
          <button type="submit" className="btn forgotpassword-btn mt-2">
            {
              sendEmailOtpBtnLoading &&
              <div className="spinner-border  text-light" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            }
            Submit
          </button>
          <Link to="/login" className="forgot-text mt-4">
            Back To Login
          </Link>
        </div>
      </form>
    </>
  );
};

export default ForgotWithEmail;
