import React, { useState, useEffect } from 'react';
import { FaArrowCircleUp } from 'react-icons/fa';

const ScrollButton = () => {
    const [visible, setVisible] = useState(false);

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        setVisible(scrolled > 300);
    };

    useEffect(() => {
        const handleScroll = () => {
            toggleVisible();
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <button className={`${visible ? "mge-btn-scroll1" : "mge-btn-scroll2"} btn-scrollBtn`} onClick={scrollToTop}>
            <FaArrowCircleUp size={22}/>
        </button>
    );
};

export default ScrollButton;
