import { SignupEmailGenerateOtp, SignupNumberGenerateOtp, emailOtpVerifyFinal, numberGenerateOtp, numberOtpVerifyFinal, postEmailVerifyOtp } from "../../Services/Apis"


// genrate email otp
export const Email_Verify_Otp = async (val) => {
  try {
    return await postEmailVerifyOtp(val).then((data) => data)
  }
  catch (error) {
    throw error
  }
}



// verify email otp 

export const Email_Otp_Verify_Final = async (val) => {
  try {
    return await emailOtpVerifyFinal(val).then((data) => data)
  }
  catch (error) {
    console.log(error)
  }
}

// generate number otp

export const Generate_Number_Otp = async (val) => {
  try {
    return await numberGenerateOtp(val).then((data) => data)
  }
  catch (error) {
    console.log(error)
  }
}

// verify number otp
export const Number_Otp_Verify_Final = async (val) => {
  try {
    return await numberOtpVerifyFinal(val).then((data) => data)
  }
  catch (error) {
    console.log(error)
  }
}


// generate number otp

export const Signup_GenerateNumberOtp = async (val) => {
  try {
    return await SignupNumberGenerateOtp(val).then((data) => data)
  }
  catch (error) {
    throw error
  }
}

// verify number otp
export const SignupNumberOtpVerifyFinal = async (val) => {
  try {
    return await SignupNumberOtpVerifyFinal(val).then((data) => data)
  }
  catch (error) {
    console.log(error)
  }
}

// generate email otp
export const Signup_GenerateEmailOtp = async (val) => {
  try {
    return await SignupEmailGenerateOtp(val).then((data) => data)
  }
  catch (error) {
    throw error
  }
}

// verify email otp
export const SignupEmailOtpVerifyFinal = async (val) => {
  try {
    return await SignupEmailOtpVerifyFinal(val).then((data) => data)
  }
  catch (error) {
    console.log(error)
  }
}