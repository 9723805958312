import { Create_Interest, GetInterests } from "../../Services/Apis"


export const getInterestData = async () => {
    try {
        return await GetInterests().then((data) => data)
    } catch (err) {
        throw err
    }
} 

export const CreateInterest = async (fData) => {
    try {
        return await Create_Interest(fData).then((data) => data)
    } catch (err) {
        throw err
    }
} 
