import linkedinImg from "../../assets/images/linkedin-icon.png";
import twitterImg from "../../assets/images/ic-twitter.png";
import instagramimg from "../../assets/images/ic-instagram-copy.png";
import facebookimg from "../../assets/images/ic-facebook.png";
import sponcorringus from "../../assets/images/sponsor logo 3.png";
import "animate.css";
import WOW from "wowjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const SocialFeeds = () => {
  const config = useSelector((state) => state.config_data.configData);
  const [links, setLinks] = useState({
    linkedin: "",
    twitter: "",
    instagram: "",
    google: "",
    facebook: "",
    pinterest: "",
  });

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  useEffect(() => {
    config?.social_media_links?.forEach((item) => {
      switch (item.name) {
        case "linkedin":
          setLinks((prevState) => ({
            ...prevState,
            linkedin: item.link,
          }));
          break;
        case "twitter":
          setLinks((prevState) => ({
            ...prevState,
            twitter: item.link,
          }));
          break;
        case "instagram":
          setLinks((prevState) => ({
            ...prevState,
            instagram: item.link,
          }));
          break;
        case "google-plus":
          setLinks((prevState) => ({
            ...prevState,
            google: item.link,
          }));
          break;
        case "facebook":
          setLinks((prevState) => ({
            ...prevState,
            facebook: item.link,
          }));
          break;
        case "pinterest":
          setLinks((prevState) => ({
            ...prevState,
            pinterest: item.link,
          }));
          break;
        default:
          break;
      }
    });
  }, [config]);

  return (
    <>
      <div className="socialfeeds-div mt-2">
        <div className="container">
          <div className="socialfeeds-text-div d-none d-sm-block">
            <h2 className="socialfeeds-h2">Social Feeds</h2>
            <p className="socialfeeds-p">
              Maximize your event's reach and engagement with strategic social
              media promotions. From Facebook to Instagram, Google to X,
              leverage the power of these platforms to connect with your
              audience, boost attendance, and amplify your brand's visibility to
              ensure maximum ROI. Don't miss out on the opportunity to make a
              lasting impression and drive meaningful results for both hosts and
              sponsors alike.
            </p>
          </div>

          <div className="mt-3 d-none justify-content-center d-sm-flex d-lg-none ">
            <div className="socialfeeds-img-div">
              <div className="d-flex">
                <div className="mt-5">
                  <Link to={links.linkedin} className="">
                    <div className="socialfeeds-linkedin-div">
                      <img src={linkedinImg} alt="linkedin" />
                    </div>
                  </Link>

                  <Link to={links.facebook} className="">
                    <div className="socialfeeds-google-div">
                      <img src={facebookimg} alt="facebook" />
                    </div>
                  </Link>
                </div>

                <div className="">
                  <div className="sf-rounded-ringus-img">
                    <div className="sf-rounded-ringus-main ">
                      <Link to={links.pinterest}>
                        <div className="sf-rounded-ringus-div ">
                          <img
                            className="sf-sponsor-img"
                            src={sponcorringus}
                            alt="ringus-img"
                            style={{ height: 80 }}
                          />
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="mt-5">
                  <Link to={links.instagram} className="">
                    <div className=" socialfeeds-instagram-div">
                      <img src={instagramimg} alt="instagram" />
                    </div>
                  </Link>

                  <Link to={links.twitter} className="">
                    <div className="socialfeeds-twitter-div">
                      <img src={twitterImg} alt="x" />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/* desktop */}

          <div className="mt-3 d-none d-lg-flex justify-content-center">
            <div className="socialfeeds-img-div">
              <div className="row">
                <div className="col-4 row mt-5">
                  <div className="col-md-7">
                    <div className="col-md-10">
                      <div className="wow animate__animated animate__slower animate__bounceIn animate__infinite infinite">
                      <ul class="example-2">
                        <li class="icon-content">
                          <Link
                            to={links.instagram}
                            aria-label="Instagram"
                            data-social="instagram">
                            <div class="filled"></div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-instagram"
                              viewBox="0 0 16 16">
                              <path
                                d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"
                                fill="currentColor"
                              ></path>
                            </svg>
                          </Link>
                          <div class="tooltip">Instagram</div>
                        </li>
                      </ul>
                      </div>

                      {/* <Link to={links.instagram}>
                        <div className="socialfeeds-instagram-div wow animate__animated animate__slower animate__bounceIn animate__infinite infinite">
                          <img src={instagramimg} alt="instagram" />
                        </div>
                      </Link> */}
                    </div>
                  </div>

                  <div className="col-md-5">
                    <div className="social-facebook-div wow animate__animated animate__slower animate__bounceIn animate__infinite infinite">
                    <ul class="example-2">
                      <li class="icon-content">
                        <Link
                          to={links.facebook}
                          aria-label="Facebook"
                          data-social="facebook">
                          <div class="filled"></div>
                          <svg
                            viewBox="0 0 320 512"
                            height="16"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path>
                          </svg>
                        </Link>
                        <div class="tooltip">Facebook</div>
                      </li>
                    </ul>
                    </div>
                    {/* <Link to={links.facebook}>
                      <div className="socialfeeds-google-div wow animate__animated animate__slower animate__bounceIn animate__infinite infinite">
                        <img src={facebookimg} alt="facebook" />
                      </div>
                    </Link> */}
                  </div>
                </div>

                <div className="col-5 d-flex justify-content-center">
                  <div className="sf-rounded-ringus-img ">
                    <div className="sf-rounded-ringus-main">
                      <Link to={links.pinterest}>
                        <div className="sf-rounded-ringus-div">
                          <img
                            className="sf-sponsor-img"
                            src={sponcorringus}
                            alt="ringus-img"
                            style={{ height: 60 }}
                          />
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 row mt-5">
                  <div className="col-md-10">
                    <ul class="example-2">
                      <div className="social-facebook-div wow animate__animated animate__slower animate__bounceIn animate__infinite infinite">
                      <li class="icon-content ">
                        <Link
                          to={links.linkedin}
                          aria-label="LinkedIn"
                          data-social="linkedin">
                          <div class="filled"></div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-linkedin"
                            viewBox="0 0 16 16">
                            <path
                              d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </Link>
                        <div class="tooltip">LinkedIn</div>
                      </li>
                      </div>
                    </ul>

                    {/* <Link to={links.linkedin}>
                      <div className="socialfeeds-linkedin-div wow animate__animated animate__slower animate__bounceIn animate__infinite infinite">
                        <img src={linkedinImg} alt="linkedin" />
                      </div>
                    </Link> */}
                  </div>

                  <div className="col-md-2">
                    <div className="wow animate__animated animate__slower animate__bounceIn animate__infinite infinite">
                    <ul class="example-2">
                      <li class="icon-content">
                        <Link
                          to={links.twitter}
                          aria-label="Twitter"
                          data-social="twitter" >
                          <div class="filled"></div>
                          <svg
                            viewBox="0 0 512 512"
                            height="1.7em"
                            xmlns="http://www.w3.org/2000/svg"
                            class="bi bi-linkedin="
                            fill="currentColor">
                            <path
                              d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </Link>
                        <div class="tooltip">X</div>
                      </li>
                    </ul>
                    </div>
                    {/* <Link to={links.twitter}>
                      <div className="socialfeeds-twitter-div wow animate__animated animate__slower animate__bounceIn animate__infinite infinite">
                        <img src={twitterImg} alt="x" />
                      </div>
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SocialFeeds;
