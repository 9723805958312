import { useEffect, useState } from "react";
import { FaMinus, FaPlus } from "react-icons/fa6";
import { Edit_Interest } from "../../utils/profile/EditInterest";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  IoIosCloseCircleOutline,
  IoMdCloseCircleOutline,
} from "react-icons/io";
import { CreateInterest } from "../../utils/profile/GetInterests";
import { Get_UserInterest } from "../../utils/basicInformation/basicinformationManager";

const EditInterests = ({
  editModal,
  setEditModal,
  types,
  iValue,
  EditMessage,
  interest,
  setEventType,
}) => {
  const [checkedItems, setCheckedItems] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [showCloseBtn, setShowCloseBtn] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [searchError, setSearchError] = useState("");
  const [addInterestSection, setAddInterestSection] = useState(false);
  const [addBtnLoading, setAddBtnLoading] = useState(false);
  const [newInterest, setNewInterest] = useState('')
  const [interestError, setInterestError] = useState("");

  const GetUserInt = async (resData) => {
    try {
      await Get_UserInterest().then((response) => {
        const foundItem = response?.data?.find(item => item?.name == resData);
        setEventType(response?.data);
        setCheckedItems((prevIds) => [...prevIds, foundItem?.id]);
      });
    } catch (error) {
      console.log(error);
    }
  }

  const handleCloseModal = () => {
    setCheckedItems([]);
    setEditModal(false);
    setNewInterest("")
    setSearchValue("")
  };

  const SubmitInterests = async () => {
    if (checkedItems?.length < 10) {
      toast.error("Please Note: Minimum 10 Interests Required for Optimal Experience", {
        position: "top-center",
        autoClose: 3000,
      });
    } else {
      const fData = { type_ids: JSON.stringify(checkedItems) };
      try {
        setBtnLoading(true);
        await Edit_Interest(JSON.stringify(fData)).then((data) => {
          if (data?.data?.success) {
            setEditModal(false);
            EditMessage(data?.data?.message);
          }
        });
      } catch (error) {
        toast.error(error?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } finally {
        setBtnLoading(false);
      }
    }
  };


  const handleCheckboxChange = (value) => {
    setCheckedItems((prevValues) =>
      prevValues.includes(value)
        ? prevValues.filter((v) => v !== value)
        : [...prevValues, value]
    );
  };

  const checkActive = () => {
    types?.filter((data) => {
      if (data?.my_event) {
        setCheckedItems((prevIds) => [...prevIds, data?.id]);
      }
    });
  };

  const checkInterest = () => {
    const filteredData = types?.filter((data) => {
      return interest?.includes(data?.name);
    })?.map((filteredItem) => filteredItem?.id);
    setCheckedItems(filteredData);
  };

  useEffect(() => {
    checkInterest();
    checkActive();
  }, [editModal]);

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    // const searchVal = e.target.value
    //   const filteredResults = types.filter((item) =>
    //     item.name.toLowerCase().includes(searchVal.toLowerCase())
    //   );
    //   setEventType(filteredResults);
    //   setShowCloseBtn(true);
    //   setSearchError("");
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (!searchValue || !searchValue.trim() === "") {
      setSearchError("Invalid search input");
    } else {
      const filteredResults = types.filter((item) =>
        item.name.toLowerCase().includes(searchValue.toLowerCase())
      );
      setEventType(filteredResults);
      setShowCloseBtn(true);
      setSearchError("");
    }
  };

  const handleSearchClear = () => {
    GetUserInt();
    setSearchValue("");
    setShowCloseBtn(false);
  };

  const createInt = async () => {
    if (!newInterest) {
      setInterestError("Interest Required");
    } else {
      setAddBtnLoading(true)
      setInterestError('')
      const fData = {
        name: newInterest
      }
      try {
        const response = await CreateInterest(fData);
        if (response?.data?.success) {
          toast.success(response?.data?.message, {
            position: "top-center",
            autoClose: 3000,
          });
          setNewInterest('');
          const resData = response?.data?.newly_added
          GetUserInt(resData);
          //  setCheckedItems(filteredData);
        } else {
          toast.error(response?.data?.message, {
            position: "top-center",
            autoClose: 3000,
          });
          setNewInterest('')
        }
      } catch (err) {
        toast.error(err?.response?.data?.message, {
          position: "top-center",
          autoClose: 3000,
        });
        setNewInterest('')
      } finally {
        setAddBtnLoading(false)
      }
    }
  }

  const capitalizeWords = (string) => {
    return string.split(' ')?.map(word =>
      word.charAt(0).toUpperCase() + word.slice(1)
    )?.join(' ');
  };

  const handleInterestChange = (e) => {
    const inputValue = e.target.value;
    const capitalizedValue = capitalizeWords(inputValue);
    setNewInterest(capitalizedValue);
  }

  return (
    <>
      <ToastContainer />
      <div className={`custom-modal ${editModal ? "show" : ""}  `}>
        <div className="interest-modal-dialog">
          <div className="interest-modal-content">
            <div className="modal-header">
              <div className="d-flex gap-2">
                <h5 className="modal-title">Edit Interests</h5>
              </div>
              <button
                type="button"
                className="btn-close"
                onClick={handleCloseModal}
              ></button>
            </div>
            <div className="modal-body">
              <div className="">
                <form className="d-flex gap-2" onSubmit={handleSearch}>
                  <div className="d-flex align-items-center w-100 position-relative">
                    <input
                      className="form-control"
                      placeholder="Search"
                      value={searchValue}
                      name="searchValue"
                      onChange={handleSearchChange}
                    />
                    {showCloseBtn && (
                      <button
                        className="search-close-btn"
                        type="button"
                        onClick={handleSearchClear}>
                        <IoIosCloseCircleOutline color="red" size={24} />
                      </button>
                    )}
                  </div>
                  {/* <button className="interests-edit-btn" type="submit">
                    Search
                  </button> */}
                </form>

                <div className="mt-3">
                  {!addInterestSection ?
                    <button className="interests-edit-btn" onClick={() => setAddInterestSection(!addInterestSection)}>Add +</button>
                    :
                    <div className="d-flex gap-2">
                      <div className="d-flex align-items-center w-100 position-relative">
                        <div className="w-100">
                          <input
                            className="form-control"
                            placeholder="Enter Interest"
                            value={newInterest}
                            name="newInterest"
                            onChange={handleInterestChange}
                          />
                          {
                            interestError &&
                            <p className="error ms-1">{interestError}</p>
                          }
                        </div>

                        {showCloseBtn && (
                          <button
                            className="search-close-btn"
                            type="button"
                            onClick={handleSearchClear}>
                            <IoIosCloseCircleOutline color="red" size={24} />
                          </button>
                        )}
                      </div>

                      <button className="d-flex align-items-center interests-edit-btn" type="submit" onClick={createInt}>
                        {addBtnLoading &&
                          <div className=" spinner-border text-light" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>}
                        Add
                      </button>
                    </div>
                  }
                </div>

                <hr className="w-100" />
                {searchError && (
                  <p className="error-message text-danger ms-1">
                    {searchError}
                  </p>
                )}
              </div>
              <div className="modal-data mt-3">
                <div className=" mb-2 mt-4">
                  <div className="content-button-div">
                    {types?.filter(fill => fill?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()))?.map((data) => {
                      if (checkedItems.includes(data.id)) {
                        return (
                          <div className="mt-2" key={data.id}>
                            <button
                              className=" active-hashtag-div"
                              onClick={() => handleCheckboxChange(data?.id)}>
                              <p className="active-hashtag-text">
                                {data?.name}
                              </p>
                            </button>
                          </div>
                        );
                      }
                    })}
                  </div>
                  <hr className="me-5" />
                  <div className="content-button-div">
                    {types?.filter(fill => fill?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()))?.map((val) => {
                      return (
                        <div key={val?.id}>
                          {checkedItems.includes(val.id) ? (
                            <div className=" mt-2">
                              <button
                                className="active-hashtag-div"
                                onClick={() => handleCheckboxChange(val?.id)}>
                                <p className="active-hashtag-text">
                                  {val?.name}
                                </p>
                              </button>
                            </div>
                          ) : (
                            <div className=" mt-2">
                              <button
                                className="hashtag-div"
                                onClick={() => handleCheckboxChange(val?.id)}>
                                <p className="hashtag-text">{val?.name}</p>
                              </button>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-end gap-3 mt-5">
                <button
                  className="img-close-btn text-dark"
                  type="button"
                  onClick={handleCloseModal}>
                  Close
                </button>
                <button
                  className="interests-edit-btn"
                  onClick={SubmitInterests}>
                  {btnLoading && (
                    <div className="spinner-border text-light" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>)}
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditInterests;
