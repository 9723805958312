import React, { useEffect, useState } from "react";
import Loader2 from "../Loader/Loader2";
import { SupportTicket } from "../../utils/chatmanage/ChatManage";

const CreateNewChatPopup = ({setNewChatModal,GetRequestMessages}) => {
  const [loading, setLoading] = useState(false);
  const [input,setInput]=useState({
    subject:'',
    description:''
  });
  const [errors, setErrors] = useState({});

   const handleInputChange=(e)=>{
    const {name,value} = e.target;
    
    if(name == 'subject'){
      if (value.length <= 100) {
        setInput((prevInput) => ({
          ...prevInput,
          [name]: value
        }));
     
     }}
      else{
        setInput((prevInput) => ({
          ...prevInput,
          [name]: value
        }));
      }
    }


  const validation = () => {
    const newErrors = {};
    if (input.subject.trim() === "") {
      newErrors.subject = "Subject is required";
    }
    if (input.description.trim() === "") {
      newErrors.description = "Describe your issue is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const handleSubmit =async (e) => {
    e.preventDefault();
    const isValid = validation();
    if (isValid) {
        setLoading(true)
        try{
            const response = await SupportTicket(input)
             setInput({  
             subject:'',
             description:''
            })
            GetRequestMessages()
            setNewChatModal(false)
        }catch(err){
            console.log(err)
        }finally{
            setLoading(false)
        }
    }
  };
 
  return (
    <>
      <div className="warningModal">
        <div className="chat-support-mdl ">
          <h4 className="">New Chat</h4>
          
          <form onSubmit={handleSubmit}>
          <div className="card-content">
              <div className="price-detail-div">
                <div className="mt-3">
                    <div className="form-group">
                      <label className="form-label">
                        Subject<span className="asterisk">*</span>
                      </label>
                      <p className="Character-0500">
                       Character:<span className="text-style-1">{input.subject.length}
                      {input.subject.length < 100 ? `/${100 - input.subject.length}` : ''}</span>
                    </p>
                      <input
                        type="text"
                        className="form-control"
                        value={input.subject}
                        name="subject"
                        placeholder="Subject"
                        onChange={handleInputChange}/>
                        {errors.subject && (
                         <span className="error">{errors.subject}</span>
                        )}
                    </div>

                    <div className="form-group">
                    <div class="">
                        <label
                          for="exampleFormControlTextarea1"
                          class="form-label" >
                          Describe Your Issue
                        </label>
                        <textarea
                          className="form-control"
                          name="description"
                          placeholder="Describe Your Issue"
                          value={input.description}
                          rows="3"
                          onChange={handleInputChange}
                        ></textarea>
                          {errors.description && (
                            <span className="error">{errors.description}</span>
                          )}
                      </div>
                    </div>
                </div>
              </div>
          </div>
        
         <div className="d-flex gap-2 justify-content-end">
            <button
              className="cancel-champ-btn mt-2"
              onClick={()=>setNewChatModal(false)}>
              Cancel
            </button>
            <button
              className="warning-mdl-btn mt-2"
              type="submit"
            >
              {loading ? 
               <div className="spinner-border text-light " role="status">
              <span className="sr-only">Loading...</span>
            </div>  
              :""}
              Submit
            </button>
        </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateNewChatPopup;
