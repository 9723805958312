import { LineWave } from "react-loader-spinner"


const Loader2 = () => {
    return (
        <>
            <div className="d-flex justify-content-center align-items-center">
                <LineWave
                    visible={true}
                    height="100"
                    width="100"
                    color="#f43254"
                    ariaLabel="line-wave-loading"
                    wrapperStyle={{}}
                    wrapperclassName=""
                    firstLineColor=""
                    middleLineColor=""
                    lastLineColor=""
                />
            </div>
        </>
    )
}
export default Loader2