import { GetMyEventTransaction } from "../../Services/Apis"


export const Get_MyEventTransaction=async(currentPage,sortValue,searchValue,statusValue)=>{
    try{
        const response = await GetMyEventTransaction(currentPage,sortValue,searchValue,statusValue).then((data) => data)
        return response
    }catch(error){
        console.log(error)
    }
} 
