import { getNotificationList, getloginActivityMsg } from "../../Services/Apis"

export const NotificationDatalist = async (currentPage) => {
    try {
        return await getNotificationList(currentPage).then((data) => data)

    } catch (error) {
        console.log(error)
    }
}


export const GetLoginActivityMsg = async (id, currentPage) => {
    try {
        return await getloginActivityMsg(id, currentPage).then((data) => data)

    } catch (error) {
        console.log(error)
    }
}