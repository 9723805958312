import React, { useState } from "react";
import OTPInput from "react-otp-input";
import { Link } from "react-router-dom";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const LoginWithMobile = () => {
  const [loading, setLoading] = useState(false);
  const [mobileForm, setMobileForm] = useState({
    dialingcode: "",
    mobilenumber: ""
  });

  const [otpSection, setOtpSeaction] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpError,setOtpError]=useState("");
  const [errors, setErrors] = useState({});


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMobileForm((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  const handleLogin=()=>{
    if(!otp){
      setOtpError("Otp is required")
    }else if (otp.length < 6) {
      setOtpError("Please enter a 6-digit OTP.");
    } else {
      console.log("Submitting OTP:", otp);
      // Reset OTP input
      setOtp("");
      setOtpError("");
    }
  }

  const onSubmitMobile = (e) => {
    e.preventDefault();
    setLoading(false);
    if (validateForm()) {
      setOtpSeaction(true);
      console.log('Form is valid:');
    } else {
      console.log('Form is not valid');
    }
  };

  const onLoginWithMobile = (e) => {
    e.preventDefault();

  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {
      dialingCode: '',
      mobileNumber: '',
    };

    if (!mobileForm.dialingcode.trim()) {
      newErrors.dialingCode = 'Dialing code is required';
      valid = false;
    }

    if (!mobileForm.mobilenumber.trim()) {
      newErrors.mobileNumber = 'Mobile number is required';
      valid = false;
    } else if (!/^\d+$/.test(mobileForm.mobilenumber.trim())) {
      newErrors.mobileNumber = 'Invalid mobile number, should contain only numbers';
      valid = false;
    } else if (mobileForm.mobilenumber.trim().length < 10) {
      newErrors.mobileNumber = 'Mobile number should be at least 10 digits';
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleOnChange = (value, country) => {
    setMobileForm({
      dialingcode: `${country.format.charAt(0)}${country?.dialCode}`,
      mobilenumber:value
    })
  }

  return (
    <>
      <div>
        <p className="login-p">Sign In with Mobile Number</p>
        {otpSection ? (
          <form onSubmit={onLoginWithMobile}>
            <div className="form-group">
              <label className="login-label">OTP</label>
              <OTPInput
                inputStyle={"form-control w-100 "}
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
              />
             {otpError && (
              <span className="" style={{ color: "red", fontSize: "13px" }}>
                {otpError}
              </span>
            )}
            </div>
            <button
              type="submit"
              className="btn d-flex align-items-center justify-content-center gap-1 login-btn mt-4"
              onClick={handleLogin}
            >
              {loading ? (
                <div className="spinner-border  text-light" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                ""
              )}
              Login
            </button>
          </form>
        ) : (
          <form onSubmit={onSubmitMobile}>
            <div className=" form-group">
              <label className="login-label">Mobile Number</label>
              <PhoneInput
                className=""
                country={'in'}
                onChange={handleOnChange}
              />
             {errors?.mobileNumber && (
              <span className="" style={{ color: "red", fontSize: "13px" }}>
                {errors?.mobileNumber}
              </span>
            )}
            </div>

            <div className="form-group"></div>
            <div className="d-flex flex-column">
              {/* <Link to="/forgotpassword" className="forgot-text">
                Forgot password?
              </Link> */}
              <button
                type="submit"
                className="btn d-flex align-items-center justify-content-center gap-1 login-btn mt-4">
                {loading ? (
                  <div className="spinner-border text-light" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  ""
                )}
                Send OTP
              </button>
            </div>
          </form>
        )}
      </div>
    </>
  );
};
export default LoginWithMobile;
