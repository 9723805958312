
import { useDispatch, useSelector } from "react-redux";
import { setEditdata } from "../../redux/EditEvent/editevent";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import preview from "../../assets/images/preview.svg";
import { ToastContainer, toast } from "react-toastify";
import EditPastEventEssentials from "../../components/EditPastEventForm/EditPastEventEssentials";
import { GetPastEventEssentials } from "../../utils/createPastEventForm/createpasteventform";
import EditPastEventGuestForm from "../../components/EditPastEventForm/EditPastEventGuestForm";
import EditPastEventPitchForm from "../../components/EditPastEventForm/EditPastEventPitchForm";
import EditPastEventHost from "../../components/EditPastEventForm/EditPastEventHost";
import EditPastEventPartnerShip from "../../components/EditPastEventForm/EditPastEventPartnerShip";
import EditPastEventSponsorPackage from "../../components/EditPastEventForm/EditPastEventSponsorPackage";
import EditPastEventMediaHub from "../../components/EditPastEventForm/EditPastEventMediaHub";
import EditPastEventSocialMediaHub from "../../components/EditPastEventForm/EditPastEventSocialMediaHub";
import { TiArrowLeft } from "react-icons/ti";
import { RiArrowRightSFill } from "react-icons/ri";

const EditPastEvent = () => {
    const navigate = useNavigate();
    const [tabValue, setTabValue] = useState("Event-Essentials");
    const [EditData, setEditData] = useState({})
    const params = useParams();
    const id = params?.id;


    const ConfigData = useSelector((state) => state?.config_data?.configData);
    const baseUrls = ConfigData?.base_urls;

    const dispatch = useDispatch();

    const EditEvents = async () => {
        try {
            const response = await GetPastEventEssentials(id);
            setEditData(response?.data?.data)
            // dispatch(setEditdata(response?.data?.data));
        } catch (err) {
            console.log(err)
        }
    };

    useEffect(() => {
        if (tabValue == "Event-Essentials") {
            EditEvents();
        }
    }, [id, tabValue]);

    const Messages = (val) => {
        toast.success(val, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            theme: "light",
        });
    };

    const tabNames = {
        "Event-Essentials": "Event Essentials",
        "Event-Guest": "Event Guests",
        "Event-Pitch": "Event Pitch",
        "Event-Host": "Event Host",
        "Event-Partnerships": "Event Partnerships",
        "Sponsor-Packages": "Sponsor Packages",
        "coupons": "Coupons",
        "Event-Media-Hub": "Event Media Hub",
        "Social-Hub": "Social Hub",
        "Cancellation-Refund": "Cancellation / Refund",
        "References": "References",
      };

    return (
        <>
            <ToastContainer />
            <div className="newevent container fluid">
                <div className="row">
                    <div className="col-6 d-flex align-items-center gap-1">
                    <h5 className="create-event-heading m-0">Edit Past Event</h5> 
                     <RiArrowRightSFill size={20}/>
                    <h5 className="create-event-heading m-0">{tabNames[tabValue]}</h5>
                    </div>
                    <div className="col-6">
                        <div className="d-flex justify-content-end gap-2">
                            <button className="btn d-flex align-items-center gap-1 preview-btn"
                                onClick={()=>navigate(`/pasteventpreview/${id}`, { state:"EditPastEvent"})}>
                                <img src={preview} alt="pre" />
                                Preview
                            </button>

                            <button className="go-back-btn d-flex align-items-center" onClick={() => navigate(-1)} type="button">
                             <TiArrowLeft size={20}/> Go Back
                             </button>
                        </div>
                    </div>
                </div>

                <div className="row mb-5 mt-4">
                    <div className="col-lg-3 d-flex flex-column gap-2 create-main-tab">
                        <button
                            onClick={() => setTabValue("Event-Essentials")}
                            className={`${tabValue == "Event-Essentials"
                                ? "active-create-event-tab"
                                : "create-event-tab"} `}>
                            Event Essentials
                        </button>

                        <button
                            onClick={() => setTabValue("Event-Guest")}
                            className={`${tabValue == "Event-Guest" ? "active-create-event-tab" : "create-event-tab"
                                } `} >
                            Event Guests
                        </button>

                        <button
                            onClick={() => setTabValue("Event-Pitch")}
                            className={`${tabValue == "Event-Pitch" ? "active-create-event-tab" : "create-event-tab"
                                } `}>
                            Event Pitch
                        </button>
                        <button
                            onClick={() => setTabValue("Event-Host")}
                            className={`${tabValue == "Event-Host" ? "active-create-event-tab" : "create-event-tab"
                                } `}>
                            Event Host
                        </button>

                        <button
                            onClick={() => setTabValue("Event-Partnerships")}
                            className={`${tabValue == "Event-Partnerships" ? "active-create-event-tab" : "create-event-tab"
                                } `}>
                            Event Partnerships
                        </button>


                        <button
                            onClick={() => setTabValue("Sponsor-Packages")}
                            className={`${tabValue == "Sponsor-Packages" ? "active-create-event-tab" : "create-event-tab"
                                } `}>
                            Sponsor Packages
                        </button>

                        <button
                            onClick={() => setTabValue("Event-Media-Hub")}
                            className={`${tabValue == "Event-Media-Hub" ? "active-create-event-tab" : "create-event-tab"
                                } `}>
                            Event Media Hub
                        </button>

                        <button
                            onClick={() => setTabValue("Social-Hub")}
                            className={`${tabValue == "Social-Hub" ? "active-create-event-tab" : "create-event-tab"
                                } `}>
                            Social Hub
                        </button>
                    </div>

                    <div className="col-lg-9">
                        {tabValue == "Event-Essentials" ? (
                            <div className="">
                                <EditPastEventEssentials id={id} EditData={EditData} setTabValue={setTabValue} Messages={Messages} />
                            </div>
                        ) : tabValue == "Event-Guest" ? (
                            <div className="">
                                <EditPastEventGuestForm id={id} setTabValue={setTabValue} Messages={Messages} />
                            </div>
                        ) : tabValue == "Event-Pitch" ? (
                            <div className="">
                                <EditPastEventPitchForm id={id} setTabValue={setTabValue} Messages={Messages} />
                            </div>
                        ) : tabValue == "Event-Host" ? (
                            <div className="">
                                <EditPastEventHost id={id} setTabValue={setTabValue} Messages={Messages} />
                            </div>
                        ) : tabValue == "Event-Partnerships" ? (
                            <div className="">
                                <EditPastEventPartnerShip id={id} setTabValue={setTabValue} Messages={Messages} />
                            </div>
                        ) : tabValue == "Sponsor-Packages" ? (
                            <div className="">
                                <EditPastEventSponsorPackage id={id} setTabValue={setTabValue} Messages={Messages} />
                            </div>
                        ) : tabValue == "Event-Media-Hub" ? (
                            <div className="">
                                <EditPastEventMediaHub id={id} setTabValue={setTabValue} Messages={Messages} />
                            </div>
                        ) : tabValue == "Social-Hub" ? (
                            <div className="">
                                <EditPastEventSocialMediaHub id={id} setTabValue={setTabValue} Messages={Messages} />
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditPastEvent;
