import arrow from "../../assets/images/arrow2.svg";
import { useState, useEffect } from 'react'
import { CreatePastEventSocialHub, GetPastEventSocialHub } from "../../utils/createPastEventForm/createpasteventform";
import { useNavigate } from "react-router-dom";
import { IoMdInformationCircleOutline } from "react-icons/io";


const PastEventSocialMediaHub = ({ setTabValue, event_id, Messages, isEdit, tabValue, Eid }) => {
    const [input, setInput] = useState({
        facebook: "",
        twitter: "",
        linkedin: "",
        instagram: "",
        youtube: ""
    })
    const [loading, setLoading] = useState(false)
    const [isHovered, setIsHovered] = useState(false)
    const navigate = useNavigate()
    const [error, setError] = useState({})

    const getSocialHub = async () => {
        try {
            await GetPastEventSocialHub(Eid)
                .then((response) => {
                    const data = response?.data?.data
                    if (data != null) {
                        setInput(data)
                    }
                })
        } catch (errr) {
            console.log(errr)
        }
    }

    useEffect(() => {
        if (isEdit && tabValue == "Social-Hub") {
            getSocialHub();
        }
    }, [])

    const handleChange = (e) => {
        const { name, value } = e.target
        setInput({
            ...input,
            [name]: value,
        });
    }

    const OnSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoading(true)
            try {
                CreatePastEventSocialHub(input, Eid).then((results) => {
                    if (results?.data?.success) {
                        Messages(results?.data?.message);
                        navigate(`/pasteventpreview/${Eid}`, { state: "CreatePastEvent" })
                    }
                })
            } catch (err) {
                console.log(err)
            }
            setLoading(false)
        }
    }

    const validateForm = () => {
        let valid = true;
        const newErrors = {};
    
        const urlRegex = /^https?|ftp:\/\/[^ "]+\/?$/;
    
        const trimmedFacebookUrl = input?.facebook?.trim();
        if (input?.facebook !== "" && input?.facebook !== null) {
          if (!urlRegex.test(trimmedFacebookUrl)) {
            newErrors.facebook = "Invalid Facebook URL";
            valid = false;
          }
        }
    
        const trimmedtwitterUrl = input?.twitter?.trim();
        if (input?.twitter !== "" && input?.twitter !== null) {
          if (!urlRegex.test(trimmedtwitterUrl)) {
            newErrors.twitter = "Invalid Twitter URL";
            valid = false;
          }
        }
    
        const trimmedlinkedinUrl = input?.linkedin?.trim();
        if (input?.linkedin !== "" && input?.linkedin !== null) {
          if (!urlRegex.test(trimmedlinkedinUrl)) {
            newErrors.linkedin = "Invalid LinkedIn URL";
            valid = false;
          }
        }
    
        const trimmedinstagraminUrl = input?.instagram?.trim();
        if (input?.instagram !== "" && input?.instagram !== null) {
          if (!urlRegex.test(trimmedinstagraminUrl)) {
            newErrors.instagram = "Invalid Instagram URL";
            valid = false;
          }
        }
    
        const trimmedyoutubeinUrl = input?.youtube?.trim();
        if (input?.youtube !== "" && input?.youtube !== null) {
          if (!urlRegex.test(trimmedyoutubeinUrl)) {
            newErrors.youtube = "Invalid YouTube URL";
            valid = false;
          }
        }
    
        setError(newErrors);
        return valid;
      };


    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <>
            <div className="basicInfo">
                {/* <h2 className="basicinfo-head">Social Hub</h2> */}

                <div className="d-flex align-items-center gap-1 position-relative">
                    <p className="createevent-head-p">
                        This section is where you can link all your event-related social media to keep attendees
                        connected and engaged through various platforms.
                    </p>
                    {/* <h2 className="event-heading mb-0 d-flex flex-column flex-md-row align-items-md-center gap-md-2">Social Hub</h2>
                    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                        <IoMdInformationCircleOutline size={23} />
                    </div>
                    {isHovered && (
                        <div className="info-popup" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                            <p className="info-popup-text">
                                This section is where you can link all your event-related social media to keep attendees
                                connected and engaged through various platforms.
                            </p>
                        </div>
                    )} */}
                </div>

                <form onSubmit={OnSubmit}>
                    <div>
                        <h5 className="my-3 form-section-heading">
                            Social Media
                        </h5>
                        <div className="row">
                            <div className="form-group col-md-6">
                                <label className="form-label">Facebook</label>
                                <IoMdInformationCircleOutline size={16} color="black" title="Enter the URL of your event's Facebook page for attendees to like and follow" />

                                <input
                                    type="text"
                                    value={input.facebook}
                                    name="facebook"
                                    onChange={handleChange}
                                    className="form-control"
                                    placeholder="Enter Facebook Url"
                                />
                                {error.facebook && (
                                    <span className="error">{error.facebook}</span>
                                )}
                            </div>

                            <div className="form-group col-md-6">
                                <label className="form-label">X (Twitter)</label>
                                <IoMdInformationCircleOutline size={16} color="black" title="Provide your event's X (Twitter) handle so attendees can follow and tweet about your event" />

                                <input
                                    type="text"
                                    value={input.twitter}
                                    name="twitter"
                                    onChange={handleChange}
                                    className="form-control"
                                    placeholder="Enter Twitter Url"
                                />
                                {error.twitter && (
                                    <span className="error">{error.twitter}</span>
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-md-6">
                                <label className="form-label">LinkedIn</label>
                                <IoMdInformationCircleOutline size={16} color="black" title="Link the LinkedIn page of your event for professional networking and updates" />

                                <input
                                    type="text"
                                    value={input.linkedin}
                                    name="linkedin"
                                    onChange={handleChange}
                                    className="form-control"
                                    placeholder="Enter LinkedIn Url"
                                />
                                {error.linkedin && (
                                    <span className="error">{error.linkedin}</span>
                                )}
                            </div>

                            <div className="form-group col-md-6">
                                <label className="form-label">Instagram</label>
                                <IoMdInformationCircleOutline size={16} color="black" title="Add the Instagram profile link for your event to share photos and stories" />

                                <input
                                    type="text"
                                    value={input.instagram}
                                    name="instagram"
                                    onChange={handleChange}
                                    className="form-control"
                                    placeholder="Enter Instagram Url"
                                />
                                {error.instagram && (
                                    <span className="error">{error.instagram}</span>
                                )}
                            </div>

                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group col-md-6">
                            <label className="form-label">YouTube Channel</label>
                            <IoMdInformationCircleOutline size={16} color="black" title="Add the Youtube channel link for your event to reels and photos" />

                            <input
                                type="text"
                                value={input.youtube}
                                name="youtube"
                                onChange={handleChange}
                                className="form-control"
                                placeholder="Enter YouTube Channel Url"
                            />
                            {error.youtube && (
                                <span className="error">{error.youtube}</span>
                            )}
                        </div>
                    </div>
                    <div className="d-flex justify-content-md-end align-items-center mt-3">
                        <div className="d-flex flex-column flex-md-row gap-2">
                            <button
                                className="d-flex align-items-center gap-1 saveandcontinue-btn"
                                type="submit">
                                {loading ? (
                                    <div className="spinner-border text-light "
                                        role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                ) : (
                                "" )}
                                Save and Continue
                                <img className="arrow-img" src={arrow} alt="arrow" />
                            </button>
                        </div>
                    </div>
                </form>

            </div>
        </>
    )
}
export default PastEventSocialMediaHub


