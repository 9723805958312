import { useEffect, useState, useMemo, useCallback, useRef } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Get_SponsorEventsForChamp } from "../../utils/champ/champAlldata";
import moment from "moment";
import Loader from "../../components/Loader/Loader";
import OrderDetaillListCard from "../../components/ChamplistData/OrderDetaillListCard";
import { useSelector } from "react-redux";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import ReactPaginate from "react-paginate";
import { IoCloseOutline } from "react-icons/io5";
import Loader2 from "../../components/Loader/Loader2";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";

const SponsorEventList = () => {
  const [SponsorEventList, setSponsorEventList] = useState([]);
  const { state } = useLocation();
  const currency = useSelector((val) => val?.CurrencyConvert);
  const [searchValue, setSearchValue] = useState("");
  const [searchError, setSearchError] = useState("");
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [amountDropdownOpen, setAmountDropdownOpen] = useState(false);
  const [dateDropdownOpen, setDateDropdownOpen] = useState(false);
  const [statusValue, setStatusValue] = useState("");
  const [sortValue, setSortValue] = useState("");
  const [columnDefs, setColumnDefs] = useState([]);
  const gridRef = useRef();

  // const isValid = isTokenValid();
  const params = useParams();
  const id = params?.id;
  const [currencyIcn, setCurrencyIcn] = useState("");
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const statusDropdownRef = useRef(null);
  const amountDropdownRef = useRef(null);
  const dateDropdownRef = useRef(null);

  const checkCurrency = (data) => {
    data?.map((val) => {
      if (val?.currency == "INR") {
        setCurrencyIcn("₹");
      } else if (val?.currency == "USD") {
        setCurrencyIcn("$");
      } else if (val?.currency == "EUR") {
        setCurrencyIcn("€");
      } else if (val?.currency == "GBP") {
        setCurrencyIcn("€");
      }
    });
  };

  const handleItemClick = (item) => {
    setStatusDropdownOpen(false);
  };

  const Get_SponsorEvents = async (searchValue) => {
    try {
      setLoading(true);
      const response = await Get_SponsorEventsForChamp(
        id,
        searchValue,
        statusValue,
        sortValue,
        currentPage
      );
      setSponsorEventList(response?.data?.data);
      checkCurrency(response?.data?.data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    Get_SponsorEvents();
  }, [id, statusValue, sortValue, currentPage]);

  const handleSearch = (e) => {
    e.preventDefault();
    if (!searchValue || !searchValue.trim() === "") {
      setSearchError("Invalid search input");
    } else {
      setSearchError("");
      Get_SponsorEvents(searchValue);
      setSearchValue("");
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const statusDropdownNode = statusDropdownRef.current;
      const amountDropdownNode = amountDropdownRef.current;
      const dateDropdownNode = dateDropdownRef.current;
      if (
        statusDropdownNode &&
        !statusDropdownNode.contains(event.target) &&
        amountDropdownNode &&
        !amountDropdownNode.contains(event.target) &&
        dateDropdownNode &&
        !dateDropdownNode.contains(event.target)
      ) {
        // Clicked outside all dropdowns, close all dropdowns
        setStatusDropdownOpen(false);
        setAmountDropdownOpen(false);
        setDateDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage?.selected + 1);
  };

  useEffect(() => {
    setColumnDefs([
      {
        headerName: "Transaction Id",
        field: "transaction_id",
        cellRenderer: ({ value, data }) => {
          return <p className=""> {value ? value : "-"}</p>;
        },
      },
      {
        headerName: "Event",
        field: "event",
        cellRenderer: ({ value, data }) => {
          return (
            <Link
              className="navigate-detail-btn"
              to={`/eventdetail/${data?.event?.id}`}
              state={{ type: "Events" }}>
              <p className="order-list-text text-capitalize">
                {data?.event?.event_title}
              </p>
            </Link>
          );
        },
      },
      {headerName: "Package", field: "package.title",width: 150},
      {
        headerName: "Package Amount",
        field: "package.amount",
        // width: 150,
        cellRenderer: ({ value, data }) => {
          const package_amt = data?.package?.amount;
          const formattedAmount = package_amt
            ? `${currency?.currencySymbol
              ? currency?.currencySymbol
              : currencyIcn
            } ${currency?.selectedCurrencyPrice
              ? (currency?.selectedCurrencyPrice * package_amt).toFixed(2)
              : package_amt
            }`
            : 0;
          return <p className="">{formattedAmount}</p>;
        },
      },
      {
        headerName: "Coupon Code ",
        field: "coupons.coupon_code",
        // width: 120,
        valueGetter: function (params) {
          const couponCode = params.data?.coupons?.coupon_code;
          return couponCode ? couponCode : "-"; // return empty string if null or undefined
        },
      },
      {
        headerName: "Coupon Discount",
        field: "discount",
        // width: 120,
      },
      {
        headerName: "Sponsored Amount",
        field: "amount",
        // width: 150,
        cellRenderer: ({ value, data }) => {
          const package_amt = data?.amount;
          const formattedAmount = package_amt
            ? `${currency?.currencySymbol
              ? currency?.currencySymbol
              : currencyIcn
            } ${currency?.selectedCurrencyPrice
              ? (currency?.selectedCurrencyPrice * package_amt).toFixed(2)
              : package_amt}`
            : 0;
          return <p className="">{formattedAmount}</p>;
        },
      },
      {
        headerName: "Income",
        width: 120,
        field: "total_commission",
        cellRenderer: ({ value, data }) => {
          const package_amt = data?.total_commission;
          const formattedAmount = package_amt
            ? `${currency?.currencySymbol
              ? currency?.currencySymbol
              : currencyIcn
            } ${currency?.selectedCurrencyPrice
              ? (currency?.selectedCurrencyPrice * package_amt).toFixed(2)
              : package_amt
            }`
            : 0;
          return <p className="">{formattedAmount}</p>;
        },
      },
      {
        headerName:"Manage Request Enquiry",
        field: "manage_request_enquiry",
      },
      {
        headerName: "Status",
        width: 120,
        field: "payment_status",
        cellRenderer: ({ value, data }) => {
          const status = data?.payment_status;
          return (
            <p
              className={
                value == "success"
                  ? "success-status-text text-capitalize p-0"
                  : value == "failed"
                    ? "failed-status-text text-capitalize p-0"
                    : "pending-status-text text-capitalize p-0"}>
              {value}
            </p>
          );
        },},
      {
        headerName: "Date",
        field: "created_at",
        cellRenderer: ({ value, data }) => {
          return moment(data?.created_at).format("ll hh:mm A");
        },
      },
    ]);
  }, [currency, SponsorEventList?.data]);


  const defaultColDef = useMemo(() => {
    return {
      filter: "agTextColumnFilter",
      floatingFilter: true,
    };
  }, []);


  const onGridReady = useCallback((params) => { }, []);

  const onBtExport = useCallback(() => {
    gridRef.current.api.exportDataAsExcel();
  }, []);

  return (
    <>
      <div className="container-fluid ">
        <div className="row pt-4 align-items-center">
          <div>
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="events-heading d-flex flex-column flex-md-row align-items-md-center gap-md-2 mb-0">
              Sponsored Events <span className="username-heading">({SponsorEventList?.total})</span> 
            </h2>
            <Link to="/dashboard" className="btn viewall-btn-p">Go Back</Link>
          </div>
          <div>
              <span className="host-username-heading" title={state?.name}>
                  ({state?.name})
               </span>
            </div>
           </div>
          {/* <div className="col-md-4">
            <form onSubmit={handleSearch}>
              <div className="d-flex search-div">
                <input
                  className="form-control search-event-input"
                  name="searchValue"
                  value={searchValue}
                  placeholder="Search Events..."
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <button className="event-search-btn" type="submit">
                  Search
                </button>
              </div>
            </form>
            {searchError && (
              <p className="error-message text-danger ms-1">{searchError}</p>
            )}
          </div> */}

          {/* <div className="col-md-4 d-flex justify-content-end align-items-center gap-3">
            <p className="m-0 order-list-text">Sort :</p>

            <div className="position-relative" ref={statusDropdownRef}>
              <button
                className="d-flex order-dropdown-button"
                onClick={() => {
                  setStatusDropdownOpen(!statusDropdownOpen);
                  setAmountDropdownOpen(false);
                  setDateDropdownOpen(false);
                }}
              >
                Status
                {statusDropdownOpen ? (
                  <IoMdArrowDropup size={24} />
                ) : (
                  <IoMdArrowDropdown size={24} />
                )}
              </button>
              {statusDropdownOpen && (
                <div className="dropdown-menu-data">
                  <div
                    className="dropdown-item"
                    onClick={() => {
                      setStatusValue("success");
                      setStatusDropdownOpen(false);
                    }}
                  >
                    Success
                  </div>
                  <div
                    className="dropdown-item"
                    onClick={() => {
                      setStatusValue("Pending");
                      setStatusDropdownOpen(false);
                    }}
                  >
                    Pending
                  </div>
                  <div
                    className="dropdown-item"
                    onClick={() => {
                      setStatusValue("failed");
                      setStatusDropdownOpen(false);
                    }}
                  >
                    Failed
                  </div>
                </div>
              )}
            </div>

            <div className="position-relative" ref={amountDropdownRef}>
              <button
                className="d-flex order-dropdown-button"
                onClick={() => {
                  setAmountDropdownOpen(!amountDropdownOpen);
                  setStatusDropdownOpen(false);
                  setDateDropdownOpen(false);
                }}
              >
                {" "}
                Amount
                {amountDropdownOpen ? (
                  <IoMdArrowDropup size={24} />
                ) : (
                  <IoMdArrowDropdown size={24} />
                )}
              </button>
              {amountDropdownOpen && (
                <div className="dropdown-menu-data">
                  <div
                    className="dropdown-item"
                    onClick={() => {
                      setSortValue("amount low to high");
                      setAmountDropdownOpen(false);
                    }}
                  >
                    Low To High
                  </div>
                  <div
                    className="dropdown-item"
                    onClick={() => {
                      setSortValue("amount high to low");
                      setAmountDropdownOpen(false);
                    }}
                  >
                    High To Low
                  </div>
                </div>
              )}
            </div>
            <div className="position-relative" ref={dateDropdownRef}>
              <button
                className="d-flex order-dropdown-button"
                onClick={() => {
                  setDateDropdownOpen(!dateDropdownOpen);
                  setStatusDropdownOpen(false);
                  setAmountDropdownOpen(false);
                }}
              >
                Date
                {dateDropdownOpen ? (
                  <IoMdArrowDropup size={24} />
                ) : (
                  <IoMdArrowDropdown size={24} />
                )}
              </button>
              {dateDropdownOpen && (
                <div className="dropdown-menu-data">
                  <div
                    className="dropdown-item"
                    onClick={() => {
                      setSortValue("date Latest to oldest");
                      setDateDropdownOpen(false);
                    }}
                  >
                    Latest To Oldest
                  </div>
                  <div
                    className="dropdown-item"
                    onClick={() => {
                      setSortValue("date oldest to latest");
                      setDateDropdownOpen(false);
                    }}
                  >
                    Oldest To Latest
                  </div>
                </div>
              )}
            </div>
          </div> */}
        </div>

        {/* <div className="d-flex gap-2 justify-content-end ">
          {sortValue && (
            <div className="d-flex justify-content-end mt-3">
              <div className="d-flex gap-2 align-items-center">
                <p className="m-0 order-list-text">Sort By :</p>
                <div className="sort-clear-div ">
                  <p className="m-0 order-list-text text-light text-capitalize p-0">
                    {sortValue}
                  </p>
                  <button
                    className="sort-clear-btn"
                    onClick={() => setSortValue("")}
                  >
                    <IoCloseOutline color="white" size={20} />
                  </button>
                </div>
              </div>
            </div>
          )}

          {statusValue && (
            <div className="d-flex justify-content-end mt-3">
              <div className="d-flex gap-2 align-items-center">
                <p className="m-0 order-list-text">Status:</p>
                <div className="sort-clear-div ">
                  <p className="m-0 order-list-text text-light text-capitalize p-0">
                    {statusValue}
                  </p>
                  <button
                    className="sort-clear-btn"
                    onClick={() => setStatusValue("")}
                  >
                    <IoCloseOutline color="white" size={20} />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div> */}

        <div className="py-5 position-relative">
          <button className="export-to-excel-btn" onClick={onBtExport}>
            Export to Excel
          </button>
          <div
            className="ag-theme-alpine"
            style={{ height: 520, width: "100%" }}>
            <AgGridReact
              ref={gridRef}
              onGridReady={onGridReady}
              columnDefs={columnDefs}
              rowData={SponsorEventList?.data}
              defaultColDef={defaultColDef}
              rowSelection="multiple"
            ></AgGridReact>
          </div>
        </div>
        {/* <div className="pt-5">
          <div className="order-head-div ">
            <div className="row d-flex align-items-center">
              <div className="col-2">
                <p className="order-head-text">Transaction Id</p>
              </div>

              <div className="col-2">
                <p className="order-head-text">Event /</p>
                <p className="order-head-text">Package Name</p>
              </div>

              <div className="col-1">
                <p className="order-head-text">Package Amount</p>
              </div>

              <div className="col-2">
                <p className="order-head-text">Coupon Code /</p>
                <p className="order-head-text">Coupon Discount </p>
              </div>

              <div className="col-2">
                <p className="order-head-text">Payable Amount</p>
              </div>

              <div className="col-1">
                <p className="order-head-text">Status</p>
              </div>

              <div className="col-2">
                <p className="order-head-text">Date</p>
              </div>
            </div>
          </div>

          {loading ? <Loader2 /> :
            <div className="order-list-main-div">
              {SponsorEventList?.data?.map((val) => {
                return (
                  <div className=" order-list-div">
                    <div className="row d-flex align-items-center">
                      <div className="col-md-2">
                        <p className="order-list-text fw-bold">
                          {val?.transaction_id ? val?.transaction_id : "-"}
                        </p>
                      </div>
                      <div className="col-md-2">
                        <Link to={`/eventdetail/${val?.event_id}`}
                          state={{ type: "Events" }}>
                          <p className="order-list-text fw-bold">
                            {val?.event?.event_title}
                          </p>
                        </Link>

                        <p className="order-list-text">{val?.package?.title}</p>
                      </div>

                      <div className="col-md-1">
                        <p className="order-list-text">
                          {currency?.selectedCurrencyPrice
                            ? `${currency?.currencySymbol
                              ? currency?.currencySymbol
                              : currencyIcn
                            } ${(
                              val?.package?.amount *
                              currency?.selectedCurrencyPrice
                            ).toFixed(2)} `
                            : `${currencyIcn} ${val?.package?.amount} `}
                        </p>
                      </div>

                      <div className="col-md-2">
                        <p className="order-list-text">
                          {val?.coupons == null ? (
                            <p className="order-data-p pt-0">No Coupon</p>
                          ) : (
                            val?.coupons?.coupon_code
                          )}
                        </p>
                        <p className="order-list-text">
                          {val?.discount
                            ? `${val?.discount}${val?.type == "Percentage" ? "%" : ""
                            }`
                            : "-"}
                        </p>
                      </div>

                      <div className="col-md-2">
                        <p className="order-list-text">
                          {currency?.selectedCurrencyPrice
                            ? `${currency?.currencySymbol
                              ? currency?.currencySymbol
                              : currencyIcn
                            } ${(
                              val?.amount * currency?.selectedCurrencyPrice
                            ).toFixed(2)} `
                            : `${currencyIcn} ${val?.amount?.toFixed(2)} `}
                        </p>
                      </div>

                      <div className="col-md-1">
                        <p
                          className={
                            val?.payment_status == "success"
                              ? "success-status-text text-capitalize p-0"
                              : val?.payment_status == "failed"
                                ? "failed-status-text text-capitalize p-0"
                                : "pending-status-text text-capitalize p-0"
                          }
                        >
                          {val?.payment_status}
                        </p>
                      </div>

                      <div className="col-md-2">
                        <p className="order-list-text">
                          {moment(val?.created_at).format("ll hh:mm A")}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          }
        </div> */}
      </div>

      {SponsorEventList?.data?.length < SponsorEventList?.total && (
        <div className="d-flex align-items-end pagination-container mt-1 pb-2">
          <ReactPaginate
            pageCount={Math.ceil(
              SponsorEventList?.total / SponsorEventList?.per_page
            )}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            onPageChange={handlePageClick}
            containerClassName="pagination"
            activeClassName="selected"
            disabledClassName="disabled"
          />
        </div>
      )}
    </>
  );
};

export default SponsorEventList;
