import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import { Signup_GenerateNumberOtp } from "../../utils/Otpverify/OtpVerify";
import { toast,ToastContainer} from "react-toastify";
import { Mobile_ForgotPassword } from "../../utils/user/UserManager";

const ForgotWithMobile = ({mobileForm,setPhoneOtp, setMobileForm,setOtpSection}) => {
  const [errors, setErrors] = useState({});
  const [btnLoading,setBtnLoading]=useState(false)

  const onLoginWithMobile = async(e) => {
    e.preventDefault();
    if (validateForm()) {
        const data = {
          phone: mobileForm?.mobilenumber,
          country_code: mobileForm?.dialingcode,
        };
        setBtnLoading(true)
        try {
          await Mobile_ForgotPassword(data).then((response) => {
            if (response.data.success){
              toast.success(response?.data?.message,{
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                theme: "light",
              });
              setOtpSection(true);
              setPhoneOtp(response?.data?.otp)
            }
          })} catch (err) {
          toast.error(err?.response?.data?.error, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            theme: "light",
          });
        } finally {
          setBtnLoading(false)
        }
    }else{
      console.log("error")
    }
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {
      dialingCode: '',
      mobileNumber: '',
    };

    if (!mobileForm.dialingcode) {
      newErrors.dialingCode = 'Dialing code is required';
      valid = false;
    } else if (isNaN(mobileForm.dialingcode)) {
      newErrors.dialingCode = 'Invalid dialing code, should contain only numbers';
      valid = false;
    }

    if (!mobileForm.mobilenumber) {
      newErrors.mobileNumber = 'Mobile number is required';
      valid = false;
    } else if (isNaN(mobileForm.mobilenumber)) {
      newErrors.mobileNumber = 'Invalid mobile number, should contain only numbers';
      valid = false;
    } else if (mobileForm.mobilenumber.toString().length < 10) {
      newErrors.mobileNumber = 'Mobile number should be at least 10 digits';
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleOnChange = (value, country) => {
    const phoneNum = value?.slice(2);
    const d_code = `${country.format.charAt(0)}${country?.dialCode}`;
    setMobileForm({
      dialingcode: d_code,
      mobilenumber: parseInt(phoneNum),
    });
  };
  return (
    <>
      <form className="m-0 p-0" onSubmit={onLoginWithMobile}>
        <>
          <div className=" form-group">
            <label className="login-label">Mobile Number <span className="asterisk">*</span></label> 
            <PhoneInput className="" country={"in"} onChange={handleOnChange} />
           
            {errors?.mobileNumber && (
              <span className="mt-1" style={{ color: "red", fontSize: "13px" }}>
                {errors?.mobileNumber}
              </span>
            )}
          </div>
        </>
        <div className="d-flex flex-column">
          <button type="submit" className="btn forgotpassword-btn mt-2">
            {
              btnLoading &&
              <div className="spinner-border  text-light" role="status">
              <span className="sr-only">Loading...</span>
              </div>
            }
            Submit
          </button>
          <Link to="/login" className="forgot-text mt-4">
            Back To Login
          </Link>
        </div>
      </form>
    </>
  );
};

export default ForgotWithMobile;
