import { GetProfessions, GetUserAvatar, ProfileUpdateDataList, getPredefineProfile, getProfileEditDataList } from "../../Services/Apis"

export const get_predefine_list = async () => {
    try {
        return await getPredefineProfile().then((data) => data)
    } catch (err) {
        throw err
    }
}

export const get_profile_edit_data = async (id) => {
    try {
        return await getProfileEditDataList(id).then((data) => data)
    } catch (err) {
        throw err
    }
}

export const update_prodile_data = async (value, id) => {
    try {
        return await ProfileUpdateDataList(value, id).then((data) => data)
    } catch (err) {
        throw err
    }
}

export const Get_UserAvatar = async () => {
    try {
        return await GetUserAvatar().then((data) => data)
    } catch (err) {
        throw err
    }
}

export const Get_Professions = async () => {
    try {
        return await GetProfessions().then((data) => data)
    } catch (err) {
        throw err
    }
}
