import { useEffect, useState } from "react";
import { IoMdSend } from "react-icons/io";
import { IoClose, IoWarningOutline } from "react-icons/io5";
import PhoneInput from "react-phone-input-2";
import {
  Update_Phone,
  Verify_Change_Phone,
} from "../../utils/profile/UpdatePhoneNumber";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { UserProfile, logActivityCheck, pass_systemIpAddress, systemIpAddress } from "../../utils/user/UserManager";
import { profileval } from "../../redux/UserProfile/Profile";

const EditPhoneNumberPopup = ({ setPhonePopup, val }) => {
  const [showSendPhoneBtn, setSendPhoneBtn] = useState(false);
  const [phoneOtpSection, setPhoneOtpSection] = useState(false);
  const [phoneVerifyBtn, setPhoneVerifyBtn] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [dialingcode, setDialingcode] = useState("");
  const [otp, setOtp] = useState("");
  const [resPhoneNumber, setResPhoneNumber] = useState("");
  const [sendBtnLoading, setSendBtnLoading] = useState(false);
  const [verifyBtnLoading, setVerifyBtnLoading] = useState(false);
  const [informationIp, setInformationIp] = useState({});
  const [countdown, setCountdown] = useState(0);
  const [reSendOtp, setReSendOtp] = useState(false)

  const dispatch = useDispatch()

  const SystemIp = async () => {
    try {
      await systemIpAddress()
        .then((response) => {
          passIpAddress(response?.data?.ip)
        })
    } catch (err) {
      console.log(err)
    }
  }

  const passIpAddress = async (ipv4) => {
    try {
      await pass_systemIpAddress(ipv4)
        .then((response) => {
          setInformationIp(response?.data)
        })
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    setPhoneNumber(`${val?.country_code}${val?.phone}`);
    SystemIp();
  }, [val]);

  const getProfile = async () => {
    try {
      await UserProfile().then((data) => {
        dispatch(profileval(data?.data?.user));
      });
    } catch (error) {
      console.log("profile", error);
    }
  };

  const handleSendOtpOnMobile = async () => {
    const fdata = {
      phone: phoneNumber?.slice(2),
      id: val?.id,
      country_code: dialingcode,
    };
    try {
      setSendBtnLoading(true);
      const response = await Update_Phone(fdata);
      if (response.data.success) {
        setSendPhoneBtn(false)
        setOtp(response?.data?.otp);
        if (response?.data?.otp > 5) {
          setPhoneVerifyBtn(true);
        } else {
          setPhoneVerifyBtn(false);
        }
        setDialingcode(response?.data?.country_code)
        setResPhoneNumber(response?.data?.phone);
        setPhoneOtpSection(true);
        toast.success(response?.data?.message, {
          position: "top-center",
          autoClose: 3000,
        });

        setReSendOtp(false)
        setCountdown(300); // 5 minutes countdown
        // Start countdown
        const timer = setInterval(() => {
          setCountdown((prevCountdown) => {
            if (prevCountdown <= 1) {
              clearInterval(timer);
              setOtp(null);
              setPhoneVerifyBtn(false);
              setPhoneOtpSection(false);
              setReSendOtp(true)
              toast.info("OTP has expired. Please request a new one.", {
                position: "top-center",
                autoClose: 3000,
              });
              return 0;
            }
            return prevCountdown - 1;
          });
        }, 1000);
      }

    } catch (error) {
      toast.error(error?.response?.data?.error, {
        position: "top-center",
        autoClose: 2000,
      });
    } finally {
      setSendBtnLoading(false);
    }
  };

  const handlePhoneOtp = (e) => {
    const phoneOtp = e.target.value;
    setOtp(phoneOtp);
    if (phoneOtp?.length > 5 && phoneOtp?.length < 7) {
      setPhoneVerifyBtn(true);
    } else {
      setPhoneVerifyBtn(false);
    }
  };

  const handleOnPhoneChange = (value, country) => {
    const phoneValue = value; // Extract the phone property
    const dialingcode = `${country.format.charAt(0)}${country?.dialCode}`;
    const num = val?.phone.split('').slice(1).join('')


    if (phoneValue.length > 10 && num != value) {
      setSendPhoneBtn(true);
    } else {
      setSendPhoneBtn(false);
    }
    setDialingcode(dialingcode);
    setPhoneNumber(phoneValue);
    // setPhoneNumber({
    // dialingcode: `${country.format.charAt(0)}${country?.dialCode}`,
    //   mobilenumber:phoneValue?.slice(2)
    // })
  };

  const loggedActivity = async () => {
    const data = {
      ip_address: informationIp?.ip,
      lat: informationIp?.latitude,
      long: informationIp?.longitude,
      log_type: 'changeNumber'
    }
    try {
      await logActivityCheck(data)
        .then((response) => {
          // console.log(response)
          // if (response?.status == 200) {
          //   localStorage.setItem("LoginToken", loginToken);
          //   navigate("/");
          // }
        })
    } catch (err) {
      console.log(err)
    }
  }

  const handleSubmit = async () => {
    const fData = {
      otp: otp,
      phone: resPhoneNumber,
    };

    try {
      setVerifyBtnLoading(true);
      const response = await Verify_Change_Phone(fData);
      if (response?.data?.success) {
        getProfile()
        toast.success(response?.data?.message, {
          position: "top-center",
          autoClose: 2000,
        });
        loggedActivity()
        setPhonePopup(false);

      } else {

        toast.error(response?.data?.message, {
          position: "top-center",
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.success(error?.response?.data?.error, {
        position: "top-center",
        autoClose: 2000,
      });
    } finally {
      setVerifyBtnLoading(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="warningModal">
        <div className="warning-mdl">
          {/* <span className="warning-close-btn" >&times;</span> */}
          <div className='d-flex justify-content-between'>
            <h4 className="edit-email-heading">Edit Phone Number</h4>
            <button className='mdl-cancel-btn' onClick={() => setPhonePopup(false)}>
              <IoClose size='25' />
            </button>
          </div>
          {/* Phone section */}
          <div className="form-group mb-0">
            <label className="form-label">
              Phone<span className="asterisk">*</span>
            </label>
            <div className="position-relative d-flex align-items-center">

              <PhoneInput
                value={resPhoneNumber ? `${dialingcode}${resPhoneNumber}` : phoneNumber}
                name="phone"
                onChange={handleOnPhoneChange}
                className=""
                placeholder="phone"
              />

              {showSendPhoneBtn && (
                <button
                  className="otp-send-btn"
                  type="button"
                  onClick={handleSendOtpOnMobile}
                  title="Send otp"
                >
                  {sendBtnLoading ? (
                    <div className="spinner-border text-danger" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) :
                    <IoMdSend size={24} color="#FF2D55" />
                  }
                </button>
              )}

              {reSendOtp && (
                <button
                  className="verify-otp"
                  type="button"
                  onClick={handleSendOtpOnMobile}
                >
                  {
                    sendBtnLoading &&
                    <div className="spinner-border text-light" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  }
                  Resend OTP
                </button>
              )}

            </div>
            {phoneOtpSection && (
              <div className="position-relative d-flex align-items-center mt-2">
                <input
                  type="text"
                  value={otp}
                  name="phone_otp"
                  className="form-control"
                  placeholder="Otp"
                  onChange={handlePhoneOtp}
                />
                {phoneVerifyBtn && (
                  <button
                    className="verify-otp"
                    type="button"
                    onClick={handleSubmit}>
                    {
                      verifyBtnLoading &&
                      <div className="spinner-border text-light" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    }
                    Verify
                  </button>
                )}
              </div>
            )}

            {countdown > 0 && (
              <p className="mt-2">OTP expires in: {Math.floor(countdown / 60)}:{countdown % 60 < 10 ? `0${countdown % 60}` : countdown % 60} minutes</p>
            )}
          </div>
          {/* <div className="d-flex gap-2 justify-content-end">
            <button
              className="mdl-cancel-btn mt-2"
              onClick={() => setPhonePopup(false)}
            >
              Cancel
            </button>
            <button
              className="warning-mdl-btn mt-2"
            // onClick={() => setTabValue(tabVal)}
            >
              Ok
            </button>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default EditPhoneNumberPopup;
