import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/sponsor-logo-3.png";
import avatar from "../../assets/images/user.png";
import loca from "../../assets/images/location.png";
import search from "../../assets/images/search.png";
// import { FaStar } from "react-icons/fa6";
// import chaticon from "../../assets/images/chat-icon.svg";
import notifiicon from "../../assets/images/notification-icon.svg";
import {
  UserProfile,
  pass_systemIpAddress,
  systemIpAddress,
  userLogoutApi,
} from "../../utils/user/UserManager";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { profileval } from "../../redux/UserProfile/Profile";
import { HomeEvents } from "../../utils/homeManager/EventsManager";
import { toast, ToastContainer } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import { isTokenValid } from "../../AuthbyToken";
import { CurrencyCon } from "../../redux/CurrencyConvert/CurrencyConvert";
import { MdVerified } from "react-icons/md";
import facebook from "../../assets/images/facebook.svg";
import linkedin from "../../assets/images/linkedin.svg";
import youtube from "../../assets/images/youtube.svg";
import instagram from "../../assets/images/insta.png";
import twitter from "../../assets/images/ic_twitter.svg";
import {
  isMobile,
  isTablet,
  isDesktop,
  isAndroid,
  isIOS,
} from 'react-device-detect';

const Navigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, isAuthenticated, isLoading, logout } = useAuth0();
  const isValidToken = isTokenValid();

  const [collapsed, setCollapsed] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [homenav, setHomenav] = useState(true);
  const [avatarDropdown, setAvatarDropdown] = useState(false);
  const [query, setQuery] = useState(localStorage.getItem("my_location") || "");
  const [predictions, setPredictions] = useState([]);
  const [informationIp, setInformationIp] = useState({});
  const [links, setLinks] = useState({
    linkedin: "",
    twitter: "",
    instagram: "",
    google: "",
    facebook: "",
    pinterest: "",
  });

  const deviceType = {
    type: isMobile ? "Mobile" : isTablet ? "Tablet" : isDesktop ? "Desktop" : "Unknown",
    os: isAndroid ? "Android" : isIOS ? "iOS" : "Other"
  };

  const debounceTimer = useRef(null);
  const allConfingData = useSelector((state) => state?.config_data?.configData);
  const profileinfo = useSelector((data) => data?.Profile_data?.ProfileData);

  const [selectedCurrency, setSelectedCurrency] = useState("INR");
  const [showLangDrop, setShowLangDrop] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    allConfingData?.social_media_links?.forEach(item => {
      switch (item.name) {
        case 'linkedin':
          setLinks(prevState => ({
            ...prevState,
            linkedin: item.link
          }));
          break;
        case 'twitter':
          setLinks(prevState => ({
            ...prevState,
            twitter: item.link
          }));
          break;
        case 'instagram':
          setLinks(prevState => ({
            ...prevState,
            instagram: item.link
          }));
          break;
        case 'google-plus':
          setLinks(prevState => ({
            ...prevState,
            google: item.link
          }));
          break;
        case 'facebook':
          setLinks(prevState => ({
            ...prevState,
            facebook: item.link
          }));
          break;
        case 'pinterest':
          setLinks(prevState => ({
            ...prevState,
            pinterest: item.link
          }));
          break;
        default:
          break;
      }
    });
  }, [allConfingData]);

  // close dropdown on outside click
  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !event.target.closest(".dropdown-link-outside")
    ) {
      setAvatarDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, []);

  const getProgile = async () => {
    try {
      await UserProfile().then((data) => {
        if (data?.status == 200) {
          localStorage.setItem(
            "LiveSample",
            data?.data?.user?.create_sample_events
          );
          dispatch(profileval(data?.data?.user));
        } else {
          localStorage.removeItem("LoginToken");
          window.location.reload();
        }
      });
    } catch (error) {
      console.log("profile", error);
    }
  };

  useEffect(() => {
    if (isValidToken) {
      getProgile();
      setShowLangDrop(true);
    } else {
      setShowLangDrop(true);
    }
  }, [isValidToken]);

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      { pageLanguage: "en" },
      "google_translate_element"
    );
    const checkExist = setInterval(() => {
      const googleDiv = document.querySelector(
        "#google_translate_element .skiptranslate"
      );
      const googleDivChild = document.querySelector(
        "#google_translate_element .skiptranslate div"
      );
      if (googleDiv && googleDivChild) {
        googleDivChild.nextElementSibling?.remove();
        Array.from(googleDiv.childNodes).forEach((node) => {
          if (
            node.nodeType === Node.TEXT_NODE &&
            node.textContent.trim() !== ""
          ) {
            node.remove();
          }
        });
        clearInterval(checkExist);
      }
    }, 1000);
    setTimeout(() => clearInterval(checkExist), 10000);
  };

  // const googleTranslateElementInit = () => {
  //   new window.google.translate.TranslateElement(
  //     { pageLanguage: "eng" },
  //     "google_translate_element"
  //   );

  //   const checkExist = setInterval(() => {
  //     const googleDiv = document.querySelector(
  //       "#google_translate_element .skiptranslate"
  //     );
  //     const googleDivChild = document.querySelector(
  //       "#google_translate_element .skiptranslate div"
  //     );
  //     if (googleDiv && googleDivChild) {
  //       googleDivChild.nextElementSibling?.remove();
  //       Array.from(googleDiv.childNodes).forEach((node) => {
  //         if (
  //           node.nodeType === Node.TEXT_NODE &&
  //           node.textContent.trim() !== ""
  //         ) {
  //           node.remove();
  //         }
  //       });

  //       setTimeout(() => {
  //         const selectElement = document.querySelector(
  //           "#google_translate_element .skiptranslate select"
  //         );
  //         if (selectElement) {
  //           selectElement.value = "en";
  //           selectElement.dispatchEvent(new Event("change"));
  //         }
  //       }, 500);

  //       clearInterval(checkExist);
  //     }
  //   }, 100);
  //   setTimeout(() => clearInterval(checkExist), 10000);
  // };

  useEffect(() => {
    googleTranslateElementInit();
  }, []);

  const TimeOutLogOut = async (informationIpAdd) => {
    // const expireTime = localStorage.getItem("expireTime");
    const expireTime = parseInt(localStorage.getItem("expireTime"), 10);
    if (expireTime < Date.now()) {
      if (isValidToken) {
        const data = {
          ip_address: informationIpAdd?.ip,
          lat: informationIpAdd?.latitude,
          long: informationIpAdd?.longitude,
          log_type: "logout",
        };

        try {
          await userLogoutApi(data).then((response) => {
            if (response?.data?.success == true) {
              localStorage.removeItem("LoginToken");
              setAvatarDropdown(false);
              window.location.reload();
              toast.warn("You will be automatically logged out due to inactivity.", {
                position: "top-center",
                autoClose: 3000,
              });
            }
            if (isAuthenticated) {
              logout();
              localStorage.removeItem("LoginToken");
            }
          });
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const TimeOutpassIpAddress = async (ipv4) => {
    try {
      await pass_systemIpAddress(ipv4).then((response) => {
        // setInformationIp(response?.data)
        TimeOutLogOut(response?.data);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const TimeOutSystemIp = async () => {
    try {
      await systemIpAddress().then((response) => {
        TimeOutpassIpAddress(response?.data?.ip);
      });
    } catch (err) {
      console.log(err);
    }
  };

  //start auto logout --------------------------------
  const checkForInactivity = async () => {
    await TimeOutSystemIp();
  };

  const updateExpireTime = () => {
    const expireTime = Date.now() + 30 * 60 * 1000;
    localStorage.setItem("expireTime", expireTime);
  };

  useEffect(() => {
    const interVal = setInterval(() => {
      checkForInactivity();
    }, 30 * 60 * 1000);
    return () => clearInterval(interVal);
  }, []);

  useEffect(() => {
    updateExpireTime();
    window.addEventListener("click", updateExpireTime);
    window.addEventListener("keypress", updateExpireTime);
    window.addEventListener("scroll", updateExpireTime);
    window.addEventListener("mousemove", updateExpireTime);

    return () => {
      window.removeEventListener("click", updateExpireTime);
      window.removeEventListener("keypress", updateExpireTime);
      window.removeEventListener("scroll", updateExpireTime);
      window.removeEventListener("mousemove", updateExpireTime);
    };
  }, []);
  // end auto logout ----------------------------------------

  const passIpAddress = async (ipv4) => {
    await pass_systemIpAddress(ipv4).then((response) => {
      setInformationIp(response?.data);
    });
  };

  const SystemIp = async () => {
    try {
      await systemIpAddress().then((response) => {
        passIpAddress(response?.data?.ip);
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    SystemIp();
  }, []);

  const handleLogout = async () => {
    const data = {
      ip_address: informationIp?.ip,
      lat: informationIp?.latitude,
      long: informationIp?.longitude,
      log_type: "logout",
      device_type: JSON.stringify(deviceType),
      city: informationIp?.city,
      country_code: informationIp?.country_code,
      region_code: informationIp?.region_code
    };
    try {
      await userLogoutApi(data).then((response) => {
        if (response?.data?.success == true) {
          localStorage.removeItem("LoginToken");
          setAvatarDropdown(false);
          // logout();
          window.location.reload();
          navigate('/')
          toast.success(response?.data?.message, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            theme: "light",
          });
          if (isAuthenticated) {
            logout();
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    const handleScroll = () => {
      const elementId = document.getElementById("navbar");
      if (window.scrollY > 170) {
        elementId.classList.add("is-sticky");
        // window.history.pushState("", document.title, window.location.pathname);
      } else {
        elementId.classList.remove("is-sticky");
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (location.pathname == "/") {
      setHomenav(true);
    } else {
      setHomenav(false);
    }
  }, [location.pathname]);

  const toggleOpen = () => setIsOpen(!isOpen);

  //   const onRouteChanged = () => {
  //     setIsOpen(!isOpen);
  //   };

  const classOne = collapsed
    ? "collapse navbar-collapse"
    : "collapse navbar-collapse show mobile-sidemenu";
  const classTwo = collapsed
    ? "navbar-toggler navbar-toggler-right collapsed"
    : "navbar-toggler navbar-toggler-right";
  //   const menuClass = `dropdown-menu${isOpen ? " show" : ""}`;

  // By default addess name code start -----------------------------------------------------------------------------------------

  // const getLocation = (type) => {
  //   if ("geolocation" in navigator) {
  //     navigator.geolocation.getCurrentPosition(
  //       async (position) => {
  //         const { latitude, longitude } = position.coords;
  //         const let_long = {
  //           lat: latitude,
  //           lng: longitude,
  //         };
  //         localStorage.setItem("let_long", JSON.stringify(let_long));
  //         try {
  //           const apiKey = "AIzaSyA3BYK_Siw8ebziSskitaA5YMjbvcO6n4U";
  //           const response = await axios.get(
  //             `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
  //           );
  //           const address = response.data.results[0].formatted_address;
  //           localStorage.setItem("my_location", address);
  //           setQuery(address);
  //         } catch (error) {
  //           console.error("Error getting location name:", error.message);
  //         } finally {
  //           if (type == "detect_location") {
  //             window.location.reload();
  //           }
  //         }
  //       },
  //       (error) => {
  //         toast.error(error.message, {
  //           position: "top-center",
  //           autoClose: 3000,
  //           hideProgressBar: false,
  //           theme: "light",
  //         });
  //       }
  //     );
  //   } else {
  //     console.error("Geolocation is not supported by your browser");
  //   }
  // };

  // useEffect(() => {
  //   const storedLocation = localStorage.getItem("let_long");
  //   if (storedLocation) {
  //     return;
  //   }
  //   getLocation();
  // }, []);

  // const handleLocation = () => {
  //   getLocation("detect_location");
  // };

  const getLocation = (type) => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          const let_long = {
            lat: latitude,
            lng: longitude,
          };
          localStorage.setItem("let_long", JSON.stringify(let_long));
          try {
            const apiKey = "AIzaSyA3BYK_Siw8ebziSskitaA5YMjbvcO6n4U";
            const response = await axios.get(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
            );
            const address = response?.data?.results[0].formatted_address;
            localStorage.setItem("my_location", address);
            setQuery(address);
          } catch (error) {
            console.error("Error getting location name:", error.message);
          } finally {
            if (type === "detect_location") {
              window.location.reload();
            }
          }
        },
        (error) => {
          toast.warn('Kindly enable location sharing to experience personalized experience.', {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            theme: "light",
          });
          setDefaultLocation();
        }
      );
    } else {
      console.error("Geolocation is not supported by your browser");
      setDefaultLocation();
    }
  };

  const setDefaultLocation = async () => {
    const defaultLocation = {
      lat: 18.9219841,
      lng: 72.8346543,
      // lat: 18.9273782,
      // lng: 72.8402478,
    };
    localStorage.setItem("let_long", JSON.stringify(defaultLocation));
    try {
      const apiKey = "AIzaSyA3BYK_Siw8ebziSskitaA5YMjbvcO6n4U";
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${defaultLocation.lat},${defaultLocation.lng}&key=${apiKey}`
      );
      const address = response.data.results[0].formatted_address;
      localStorage.setItem("my_location", address);
      setQuery(address);
    } catch (error) {
      console.error("Error setting default location name:", error.message);
    }
  };

  useEffect(() => {
    const storedLocation = localStorage.getItem("let_long");
    if (storedLocation) {
      return;
    }
    getLocation();
  }, []);

  const handleLocation = () => {
    getLocation("detect_location");
  };

  // By default addess name code end --------------------

  // place api code start ----------------
  const getPlacePredictions = (input) => {
    if (window.google) {
      const service = new window.google.maps.places.AutocompleteService();
      service.getPlacePredictions({ input }, (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setPredictions(predictions);
        }
      });
    }
  };

  const getPlaceDetails = (placeId) => {
    if (window.google) {
      const service = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );

      service.getDetails({ placeId }, (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const lat_long = place.geometry.location.toJSON();
          localStorage.setItem("let_long", JSON.stringify(lat_long));
          HomeEvents(lat_long);
        }
      });
    }
  };

  useEffect(() => {
    const API_KEY = "AIzaSyA3BYK_Siw8ebziSskitaA5YMjbvcO6n4U";

    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places`;
    script.async = true;

    const onScriptLoad = () => { };

    script.addEventListener("load", onScriptLoad);
    document.body.appendChild(script);

    return () => {
      script.removeEventListener("load", onScriptLoad);
      document.body.removeChild(script);
    };
  }, []);

  const debounce = (func, delay) => {
    clearTimeout(debounceTimer.current);
    debounceTimer.current = setTimeout(func, delay);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setQuery(value);
    debounce(() => {
      if (value) {
        getPlacePredictions(value);
      } else {
        setPredictions([]);
      }
    }, 300);
  };

  const handleSelectPrediction = (prediction) => {
    localStorage.setItem("my_location", prediction?.description);
    setQuery(prediction?.description);
    setPredictions([]);
    getPlaceDetails(prediction?.place_id);

    if (location.pathname !== "/") {
      navigate("/");
    } else {
      window.location.reload();
    }
  };

  // useEffect(() => {
  //   // Function to fetch data based on the selected currency
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch(`https://v6.exchangerate-api.com/v6/00607bafc419ee07d1af0fe1/latest/${selectedCurrency}`);
  //       const data = await response.json();
  //       setApiData(data);

  // const cnvrt = apiData?.conversion_rates[selectedCurrency];
  // console.log("ccnnv",cnvrt)
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };
  //   fetchData();
  // }, [selectedCurrency]);

  // console.log("aaa",apiData.conversion_rates[selectedCurrency])

  // place api code end ---------------

  // currency

  const ChangeCurrency = async (currency) => {
    let currencyIcn;
    if (currency == "INR") {
      currencyIcn = "₹";
    } else if (currency == "USD") {
      currencyIcn = "$";
    } else if (currency == "EUR") {
      currencyIcn = "€";
    } else if (currency == "GBP") {
      currencyIcn = "£";
    }

    try {
      const response = await fetch(
        `https://v6.exchangerate-api.com/v6/00607bafc419ee07d1af0fe1/latest/INR`
        // `https://api.exchangerate-api.com/v4/latest/INR`
      );
      const data = await response.json();
      const priceInSelectedCurrency = data?.conversion_rates[currency];
      const newprice = priceInSelectedCurrency + priceInSelectedCurrency * 0.05;

      const currData = {
        currencySymbol: currencyIcn,
        selectedCurrencyPrice:
          currency == "INR" ? priceInSelectedCurrency : newprice,
      };
      dispatch(CurrencyCon(currData));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleChangeCurrency = async (e) => {
    const currency = e.target.value;
    setSelectedCurrency(currency);
    ChangeCurrency(currency);
  };

  const handleImageError = (event) => {
    event.target.src = logo;
    event.target.onError = null;
  };

  const userAvatarError = (event) => {
    event.target.src = avatar;
    event.target.onError = null;
  };

  return (
    <>
      <ToastContainer />
      <header id="header" className="header-area">
        <div
          id="navbar"
          className={`${homenav ? "elkevent-home-nav" : "elkevent-nav"} `}
        >
          <nav className="navbar navbar-expand-lg navbar-light">
            <div className="container-fluid">
              {/* logo */}

              <div className="d-flex align-items-center">
                {location?.pathname == "/" ? (
                  <Link
                    className="nav-logo"
                    onClick={() => window.location.reload(false)}>
                    <img
                      className="logo-img"
                      src={`${allConfingData?.base_urls?.company_logo_url}/${allConfingData?.company_logo}`}
                      onError={handleImageError}
                      alt="logo"
                    />
                  </Link>
                ) : (
                  <Link className="nav-logo" to="/">
                    <img
                      className="logo-img"
                      src={`${allConfingData?.base_urls?.company_logo_url}/${allConfingData?.company_logo}`}
                      onError={handleImageError}
                      alt="logo"
                    />
                  </Link>
                )}

                <div className="input-group search-input-maindiv d-none d-sm-flex rounded ">
                  <form className="">
                    <div className="main-input">
                      <button
                        className="get-location-btn"
                        type="button"
                        title="Detect Location"
                        onClick={handleLocation}>
                        <img className="search-img" src={loca} alt="img" />
                      </button>
                      <input
                        type="search"
                        className="search-input"
                        placeholder="Search Location"
                        value={query ? query : ""}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="srch-location">
                      <ul className="srch-ul">
                        {predictions?.map((prediction) => (
                          <li
                            className="srch-li"
                            key={prediction?.place_id}
                            onClick={() => handleSelectPrediction(prediction)}
                          >
                            {prediction?.description}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </form>
                </div>
              </div>

              <div className={`${classOne} pt-4 pt-lg-0`}>
                <ul className="ul-list d-flex flex-column flex-lg-row align-items-lg-center mb-0">
                  <li className="nav-item">
                    <Link exact="true" to="/howitworks" className="nav-btn" onClick={() => setCollapsed(true)}>
                      {/* How it Works */}
                      Getting Started
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link to="/events/all" className="nav-btn" onClick={() => setCollapsed(true)}>
                      {/* Discover Events */}
                      Events Explorer
                    </Link>
                  </li>

                  <li>
                    <select
                      className="currency-select-button"
                      id="locationSelect"
                      aria-label="language"
                      onChange={handleChangeCurrency}
                      value={selectedCurrency}
                    >
                      {allConfingData?.currency_list?.map((val, index) => {
                        return (
                          <option key={val?.id} value={val?.code}>
                            {val?.symbol} {val?.code}
                          </option>
                        );
                      })}
                    </select>
                  </li>

                  <div>
                    <ul className="nav-social-links d-md-none d-flex gap-2">
                      {/* <p className="m-0"> Follow us on</p> */}
                      <Link to={links?.instagram}>
                        <li>
                          <img
                            className="social-icon"
                            src={instagram}
                            alt="instagram"
                          />
                        </li>
                      </Link>
                      <Link to={links?.facebook}>
                        <li>
                          <img
                            className="social-icon"
                            src={facebook}
                            alt="facebook"
                          />
                        </li>
                      </Link>

                      <Link to={links?.linkedin}>
                        <li>
                          <img
                            className="social-icon"
                            src={linkedin}
                            alt="linkedin"
                          />
                        </li>
                      </Link>
                      <Link to={links?.twitter}>
                        <li>
                          <img
                            className="social-icon"
                            src={twitter}
                            alt="twitter"
                          />
                        </li>
                      </Link>
                    </ul>
                  </div>

                  <li className="googleT">
                    <div id="google_translate_element"></div>
                  </li>
                </ul>
              </div>

              {/* ========================================== */}
              {/* mobile screen  */}
              {/* ============================================= */}
              <div className="d-flex d-lg-none align-items-center">
                <div className="d-flex">
                  {isValidToken || isAuthenticated ? (
                    <div className="d-block me-2">
                      <ul className="navbar-nav ms-auto">
                        <li className="nav-item dropdown position-relative">
                          <button
                            className=" nav-link dropdown-toggle text-white"
                            onClick={() => setAvatarDropdown(!avatarDropdown)}
                          >
                            <img
                              className="avatar-toggle-btn rounded-circle"
                              src={profileinfo?.image_type == "browse"
                                ? `${allConfingData?.base_urls?.customer_image_url}/${profileinfo?.image}`
                                : `${allConfingData?.base_urls?.avatar_image_url}/${profileinfo?.predefine_image}`}
                              alt="User Avatar"
                              width="40"
                              height="40"
                              onError={userAvatarError}
                            />
                          </button>
                          {avatarDropdown ? (
                            Object?.keys(profileinfo)?.length == 0 ? (
                              ""
                            ) : (
                              <ul
                                className="d-flex flex-column avatar-dropdown-menu"
                                aria-labelledby="navbarDropdown"
                              >
                                <li>
                                  <div className="d-flex align-items-center justify-content-center user_info">
                                    <div className="">
                                      <p className="user_name_text">
                                        {`${profileinfo?.f_name} ${profileinfo?.l_name}`}
                                        {profileinfo?.is_prime_user ? (
                                          <MdVerified
                                            size={18}
                                            color="#17c3ff"
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <Link
                                    to="/profile"
                                    className="avatar-dropdown-item dropdown-link-outside"
                                    onClick={() => setAvatarDropdown(false)}>
                                    My Profile
                                  </Link>
                                </li>

                                {profileinfo?.user_type ? (
                                  <li>
                                    <Link
                                      to="/myevents/all"
                                      onClick={() => setAvatarDropdown(false)}
                                      className="avatar-dropdown-item dropdown-link-outside">
                                      Manage Events
                                    </Link>
                                  </li>
                                ) : (
                                  <li>
                                    <Link
                                      to="/myevents/all"
                                      onClick={() => setAvatarDropdown(false)}
                                      className="avatar-dropdown-item dropdown-link-outside">
                                      {profileinfo?.i_want_to == "is_host" ? "My Events" : "My Sponsored"}
                                    </Link>
                                  </li>
                                )}

                                {profileinfo?.user_type ? (
                                  ""
                                ) : (<>
                                  {profileinfo?.i_want_to == "is_host" &&
                                    <li>
                                      <Link to="/mypastevents/all"
                                        className="Upgrade-btn avatar-dropdown-item dropdown-link-outside"
                                        onClick={() => setAvatarDropdown(false)}>
                                        My Past Events
                                      </Link>
                                    </li>
                                  }
                                </>)}

                                {profileinfo?.user_type ? (
                                  ""
                                ) : (
                                  profileinfo?.i_want_to == "is_sponsor" && (
                                    <li>
                                      <Link
                                        to="/myorders"
                                        onClick={() => setAvatarDropdown(false)}
                                        className="avatar-dropdown-item dropdown-link-outside"
                                      >
                                        My Sponsorings
                                      </Link>
                                    </li>
                                  )
                                )}
                                {profileinfo?.user_type ? (
                                  ""
                                ) : (
                                  profileinfo?.i_want_to == "is_host" && (
                                    <li>
                                      <Link
                                        to="/myeventransaction"
                                        onClick={() => setAvatarDropdown(false)}
                                        className="avatar-dropdown-item dropdown-link-outside"
                                      >
                                        Transactions
                                      </Link>
                                    </li>
                                  )
                                )}
                                <li>
                                  <Link
                                    to="/managerequest"
                                    onClick={() => setAvatarDropdown(false)}
                                    className="avatar-dropdown-item dropdown-link-outside"
                                  >
                                    Manage Request
                                  </Link>
                                </li>
                                <li>
                                  {/* <button className="Upgrade-btn avatar-dropdown-item"> */}
                                  <Link
                                    to="/membership"
                                    onClick={() => setAvatarDropdown(false)}
                                    className="avatar-dropdown-item dropdown-link-outside"
                                  >
                                    {/* <FaStar className="me-2" size={18} /> */}
                                    Membership
                                  </Link>
                                </li>

                                {/* {profileinfo?.user_type ? (
                                  ""
                                ) : ( */}
                                <li>
                                  <Link
                                    to="/testimonial"
                                    className="avatar-dropdown-item dropdown-link-outside"
                                    onClick={() => setAvatarDropdown(false)}>
                                    Testimonial
                                  </Link>
                                </li>
                                {/* )} */}

                                {/* </button> */}
                                <li>
                                  <Link
                                    to="/chat"
                                    className="avatar-dropdown-item dropdown-link-outside"
                                    onClick={() => setAvatarDropdown(false)}>
                                    Chat Support
                                    {/* <img className="" src={chaticon} alt="chaticon" /> */}
                                  </Link>
                                </li>

                                <li>
                                  <Link
                                    to="/notification"
                                    className="avatar-dropdown-item dropdown-link-outside"
                                    onClick={() => setAvatarDropdown(false)}>
                                    Notification
                                    {/* <img className="nav-btn " src={notifiicon} alt="chaticon" /> */}
                                  </Link>
                                </li>
                                {/* <li><hr className="dropdown-divider"/></li> */}
                                <li>
                                  {/* <button className="Upgrade-btn avatar-dropdown-item"> */}
                                  <Link
                                    to="/setting"
                                    onClick={() => setAvatarDropdown(false)}
                                    className="avatar-dropdown-item dropdown-link-outside">
                                    {/* <FaStar className="me-2" size={18} /> */}
                                    Settings
                                  </Link>
                                </li>
                                <li>
                                  <button
                                    className="avatar-dropdown-item dropdown-link-outside"
                                    onClick={() => handleLogout()}
                                  >
                                    Logout
                                  </button>
                                </li>
                              </ul>
                            )
                          ) : (
                            ""
                          )}
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <div className="others-option d-lg-none me-3 mt-0">
                      <div className="login-signup-div gap-2">
                        <div>
                          <Link
                            to="/login"
                            className="signin-btn "
                          // onClick={toggleNavbar}
                          >
                            Sign in
                          </Link>
                        </div>

                        {/* <div>
                       <Link
                        to="/signup"
                        className="signup-btn"
                        onClick={toggleNavbar}
                      >
                        Sign p
                      </Link>
                    </div> */}
                      </div>
                    </div>
                  )}

                  {/* togglebutton */}
                  <button
                    onClick={toggleNavbar}
                    className={classTwo}
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                </div>
              </div>

              <div className="input-group search-input-maindiv d-flex m-auto d-sm-none rounded">
                <form className="">
                  <div className="main-input">
                    <button
                      className="get-location-btn"
                      type="button"
                      title="Detect Location"
                      onClick={handleLocation}
                    >
                      <img className="search-img" src={loca} alt="img" />
                    </button>
                    <input
                      type="search"
                      className="search-input"
                      placeholder="Search Location"
                      value={query ? query : ""}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="srch-location">
                    <ul className="srch-ul">
                      {predictions?.map((prediction) => (
                        <li
                          className="srch-li"
                          key={prediction?.place_id}
                          onClick={() => handleSelectPrediction(prediction)}
                        >
                          {prediction?.description}
                        </li>
                      ))}
                    </ul>
                  </div>
                </form>
              </div>
              {/* <div className="input-group search-input-maindiv d-flex m-auto d-sm-none rounded ">
                <form className="">
                  <div className="main-input">
                    <button className="get-location-btn" type="button" title="Detect Location" onClick={handleLocation}>
                      <img className="search-img" src={loca} alt="img" /></button>
                    <input
                      type="search"
                      className="search-input"
                      placeholder="Search Location..."
                      value={query}
                      onChange={handleChange}
                    />
                  </div>
                  {
                    <div className="srch-location">
                      <ul className="srch-ul">
                        {predictions?.map((prediction) => (
                          <li
                            className="srch-li"
                            key={prediction?.place_id}
                            onClick={() =>
                              handleSelectPrediction(prediction?.description)
                            }
                          >
                            {prediction?.description}
                          </li>
                        ))}
                      </ul>
                    </div>
                  }
                </form>
              </div> */}

              {/* ========================================== */}
              {/* mobile screen end*/}
              {/* ============================================= */}

              <div className="others-option other-option-div d-none d-lg-block">
                {isValidToken || isAuthenticated ? (
                  <div className={`${classOne}`} id="navbarNav">
                    <div className="d-flex pb-2 pb-xl-0">
                      <div className="d-flex gap-3 align-items-start align-items-lg-center ms-4 flex-lg-row flex-column"></div>
                      <div
                        className="d-none d-sm-block"
                        id="dropdown"
                        ref={dropdownRef}>
                        <ul className="navbar-nav ms-auto d-flex align-items-center gap-3">
                          <div>
                            <ul className="nav-social-links d-flex gap-2">
                              {/* <p className="m-0"> Follow us on</p> */}
                              <Link to={links?.instagram}>
                                <li>
                                  <img
                                    className="social-icon"
                                    src={instagram}
                                    alt="instagram"
                                  />
                                </li>
                              </Link>
                              <Link to={links?.facebook}>
                                <li>
                                  <img
                                    className="social-icon"
                                    src={facebook}
                                    alt="facebook"
                                  />
                                </li>
                              </Link>

                              <Link to={links?.linkedin}>
                                <li>
                                  <img
                                    className="social-icon"
                                    src={linkedin}
                                    alt="linkedin"
                                  />
                                </li>
                              </Link>
                              <Link to={links?.twitter}>
                                <li>
                                  <img
                                    className="social-icon"
                                    src={twitter}
                                    alt="twitter"
                                  />
                                </li>
                              </Link>
                            </ul>
                          </div>
                          <li className="d-none d-xl-flex">
                            <Link to={"/notification"}>
                              <img
                                className="d-none d-xl-flex"
                                src={notifiicon}
                                alt="noti"
                              />
                            </Link>
                          </li>
                          <li className="nav-item dropdown position-relative">
                            <button
                              className=" nav-link dropdown-toggle text-white"
                              onClick={() => setAvatarDropdown(!avatarDropdown)}
                            >
                              <img
                                className="avatar-toggle-btn rounded-circle"
                                src={profileinfo?.image_type == "browse"
                                  ? `${allConfingData?.base_urls?.customer_image_url}/${profileinfo?.image}`
                                  : `${allConfingData?.base_urls?.avatar_image_url}/${profileinfo?.predefine_image}`}
                                alt="User Avatar"
                                width="40"
                                height="40"
                                onError={userAvatarError}
                              />
                            </button>
                            {avatarDropdown ? (
                              Object?.keys(profileinfo)?.length == 0 ? (
                                ""
                              ) : (
                                <ul
                                  className="d-flex flex-column avatar-dropdown-menu"
                                  aria-labelledby="navbarDropdown"
                                >
                                  <li>
                                    <div className="d-flex align-items-center justify-content-center user_info">
                                      <div className="">
                                        <p className="user_name_text">
                                          {`${profileinfo?.f_name} ${profileinfo?.l_name}`}
                                          {profileinfo?.is_prime_user ? (
                                            <MdVerified
                                              size={18}
                                              color="#17c3ff"
                                            />
                                          ) : (
                                            ""
                                          )}{" "}
                                        </p>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <Link
                                      to="/profile"
                                      className="avatar-dropdown-item"
                                      onClick={() => setAvatarDropdown(false)}>
                                      My Profile
                                    </Link>
                                  </li>

                                  {profileinfo?.user_type ? (
                                    <li>
                                      <Link
                                        to="/dashboard"
                                        onClick={() => setAvatarDropdown(false)}
                                        className="avatar-dropdown-item">
                                        Dashboard
                                      </Link>
                                    </li>
                                  ) : (
                                    ""
                                  )}

                                  {profileinfo?.user_type ? (
                                    <li>
                                      <Link
                                        to="/myevents/all"
                                        onClick={() => setAvatarDropdown(false)}
                                        className="avatar-dropdown-item">
                                        Manage Events
                                      </Link>
                                    </li>
                                  ) : (
                                    <li>
                                      <Link
                                        to="/myevents/all"
                                        onClick={() => setAvatarDropdown(false)}
                                        className="avatar-dropdown-item">
                                        {profileinfo?.i_want_to == "is_host" ? "My Events" : "My Sponsored"}
                                      </Link>
                                    </li>
                                  )}

                                  {profileinfo?.user_type ? (
                                    ""
                                  ) : (<>
                                    {profileinfo?.i_want_to == "is_host" &&
                                      <li>
                                        <Link
                                          to="/mypastevents/all"
                                          className="avatar-dropdown-item"
                                          onClick={() => setAvatarDropdown(false)}>
                                          My Past Events
                                        </Link>
                                      </li>}
                                  </>)}

                                  {profileinfo?.user_type ? (
                                    ""
                                  ) : (
                                    profileinfo?.i_want_to == "is_sponsor" &&
                                    <li>
                                      <Link
                                        to="/myorders"
                                        onClick={() => setAvatarDropdown(false)}
                                        className="avatar-dropdown-item">
                                        My Sponsorings
                                      </Link>
                                    </li>
                                  )}

                                  {profileinfo?.user_type ? (
                                    ""
                                  ) :
                                    (<>
                                      {profileinfo?.i_want_to == "is_host" &&
                                        <li>
                                          <Link
                                            to="/myeventransaction"
                                            onClick={() => setAvatarDropdown(false)}
                                            className="avatar-dropdown-item">
                                            Transactions
                                          </Link>
                                        </li>}
                                    </>
                                    )}

                                  <li>
                                    <Link
                                      to="/managerequest"
                                      onClick={() => setAvatarDropdown(false)}
                                      className="avatar-dropdown-item">
                                      Manage Request
                                    </Link>
                                  </li>
                                  <li>
                                    {/* <button className="Upgrade-btn avatar-dropdown-item"> */}
                                    <Link
                                      to="/membership"
                                      onClick={() => setAvatarDropdown(false)}
                                      className="avatar-dropdown-item">
                                      {/* <FaStar className="me-2" size={18} /> */}
                                      Membership
                                    </Link>
                                    {/* </button> */}
                                  </li>

                                  {/* {profileinfo?.user_type ? (
                                    ""
                                  ) : ( */}
                                  <li>
                                    <Link
                                      to="/testimonial"
                                      className="avatar-dropdown-item"
                                      onClick={() => setAvatarDropdown(false)}>
                                      Testimonial
                                    </Link>
                                  </li>
                                  {/* )} */}

                                  {profileinfo?.user_type ? (
                                    ""
                                  ) : (
                                    <li>
                                      <Link
                                        to="/chat"
                                        className="avatar-dropdown-item"
                                        onClick={() => setAvatarDropdown(false)}>
                                        Chat Support
                                        {/* <img className="" src={chaticon} alt="chaticon" /> */}
                                      </Link>
                                    </li>
                                  )}

                                  <li>
                                    <Link
                                      to="/notification"
                                      className="avatar-dropdown-item d-flex d-xl-none"
                                      onClick={() => setAvatarDropdown(false)}>
                                      Notification
                                      {/* <img className="nav-btn " src={notifiicon} alt="chaticon" /> */}
                                    </Link>
                                  </li>
                                  <li>
                                    {/* <button className="Upgrade-btn avatar-dropdown-item"> */}
                                    <Link
                                      to="/setting"
                                      onClick={() => setAvatarDropdown(false)}
                                      className="avatar-dropdown-item">
                                      {/* <FaStar className="me-2" size={18} /> */}
                                      Settings
                                    </Link>
                                  </li>
                                  {/* <li><hr className="dropdown-divider"/></li> */}
                                  <li>
                                    <button
                                      className="avatar-dropdown-item"
                                      onClick={() => handleLogout()}>
                                      Logout
                                    </button>
                                  </li>
                                </ul>
                              )
                            ) : (
                              ""
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                ) : (
                  <ul className="login-signup-div d-none d-lg-flex gap-2">
                    <li>
                      <Link
                        to="/login"
                        className="signin-btn"
                      // onClick={toggleNavbar}
                      >
                        Sign in
                      </Link>
                    </li>

                    {/* <li>
                      <Link
                        to="/signup"
                        className="signup-btn d-none d-xl-block"
                        onClick={toggleNavbar}
                      >
                        Sign p
                      </Link>
                    </li> */}
                  </ul>
                )}
              </div>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Navigation;
