import { UpdatePhone, VerifyChangePhone } from "../../Services/Apis"


export const Update_Phone = async (fData) => {
    try {
        return await UpdatePhone(fData).then((data) => data)
    } catch (err) {
        throw err
    }
} 


export const Verify_Change_Phone = async (fData) => {
    try {
        return await VerifyChangePhone(fData).then((data) => data)
    } catch (err) {
        throw err
    }
} 