import { MReqMessageSend } from "../../Services/Apis";


export const MReq_MessageSend = async (id,msg) => {
    try {
        const response =  await MReqMessageSend(id,msg).then((data) => data)
        return response
    } catch (err) {
        throw err;
    }
}