

import { useEffect, useState, useCallback } from "react";
import { toast, ToastContainer } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import { get_city_state_country } from "../../../utils/citystatecountry/cityStateCountry";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { GetServiceProvider } from "../../../utils/CreateEvents/createEvent";
import Select from "react-select";
import ReactQuill from "react-quill";
import { Get_UserInterest } from "../../../utils/basicInformation/basicinformationManager";
import Documenticon from "../../../assets/images/document-upload-icon.svg";
import pdfimg from "../../../assets/images/pdf.png";
import { MdDelete } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import { Partner_With_Sponsor } from "../../../utils/eventdetail/eventdetail";
import { isTokenValid } from "../../../AuthbyToken";

const PartnerWithSponsorRingusForm = ({ setPartnerWithSponsorRingusMdl, Event_id }) => {
  const [errors, setErrors] = useState({});
  const [mapData, setMapData] = useState(null);
  const isValid = isTokenValid();

  const [input, setInput] = useState({
    name: "",
    company_name: "",
    email: "",
    country_code: "",
    mobile_no: "",
    alternative_number: "",
    address: "",
    country_id: "101",
    state_id: "",
    city_id: "",
    pin_code: "",
    landmark: "",
    website: "",
    experience_summary: "",
    visiting_card: "",
    document: '',
    company_profile: "",
    providers: "",
    tags: "",
    area_coverd_location: ""
  });

  const [countries, seCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [zip_country, setZip_Country] = useState({});
  const [zip_state, setZip_State] = useState({});
  const [zip_city, setZip_City] = useState({});
  const latlong = JSON.parse(localStorage.getItem("let_long"));
  const [center, setCenter] = useState({
    lat: latlong?.lat ? latlong?.lat : 19.0759837,
    lng: latlong?.lng ? latlong?.lng : 72.8776559,
  });
  const [marker, setMarker] = useState(null);
  const [pageLoad, setPageLoad] = useState(true);
  const [providerList, setProviderList] = useState([]);
  const [predefineIntrest, setPredefineIntrest] = useState([]);
  const [areaCoveredLocation, setAreaCoveredLocation] = useState([])
  const [documents, setDocuments] = useState([]);

  const getCountryCityState = async () => {
    try {
      await get_city_state_country().then((response) => {
        seCountries(response?.data?.data?.countries);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const ServiceProvider = async () => {
    try {
      await GetServiceProvider().then((response) => {
        const val = response?.data?.data;
        setProviderList(
          val.map((item) => {
            return {
              value: item?.id,
              label: item?.service_type,
            };
          })
        );
      });
    } catch (err) {
      console.log(err);
    }
  };

  const GetStaticDataFunction = async () => {
    try {
      Get_UserInterest().then((response) => {
        const getInterest = response?.data;
        const options = getInterest?.map((topic) => ({
          value: topic.id,
          label: topic.name,
        }));
        setPredefineIntrest(options);
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getCountryCityState();
    if (isValid) {
      ServiceProvider();
    }
    GetStaticDataFunction();
  }, []);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;

    if (name == "company_profile" || name == 'document' || name == "visiting_card") {
      setInput({
        ...input,
        [name]: files[0],
      });
    } else {
      setInput({
        ...input,
        [name]: value,
      });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        await Partner_With_Sponsor(Event_id, input).then((result) => {
          if (result?.success) {
            toast.success(result?.massage, {
              position: "top-center",
              autoClose: 3000
            });
            setPartnerWithSponsorRingusMdl(false);
          }
        });
      } catch (error) {
        console.log(error, "error");
      }
    }
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    if (input?.name?.trim() == "") {
      newErrors.name = "Contact Person Name is required";
      valid = false;
    }

    if (input?.company_name?.trim() == "") {
      newErrors.company_name = "Company Name is required";
      valid = false;
    }

    if (!input.email) {
      newErrors.email = "Reference Email is required";
      valid = false;
    }
    else if (!/\S+@\S+\.\S+/.test(input.email)) {
      newErrors.email = "Invalid Email address."
      valid = false;
    }

    if (!input.mobile_no) {
      newErrors.mobile_no = "Mobile No. is required";
      valid = false;
    } else if (!/^\d{10}$/.test(input.mobile_no)) {
      newErrors.mobile_no = "Invalid mobile number.";
    }

    if (!input.alternative_number) {
      newErrors.alternative_number = "Alternative Mobile No. is required";
      valid = false;
    } else if (!/^\d{10}$/.test(input.alternative_number)) {
      newErrors.alternative_number = "Invalid mobile number.";
    }

    const summary = input.experience_summary?.replace(/<p><br><\/p>/g, '')
    if (summary?.trim() == "") {
      newErrors.experience_summary = "Experience Summary is required";
      valid = false;
    }

    if (input.address.trim() == "") {
      newErrors.address = "Area, Street, Sector, Village is required";
      valid = false;
    }

    if (!input.country_id || input.country_id.toString()?.trim() == "") {
      newErrors.country_id = "Country is required";
      valid = false;
    }

    if (!input.state_id || input.state_id.toString()?.trim() == "") {
      newErrors.state_id = "State is required";
      valid = false;
    }

    if (!input.city_id || input.city_id.toString() == "") {
      newErrors.city_id = "City is required";
      valid = false;
    }

    if (input.pin_code?.trim() == "") {
      newErrors.pin_code = "Pin Code is required";
      valid = false;
    }

    if (!input.house_no || !input.house_no.toString()?.trim()) {
      newErrors.house_no = "Flat/House No, Building is required";
      valid = false;
    }

    // if (!input.landmark || !input.landmark.trim()) {
    //   newErrors.landmark = "Landmark Is Required";
    //   valid = false;
    // }

    if (input?.tags.length <= 0) {
      newErrors.tags = "Guest Interest is required";
      valid = false;
    } else if (
      !Array.isArray(JSON.parse(input.tags)) ||
      JSON.parse(input?.tags).length < 5
    ) {
      newErrors.tags = "Select at least 5 Guest Interest";
      valid = false;
    } else if (JSON.parse(input.tags).length > 15) {
      newErrors.tags = "Select at most 15 Guest Interest";
      valid = false;
    }

    if (input?.providers.length <= 0) {
      newErrors.providers = "Providers is required";
      valid = false;
    }

    if (!input?.document) {
      newErrors.document = "Document is required";
      valid = false
    }

    if (!input?.visiting_card) {
      newErrors.visiting_card = "Visiting Card is required";
      valid = false
    }

    if (!input?.company_profile) {
      newErrors.company_profile = "Company Profile is required";
      valid = false
    }

    setErrors(newErrors);
    return valid;
  };

  const handlePinCodeChange = async (val, type) => {
    const pinc = val;

    if (isNaN(pinc)) {
      console.error("Invalid pin code. Please enter a valid number.");
      // You may set an error state or handle it in another way
      return;
    }

    setInput((prevInput) => ({ ...prevInput, pin_code: pinc }));

    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${pinc}&key=AIzaSyA3BYK_Siw8ebziSskitaA5YMjbvcO6n4U`
      );
      const data = await response.json();
      if (data.results && data.results.length > 0) {
        const addressComponents = data.results[0].address_components;
        const latLong = data?.results[0]?.geometry?.location;
        const fullAddress = addressComponents
          ?.map((component) => component.long_name)
          .join(", ");
        if (addressComponents) {
          const cityComponent = addressComponents.find((component) =>
            component?.types?.includes("locality")
          );
          const stateComponent = addressComponents.find((component) =>
            component?.types?.includes("administrative_area_level_1")
          );
          const countryComponent = addressComponents.find((component) =>
            component?.types?.includes("country")
          );

          if (countryComponent) setZip_Country(countryComponent);
          if (stateComponent) setZip_State(stateComponent);
          if (cityComponent) setZip_City(cityComponent);

          const country = countries.find(
            (country) => country.country_name == countryComponent?.long_name
          );
          setStates(country ? country?.states : []);

          const state = country
            ? country.states.find(
              (state) => state?.state_name == stateComponent?.long_name
            )
            : null;

          const city = state
            ? state.cities.find(
              (city) => city?.city_name == cityComponent?.long_name
            )
            : null;

          if (state) setCities(state.cities);

          if (state) {
            // this is coverd area location
            const coverdLocation = state?.cities
            const hostCoverdLocationArea = coverdLocation?.map((item) => ({
              value: item?.city_id,
              label: item?.city_name,
            }))
            setAreaCoveredLocation(hostCoverdLocationArea)
          }

          if (addressComponents && latLong) {
            if (type == "map") {
              setInput((prevInput) => ({
                ...prevInput,
                country_id: country?.country_id,
                state_id: state?.state_id,
                city_id: city?.city_id,
              }));
            } else {
              setInput((prevInput) => ({
                ...prevInput,
                country_id: country?.country_id,
                state_id: state?.state_id,
                city_id: city?.city_id,
                // address: fullAddress,
                latitude: latLong?.lat,
                longitude: latLong?.lng,
              }));
              setCenter(latLong);
              setMarker(latLong);
            }
          }
        } else {
          console.error(
            "No address components found in the Geocoding API response."
          );
        }
      } else {
        console.error("No results found in the Geocoding API response.");
      }
    } catch (error) {
      console.error("Error fetching location:", error);
      // Handle the error (e.g., set an error state)
    }
  };

  const handleCountryChange = (e) => {
    const cntry = e?.target?.value;
    setInput({ ...input, country_id: cntry, state_id: "", city_id: "" });
    if (cntry) {
      const country = countries.find((country) => country.country_id == cntry);
      setStates(country ? country?.states : []);
    } else {
      const country = countries.find((country) => country.country_id == "101");
      setStates(country ? country?.states : []);
      setCities([]);
    }
    //  else {
    //   setStates([]);
    //   setCities([]);
    // }
  };

  // console.log("contry", input?.country, input?.state, input?.city)

  const handleStateChange = (e) => {
    const sState = e.target.value;
    setInput({ ...input, state_id: sState, city_id: "" });

    if (sState) {
      // const country = countries.find(country => country?.country_name == selectedCountry);
      const state = states
        ? states.find((state) => state?.state_id == sState)
        : null;
      setCities(state ? state.cities : []);
    } else {
      setCities([]);
    }
  };

  const handleCityChange = (e) => {
    const cityid = e.target.value;
    setInput({ ...input, city_id: cityid });
  };

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyA3BYK_Siw8ebziSskitaA5YMjbvcO6n4U",
  });

  const onUnmount = useCallback(function callback() {
    setMapData(null);
  }, []);

  const getAddressFromLatLng = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyA3BYK_Siw8ebziSskitaA5YMjbvcO6n4U`
      );

      if (response.ok) {
        const data = await response.json();
        if (data?.results && data?.results.length > 0) {
          const full_address = data?.results[0]?.address_components;
          const location = full_address.find((loc) =>
            loc?.types?.includes("postal_code")
          );
          const location1 = data?.results[0]?.formatted_address;
          const let_lng = data?.results[0]?.geometry?.location;
          setInput({
            ...input,
            latitude: let_lng?.lat,
            longitude: let_lng?.lng,
            pin_code: location.long_name,
            // address: location1,
          });
          handlePinCodeChange(location?.long_name, "map");
        } else {
          console.log("Address not found");
        }
      } else {
        console.log("Failed to fetch address");
      }
    } catch (error) {
      console.error("Error fetching address:", error);
    }
  };

  const handleMapClick = (event) => {
    const { latLng } = event;
    const lat = latLng.lat();
    const lng = latLng.lng();
    setCenter({ lat, lng });
    setMarker({ lat, lng });
    getAddressFromLatLng(lat, lng);
  };

  const handleAddProvider = (selectedOption) => {
    const list = selectedOption?.map((item) => {
      return item.value;
    });
    setInput({ ...input, providers: list });
  };

  // rect quill length count
  const stripHtmlTags = (html) => {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  };

  const getDescriptionLength = () => {
    const cleanDescription = stripHtmlTags(input.experience_summary);
    return cleanDescription.length;
  };

  const maxLength = 500;
  const currentLength = getDescriptionLength();
  const remainingLength = maxLength - currentLength;
  //

  const handleSummary = (value) => {
    const text = value.replace(/<[^>]*>?/gm, "");
    if (text.length <= 500) {
      setInput((prevState) => ({
        ...prevState,
        experience_summary: value,
      }));
      setErrors({
        ...errors,
        experience_summary: "",
      });
    } else {
      setErrors({
        ...errors,
        experience_summary: "Experience Summary cannot exceed 2500 characters.",
      });
    }
  };

  const handleAddIntrest = (selectedOption) => {
    const list = selectedOption?.map((item) => {
      return item.value;
    });
    setInput({ ...input, tags: JSON.stringify(list) });
  };

  const handlelocationArea = (selectedOption) => {
    const list = selectedOption?.map((item) => {
      return item.value;
    });
    setInput({ ...input, area_coverd_location: list });
  }

  const handleFileChangeDocument = (event) => {
    const files = event.target.files;
    const documentArray = [];

    // if (documents.length + files.length > 3) {
    //   toast.warn("You can only add up to 3 documents", {
    //     position: "top-center",
    //     autoClose: 3000,
    //     hideProgressBar: false,
    //     theme: "light",
    //   });
    //   return;
    // }

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      documentArray.push(file);
    }
    setDocuments([...documents, ...documentArray]);
  };

  const handleRemoveDocument = (idx) => {
    const val = documents.filter((item, index) => index !== idx);
    setDocuments(val);
  };

  const handleMobileNumberChange = (value, country) => {
    const phoneNum = value?.slice(2);
    const d_code = `${country.format.charAt(0)}${country?.dialCode}`;
    setInput({
      ...input,
      mobile_no: `${phoneNum}`,
      country_code: `${d_code}`
    });
  };

  const handleAlternateNumberChange = (value, country) => {
    const phoneNum = value?.slice(2);
    const d_code = `${country.format.charAt(0)}${country?.dialCode}`;
    setInput({
      ...input,
      alternative_number: `${phoneNum}`,
      country_code: `${d_code}`
    });
  };


  return (
    <>
      <ToastContainer />
      <div className="package_form_overlay">
        <div className="partnerhost_form_popup">
          <div className="package_form_popup-content">
            <span
              className="package_form_close"
              onClick={() => setPartnerWithSponsorRingusMdl(false)}>
              &times;
            </span>
            <div className="basicInfo ">
              <h2 className="basicinfo-head m-0 p-0">Partner With SponsorRingUs</h2>
              <p className="basicinfo-p m-0">Please Fill The Details</p>

              <div className="">
                <div className="row mt-3 partner-form-div">
                  {/* <h4 className="form-heading"> Sponsorship Package</h4> */}
                  <div className="col-md-12">
                    <form onSubmit={onSubmit}>
                      <div className="row">
                        <div className="form-group col-12 col-md-6">
                          <label className="form-label">
                            Contact Person Name
                            <span className="asterisk">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={input?.name}
                            name="name"
                            placeholder="Enter Contact Person Name"
                            onChange={handleInputChange}
                          />
                          {errors?.name && (
                            <span className="error">{errors?.name}</span>
                          )}
                        </div>

                        <div className="form-group col-12 col-md-6">
                          <label className="form-label">
                            Company Name<span className="asterisk">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={input?.company_name}
                            name="company_name"
                            placeholder="Enter Company Name"
                            onChange={handleInputChange}
                          />
                          {errors?.company_name && (
                            <span className="error">{errors?.company_name}</span>
                          )}
                        </div>
                      </div>

                      <div className="row">
                        <div className="form-group col-md-6">
                          <label className="form-label">
                            Mobile No.<span className="asterisk">*</span>
                          </label>
                          <PhoneInput
                            className=""
                            country={"in"}
                            name='mobile_no'
                            value={`${input?.country_code}${input?.mobile_no}`}
                            onChange={handleMobileNumberChange}
                          />
                          {errors.mobile_no && (
                            <span className="error">
                              {errors.mobile_no}
                            </span>
                          )}
                        </div>

                        <div className="form-group col-md-6">
                          <label className="form-label">
                            Alternate No.<span className="asterisk">*</span>
                          </label>
                          <PhoneInput
                            className=""
                            country={"in"}
                            value={`${input?.country_code}${input?.alternative_number}`}
                            onChange={handleAlternateNumberChange}
                          />
                          {errors.alternative_number && (
                            <span className="error">
                              {errors.alternative_number}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6 form-group ">
                          <label className="form-label">
                            Email<span className="asterisk">*</span>
                          </label>
                          <div className="position-relative d-flex align-items-center">
                            <input
                              type="email"
                              className="form-control rounded-3"
                              placeholder="Enter Email"
                              name="email"
                              value={input.email}
                              onChange={handleInputChange}
                            />
                          </div>

                          {errors.email && (
                            <span className="error">{errors.email} </span>
                          )}
                        </div>
                      </div>

                      <div className="row mt-2">
                        <h5 className=" form-section-heading m-0">Address</h5>
                        <div className="form-group col-6 col-md-3 ">
                          <label className="form-label">
                            Pin Code<span className="asterisk">*</span>
                          </label>
                          <input
                            type="number"
                            min="0"
                            value={input.pin_code}
                            name="pin_code"
                            onChange={(e) =>
                              handlePinCodeChange(e.target.value)
                            }
                            className="form-control"
                            placeholder="Pin Code"
                          />
                          {errors.pin_code && (
                            <span className="error">{errors.pin_code}</span>
                          )}
                        </div>
                        <div className="form-group col-6 col-md-3 ">
                          <label className="form-label">
                            Country<span className="asterisk">*</span>
                          </label>
                          <select
                            className="form-control"
                            name="country"
                            onChange={handleCountryChange}
                          >
                            <option selected>Select Country</option>

                            {countries &&
                              countries?.map((val, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={val?.country_id}
                                    selected={
                                      Object?.keys(zip_country)?.length == 0
                                        ? input?.country_id ? val?.country_id == input?.country_id : val?.country_name == "India"
                                        : zip_country?.long_name ==
                                        val?.country_name
                                    }
                                  >
                                    {val?.country_name}
                                  </option>
                                );
                              })}
                          </select>
                          {errors.country_id && (
                            <span className="error">{errors.country_id}</span>
                          )}
                        </div>

                        <div className="form-group col-6 col-md-3 ">
                          <label className="form-label">
                            State<span className="asterisk">*</span>
                          </label>
                          <select
                            className="form-control"
                            name="state"
                            onChange={handleStateChange}
                          >
                            <option selected>Select State</option>
                            {states &&
                              states?.map((val, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={val?.state_id}
                                    selected={Object.keys(zip_state).length == 0
                                      ? val?.state_id == input?.state_id
                                      : zip_state?.long_name ==
                                      val?.state_name
                                    }
                                  >
                                    {val?.state_name}
                                  </option>
                                );
                              })}
                          </select>
                          {errors.state_id && (
                            <span className="error">{errors.state_id}</span>
                          )}
                        </div>

                        <div className="form-group col-6 col-md-3 ">
                          <label className="form-label">
                            City<span className="asterisk">*</span>
                          </label>
                          <select
                            className="form-control"
                            name="city"
                            onChange={handleCityChange}
                          >
                            <option selected>Select City</option>
                            {cities &&
                              cities?.map((val, index) => {
                                //  console.log("ccc",input?.city?.toLowerCase() == val?.city_name?.toLowerCase())
                                return (
                                  <>
                                    <option
                                      key={index}
                                      value={val?.city_id}
                                      selected={
                                        Object.keys(zip_city).length == 0
                                          ? val?.city_id == input?.city_id
                                          : zip_city?.long_name ==
                                          val?.city_name
                                      }
                                    >
                                      {val?.city_name}
                                    </option>
                                  </>
                                );
                              })}
                          </select>
                          {errors.city_id && (
                            <span className="error">{errors.city_id}</span>
                          )}
                        </div>
                      </div>

                      <div className="row">
                        <div className="form-group col-md-3 col-sm-6">
                          <label className="form-label">
                            Flat/House No., Building
                            <span className="asterisk">*</span>
                          </label>

                          <input
                            type="text"
                            value={input.house_no}
                            name="house_no"
                            onChange={handleInputChange}
                            className="form-control"
                            placeholder="Flat/House No., Building"
                          />
                          {errors.house_no && (
                            <span className="error">{errors.house_no}</span>
                          )}
                        </div>

                        <div className="form-group col-md-6">
                          <label className="form-label">
                            Area, Street, Sector, Village
                            <span className="asterisk">*</span>
                          </label>
                          <input
                            type="text"
                            value={input?.address}
                            name="address"
                            onChange={handleInputChange}
                            className="form-control"
                            placeholder="Area, Street, Sector, Village"
                          />
                          {errors.address && (
                            <span className="error">{errors.address}</span>
                          )}
                          {/* <p className="text-primary form-label mb-0 ">
                        <FaInfoCircle /> Current Area, Street, Sector, Village Select With A Map
                      </p> */}
                        </div>

                        <div className="form-group col-md-3 col-sm-6">
                          <label className="form-label">
                            Landmark<span className="asterisk"></span>
                          </label>

                          <input
                            type="text"
                            value={input.landmark}
                            name="landmark"
                            onChange={handleInputChange}
                            className="form-control"
                            placeholder="Landmark"
                          />
                          {errors.landmark && (
                            <span className="error">{errors.landmark}</span>
                          )}
                        </div>
                      </div>

                      {/* <div className="row">
                        <div className="form-group col-md-3 col-sm-6">
                          <label className="form-label">
                            Address Type<span className="asterisk">*</span>
                          </label>
                          <select
                            className="form-control"
                            name="address_type"
                            onChange={handleInputChange}
                          >
                            <option value="" selected>
                              Select Address Type
                            </option>
                            <option
                              value="Home"
                              selected={input.address_type == "Home"}
                            >
                              Home
                            </option>
                            <option
                              value="Office"
                              selected={input.address_type == "Office"}
                            >
                              Office
                            </option>
                          </select>
                          {errors.address_type && (
                            <span className="error">{errors.address_type}</span>
                          )}
                        </div>
                      </div> */}

                      <div className="row mt-2">
                        <div className="col-md-12">
                          {isLoaded ? (
                            <GoogleMap
                              mapContainerStyle={{
                                height: "400px",
                                width: "100%",
                              }}
                              center={center}
                              zoom={13}
                              // onLoad={onLoad}
                              onUnmount={onUnmount}
                              onClick={handleMapClick}
                            >
                              {marker && <Marker position={marker} />}
                            </GoogleMap>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-md-6">
                          <label className="form-label">
                            Website URL
                            <span className="asterisk"></span>
                          </label>
                          <input
                            type="text"
                            value={input.website}
                            name="website"
                            onChange={handleInputChange}
                            className="form-control"
                            placeholder="Enter Website URL"
                          />
                          {/* {errors.website && (
                            <span className="error">{errors.website}</span>
                          )} */}
                        </div>

                        <div className="col-md-6">
                          <label className="form-label">
                            Service Provider <span className="asterisk">*</span>
                          </label>
                          <Select
                            className=""
                            name="providers"
                            defaultValue={[]}
                            onChange={handleAddProvider}
                            options={providerList}
                            isMulti
                            placeholder="Select Service Provider"
                          />
                          {errors.providers && (
                            <span className="error">{errors.providers}</span>
                          )}
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div className="d-flex justify-content-between">
                            <label className="form-label">
                              Experience Summary<span className="asterisk">*</span>
                            </label>

                            <p className="Character-0500">
                              Character:<span className="text-style-1">
                                {currentLength}/{remainingLength}
                              </span>
                            </p>
                          </div>
                          <div style={{ height: "200px" }}>
                            <ReactQuill
                              value={input?.experience_summary}
                              onChange={handleSummary}
                              theme="snow"
                              style={{ height: "150px" }}
                            />
                          </div>
                          {errors.experience_summary && (
                            <span className="error">{errors.experience_summary}</span>
                          )}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label">
                              Interest<span className="asterisk">*</span>
                            </label>
                            <Select
                              defaultValue={[]}
                              isMulti
                              name="tags"
                              options={predefineIntrest}
                              onChange={handleAddIntrest}
                              className=""
                              classNamePrefix="Select"
                              placeholder="Select Interest"
                            />
                            {errors.tags && (
                              <span className="error">{errors.tags}</span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label">
                              Areas/Locations Covered<span className="asterisk"></span>
                            </label>
                            <Select
                              defaultValue={[]}
                              isMulti
                              name="tags"
                              options={areaCoveredLocation}
                              onChange={handlelocationArea}
                              className=""
                              classNamePrefix="Select"
                              placeholder="Select Areas/Locations Covered"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <label className="form-label">
                            Add Docs<span className="asterisk">*</span>
                          </label>
                          <div className="">
                            <div className="row mt-2">
                              <div className="d-flex flex-column align-items-center col-md-12">
                                <label className="col-3">
                                  <img
                                    className="markus-upload-docs"
                                    src={Documenticon}
                                    //accept=".pdf,.doc,.docx,.txt"
                                    alt="img-upload" />
                                  <input
                                    className="d-none"
                                    type="file"
                                    name="document"
                                    // value={documents}
                                    multiple
                                    // onChange={handleFileChangeDocument}
                                    onChange={handleInputChange}

                                  />
                                </label>
                              </div>

                              {input?.document && <div className="row">
                                <div className="px-1 py-1 col-2 ">
                                  <div className="markas-pdf-div position-relative">
                                    <img
                                      className="markas-pdf-img"
                                      src={pdfimg}
                                      alt="pdf"
                                    />
                                    <p className="pdf-title">{input?.document?.name}</p>

                                    <button
                                      className="image-remove-icon"
                                      onClick={() => setInput({ ...input, document: "" })}
                                    >
                                      <MdDelete size={20} color="white" />
                                    </button>
                                  </div>
                                </div>
                              </div>}

                              {/* <div className="row">
                                {documents?.map((file, index) => {
                                  return (
                                    <div className="px-1 py-1 col-2 " key={index}>
                                      <div className="markas-pdf-div position-relative">
                                        <img
                                          className="markas-pdf-img"
                                          src={pdfimg}
                                          alt="pdf"
                                        />
                                        <p className="pdf-title">{file?.name}</p>

                                        <button
                                          className="image-remove-icon"
                                          onClick={() =>
                                            handleRemoveDocument(index)
                                          }
                                        >
                                          <MdDelete size={20} color="white" />
                                        </button>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div> */}
                              {errors.document && (
                                <span className="error">{errors.document}</span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="form-group col-6">
                          <label className="form-label">
                            Visiting Card
                            <span className="asterisk">*</span>
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            name="visiting_card"
                            placeholder="Visiting Card"
                            onChange={handleInputChange}
                          />
                          {errors?.visiting_card && (
                            <span className="error">{errors?.visiting_card}</span>
                          )}
                        </div>

                        <div className="form-group col-6">
                          <label className="form-label">
                            Company Profile<span className="asterisk">*</span>
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            name="company_profile"
                            placeholder="Company Profile"
                            onChange={handleInputChange}
                          />
                          {errors?.company_profile && (
                            <span className="error">{errors?.company_profile}</span>
                          )}
                        </div>
                      </div>

                      <div className="row">
                      <div className="col-md-6">
                          {input?.visiting_card !== "" && (
                            <div className="mt-3">
                              <div className="d-flex justify-content-end">
                                <button className="img-close-btn" type="button">
                                  <IoClose
                                    size={24}
                                    onClick={() => setInput({ ...input, visiting_card: "" })}
                                  />
                                </button>
                              </div>
                              {input?.visiting_card instanceof File && (
                                <div className="mt-2 d-flex justify-content-center">
                                  <img
                                    className="event-logo-img"
                                    src={URL.createObjectURL(input?.visiting_card)}
                                    alt="updateimg"
                                  />
                                </div>
                              )}
                            </div>

                          )}
                        </div>

                        <div className="col-md-6">
                          {input?.company_profile !== "" && (
                            <div className="mt-3">
                              <div className="d-flex justify-content-end">
                                <button className="img-close-btn" type="button">
                                  <IoClose
                                    size={24}
                                    onClick={() => setInput({ ...input, company_profile: "" })}
                                  />
                                </button>
                              </div>
                              {input?.company_profile instanceof File && (
                                <div className="mt-2 d-flex justify-content-center">
                                  <img
                                    className="event-logo-img"
                                    src={URL.createObjectURL(input?.company_profile)}
                                    alt="updateimg"
                                  />
                                </div>
                              )}
                            </div>

                          )}
                        </div>
                      </div>

                      <div className="d-flex gap-3 justify-content-end align-items-center mt-5">
                        <button className="btn delete-event-btn" type="button" onClick={() => setPartnerWithSponsorRingusMdl(false)}>Close</button>
                        <div className="">
                          <button className="btn saveandcontinue-btn" type="submit">
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PartnerWithSponsorRingusForm;
