import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { logActivityCheck, loginUserData, pass_systemIpAddress, systemIpAddress } from "../../utils/user/UserManager";
import { useDispatch } from "react-redux";
import { loginmessage } from "../../redux/Login";
import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

const LoginWithEmail = ({ informationIp, deviceType }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  // const [informationIp, setInformationIp] = useState({})
  // const [loginToken, setloginToken] = useState();
  const navigate = useNavigate();

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      email: email,
      password: password,
    };
    const newErrors = {};
    if (!email || !email.trim() === "") {
      newErrors.email = "Email is required";
      setLoading(false);
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Email is invalid";
      setLoading(false);
    } else {
      newErrors.email = "";
    }
    if (!password || !password.trim() === "") {
      newErrors.password = "Password is required";
      setLoading(false);
    } else if (password.trim().length < 8) {
      newErrors.password = "Password should be at least 8 characters long";
      setLoading(false);
    } else {
      newErrors.password = "";
    }
    setErrors(newErrors);

    if (newErrors.email || newErrors.password) {
      return;
    } else {
      try {
        const response = await loginUserData(data);
        const token = response?.data?.token;
        const message = response?.data?.message;
        const userType = response?.data?.user_type;
        const liveSample = response?.data?.sample;
        toast.dismiss();
        dispatch(loginmessage({ userType: userType, message: message }));
        if (token) {
          localStorage.setItem("LoginToken", token);
          localStorage.setItem("LiveSample", liveSample);
          toast.dismiss();
          userType ? navigate("/dashboard") : navigate("/");
          setEmail("");
          setPassword("");
          // setloginToken(token)
          loggedActivity();
        }
      } catch (error) {
        toast.error(error?.response?.data?.message, {
          position: "top-center",
          autoClose: 3000,
        });
        setEmail("");
        setPassword("");
      }
    }
    setLoading(false);
  };

  // const SystemIp = async () => {
  //   try {
  //     await systemIpAddress()
  //       .then((response) => {
  //         passIpAddress(response?.data?.ip)
  //       })
  //   } catch (err) {
  //     console.log(err)
  //   }
  // }

  // const passIpAddress = async (ipv4) => {
  //   try {
  //     await pass_systemIpAddress(ipv4)
  //       .then((response) => {
  //         setInformationIp(response?.data)
  //       })
  //   } catch (err) {
  //     console.log(err)
  //   }
  // }

  // useEffect{informationIp}(() => {
  //   SystemIp();
  // }, []);

  const loggedActivity = async () => {
    const data = {
      ip_address: informationIp?.ip,
      lat: informationIp?.latitude,
      long: informationIp?.longitude,
      log_type: 'login',
      device_type: JSON.stringify(deviceType),
      city: informationIp?.city,
      country_code: informationIp?.country_code,
      region_code: informationIp?.region_code
    }

    try {
      await logActivityCheck(data)

    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      <div className="">
        <p className="login-p">Sign In With Email</p>
        <form onSubmit={onSubmit}>
          <div className="form-group">
            <label className="login-label">Email</label>
            <input
              type="email"
              className="form-control  rounded-3"
              placeholder="Enter Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {errors?.email && (
              <span className="" style={{ color: "red", fontSize: "13px" }}>
                {errors?.email}
              </span>
            )}
          </div>

          <div className="form-group">
            <label className="login-label">Password</label>
            <input
              type="password"
              className="form-control rounded-3"
              placeholder="Enter Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {errors.password && (
              <span
                className="error-msg"
                style={{ color: "red", fontSize: "13px" }}
              >
                {errors.password}
              </span>
            )}
          </div>
          <div className="d-flex flex-column">
            <div>
            <Link to="/forgotpassword" className="forgot-text">
              Forgot Password?
            </Link>
            </div>
            <button
              type="submit"
              className="btn d-flex align-items-center justify-content-center gap-1 login-btn mt-4">
              {loading ? (
                <div className="spinner-border  text-light" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                ""
              )}
              Sign In
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default LoginWithEmail;
