import moment from "moment";
import { useEffect, useState, useMemo, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";

const OrderListTable = ({ val, currency }) => {
  const [columnDefs, setColumnDefs] = useState([]);
  const gridRef = useRef();

  const getCurrencySymbol = (currencyCode) => {
    switch (currencyCode) {
      case "INR":
        return "₹";
      case "USD":
        return "$";
      case "EUR":
        return "€";
      case "GBP":
        return "£";
      default:
        return "";
    }
  };

  useEffect(() => {
    setColumnDefs([
      {
        headerName: "Transaction Id",
        field: "transaction_id",
        cellRenderer: ({ value, data }) => {
          return <p className=""> {value ? value : "-"}</p>;
        },
      },
      {
        headerName: "Event",
        field: "events.event_title",
        // width: 150,
        cellRenderer: ({ value, data }) => {
          return (
            <Link
              className="navigate-detail-btn"
              to={`/eventdetail/${data?.events?.id}`}
              state={{ type: "Events" }}
            >
              <p className="order-list-text fw-bold">
                {data?.events?.event_title}
              </p>
            </Link>
          );
        },
      },
      { headerName: "Package",width: 150, field: "events_packages.title" },

      {
        headerName: "Package Amount",
        field: "events_packages.amount",
        // width: 150,
        cellRenderer: ({ value, data }) => {
          const package_amt = data?.events_packages?.amount;
          const crncy = data?.currency;
          const currencySymbol = getCurrencySymbol(crncy);

          const formattedAmount = package_amt
            ? `${
                currency?.currencySymbol
                  ? currency?.currencySymbol
                  : currencySymbol
              } ${
                currency?.selectedCurrencyPrice
                  ? (currency?.selectedCurrencyPrice * package_amt).toFixed(2)
                  : package_amt
              }`
            : "-";
          return <p className="">{formattedAmount}</p>;
        },
      },
      {
        headerName: "Coupon Code ",
        field: "coupons.coupon_code",
        // width: 150,
        valueGetter: function (params) {
          const couponCode = params.data?.coupons?.coupon_code;
          return couponCode ? couponCode : "-";
        },
      },
      {
        headerName: "Coupon Discount",
        field: "discount",
        // width: 150,
        cellRenderer: function (params) {
          const couponDiscount = params.data?.coupons?.discount;
          const discType = params.data?.coupons?.type;
          return couponDiscount ? (
            <p>
              {couponDiscount} {discType == "Percentage" ? "%" : ""}
            </p>
          ) : (
            "-"
          );
        },
      },
      {
        headerName: "Payable Amount",
        field: "amount",
        // width: 150,
        cellRenderer: ({ value, data }) => {
          const package_amt = data?.amount;
          const crncy = data?.currency;
          const currencySymbol = getCurrencySymbol(crncy);
          const formattedAmount = package_amt
            ? `${
                currency?.currencySymbol
                  ? currency?.currencySymbol
                  : currencySymbol
              } ${
                currency?.selectedCurrencyPrice
                  ? (currency?.selectedCurrencyPrice * package_amt).toFixed(2)
                  : package_amt
              }`
            : "-";
          return <p className="">{formattedAmount}</p>;
        },
      },

      {
        headerName: "Status",
        field: "payment_status",
        // width: 150,
        cellRenderer: ({ value, data }) => {
          const status = data?.payment_status;
          return (
            <p
              className={
                value == "success"
                  ? "success-status-text text-capitalize p-0"
                  : value == "failed"
                  ? "failed-status-text text-capitalize p-0"
                  : "pending-status-text text-capitalize p-0"
              }
            >
              {value}
            </p>
          );
        },
      },
      {
        headerName: "Date",
        field: "created_at",
        cellRenderer: ({ value, data }) => {
          return moment(data?.created_at).format("ll hh:mm A");
        },
      },
    ]);
  }, [currency, val]);

  const defaultColDef = useMemo(() => {
    return {
      filter: "agTextColumnFilter",
      floatingFilter: true,
    };
  }, []);

  const onGridReady = useCallback((params) => {}, []);

  const onBtExport = useCallback(() => {
    gridRef.current.api.exportDataAsExcel();
  }, []);

  const discPrice = val?.events_packages?.amount - val?.amount;

  return (
    <>
      <div className="pt-5 position-relative">
        <button className="export-to-excel-btn" onClick={onBtExport}>
          Export to Excel
        </button>
        <div className="ag-theme-alpine" style={{height:600,width:"100%"}}>
          <AgGridReact
            ref={gridRef}
            onGridReady={onGridReady}
            columnDefs={columnDefs}
            rowData={val}
            defaultColDef={defaultColDef}
            rowSelection="multiple"
          ></AgGridReact>
        </div> 
      </div>
    </>
  );
};
export default OrderListTable;
