import { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import RoadMap from "../../assets/images/Roadmap.png"
import SponsorProcessFlow from "../../assets/images/ProcessFlow.png"
import HostProcessFlow from '../../assets/images/HostProcessFlow.png'

const HowitWorks = () => {

    const [whySponsorRingUs, setWhySponsorRingUs] = useState(true)
    const [sponsorWorks, setSponsorWorks] = useState(false)
    const [hostWorks, setHostWorks] = useState(false)
    const [champWorks, setChampWorks] = useState(false)
    

    const handleWhySponsorRingUs = () => {
        setWhySponsorRingUs(!whySponsorRingUs)
        setSponsorWorks(false)
        setHostWorks(false)
        setChampWorks(false)
    }

    const handleSponsor = () => {
        setWhySponsorRingUs(false)
        setSponsorWorks(!sponsorWorks)
        setHostWorks(false)
        setChampWorks(false)
    }

    const handleHost = () => {
        setWhySponsorRingUs(false)
        setSponsorWorks(false)
        setHostWorks(!hostWorks)
        setChampWorks(false)
    }

    const handleChamp = () => {
        setWhySponsorRingUs(false)
        setSponsorWorks(false)
        setHostWorks(false)
        setChampWorks(!champWorks)
    }


    return (
        <>
         <div className="newevent container fluid py-5">
               <h2 className="text-center">Coming Soon...</h2>
               
                {/* <div className="row">
                    <div className="col-md-3 d-flex flex-column mt-0 hiw-sidebar">
                        <div>
                            <button
                                className="filter-btn"
                                // onClick={() => setWhySponsorRingUs(!whySponsorRingUs)}
                                onClick={handleWhySponsorRingUs}
                            >
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <p className="filter-heading mb-0">Why SponsorRingUs?</p>
                                    </div>
                                    <div>
                                        {whySponsorRingUs ? (
                                            <IoIosArrowUp size={18} />
                                        ) : (
                                            <IoIosArrowDown size={18} />
                                        )}
                                    </div>
                                </div>
                            </button>
                            {whySponsorRingUs && (
                                <div className="filter-dropdown-content">
                                    <div className="card-body">
                                        <div className="py-1 pt-0">
                                            <a href="#whySponsorRingUs_value_proposition " className="my_a">Value Proposition</a>
                                        </div>
                                        <div className="py-1">
                                            <a href="#whySponsorRingUs_problem&solution" className="my_a">Problem & Solution</a>
                                        </div>
                                        <div className="py-1">
                                            <a href="#whySponsorRingUs_roadmap" className="my_a">SponsorRingUs - Roadmap</a>
                                        </div>
                                        <div className="py-1">
                                            <a href="#whySponsorRingUs_importance" className="my_a">Importance</a>
                                        </div>
                                        <div className="py-1 pb-0">
                                            <a href="#whySponsorRingUs_benefits" className="my_a">Benefits</a>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <hr className="m-0" />
                        </div>
                        <div>
                            <button
                                className="filter-btn"
                                // onClick={() => setSponsorWorks(!sponsorWorks)}
                                onClick={handleSponsor}
                            >
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <p className="filter-heading mb-0">SPONSOR - How it works?</p>
                                    </div>
                                    <div>
                                        {sponsorWorks ? (
                                            <IoIosArrowUp size={18} />
                                        ) : (
                                            <IoIosArrowDown size={18} />
                                        )}
                                    </div>
                                </div>
                            </button>
                            {sponsorWorks && (
                                <div className="filter-dropdown-content">
                                    <div className="card-body">
                                        <div className="py-1 pt-0">
                                            <a href="#sponsor_problem&solution" className="my_a">Problem & Solution</a>
                                        </div>
                                        <div className="py-1">
                                            <a href="#sponsor_importance" className="my_a">Importance</a>
                                        </div>
                                        <div className="py-1">
                                            <a href="#sponsor_processflow" className="my_a">SPONSOR – Process Flow</a>
                                        </div>
                                        <div className="py-1">
                                            <a href="#sponsor_benefits" className="my_a">Benefits</a>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <hr className="m-0" />
                        </div>
                        <div>
                            <button
                                className="filter-btn"
                                // onClick={() => setHostWorks(!hostWorks)}
                                onClick={handleHost}
                            >
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <p className="filter-heading mb-0">HOST - How it works?</p>
                                    </div>
                                    <div>
                                        {hostWorks ? (
                                            <IoIosArrowUp size={18} />
                                        ) : (
                                            <IoIosArrowDown size={18} />
                                        )}
                                    </div>
                                </div>
                            </button>
                            {hostWorks && (
                                <div className="filter-dropdown-content">
                                    <div className="card-body">
                                        <div className="py-1 pt-0">
                                            <a href="#host_introduction" className="my_a">Introduction</a>
                                        </div>
                                        <div className="py-1">
                                            <a href="#host_problem&solution" className="my_a">Problem & Solution</a>
                                        </div>
                                        <div className="py-1">
                                            <a href="#host_processflow" className="my_a">HOST – Process Flow</a>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <hr className="m-0" />
                        </div>
                        <div>
                            <button
                                className="filter-btn"
                                // onClick={() => setChampWorks(!champWorks)}
                                onClick={handleChamp}
                            >
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <p className="filter-heading mb-0">Champ - How it works?</p>
                                    </div>
                                    <div>
                                        {champWorks ? (
                                            <IoIosArrowUp size={18} />
                                        ) : (
                                            <IoIosArrowDown size={18} />
                                        )}
                                    </div>
                                </div>
                            </button>
                            {champWorks && (
                                <div className="filter-dropdown-content">
                                    <div className="card-body">
                                        <div className="py-1 pt-0">
                                            <a href="#introduction" className="my_a">Introduction</a>
                                        </div>
                                        <div className="py-1">
                                            <a href="#importance&benefit" className="my_a">Importance and Benefit</a>
                                        </div>
                                        <div className="py-1">
                                            <a href="#problem&solution" className="my_a">Problem & Solution</a>
                                        </div>
                                        <div className="py-1">
                                            <a href="#missedopportunities" className="my_a">Missed Opportunities</a>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <hr className="m-0" />
                        </div>
                    </div>

                    <div className="col-md-9 hiw-content">
                        <div>
                            {whySponsorRingUs &&
                                <>
                                    <div className="my-3 mx-3" id="">
                                        <h3 className="hiw-text-main">Why SponsorRingUs</h3>
                                        <ul className="htw-ul">
                                            <li className="htw-li">To empower event organizers to secure funding and achieve their goals by connecting them with passionate sponsors.</li>
                                            <li className="htw-li">To bridge the gap between events and brands, fostering mutually beneficial partnerships that fuel creativity and innovation.</li>
                                            <li className="htw-li">To democratize event sponsorship, making it accessible to organizers of all levels and giving brands impactful ways to reach new audiences.</li>
                                            <li className="htw-li">To revolutionize the event industry by building a seamless platform for sponsorship collaboration, driving success for both event organizers and brands.</li>
                                            <li className="htw-li">To empower individuals, especially students searching for an earning source, by becoming affiliates (Champs).</li>
                                        </ul>
                                    </div>

                                    <div className="my-3 mx-3" id="whySponsorRingUs_value_proposition">
                                        <h3 className="hiw-text-main">Value Proposition</h3>
                                        <ul className="htw-ul">
                                            <li className="htw-li"><span className="htw-span">Inclusive Platform:</span> Create an inclusive platform accessible to all types of events and organizers, fostering positive social and environmental change while ensuring diversity, accessibility, and inclusivity.</li>
                                            <li className="htw-li"><span className="htw-span">Seamless - Efficient Matching:</span> Connect event organizers with sponsors effortlessly using advanced algorithms and data analytics for precise matchmaking based on demographics, interests, and objectives.</li>
                                            <li className="htw-li"><span className="htw-span">Social Upliftment:</span> Contribute to social upliftment by supporting local events and businesses, showcasing the positive impact of sponsorships on communities and encouraging responsible practices.</li>
                                            <li className="htw-li"><span className="htw-span">Community & Support:</span> Foster a supportive community of event hosts, sponsors, and industry professionals, encouraging collaboration, knowledge-sharing, and positive connections.</li>
                                            <li className="htw-li"><span className="htw-span">Targeted Connections:</span> Facilitate personalized connections by offering sponsors and event hosts the ability to choose from customizable sponsorship packages tailored to their specific needs and budget.</li>
                                            <li className="htw-li"><span className="htw-span">Maximized ROI:</span> Deliver transparent ROI tracking through robust analytics tools, allowing sponsors to monitor real-time performance, reach, engagement, and conversions for optimized future sponsorship strategies.</li>
                                            <li className="htw-li"><span className="htw-span">Network & Visibility:</span> Build extensive networks for event hosts through sponsor’s brand awareness, providing sponsors with hyper-localized exposure to their target market.</li>
                                            <li className="htw-li"><span className="htw-span">Strategic Partnership:</span> Facilitate strategic partnerships between sponsors and event hosts, aligning with their shared goals and objectives for mutual success.</li>

                                        </ul>
                                    </div>
                                    <div className="my-3 mx-3" id="whySponsorRingUs_problem&solution">
                                        <h3 className="hiw-text-main">Problem & Solution</h3>
                                        <ul className="problem-solution p-0">
                                            <div className="prb-sol py-3 text-center d-flex">
                                                <h5 className="col-md-6">Problem</h5>
                                                <h5 className="col-md-6">Solution</h5>
                                            </div>
                                            <ul className="row">
                                                <div className="col-md-6">
                                                    <li className=" problem-solution-li">Lack of structured sponsor matching leads to disorganization and missed opportunities for event organizers.</li>
                                                </div>

                                                <div className="col-md-6">
                                                    <li className=" problem-solution-li">We Implement a smart AI-backed matchmaking algorithm for organized sponsor matching.</li>
                                                </div>
                                            </ul>

                                            <ul className="row">
                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">Limited visibility and time-consuming outreach efforts hinder event promotion effectiveness.</li>
                                                </div>
                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">Enhance visibility and streamline outreach through targeted sponsor recommendations.</li>
                                                </div>
                                            </ul>

                                            <ul className="row">
                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">Challenges in accurately measuring return on investment (ROI) for event sponsorship.</li>
                                                </div>
                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">Introduce community and networking features with feedback mechanisms to improve ROI measurement. Offer sponsorship management tools with a dashboard for reporting and analytics.</li>
                                                </div>
                                            </ul>

                                            <ul className="row">
                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">Low awareness about events due to fragmented information and difficulty in discovering opportunities.</li>
                                                </div>
                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">Provide event promotion and marketing services with brand storytelling tools. Create awareness by leveraging content and educational resources, targeting local businesses and niche markets.</li>
                                                </div>

                                            </ul>
                                            <ul className="row">
                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">The absence of a centralized platform for event management exacerbates coordination issues and promotional efforts.</li>
                                                </div>
                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">Generate opportunities through local community partnerships, fostering social impact and cause marketing initiatives. Establish a centralized platform for events, featuring enhanced event profiles and promotion tools.</li>
                                                </div>
                                            </ul>
                                        </ul>
                                    </div>

                                    <div className="my-3 mx-3" id="whySponsorRingUs_roadmap">
                                        <h3 className="hiw-text-main">SponsorRingUs - Roadmap</h3>
                                        <div className="roadMap-div">
                                            <img src={RoadMap} className="roadMap-img" />
                                        </div>
                                    </div>

                                    <div className="my-3 mx-3" id="whySponsorRingUs_importance">
                                        <h3 className="hiw-text-main">Importance</h3>
                                        <ul className="htw-ul">
                                            <li className="htw-li"><span className="htw-span">User-Friendliness:</span> Enjoy a data-driven platform that is intuitive and easy to navigate. Increase user satisfaction and engagement with seamless interface design.</li>
                                            <li className="htw-li"><span className="htw-span">Efficiency:</span> Simplify the sponsorship process, saving time and effort. Streamline workflow and resource allocation for improved productivity.</li>
                                            <li className="htw-li"><span className="htw-span">Better Connectivity:</span> Connect with sponsors/hosts based on cultural and community aspects, enhancing engagement. Foster meaningful relationships and collaborations within diverse communities.</li>
                                            <li className="htw-li"><span className="htw-span">Personalization:</span> Curate brand sponsorships based on personal preferences. Enhance brand relevance and resonance through targeted sponsorship opportunities.</li>
                                            <li className="htw-li"><span className="htw-span">Hyper-local Reach:</span> Secure sponsorships for even small events like kitty parties, cricket matches, and team dinners. Tap into local markets and communities for enhanced brand visibility.</li>
                                            <li className="htw-li"><span className="htw-span">Support:</span> Access additional help for event organization, such as decorators. Receive tailored assistance and guidance for seamless event execution.</li>
                                            <li className="htw-li"><span className="htw-span">Transparency and Accountability:</span> Ensure fair and standardized practices in sponsorships. Build trust and credibility through transparent sponsorship processes.</li>
                                            <li className="htw-li"><span className="htw-span">Data Collection:</span> Collect valuable insights into event trends, sponsor/host preferences, and sponsorship ROI. Make informed decisions and optimize sponsorship strategies based on data-driven insights</li>
                                            <li className="htw-li"><span className="htw-span">Niche Networks:</span> Build specialized networks tailored to specific industries or interests. Cultivate strong connections within niche communities for targeted sponsorships.</li>
                                            <li className="htw-li"><span className="htw-span">Promoting Local Economy:</span> Contribute to the "Make in India" initiative by fostering local sponsorships and supporting local businesses. Stimulate economic growth and sustainability in local communities.</li>
                                        </ul>
                                    </div>

                                    <div className="my-3 mx-3" id="whySponsorRingUs_benefits">
                                        <h3 className="hiw-text-main">Benefits</h3>
                                        <ul className="htw-ul">
                                            <li className="htw-li"><span className="htw-span">Empowerment:</span> Individuals can monetize their events and turn them into memorable brand experiences. Unlock new revenue streams and opportunities for event organizers.</li>
                                            <li className="htw-li"><span className="htw-span">Analytics:</span> Both parties gain insights into the effectiveness of the collaboration through detailed analytics. Measure and evaluate sponsorship performance for continuous improvement.</li>
                                            <li className="htw-li"><span className="htw-span">Community Building:</span> SponsorRingUs fosters a community where individuals and brands can connect and thrive. Cultivate a supportive ecosystem for networking and collaboration.</li>
                                            <li className="htw-li"><span className="htw-span">Streamlined Process:</span> Easily post event details and sponsorship requirements. Save time and resources with simplified processes and automated workflows.</li>
                                            <li className="htw-li"><span className="htw-span">Community Involvement:</span> Connect and consult within the community for insights. Tap into collective wisdom and expertise for enhanced event planning and execution.</li>
                                            <li className="htw-li"><span className="htw-span">Organized Sponsor Matching:</span> Efficiently match sponsors with suitable events, eliminating the hassle of unorganized processes. Increase sponsorship success rates with targeted matching algorithms.</li>
                                            <li className="htw-li"><span className="htw-span">Aligned Sponsorship Opportunities:</span> Ensure that sponsorship opportunities are well-aligned with the interests and goals of both parties. Maximise mutual benefits and ROI with strategic partnership alignments.</li>
                                            <li className="htw-li"><span className="htw-span">Access to Diverse Events:</span> Explore a wide range of events to find sponsorship opportunities that suit your brand. Expand brand visibility and reach through diverse event sponsorships.</li>
                                            <li className="htw-li"><span className="htw-span">Visibility & Time Management:</span> Save time and increase visibility through streamlined outreach for event organizers. Enhance brand exposure and presence with efficient time management strategies.</li>
                                            <li className="htw-li"><span className="htw-span">Efficient ROI Measurement Tools:</span> Access tools for measuring the return on investment (ROI) of sponsorships, enabling better decision-making. Track and analyse sponsorship performance to optimise ROI and resource allocation.</li>



                                        </ul>
                                    </div>

                                </>
                            }

                            {sponsorWorks &&
                                <>
                                    <div className="my-3 mx-3" id="">
                                        <h3 className="hiw-text-main mx-3">SPONSOR – How it works?</h3>
                                        <div className="mx-3">
                                            <p>Struggling to find the right events for your sponsorships? Forget wasting time finding the perfect event. SponsorMyEvent streamlines the hunt.</p>
                                            <p>Welcome to SponsorRingUs, your gateway to impactful brand collaborations and sponsorships. Whether you're a multinational corporation or a local business, our platform offers you the </p>
                                            <p>opportunity to align your brand with events that resonate with your target audience. From hyperlocal sponsorships to data-driven insights, SponsorRingUs empowers you to maximize your brand exposure and engagement. </p>
                                            <p>We understand the finding a sponsor and negotiating a deal can be a time-consuming and complex process. SponsorRingUS provides tools and resources to streamline this process, including:</p>
                                        </div>

                                        <ul className="htw-ul">
                                            <li className="htw-li"><span className="htw-span">Standardized sponsorship packages:</span> Offer pre-defined packages with varying levels of benefits, making it easier for organizers to present options and for sponsors to understand the return on investment. </li>
                                            <li className="htw-li"><span className="htw-span">Proposal generator:</span> Assist organizers in creating attractive and professional sponsorship proposals to send to potential sponsors.</li>
                                            <li className="htw-li"><span className="htw-span">Communication tools: </span> Facilitate communication and negotiation between organizers and sponsors through messaging features and document sharing. </li>
                                            <li className="htw-li"><span className="htw-span">Payment processing:</span> Secure and efficient online payments.</li>

                                        </ul>
                                    </div>
                                    <div className="my-3 mx-3" id="sponsor_problem&solution">
                                        <h3 className="hiw-text-main">Problem & Solution</h3>
                                        <ul className="problem-solution p-0">
                                            <div className="prb-sol d-flex py-3 text-center">
                                                <h5 className="col-md-6">Problem Statement</h5>
                                                <h5 className="col-md-6">Solution</h5>
                                            </div>
                                            <ul className="row">
                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">Events are disorganized and hard to match.</li>
                                                </div>

                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">We'll organize events for easier matching.</li>
                                                </div>
                                            </ul>
                                            <ul className="row">
                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">Searching and filtering events is difficult.</li>
                                                </div>

                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">We'll use a smart system to help you find what you need.</li>
                                                </div>
                                            </ul>
                                            <ul className="row">
                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">Finding sponsorship opportunities is tough.</li>
                                                </div>

                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">We'll help align opportunities for sponsorship.</li>
                                                </div>
                                            </ul>
                                            <ul className="row">
                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">It's hard to find a variety of events.</li>
                                                </div>

                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">You'll have access to a wide range of events.</li>
                                                </div>
                                            </ul>
                                            <ul className="row">
                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">Small businesses struggle to get noticed.</li>
                                                </div>

                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">We'll increase your visibility.</li>
                                                </div>
                                            </ul>
                                            <ul className="row">
                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">Sponsors find it hard to reach smaller ventures.</li>
                                                </div>

                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">We'll expand your reach.</li>
                                                </div>
                                            </ul>
                                            <ul className="row">
                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">Getting sponsorships is challenging.</li>
                                                </div>

                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">We'll assist you in securing sponsorships.</li>
                                                </div>
                                            </ul>
                                            <ul className="row">
                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">Sponsors struggle to find suitable events and manage sponsorships.</li>
                                                </div>

                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">We'll provide insights and tools based on data.</li>
                                                </div>
                                            </ul>
                                            <ul className="row">
                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">People aren't aware of upcoming events.</li>
                                                </div>

                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">We'll create awareness about events.</li>
                                                </div>
                                            </ul>
                                            <ul className="row">
                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">Opportunities are difficult to come by.</li>
                                                </div>

                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">We'll help generate opportunities for you.</li>
                                                </div>
                                            </ul>
                                        </ul>
                                        <p className="htw-span">Overall, SponsorRingUs aims to create a more efficient and successful ecosystem for event sponsorships, benefiting organizers, sponsors, and ultimately the event attendees themselves. SponsorRingUs aims to bridge the gap between event organizers and sponsors, making it easier for both parties to find mutually beneficial partnerships and achieve their goals.</p>
                                    </div>

                                    <div className="my-3 mx-3" id="sponsor_importance">
                                        <h3 className="hiw-text-main mx-3">Importance</h3>
                                        <ul className="imp-ul">
                                            <li>Importance</li>
                                            <li className="imp-li">A user-friendly platform facilitates intuitive navigation.</li>
                                            <li className="imp-li">Streamlining sponsorship processes saves time and effort.</li>
                                            <li className="imp-li">Transparency ensures fairness and standardization.</li>
                                            <li className="imp-li">Cultural and community aspects enhance engagement.</li>
                                            <li className="imp-li">Valuable data collection offers insights into trends.</li>
                                            <li className="imp-li">Niche networks cater to specific industries or interests.</li>
                                        </ul>
                                    </div>

                                    <div className="my-3 mx-3" id="sponsor_processflow">
                                        <h3 className="hiw-text-main mx-3">SPONSOR – Process Flow</h3>
                                        <div className="roadMap-div">
                                            <img src={SponsorProcessFlow} className="roadMap-img" />
                                        </div>
                                    </div>

                                    <div className="my-3 mx-3" id="sponsor_benefits">
                                        <h3 className="hiw-text-main mx-3">Benefits</h3>
                                        <ul className="imp-ul">
                                            <li className="imp-li">Benefits</li>
                                            <li className="imp-li">Diversity in marketing channels reaches new audiences.</li>
                                            <li className="imp-li">Detailed analytics provide insights into collaboration.</li>
                                            <li className="imp-li">SponsorRingUs fosters a thriving community for connection.</li>
                                            <li className="imp-li">Well-aligned opportunities cater to mutual interests.</li>
                                            <li className="imp-li">Increased awareness generates sponsorship opportunities.</li>
                                            <li className="imp-li">Centralized platform simplifies event and sponsorship management.</li>
                                        </ul>
                                    </div>

                                </>
                            }

                            {hostWorks &&
                                <>
                                    <div className="my-3 mx-3" id="host_introduction">
                                        <h3 className="hiw-text-main mx-3">Introduction:</h3>
                                        <div className="">
                                            <p>Many hosts / event organizers struggle to find sponsors who are a good fit for their event and who can provide the necessary financial support. Finding sponsors can be time–consuming and challenging, especially for smaller events or those with niche audiences.</p>
                                            <p>Welcome to SponsorRingUs, where organizing your dream event and securing sponsorships is made simple. We act as a matchmaker, bringing together event organizers and potential sponsors based on various criteria like target audience, industry, and event goals by having platform where organizers can easily list their events, highlight their target audience and event goals, and showcase the benefits of sponsorship. This makes it easier for sponsors to find relevant events that align with their marketing objectives.</p>
                                            <p>There are tons of problems a host faces when it comes to organizing an event. We at SponsorRingUs make your life easier by helping you delegate your work to sponsors. In doing so, we…</p>
                                        </div>

                                        <ul className="htw-ul">
                                            <li className="htw-li"><span className="htw-span">Increase event visibility:</span> The platform allows hosts / event organizers to create detailed profiles for their events, including information about the target audience, budget, and sponsorship opportunities. This helps potential sponsors easily discover relevant events and assess their fit.</li>
                                            <li className="htw-li"><span className="htw-span">Simplify:</span> SponsorRingUs streamlines the communication process between hosts/ event organizers and potential sponsors. Hosts / Event Organizers can directly message sponsors through the platform, send proposals, and negotiate sponsorship deals.</li>
                                            <li className="htw-li"><span className="htw-span">Provide matchmaking tools:</span> The website employees advanced search and filtering tools to help hosts / event organizers find sponsors that align with their target audience, industry, and budget. This saves them time and effort in identifying suitable partners.</li>
                                        </ul>
                                    </div>

                                    <div className="my-3 mx-3" id="host_problem&solution">
                                        <h3 className="hiw-text-main">Problem & Solution</h3>
                                        <ul className="problem-solution p-0">
                                            <div className="prb-sol d-flex py-3 text-center">
                                                <h5 className="col-md-6">Problem</h5>
                                                <h5 className="col-md-6">Solution</h5>
                                            </div>
                                            <ul className="row">
                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">Unorganized Sponsor Matching  </li>
                                                </div>

                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">We Implement a smart AI-backed matchmaking algorithm for organized sponsor matching.</li>
                                                </div>
                                            </ul>
                                            <ul className="row">
                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">Limited visibility and time-consuming outreach efforts hinder event promotion effectiveness.</li>
                                                </div>

                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">Enhance visibility and streamline outreach through targeted sponsor recommendations.</li>
                                                </div>
                                            </ul>
                                            <ul className="row">
                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">Challenges in accurately measuring return on investment (ROI) for event sponsorship.</li>
                                                </div>

                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">Introduce community and networking features with feedback mechanisms to improve ROI measurement. Offer sponsorship management tools with a dashboard for reporting and analytics.</li>
                                                </div>
                                            </ul>
                                            <ul className="row">
                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">Low awareness about events due to fragmented information and difficulty in discovering opportunities.</li>
                                                </div>

                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">Provide event promotion and marketing services with brand storytelling tools. Create awareness by leveraging content and educational resources, targeting local businesses and niche markets.</li>
                                                </div>
                                            </ul>
                                            <ul className="row">
                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">The absence of a centralized platform for event management exacerbates coordination issues and promotional efforts.</li>
                                                </div>

                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">Generate opportunities through local community partnerships, fostering social impact and cause marketing initiatives. Establish a centralized platform for events, featuring enhanced event profiles and promotion tools.</li>
                                                </div>
                                            </ul>
                                        </ul>
                                    </div>

                                    <div className="my-3 mx-3" id="host_processflow">
                                        <h3 className="hiw-text-main mx-3">HOST – Process Flow</h3>
                                        <div className="roadMap-div">
                                            <img src={HostProcessFlow} className="roadMap-img" />
                                        </div>
                                    </div>
                                </>

                            }

                            {champWorks &&
                                <>
                                    <div className="my-3 mx-3" id="introduction">
                                        <h3 className="hiw-text-main">Introduction</h3>
                                        <p>In today's fast-paced world, event organizers often struggle to find sponsors, leading to setbacks and frustration. But SponsorRingUs is changing the game. Using referral networking, we connect partners, known as 'Champs', with eager sponsors & hosts, offering a streamlined solution to the age-old challenge of securing event funding</p>
                                        <p>In this guide, we'll take a closer look at the special challenges faced by Champs and how SponsorRingUs provides a solution tailored just for them. From navigating the maze of sponsor acquisition to making the most of sponsor support, we'll show you how our platform empowers Champs to take their events to the next level.</p>
                                        <p>Welcome to SponsorRingUs, Champs! As advocates for brand collaborations and sponsorships, you play a vital role in connecting organizers and sponsors for mutually beneficial partnerships. Get ready to embark on a journey of growth and opportunity with SponsorRingUs.</p>
                                    </div>

                                    <div className="my-3 mx-3" id="importance&benefit">
                                        <h3 className="hiw-text-main">Importance and Benefit</h3>
                                        <ul className="problem-solution p-0">
                                            <div className="prb-sol d-flex py-3 text-center">
                                                <h5 className="col-md-6">Importance</h5>
                                                <h5 className="col-md-6">Benefits</h5>
                                            </div>
                                            <ul className="row">
                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">SponsorRingUs connects champs with sponsors for growth.</li>
                                                </div>

                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">Champs earn commissions and expand reach.</li>
                                                </div>
                                            </ul>
                                            <ul className="row">
                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">Champs network with industry pros for career growth.</li>
                                                </div>

                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">Networking leads to partnerships and opportunities.</li>
                                                </div>
                                            </ul>
                                            <ul className="row">
                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">Access to diverse sponsors boosts revenue generation.</li>
                                                </div>

                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">Practical experience enhances skills and expertise.</li>
                                                </div>
                                            </ul>
                                            <ul className="row">
                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">Champions build reputation and gain recognition.</li>
                                                </div>

                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">Flexibility allows for effective time management.</li>
                                                </div>
                                            </ul>
                                            <ul className="row">
                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">Reliable income source with opportunities for growth.</li>
                                                </div>

                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">Professional growth and career advancement opportunities.</li>
                                                </div>
                                            </ul>
                                        </ul>
                                    </div>

                                    <div className="my-3 mx-3" id="problem&solution">
                                        <h3 className="hiw-text-main">Problem & Solution</h3>

                                        <ul className="problem-solution p-0">
                                            <div className="prb-sol d-flex py-3 text-center">
                                                <h5 className="col-md-6">Problem</h5>
                                                <h5 className="col-md-6">Solution</h5>
                                            </div>
                                            <ul className="row">
                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">Champs' efforts go unnoticed, lacking recognition.</li>
                                                </div>

                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">SponsorRingUs showcases champs' achievements, boosting visibility on a dedicated platform.</li>
                                                </div>
                                            </ul>
                                            <ul className="row">
                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">New champs lack mentorship and guidance.</li>
                                                </div>

                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">SponsorRingUs offers mentorship programs led by experienced champs, providing valuable support.</li>
                                                </div>
                                            </ul>
                                            <ul className="row">
                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">Champs struggle to access resources and tools.</li>
                                                </div>

                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">SponsorRingUs provides a resource library and toolkits, empowering champs with effective strategies.</li>
                                                </div>
                                            </ul>
                                            <ul className="row">
                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">Communication with champs is inconsistent.</li>
                                                </div>

                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">SponsorRingUs ensures regular updates and transparent channels for clear communication.</li>
                                                </div>
                                            </ul>
                                            <ul className="row">
                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">Champs find it hard to showcase expertise.</li>
                                                </div>

                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">SponsorRingUs offers personalized profiles for champs to highlight skills and achievements.</li>
                                                </div>
                                            </ul>
                                            <ul className="row">
                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">Champs often lose motivation and engagement.</li>
                                                </div>

                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">SponsorRingUs implements incentive programs to maintain champs' motivation and engagement.</li>
                                                </div>
                                            </ul>
                                            <ul className="row">
                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">Collaboration and knowledge sharing is limited.</li>
                                                </div>

                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">SponsorRingUs fosters collaboration through community forums and networking events.</li>
                                                </div>
                                            </ul>
                                            <ul className="row">
                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">Champs' contributions aren't adequately recognized.</li>
                                                </div>

                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">SponsorRingUs implements a feedback mechanism for event organizers to recognize champs' efforts.</li>
                                                </div>
                                            </ul>
                                            <ul className="row">
                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">Networking opportunities for champs are limited.</li>
                                                </div>

                                                <div className="col-md-6">
                                                    <li className="problem-solution-li">SponsorRingUs grants exclusive access to networking events, expanding champs' professional connections.</li>
                                                </div>
                                            </ul>
                                        </ul>
                                    </div>

                                    <div className="my-3 mx-3" id="missedopportunities">
                                        <h3 className="hiw-text-main">Missed Opportunities</h3>
                                        <ul className="htw-ul">
                                            <li className="htw-li">If partners (Champs) don't use SponsorRingUs fully, they might miss chances to work with sponsors and grow their careers.</li>
                                            <li className="htw-li">Partners (Champs) who aren't active on the platform might struggle to be seen by sponsors and event organizers, making it hard to expand their connections.</li>
                                            <li className="htw-li">Success as a partners (Champs) can be affected by things like market trends and competition, which can impact earnings and career growth.</li>
                                            <li className="htw-li">Being a partners (Champs) takes hard work and dedication, and not using SponsorRingUs properly could mean wasted time and effort.</li>
                                            <li className="htw-li">Not using the platform might make partners (Champs) seem less trustworthy to sponsors and organizers who are active on it.</li>
                                            <li className="htw-li">Without SponsorRingUs, it's harder for partners (Champs) to keep track of their marketing and might lead to less success.</li>
                                            <li className="htw-li">Partners (Champs) miss out on helpful tools and tutorials provided by SponsorRingUs if they don't use the platform.</li>
                                            <li className="htw-li">Partners (Champs) might struggle to prove their success and report accurately without SponsorRingUs, leading to payment issues</li>
                                            <li className="htw-li">Partners (Champs) have less say in how transparent and reliable their payments are without using SponsorRingUs.</li>

                                        </ul>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div> */}
            </div >
     
        </>
    );
};

export default HowitWorks;
