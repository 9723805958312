import EventCard from "./RecommendedEventCard";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import React, { useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import errow from "../../assets/images/arrow.svg";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Link } from "react-router-dom";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";

const Recommended = ({ recommendedEvents }) => {
  const [isHovered, setIsHovered] = useState(false);
  const MangerecommendedEvents = recommendedEvents?.slice(0, 8);
  const ConfigData = useSelector((state) => state?.config_data?.configData);
  const baseUrls = ConfigData?.base_urls;

  const breakpoints = {
    300: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    500: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    1024: {
      slidesPerView: 4,
      spaceBetween: 20,
    },
    1200: {
      slidesPerView: 5,
      spaceBetween: 30,
    },
    1600: {
      slidesPerView: 5,
      spaceBetween: 30,
    },
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <>
      <div className="event-near">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center gap-1 position-relative">
              <h2 className="event-heading mb-0 d-flex flex-column flex-md-row align-items-md-center gap-md-2">Recommended</h2>
              <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <IoMdInformationCircleOutline size={23} />
              </div> {isHovered && (
                <div className="info-popup" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                  <p className="info-popup-text">Just for You!</p>
                </div>
              )}
            </div>
            {MangerecommendedEvents?.length > 5 && <button className="view-all-btn">
              <div className="d-flex gap-3 align-items-center ">
                <Link to="/events/Recommended" className="mb-0 viewall-btn-p">View All  <img className="" src={errow} alt="img" /></Link>
              </div>
            </button>}

          </div>
          <div className="mt-3">
            <Swiper
              className=""
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              // spaceBetween={20}
              // slidesPerView={5}
              breakpoints={breakpoints}
              navigation
              loop={false}
              effect="fade"
              speed={500}
            >
              <div className="even-card-div">
                {
                  MangerecommendedEvents && MangerecommendedEvents?.map((val) => {
                    return (
                      <SwiperSlide key={val?.id}>
                        <EventCard baseUrls={baseUrls} val={val} />
                      </SwiperSlide>
                    )
                  })
                }
                {/* {MangerecommendedEvents?.length > 5 &&
                  <SwiperSlide>
                    <Link to="/events/Recommended" className="seeAll-eventcard">
                      <div className="viewall-btn-p-for-seeAll">View All <br />
                        <img className="" src={errow} alt="img" /></div>
                    </Link>
                  </SwiperSlide>
                } */}
              </div>
            </Swiper>
          </div>
        </div>
      </div >
    </>
  );
};

export default Recommended;
