import emptyevent from "../../assets/images/empty-events.svg";
import { FaRegCalendarAlt } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Delete_Event, Get_EventBenefit, get_my_event } from "../../utils/myevent/MyEvent";
import MyEventCard from "../../components/MyEvent/MyEventcard";
import { Circles } from "react-loader-spinner";
import Loader from "../../components/Loader/Loader";
import { FaListUl } from "react-icons/fa6";
import { BsFillGridFill } from "react-icons/bs";
import MyEventsListCard from "../../components/MyEvent/EventsListCard";
import ReactPaginate from "react-paginate";
import Loader2 from "../../components/Loader/Loader2";
import SortSection from "../../components/MyEvent/SortSection";
import FilterSection from "../../components/MyEvent/FilterSection";
import { getEventTypeData } from "../../utils/basicInformation/basicinformationManager";
import { ToastContainer, toast } from "react-toastify";
import ApproveListCard from "../../components/MyEvent/ApproveListCard";
import { MdFilterListAlt, MdOutlineSort } from "react-icons/md";
import { useSelector } from "react-redux";
import IsHostSponsorPopup from "../../components/Common/IsHostSponsorPopup";
import { Get_CityList, Get_EducationList, Get_HouseholdIncome, Get_LanguageList, Getinterests } from "../../utils/event/GetEventList";
import { GetEventBudget } from "../../utils/CreateEvents/createEvent";
import { isTokenValid } from "../../AuthbyToken";
import CreateEventExpressAndAdvancedMdl from "../../components/Common/CreateEvent/CreateEventExpressAndAdvancedMdl";

const MyEvents = () => {
  const params = useParams();
  const isValid = isTokenValid();
  const [myEvent, setMyEvent] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [eventLoading, setEventLoading] = useState(true);
  const [search_event, setSearch] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [searchError, setSearchError] = useState("");
  const [cardView, setCardView] = useState("grid");
  const [isShow, setIsShow] = useState(false);
  const someFilterHide = false;
  const event_status = params?.event_status;

  const [currentPage, setCurrentPage] = useState(1);
  // sort & filter
  const [sortVal, setSortVal] = useState("");
  const [type, setType] = useState([]);
  const [eventRange, setEventRange] = useState({ min: "", max: "" });
  const [eventType, setEventType] = useState("");
  const [eventMode, setEventMode] = useState("");
  const [eventCategory, setEventCategory] = useState("");
  const [status, setStatus] = useState("");
  const [openFilterBar, setOpenFilterBar] = useState(false);
  const [openSortBar, setOpenSortBar] = useState(false);
  // FilterDrop 
  const [rangeDrop, setRangeDrop] = useState(true);
  const [typeDrop, setTypeDrop] = useState(true);
  const [eventModeDrop, setEventModeDrop] = useState(true);
  const [eventCategoryDrop, setEventCategoryDrop] = useState(true);
  const [statusDrop, setStatusDrop] = useState(true);
  const [eventTypeDrop, setEventTypeDrop] = useState(true);
  const [GuestMaritalStatusDrop, setGuestMaritalStatusDrop] = useState(true);

  const [guestProfessionDrop, setGuestProfessionDrop] = useState(true);
  const [guestIndustryDrop, setGuestIndustryDrop] = useState(true);
  const [guestTechnologyProficiencyDrop, setGuestTechnologyProficiencyDrop] = useState(true);
  const [GuestSocialMediaUsageDrop, setGuestsSocialMediaUsageDrop] = useState(true);
  // new filter
  const [interestDrop, setInterestDrop] = useState(true);
  const [predefineHastag, setPredefineHastag] = useState([]);
  const [selectedHastags, setSelectedHastag] = useState([]);
  const [hastag, setHastag] = useState([]);
  const [srt_Evt_DateDrop, setSrt_Evt_DateDrop] = useState(true)
  const [srt_Evt_Date, setSrt_Evt_Date] = useState({ start: '', end: '' })
  const [genderDrop, setGenderDrop] = useState(true)
  const [selectedGenders, setSelectedGenders] = useState('');
  const [minAgeDrop, setMinAgeDrop] = useState(true)
  const [minMaxAge, setMinMaxAge] = useState({ min: '', max: '' });
  const [distanceDrop, setDistanceDrop] = useState(true);
  const [distanceRange, setDistanceRange] = useState("");
  const [primeHostDrop, setPrimeHostDrop] = useState(true);
  const [premierDrop, setPremierDrop] = useState(true)
  const [prime_host, setPrime_host] = useState('');
  const [premier, setPremier] = useState('')
  const [cityDrop, setCityDrop] = useState(true)
  const [predefineCities, setPredefineCities] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);

  const [languageDrop, setLanguageDrop] = useState(true)
  const [predefineLanguage, setPredefineLanguage] = useState([]);
  const [language, setLanguage] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [selectedGuestAge, setSelectedGuestAge] = useState([]);
  const [selectedGenderGroup, setSelectedGenderGroup] = useState([]);
  const [selectedGuestHouseIncome, setSelectedGuestHouseIncome] = useState([]);
  const [selectedGuestEducation, setSelectedGuestEducation] = useState([]);
  const [selectedGuestMaritalStatus, setSelectedGuestMaritalStatus] = useState([]);
  const [selectedGuestProfession, setSelectedGuestProfession] = useState([]);
  const [selectedGuestIndustry, setSelectedGuestIndustry] = useState([]);
  const [selectedGuestTechnologyProficiency, setSelectedGuestTechnologyProficiency] = useState([]);
  const [selectedGuestSocialMediaUsage, setSelectedGuestsSocialMediaUsage] = useState([]);

  const [SponsorshipDrop, setSponsorshipDrop] = useState(true);
  const [SponsorshipDate, setSponsorshipDate] = useState({ start: '', end: '' });
  const [SponsorshipClosingDate, setSponsorshipClosingDate] = useState({ start: '', end: '' });
  const [durationDrop, setDurationDrop] = useState(true);
  const [durationValue, setDurationValue] = useState('');
  const [no_ParticipantsDrop, setNo_ParticipantsDrop] = useState(true)
  const [no_Participants, setNo_Participants] = useState({ min: '', max: '' });
  const [archive_Type, setArchive_Type] = useState("");
  const [isEventEnded, setIsEventEnded] = useState(false);
  const [house_Hold_IncomeDrop, setHouse_Hold_IncomeDrop] = useState(true);
  const [house_Hold_Income, setHouse_Hold_Income] = useState([]);
  const [selectHouse_Hold_Income, setSelectHouse_Hold_Income] = useState("");
  const [educationDrop, setEducationDrop] = useState(true);
  const [educationValue, setEducationValue] = useState([]);
  const [selectEducationValue, setSelectEducationValue] = useState("");
  const [eventBudget, setEventBudget] = useState([]);
  const [selectEventBudget, setSelectEventBudget] = useState("");
  const [eventBudgetValue, setEventBudgetValue] = useState("");
  const [showExpressAndAdvancedMdl,setShowExpressAndAdvancedMdl]=useState(false);
  const [expressBenefit,setExpressBenefit]=useState([]);
  const [advanceBenefit,setAdvanceBenefit]=useState([]);

  const navigate = useNavigate()
  const [showPopup, setShowPopup] = useState(false)

  const ConfigData = useSelector((state) => state?.config_data?.configData);
  const profileData = useSelector((state) => state?.Profile_data?.ProfileData);

  const baseUrls = ConfigData?.base_urls;

  const getMyEvent = async () => {
    const get_let_long = localStorage.getItem('let_long');
    const let_log = JSON.parse(get_let_long)
    const hastgg = JSON.stringify(hastag);
    const cityS = JSON.stringify(cities);
    const languages = JSON.stringify(language);
    const guestAge = JSON.stringify(selectedGuestAge);
    const genderGroup = JSON.stringify(selectedGenderGroup);
    const guestHouseIncome = JSON.stringify(selectedGuestHouseIncome);
    const guestEducation = JSON.stringify(selectedGuestEducation);
    const guestMaritalStatus = JSON.stringify(selectedGuestMaritalStatus);
    const guestProfession = JSON?.stringify(selectedGuestProfession);
    const guestIndustry = JSON?.stringify(selectedGuestIndustry);
    const guestTechnologyProficiency = JSON?.stringify(selectedGuestTechnologyProficiency);
    const guestSocialMediaUsage = JSON?.stringify(selectedGuestSocialMediaUsage);

    const get_lat_long = localStorage.getItem('let_long');
    const lat_long = JSON.parse(get_lat_long);
    setEventLoading(true);
    try {
      const response = await get_my_event(event_status, eventMode, eventType, eventRange, currentPage, search_event, sortVal, lat_long, eventCategory, status, hastgg,
        no_Participants, srt_Evt_Date, selectedGenders, minMaxAge, distanceRange, prime_host, cityS, languages, SponsorshipClosingDate, durationValue, premier, archive_Type,
        selectHouse_Hold_Income, selectEducationValue, eventBudgetValue, guestAge, genderGroup, guestHouseIncome, guestEducation, guestMaritalStatus, guestProfession, guestIndustry, guestTechnologyProficiency,
        guestSocialMediaUsage);
      const data = response?.data?.data;
      setMyEvent(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setEventLoading(false);
    }
  };

  useEffect(() => {
    if (isValid) {
      getMyEvent();
    }
  }, [event_status, eventMode, eventType, eventRange, currentPage, sortVal, eventCategory, status, hastag, no_Participants, srt_Evt_Date, selectedGenders, minMaxAge,
    distanceRange, prime_host, cities, language, SponsorshipClosingDate, durationValue, premier, archive_Type, selectHouse_Hold_Income, selectEducationValue, eventBudgetValue,
    selectedGuestAge, selectedGenderGroup, selectedGuestHouseIncome, selectedGuestEducation, selectedGuestMaritalStatus, selectedGuestProfession,
    selectedGuestIndustry, selectedGuestTechnologyProficiency, selectedGuestSocialMediaUsage]);

  useEffect(() => {
    setCurrentPage(1);
    if (event_status == "Approved" || cardView == "list") {
      setOpenFilterBar(false)
    } else {
      setOpenFilterBar(true)
    }
  }, [event_status]);

  // console.log("openFiltr",openFilterBar)

  const getEventType = async () => {
    try {
      await getEventTypeData().then((response) => {
        setType(response?.data);
      });
    } catch (error) {
      console.log(error);
    }
  }

  const getHastag = async () => {
    try {
      await Getinterests().then((response) => {
        const getInterest = response?.data?.data;
        setPredefineHastag(getInterest);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const GetLanguage = async () => {
    try {
      const response = await Get_LanguageList();
      setPredefineLanguage(response?.data?.data)
    } catch (err) {
      console.log("err", err)
    }
  }

  const getCity = async () => {
    try {
      const response = await Get_CityList();
      setPredefineCities(response?.data?.data)
    } catch (err) {
      console.log("err", err)
    }
  }

  const GetHouseholdIncome = async () => {
    try {
      const response = await Get_HouseholdIncome();
      setHouse_Hold_Income(response?.data?.data);
    } catch (err) {
      console.log(err)
    }
  }

  const GetEducationList = async () => {
    try {
      const response = await Get_EducationList();
      setEducationValue(response?.data?.data);
    } catch (err) {
      console.log(err);
    }
  }

  const getEventBudgetList = async () => {
    try {
      await GetEventBudget().then((response) => {
        setEventBudget(response?.data?.data)
      })
    } catch (err) {
      console.log(err)
    }
  }

  const GetEventBenefit=async()=>{
    try{
      const response = await Get_EventBenefit();
      const data = response?.data?.data;
      const expressEvents = data?.filter(ben => ben?.event_type === 'Express Event');
      const advanceEvents = data?.filter(ben => ben?.event_type === 'Advance Event');  
       setExpressBenefit(expressEvents);
       setAdvanceBenefit(advanceEvents);
      
       // const [expressBenefit,setExpressBenefit]=useState([]);
       // const [advanceBenefit,setAdvanceBenefit]=useState([]);
    }catch(err){
      console.log(err)
    }
  }
  // console.log("advanceBenefit",advanceBenefit,expressBenefit)

  useEffect(() => {
    getHastag();
    getEventType();
    getCity();
    GetLanguage();
    getEventBudgetList();
    GetHouseholdIncome();
    GetEducationList();
    GetEventBenefit();
  }, []);

  const selectedLanguageFilter = () => {
    const langNames = language?.map(name => name?.toLowerCase());
    const filterCities = predefineLanguage?.filter(lag => langNames?.includes(lag.toLowerCase()));
    setSelectedLanguage(filterCities);
  }

  useEffect(() => {
    if (predefineLanguage) {
      selectedLanguageFilter()
    }
  }, [language, predefineLanguage]);


  const selectedCityFilter = () => {
    const cityNames = cities?.map(id => id);
    const filterCities = predefineCities?.filter(tag => cityNames?.includes(tag?.city_id));
    setSelectedCities(filterCities);
  }

  useEffect(() => {
    if (predefineCities) {
      selectedCityFilter();
    }
  }, [cities, predefineCities]);

  const selectedHastagFilter = () => {
    const hastagIDs = hastag?.map(id => parseInt(id));
    const filterHas = predefineHastag?.filter(tag => hastagIDs?.includes(tag.id));
    setSelectedHastag(filterHas)
  }

  useEffect(() => {
    if (predefineHastag) {
      selectedHastagFilter()
    }
  }, [hastag, predefineHastag]);

  const handleSearch = (e) => {
    e.preventDefault();
    if (isSearch) {
      getMyEvent();
      setIsSearch(false)
    } else {
      if (!search_event || !search_event.trim() === "") {
        setSearchError("Invalid search input");
      } else {
        getMyEvent();
        setIsSearch(true)
        setSearchError("");
        setSearch("");
      }
    }

  };

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  const handleDeleteEvent = async (id) => {
    try {
      const response = await Delete_Event(id);
      if (response?.data?.success) {
        toast.success(response?.data?.message, {
          position: "top-center",
          autoClose: 3000,
        });
        getMyEvent();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: "top-center",
        autoClose: 3000,
      });
    }
  }

  const handleTabChange = () => {
    setMyEvent([]);
    setArchive_Type("");
    setOpenFilterBar(false)
  }

  const handleSearchChange = (e) => {
    const SVal = e.target.value;
    if (SVal?.length > 0) {
      setIsSearch(false)
    }
    setSearch(e.target.value)
  }

   useEffect(() => {
    const handleResize = () => {
     if (window.innerWidth < 1200) {
       setOpenFilterBar(false);
     } else {
       setOpenFilterBar(true);
     }
   };
    // Call the function initially to set the state based on the current window width
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleHostProfile = () => {
    if(profileData?.about_me_section) {
      //navigate('/CreateEvent/create')
      setShowExpressAndAdvancedMdl(!showExpressAndAdvancedMdl);
    } else {
      setShowPopup(true)
    }
  }

  //  console.log("express",expressBenefit)
  return (
    <>
    {
     showExpressAndAdvancedMdl &&
     <CreateEventExpressAndAdvancedMdl setShowExpressAndAdvancedMdl={setShowExpressAndAdvancedMdl} 
      advanceBenefit={advanceBenefit} expressBenefit={expressBenefit}/>
    }
    
    {/* popup start*/}
      {showPopup &&
        <div className="logout-popup-overlay">
             <div className="logout-popup">
               <p>Before creating a new event, please complete the host profile section in <b>'My Profile'</b>.</p>
               <div className="d-flex gap-3 justify-content-center">
               <button onClick={() => navigate(`/editprofile/${profileData?.id}`)}
                 className="ok-button">Go to Profile</button>
               <button onClick={() => setShowPopup(false)}
                 className="ok-button">OK</button>
                </div>
              </div>
        </div>
      }

     {/* popup end*/}
      <ToastContainer />
      {Loading ? (
        <Loader />
        ) : (
        <div className="your-events container-lg z-1">
          <div className="row align-items-center">
            <div className="col-5 col-sm-4 col-md-3">
              {profileData?.user_type ?
                <h2 className="events-heading mb-0">Manage Events</h2>
                 :
                <h2 className="events-heading mb-0">{profileData.i_want_to == "is_host" ? "My Events" : "My Sponsored" } </h2>
              }
            </div>

            <div className="d-none d-md-block col-sm-4 col-md-4 col-xl-5">
              <form onSubmit={handleSearch}>
                <div className="d-flex search-div align-items-center position-relative">
                  <input
                    className="event-search-input "
                    name="search_event"
                    value={search_event}
                    placeholder="Search Events"
                    onChange={handleSearchChange}
                  />
                  <button className={isSearch ? "event-search-clear-button" : "event-search-button"} type="submit">
                    {isSearch ? 'Clear' : `Search`}
                    <svg fill="currentColor" viewBox="0 0 24 24" className="icon">
                      <path clip-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z" fill-rule="evenodd"></path>
                    </svg>
                  </button>
                </div>
              </form>
              {searchError && (
                <p className="error-message text-danger ms-1">{searchError}</p>
              )}
            </div>

            <div className="col col-md-4  col-xl-3 d-flex  justify-content-xl-center justify-content-xxl-end pe-xl-4 gap-2 align-items-center">
              {profileData.i_want_to == "is_host" &&
              <div>
               <button className="btn Create-new-Event" onClick={handleHostProfile}>
                 <FaRegCalendarAlt size={16}/>Create New Event
               </button>
              </div>
               // <div>
                //   <Link to={"/CreateEvent/create"} className="btn Create-new-Event">
                //     <FaRegCalendarAlt size={16} />Create New Event
                //   </Link>
                // </div>
                // :
                // <div className="position-relative">
                //   <button
                //     onClick={() => setIsShow(true)}
                //     className="btn Create-new-Event">
                //     <FaRegCalendarAlt size={16} />Create New Event
                //   </button>

                //   <div className="position-absolute">
                //     <IsHostSponsorPopup type={'host'} isShow={isShow} setIsShow={setIsShow} />
                //   </div>
                // </div>
              }
            </div>

            <div className="d-none d-lg-block gap-2 col-md-1 d-flex justify-content-end gap-2 align-items-center">
              <div className="d-flex justify-content-end">
                <button
                  className={
                    cardView == "grid"
                      ? "event-grid-btn-actv"
                      : "event-grid-btn"}
                  onClick={() => { setCardView("grid"); setOpenFilterBar(true) }}>
                  <BsFillGridFill size={24} />
                </button>
                <button
                  className={
                    `${cardView == "list"
                      ? "event-list-btn-actv"
                      : "event-list-btn"}`}
                  onClick={() => { setCardView("list"); setOpenFilterBar(false) }}>
                  <FaListUl size={24} />
                </button>
              </div>
            </div>

            <div className="d-flex justify-content-center d-md-none col-sm-12 mt-4 col-md-5">
              <div className="d-flex search-div">
                <input
                  className="form-control search-event-input"
                  name="search"
                  value={search_event}
                  placeholder="Search Events"
                  onChange={(e) => setSearch(e.target.value)} />
                <button
                  className="d-flex align-items-center gap-1 event-search-btn"
                  onClick={handleSearch}>
                  Search
                </button>
              </div>
              {searchError && (
                <p className="error-message text-danger ms-1">{searchError}</p>
              )}
            </div>
          </div>

          {/* <div className="row ">
            <div className="col-md-3">
              <h2 className="your-events-heading">My Events</h2>
            </div>
    
            <div className=" d-none d-md-block col-md-4 col-xl-5">
              <form onSubmit={handleSearch}>
                <div className="d-flex search-div">
                  <input
                    className="form-control search-event-input"
                    name="search_event"
                    value={search_event}
                    placeholder="Search Events..."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <button className="event-search-btn" type="submit">
                    Search
                  </button>
                </div>
              </form>
              {searchError && (
                <p className="error-message text-danger ms-1">
                  {searchError}
                </p>
              )}
            </div>

            <div className="col-xl-4 col-md-5 d-flex justify-content-end gap-2 align-items-center">
              <div>
                <Link
                  to={"/CreateEvent/create"}
                  className="btn Create-new-Event">
                  <FaRegCalendarAlt size={18}/>Create New Event
                </Link>
              </div>
              <div className="d-flex">
                <button
                  className={
                    cardView == "grid"
                      ? "event-grid-btn-actv"
                      : "event-grid-btn"}
                  onClick={() => setCardView("grid")}>
                  <BsFillGridFill size={24} />
                </button>
                <button
                  className={
                    cardView == "list"
                      ? "event-list-btn-actv"
                      : "event-list-btn" }
                   onClick={() => setCardView("list")}>
                  <FaListUl size={24} />
                </button> 
              </div>
            </div>

              <div className="d-flex justify-content-center d-sm-none col-md-6 mt-4 col-md-5">
              <div className="d-flex search-div">
                <input
                  className="form-control search-event-input"
                  name="search"
                  value={search_event}
                  placeholder="Search Events..."
                  onChange={(e) => setSearch(e.target.value)}
                />
                <button
                  className="d-flex align-items-center gap-1 event-search-btn"
                  onClick={handleSearch}>
                  Search
                </button>
              </div>
              {searchError && (
                <p className="error-message text-danger ms-1">{searchError}</p>
              )}
            </div>
          </div> */}

          {/* {myEvent?.data?.length <= 0 ? (
            <div className="your-event-content">
              <div className="yourevent-content-div">
                <img
                  className="emptyevent-image"
                  src={emptyevent}
                  alt="empty"
                />
                <p className="Looks-like-you-haven">
                  Looks like you haven't added any events yet! Don't worry, it's
                  easy to get started. Click on the
                  <span className="text-style-1">‘Create New Event'</span>button
                  to begin creating memorable moments.
                </p>
                <Link to="/newevent" className="btn Create-new-Event">
                  <FaRegCalendarAlt size={18} /> Create New Event
                </Link>
              </div>
            </div>
          ) : ( */}


          <>
            {/* Tab Button*/}
            {profileData?.i_want_to == "is_host" ?
            <div className="mt-3 d-none d-xl-flex">
              <div className="d-flex gap-3 py-3">
                <div className="d-flex flex-column align-items-center">
                  <Link
                    to="/myevents/all"
                    onClick={handleTabChange}
                    className={
                      event_status == "all"
                        ? "select-myevent-tab"
                        : "myevent-tab"}>
                    All Events
                  </Link>
                  {/* {event_status == "all" ? (
                    <div className="tab-bottom-line"></div>
                  ) : (
                    ""
                  )} */}
                </div>

                <div className="d-flex flex-column align-items-center">
                  <Link
                    to="/myevents/Draft"
                    onClick={handleTabChange}
                    className={
                      event_status == "Draft"
                        ? "select-myevent-tab"
                        : "myevent-tab"}>
                    Draft
                  </Link>
                  {/* {event_status == "Draft" ? (
                    <div className="tab-bottom-line"></div>
                  ) : (
                    ""
                  )} */}
                </div>

                <div className=" d-flex flex-column align-items-center">
                  <Link
                    to="/myevents/Under_Review"
                    onClick={handleTabChange}
                    className={
                      event_status == "Under_Review"
                        ? "select-myevent-tab"
                        : "myevent-tab"
                    }>
                    In Review
                  </Link>
                  {/* {event_status == "Under_Review" ? (
                    <div className="tab-bottom-line"></div>
                  ) : (
                    ""
                  )} */}
                </div>

                <div className=" d-flex flex-column align-items-center">
                  <Link
                    to="/myevents/Rejected"
                    onClick={handleTabChange}
                    className={
                      event_status == "Rejected"
                        ? "select-myevent-tab"
                        : "myevent-tab"
                    }>
                    Rejected
                  </Link>
                  {/* {event_status == "Rejected" ? (
                    <div className="tab-bottom-line"></div>
                  ) : (
                    ""
                  )} */}
                </div>

                <div className=" d-flex flex-column align-items-center">
                  <Link
                    to="/myevents/Approved"
                    onClick={handleTabChange}
                    className={
                      event_status == "Approved"
                        ? "select-myevent-tab"
                        : "myevent-tab"
                    }>
                    Approved
                  </Link>
                  {/* {event_status == "Approved" ? (
                    <div className="tab-bottom-line"></div>
                  ) : (
                    ""
                  )} */}
                </div>

                <div className=" d-flex flex-column align-items-center">
                  <Link
                    to="/myevents/Published"
                    onClick={handleTabChange}
                    className={
                      event_status == "Published"
                        ? "select-myevent-tab"
                        : "myevent-tab"
                    }>
                    Published
                  </Link>
                  {/* {event_status == "Published" ? (
                    <div className="tab-bottom-line"></div>
                  ) : (
                    ""
                  )} */}
                </div>

                <div className=" d-flex flex-column align-items-center">
                  <Link
                    to="/myevents/Archived"
                    onClick={handleTabChange}
                    className={
                      event_status == "Archived"
                        ? "select-myevent-tab"
                        : "myevent-tab"
                    }>
                    Event Archives
                  </Link>
                  {/* {event_status == "Published" ? (
                    <div className="tab-bottom-line"></div>
                  ) : (
                    ""
                  )} */}
                </div>

                <div className=" d-flex flex-column align-items-center">
                  <Link
                    to="/myevents/Past_Event"
                    onClick={handleTabChange}
                    className={
                      event_status == "Past_Event"
                        ? "select-myevent-tab"
                        : "myevent-tab"
                    }>
                    Past Events
                  </Link>
                  {/* {event_status == "Past_Event" ? (
                    <div className="tab-bottom-line"></div>
                  ) : (
                    ""
                  )} */}
                </div>
              </div>
            </div>
            :""}

            {/*Filter Section  */}
            <div className="row position-relative mt-3">

              <div className="">
                <button className={cardView == "list" || event_status == 'Approved' ? `${openFilterBar ? "active-side-filter-button" : "side-filter-button"} mt-2 d-block` : "d-none"}
                  onClick={() => { setOpenFilterBar(!openFilterBar); setOpenSortBar(false) }}>Filter<MdFilterListAlt /></button>
              </div>

              <div className={openFilterBar ? "col-md-4 col-xl-3 " : "col-3"}>
                <div className="position-relative ">
                  <div className="d-flex gap-2 mt-2">
                    <button className="side-filterbar-button d-block d-xl-none" onClick={() => { setOpenFilterBar(!openFilterBar); setOpenSortBar(false) }}>Filter <MdFilterListAlt /></button>
                    <button className="side-filterbar-button d-block d-xl-none" onClick={() => { setOpenSortBar(!openSortBar); setOpenFilterBar(false) }}>Sort by <MdOutlineSort /></button>
                  </div>

                  <div className={`${event_status == "Approved" || cardView == "list" ? "mt-5" : "mt-0"}`}>
                    <div className={`event-filter-sidebar ${openFilterBar ? 'open' : 'd-none'}`}>
                      <aside className="d-lg-block">
                        <FilterSection
                          profileData={profileData}
                          someFilterHide={someFilterHide}
                          setEventModeDrop={setEventModeDrop}
                          eventMode={eventMode}
                          setEventMode={setEventMode}
                          eventModeDrop={eventModeDrop}
                          setTypeDrop={setTypeDrop}
                          typeDrop={typeDrop}
                          eventType={eventType}
                          setEventType={setEventType}
                          type={type}
                          setEventRange={setEventRange}
                          eventRange={eventRange}
                          rangeDrop={rangeDrop}
                          setRangeDrop={setRangeDrop}
                          setSortVal={setSortVal}
                          getMyEvent={getMyEvent}
                          // 
                          setEventCategory={setEventCategory}
                          eventCategory={eventCategory}
                          eventCategoryDrop={eventCategoryDrop}
                          setEventCategoryDrop={setEventCategoryDrop}
                          status={status}
                          setStatus={setStatus}
                          statusDrop={statusDrop}
                          setStatusDrop={setStatusDrop}
                          setMyEvent={setMyEvent}
                          event_status={event_status}
                          setEventTypeDrop={setEventTypeDrop}
                          eventTypeDrop={eventTypeDrop}

                          interestDrop={interestDrop}
                          setInterestDrop={setInterestDrop}
                          setHastag={setHastag}
                          predefineHastag={predefineHastag}
                          hastag={hastag}
                          selectedHastags={selectedHastags}
                          no_ParticipantsDrop={no_ParticipantsDrop}
                          setNo_ParticipantsDrop={setNo_ParticipantsDrop}
                          setNo_Participants={setNo_Participants}
                          srt_Evt_DateDrop={srt_Evt_DateDrop}
                          setSrt_Evt_DateDrop={setSrt_Evt_DateDrop}
                          srt_Evt_Date={srt_Evt_Date}
                          setSrt_Evt_Date={setSrt_Evt_Date}
                          selectedGenders={selectedGenders}
                          setSelectedGenders={setSelectedGenders}
                          genderDrop={genderDrop}
                          setGenderDrop={setGenderDrop}
                          minAgeDrop={minAgeDrop}
                          setMinAgeDrop={setMinAgeDrop}
                          minMaxAge={minMaxAge}
                          setMinMaxAge={setMinMaxAge}
                          distanceDrop={distanceDrop}
                          setDistanceDrop={setDistanceDrop}
                          distanceRange={distanceRange}
                          setDistanceRange={setDistanceRange}
                          primeHostDrop={primeHostDrop}
                          setPrimeHostDrop={setPrimeHostDrop}
                          prime_host={prime_host}
                          setPrime_host={setPrime_host}
                          premier={premier}
                          setPremier={setPremier}
                          premierDrop={premierDrop}
                          setPremierDrop={setPremierDrop}

                          cityDrop={cityDrop}
                          setCityDrop={setCityDrop}
                          predefineCities={predefineCities}
                          setPredefineCities={setPredefineCities}
                          cities={cities}
                          setCities={setCities}
                          selectedCities={selectedCities}
                          predefineLanguage={predefineLanguage}
                          languageDrop={languageDrop}
                          setLanguageDrop={setLanguageDrop}
                          language={language}
                          setLanguage={setLanguage}
                          selectedLanguage={selectedLanguage}

                          SponsorshipDrop={SponsorshipDrop}
                          setSponsorshipDrop={setSponsorshipDrop}
                          SponsorshipDate={SponsorshipDate}
                          setSponsorshipDate={setSponsorshipDate}
                          setSponsorshipClosingDate={setSponsorshipClosingDate}
                          durationDrop={durationDrop}
                          setDurationDrop={setDurationDrop}
                          setDurationValue={setDurationValue}
                          durationValue={durationValue}

                          house_Hold_IncomeDrop={house_Hold_IncomeDrop}
                          setHouse_Hold_IncomeDrop={setHouse_Hold_IncomeDrop}
                          house_Hold_Income={house_Hold_Income}
                          selectHouse_Hold_Income={selectHouse_Hold_Income}
                          setSelectHouse_Hold_Income={setSelectHouse_Hold_Income}
                          educationDrop={educationDrop}
                          setEducationDrop={setEducationDrop}
                          educationValue={educationValue}
                          selectEducationValue={selectEducationValue}
                          setSelectEducationValue={setSelectEducationValue}
                          eventBudget={eventBudget}
                          selectEventBudget={selectEventBudget}
                          setSelectEventBudget={setSelectEventBudget}
                          eventBudgetValue={eventBudgetValue}
                          setEventBudgetValue={setEventBudgetValue}

                          selectedGuestAge={selectedGuestAge}
                          setSelectedGuestAge={setSelectedGuestAge}
                          selectedGenderGroup={selectedGenderGroup}
                          setSelectedGenderGroup={setSelectedGenderGroup}
                          selectedGuestHouseIncome={selectedGuestHouseIncome}
                          setSelectedGuestHouseIncome={setSelectedGuestHouseIncome}
                          selectedGuestEducation={selectedGuestEducation}
                          setSelectedGuestEducation={setSelectedGuestEducation}

                          GuestMaritalStatusDrop={GuestMaritalStatusDrop}
                          setGuestMaritalStatusDrop={setGuestMaritalStatusDrop}
                          selectedGuestMaritalStatus={selectedGuestMaritalStatus}
                          setSelectedGuestMaritalStatus={setSelectedGuestMaritalStatus}
                          guestProfessionDrop={guestProfessionDrop}
                          setGuestProfessionDrop={setGuestProfessionDrop}
                          selectedGuestProfession={selectedGuestProfession}
                          setSelectedGuestProfession={setSelectedGuestProfession}
                          guestIndustryDrop={guestIndustryDrop}
                          setGuestIndustryDrop={setGuestIndustryDrop}
                          selectedGuestIndustry={selectedGuestIndustry}
                          setSelectedGuestIndustry={setSelectedGuestIndustry}
                          guestTechnologyProficiencyDrop={guestTechnologyProficiencyDrop}
                          setGuestTechnologyProficiencyDrop={setGuestTechnologyProficiencyDrop}
                          selectedGuestTechnologyProficiency={selectedGuestTechnologyProficiency}
                          setSelectedGuestTechnologyProficiency={setSelectedGuestTechnologyProficiency}
                          GuestSocialMediaUsageDrop={GuestSocialMediaUsageDrop}
                          setGuestsSocialMediaUsageDrop={setGuestsSocialMediaUsageDrop}
                          selectedGuestSocialMediaUsage={selectedGuestSocialMediaUsage}
                          setSelectedGuestsSocialMediaUsage={setSelectedGuestsSocialMediaUsage}
                        />
                      </aside>
                    </div>
                  </div>
                  {/*Sort Seaction */}
                  <div className={`d-xl-none event-filter-sidebar ${openSortBar ? "open" : "d-none"}`}>
                    <div className="filter-sidebar bg-white">
                      <div>
                        <div className="filter-content-div d-flex justify-content-between align-items-center">
                          <p className="filter-heading-text mb-0">Sort by</p>
                        </div>
                        <hr className="m-0" />
                        <SortSection sortVal={sortVal} setSortVal={setSortVal} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={openFilterBar ? "col-md-12 col-xl-9 event-card-div" : "col-md-12"}>
                <div>
                  <div className="d-none d-xl-flex gap-3 align-items-center justify-content-end">
                    <SortSection sortVal={sortVal} setSortVal={setSortVal} />
                  </div>

                  <hr className="mt-3" />
                  {
                    event_status == "Archived" &&
                    <div className="d-flex justify-content-end mb-2">
                      <button className={archive_Type ? "active-sponsored-btn" : "sponsored-btn"} onClick={() => setArchive_Type(true)}>Sponsored</button>
                      <button className={archive_Type ? "sponsored-btn" : "active-sponsored-btn"} onClick={() => setArchive_Type("")}>Non Sponsored</button>
                    </div>
                  }
                </div>
                {
                  event_status == "Approved" ?
                    <div>
                      {/* <div className="list-head-div">
                     <div className="row justify-content-center align-items-center">
                     <div className="col-md-2">
                       <p className="listcard-head-title">Image</p>
                     </div>

                     <div className="col-md-2">
                       <p className="listcard-head-title">Title</p>
                     </div>

                     <div className="col-md-2">
                       <p className="listcard-head-title">Date</p>
                     </div>

                     <div className="col-md-2">
                       <p className="listcard-head-title">Location</p>
                     </div>

                     <div className="col-md-2">
                       <p className="listcard-head-title">Status</p>
                     </div>

                     <div className="col-md-2">
                       <p className="listcard-head-title">Action</p>
                     </div>
                   </div>
                   </div> */}
                      <div>
                        {/* {myEvent?.data?.length <= 0 && 
                    <h2 className="no-data-msg">No Data Found</h2>
                    } */}
                        <ApproveListCard val={myEvent?.data} baseUrls={baseUrls} getMyEvent={getMyEvent} profileData={profileData} />
                        {/* {eventLoading ? (
                         <Loader2 />
                          ) : (
                            myEvent?.data?.map((val) => {
                              return (
                                <div className="col-md-12 mt-3" key={val?.id}>
                                  <ApproveListCard val={val} getMyEvent={getMyEvent} handleDeleteEvent={handleDeleteEvent} />
                                </div>
                              );
                            })
                          )} */}
                      </div>
                    </div>

                    :
                    <>
                      {myEvent?.data?.length <= 0 ? (
                        <h2 className="no-data-msg">No Data Found</h2>
                      ) : (
                        <div className="row">
                          {cardView == "list" ? (
                            <>
                              {/* <div>
                                <div className="list-head-div">
                                  <div className="row justify-content-center align-items-center">
                                    <div className="col-md-2">
                                      <p className="listcard-head-title">Image</p>
                                    </div>

                                    <div className="col-md-2">
                                      <p className="listcard-head-title">Title</p>
                                    </div>

                                    <div className="col-md-2">
                                      <p className="listcard-head-title">Date</p>
                                    </div>

                                    <div className="col-md-2">
                                      <p className="listcard-head-title">Location</p>
                                    </div>

                                    <div className="col-md-2">
                                      <p className="listcard-head-title">Status</p>
                                    </div>

                                    <div className="col-md-2">
                                      <p className="listcard-head-title">Action</p>
                                    </div>
                                  </div>
                                </div>

                              {eventLoading ? (
                                  <Loader2 />
                                ):(
                                   myEvent?.data?.map((val) => {
                                    return (
                                      <div className="col-md-12 mt-4" key={val?.id}>
                                        <MyEventsListCard baseUrls={baseUrls} val={val} handleDeleteEvent={handleDeleteEvent} />
                                      </div>
                                    );
                                  })
                                )}
                              </div> */}
                              <MyEventsListCard rowData={myEvent?.data} baseUrls={baseUrls} handleDeleteEvent={handleDeleteEvent} getMyEvent={getMyEvent}
                                archive_Type={archive_Type} event_status={event_status} profileData={profileData}/>
                            </>
                          ) : (
                            <>
                              {eventLoading ? (
                                <Loader2 />
                              ) : (
                                myEvent?.data?.map((val) => {
                                  return (
                                    <div className="d-flex justify-content-center col-6 col-sm-4 col-md-3 col-lg-3 col-xl-3 mt-2" key={val?.id}>
                                      <MyEventCard baseUrls={baseUrls} val={val} handleDeleteEvent={handleDeleteEvent} getMyEvent={getMyEvent} 
                                       archive_Type={archive_Type} profileData={profileData}/>
                                    </div>
                                  );
                                })
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </>
                }

                {myEvent?.data?.length < myEvent?.total && (
                  <div className="pagination-container mt-3">
                    <ReactPaginate
                      pageCount={Math.ceil(myEvent?.total / myEvent?.per_page)}
                      pageRangeDisplayed={1}
                      marginPagesDisplayed={2}
                      onPageChange={handlePageClick}
                      containerClassName="pagination"
                      activeClassName="selected"
                      disabledClassName="disabled"
                    />
                  </div>
                )}

                {/* popup start*/}
                {showPopup &&
                  <div className="logout-popup-overlay">
                    <div className="logout-popup">
                      <p>Before creating a new event, please complete the host profile section in <b>'My Profile'</b>.</p>
                      <div className="d-flex gap-3 justify-content-center">
                        <button
                          onClick={() => navigate(`/editprofile/${profileData?.id}`)}
                          className="ok-button">Go to Profile</button>

                        <button
                          onClick={() => setShowPopup(false)}
                          className="ok-button">OK</button>
                      </div>
                    </div>
                  </div>
                }
                {/* popup end*/}

              </div>
            </div>
          </>

        </div >
      )}
    </>
  );
};

export default MyEvents;
