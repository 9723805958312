
import InputRange from "react-input-range";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useState } from "react";
import { Link } from "react-router-dom";

const MyPastEventFilters = (props) => {
    const [priceRange, setPriceRange] = useState({ min: "", max: "" });
    const [participantsRange, setParticipantsRange] = useState({ min: "", max: "" })
    const [ageRange, setAgeRange] = useState({ min: "", max: "" })
    const [event_Date, setEvent_Date] = useState({ start: '', end: '' })
    const [distance, setDistance] = useState(0)
    const [durationData, setDurationData] = useState("");
    const currentDate = new Date().toISOString().split('T')[0];
    const [showIntrest, setShowIntrest] = useState({});
    const [staticHastag_srch, setStaticHastag_srch] = useState('');
    const [staticCity_srch, setStaticCity_srch] = useState('');
    const [staticLanguage_srch, setStaticLanguage_srch] = useState('');
    const [expandAllBtn, setExpandAllBtn] = useState(true);
 
    const {
        profileData,
        setEventModeDrop,
        eventMode,
        setEventMode,
        eventModeDrop,
        setTypeDrop,
        typeDrop,
        eventType,
        setEventType,
        type,
        setEventRange,
        eventRange,
        // GetEvent,
        rangeDrop,
        setRangeDrop,
        setSortVal,
        getMyEvent,
        // 
        setEventCategory,
        eventCategory,
        eventCategoryDrop,
        setEventCategoryDrop,
        status,
        setStatus,
        statusDrop,
        setStatusDrop,

        interestDrop,
        setInterestDrop,
        setHastag,
        predefineHastag,
        hastag,
        selectedHastags,

        setNo_Participants,
        no_ParticipantsDrop,
        setNo_ParticipantsDrop,
        srt_Evt_DateDrop,
        setSrt_Evt_DateDrop,
        setSrt_Evt_Date,
        selectedGenders,
        setSelectedGenders,
        genderDrop,
        setGenderDrop,
        minAgeDrop,
        setMinAgeDrop,
        minMaxAge,
        setMinMaxAge,
        distanceDrop,
        setDistanceDrop,
        distanceRange,
        setDistanceRange,
        primeHostDrop,
        setPrimeHostDrop,
        prime_host,
        setPrime_host,
        premier,
        setPremier,
        premierDrop,
        setPremierDrop,
        cityDrop,
        setCityDrop,
        predefineCities,
        setPredefineCities,
        cities,
        setCities,
        selectedCities,
        predefineLanguage,
        languageDrop,
        setLanguageDrop,
        language,
        setLanguage,
        selectedLanguage,
        SponsorshipDrop,
        setSponsorshipDrop,
        SponsorshipDate,
        setSponsorshipDate,
        setSponsorshipClosingDate,
        durationDrop,
        setDurationDrop,
        setDurationValue,
        durationValue,
        eventBudget,
        selectEventBudget,
        setSelectEventBudget,
        eventBudgetValue,
        setEventBudgetValue,
    } = props;

    // intrest data see more-less
    const [showMoreIntrest, setShowMoreIntrest] = useState(false);
    const hashtagsToShow = showMoreIntrest || staticHastag_srch?.length > 0 ? predefineHastag : predefineHastag?.slice(0, 5);

    // city data see more-less
    const [showMoreCity, setShowMoreCity] = useState(false);
    const CitiesToShow = showMoreCity || staticCity_srch?.length > 0 ? predefineCities : predefineCities?.slice(0, 5);

    // setShowMoreLanguage
    const [showMoreLanguage, setShowMoreLanguage] = useState(false);
    const LanguageToShow = showMoreLanguage || showMoreLanguage?.length > 0 ? predefineLanguage : predefineLanguage?.slice(0, 5);

    const handleChangeRange = (range) => {
        setPriceRange(range);
    };

    const handleRangeSubmit = () => {
        setEventRange(priceRange);
        getMyEvent()
    };


    const handleClearAllFilter = () => {
        setSortVal("");
        setEventMode("");
        setEventType("");
        setEventRange({ min: "", max: "" });
        setNo_Participants({ min: "", max: "" })
        setSrt_Evt_Date({ start: '', end: '' })
        setStatus("");
        setEventCategory("");
        setHastag([]);
        setMinMaxAge({ min: "", max: "" })
        setDistanceRange("")
        setCities([])
        setSelectedGenders('');
        setLanguage([]);
        setSponsorshipDate({ start: '', end: '' });
        setSponsorshipClosingDate({ start: '', end: '' });
        setDurationValue('');
        setEvent_Date({ start: '', end: '' })
        setPrime_host('')
        setPremier('')
        setEventBudgetValue('')
    };

    const handleChangeParticipantsRange = (range) => {
        setParticipantsRange(range)
    }

    const HandleParticipantsRangeSubmit = () => {
        setNo_Participants(participantsRange)
        // GetEvent();
    }

    // date filter
    const setStartDate = (date) => {
        setEvent_Date((prevState) => ({
            ...prevState,
            start: date
        }));
    };


    const setEndDate = (date) => {
        setEvent_Date((prevState) => ({
            ...prevState,
            end: date
        }));
    };

    const mangeEventStartEndDate = () => {
        setSrt_Evt_Date(event_Date)
        // GetEvent();
    }

    // const handleGenderChange = (e) => {
    //   const { value } = e.target;
    //   setSelectedGenders(value);
    // };

    // age range
    const handleChangeAgeRange = (range) => {
        setAgeRange(range)
    }

    const HandleAgeRangeSubmit = () => {
        setMinMaxAge(ageRange)
        // GetEvent();
    }

    // distancce in km filter
    const handleChangeDistanceRange = (range) => {
        setDistance(range)
    }

    const HandleDistanceRangeSubmit = () => {
        setDistanceRange(distance)
        // GetEvent();
    }

    // prime_host
    const handleTogglePrime = () => {
        setPrime_host(prevState => (prevState == true ? '' : true));
    }

    //primer 
    const handleTogglePremier = () => {
        setPremier(prevState => (prevState == true ? '' : true));
    }

    // duration onchange
    const HandleOnchangeDuration = (e) => {
        setDurationData(e?.target?.value)
    }

    // duration button
    const HandleDurationSubmit = () => {
        setDurationValue(durationData)
    }
    // setSponsorshipDate
    const setStartSponsorshipDate = (date) => {
        setSponsorshipDate((prevState) => ({
            ...prevState,
            start: date
        }));
    };

    const setEndSponsorshipDate = (date) => {
        setSponsorshipDate((prevState) => ({
            ...prevState,
            end: date
        }));
    };

    const manageSponsorshipDate = () => {
        setSponsorshipClosingDate(SponsorshipDate)
    }

    const handleExpandAll = () => {
        setRangeDrop(true)
        setTypeDrop(true)
        setEventModeDrop(true)
        setEventCategoryDrop(true)
        setStatusDrop(true)
        setNo_ParticipantsDrop(true)
        setSrt_Evt_DateDrop(true)
        setGenderDrop(true)
        setMinAgeDrop(true)
        setDistanceDrop(true)
        setPrimeHostDrop(true)
        setCityDrop(true)
        setInterestDrop(true)
        setLanguageDrop(true)
        setSponsorshipDrop(true)
        setDurationDrop(true)
        setPremierDrop(true)

        setExpandAllBtn(true)
    }

    const handleCollapseAll = () => {
        setRangeDrop(false)
        setTypeDrop(false)
        setEventModeDrop(false)
        setEventCategoryDrop(false)
        setStatusDrop(false)
        setNo_ParticipantsDrop(false)
        setSrt_Evt_DateDrop(false)
        setGenderDrop(false)
        setMinAgeDrop(false)
        setDistanceDrop(false)
        setPrimeHostDrop(false)
        setCityDrop(false)
        setInterestDrop(false)
        setLanguageDrop(false)
        setSponsorshipDrop(false)
        setDurationDrop(false)
        setPremierDrop(false)
      
        setExpandAllBtn(false)
    }

console.log("durationValue",durationValue)
    return (
        <>
            <aside className="">
                <div className="filter-sidebar">
                    <div>
                        <div className="filter-content-div d-flex justify-content-between align-items-center">
                            <p className="filter-heading-text mb-0">Filters</p>
                            <div className="d-flex gap-1">
                                {
                                  expandAllBtn ?
                                    <button className="clear-filter-button" onClick={handleCollapseAll}>Collapse All</button>
                                     :
                                    <button className="clear-filter-button" onClick={handleExpandAll}>Expand All</button>
                                }

                                <button className="clear-filter-button" onClick={handleClearAllFilter}>Clear All</button>
                            </div>
                        </div>
                        <hr className="m-0" />
                    </div>

                    <div className="filter-drop-content mt-1">
                        <div className="filter-div mt-3">
                            <button
                                className="filter-btn"
                                onClick={() => setNo_ParticipantsDrop(!no_ParticipantsDrop)}>
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex align-items-center gap-1">
                                        {no_ParticipantsDrop ? (
                                            <IoIosArrowUp size={18} color="#888" />
                                        ) : (
                                            <IoIosArrowDown size={18} color="#888" />
                                        )}
                                        <p className={no_ParticipantsDrop ? "active-filter-heading" : "filter-heading mb-0"}>Attendee Count</p>
                                    </div>
                                    <div>
                                        <button type="button" className="filter-clear-btn" onClick={(e) => { e.stopPropagation(); setParticipantsRange({ min: '', max: '' }); setNo_Participants({ min: '', max: '' }) }}>Clear</button>
                                        {/* <img className="" src={rangeDrop ? uparrow : downarrrow} alt="arrow" /> */}
                                    </div>
                                </div>
                            </button>
                            {no_ParticipantsDrop && (
                                <div className="range-content mt-0 pt-0">
                                    <div className="d-flex gap-4 mt-1">
                                        <div className="mb-3">
                                            {/* <label htmlFor="fromInput" className="form-label">
                                               From:
                                          </label> */}
                                            <input
                                                type="number"
                                                min='0'
                                                className="filter-section-input"
                                                id="fromInput"
                                                name="no_Participants"
                                                placeholder="From"
                                                value={participantsRange?.min}
                                                onChange={(e) =>
                                                    setParticipantsRange({
                                                        min: e.target.value,
                                                        max: participantsRange.max,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className="mb-3">
                                            {/* <label htmlFor="toInput" className="form-label">
                                              To:
                                            </label> */}
                                            <input
                                                type="number"
                                                max="100000"
                                                className="filter-section-input"
                                                id="toInput"
                                                name="no_Participants"
                                                placeholder="To"
                                                value={participantsRange?.max}
                                                onChange={(e) =>
                                                    setParticipantsRange({
                                                        min: participantsRange.min,
                                                        max: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                    </div>

                                    <div className="mt-2 px-3 mb-2">
                                        <InputRange
                                            maxValue={100000}
                                            minValue={0}
                                            name="no_Participants"
                                            value={participantsRange}
                                            onChange={(newValue) => handleChangeParticipantsRange(newValue)}
                                        />
                                    </div>
                                    <button
                                        className="range-submit-btn mt-3"
                                        onClick={HandleParticipantsRangeSubmit}>
                                        Submit
                                    </button>
                                </div>
                            )}
                            <hr className="m-0" />
                        </div>

                        <div className="filter-div">
                            <button
                                className="filter-btn"
                                onClick={() => setRangeDrop(!rangeDrop)}>
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex align-items-center gap-1">
                                        {rangeDrop ? (
                                            <IoIosArrowUp size={18} color="#888" />
                                        ) : (
                                            <IoIosArrowDown size={18} color="#888" />
                                        )}
                                        <p className={rangeDrop ? "active-filter-heading m-0" : "filter-heading mb-0"}>Event Budget Range</p>
                                    </div>
                                    <div>
                                        <button type="button" className="filter-clear-btn" onClick={(e) => { e.stopPropagation(); setSelectEventBudget(''); setEventBudgetValue(''); }}>Clear</button>
                                        {/* <img className="" src={rangeDrop ? uparrow : downarrrow} alt="arrow" /> */}
                                    </div>
                                </div>
                            </button>
                            {rangeDrop && (
                                <div className="range-content pt-1">
                                    <label className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="total_budget_id"
                                            checked={eventBudgetValue == ""}
                                            value=""
                                            onChange={(e) => setEventBudgetValue(e.target.value)}
                                        />
                                        <span className={rangeDrop ? "active-lebel-text" : "form-check-label"}>All</span>
                                    </label>
                                    {eventBudget?.map((val) => {
                                        return (<>
                                            <label className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="total_budget_id"
                                                    checked={eventBudgetValue == val?.id}
                                                    value={val?.id}
                                                    onChange={(e) => setEventBudgetValue(e.target.value)}
                                                />
                                                <span className={rangeDrop ? "active-lebel-text" : "form-check-label"}>{val?.budget}</span>
                                            </label>
                                        </>)
                                    })}
                                    {/* <select className="form-control" name="total_budget_id"
                onChange={(e) => setSelectEventBudget(e.target.value)}>
                <option className="active-lebel-text" value="" selected={selectEventBudget == ''} >Select Event Budget Range</option>
                {eventBudget?.map((val) => (
                  <option className="active-lebel-text" value={val?.id} key={val?.id}>{val?.budget}</option>
                ))}
              </select>
              <button className="range-submit-btn mt-3" onClick={() => setEventBudgetValue(selectEventBudget)}>
                Submit
              </button> */}
                                    {/* <div className=" d-flex gap-4 mt-2">
                <div className="mb-3">
                  <label htmlFor="fromInput" className="form-label">
                    From:
                  </label>
                  <input
                    type="number"
                    min='0'
                    className="filter-section-input"
                    id="fromInput"
                    name="eventRange"
                    placeholder="From"
                    value={priceRange?.min}
                    onChange={(e) =>
                      setPriceRange({
                        min: e.target.value,
                        max: priceRange.max,
                      })
                    }
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="toInput" className="form-label">
                    To:
                  </label>
                  <input
                    type="number"
                    min='0'
                    className="filter-section-input"
                    id="toInput"
                    name="eventRange"
                    placeholder="To"
                    value={priceRange?.max}
                    onChange={(e) =>
                      setPriceRange({
                        min: priceRange.min,
                        max: e.target.value,
                      })
                    }
                  />
                </div>
              </div>

              <div className="mt-2 px-3 mb-2">
                <InputRange
                  maxValue={100000}
                  minValue={0}
                  name="eventRange"
                  value={priceRange}
                  onChange={(newValue) => handleChangeRange(newValue)}
                />
              </div>
              <button
                className="range-submit-btn mt-3"
                onClick={handleRangeSubmit}
              >
                Submit
              </button> */}
                                </div>
                            )}
                            <hr className="m-0" />
                        </div>

                        <div className="filter-div">
                            <button
                                className="filter-btn"
                                onClick={() => setSrt_Evt_DateDrop(!srt_Evt_DateDrop)}>
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex aign-items-center gap-1">
                                        {srt_Evt_DateDrop ? (
                                            <IoIosArrowUp size={18} color="#888" />
                                        ) : (
                                            <IoIosArrowDown size={18} color="#888" />
                                        )}
                                        <p className={srt_Evt_DateDrop ? "active-filter-heading m-0" : "filter-heading mb-0"}> Event Date</p>
                                    </div>
                                    <div>
                                        <button type="button" className="filter-clear-btn" onClick={(e) => {
                                            e.stopPropagation(); setStartDate(''); setEndDate('');
                                            setSrt_Evt_Date({ start: '', end: '' })
                                        }}>Clear</button>
                                    </div>
                                </div>
                            </button>
                            {srt_Evt_DateDrop && (
                                <div className="filter-content">
                                    <div className="card-body pt-0 m-0">
                                        <div className="d-flex gap-2">
                                            <div className=''>
                                                <label className="filter-date-label p-0">From:</label>
                                                <input
                                                    type="date"
                                                    className='filter-date-input'
                                                    value={event_Date.start}
                                                    // min={currentDate}
                                                    onChange={(e) => setStartDate(e.target.value)}
                                                />
                                            </div>

                                            <div className=''>
                                                <label className="filter-date-label p-0">To:</label>
                                                <input
                                                    type="date"
                                                    className='filter-date-input'
                                                    value={event_Date.end}
                                                    min={event_Date?.start}
                                                    onChange={(e) => setEndDate(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <button
                                            className="range-submit-btn mt-3"
                                            onClick={mangeEventStartEndDate}>
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            )}
                            <hr className="m-0" />
                        </div>

                        <div className="filter-div">
                            <button
                                className="filter-btn"
                                onClick={() => setDurationDrop(!durationDrop)} >
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex align-items-center gap-1">
                                        {durationDrop ? (
                                            <IoIosArrowUp size={18} color="#888" />
                                         ) : (
                                            <IoIosArrowDown size={18} color="#888" />
                                        )}
                                        <p className={durationDrop ? "active-filter-heading m-0" : "filter-heading mb-0"}>Event Duration</p>
                                    </div>
                                    <div>
                                        <button type="button" className="filter-clear-btn" onClick={(e) => { e.stopPropagation(); setDurationData(''); setDurationValue('') }}>Clear</button>
                                    </div>
                                </div>
                            </button>
                            {durationDrop && (
                                <div className="hastag-main-div ">
                                    <div className="filter-content w-100">
                                        <label className="form-check">
                                            <input className="form-check-input"
                                                type="radio"
                                                name="host_id"
                                                checked={durationValue == ""}
                                                value=""
                                                onChange={(e) => setDurationValue(e.target.value)} />
                                            <span className={durationDrop ? "active-lebel-text" : "form-check-label"}>All</span>
                                        </label>

                                        <label className="form-check">
                                            <input className="form-check-input"
                                                type="radio"
                                                name="host_id"
                                                checked={durationValue == "1"}
                                                value="1"
                                                onChange={(e) => setDurationValue(e.target.value)} />
                                            <span className={durationDrop ? "active-lebel-text" : "form-check-label"}>1</span>
                                        </label>

                                        <label className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="host_id"
                                                checked={durationValue == "2"}
                                                value="2"
                                                onChange={(e) => setDurationValue(e.target.value)} />
                                            <span className={durationDrop ? "active-lebel-text" : "form-check-label"}>2</span>
                                        </label>

                                        <label className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="host_id"
                                                checked={durationValue == "3"}
                                                value="3"
                                                onChange={(e) => setDurationValue(e.target.value)} />
                                            <span className={durationDrop ? "active-lebel-text" : "form-check-label"}>3</span>
                                        </label>

                                        <label className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="host_id"
                                                checked={durationValue == "4"}
                                                value="4"
                                                onChange={(e) => setDurationValue(e.target.value)} />
                                            <span className={durationDrop ? "active-lebel-text" : "form-check-label"}>4</span>
                                        </label>

                                        <label className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="host_id"
                                                checked={durationValue == "5"}
                                                value="5"
                                                onChange={(e) => setDurationValue(e.target.value)} />
                                            <span className={durationDrop ? "active-lebel-text" : "form-check-label"}>5+</span>
                                        </label>
                                        {/* <select className="form-control" name="host_id"
                  onChange={HandleOnchangeDuration}>
                  <option className="active-lebel-text" value="" selected={durationData == ''}>Select Day</option>
                  <option className="active-lebel-text" value="1">1 </option>
                  <option className="active-lebel-text" value="2">2 </option>
                  <option className="active-lebel-text" value="3">3 </option>
                  <option className="active-lebel-text" value="4">4 </option>
                  <option className="active-lebel-text" value="5">5+ </option>
                </select> */}
                                    </div>
                                    {/* <button
                className="range-submit-btn my-2"
                onClick={HandleDurationSubmit}>
                Submit
              </button> */}
                                </div>
                            )}
                            <hr className="m-0" />
                        </div>

                        <div className="filter-div">
                            <button className="filter-btn" onClick={() => setCityDrop(!cityDrop)}>
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex align-items-center gap-1">
                                        {cityDrop ? (
                                            <IoIosArrowUp size={18} color="#888" />
                                        ) : (
                                            <IoIosArrowDown size={18} color="#888" />
                                        )}
                                        <p className={cityDrop ? "active-filter-heading m-0" : "filter-heading mb-0"}>Event City</p>
                                    </div>
                                    <div>
                                        <button type="button" className="filter-clear-btn" onClick={(e) => { e.stopPropagation(); setCities([]); setStaticCity_srch('') }}>Clear</button>
                                    </div>
                                </div>
                            </button>
                            {cityDrop && (
                                <div>
                                    <div className="py-1 px-3 hastag-main-div d-flex gap-2">
                                        <input className="filter-search-input w-100" placeholder="Search..." value={staticCity_srch} onChange={(e) => setStaticCity_srch(e.target.value)} />
                                        {/* <button className="btn-clear" onClick={() => {
                                         setCities([]);
                                         setStaticCity_srch('');
                                        }}>Clear</button> */}
                                    </div>
                                    {cities?.length <= 0 ?
                                        "" :
                                        <div>
                                            <div className="hastag-main-div">
                                                {selectedCities?.map((val) => {
                                                    return (
                                                        <>
                                                            <div className="" key={val?.city_id}>
                                                                <button
                                                                    key={val?.city_id}
                                                                    className="selected-IntrestP text-capitalize"
                                                                    onClick={() => {
                                                                        setCities((prevCity) => {
                                                                            const newValue = val?.city_id;
                                                                            if (prevCity?.includes(newValue)) {
                                                                                return prevCity?.filter(
                                                                                  (item) => item !== newValue
                                                                                );
                                                                            }
                                                                        });
                                                                    }}>
                                                                    {val?.city_name}
                                                                </button>
                                                            </div>
                                                        </>);
                                                })}
                                            </div>
                                            <hr className="m-0" />
                                        </div>
                                    }
                                    <div className="hastag-main-div">
                                        {CitiesToShow?.filter(fill => fill?.city_name?.toLowerCase().includes(staticCity_srch.toLowerCase()))?.map((val) => {
                                            return (
                                                <div className="" key={val?.city_id}>
                                                    <button
                                                        key={val?.city_id}
                                                        className="Card-IntrestP text-capitalize"
                                                        onClick={() => {
                                                            setCities((prevCity) => {
                                                                if (prevCity?.length < 5) {
                                                                    const newValue = val?.city_id; // Get the new value
                                                                    const updatedCity = [...prevCity]; // Copy the previous value
                                                                    updatedCity.push(newValue); // Add the new value
                                                                    return updatedCity; // Return the updated value
                                                                }
                                                                return prevCity;
                                                            })
                                                            setStaticCity_srch('')
                                                        }}>
                                                        {val?.city_name}
                                                    </button>
                                                </div>
                                            );
                                        })}
                                        <button onClick={() => setShowMoreCity(!showMoreCity)} className="btn viewall-btn-p p-0 m-0 text-black text-decoration-underline">
                                            {showMoreCity ? 'See Less' : 'See More'}
                                        </button>
                                    </div>
                                </div>
                            )}
                            <hr className="m-0" />
                        </div>

                        <div className="filter-div">
                            <button
                                className="filter-btn"
                                onClick={() => setEventModeDrop(!eventModeDrop)}>
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex aiign-items-center gap-1">
                                        {eventModeDrop ? (
                                            <IoIosArrowUp size={18} color="#888" />
                                        ) : (
                                            <IoIosArrowDown size={18} color="#888" />
                                        )}
                                        <p className={eventModeDrop ? "active-filter-heading m-0" : "filter-heading mb-0"}>Event Mode</p>
                                    </div>
                                    <div>
                                        <button type="button" className="filter-clear-btn" onClick={(e) => { e.stopPropagation(); setEventMode('') }}>Clear</button>
                                    </div>
                                </div>
                            </button>
                            {eventModeDrop && (
                                <div className="filter-dropdown-content">
                                    <div className="card-body pt-0">
                                        <div>
                                            <label className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="eventMode"
                                                    value=""
                                                    onChange={(e) => setEventMode(e.target.value)}
                                                />
                                                <span className={eventModeDrop ? "active-lebel-text" : "form-check-label"}>All</span>
                                            </label>
                                        </div>

                                        <div>
                                            <label className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="eventMode"
                                                    value="Online"
                                                    checked={eventMode == "Online"}
                                                    onChange={(e) => setEventMode(e.target.value)}
                                                />
                                                <span className={eventModeDrop ? "active-lebel-text" : "form-check-label"}>Online</span>
                                            </label>
                                        </div>

                                        <div>
                                            <label className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="eventMode"
                                                    value="Hybrid"
                                                    checked={eventMode == "Hybrid"}
                                                    onChange={(e) => setEventMode(e.target.value)}
                                                />
                                                <span className={eventModeDrop ? "active-lebel-text" : "form-check-label"}>Hybrid</span>
                                            </label>
                                        </div>
                                        <div>
                                            <label className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="eventMode"
                                                    value="Offline_Indoor"
                                                    checked={eventMode == "Offline_Indoor"}
                                                    onChange={(e) => setEventMode(e.target.value)}
                                                />
                                                <span className={eventModeDrop ? "active-lebel-text" : "form-check-label"}>Offline Indoor</span>
                                            </label>
                                        </div>
                                        <div>
                                            <label className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="eventMode"
                                                    value="Offline_Outdoor"
                                                    checked={eventMode == "Offline_Outdoor"}
                                                    onChange={(e) => setEventMode(e.target.value)}
                                                />
                                                <span className={eventModeDrop ? "active-lebel-text" : "form-check-label"}>Offline Outdoor</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <hr className="m-0" />
                        </div>

                        <div className="filter-div">
                            <button className="filter-btn" onClick={() => setEventCategoryDrop(!eventCategoryDrop)}>
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex slign-items-center gap-1">
                                        {eventCategoryDrop ? (
                                            <IoIosArrowUp size={18} color="#888" />
                                        ) : (
                                            <IoIosArrowDown size={18} color="#888" />
                                        )}
                                        <p className={eventCategoryDrop ? "active-filter-heading" : "filter-heading mb-0"}>Event Timings</p>
                                    </div>
                                    <div>
                                        <button type="button" className="filter-clear-btn" onClick={(e) => { e.stopPropagation(); setEventCategory('') }}>Clear</button>
                                    </div>
                                </div>
                            </button>
                            {eventCategoryDrop && (
                                <div className="filter-dropdown-content">
                                    <div className="card-body pt-0">
                                        <div>
                                            <label className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="eventTimeCategory"
                                                    value=""
                                                    onChange={(e) => setEventCategory(e.target.value)} />
                                                <span className={eventCategoryDrop ? "active-lebel-text" : "form-check-label"}>All</span>
                                            </label>
                                        </div>

                                        <div>
                                            <label className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="eventTimeCategory"
                                                    value="Day_event"
                                                    checked={eventCategory == "Day_event"}
                                                    onChange={(e) => setEventCategory(e.target.value)}
                                                />
                                                <span className={eventCategoryDrop ? "active-lebel-text" : "form-check-label"}>Day Event</span>
                                            </label>
                                        </div>

                                        <div>
                                            <label className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="eventTimeCategory"
                                                    value="Night_event"
                                                    checked={eventCategory == "Night_event"}
                                                    onChange={(e) => setEventCategory(e.target.value)}
                                                />
                                                <span className={eventCategoryDrop ? "active-lebel-text" : "form-check-label"}>Night Event</span>
                                            </label>
                                        </div>

                                        <div>
                                            <label className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="eventTimeCategory"
                                                    value="Full_day"
                                                    checked={eventCategory == "Full_day"}
                                                    onChange={(e) => setEventCategory(e.target.value)} />
                                                <span className={eventCategoryDrop ? "active-lebel-text" : "form-check-label"}>Full Day</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <hr className="m-0" />
                        </div>

                        <div className="filter-div">
                            <button
                                className="filter-btn"
                                onClick={() => setStatusDrop(!statusDrop)}>
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex align-items-center gap-1">
                                        {statusDrop ? (
                                            <IoIosArrowUp size={18} color="#888" />
                                        ) : (
                                            <IoIosArrowDown size={18} color="#888" />
                                        )}
                                        <p className={statusDrop ? "active-filter-heading m-0" : "filter-heading mb-0"}>Event Status</p>
                                    </div>
                                    <div>
                                        <button type="button" className="filter-clear-btn" onClick={(e) => { e.stopPropagation(); setStatus('') }}>Clear</button>
                                    </div>
                                </div>
                            </button>
                            {statusDrop && (
                                <div className="filter-content">
                                    <div className="card-body pt-0">
                                        <div>
                                            <label className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="status"
                                                    value=""
                                                    onClick={() => setStatus("")}
                                                />
                                                <span className={statusDrop ? "active-lebel-text" : "form-check-label"}>All</span>
                                            </label>
                                        </div>

                                        <div>
                                            <label className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="status"
                                                    checked={status == "Live"}
                                                    value="Live"
                                                    onClick={(e) => setStatus(e.target.value)}
                                                />
                                                <span className={statusDrop ? "active-lebel-text" : "form-check-label"}>Live</span>
                                            </label>
                                        </div>

                                        <div>
                                            <label className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="status"
                                                    checked={status == "Sample"}
                                                    value="Sample"
                                                    onClick={(e) => setStatus(e.target.value)} />
                                                <span className={statusDrop ? "active-lebel-text" : "form-check-label"}>Sample</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <hr className="m-0" />
                        </div>

                        <div className="filter-div">
                            <button className="filter-btn" onClick={() => setTypeDrop(!typeDrop)}>
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex align-items-center gap-1">
                                        {typeDrop ? (
                                            <IoIosArrowUp size={18} color="#888" />
                                        ) : (
                                            <IoIosArrowDown size={18} color="#888" />
                                        )}
                                        <p className={typeDrop ? "active-filter-heading m-0" : "filter-heading mb-0"}>Event Types </p>
                                    </div>
                                    <div>
                                        <button type="button" className="filter-clear-btn" onClick={(e) => { e.stopPropagation(); setEventType(''); }}>Clear</button>
                                    </div>
                                </div>
                            </button>

                            {typeDrop && (
                                <div className="filter-content">
                                    <div className="card-body pt-0">
                                        <div>
                                            <label className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="filterData"
                                                    value=""
                                                    onClick={() => setEventType("")}
                                                />
                                                <span className={typeDrop ? "active-lebel-text" : "form-check-label"}>All</span>
                                            </label>
                                        </div>
                                        {type &&
                                            type?.map((val) => {
                                                return (
                                                    <>
                                                        <div key={val?.id}>
                                                            <label className={typeDrop ? "active-lebel-text form-check" : "form-check"}>
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="filterData"
                                                                    checked={eventType == val?.id}
                                                                    value=""
                                                                    onClick={() => setEventType(val?.id)} />
                                                                <span className="form-check-label">
                                                                    {val?.name}
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </>
                                                );
                                            })}
                                    </div>
                                </div>
                            )}
                            <hr className="m-0" />
                        </div>


                        {/* predefineLanguage */}
                        <div className="filter-div">
                            <button
                                className="filter-btn"
                                onClick={() => setLanguageDrop(!languageDrop)}>
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex align-items-center gap-1">
                                        {languageDrop ? (
                                            <IoIosArrowUp size={18} color="#888" />
                                        ) : (
                                            <IoIosArrowDown size={18} color="#888" />
                                        )}
                                        <p className={languageDrop ? "active-filter-heading m-0" : "filter-heading mb-0"}>Language</p>
                                    </div>
                                    <div>
                                        <button type="button" className="filter-clear-btn" onClick={(e) => { e.stopPropagation(); setLanguage([]); setStaticLanguage_srch('') }}>Clear</button>
                                    </div>
                                </div>
                            </button>
                            {languageDrop && (
                                <div>
                                    {/* staticCity_srch, setStaticCity_srch */}
                                    <div className="py-1 px-3 hastag-main-div d-flex gap-2">
                                        <input className="filter-search-input w-100" placeholder="Search..." value={staticLanguage_srch} onChange={(e) => setStaticLanguage_srch(e.target.value)} />
                                        {/* <button className="btn-clear"
                onClick={() => {
                  setStaticLanguage_srch('')
                  setLanguage([])
                }}>Clear</button> */}
                                    </div>
                                    {languageDrop?.length <= 0 ? "" :
                                        <div>
                                            <div className="hastag-main-div ">
                                                {selectedLanguage?.map((val) => {
                                                    return (
                                                        <>
                                                            <div className="" key={val}>
                                                                <button
                                                                    key={val}
                                                                    className="selected-IntrestP text-capitalize"
                                                                    onClick={() => {
                                                                        setLanguage((prevLang) => {
                                                                            const newValue = val;
                                                                            if (prevLang?.includes(newValue)) {
                                                                                return prevLang?.filter(
                                                                                 (item) => item !== newValue
                                                                             )
                                                                            }
                                                                        });
                                                                    }}>
                                                                    {val}
                                                                </button>
                                                            </div>
                                                        </>);
                                                })}
                                            </div>
                                            {/* <hr /> */}
                                        </div>
                                    }
                                    <div className="hastag-main-div">
                                        {LanguageToShow?.filter(fill => fill?.toLowerCase().includes(staticLanguage_srch.toLowerCase()))?.map((val) => {
                                            //language, 
                                            return (
                                                <div className="" key={val?.city_id}>
                                                    <button
                                                        key={val?.id}
                                                        className="Card-IntrestP text-capitalize"
                                                        onClick={() => {
                                                            setLanguage((prevLang) => {
                                                                if (prevLang?.length < 5) {
                                                                    const newValue = val; // Get the new value
                                                                    const updatedLang = [...prevLang]; // Copy the previous value
                                                                    updatedLang?.push(newValue); // Add the new value
                                                                    return updatedLang;// Return the updated value
                                                                }
                                                                return prevLang;
                                                            })
                                                            setStaticLanguage_srch('')
                                                        }}> {val} </button>
                                                </div>
                                            );
                                        })}
                                        <button onClick={() => setShowMoreLanguage(!showMoreLanguage)} className="btn viewall-btn-p p-0 m-0 text-black text-decoration-underline">
                                            {showMoreLanguage ? 'See Less' : 'See More'}
                                        </button>
                                    </div>
                                </div>
                            )}
                            <hr className="m-0" />
                        </div>

                        <div className="filter-div">
                            <button
                                className="filter-btn"
                                onClick={() => setSponsorshipDrop(!SponsorshipDrop)}>
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex align-items-center gap-1">
                                        {SponsorshipDrop ? (
                                            <IoIosArrowUp size={18} color="#888" />
                                        ) : (
                                            <IoIosArrowDown size={18} color="#888" />
                                        )}
                                        <p className={SponsorshipDrop ? "active-filter-heading m-0" : "filter-heading mb-0"}>Cut Off Date</p>
                                    </div>
                                    <div>
                                        <button type="button" className="filter-clear-btn" onClick={(e) => {
                                            e.stopPropagation(); setStartSponsorshipDate('');
                                            setEndSponsorshipDate(''); setSponsorshipClosingDate({ start: '', end: '' })
                                        }}>Clear</button>
                                    </div>
                                </div>
                            </button>
                            {SponsorshipDrop && (
                                <div className="filter-content">
                                    <div className="card-body pt-0 m-0">

                                        <div className="d-flex gap-2">
                                            <div className=''>
                                                {/* <label className="form-check p-0">Range:</label> */}
                                                <label className="filter-date-label p-0">From:</label>
                                                <input
                                                    type="date"
                                                    className='filter-date-input'
                                                    value={SponsorshipDate.start}
                                                    min={currentDate}
                                                    onChange={(e) => setStartSponsorshipDate(e.target.value)}
                                                />
                                            </div>

                                            <div className=''>
                                                {/* <label className="form-check p-0">To:</label> */}
                                                <label className="filter-date-label p-0">To:</label>
                                                <input
                                                    type="date"
                                                    className='filter-date-input'
                                                    value={SponsorshipDate.end}
                                                    min={currentDate}
                                                    onChange={(e) => setEndSponsorshipDate(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <button
                                            className="range-submit-btn mt-3"
                                            onClick={manageSponsorshipDate}>
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            )}
                            <hr className="m-0" />
                        </div>

                        <div className="filter-div">
                            <button
                                className="filter-btn"
                                onClick={() => setDistanceDrop(!distanceDrop)}>
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex align-items-center gap-1">
                                        {distanceDrop ? (
                                            <IoIosArrowUp size={18} color="#888" />
                                        ) : (
                                            <IoIosArrowDown size={18} color="#888" />
                                        )}
                                        <p className={distanceDrop ? "active-filter-heading m-0" : "filter-heading mb-0"}>Distance</p>
                                    </div>
                                    <div>
                                        <button type="button" className="filter-clear-btn" onClick={(e) => {
                                            e.stopPropagation(); handleChangeDistanceRange('');
                                            setDistanceRange('')
                                        }}>Clear</button>
                                    </div>
                                </div>
                            </button>
                            {distanceDrop && (
                                <div className="range-content">
                                    <div className="mt-2 px-3 mb-2">
                                        <InputRange
                                            maxValue={999}
                                            minValue={0}
                                            name="distance"
                                            value={distance}
                                            onChange={(newValue) => handleChangeDistanceRange(newValue)}
                                        />
                                    </div>
                                    <button
                                        className="range-submit-btn mt-3"
                                        onClick={HandleDistanceRangeSubmit}>
                                        Submit
                                    </button>
                                </div>
                            )}
                            <hr className="m-0" />
                        </div>

                        <div className="filter-div">
                            <button
                                className="filter-btn"
                                onClick={() => setInterestDrop(!interestDrop)}>
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex align-items-center gap-1">
                                        {interestDrop ? (
                                            <IoIosArrowUp size={18} color="#888" />
                                        ) : (
                                            <IoIosArrowDown size={18} color="#888" />
                                        )}
                                        <p className={interestDrop ? "active-filter-heading m-0" : "filter-heading mb-0"}>Interests</p>
                                    </div>
                                    <div>
                                        <button type="button" className="filter-clear-btn" onClick={(e) => { e.stopPropagation(); setHastag([]); setStaticHastag_srch('') }}>Clear</button>
                                    </div>
                                </div>
                            </button>

                            {interestDrop && (
                                <div>
                                    <div className="py-1 px-3 d-flex hastag-main-div gap-2">
                                        <input className="filter-search-input w-100" placeholder="Search..." value={staticHastag_srch} onChange={(e) => setStaticHastag_srch(e.target.value)} />
                                        {/* <button className="btn-clear" onClick={() => {
                  setStaticHastag_srch('');
                  setHastag([]);
                }}>Clear</button> */}
                                    </div>

                                    {selectedHastags?.length <= 0 ?
                                        "" :
                                        <div>

                                            <div className="hastag-main-div">
                                                {selectedHastags?.map((val) => {
                                                    return (
                                                        <>
                                                            <div className="" key={val?.id}>
                                                                <button
                                                                    key={val?.id}
                                                                    className="selected-IntrestP text-capitalize"
                                                                    onClick={() => {
                                                                        setHastag((prevHastag) => {
                                                                            const newValue = val?.id;
                                                                            if (prevHastag.includes(newValue)) {
                                                                                return prevHastag.filter(
                                                                                    (item) => item !== newValue
                                                                                );
                                                                            }
                                                                        });
                                                                    }}>
                                                                    #{val?.name}
                                                                </button>
                                                            </div>
                                                        </>
                                                    );
                                                })}
                                            </div>
                                            <hr />
                                        </div>
                                    }

                                    <div className="hastag-main-div">
                                        {hashtagsToShow?.filter(fill => fill?.name?.toLowerCase().includes(staticHastag_srch.toLowerCase()))?.map((val) => {
                                            return (
                                                <div key={val?.id}>
                                                    <button
                                                        key={val?.id}
                                                        className="Card-IntrestP text-capitalize"
                                                        onClick={() => {
                                                            setHastag((prevHastag) => {
                                                                if (prevHastag?.length < 5) {
                                                                    const newValue = val?.id; // Get the new value
                                                                    const updatedHastag = [...prevHastag]; // Copy the previous value
                                                                    updatedHastag.push(newValue); // Add the new value
                                                                    return updatedHastag;
                                                                } // Return the updated value
                                                                return prevHastag;
                                                            });
                                                            setStaticHastag_srch('')
                                                        }}> #{val?.name}
                                                    </button>
                                                </div>
                                            );
                                        })}
                                        <button onClick={() => setShowMoreIntrest(!showMoreIntrest)} className="btn viewall-btn-p p-0 m-0 text-black text-decoration-underline">
                                            {showMoreIntrest ? 'See Less' : 'See More'}
                                        </button>
                                    </div>
                                </div>
                            )}
                            <hr className="m-0" />
                        </div>

                        <div className="filter-div">
                            <button className="filter-btn"
                                onClick={() => setPrimeHostDrop(!primeHostDrop)}>
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex align-items-center gap-1">
                                        {primeHostDrop ? (
                                            <IoIosArrowUp size={18} color="#888" />
                                        ) : (
                                            <IoIosArrowDown size={18} color="#888" />
                                        )}
                                        <p className={primeHostDrop ? "active-filter-heading m-0" : "filter-heading mb-0"}>Prime Host</p>
                                    </div>
                                    <div>
                                        <button type="button" className="filter-clear-btn" onClick={(e) => { e.stopPropagation(); setPrime_host(''); }}>Clear</button>
                                    </div>
                                </div>
                            </button>
                            {primeHostDrop && (
                                <div className="filter-content">
                                    <div className="card-body d-flex gap-2 align-items-center mt-0 pt-0">
                                        {/* <p className="p-0 m-0">Is Prime</p> */}
                                        <div className="form-check form-switch d-flex align-items-center gap-3">
                                            <input
                                                className="form-check-input "
                                                type="checkbox"
                                                role="switch"
                                                checked={prime_host}
                                                onChange={handleTogglePrime}
                                            />
                                            {/* <label className="form-check-label my-0">
                                            {prime_host ? 'On' : 'Off'}
                                            </label> */}
                                        </div>

                                    </div>
                                </div>
                            )}
                            <hr className="m-0" />
                        </div>

                        <div className="filter-div">
                            <button
                                className="filter-btn"
                                onClick={() => setPremierDrop(!premierDrop)}>
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex align-items-center gap-1">
                                        {premierDrop ? (
                                            <IoIosArrowUp size={18} color="#888" />
                                        ) : (
                                            <IoIosArrowDown size={18} color="#888" />
                                        )}
                                        <p className={premierDrop ? "active-filter-heading m-0" : "filter-heading mb-0"}>Premier Host</p>
                                    </div>
                                    <div>
                                        <button type="button" className="filter-clear-btn" onClick={(e) => { e.stopPropagation(); setPremier(''); }}>Clear</button>
                                    </div>
                                </div>
                            </button>
                            {premierDrop && (
                                <div className="filter-content">
                                    <div className="card-body d-flex gap-2 align-items-center mt-0 pt-0">
                                        <div className="form-check form-switch d-flex align-items-center gap-3">
                                            <input
                                                className="form-check-input "
                                                type="checkbox"
                                                role="switch"
                                                checked={premier}
                                                onChange={handleTogglePremier}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                            <hr className="m-0" />
                        </div>
                    </div>
                </div>
            </aside>
        </>
    );
};

export default MyPastEventFilters;
