import ImageGallery from "react-image-gallery";
import { IoClose } from "react-icons/io5";
import "react-image-gallery/styles/css/image-gallery.css";
import { useEffect, useRef } from "react";

const ImageGlr=({galleryImg, galleryModal,setGalleryModal})=>{
  const modalRef = useRef(null);


  const handleOutsideClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setGalleryModal(false)
    }
  };

  useEffect(() => {
    if (galleryModal) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [galleryModal]);

    return(<>
    <div className={`gallery-modal ${galleryModal ? "show" : ""}`}>
        <div className="gallery-dialog">
          <div className="gallery-content" ref={modalRef}>
          <button className="gallery-close-btn" onClick={()=>setGalleryModal(false)}><IoClose color="white" size={24} /></button>
            <ImageGallery items={galleryImg} />
        </div>
        </div>
    </div>
    </>)
};
export default ImageGlr;