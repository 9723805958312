import { useEffect,useState, useMemo , useCallback, useRef} from "react";
import { Get_TransctionsListForChamp } from "../../utils/champ/champAlldata";
import moment from "moment";
import Loader2 from "../Loader/Loader2";
import OrderListCard from "../MyOrders/OrderListCard";
import OrderDetaillListCard from "./OrderDetaillListCard";
import ReactPaginate from "react-paginate";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import 'ag-grid-enterprise';
import { Link } from "react-router-dom";

const Transaction = ({ currency, submitSearchValue, statusValue, sortValue, setPdfDownload,setTotalCount}) => {
  const [loading, setLoading] = useState(true);
  const [listData, setListData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currencyIcn, setCurrencyIcn] = useState("");
  const [columnDefs, setColumnDefs] = useState([]);
  const gridRef = useRef();


  const checkCurrency = (data) => {
    data?.map((val) => {
      if (val?.currency == "INR") {
        setCurrencyIcn("₹");
      } else if (val?.currency == "USD") {
        setCurrencyIcn("$");
      } else if (val?.currency == "EUR") {
        setCurrencyIcn("€");
      } else if (val?.currency == "GBP") {
        setCurrencyIcn("€");
      }
    });
  };

  const GetTransctionsList = async () => {
    try {
      setLoading(true);
      const response = await Get_TransctionsListForChamp(submitSearchValue, statusValue, sortValue, currentPage);
      setListData(response?.data?.data);
      setTotalCount(response?.data?.data?.total)
      checkCurrency(response?.data?.data?.data)
      const specificFieldsData = response?.data?.data?.data?.map(item => {
        return {
          'Transaction Id': item?.transaction_id,
          'Event Name': item?.event_title,
          'Package Name': item?.title,
          'Package Amount': item?.package_amount,
          'Coupon Code': item?.coupon_code,
          'Sponsored Amount': item?.amount,
          'Status': item?.payment_status,
          'Date': moment(item?.created_at).format("ll hh:mm A"),
          'Coupon Discount': item?.discount,
        };
      });
      setPdfDownload(specificFieldsData)
      // checkCurrency(response?.data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    GetTransctionsList();
  }, [submitSearchValue, statusValue, sortValue, currentPage]);


  useEffect(() => {
    setColumnDefs([
      {
        headerName: "Sponsor Name",
        field: "sponsor_username",        
        cellRenderer:({ value, data })=> {
          return  <p className=""> {value ? value : "-"}</p>
        }
      },
      {
        headerName: "Transaction Id",
        field: "transaction_id",        
        cellRenderer:({ value, data })=> {
          return  <p className=""> {value ? value : "-"}</p>
        }
      },
      { headerName: "Event", field: "event_title",
        width:120,
        cellRenderer:({ value, data })=> {
         return  <Link
         className="navigate-detail-btn"
         to={`/eventdetail/${data?.event_id}`}
         state={{ type: "Events" }}> 
         <p className="order-list-text text-capitalize">{value}</p> </Link>
       }},
       { headerName:"Package",field:"title", width:120,},

       {headerName:"Package Amount",field:"package_amount", width:150,
        cellRenderer:({ value, data })=> {
        const package_amt = data?.package_amount;
        const formattedAmount = package_amt
        ? `${ currency?.currencySymbol
            ? currency?.currencySymbol
            : currencyIcn
        } ${ currency?.selectedCurrencyPrice
            ? (currency?.selectedCurrencyPrice * package_amt).toFixed(2)
            : package_amt
        }` : 0;
       return <p className="">{formattedAmount}</p>;
       }},
      {
        headerName: "Coupon Code ",
        field: "coupon_code",
        width:150,
        // valueFormatter: ({value, data }) => `${data.currency} ${data?.sponsored_collection}`
      },
      {
        headerName: "Coupon Discount",
        field: "discount",
        width:150,
      },
      {
        headerName: "Sponsored Amount",
        width:150,
        field: "amount",
        cellRenderer:({ value, data })=> {
          const package_amt = data?.amount;
          const formattedAmount = package_amt
          ? `${
            currency?.currencySymbol
              ? currency?.currencySymbol
              : currencyIcn
          } ${
            currency?.selectedCurrencyPrice
            ? (currency?.selectedCurrencyPrice * package_amt).toFixed(2)
            : package_amt
          }` : 0;
         return <p className="">{formattedAmount}</p>;
         }
      },
      {
        headerName: "Income",
        width:150,
        field: "total_commission",
        cellRenderer:({ value, data })=> {
          const package_amt = data?.total_commission;
          const formattedAmount = package_amt
          ? `${
            currency?.currencySymbol
              ? currency?.currencySymbol
              : currencyIcn
          } ${
            currency?.selectedCurrencyPrice
            ? (currency?.selectedCurrencyPrice * package_amt).toFixed(2)
            : package_amt
          }` : 0;
         return <p className="">{formattedAmount}</p>;
         }
      },  
      { headerName: "User Category", field: "membership_plan_name",width:150,
        cellRenderer: ({ value, data }) => {
        return <p className={value == 'Basic' ? "basic-membership-section": "premium-membership-section"}> {value ? value : "-"}</p>
        }},   
      {
        headerName: "Status"  ,
        field: "payment_status",
        width:150,
        cellRenderer: ({value, data}) => {
          const status = data?.payment_status;
        return  <p
          className={
            value == "success"
              ? "success-status-text text-capitalize p-0"
              : value == "failed"
              ? "failed-status-text text-capitalize p-0"
              : "pending-status-text text-capitalize p-0"
          }
          >{value}</p>
        }      
      },
      {
        headerName: "Date",
        field: "created_at",
        cellRenderer:({ value, data })=> {
          return moment(data?.created_at).format("ll hh:mm A");
        },},
    ]);
  }, [currency,listData?.data]);

  const defaultColDef = useMemo(() => {
    return {
      filter: "agTextColumnFilter",
      floatingFilter: true,
    };
  }, [])

  const onGridReady = useCallback((params) => {
  }, []);

  const onBtExport = useCallback(() => {
    gridRef.current.api.exportDataAsExcel();
  }, []);


  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  return (
    <>
    {
        loading ? 
          <Loader2 /> :
     <div>
     <button className="export-to-excel-btn" onClick={onBtExport}>
        Export to Excel
      </button>
      <div className="ag-theme-alpine" style={{ height: 520, width: "100%" }}>
        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          columnDefs={columnDefs}
          rowData={listData?.data}
          defaultColDef={defaultColDef}
          rowSelection="multiple"
        ></AgGridReact>
      </div>
      
    {listData?.data?.length < listData?.total && (
      <div className="pagination-container ">
        <ReactPaginate
          pageCount={Math.ceil(listData?.total / listData?.per_page)}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          onPageChange={handlePageClick}
          containerClassName="pagination"
          activeClassName="selected"
          disabledClassName="disabled"
        />
      </div>
    )}
  </div>
 }
      {/* <div className="transctions-list-div d-flex flex-column ms-1">
        {
          loading ? (
            <Loader2 />
          ) :
            <>
           
              <div className="order-head-div ">
                <div className="row d-flex align-items-center">
                  <div className="col-2 p-0">
                    <p className="order-head-text">Transaction Id</p>
                  </div>

                  <div className="col-2 p-0">
                    <p className="order-head-text">Event /</p>
                    <p className="order-head-text">Package Name</p>
                  </div>

                  <div className="col-1 p-0">
                    <p className="order-head-text">Package Amount</p>
                  </div>

                  <div className="col-2 p-0">
                    <p className="order-head-text">Coupon Code /</p>
                    <p className="order-head-text">Coupon Discount </p>
                  </div>

                  <div className="col-2 p-0">
                    <p className="order-head-text">Sponsored</p>
                    <p className="order-head-text">Amount</p>
                  </div>

                  <div className="col-1 p-0">
                    <p className="order-head-text">Status</p>
                  </div>

                  <div className="col-2 p-0">
                    <p className="order-head-text">Date</p>
                  </div>
                </div>
              </div>

              <div className="champ-list-main-div">
                {listData?.data?.map((val) => {
                  return (<>
                    <OrderDetaillListCard val={val} currency={currency} />
                  </>)
                })}
              </div>
            </>
        }
      </div>
      {listData?.data?.length < listData?.total && (
      <div className="pagination-container ">
        <ReactPaginate
          pageCount={Math.ceil(listData?.total / listData?.per_page)}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          onPageChange={handlePageClick}
          containerClassName="pagination"
          activeClassName="selected"
          disabledClassName="disabled"
        />
      </div>
     )}  */}
    </>
  );
};
export default Transaction;
