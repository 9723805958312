import { useState, useEffect } from "react";
import { BiSolidDonateHeart } from "react-icons/bi";
import { Post_Query } from "../../utils/eventdetail/postquery";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import DateFormat from "../Common/DateFormat";
import IsHostSponsorPopup from "../Common/IsHostSponsorPopup";
import LiveSamplePopup from "../Common/LiveSamplePopup";
import { useSelector } from "react-redux";
import { RiShieldCheckFill } from "react-icons/ri";
import { MdVerified } from "react-icons/md";
import ReactStars from "react-rating-stars-component";

const GotItModal = ({
  gotitModal,
  gotItValue,
  setItModal,
  currency,
  event,
}) => {
  const [query, setQuery] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isShow, setIsShow] = useState(false);

  const ConfigData = useSelector((state) => state?.config_data?.configData);
  const baseUrls = ConfigData?.base_urls;

  const navigate = useNavigate();

  const handleTextChange = (event) => {
    const newText = event.target.value;
    if (newText.length <= 500) {
      setQuery(newText);
    }
  };

  const handleQuery = async (e) => {
    e.preventDefault();
    const id = gotItValue?.id;
    if (query.length >= 3) {
      const discription = {
        discription: query,
      };
      try {
        const sendQuery = await Post_Query(id, discription);
        setQuery("");
        toast.success(sendQuery?.data?.message, {
          position: "top-right",
          autoClose: 3000,
        });
        setErrorMessage("");
        setItModal(false);
      } catch (error) {
        toast.error(error?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
        });
        setErrorMessage("");
        setItModal(false);
      }
    } else {
      setErrorMessage("Query must be at least 3 characters long.");
    }
    const formData = {
      discription: query,
      event_id: gotItValue?.event_id,
    };
  };

  const handleNavigation = () => {
    navigate("/paynow", { state: { data: gotItValue } });
  };

  return (
    <>
      <ToastContainer />
      <div className={`custom-modal ${gotitModal ? "show" : ""}  `}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <div className="d-flex gap-2">
                <BiSolidDonateHeart size={25} color="#f43254" />
                <h5 className="modal-title text-capitalize">{gotItValue?.title}</h5>
              </div>
              <button
                type="button"
                className="btn-close"
                onClick={() => setItModal(false)}
              ></button>
            </div>
            <div className="modal-body">
              <div>
                <div className="row">
                  <p className="col-8 col-md-7 pack-content-text mb-0">
                    {/* Minimum */}
                    Price
                  </p>
                  <p className="col-4 col-md-5 pack-price-text mb-0">
                    {currency?.currencySymbol
                      ? currency?.currencySymbol
                      : gotItValue?.currencies?.symbol}
                    {currency?.selectedCurrencyPrice
                      ? Math.ceil(
                        gotItValue?.amount * currency?.selectedCurrencyPrice
                      )
                      : Math.ceil(gotItValue?.amount)}
                  </p>
                </div>

                <div className="row">
                  <p className="col-8 col-md-7  pack-content-text mb-0">
                    Last Date
                  </p>
                  <p className="col-4 col-md-5 pack-bold-text mb-0">
                    <DateFormat date={gotItValue?.cut_off_date} />
                  </p>
                </div>

                <div className="row">
                  <p className="col-8 col-md-7 pack-content-text">
                    Available Packages:
                  </p>
                  <p className="col-4 col-md-5 pack-bold-text">
                    {gotItValue?.available_package}
                  </p>
                </div>


                <p
                  className="modal-dis"
                  dangerouslySetInnerHTML={{ __html: gotItValue?.discription }}
                ></p>
                <div className="hosted-by-div h-100 overflow-hidden">
                  <b className="label-guest">Event Organizer</b>
                  <div className="d-flex align-items-center gap-3 mt-2">
                    <div className="position-relative">
                      <img
                        className="event-detail-profile"
                        src={
                          event?.users?.image_type == "browse"
                            ? `${baseUrls?.customer_image_url}/${event?.users?.image}`
                            : `${baseUrls?.avatar_image_url}/${event?.users?.predefine_image}`}
                        alt="profile"
                      />
                      <button className="event-detail-shield-btn">
                        <RiShieldCheckFill color="white" size={16} />
                      </button>
                    </div>
                    <div className="d-flex align-items-center gap-2 mt-2">
                      <div>
                        <p className="detail-p-text p-0 m-0">
                          {event?.users?.f_name}{" "}
                          {event?.users?.is_prime_user ? (
                            <MdVerified color="#039be5" />
                          ) : (
                            ""
                          )}
                        </p>
                        <ReactStars
                          count={5}
                          size={15}
                          value={event?.rating}
                          edit={false}
                        />

                        <p className="p-0 m-0">{`${event?.users?.country_code}${event?.users?.phone}`}</p>

                        {event?.users?.email &&
                          <p>
                            {event?.users?.email}
                          </p>}



                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-md-3 mt-4">
                <div className="d-flex justify-content-between">
                  <label className="form-label">Query</label>
                  <p className="Character-0500">
                    Character:
                    <span className="text-style-1">
                      {query.length}
                      {query.length < 500 ? `/${500 - query.length}` : ""}
                    </span>
                  </p>
                </div>
                <textarea
                  className="form-control"
                  placeholder="Write Your Query Here…"
                  value={query}
                  onChange={handleTextChange}
                  name="query"
                  rows="4"
                  cols="50"
                ></textarea>
                <p className="error-text">{errorMessage}</p>
              </div>

              <div className="d-flex flex-column flex-sm-row justify-content-end gap-2 mt-2 mt-md-5">
                <div className="d-flex justify-content-end">
                  <button
                    className="btn text-dark"
                    type="button"
                    onClick={() => setItModal(false)}
                  >
                    Close
                  </button>
                </div>
                <div className="d-flex gap-2 justify-content-end">
                  <button
                    className="query-send-btn d-flex align-items-center gap-2"
                    type="submit"
                    onClick={handleQuery}>
                    <span>Send</span>
                    <div class="svg-wrapper-1">
                      <div class="svg-wrapper">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24">
                          <path fill="none" d="M0 0h24v24H0z"></path>
                          <path
                            fill="currentColor"
                            d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </button>
                  {/* <button type="submit" className="send-Query-btn" onClick={handleQuery}>
                    Send Query
                  </button> */}

                  {/* {event?.status == "Live" ? <button onClick={handleNavigation} className="send-Query-btn">
                    Pay Now
                  </button> :
                    <button onClick={() => setIsShow(true)} className="send-Query-btn">Pay Now</button>} */}

                  <LiveSamplePopup isShow={isShow} setIsShow={setIsShow} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GotItModal;
