import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    champs:{},
}


export const Get_Champs = createSlice({
    name: 'champsData',
    initialState: initialState,
    reducers: {
        getChamps: (state, action) => {
            state.champs = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { getChamps } = Get_Champs.actions;

export default Get_Champs.reducer;