import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/sponsor logo - dark.png"

import { ToastContainer, toast } from "react-toastify";
import ChampSignUpForm from "../../components/SignUp/ChampSignUpForm";
import UserSignUpForm from "../../components/SignUp/UserSignUpForm";


const SignUp = () => {
  const [signupWith, setSignUpWith] = useState("")

  return (
    <>
      <ToastContainer />
      <section className="signup-area">
        <div className="login-signup-header">
          <Link to="/">
            <div className="">
              <img src={logo} className='login-signup-logo' alt="logo" />
            </div>
          </Link>
        </div>

        <div className="d-table1">
          <div className="container-xl">
            <div className="signup-form mt-2">
              <div className="align-items-center">
                <div className="d-sm-flex justify-content-between">
                  <h3 className="m-0">Sign Up {signupWith !== "" && `(${signupWith})`}  </h3>
                  <p className="Dont-have-an-accoun-signup p-0">
                    Already have a SponsorRingUs account?
                    <Link to={"/login"}>
                      <span className="text-style-1 ps-1">Sign In</span>
                    </Link>
                  </p>
                </div>
                <UserSignUpForm />
                {/* <ChampSignUpForm /> */}
                {/* {
                  signupWith == "" &&
                  <div className="row justify-content-center gap-3 signup-option-buttons">
                    <button type="submit" className="login-btn col-md-3 mt-3" onClick={() => setSignUpWith('User')}>
                      User
                    </button>
                    <button type="submit" className="login-btn col-md-3 mt-3" onClick={() => setSignUpWith('Champ')}>
                      Champ
                    </button>
                  </div>
                }

                {
                  signupWith == 'User' ?
                   <UserSignUpForm />
                    :
                    signupWith == 'Champ' ?
                   <ChampSignUpForm />
                    :
                    ""
                } */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SignUp;
