import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ForgotPass } from "../../utils/user/UserManager";
import { ToastContainer,toast } from "react-toastify";
import { Tooltip } from "react-tooltip";

const NewPasswordForm=({setOtpSection,forgotOption,email,mobileForm})=>{
    const [newpPasswordError,setNewpPasswordError]=useState({});
    const [submitBtnLoading,setSubmitBtnLoading]=useState(false);
    const navigate = useNavigate()
    const [newPasswordForm,setNewPasswordForm]=useState({
        newPassword:"",
        confirmNewPassword:""
      });

      console.log("errr",newpPasswordError)

    const handleNewPassword=async(e)=>{
        setNewPasswordForm({...newPasswordForm,[e.target.name]:e.target.value})
     }

       const onNewPasswordFormSubmit=async(e)=>{
        e.preventDefault(); 
        let fData;
        if (forgotOption === "email") {
          fData = {
            email: email,
            password: newPasswordForm?.newPassword,
            password_confirmation: newPasswordForm?.confirmNewPassword,
          };
        } else {
          fData = {
            phone: mobileForm?.mobilenumber,
            country_code: mobileForm?.dialingcode,
            password: newPasswordForm?.newPassword,
            password_confirmation: newPasswordForm?.confirmNewPassword,
          };
        }

        if(validation()){     
          setSubmitBtnLoading(true);
          try{
          const response = await ForgotPass(fData);
          if (response.data.success) {
            setOtpSection(false)
            toast.success(response?.data?.message, {
              position: "top-center",
              autoClose: 3000,
            });
            navigate("/login")
          }
          setNewPasswordForm({
            newPassword:"",
            confirmNewPassword:""
          })

         }catch(err){
          // console.log(err)
          toast.error(err?.response?.data?.message, {
            position: "top-center",
            autoClose: 3000,
          });
        }finally{
          setSubmitBtnLoading(false)
         }
        }
      }
     
      const validation = () => {
        const newErrors = {};

        if (newPasswordForm.newPassword.length < 8) {
          newErrors.newPassword = "Password must be at least 8 characters long.";
        } else if(!/[A-Z]/.test(newPasswordForm.newPassword)) {
          newErrors.newPassword = "Password must contain at least one uppercase letter.";
        }else if (!/[a-z]/.test(newPasswordForm.newPassword)) {
          newErrors.newPassword = "Password must contain at least one lowercase letter.";
        } else if (!/[0-9]/.test(newPasswordForm.newPassword)) {
          newErrors.newPassword = "Password must contain at least one number.";
        } else if (!/[!@#$%^&*]/.test(newPasswordForm.newPassword)) {
          newErrors.newPassword = "Password must contain at least one special character.";
        }
        // else if (/(.)\1{2,}/.test(newPasswordForm.newPassword)) {
        //   newErrors.newPassword = "Your password must not contain more than 2 consecutive identical characters.";
        // }
         else {
          newErrors.newPassword = "";
        } 
        
        if (!newPasswordForm.confirmNewPassword || !newPasswordForm.confirmNewPassword.trim() === "") {
          newErrors.confirmNewPassword = "Confirm password is required";
        } else if (newPasswordForm.newPassword !== newPasswordForm?.confirmNewPassword) {
          newErrors.confirmNewPassword = "Passwords do not match.";
        } else {
          newErrors.confirmNewPassword = "";
        }

        // if (!newPasswordForm.newPassword || newPasswordForm.newPassword.trim() === '') {
        //   newErrors.newPassword = 'Password is required';
        // } else if (newPasswordForm.newPassword.trim().length < 8) {
        //   newErrors.newPassword = 'Password should be at least 8 characters long';
        // } else {
        //   newErrors.newPassword = '';
        // }
      
        // if (!newPasswordForm.confirmNewPassword || newPasswordForm.confirmNewPassword.trim() === '') {
        //   newErrors.confirmNewPassword = 'Confirm password is required';
        // } else if (newPasswordForm.confirmNewPassword !== newPasswordForm.newPassword) {
        //   newErrors.confirmNewPassword = 'Passwords do not match';
        // } else {
        //   newErrors.confirmNewPassword = '';
        // }
        setNewpPasswordError(newErrors);
        // Return true if no errors, false otherwise
        return Object.keys(newErrors).every(key => newErrors[key] === '');
      };


    return(<>
    <Tooltip id="password-example-tooltip"  className="tooltip_style" >
     <div style={{ display: 'flex', flexDirection: 'column',gap:3 }}>
     <span className="tooltip-password-head">Password must contain:</span>
     <span className="mt-2">-At least one uppercase letter </span>
     <span>-At least one lowercase letter</span>
     <span>-At least one special character</span>
     <span>-At least one number</span>
     <span>-Be at least 8 characters</span>
     {/* <span>-Your password must not contain more than 2 consecutive identical characters.</span> */}
     </div>
    </Tooltip>
            <form onSubmit={onNewPasswordFormSubmit}>
                       {/* <div>
                      <div className="form-group mb-2">
                        <label className="forgotpassword-label">Email</label>
                        <input
                          type="email"
                          className="form-control  rounded-3"
                          placeholder="Enter Email"
                          name="email"
                          value={newPasswordForm?.email}
                          onChange={handleNewPassword}
                        />
                      </div>
                      {newpPasswordError.email && <span className="" style={{ color: 'red', fontSize: '13px' }}>{newpPasswordError.email}</span>}
                    </div> */}

                    <div>
                      <div className="form-group mb-0">
                        <label className="forgotpassword-label">New Password</label>
                        <input
                          data-tooltip-id="password-example-tooltip"
                          type="password"
                          className="form-control  rounded-3"
                          placeholder="Enter new password"
                          name="newPassword"
                          value={newPasswordForm?.newPassword}
                          onChange={handleNewPassword}
                        />
                      </div>
                      {newpPasswordError.newPassword && <span className="" style={{ color: 'red', fontSize: '13px' }}>{newpPasswordError.newPassword}</span>}
                    </div>

                    <div className="mt-2">
                      <div className="form-group mb-0">
                        <label className="forgotpassword-label">Confirm New Password</label>
                        <input
                          type="password"
                          className="form-control  rounded-3"
                          placeholder="Enter confirm new password"
                          name="confirmNewPassword"
                          value={newPasswordForm?.confirmNewPassword}
                           onChange={handleNewPassword}
                        />
                      </div>
                      {newpPasswordError.confirmNewPassword && <span className="" style={{color:'red',fontSize:'13px' }}>{newpPasswordError.confirmNewPassword}</span>}
                    </div>

                    <div className="d-flex flex-column mt-3">
                      <button type="submit" className="btn forgotpassword-btn mt-2">
                      {
                       submitBtnLoading &&
                       <div className="spinner-border  text-light" role="status">
                       <span className="sr-only">Loading...</span>
                       </div>
                       }  
                        Submit
                      </button>
                      <Link to="/login" className="forgot-text mt-4">
                        Back To Login
                      </Link>
                    </div>
                  </form>
    </>)
};

export default NewPasswordForm;