import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Autoplay } from "swiper/modules";
import { IoMdInformationCircleOutline } from 'react-icons/io';
import UserAvatarImg from "../../assets/images/user avatar.png"

const Partner = ({ partnerPostarList, baseUrls }) => {
  const [isHovered, setIsHovered] = useState(false);

  const breakpoints = {
    300: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
    1200: {
      slidesPerView: 4,
      spaceBetween: 40,
    },
    1600: {
      slidesPerView: 5,
      spaceBetween: 40,
    },
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleImageError = (event) => {
    event.target.src = UserAvatarImg;
    event.target.onError = null;
  };

  return (
    <section className="partner-area ptb-20">
      <div className="container">
        <div className="section-title d-lg-flex justify-content-between">
          <div>
            <h2 className="our-event-heading">
              {/* Our Event <b>Sponsors</b> */}
              Check who make our Events Possible
            </h2>
          </div>
          {/* <div className="d-flex align-items-center gap-1 position-relative">
            <h2 className="event-heading mb-0 d-flex flex-column flex-md-row align-items-md-center gap-md-2"> Our Event Sponsors</h2>
            <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              <IoMdInformationCircleOutline size={23} />
            </div> {isHovered && (
              <div className="info-popup" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <p className="info-popup-text">Check who make our Events Possible!</p>
              </div>
            )}
          </div> */}
          <div>
            <Link to="/howitworks" className="become-sponsor">
              Become a Sponsor
            </Link>
          </div>
        </div>

        <div className="row">
          <div className="parter-banner d-flex justify-content-center">
            <Swiper
              spaceBetween={0}
              breakpoints={breakpoints}
              modules={[Autoplay]}
              //loop={true}
              //autoplay={{
              //delay: 3000,
              //disableOnInteraction: false,
              //}}
              effect="fade"
              speed={800}
              className="container d-flex justify-content-center">
              {partnerPostarList?.map((item) => {
                return (
                  <SwiperSlide className="parter_slide" key={item?.id}>
                    <div className="">
                      <img className="partnerposter-img" loading='lazy'
                        src={item?.image_type == "browse" ?
                          `${baseUrls?.customer_image_url}/${item?.image}` :
                          `${baseUrls?.avatar_image_url}/${item?.predefine_image}`
                        } alt="Profile"
                        onError={handleImageError}
                      />
                      <p className="text-center mt-2">{`${item?.f_name} ${item?.l_name}`}</p>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Partner;
