import { ChampRegister, CheckSocialMediaUser, Email_Forgot_Password, Email_Forgot_Verify_OTP, Forgot_Password, Mobile_Forgot_Password, Verify_Forgot_Number_OTP, check_log_activity, getProfile, pass_system_ip, system_ip, userChangePassword, userLogin, userRegister, userSocialMediaLogin, userSocialMediaLoginChamp, user_log_out } from "../../Services/Apis"


export const loginUserData = async (userDetails) => {
    try {
        return await userLogin(userDetails).then((data) => data);

    }
    catch (error) {
        throw error
    }
}

export const registerUserData = async (regi_detail) => {
    try {
        const value = await userRegister(regi_detail).then((data) => data)
        return value;
    }
    catch (error) {
        throw error
    }
}

export const Champ_Register = async (champ_FD) => {
    try {
        const value = await ChampRegister(champ_FD).then((data) => data)
        return value;
    }
    catch (error) {
        throw error
    }
}



export const UserProfile = async () => {
    const value = await getProfile().then((data) => data)
    return value
}


// login With Social Media user

export const SocialMediaLogin = async (val) => {
    try {
        return await userSocialMediaLogin(val).then((data) => data);

    }
    catch (error) {
        throw error
    }
}

// login with Social Media champ  SocialMediaLogin_champ 

export const SocialMediaLogin_champ = async (val) => {
    try {
        return await userSocialMediaLoginChamp(val).then((data) => data);

    }
    catch (error) {
        throw error
    }
}


// get ip address log activity 3rd party api
export const systemIpAddress = async () => {
    try {
        const value = await system_ip().then((data) => data)
        return value
    } catch (err) {
        throw err
    }

}


// pass ip address log activity 3rd party api
export const pass_systemIpAddress = async (ip) => {
    try {
        const value = await pass_system_ip(ip).then((data) => data)
        return value
    } catch (err) {
        throw err
    }

}


// log activity local api
export const logActivityCheck = async (val) => {
    try {
        return await check_log_activity(val).then((data) => data);
    }
    catch (error) {
        throw error
    }
}

//user logout api
export const userLogoutApi = async (data) => {
    try {
        return await user_log_out(data).then((data) => data);
    }
    catch (error) {
        throw error
    }
}

// CheckSocialMediaUser
export const Check_SocialMediaUser = async (fdata) => {
    try {
        return await CheckSocialMediaUser(fdata).then((data) => data);
    }
    catch (error) {
        throw error
    }
}


// user Change passWord 
export const ChangeUserPassword = async (Data) => {
    try {
        return await userChangePassword(Data).then((data) => data);

    }
    catch (error) {
        throw error
    }
}

// Email_Forgot_Password
export const Email_ForgotPassword = async (Data) => {
    try {
        return await Email_Forgot_Password(Data).then((data) => data);
      }
    catch (error) {
        throw error
    }
}

// Email_Forgot_Password
export const Email_ForgotVerify_OTP = async (Data) => {
    try {
        return await Email_Forgot_Verify_OTP(Data).then((data) => data);
      }
    catch (error) {
        throw error
    }
}

// forgot passWord 
export const ForgotPass = async (Data) => {
    try {
        return await Forgot_Password(Data).then((data) => data);

    }
    catch (error) {
        throw error
    }
}

// forgot passWord 
export const Mobile_ForgotPassword = async (Data) => {
    try {
        return await Mobile_Forgot_Password(Data).then((data) => data);
    }
    catch (error) {
        throw error
    }
}

export const Verify_ForgotNumber_OTP = async (Data) => {
    try {
        return await Verify_Forgot_Number_OTP(Data).then((data) => data);
    }
    catch (error) {
        throw error
    }
}
