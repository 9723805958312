// import moment from "moment";
// import { Link, useNavigate } from "react-router-dom";
// import dImage from '../../assets/images/default-image.jpg';

// const EventsListCard = ({baseUrls, val, isValid }) => {

//   const cardImageError = (event) => { 
//     event.target.src = dImage;
//     event.target.onError = null;
//   };

//   return (<>
//     <div className="listcard-div">

//       <div className="row justify-content-center align-items-center">
//         <div className="col-md-2">
//           <Link className="navigate-detail-btn" to={`/eventdetail/${val?.id}`} state={{ type: 'Events' }}>
//             <img className="listcard-img object-fit-cover" src={`${baseUrls?.logo_image_url}/${val?.logo}`} 
//              onError={cardImageError}
//              alt="img" />
//           </Link>

//         </div>
//         <div className="col-md-2">
//           <Link className="navigate-detail-btn" to={`/eventdetail/${val?.id}`} state={{ type: 'Events' }}>
//             <p className="listcard-title" title={val?.event_title}>{val?.event_title}</p>
//           </Link>
//         </div>

//         <div className="col-md-2">
//           <p className="listcard-title">{moment(val?.start_date).format('DD-MM-YYYY')}</p>
//         </div>

//         <div className="col-md-3">
//           <p className="listcard-title" title={val?.address}>{val?.address}</p>
//         </div>

//         <div className="col-md-2">
//           <p className="listcard-title">{val?.event_status}</p>
//         </div>

//         {/* {isValid && <div className="col-md-1">
//           <button className="listevent-editbtn">Edit</button>
//         </div>} */}

//       </div>
//     </div>
//   </>)
// };

// export default EventsListCard;
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import { FaRegCalendarAlt } from 'react-icons/fa';
import { MdVerified } from 'react-icons/md';
import { Link } from 'react-router-dom';

const EventsListCard = ({ rowData, baseUrls, setHastag, setInterestDrop }) => {
  const [currencyIcn, setCurrencyIcn] = useState('');
  const [columnDefs, setColumnDefs] = useState([]);
  const gridRef = useRef();

  const onGridReady = (params) => {
    gridRef.current = params.api;
  };

  const onBtExport = useCallback(() => {
    gridRef.current.api.exportDataAsExcel();
  }, []);

  useEffect(() => {
    rowData?.map((val) => {
      if (val?.currency_code == "INR") {
        setCurrencyIcn("₹");
      } else if (val?.currency_code == "USD") {
        setCurrencyIcn("$");
      } else if (val?.currency_code == "EUR") {
        setCurrencyIcn("€");
      } else if (val?.currency_code == "GBP") {
        setCurrencyIcn("€");
      }
    })
  }, [rowData]);

  // console.log("currencyIcn",currencyIcn)

  const AvatarRenderer = ({ value, data }) => {
    return (
      <div className="">
        <Link
          to={`/eventdetail/${data?.id}`} state={{ type: 'Events' }}
        >
          <img
            className="listcard-img object-fit-cover"
            src={`${baseUrls?.logo_image_url}/${data?.logo}`}
            alt="img"
          />
        </Link>
      </div>
    );
  };


  const defaultColDef = useMemo(() => {
    return {
      filter: "agTextColumnFilter",
      floatingFilter: true,
    };
  }, []);

  useEffect(() => {
    setColumnDefs([
      {
        headerName: "Image",
        field: "logo",
        width: 100,
        cellRenderer: AvatarRenderer,
        filter: false,
      },
      {
        headerName: "Title",
        field: "event_title",
        cellRenderer: ({ value, data }) => {
          return (<div className="d-flex gap-1 align-items-center">
            <Link to={`/eventdetail/${data?.id}`} state={{ name: 'Events' }}>
              <p className="text-dark">{value ? value : "-"}</p>
            </Link>
          </div>
          );
        },
        // filter: params => params.data.editable,
      },
      {
        headerName: "Start Date",
        field: "start_date",
        cellRenderer: ({ value, data }) => {
          return (<div className="d-flex gap-1 align-items-center">
            <p className="text-dark">{moment(value).format("ddd, DD MMM YYYY")} </p>

          </div>
          );
        },
        // cellRenderer: AvatarRenderer,
        // filter: params => params.data.editable,
      },
      {
        headerName: "End Date",
        field: "end_date",
        cellRenderer: ({ value, data }) => {
          return (<div className="d-flex gap-1 align-items-center">
            <p className="text-dark">{moment(value).format("ddd, DD MMM YYYY")} </p>

          </div>
          );
        },
        // cellRenderer: AvatarRenderer,
        // filter: params => params.data.editable,
      },
      {
        headerName: "Publish Date",
        field: "published_date",
        cellRenderer: ({ value, data }) => {
          return (
            <>
              <div className="d-flex align-items-center gap-1 ">
                <p className=" text-dark" title={moment(value).format("ddd, DD MMM YYYY")}>
                  {moment(value).format("ddd, DD MMM YYYY")}
                </p>
              </div>
            </>
          );
        },
      },
      {
        headerName: "CutOff Date",
        field: "published_end_date",
        cellRenderer: ({ value, data }) => {
          return (
            <>
              <div className="d-flex align-items-center gap-1 ">
                <p className=" text-dark" title={moment(value).format("ddd, DD MMM YYYY")}>
                  {moment(value).format("ddd, DD MMM YYYY")}
                </p>
              </div>
            </>
          );
        },
      },
      {
        headerName: "Event Mode",
        field: "event_mode",
        cellRenderer: ({ value, data }) => {
          const event_mode = value.replace('_', ' ')
          return (<div className="d-flex gap-1 align-items-center">
            <p className="text-dark">{event_mode} </p>

          </div>
          );
        },
        // cellRenderer: AvatarRenderer,
        // filter: params => params.data.editable,
      },
      {
        headerName: "Status",
        field: "status",
        width: 120,
        // cellRenderer: AvatarRenderer,
        // filter: params => params.data.editable,
      },
      {
        headerName: "Event Status",
        field: "event_status",
        width: 120,
        // cellRenderer: AvatarRenderer,
        // filter: params => params.data.editable,
      },
      {
        headerName: "Location",
        field: "address",
        width: 350,
        cellRenderer: ({ value, data }) => {
          return (
            <>
              {value ?
                <p className="text-dark" title={`${data?.city?.city_name}, ${data?.state?.short_name}, ${data?.country?.short_name}`}>
                  {/* {value}, {data?.city?.city_name}, {data?.state?.short_name}, {data?.country?.short_name} */}
                  {data?.city?.city_name}, {data?.state?.short_name}, {data?.country?.short_name}
                </p>
                : <p className="text-dark" title={`${data?.city?.city_name}, ${data?.state?.short_name}, ${data?.country?.short_name}`}>
                  {data?.city?.city_name}, {data?.state?.short_name}, {data?.country?.short_name}
                </p>
              }
            </>
          );
        },
        // cellRenderer: AvatarRenderer,
        // filter: params => params.data.editable,
      },
      {
        headerName: "From",
        field: "min_amount",
        cellRenderer: ({ value, data }) => {
          return (
            <>
              <div className="d-flex align-items-center gap-1">
                <p className="feature-card-cutoff-p">
                  {currencyIcn}{value}
                </p>
              </div>
            </>
          );
        },
      },
      // {
      //   headerName: "Max",
      //   field: "max_amount",
      //   cellRenderer: ({ value, data }) => {
      //     return (
      //       <>
      //        <div className="d-flex align-items-center gap-1">
      //         <p className="feature-card-cutoff-p">
      //          {currencyIcn}{value}  
      //         </p>
      //       </div>
      //       </>
      //     );
      //   },
      // },
      {
        headerName: "Interests",
        field: "tags",
        width: 350,
        cellRenderer: ({ value, data }) => {
          return (
            <div>
              <div className='Card-IntrestD'>
                {value?.length > 0 && value?.map((item) => {
                  return (
                    <button key={item?.id} className="Card-IntrestP"
                      onClick={() => {
                        setHastag(prevHastag => {
                          const newValue = item?.interest_id; // Get the new value
                          const updatedHastag = [...prevHastag]; // Copy the previous value
                          updatedHastag?.push(newValue); // Add the new value
                          return updatedHastag; // Return the updated value
                        });
                        setInterestDrop(true)
                      }}>
                      #{item?.user_interests?.name}
                    </button>
                  )
                })}
              </div>
            </div>
          )
        },
        // cellRenderer: AvatarRenderer,
        // filter: params => params.data.editable,
      },
      // {
      //   headerName: "Action",
      //   field: "id",
      //   cellRenderer: ({ value, data, rowIndex }) => {
      //     console.log("data", data)
      //     return (
      //       <div className="d-flex gap-2">
      //         <button
      //           className="create-Date-Time"
      //           type="button"
      //           onClick={() => handleEditPartnerShip(value, data, rowIndex)}
      //         >
      //           Edit
      //         </button>
      //         <button
      //           className="create-Date-Time"
      //           type="button"
      //           onClick={() => handledeletePartnerShip(value, data, rowIndex)}
      //         >
      //           Remove
      //         </button>
      //       </div>
      //     );
      //   },
      // },

    ]);
  }, []);


  return (
    <>
      <div className="ag-theme-alpine p-0" style={{ height: 600, width: "100%" }}>
        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          columnDefs={columnDefs}
          rowData={rowData}
          defaultColDef={defaultColDef}
          rowSelection="multiple"
        ></AgGridReact>
      </div>
    </>
  )
}
export default EventsListCard;