import { useState, useEffect } from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation, Scrollbar, A11y } from 'swiper/modules'
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { GoDotFill } from "react-icons/go";
import { RxCheck } from "react-icons/rx";
import { RiShieldCheckFill } from 'react-icons/ri';
import defaultImage from "../../../assets/images/default-image.jpg";
import errow from "../../../assets/images/arrow.svg";
import { FaLocationDot } from 'react-icons/fa6';
import { BsFillTicketDetailedFill } from "react-icons/bs";
import moment from 'moment';
import { FaRegCalendarAlt } from 'react-icons/fa';
import FeaturedCard from "../../Home/Featuredcard"
import EventHostUserListing from './EventHostUserListing';


const EventHostUi = ({ userAllEvent, event, id }) => {
    const location = useLocation();
    const ConfigData = useSelector((state) => state?.config_data?.configData);
    const baseUrls = ConfigData?.base_urls;

    const birthDate = event?.users?.date_of_birth;
    const birthYear = birthDate ? new Date(birthDate).getFullYear() : '';
    const eventhostImage = location?.pathname?.split("/")[1];

    const [open, setOpen] = useState(false)
    const [currencyIcn, setCurrencyIcn] = useState('');


    useEffect(() => {
        if (userAllEvent?.currency_code == "INR") {
            setCurrencyIcn("₹");
        } else if (userAllEvent?.currency_code == "USD") {
            setCurrencyIcn("$");
        } else if (userAllEvent?.currency_code == "EUR") {
            setCurrencyIcn("€");
        } else if (userAllEvent?.currency_code == "GBP") {
            setCurrencyIcn("€");
        }
    }, [userAllEvent?.currency_code]);

    const [reviews, setReviews] = useState([
        {
            name: 'Kousik',
            date: 'July 2024',
            review: '“...Lastly, Prateek ji, a big thank you for all your support and local recommendations. Also, my spouse and me would like to acknowledge the efforts and thought process put in by you and your..."',
            imageUrl: 'https://www.sponsorringus.com/backend/storage/app/public/event_banner_image/2024-07-12-66910dce388f5.jpg'
        },
        {
            name: 'Adithya',
            date: 'July 2024',
            review: '“...Prateek was a great host and the place was exactly as described in the listing. I would definitely come back here again!”',
            imageUrl: 'https://www.sponsorringus.com/backend/storage/app/public/event_banner_image/2024-07-12-66910dce388f5.jpg'
        },
        {
            name: 'Suresh',
            date: 'August 2024',
            review: '“...Amazing place! Very hospitable and clean. Would highly recommend!”',
            imageUrl: 'https://www.sponsorringus.com/backend/storage/app/public/event_banner_image/2024-07-12-66910dce388f5.jpg'
        },
        {
            name: 'Anita',
            date: 'September 2024',
            review: '“...A perfect getaway with all the necessary amenities. Thank you for a wonderful stay!”',
            imageUrl: 'https://www.sponsorringus.com/backend/storage/app/public/event_banner_image/2024-07-12-66910dce388f5.jpg'
        }
    ])


    const getDurationInYearsAndMonths = (createdAt) => {
        const createdDate = new Date(createdAt);
        const now = new Date();
        let years = now.getFullYear() - createdDate.getFullYear();
        let months = now.getMonth() - createdDate.getMonth();
        // Adjust for cases where the end month is earlier than the start month
        if (months < 0) {
            years--;
            months += 12;
        }
        // Adjust for cases where the end day is earlier than the start day in the same month
        if (now.getDate() < createdDate.getDate()) {
            months--;
            if (months < 0) {
                years--;
                months += 12;
            }
        }
        return { years, months };
    }
    const duration = getDurationInYearsAndMonths(event?.users?.created_at);

    const formatLanguages = (languages) => {
        if (languages.length === 0) return '';
        if (languages.length === 1) return languages[0].language;
        if (languages.length === 2) return `${languages[0].language} and ${languages[1].language}`;

        return languages.reduce((acc, curr, index) => {
            if (index === 0) return curr.language;
            if (index === languages.length - 1) return `${acc}, and ${curr.language}`;
            return `${acc}, ${curr.language}`;
        }, '');
    };

    return (
        <>
            <div className="app mt-2">
                <div className="d-sm-flex">
                    <div className="col-12 col-sm-4 col-xl-4 ">
                        <div className="profile-header-h">
                            <div className="profile-picture-h position-relative">
                                <img src={event?.users?.image_type == "browse" ?
                                    `${baseUrls?.customer_image_url}/${event?.users?.image}` :
                                    `${baseUrls?.avatar_image_url}/${event?.users?.predefine_image}`
                                } alt="Profile" />
                                <button className="event-detail-host-section-shield-btn">
                                    <RiShieldCheckFill color="white" size={14} />
                                </button>
                                <h5 className="event-host-name-title text-capitalize py-1 m-0">{event.users?.f_name}</h5>
                                <div className="super-host-h">Superhost</div>
                            </div>
                            <div className="profile-details-h">
                                <div className="reviews-h">
                                    <span className="span-text-h"><b className="b-bold-h">{event?.views}</b> <br />Reviews</span>
                                    <hr className="m-1" />
                                    <span className="span-text-h"><b className="b-bold-h">{event?.rating} ★</b> <br />Rating</span>
                                    <hr className="m-1" />
                                    <span className="span-text-h"><b className="b-bold-h"> {duration?.years == '0' ? duration?.months : duration?.years}  </b><br />{duration?.years == '0' ? 'Months' : "Years"} hosting</span>
                                </div>
                            </div>
                        </div>
                        <div className="confirmed-info-h mt-4">
                            <p>{event?.users?.f_name}'s confirmed information</p>
                            <ul>
                                <li>{event?.users?.verify_aadhar ? <RxCheck size={20} color="green" /> : <RxCheck size={20} color="orange" />}
                                    Aadhaar Card
                                </li>
                                <li>{event?.users?.verify_email ? <RxCheck size={20} color="green" /> : <RxCheck size={20} color="orange" />}
                                    Email address
                                </li>
                                <li>{event?.users?.verify_phone ? <RxCheck size={20} color="green" /> : <RxCheck size={20} color="orange" />}
                                    Phone Number
                                </li>
                            </ul>

                            <div onClick={() => setOpen(true)}>
                                <a href='#'>Learn about identity verification</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-8 col-xl-8">
                        <div className="profile-info-h">
                            <div className="about-h mt-3">
                                <h4 className='event-host-name-title text-capitalize'>About {event?.users?.f_name}</h4>
                                <div className="row">
                                    <div className="col-6 event-host-p">Hosted By {event?.users?.f_name} {event?.users?.l_name}</div>
                                    <div className="col-6 event-host-p">Born in the {birthYear}s</div>
                                </div>

                                <div className="row mt-2">
                                    <div className="col-6 event-host-p">{userAllEvent?.length} Publish Event</div>
                                </div>

                                <div className="row mt-2">
                                    <div className="col-6 d-flex gap-1 event-host-p">
                                        Speaks {formatLanguages(event?.users?.languages)}
                                    </div>
                                    <div className="col-6 event-host-p">Lives in {event?.users?.city?.city_name}, {event?.users?.country?.country_name}</div>
                                </div>

                                <p className="mt-2" dangerouslySetInnerHTML={{ __html: event?.users?.my_journey }}></p>
                                {event?.users?.website_link &&
                                    <Link to={event?.users?.website_link} target='_blank'>Check us out on website</Link>
                                }
                            </div>
                        </div>
                        <hr className="mx-5" />
                        {/* <div className="reviews-section-h">
                            <h5 className='event-host-name-title'>What guests are saying about {event?.users?.f_name}</h5>
                            <div className="">
                                <Swiper
                                    spaceBetween={10}
                                    slidesPerView={2}
                                    modules={[Autoplay, Scrollbar, A11y, Navigation, Pagination]}
                                    loop={true}
                                    effect="fade"
                                    speed={1000}
                                    className=''>
                                    <div className=''>    
                                    {reviews?.map((item, index) => {
                                        return (
                                            <SwiperSlide key={index}>
                                                <div className=''>
                                                <div className="review-card-h">
                                                    <div className="d-flex gap-3">
                                                        <img src={item.imageUrl} alt={item.name} />
                                                        <div>
                                                            <span className="py-0 my-0 ">{item?.name}</span>
                                                            <span className="py-0 my-0 detail-p-text">{item?.date}</span>
                                                        </div>
                                                    </div>
                                                    <p className="mt-3 event-host-review-p">{item?.review}</p>
                                                </div>
                                                </div>
                                            </SwiperSlide>
                                        );
                                    })}
                                    </div>
                                </Swiper>
                            </div>
                        </div> */}
                        <div className="listings-section-h">
                            <div className="d-flex justify-content-between align-items-center mb-1">
                                {userAllEvent?.length > 1 ? (
                                    <h5 className='event-host-name-title text-capitalize'>Other Events by {event.users?.f_name}</h5>
                                ) : userAllEvent?.length > 0 ? (
                                    <h5 className='event-host-name-title text-capitalize'>There are no Events by {event.users?.f_name}</h5>
                                ) : ""
                                }
                                {(eventhostImage === 'eventdetail' || eventhostImage === 'preview') && (
                                    userAllEvent?.length > 2 &&
                                    <button className="view-all-btn">
                                        <div className="d-flex gap-3 align-items-center">
                                            <Link to={`/events/${event?.users?.id}`} className="viewall-btn-p mb-0">View All <img className="" src={errow} alt="img" /></Link>
                                        </div>
                                    </button>
                                )}
                                {/* } */}
                            </div>
                            <div className="listings-h">
                                <Swiper
                                    spaceBetween={40}
                                    slidesPerView={2}
                                    modules={[Autoplay, Scrollbar, A11y, Navigation, Pagination]}
                                    autoplay={{ delay: 3000 }}
                                    loop={true}
                                    effect="fade"
                                    speed={1000}>
                                    {userAllEvent?.filter((item) => item?.id != id)
                                        ?.map((val, index) => {
                                            return (
                                                <SwiperSlide key={index}>
                                                    <EventHostUserListing baseUrls={baseUrls} val={val} eventhostImage={eventhostImage} />
                                                </SwiperSlide>
                                            )
                                        })}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {open &&
                <div className="logout-popup-overlay">
                    <div className="logout-popup">
                        <p><b>Verified Identity</b> badge means that they have provided identification information for completing our verification process.
                            Safeguards have been built up in the process but is not a guarantee that someone is who they claim to be.</p>
                        <button
                            onClick={() => setOpen(false)}
                            className="ok-button">OK</button>
                    </div>
                </div>
            }
        </>
    );
};

export default EventHostUi;