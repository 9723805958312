import React, { useState, useEffect, useRef } from "react";
import arrow from "../../assets/images/arrow2.svg";
import ConditionButton from "../Common/CreateEventConditionButton";
import { Confirmed_Sponsor_Dlt, Confirmed_Sponsor_Update, CreateEventPitch, GetSingleConfirmedSponsor } from "../../utils/CreateEvents/createEvent";
import ReactQuill from "react-quill";
import { Get_Edit_Event_Pitch } from "../../utils/EditEvent/editEvent";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";
import { IoClose } from "react-icons/io5";
import { toast } from "react-toastify";
import AddressSection from "../Common/CreateEvent/AddressSection";
import PhoneInput from "react-phone-input-2";
import { Express_EventPitch, Express_Sponsor_DetailsUpdate } from "../../utils/CreateExpreesEvent/CreateExpressEvent";

const ExpressEventPitchForm = ({ setTabValue, event_data, Messages, isEdit, Eid, tabValue }) => {
  const [openSponsorCharacteristics, setOpenSponsorCharacteristics] = useState("0");
  const [sponsorshipPreferences, setSponsorshipPreferences] = useState("0");
  const [promotionalPlan, setPromotionalPlan] = useState("0");
  const [confirmedSponsors, setConfirmedSponsors] = useState("0");
  const [confirmedSponsorId, setConfirmedSponsorId] = useState(null)
  const [updateCheck, setUpdateCheck] = useState(false)
  const [normalEdit, setNormalEdit] = useState(false)
  const [editSponsorsList, setEditSponsorsList] = useState([]);
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const [selectedCurrencySymbol, setSelectedCurrencySymbol] = useState(3);
  const ConfigData = useSelector((state) => state?.config_data?.configData);
  const baseUrls = ConfigData?.base_urls;
  const [isHovered, setIsHovered] = useState(false);

  const [input, setInput] = useState({
    why_partner_with_us: "",
    event_theme: "",
  });

  const refs = {
    event_theme: useRef(null),
    preferred_categories: useRef(null),
    before_brand_visibility: useRef(null),
    during_brand_visibility: useRef(null),
    after_brand_visibility: useRef(null),
    engagement_opportunities: useRef(null),
    desired_sponsor_characteristics: useRef(null),
    sponsorship_preferences: useRef(null),
    promotional_plan: useRef(null),
    media_exposure: useRef(null),
    social_media_campaigns: useRef(null),
    any_sponsor_characteristics: useRef(null),
    any_confirmed_sponsors: useRef(null),
    any_promotional_plan: useRef(null),
    any_sponsorship_preferences: useRef(null),
  };
  const [error, setError] = useState({});
  
  const [sponsors, setSponsors] = useState([]);
  const [sponsorData, setSponsorData] = useState({
    sponsor_name: "",
    sponsors_brand_logo: null,
    inclusions: "",
    sponsor_address:"",
    sponsor_number:"",
    sponsored_package_rate:"",
    currency_id:"",
    country_code:+91,
  });

  const fileInputRef = useRef(null);
  const [sponsorError, setSponsorError] = useState({});

  const getData = async () => {
    try {
      await Get_Edit_Event_Pitch(Eid)
        .then((response) => {
          const data = response?.data?.data
          setInput(data)
          setOpenSponsorCharacteristics(data?.any_sponsor_characteristics)
          setConfirmedSponsors(data?.any_confirmed_sponsors)
          setSponsorshipPreferences(data?.any_sponsorship_preferences)
          setPromotionalPlan(data?.any_promotional_plan)
          setEditSponsorsList(data?.new_sponsor)
          console.log("data",data);
        })
    } catch (errr) {
      console.log(errr)
    }
  }

  useEffect(() => {
    if (isEdit && tabValue == "Event-Pitch") {
      getData();
    }
  }, [Eid])

  useEffect(() => {
    setInput({
      ...input,
      any_confirmed_sponsors: confirmedSponsors,
    });
  }, [
    confirmedSponsors,
  ]);


  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "sponsors_brand_logo") {
      setInput({
        ...input,
        [name]: files[0],
      });
    } else {
      setInput({
        ...input,
        [name]: value,
      });
    }
  };


  const onSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()){
      const fdata = {
        ...input,
        new_sponsor: [...editSponsorsList, ...sponsors,]
      }
      if (confirmedSponsors == '1') {
        if (editSponsorsList?.length > 0 || sponsors?.length > 0) {
          setSaveBtnLoading(true);
          try {
            await Express_EventPitch(fdata, Eid).then((results) => {
              if (results?.data?.success) {
                setTabValue("Sponsor-Packages");
                Messages(results?.data?.message);
              }
            });
          } catch (error) {
            console.log(error)
          } finally {
            setSaveBtnLoading(false)
          }
        } else {
          toast.warn("Sponsor is required", {
            position: "top-center",
          });
        }
      } else {
        try {
          setSaveBtnLoading(true);
          await Express_EventPitch(fdata,Eid).then((results) => {
            if (results?.data?.success) {
              setTabValue("Sponsor-Packages");
              Messages(results?.data?.message);
            }
          });
        } catch (error) {
          console.log(error);
        } finally {
          setSaveBtnLoading(false)
        }
    }}
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    // if (!input.event_theme.trim() == "") {
    //   newErrors.event_theme = "Event Theme is required";
    //   valid = false;
    // }

    // const preferred = input?.preferred_categories?.replace(/<p><br><\/p>/g, '')
    // if (preferred?.trim() == "") {
    //   newErrors.preferred_categories = "Preferred Categories is required";
    //   valid = false;
    // }

   
    setError(newErrors);
    return valid;
  };

  // reactQuill function -------------
  const handleWhyPartnerWithUs = (value) => {
    const text = value.replace(/<[^>]*>?/gm, "");
    if (text?.length <= 2500) {
      setInput((prevState) => ({
        ...prevState,
        why_partner_with_us: value,
      }));
      setError({
        ...error,
        why_partner_with_us: "",
      });
    } else {
      setError({
        ...error,
        why_partner_with_us: "Why Partner With Us cannot exceed 2500 characters.",
      });
    }
  };

  const handleEventTheme = (value) => {
    const text = value.replace(/<[^>]*>?/gm, "");
    if (text.length <= 500) {
      setInput((prevState) => ({
        ...prevState,
        event_theme: value,
      }));
      setError({
        ...error,
        event_theme: "",
      });
    } else {
      setError({
        ...error,
        event_theme: "Event Theme cannot exceed 500 characters.",
      });
    }
  };

  const handlePreferredCategories = (value) => {
    const text = value.replace(/<[^>]*>?/gm, "");
    if (text.length <= 500) {
      setInput((prevState) => ({
        ...prevState,
        preferred_categories: value,
      }));
      setError({
        ...error,
        preferred_categories: "",
      });
    } else {
      setError({
        ...error,
        preferred_categories:
          "Preferred Categories cannot exceed 500 characters.",
      });
    }
  };

  const handleBeforeVisibility = (value) => {
    const text = value.replace(/<[^>]*>?/gm, "");
    if (text.length <= 500) {
      setInput((prevState) => ({
        ...prevState,
        before_brand_visibility: value,
      }));
      setError({
        ...error,
        before_brand_visibility: "",
      });
    } else {
      setError({
        ...error,
        before_brand_visibility:
          "Before Brand Visibility cannot exceed 500 characters.",
      });
    }
  };

  const handleDuringVisibility = (value) => {
    const text = value.replace(/<[^>]*>?/gm, "");
    if (text.length <= 500) {
      setInput((prevState) => ({
        ...prevState,
        during_brand_visibility: value,
      }));
      setError({
        ...error,
        during_brand_visibility: "",
      });
    } else {
      setError({
        ...error,
        during_brand_visibility:
          "During Brand Visibility cannot exceed 500 characters.",
      });
    }
  };

  const handleAfterVisibility = (value) => {
    const text = value.replace(/<[^>]*>?/gm, "");
    if (text.length <= 500) {
      setInput((prevState) => ({
        ...prevState,
        after_brand_visibility: value,
      }));
      setError({
        ...error,
        after_brand_visibility: "",
      });
    } else {
      setError({
        ...error,
        after_brand_visibility:
          "After Brand Visibility cannot exceed 500 characters.",
      });
    }
  };

  const handleEngagementOpportunities = (value) => {
    const text = value.replace(/<[^>]*>?/gm, "");
    if (text.length <= 500) {
      setInput((prevState) => ({
        ...prevState,
        engagement_opportunities: value,
      }));
      setError({
        ...error,
        engagement_opportunities: "",
      });
    } else {
      setError({
        ...error,
        engagement_opportunities:
          "Engagement Opportunities cannot exceed 500 characters.",
      });
    }
  };

  const handleDesiredCharacteristics = (value) => {
    const text = value.replace(/<[^>]*>?/gm, "");
    if (text.length <= 500) {
      setInput((prevState) => ({
        ...prevState,
        desired_sponsor_characteristics: value,
      }));
      setError({
        ...error,
        desired_sponsor_characteristics: "",
      });
    } else {
      setError({
        ...error,
        desired_sponsor_characteristics:
          "Desired Sponsor Characteristics cannot exceed 500 characters.",
      });
    }
  };

  const handleSponsorshipPreferences = (value) => {
    const text = value.replace(/<[^>]*>?/gm, "");
    if (text.length <= 500) {
      setInput((prevState) => ({
        ...prevState,
        sponsorship_preferences: value,
      }));
      setError({
        ...error,
        sponsorship_preferences: "",
      });
    } else {
      setError({
        ...error,
        sponsorship_preferences:
          "Sponsorship Preferences cannot exceed 500 characters.",
      });
    }
  };

  const handlePromotionalPlan = (value) => {
    const text = value.replace(/<[^>]*>?/gm, "");
    if (text.length <= 500) {
      setInput((prevState) => ({
        ...prevState,
        promotional_plan: value,
      }));
      setError({
        ...error,
        promotional_plan: "",
      });
    } else {
      setError({
        ...error,
        promotional_plan:
          "Promotional Plan cannot exceed 500 characters.",
      });
    }
  };

  const handleMediaExposure = (value) => {
    const text = value.replace(/<[^>]*>?/gm, "");
    if (text.length <= 500) {
      setInput((prevState) => ({
        ...prevState,
        media_exposure: value,
      }));
      setError({
        ...error,
        media_exposure: "",
      });
    } else {
      setError({
        ...error,
        media_exposure:
          "Media Exposure Characteristics cannot exceed 500 characters.",
      });
    }
  };

  const handleSocialMediaCampaigns = (value) => {
    const text = value.replace(/<[^>]*>?/gm, "");
    if (text.length <= 500) {
      setInput((prevState) => ({
        ...prevState,
        social_media_campaigns: value,
      }));
      setError({
        ...error,
        social_media_campaigns: "",
      });
    } else {
      setError({
        ...error,
        social_media_campaigns:
          "Social Media Campaigns Characteristics cannot exceed 500 characters.",
      });
    }
  };

  const handleSponsorDetails = (value) => {
    const text = value.replace(/<[^>]*>?/gm, "");
    if (text.length <= 500) {
      setSponsorData((prevState) => ({
        ...prevState,
        inclusions:value,
      }));
      setSponsorError({
        ...sponsorError,
        inclusions: "",
      });
    } else {
      setSponsorError({
        ...sponsorError,
        inclusions:
          "Sponsor Details cannot exceed 500 characters.",
      });
    }
  }

  const handleSponsorChange = (e) => {
    const { name, value, files } = e.target;
    if (name == "sponsors_brand_logo") {
      setSponsorData({
        ...sponsorData,
        [name]: files[0],
      });
    } else {
      setSponsorData({
        ...sponsorData,
        [name]: value,
      });
    }
  };

  const sponsorValidateForm = () => {
    let valid = true;
    const newErrors = {};
    if (sponsorData?.sponsor_name?.trim() == "") {
      newErrors.sponsor_name = "Confirmed Sponsors is required";
      valid = false;
    }

    if (!sponsorData?.sponsors_brand_logo) {
      newErrors.sponsors_brand_logo = "Sponsor Brand logo is required";
      valid = false;
    }

    const sponsorDetail = sponsorData?.inclusions?.replace(/<p><br><\/p>/g, '')
    if (sponsorDetail.trim() == "") {
      newErrors.inclusions = "Inclusions is required";
      valid = false;
    }

    if (sponsorData?.sponsor_name?.trim() == "") {
      newErrors.sponsor_name = "Confirmed Sponsors is required";
      valid = false;
    }

    if (sponsorData?.sponsor_address?.trim() == "") {
      newErrors.sponsor_address = "Sponsors Address is required";
      valid = false;
    }

    if(sponsorData?.sponsored_package_rate == ""){
      newErrors.sponsored_package_rate = "Sponsors Package Rate is required";
      valid = false;
    }

    if (!sponsorData?.sponsor_number) {
      newErrors.sponsor_number = 'Mobile number is required';
      valid = false;
    } else if (sponsorData.sponsor_number.toString().length < 10) {
      newErrors.sponsor_number = 'Mobile number should be at least 10 digits';
      valid = false;
    }
    setSponsorError(newErrors)
    return valid;
  }

  // const handleAddSponsor = (e) => {
  //   e.preventDefault();
  //   if (sponsorValidateForm()) {
  //     const newSponsor = {
  //       sponsor_name: sponsorData?.sponsor_name,
  //       sponsors_brand_logo: sponsorData?.sponsors_brand_logo,
  //       inclusions: sponsorData?.inclusions
  //     };
  //     setSponsors([...sponsors, newSponsor]);

  //     setSponsorData({
  //       sponsor_name: "",
  //       sponsors_brand_logo: null,
  //       inclusions: ""
  //     });
  //     // fileInputRef.current.value = null;
  //   }
  // };

  const handleAddSponsor = (e) => {
    e.preventDefault();
    if (sponsorValidateForm()) {
      const newSponsor = {
        sponsor_name: sponsorData?.sponsor_name,
        sponsors_brand_logo: sponsorData?.sponsors_brand_logo,
        inclusions: sponsorData?.inclusions,
        sponsor_address:sponsorData?.sponsor_address,
        sponsor_number:sponsorData?.sponsor_number,
        country_code:sponsorData?.country_code,
        sponsored_package_rate:sponsorData?.sponsored_package_rate,
        currency_id:selectedCurrencySymbol,
      };

      if (updateCheck) {
        try {
          Express_Sponsor_DetailsUpdate(confirmedSponsorId, sponsorData)
            .then((response) => {
              console.log(response?.data)
              if (response?.data?.success) {
                Messages(response?.data?.message)
                setUpdateCheck(false)
                getData();
                setSponsorData({
                  sponsor_name: "",
                  sponsors_brand_logo:null,
                  inclusions: "",
                  sponsor_address:"",
                  sponsor_number:"",
                  country_code:+91,
                  sponsored_package_rate:"",
                  currency_id:3
                });
              }
            })
        } catch (err) {
          console.log(err)
        }
      } else {
        if (normalEdit) {
          setSponsors((prevSponsors) =>
            prevSponsors.map((sponsor, index) =>
              index === confirmedSponsorId ? newSponsor : sponsor
            )
          );
          setSponsorData({
            sponsor_name: "",
            sponsors_brand_logo:null,
            inclusions: ""
          });
          Messages("Sponsor update successfully")
          setNormalEdit(false)
        } else {
          setSponsors([...sponsors, newSponsor]);
          setSponsorData({
            sponsor_name: "",
            sponsors_brand_logo:null,
            inclusions: "",
            sponsor_address:"",
            sponsor_number:"",
            country_code:+91,
            sponsored_package_rate:"",
            currency_id:3
          });
        }
      }
      // fileInputRef.current.value = null;
    }
  };

  const handleNormalEdit = (sponsor, id) => {
    setSponsorData(sponsor)
    setConfirmedSponsorId(id)
    setNormalEdit(true)
  }

  const handleDeleteSponsor = (indexToDelete) => {
    setSponsors((prevSponsors) =>
      prevSponsors.filter((_, index) => index !== indexToDelete)
    );
  };

  const EditConfirmSponsor = async (id) => {
    setUpdateCheck(true)
    setConfirmedSponsorId(id)
    try {
      GetSingleConfirmedSponsor(id)
        .then((response) => {
          setSponsorData(response?.data?.data)
        })
    } catch (err) {
      console.log(err)
    }
  }

  const DltConfirmSponsor = async (id) => {
    try {
      await Confirmed_Sponsor_Dlt(id)
        .then((response) => {
          if (response?.data?.success) {
            Messages(response?.data?.message)
            getData();
          }
        })
    } catch (err) {
      console.log(err)
    }

  }

  const stripHtmlTags = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;

    let textWithNewlines = div.innerHTML

    if (/^<p><br><\/p>$/i.test(textWithNewlines)) {
      textWithNewlines = '';  // Remove the entire content if it matches <p><br></p>
    } else {
      // Otherwise, apply replacements
      textWithNewlines = textWithNewlines
        .replace(/<br\s*\/?>/gi, '\n')  // Replace <br> with newline
    }

    div.innerHTML = textWithNewlines;
    return div.textContent || div.innerText || "";
  };

  // React Quill length count-------------------start-----------------------//
  const maxThemeLength = 500;
  const maxWhyPartnerWithUsLength = 2500;

  // React theme quill length count
  const getWhyPartnerWithUsLength = () => {
    const cleanDescription = stripHtmlTags(input?.why_partner_with_us);
    return cleanDescription.length;
  };

  const currentWhyPartnerWithUsLength = getWhyPartnerWithUsLength();
  const remainingWhyPartnerWithUsLength = maxWhyPartnerWithUsLength - currentWhyPartnerWithUsLength;


  const getThemeLength = () => {
    const cleanDescription = stripHtmlTags(input?.event_theme);
    return cleanDescription.length;
  };

  const currentThemeLength = getThemeLength();
  const remainingThemeLength = maxThemeLength - currentThemeLength;

  // React preferred quill length count
  const getPreferredLength = () => {
    const cleanDescription = stripHtmlTags(input?.preferred_categories);
    return cleanDescription.length;
  };
  const currentPreferredLength = getPreferredLength();
  const remainingPreferredLength = maxThemeLength - currentPreferredLength;

  // React before quill length count
  const getBeforeLength = () => {
    const cleanDescription = stripHtmlTags(input?.before_brand_visibility);
    return cleanDescription.length;
  };
  const currentBeforeLength = getBeforeLength();
  const remainingBeforeLength = maxThemeLength - currentBeforeLength;

  // React during quill length count
  const getDuringLength = () => {
    const cleanDescription = stripHtmlTags(input?.during_brand_visibility);
    return cleanDescription.length;
  };
  const currentDuringLength = getDuringLength();
  const remainingDuringLength = maxThemeLength - currentDuringLength;

  // React after quill length count
  const getAfterLength = () => {
    const cleanDescription = stripHtmlTags(input?.after_brand_visibility);
    return cleanDescription.length;
  };
  const currentAfterLength = getAfterLength();
  const remainingAfterLength = maxThemeLength - currentAfterLength;

  // React Engment quill length count
  const getEngagementLength = () => {
    const cleanDescription = stripHtmlTags(input?.engagement_opportunities);
    return cleanDescription.length;
  };
  const currentengagementLength = getEngagementLength();
  const remainingengagementLength = maxThemeLength - currentengagementLength;

  // React Desired quill length count
  const getDesiredLength = () => {
    const cleanDescription = stripHtmlTags(input?.desired_sponsor_characteristics);
    return cleanDescription.length;
  };
  const currentdesiredLength = getDesiredLength();
  const remainingdesiredLength = maxThemeLength - currentdesiredLength;

  // React prefrences quill length count
  const getSponsorshipLength = () => {
    const cleanDescription = stripHtmlTags(input?.sponsorship_preferences);
    return cleanDescription.length;
  };
  const currentSponsorshipLength = getSponsorshipLength();
  const remainingSponsorshipLength = maxThemeLength - currentSponsorshipLength;

  // React Promotional quill length count
  const getPromotionalLength = () => {
    const cleanDescription = stripHtmlTags(input?.promotional_plan);
    return cleanDescription.length;
  };
  const currentPromotionalLength = getPromotionalLength();
  const remainingPromotionalLength = maxThemeLength - currentPromotionalLength

  // React Media quill length count
  const getMediaLength = () => {
    const cleanDescription = stripHtmlTags(input?.media_exposure);
    return cleanDescription.length;
  };
  const currentMediaLength = getMediaLength();
  const remainingMediaLength = maxThemeLength - currentMediaLength

  // React Social media quill length count
  const getSocialLength = () => {
    const cleanDescription = stripHtmlTags(input?.social_media_campaigns);
    return cleanDescription.length;
  };
  const currentSocialLength = getSocialLength();
  const remainingSocialLength = maxThemeLength - currentSocialLength

  // React Sponsor details quill length count
  const getSponsorLength = () => {
    const cleanDescription = stripHtmlTags(sponsorData?.inclusions);
    return cleanDescription.length;
  };
  const currentSponsorLength = getSponsorLength();
  const remainingSponsorLength = maxThemeLength - currentSponsorLength

  // React Quill length count-------------------end-----------------------//

  useEffect(() => {
    const firstErrorKey = Object.keys(error)[0];
    if (firstErrorKey && refs[firstErrorKey] && refs[firstErrorKey].current) {
      refs[firstErrorKey].current.focus();
    }
  }, [error]);

  const handleNumberChange = (value, country) => {
    const phoneNum = value?.slice(2);
    const d_code = `${country.format.charAt(0)}${country?.dialCode}`;
    setSponsorData({
      ...sponsorData,
      country_code:d_code,
      sponsor_number: parseInt(phoneNum),
    });
  };


  const formatNumber = (value) => {
    return (value?.replace(/\B(?=(\d{3})+(?!\d))/g, ','))
  };

  const handleChangeCurrencySymbol=(e)=>{
    const item = e.target.value
    setSelectedCurrencySymbol(item)
  }

  return (<>
    <div className="basicInfo">
      {/* <h2 className="basicinfo-head">Event Pitch</h2> */}
      <div className="d-flex align-items-center gap-1 position-relative">
      <p className="createevent-head-p">This section is designed to articulate the unique value and opportunities your event offers to potential sponsors. It should present a persuasive argument to potential sponsors about why they should support your event.
      </p>
      </div>
      <form onSubmit={onSubmit}>
        <div className="row">
          {event_data?.description && (
            <div className="col-md-12" >
              <label className="form-label mb-0">
                Event Description
              </label>
              {/* <IoMdInformationCircleOutline size={16} title="Provide a brief overview describing the purpose and what the event is all about and its main attractions." /> */}
              <p className="reactquill-tooltip-p mb-1">(Note: Provide a brief overview describing the purpose and what the event is all about and its main attractions.)</p>
              <textarea
                value={stripHtmlTags(event_data?.description)}
                type="text"
                rows="5"
                name="event_description"
                disabled
                className="form-control"
                placeholder="Event Description"
              />
            </div>
          )}
        </div>

        <div className="row mt-2">
          <div
            className="col-md-12" >
            <div className="d-flex justify-content-between">
              <label className="form-label mb-0">
                Why Partner With Us?<span className="asterisk"></span>
                {/* <IoMdInformationCircleOutline size={16} color="black" title="Explain the theme and any special focus areas of the event." /> */}
              </label>
              <p className="Character-0500">
                Character:<span className="text-style-1">
                {currentWhyPartnerWithUsLength}/{maxWhyPartnerWithUsLength}
                </span>
              </p>
            </div>
            <p className="reactquill-tooltip-p mb-1">(Note:Enter the how sponsor would benefit from partnering with your event.)</p>
            <div style={{ height: "180px" }}>
              <ReactQuill
                value={input?.why_partner_with_us}
                onChange={handleWhyPartnerWithUs}
                theme="snow"
                style={{ height: "130px" }}
                placeholder="Enter Why Partner With Us?"
              />
            </div>
            {error.why_partner_with_us && (
              <span className="error">{error.why_partner_with_us}</span>
            )}
          </div>
        </div>

        <div className="row mt-2">
          <div
            className="col-md-12">
            <div className="d-flex justify-content-between">
              <label className="form-label mb-0">
                Event Theme<span className="asterisk"></span>
                {/* <IoMdInformationCircleOutline size={16} color="black" title="Explain the theme and any special focus areas of the event." /> */}
              </label>
              <p className="Character-0500">
                Character:<span className="text-style-1">
                  {/* remainingThemeLength */}
                  {currentThemeLength}/{maxThemeLength}
                </span>
              </p>
            </div>
            <p className="reactquill-tooltip-p mb-1">(Note: Explain the theme and any special focus areas of the event.)</p>
            <div style={{ height: "180px" }}>
              <ReactQuill
                value={input?.event_theme}
                onChange={handleEventTheme}
                theme="snow"
                style={{ height: "130px" }}
                placeholder="Enter Event Theme"
              />
            </div>
            {error.event_theme && (
              <span className="error">{error.event_theme}</span>
            )}
          </div>
        </div>

        <div>
          <div className="row mt-3">
            <div className="d-flex align-items-center gap-3">
              <label className="mb-0">
                Do you have any confirmed sponsors?
              </label>
              <div className="d-flex gap-3">
                <div className="form-check">
                  <label className="form-check-label m-0">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="1"
                      checked={confirmedSponsors == "1"}
                      name="confirmedSponsors"
                      onChange={(e) => setConfirmedSponsors(e?.target?.value)}/>
                    Yes
                  </label>
                </div>
                <div className="form-check">
                  <label className="form-check-label m-0">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="0"
                      checked={confirmedSponsors == "0"}
                      name="confirmedSponsors"
                      onChange={(e) => setConfirmedSponsors(e?.target?.value)}/>
                    No
                  </label>
                </div>
              </div>
              {/* <button className="create-event-btn" onClick={()=>setConfirmedSponsors(!confirmedSponsors)}>
                            {confirmedSponsors ? "No" :"Yes"} </button> */}
            </div>
          </div>
          {confirmedSponsors == "1" && (
            <div>
              <div className="align-items-end mt-2">
                <h5 className="form-heading">
                  Confirmed Sponsors
                  <IoMdInformationCircleOutline size={16} color="black" title="Provide details of any confirmed sponsors" />
                </h5>

               <div className="row">
                <div className="col-md-6">
                  <label className="form-label">
                    Confirmed Sponsors<span className="asterisk">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={sponsorData?.sponsor_name}
                    name="sponsor_name"
                    onChange={handleSponsorChange}
                    placeholder="Enter Confirmed Sponsors"/>
                  {sponsorError?.sponsor_name && (
                    <span className="error">{sponsorError?.sponsor_name}</span>
                  )}
                </div>

                <div className="col-md-6">
                  <label className="form-label">
                    Sponsor Brand logo<span className="asterisk">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="file"
                    // ref={fileInputRef}
                    onChange={handleSponsorChange}
                    name="sponsors_brand_logo"
                    placeholder="Logo"/>
                  {sponsorError?.sponsors_brand_logo && (
                    <span className="error">{sponsorError?.sponsors_brand_logo}</span>
                  )}
                </div>
               </div>
 
                <div className="col-md-6">
                  {sponsorData?.sponsors_brand_logo !== null && (
                    <div className="mt-3">
                      <div className="d-flex justify-content-end">
                        <button className="img-close-btn" type="button">
                          <IoClose
                            size={24}
                            onClick={() => setSponsorData({ ...sponsorData, sponsors_brand_logo: null })}
                          />
                        </button>
                      </div>

                      <div className="mt-2 d-flex justify-content-center">
                        {sponsorData?.sponsors_brand_logo instanceof File ? (
                          <img
                            className="event-logo-img"
                            src={URL.createObjectURL(sponsorData?.sponsors_brand_logo)}
                            alt="updateimg"/>
                        ) : (
                          <img
                            className="event-logo-img"
                            src={`${baseUrls?.sponsors_brand_logo}/${sponsorData?.sponsors_brand_logo}`}
                            alt="updateimg"/>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                 
                <div className="row mt-3">
                 <div className="col-md-6">
                  <label className="form-label">
                    Address<span className="asterisk">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={sponsorData?.sponsor_address}
                    name="sponsor_address"
                    onChange={handleSponsorChange}
                    placeholder="Enter Address"
                  />
                  {sponsorError?.sponsor_address && (
                    <span className="error">{sponsorError?.sponsor_address}</span>
                  )}
                 </div>
          
              <div className="col-md-4">
                <div className=" form-group">
                <label className="form-label">Mobile Number <span className="asterisk">*</span></label> 
                <PhoneInput className="" country={"in"}  value={`${sponsorData?.country_code}${sponsorData?.sponsor_number}`} onChange={handleNumberChange} />           
                {sponsorError?.sponsor_number && (
                 <span className="error">{sponsorError?.sponsor_number}</span>
                )}
               </div>
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-6" style={{position:"relative"}}>
                <label className='form-label'>Sponsored Package Rate<span className="asterisk">*</span></label>
                  <input
                    type="text"
                    min='0'
                    value={formatNumber(sponsorData?.sponsored_package_rate)}
                    name='sponsored_package_rate'
                    className="form-control"
                    placeholder="Sponsored Package Rate"
                    onChange={handleSponsorChange}/>
                  <select className="currency_tag" name="currency_id" value={selectedCurrencySymbol} onChange={handleChangeCurrencySymbol}>
                    {ConfigData?.currency_list?.map((val) => {
                      return (
                       <option key={val?.id} value={val?.id}>{val?.code} {val?.symbol}</option>
                         )
                      })}
                   </select>
                    {sponsorError?.sponsored_package_rate && <span className="error">{sponsorError?.sponsored_package_rate}</span>}
                  </div>
             </div>

                <div className="col-md-12 mt-3">
                  <div className="d-flex justify-content-between">
                    <label className="form-label">
                     Inclusions<span className="asterisk">*</span>
                    </label>
                    <p className="Character-0500">
                      Character:<span className="text-style-1">
                        {/* remainingSponsorLength */}
                        {currentSponsorLength}/{maxThemeLength}
                      </span>
                    </p>
                  </div>
                
                  <div style={{ height: "180px" }}>
                    <ReactQuill
                      value={sponsorData?.inclusions}
                      onChange={handleSponsorDetails}
                      theme="snow"
                      style={{ height: "130px" }}
                      placeholder="Enter Inclusions"
                    />
                  </div>
                  {sponsorError?.inclusions && (
                    <span className="error">{sponsorError?.inclusions}</span>
                  )}
                </div>

                <div className="col-md-2 mt-3">
                  <button
                    className="n-btn"
                    type="button"
                    onClick={handleAddSponsor}>
                    Add Sponsor
                  </button>
                </div>
              </div>

              {editSponsorsList?.length > 0 &&
                <div className="mt-3">
                  <h5 className="form-heading">Confirmed Sponsors List</h5>
                  <ul className="sponsor-list p-0">
                    {editSponsorsList?.map((sponsor, index) => (
                      <li
                        key={index}
                        className="d-flex justify-content-between gap-2 align-items-center sponsor-item mt-2">
                        <img
                          src={`${baseUrls?.sponsors_brand_logo}/${sponsor?.sponsors_brand_logo}`}
                          alt={sponsor?.sponsor_name}
                          className="sponsor-logo"/>
                        <p className="sponsor-name text-capitalize p-0 m-0">{sponsor?.sponsor_name}</p>
                        <p className="sponsor-name text-capitalize p-0 m-0">{sponsor?.sponsor_address}</p>
                        <p className="sponsor-name text-capitalize p-0 m-0">{sponsor?.sponsor_number}</p>
                        <p className="sponsor-name text-capitalize p-0 m-0">{sponsor?.sponsored_package_rate}</p>
                        <div className="sponsor-details" dangerouslySetInnerHTML={{ __html:sponsor?.inclusions}}></div>
                        <div className="d-flex gap-2">
                          <button className="btn btn-danger btn-sm"
                            onClick={() => EditConfirmSponsor(sponsor?.id)}
                            type="button">
                            Edit
                          </button>
                          <button className="btn btn-danger btn-sm"
                            onClick={() => DltConfirmSponsor(sponsor?.id)}
                            type="button">
                            Delete
                          </button>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              }

              {
                sponsors?.length > 0 &&
                <div className="mt-3">
                  {/* <h5 className="form-heading">Confirmed Sponsors List</h5> */}
                  <ul className="sponsor-list p-0">
                    {sponsors?.map((sponsor, index) => (
                      <li
                        key={index}
                        className="d-flex justify-content-between gap-2 align-items-center sponsor-item mt-2">
                        <img
                          src={URL.createObjectURL(sponsor?.sponsors_brand_logo)}
                          alt={sponsor.sponsor_name}
                          className="sponsor-logo" />
                        <p className="sponsor-name text-capitalize p-0 m-0">{sponsor?.sponsor_name}</p>
                        <p className="sponsor-name text-capitalize p-0 m-0">{sponsor?.sponsor_address}</p>
                        <p className="sponsor-name text-capitalize p-0 m-0">{sponsor?.sponsor_number}</p>
                        <p className="sponsor-name text-capitalize p-0 m-0">{sponsor?.sponsored_package_rate}</p>
                        <div className="sponsor-details" dangerouslySetInnerHTML={{ __html: sponsor?.inclusions }}></div>

                        <div className="d-flex gap-2">
                          <button
                            className="btn btn-danger btn-sm"
                            type="button"
                            onClick={() => handleNormalEdit(sponsor, index)}>
                            Edit
                          </button>
                          <button
                            className="btn btn-danger btn-sm"
                            type="button"
                            onClick={() => handleDeleteSponsor(index)}>
                            Delete
                          </button>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              }
            </div>
          )}
        </div>
        <div className="d-flex justify-content-md-end align-items-center my-3">
          <div className="d-flex flex-column flex-md-row gap-2">
            <button
              className="d-flex align-items-center gap-1 saveandcontinue-btn"
              type="submit">
              {saveBtnLoading ? (
                <div className="spinner-border text-light" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                ""
              )}
              Save and Continue
              <img className="arrow-img" src={arrow} alt="arrow" />
            </button>
          </div>
        </div>
      </form>
    </div>
  </>
  );
};
export default ExpressEventPitchForm;
