import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import sendmail from "../../assets/images/sendmail.svg";
import facebook from "../../assets/images/facebook.svg";
import linkedin from "../../assets/images/linkedin.svg";
import youtube from "../../assets/images/youtube.svg";
import instagram from "../../assets/images/insta.png";
import twitter from "../../assets/images/ic_twitter.svg";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import { APP_VERSION } from "../../Services/AppVersion";
import { Create_NewsLetter } from "../../utils/Footer/FooterManange";

const Footer = () => {
  const allConfingData = useSelector((state) => state?.config_data?.configData);
  const [links, setLinks] = useState({
    linkedin: '',
    twitter: '',
    instagram: '',
    google: '',
    facebook: '',
    pinterest: ''
  });
  const [sendBtnLoading, setBtnLoading] = useState(false)
  const [fdataEmail, setFdataEmail] = useState("")

  useEffect(() => {
    allConfingData?.social_media_links?.forEach(item => {
      switch (item.name) {
        case 'linkedin':
          setLinks(prevState => ({
            ...prevState,
            linkedin: item.link
          }));
          break;
        case 'twitter':
          setLinks(prevState => ({
            ...prevState,
            twitter: item.link
          }));
          break;
        case 'instagram':
          setLinks(prevState => ({
            ...prevState,
            instagram: item.link
          }));
          break;
        case 'google-plus':
          setLinks(prevState => ({
            ...prevState,
            google: item.link
          }));
          break;
        case 'facebook':
          setLinks(prevState => ({
            ...prevState,
            facebook: item.link
          }));
          break;
        case 'pinterest':
          setLinks(prevState => ({
            ...prevState,
            pinterest: item.link
          }));
          break;
        default:
          break;
      }
    });
  }, [allConfingData])

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (fdataEmail) {
      const fdata = {
        email: fdataEmail
      }
      setBtnLoading(true)
      try {
        const response = await Create_NewsLetter(fdata);
        setFdataEmail('');
        toast.success(response?.message, {
          position: 'top-center',
          autoClose: 3000
        });
      } catch (err) {
        toast.error(err?.response?.data?.message, {
          position: 'top-center',
          autoClose: 3000
        });
      } finally {
        setBtnLoading(false)
      }
    } else {
      alert("email is required")
    }
  }

  return (<>
    <ToastContainer />

    <footer className="footer-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div className="single-footer-widget">
              <h3 className=""></h3>
              <span className="footer-link-text"><Link to={`/${"post"}`} className="span-link">- SponsorRingUs Post</Link></span>
              <span className="footer-link-text"><Link to="/events/all" className="span-link">- SponsorRingUs Explore</Link></span>
              {/* <span><Link to={`/${"champ"}`} className="span-link">- SponsorRingUs Champ</Link></span>
              <span><Link to={`/${"affiliate"}`} className="span-link">- SponsorRingUs Affiliate</Link></span>
              <span><Link to={`/${"prime"}`} className="span-link">- SponsorRingUs Prime</Link></span>
              <span><Link to={`/${"premier"}`} className="span-link">- SponsorRingUs Premier</Link></span> */}
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="single-footer-widget">
              <h3>Support</h3>
              <span className="footer-link-text"><Link to={`/${"contact_us"}`} className="span-link">- Contact us</Link></span>
              {/* <span><Link to={`/${"how_to_use"}`} className="span-link">- How to use</Link></span> */}
              <span className="footer-link-text"><Link to={`/${"faq"}`} className="span-link">- FAQ's</Link></span>
              <span className="footer-link-text"><Link to={`/${"terms_&_conditions"}`} className="span-link">- Terms of use</Link></span>
              <span className="footer-link-text"><Link to={`/${"privacy_policy"}`} className="span-link"> - Privacy Policy</Link></span>
              <span className="footer-link-text"><Link to='https://sponsorringus.com/admin/storage/app/public/docs/User_Manual.pdf' className="span-link" target="_blank"> - How to use</Link></span>
              <span className="footer-link-text"><Link to={`/${"refund_and_cancellation_policy"}`} className="span-link">- Refund and Cancellation Policy</Link></span>
            </div>
            {/* <div className="single-footer-widget mt-4">
              <h3>Email us</h3>
              <span>info@SponsorRingUs.org</span>
            </div>

            <div className="single-footer-widget mt-4">
              <h3>Telephone</h3>
              <span>+91 8889997890</span>
            </div> */}
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="single-footer-widget">
              <h3 className="">Company</h3>
              <span className="footer-link-text"><Link to={`/${"about_us"}`} className="span-link">- About us</Link></span>
              <span className="footer-link-text"><Link to={`/${"our_blog"}`} className="span-link">- Our Blog</Link></span>
              <span className="footer-link-text"><Link to={`/${"newsroom"}`} className="span-link">- Newsroom</Link></span>
              <span className="footer-link-text"><Link to={`/${"careers"}`} className="span-link">- Careers</Link></span>
              <span className="footer-link-text"><Link to={`/${"sitemap"}`} className="span-link">- Sitemap</Link></span>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="single-footer-widget">
              <h3>Subscribe Newsletter</h3>
              {/* <p>Join over 68,000 people getting our emails</p> */}
              <form action="#" onSubmit={handleSubmit}>
                <div className="input-group d-flex mt-4">
                  <input
                    className="form-control Subscribe-input"
                    type="email"
                    name="fdataEmail"
                    value={fdataEmail}
                    onChange={(e) => setFdataEmail(e?.target?.value)}
                    placeholder="Enter Your Email Address"
                    aria-label="Recipient's username"
                    aria-describedby="button-addon2"
                  />

                  <button className="mail-sent-button">
                    <div class="svg-wrapper-1">
                      <div class="svg-wrapper">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24">
                          <path fill="none" d="M0 0h24v24H0z"></path>
                          <path
                            fill="currentColor"
                            d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"
                          ></path>
                        </svg>
                      </div>
                    </div>
                    {/* <span>Send</span> */}
                  </button>
                  {/* <button className="mail-button" type="submit">
                  {
                    sendBtnLoading ?
                    <div className="spinner-border text-light" role="status">
                       <span className="sr-only">Loading...</span>
                   </div>
                   :
                   <img src={sendmail} alt="sendmail" style={{height:20}}/>
                  }
                  </button> */}
                </div>
              </form>
            </div>
          </div>

          <div>
            {/* <div className="row align-items-center mt-5">
              <div className="col-lg-5 copyright-area2"></div>
              <img className="col-2 logo" src={`${allConfingData?.base_urls?.company_logo_url}/${allConfingData?.company_logo}`} alt="logo" />
              <div className="col-lg-5 copyright-area2"></div>
            </div> */}
            <div className="row align-items-center mt-5 text-center">
              <div className="col-lg-5 copyright-area2"></div>
              <div className="col-2">
                <img className="bottom-logo" src={`${allConfingData?.base_urls?.company_logo_url}/${allConfingData?.company_logo}`} alt="logo" />
              </div>
              <div className="col-lg-5 copyright-area2"></div>
            </div>

            <div className="d-flex justify-content-between mt-4">
              <p>Copyright <i className="icofont-copyright"></i> SponsoRingUs. All rights reserved</p>
              <div>
                <ul className="social-links d-flex gap-2">
                  <p> Follow us on</p>
                  <Link to={links?.instagram}>
                    <li>
                      <img src={instagram} alt="instagram" />
                    </li>
                  </Link>
                  <Link to={links?.facebook}>
                    <li>
                      <img src={facebook} alt="facebook" />
                    </li>
                  </Link>

                  <Link to={links?.linkedin}>
                    <li>
                      <img src={linkedin} alt="linkedin" />
                    </li>
                  </Link>
                  <Link to={links?.twitter}>
                    <li>
                      {/* <img src={youtube} alt="youtube" /> */}
                      <img src={twitter} alt="twitter" />
                    </li>
                  </Link>
                </ul>
              </div>
            </div>
            <div>
              <p className="text-center">© 2024 SponsorRingUs | Version {APP_VERSION}</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </>
  );
};

export default Footer;
