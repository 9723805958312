import { ToastContainer, toast } from "react-toastify";
import { Event_packages_delete } from "../../utils/editevents/EditEvent";


const DeleteConfirmation = ({ showDltModal, setShowDltModal, package_id, getPredefineData }) => {

    const handleDelete = async () => {
        try {
            await Event_packages_delete(package_id)
                .then((result) => {
                    if (result?.data?.success) {
                        toast.success(result?.data?.message, {
                            position: "top-center",
                            autoClose: 3000,
                        })
                        setShowDltModal(false);
                        getPredefineData();
                    } else {
                        toast.success(result?.data?.message, {
                            position: "top-center",
                            autoClose: 3000,
                        })
                        setShowDltModal(false);
                    }
                })
        } catch (errr) {
            console.log(errr)
        }
    };
    return (
        <>
            <ToastContainer />
            <div className="text-center">
                {showDltModal && (
                    <div
                        className="modal main-model-div fade show d-block"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="myModal"
                    >
                        <div className="modal-dialog modal-confirm">
                            <div className="modal-content">
                                <div className="modal-header flex-column">
                                    <div className="icon-box">
                                        <i className="material-icons">&chi;</i>
                                    </div>
                                    <h4 className="modal-title w-100">Are you sure?</h4>
                                </div>
                                <div className="modal-body">
                                    <p>Do you really want to delete this record? This process cannot be undone.</p>
                                </div>
                                <div className=" justify-content-center">
                                    <button
                                        type="button"
                                        className="btn delete-event-btn "
                                        onClick={() => setShowDltModal(false)}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        className="btn saveandcontinue-btn"
                                        onClick={handleDelete}
                                    >
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

        </>
    )
}
export default DeleteConfirmation