import moment from "moment";
import ReactStars from "react-rating-stars-component";
import { MdDelete, MdDeleteOutline } from "react-icons/md";
import { useSelector } from "react-redux";
import { useState } from 'react'


const TestimonialReview = ({ val, baseUrls, DeleteTestimonial }) => {
    const profileinfo = useSelector((data) => data?.Profile_data?.ProfileData);
    const userId = profileinfo?.id;

    const [showMore, setShowMore] = useState(false);

    return (
        <>
            <div className="mtm-card">
                {userId == val?.user_id &&
                    <button className="mtm-delete-button" title="Delete" onClick={() => DeleteTestimonial(val.id)}><MdDelete size={20} color="#FF2D55" /></button>}
                <div className="d-flex align-items-center">
                    <img className="user-img" src={val?.users?.image_type == "browse" || val?.users?.image_type == null ?
                        `${baseUrls?.customer_image_url}/${val?.users?.image}` :
                        `${baseUrls?.avatar_image_url}/${val?.users?.predefine_image}`
                    } alt="profl" />

                    <div className=" mt-2">
                        <h6 className="m-0 user-name-text" >{val?.users?.f_name} {val?.users?.l_name}</h6>
                        <div className="d-flex gap-1">
                            <p className="mtm-text m-0 p-0">{val?.users?.city?.city_name ? `${val?.users?.city?.city_name} ,`: "" }</p>
                            <p className="mtm-text m-0 p-0">{val?.users?.country?.country_name}</p>

                        </div>
                        <p className="mtm-text m-0 p-0">{val?.type}</p>
                    </div>

                </div>

                <div className="mt-3">
                    <p className="m-0 testimonial-head-text">{val?.heading}</p>
                    <div className="mtm-paragraph" dangerouslySetInnerHTML={{ __html: showMore ? val?.testimonials : `${val?.testimonials.substring(0, 135)}` }}></div>

                    {!showMore && val?.testimonials?.length > 135 && (
                        <button onClick={() => setShowMore(true)} className="btn viewall-btn-p p-0 m-0">See More</button>
                    )}
                    {showMore && <button onClick={() => setShowMore(false)} className="btn viewall-btn-p p-0 m-0">See Less</button>}

                    <div>
                        <ReactStars
                            count={5}
                            size={20}
                            value={val?.rating}
                            edit={false}
                            emptyIcon={<i className="far fa-star"></i>}
                            fullIcon={<i className="fa fa-star"></i>}
                            activeColor="#f43254"
                        />
                    </div>
                    <div className="msg-info-time-left">
                        <i className="">
                            {moment(val?.created_at).format(
                                "DD MMM hh:mm A"
                            )}
                        </i>
                    </div>
                </div>
            </div>

        </>
    )
}
export default TestimonialReview