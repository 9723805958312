import { CreatePartner_With_Host, CreatePartner_With_Sponsor, GetHostAllEvent, GetHostAllPastEvent, GetRejectData, PastEventDetail, SendRejectData, eventdatail } from "../../Services/Apis"

export const Event_detail = async (id) => {
    try {
        const constant = await eventdatail(id).then((data) => data?.data)
        return constant
    } catch (error) {
        throw error
    }
}

//get past Event detail data
export const Past_Event_Detail = async (id) => {
    try {
        const response = await PastEventDetail(id).then((data) => data?.data);
        return response
    } catch (error) {
        throw error
    }
}

//get reject data list
export const get_Reject_Data = async (id) => {
    try {
        const response = await GetRejectData(id).then((data) => data?.data);
        return response
    } catch (error) {
        throw error
    }
}

export const Reject_Msg_Send = async (id, input) => {
    try {
        const response = await SendRejectData(id, input).then((data) => data?.data);
        return response
    } catch (error) {
        throw error
    }
}


export const Partner_With_Host = async (id, input) => {
    try {
        const response = await CreatePartner_With_Host(id, input).then((data) => data?.data);
        return response
    } catch (error) {
        throw error
    }
}


export const Partner_With_Sponsor = async (id, input) => {
    try {
        const response = await CreatePartner_With_Sponsor(id, input).then((data) => data?.data);
        return response
    } catch (error) {
        throw error
    }
}

export const get_Host_All_Event = async (id) => {
    try {
        const response = await GetHostAllEvent(id).then((data) => data?.data);
        return response
    } catch (error) {
        throw error
    }
}


export const get_Host_All_Past_Event = async (id) => {
    try {
        const response = await GetHostAllPastEvent(id).then((data) => data?.data);
        return response
    } catch (error) {
        throw error
    }
}