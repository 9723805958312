import { Express_Event_Essentials, Express_Event_Essentials_Update, Express_Event_Guest, Express_Event_Media_Hub, Express_Event_Pitch, Express_Sponsor_Details_Update, Express_Sponsor_Package, Express_Sponsor_Package_Update, Get_Express_Event_Assentials, Get_Express_Event_Media_Hub, Get_Express_Sponsor_Package } from "../../Services/Apis"

export const Express_EventEssentials = async (fdata) => {
    try {
        return await Express_Event_Essentials(fdata).then((data) => data)
    } catch (error) {
        throw error
    }
}

export const Express_Event_EssentialsUpdate = async (id,fdata) => {
    try {
        return await Express_Event_Essentials_Update(id,fdata).then((data) => data)
    } catch (error) {
        throw error
    }
}


export const Get_Express_EventAssentials = async (id) => {
    try {
        return await Get_Express_Event_Assentials(id).then((data) => data)
    } catch (error) {
        throw error
    }
}


export const Express_EventGuest = async (id,fdata) => {
    try {
        return await Express_Event_Guest(id,fdata).then((data) => data)
    } catch (error) {
        throw error
    }
}

export const Get_Express_SponsorPackage = async (id) => {
    try {
        return await Get_Express_Sponsor_Package(id).then((data) => data)
    } catch (error) {
        throw error
    }
}

export const Express_EventPitch = async (fData,id) => {
    try {
        return await Express_Event_Pitch(fData,id).then((data) => data)
    } catch (error) {
        throw error
    }
}

export const Express_SponsorPackage = async (id,fData) => {
    try {
        return await Express_Sponsor_Package(id,fData).then((data) => data)
    } catch (error) {
        throw error
    }
}

export const Express_Event_MediaHub = async (id,fData) => {
    try {
      return await Express_Event_Media_Hub(id,fData).then((data) => data)
    } catch (error) {
      throw error
    }
}

export const GetExpress_Event_Media_Hub = async (id) => {
    try {
      return await Get_Express_Event_Media_Hub(id).then((data) => data)
    } catch (error) {
      throw error
    }
}

export const Express_Sponsor_DetailsUpdate = async (id,fdata) => {
    try {
      return await Express_Sponsor_Details_Update(id,fdata).then((data) => data)
    } catch (error) {
      throw error
    }
}

export const Express_Sponsor_PackageUpdate = async (id,fdata) => {
    try {
      return await Express_Sponsor_Package_Update(id,fdata).then((data) => data)
    } catch (error) {
      throw error
    }
}






