import { useDispatch } from "react-redux"
import { AuthGetHomeEvents, GetHomeEvents, Near_By_Cuttoff_Date } from "../../Services/Apis"

export const AuthHomeEvents = async (latlong) => {
    // const dispatch = useDispatch()
    try{
    const response = await AuthGetHomeEvents(latlong).then((data) => data?.data)
    return response
    }
    catch(error){
       console.log(error)
    }
}

export const HomeEvents = async (latlong) => {
    // const dispatch = useDispatch()
    try{
    const response = await GetHomeEvents(latlong).then((data) => data?.data)
    return response
    }
    catch(error){
       console.log(error)
    }
}

export const Near_By_CuttoffDate=async()=>{
   try{
    const response = await Near_By_Cuttoff_Date().then((data) => data)
    return response
   }
   catch(error){
    throw error
   }
}
