import { useState } from "react";
import { useSelector } from "react-redux";
import { Change_Champ } from "../../utils/champ/champAlldata";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SelectChampModal = ({setSelectChampMdl,input,setInput}) => {
  const champsData = useSelector((data) => data?.Get_Champs?.champs);

  const handleChangeChamp=async(e)=>{
    const value = e.target.value;
    setInput({...input,
      champ_id:value
    });
    
    const Fdata ={
      champ_id:value
    }
      try{
        const resonse = await Change_Champ(Fdata);
        toast.success(resonse?.data?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          });
      }catch(error){
       console.log("error",error)
      }
  }

  return (
    <>
     <ToastContainer />
      <div className="select_champ_overlay">
        <div className="select_champ_popup">
          <div className="package_form_popup-content">
            <span className="champ_form_close" onClick={()=>setSelectChampMdl(false)}>&times;</span>
            <div className="">
              <h2 className="champ-modal-head">
                Do you want to change the champ?
              </h2>
            
               <div className="">
                <div className="d-flex gap-1">
                  <p className="champ-head-text">Current Champ</p>
                  <p className="champ-head-text">:</p>
                  <p className="champ-name-text">{champsData?.current_champ?.f_name} {champsData?.current_champ?.l_name}</p>
                </div>

                <div className="row">
               <p className="champ-head-text mb-1">New Champs</p>
               <div className="col-6">
                <select className="form-select" name="champ_change" onChange={handleChangeChamp}>
                  <option selected >Select</option>
                  {
                    champsData?.champs?.map((val)=>{
                      return(<>
                           <option value={val?.id}>{val?.f_name} {val?.l_name}</option>
                      </>)
                    })
                  }
                </select>
                </div>
                </div>

                <div className="d-flex gap-2 justify-content-end mt-3">
                 <button
                    className="cancel-champ-btn"
                    onClick={() => setSelectChampMdl(false)}>
                    Cancel
                 </button>
                  <button
                    className="change-champ-btn"
                    onClick={() => setSelectChampMdl(false)}>
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SelectChampModal;
