import React, { useState } from "react";
import ReactStars from "react-rating-stars-component";
import imageicon from "../../assets/images/image-upload-icon.svg";
import { ReviewEvent } from "../../utils/review/Review";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import "swiper/css/navigation";
import 'swiper/css/autoplay';
import 'swiper/css/grid';
import { Navigation, Grid } from 'swiper/modules'
import { toast } from "react-toastify";
import { IoClose } from "react-icons/io5";

const SponsoredReview = ({ eventId, userReview, evetdetail, setReviewMdl }) => {
    const [images, setImages] = useState([]);
    const [thoughts, setThoughts] = useState("");
    const [rating, setRating] = useState(0);
    const [errorRating, setErrorRating] = useState("");
    const [errorThoughts, setErrorThoughts] = useState("");
    const [btnloading, setBtnLoading] = useState(false);
    // const [open, setOpen] = useState(false);

    const handleThoughtsChange = (event) => {
        setThoughts(event.target.value);
        setErrorThoughts("");
    };

    const handleFileChangeImage = (event) => {
        const files = event.target.files;
        const imageArray = [];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            imageArray?.push(file)
        }
        setImages([...images, ...imageArray]);
    }

    const handleRatingChange = (newRating) => {
        setRating(newRating);
        setErrorRating("");
    };

    const handleSendClick = async () => {
        if (rating == 0) {
            setErrorRating("Please provide a rating before sending.");
        }
        if (thoughts.trim() == "") {
            setErrorThoughts("Please enter your thoughts before sending.");
        }
        if (rating == 0 || thoughts.trim() == "") {
            return;
        }
        setErrorRating("");
        setErrorThoughts("");

        const data = {
            fileUpload: images,
            comment: thoughts,
            rating: rating,
            event_id: eventId
        };

        try {
            setBtnLoading(true)
            await ReviewEvent(data)
                .then((response) => {
                    if (response?.success) {
                        userReview();
                        evetdetail();
                        toast.success(response?.message, {
                            position: 'top-center',
                            autoClose: 3000
                        })
                        setImages([]);
                        setThoughts("");
                        setRating(0);
                    }
                })
        } catch (err) {
            toast.error(err?.response?.data?.message, {
                position: "top-center",
            })
        } finally {
            setBtnLoading(false)
        }

        setRating(0);
        setThoughts("");
    };


    const breakpoints = {
        300: {
            slidesPerView: 2,
            spaceBetween: 5,
        },
        500: {
            slidesPerView: 3,
            spaceBetween: 10,
        },
        768: {
            slidesPerView: 3,
            spaceBetween: 10,
        },
        1024: {
            slidesPerView: 3,
            spaceBetween: 20,
        },
        1200: {
            slidesPerView: 3,
            spaceBetween: 30,
        },
        1600: {
            slidesPerView: 3,
            spaceBetween: 30,
        },
    };

    return (
        <>
            {/* {open && */}
            <div className='overlay'>
                <div className="popup">
                    <div className="popup-content">
                        <div className="basicInfo">
                            <div className="d-flex justify-content-between align-items-center">
                                <h2 className="basicinfo-head">Give Review!</h2>
                                <div>
                                    <span className='close' onClick={() => setReviewMdl(false)}>
                                        {/* <IoClose size={24} color="black" /> */}
                                        &times;
                                    </span>
                                </div>
                            </div>
                            <div className="main-thoughts">
                                <div className="row">
                                    <h4 className="form-heading">Select Image</h4>
                                    <div className="d-flex flex-column align-items-center">
                                        <label>
                                            <img className='upload-icon' src={imageicon} alt='img-upload' />
                                            <input className='d-none' type='file' accept=".jpg,.jpeg,.png" multiple onChange={handleFileChangeImage} />
                                        </label>
                                        <div className=''>The maximunm size of an image can be 1 MB</div>
                                    </div>
                                    <div className="d-flex justify-content-center mt-3 ms-0">
                                        <div className="swiper-div">
                                            <Swiper
                                                spaceBetween={20}
                                                // slidesPerView={3}
                                                breakpoints={breakpoints}
                                                modules={[Navigation, Grid]}
                                                navigation={true}
                                                loop={false}
                                                grid={{
                                                    rows: 2,
                                                    fill: "row"
                                                }}
                                                autoplay={{
                                                    delay: 2000,
                                                    disableOnInteraction: true,
                                                }}
                                                className='mySwiper py-3'>
                                                {images?.map((file, index) => (
                                                    <SwiperSlide key={index}>
                                                        <div className="p-0 mt-0" key={index}>
                                                            <img
                                                                className=""
                                                                style={{ width: "100%", height: "120px", objectFit: "cover", borderRadius: "10px" }}
                                                                src={URL.createObjectURL(file)}
                                                                alt={`selected-${index}`}
                                                            />
                                                        </div>
                                                    </SwiperSlide>
                                                ))}
                                            </Swiper>
                                        </div>
                                    </div>

                                </div>
                                <div className="my-3">
                                    <ReactStars
                                        count={5}
                                        size={30}
                                        onChange={handleRatingChange}
                                        value={rating}
                                        emptyIcon={<i className="far fa-star"></i>}
                                        fullIcon={<i className="fa fa-star"></i>}
                                        activeColor="#f43254"
                                    />
                                    {errorRating && <p className="text-danger">{errorRating}</p>}
                                </div>
                                <div className="mt-3">
                                    <h6 className="d-flex justify-content-start">Do you have any thoughts you’d like to share?</h6>
                                    <textarea
                                        className="form-control"
                                        placeholder="Write your thoughts here…"
                                        name="query"
                                        rows="4"
                                        cols="50"
                                        value={thoughts}
                                        onChange={handleThoughtsChange}
                                    // style={{ width: '700px' }}
                                    ></textarea>
                                    {errorThoughts && <p className="text-danger">{errorThoughts}</p>}
                                </div>
                                <div className="d-flex justify-content-end">
                                    <button className=" become-sponsor mt-3 w-25" onClick={handleSendClick}>
                                        {
                                            btnloading &&
                                            <div
                                                className="spinner-border text-light "
                                                role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        }
                                        Send
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* } */}
        </>
    )
}
export default SponsoredReview