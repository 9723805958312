
import { GetTestimonial, PostTestimonial, GetTestimonialDataList, GetOnlyTestimonialDataList, Dlt_testimonial } from "../../Services/Apis"


//get condition msg 
export const CallTestimonial = async () => {
    try {
        const response = await GetTestimonial().then((data) => data?.data)
        return response;
    }
    catch (error) {
        throw error
    }
}

// post
export const postTestimonial = async (value) => {

    try {
        const response = await PostTestimonial(value).then((data) => data?.data)
        return response;
    }
    catch (error) {
        throw error
    }
}

// get testimoonial data list with rating or testimonial name
export const getTestimonialdataList = async (val, starRating,lateOlde,currentPage) => {
    try {
        const response = await GetTestimonialDataList(val, starRating,lateOlde,currentPage).then((data) => data?.data)
        return response;
    }
    catch (error) {
        throw error
    }
}

// only testimonial Data 
export const getOnlyTestimonialdataList = async () => {
    try {
        const response = await GetOnlyTestimonialDataList().then((data) => data?.data)
        return response;
    }
    catch (error) {
        throw error
    }
}

// delete Testimonial by id
export const deleteTestimonial = async (id) => {
    try {
        const response = await Dlt_testimonial(id).then((data) => data?.data)
        return response;
    }
    catch (error) {
        throw error
    }
}