import { IoClose, IoWarningOutline } from "react-icons/io5";


const PackageInclusionModal=({val,setShowMore})=>{
  return(<>
   <div className="warningModal">
        <div className="packagedetail-mdl position-relative">
        <h5 className="pack-card-heading text-danger text-capitalize">{val?.title}</h5>
          <button
            className="terms-mdl-close-btn"
            onClick={() => setShowMore(false)}>
            <IoClose size={24} />
          </button>
            <hr className="w-100 m-0"/>
           <div className="packagedetail-content mt-3">
           <p className="pack-content-text">Package Inclusion :</p>
          <div className="pack-content-text" dangerouslySetInnerHTML={{ __html:val?.package_inclusions}}>
          </div> 
          </div>
        </div>
      </div>
    </>)
 };

export default PackageInclusionModal;