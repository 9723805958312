import srchlogo from "../../assets/images/ic-search.png";
import profile from "../../assets/images/author2.jpg";
import checkimg from "../../assets/images/icons-16-check.png";
import moredots from "../../assets/images/ic-more.png";
import sentBtn from "../../assets/images/ic-send.png";
import CreateNewChatPopup from "../../components/Common/CreateNewChatPopup";
import React, { useEffect, useState, useRef } from "react";
import {
  Delete_SupportTicket,
  GetMessages,
  Get_RequestMessages,
  SearchRequest,
  SendMessage,
} from "../../utils/chatmanage/ChatManage";
import Loader2 from "../../components/Loader/Loader2";
import Loader from "../../components/Loader/Loader";
import DeleteChatWarningModal from "../../components/Common/DeleteChatWarningModal";
import { BsCheck, BsCheckAll } from "react-icons/bs";
import moment from "moment";
import { GiSettingsKnobs } from "react-icons/gi";

const Chat = () => {
  const [newChatModal, setNewChatModal] = useState(false);
  const [RequestListData, setRequestListData] = useState([]);
  const [messages, setMessages] = useState([]);
  const [chatLoading, setChatLoading] = useState(false);
  const [chatInfo, setChatInfo] = useState({});
  const [message, setMessage] = useState("");
  const [defaulMessage, setdefaulMessage] = useState({});
  const [inputMessageErr, setInputMessageErr] = useState("");
  const [sendBtnLoading, setSendBtnLoading] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [deleteChatMdl, setDeleteChatMdl] = useState(false);
  const [searchValue,setSearchValue]=useState("");
  const [searchErr,setSearchErr]=useState("");
  const chatContainerRef = useRef(null);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [defaulMessage, messages]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };


  const GetRequestMessages = async () => {
    setLoading(true);
    try {
      const response = await Get_RequestMessages();
      setRequestListData(response?.data?.data);
      setChatInfo(response?.data?.data[0]);
      handleGetChat(response?.data?.data[0]);
      //setChatInfo()
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleGetChat = async (val) => {
    setChatInfo(val);
    setChatLoading(true);
    try {
      const response = await GetMessages(val?.id ? val?.id : chatInfo?.id);
      setMessages(response?.data?.data);
      setdefaulMessage(response?.data?.SupportTicket);
    } catch (err) {
      console.log(err);
    } finally {
      setChatLoading(false);
    }
  };

  // console.log()

  useEffect(() => {
    GetRequestMessages();
  }, []);

  const handleMessageSend = async () => {
    if (message?.length <= 0 || message == "") {
      setInputMessageErr("Message must be at least 1 characters long.");
    } else {
      const fData = {
        id: chatInfo?.id,
        reply: message,
      };
      setSendBtnLoading(true);
      try {
        const response = await SendMessage(fData);
        handleGetChat(chatInfo);
        setMessage("");
        console.log("ress", response);
      } catch (err) {
        console.log(err);
      } finally {
        setSendBtnLoading(false);
      }
      setInputMessageErr("");
    }
  };

  const handleDeleteChat = async () => {
    try {
      const response = await Delete_SupportTicket(chatInfo?.id);
      setDeleteChatMdl(false);
      GetRequestMessages();
      setIsOpen(false);
    } catch (err) {
      console.log(err);
    }
  };

 const handleSearchRequest=async(e)=>{
    e.preventDefault();
    if(searchValue == ''){
        setSearchErr("Invalid search input")
    }else{
      try{
         const response = await SearchRequest(searchValue);
         setRequestListData(response?.data?.data); 
         setChatInfo(response?.data?.data[0]);
         handleGetChat(response?.data?.data[0]); 
         setSearchValue("");  
        }catch(err){
          console.log(err)
        }
    }
 } 

  return (
    <>
      {newChatModal && (
        <CreateNewChatPopup
          setNewChatModal={setNewChatModal}
          GetRequestMessages={GetRequestMessages}
        />
      )}
      {deleteChatMdl && (
        <DeleteChatWarningModal
          msg={"Do You Want to Delete This Chat"}
          handleDeleteChat={handleDeleteChat}
          setDeleteChatMdl={setDeleteChatMdl}
        />
      )}

      {loading ? (
        <Loader />
      ) : (
        <div className="container py-4 ">
          <div className="">
            <div className="d-flex justify-content-between py-2">
              <h3 className="">Messages</h3>
              <button
                class="signup-btn"
                onClick={() => setNewChatModal(!newChatModal)}>
                Start New Chat
              </button>
            </div>

            {RequestListData && RequestListData?.length <= 0 ? (
              <h3 className="text-center">No Data Found</h3>
            ) : (
              <div className="row">
                <div className="col-12 col-xl-4 p-0">
                  <div className="chat-list ">
                   <form onSubmit={handleSearchRequest}>
                    <div className="chat-field  pb-3">
                      <div className="">
                      <img src={srchlogo} className="ic_search" />
                      <input
                        type="search"
                        placeholder="Search"
                        className="input-chat-list"
                        value={searchValue}
                        name="searchValue"
                        onChange={(e)=>setSearchValue(e.target.value)}
                      />
                      {searchErr && (
                       <span className="error ms-3">{searchErr}</span>
                      )}
                       {/* <div>
                        <button className="setting-btn"><GiSettingsKnobs size={18}/></button>
                       </div> */}
                      </div>
                    </div>
                </form>

                    {RequestListData?.map((val, index) => (
                      <button
                        className="chat-list-button p-0"
                        onClick={() => handleGetChat(val)}>
                        <div
                          className={
                            chatInfo?.id == val?.id
                              ? "active-user-list py-3"
                              : "user-list py-3"
                          }>
                          <div className="d-flex justify-content-between">
                            {/* <img src={profile} className="user-img" /> */}
                            <div className="d-flex w-75">
                              <div className="">
                                <span className="userName text-capitalize" title={val?.subject}>
                                  {val?.subject}
                                </span>
                                <p
                                  className="user-msg"
                                  title={val?.description}
                                >
                                  {val?.description}
                                </p>
                              </div>
                            </div>
                            <div className="mt-auto text-end">
                              <i className="Timestamp">
                                {moment(val?.created_at).format(
                                  "DD MMM hh:mm A"
                                )}
                              </i>
                            </div>
                          </div>
                        </div>
                      </button>
                    ))}
                  </div>
                </div>

                <div className="col-12 col-xl-8 mt-3 mt-xl-0 p-0">
                  <div className="chat-view">
                    <div className="single-user-head d-flex justify-content-between">
                      <div className="w-100">
                        {/* <img src={profile} className="user-img" /> */}
                        <span
                          className="chat-view-user text-capitalize"
                          title={chatInfo?.subject}>
                          {chatInfo?.subject}
                        </span>
                        <p className="last-seen" title={chatInfo?.description}>
                          {chatInfo?.description}
                        </p>
                      </div>

                      <div>
                        <div className="dropdown">
                          <button
                            className="chat-list-button"
                            onClick={toggleDropdown}>
                            <img
                              src={moredots}
                              className="moredots"
                              style={{ height: 20, width: 20 }} />
                          </button>

                          <div
                            className={`dropdown-content ${isOpen ? "open" : "closed"}`} >
                            <button
                              className="list-drop-button"
                              onClick={() => setDeleteChatMdl(!deleteChatMdl)}>
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {chatLoading ? (
                      <Loader2 />
                    ) : (
                      <div>
                        <div className="set-hign" ref={chatContainerRef}>
                          <div className="msger-chat">
                            {defaulMessage?.description && (
                              <div className="msg left-msg mt-4">
                                <div className="msg-bubble-left">
                                  <div>
                                    <div className="msg-text-left">
                                      {defaulMessage?.description}
                                    </div>
                                  </div>
                                  <div className="msg-info-time-left">
                                    <i className="">
                                    {moment(defaulMessage?.created_at).format("DD MMM hh:mm A")}
                                    </i>
                                  </div>
                                </div>
                              </div>
                            )}
                            {messages?.map((val) => {
                              return (
                                <>
                                  {val?.admin_message && (
                                    <div className="msg left-msg mt-4">
                                      <div className="msg-bubble-left">
                                        <div>
                                          <div className="msg-text-left">
                                            {val?.admin_message}
                                          </div>
                                        </div>

                                        <div className="msg-info-time-left">
                                          <i className="">
                                            {moment(val?.created_at).format("DD MMM hh:mm A" )}
                                          </i>
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  {val?.customer_message && (
                                    <div className="msg right-msg mt-4">
                                      <div className="msg-bubble-right">
                                        <div className="msg-text-right">
                                          {val?.customer_message}
                                        </div>
                                        <div className="msg-info-time-right d-flex jut- align-items-center gap-2">
                                          <div className="">
                                            <i className="">
                                              {moment(val?.created_at).format(
                                                "DD MMM hh:mm A"
                                              )}
                                            </i>
                                          </div>
                                          {val?.admin_seen ? (
                                            <BsCheckAll
                                              color="#2e73b9"
                                              size={18} />
                                          ) : (
                                            <BsCheck size={18} />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </>
                              );
                            })}
                          </div>
                        </div>

                        <div className="pt-2 px-3 d-flex align-items-center gap-2 ">
                          <div className="w-100">
                            <textarea
                              type="text"
                              className="send-msg-input"
                              placeholder="Send A Message"
                              name="message"
                              onChange={(e) => setMessage(e.target.value)}
                              rows="5"
                              cols="50"
                              style={{ resize: "none" }}
                            />
                          </div>
                          
                          <div className="">
                          {sendBtnLoading ? (
                            <button className="input-sent-button">
                              <div
                                className=" spinner-border text-light"
                                role="status" >
                                <span className="sr-only">Loading...</span>
                              </div>
                            </button>
                          ) : (
                            <button
                              className="input-sent-button"
                              type="submit"
                              onClick={handleMessageSend}
                            >
                              <img src={sentBtn} className="send-btn" />
                            </button>
                          )}
                         </div>

                    
                        </div>
                        {inputMessageErr && (
                              <span className="error ms-3">{inputMessageErr}</span>
                            )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default Chat;
