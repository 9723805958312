import { CreateCoupon, CreatedCouponList, Delete_Coupon_Delete, editCouponData, getPackageList, updateCouponData } from "../../Services/Apis"


// Delete coupon 
export const Coupon_delete = async (id) => {
    try {
        return await Delete_Coupon_Delete(id).then((data) => data)
    }
    catch (error) {
        throw error
    }
}

// get single Edit Coupon
export const Edit_Coupon_Data = async (id) => {
    try {
        return await editCouponData(id).then((data) => data)
    }
    catch (error) {
        throw error
    }
}

// Update coupon Data
export const Update_Coupon_Data = async (couponFD, id) => {
    try {
        return await updateCouponData(couponFD, id).then((data) => data)
    }
    catch (error) {
        throw error
    }
}


// create coupons
export const Create_Coupon_On_Event = async (data, id) => {
    try {
        return await CreateCoupon(data, id).then((data) => data)
    }
    catch (error) {
        throw error
    }
}

// created packages Get_Packages_List
export const Get_Packages_List = async (id) => {
    try {
        return await getPackageList(id).then((data) => data)
    }
    catch (error) {
        throw error
    }
}

// get Created Coupon_list 
export const Created_Coupon_List = async (id) => {
    try {
        return await CreatedCouponList(id).then((data) => data)
    }
    catch (error) {
        throw error
    }
}