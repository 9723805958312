import { MdClear } from "react-icons/md";
import { FaLongArrowAltDown, FaLongArrowAltUp } from "react-icons/fa";

const SortSection = ({ sortVal, setSortVal, someFilterHide }) => {

  const words = sortVal.split(' ');
  const sortValTitle = words.slice(1).join(' ');
  return (<>
         <div>
        {/* sort title */}

        <div className="d-flex gap-3 align-items-center justify-content-xl-end mt-2">
          <div>
            <p className="d-none d-xl-block sortby-text">Sort By </p>
          </div>
          <div>
            <div className="sort-list-div d-flex flex-column flex-xl-row gap-2">
              <div className="d-flex gap-2">
                <button
                  className="d-flex align-items-center sort-btn"
                  onClick={() =>
                    setSortVal(
                      sortVal == "dist far to near"
                        ? "dist near to far"
                        : "dist far to near"
                    )
                  }
                >
                  Distance
                  {sortVal == "dist near to far" ? (
                    <FaLongArrowAltUp />
                  ) : (
                    <FaLongArrowAltDown />
                  )}
                </button>
              </div>

              <div className="d-flex gap-2">
                <button
                  className="d-flex align-items-center sort-btn"
                  onClick={() =>
                    setSortVal(
                      sortVal == "amount low to high"
                        ? "amount high to low"
                        : "amount low to high"
                    )
                  }
                >
                  Sponsorship amount
                  {sortVal == "amount high to low" ? (
                    <FaLongArrowAltUp />
                  ) : (
                    <FaLongArrowAltDown />
                  )}
                </button>
              </div>

              <div className="d-flex gap-2">
                <button
                  className="d-flex align-items-center sort-btn"
                  onClick={() =>
                    setSortVal(
                      sortVal == "date Latest to oldest"
                        ? "date oldest to latest"
                        : "date Latest to oldest"
                    )
                  }
                >
                  Date of event
                  {sortVal == "date Latest to oldest" ? (
                    <FaLongArrowAltUp />
                  ) : (
                    <FaLongArrowAltDown />
                  )}
                </button>
              </div>

              <div className="d-flex gap-2">
                <button
                  className="d-flex align-items-center sort-btn"
                  onClick={() =>
                    setSortVal(
                      sortVal == "duration lowest to highest"
                        ? "duration highest to lowest"
                        : "duration lowest to highest"
                    )
                  }
                >
                  Duration of event
                  {sortVal == "duration highest to lowest" ? (
                    <FaLongArrowAltUp />
                  ) : (
                    <FaLongArrowAltDown />
                  )}
                </button>
              </div>

              <div className="d-flex gap-2">
                <button
                  className="d-flex align-items-center sort-btn"
                  onClick={() =>
                    setSortVal(
                      sortVal == "participants lowest to highest"
                        ? "participants highest to lowest"
                        : "participants lowest to highest"
                    )
                  }>
                   Attendee Count 
                  {sortVal == "participants highest to lowest" ? (
                    <FaLongArrowAltUp />
                  ) : (
                    <FaLongArrowAltDown />
                  )}
                </button>
              </div>
            </div>

            <div className="mt-2">
              {sortVal !== "" && (
                <>
                  <div className="d-flex align-items-center sort-clear-div">
                    {(sortVal == "dist far to near" && (
                      <p className="sort-select-bold-text m-0">Distance :</p>
                    )) ||
                      (sortVal == "dist near to far" && (
                        <p className="sort-select-bold-text m-0">Distance :</p>
                      ))}

                    {(sortVal == "amount high to low" && (
                      <p className="sort-select-bold-text m-0">Sponsorship:</p>
                    )) ||
                      (sortVal == "amount low to high" && (
                        <p className="sort-select-bold-text m-0">Sponsorship:</p>
                      ))}

                    {(sortVal == "date oldest to latest" && (
                      <p className="sort-select-bold-text m-0">Date:</p>
                    )) ||
                      (sortVal == "date Latest to oldest" && (
                        <p className="sort-select-bold-text m-0">Date:</p>
                    ))}

                    {(sortVal == "duration highest to lowest" && (
                      <p className="sort-select-bold-text m-0">Duration:</p>
                    )) ||
                      (sortVal == "duration lowest to highest" && (
                        <p className="sort-select-bold-text m-0">Duration:</p>
                    ))}

                    {(sortVal == "participants highest to lowest" && (
                      <p className="sort-select-bold-text m-0">Attendee Count :</p>
                    )) ||
                      (sortVal == "participants lowest to highest" && (
                        <p className="sort-select-bold-text m-0">Attendee Count :</p>
                    ))}

                    <p className="sort-select-text ms-1 m-0">{sortValTitle}</p>
                    <div>
                      
                      <button
                        className="sort-clear-btn ms-1"
                        onClick={() => setSortVal("")}
                      >
                        <MdClear />
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
  </>)
};

export default SortSection;
