import upload from "../../assets/images/upload-icon.png";
import arrow from "../../assets/images/arrow2.svg";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import { useCallback, useEffect, useState } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import {
  Get_UserInterest,
  Get_languages_list,
  getEventPeopleRangeData,
  getEventTypeData,
} from "../../utils/basicInformation/basicinformationManager";

import { IoClose } from "react-icons/io5";
import { useSelector } from "react-redux";
import { Update_Event } from "../../utils/updateevent/UpdateEvent";
import Loader from "../Loader/Loader";
import { FaInfoCircle } from "react-icons/fa";
import Select from "react-select";
import { get_city_state_country } from "../../utils/citystatecountry/cityStateCountry";
import PhoneInput from "react-phone-input-2";
import CreatableSelect from "react-select/creatable";

const EditBasicInformation = ({
  baseUrls,
  id,
  EditEvents,
  setTabValue,
  profileinfo,
  Messages,
  // EditData
}) => {
  const latlong = JSON?.parse(localStorage.getItem("let_long"));
  const [type, setType] = useState([]);
  const [eventPeopleRange, setEventPeopleRange] = useState([]);
  const [predefineIntrest, setPredefineIntrest] = useState([]);
  const [center, setCenter] = useState({ lat: 24.5980386, lng: 73.6866158 });
  const [marker, setMarker] = useState(null);
  const liveSample = localStorage.getItem('LiveSample')

  const [input, setInput] = useState({
    champ_id: "",
    banner: "",
    logo: "",
    event_title: "",
    event_type: "",
    address: "",
    country: "",
    state: "",
    city: "",
    pin_code: "",
    contact_number: "",
    country_code: "",
    start_date: "",
    start_time: "",
    end_date: "",
    end_time: "",
    people_range: "",
    website_link: "",
    facebook: "",
    twitter: "",
    linkedin: "",
    instagram: "",
    latitude: "",
    longitude: "",
    description: "",
    event_mode: "",
    tags: "",
    status: liveSample == 0 ? 'Live' : '',
    event_time_category: "",
    total_budget: "",
    languages: "",
    // gender: "",
    background_of_guest: "",
    min_age: "",
    max_age: "",
    email: "",
    male: "",
    female: "",
    other: "",
    people_range_min: "",
    people_range_max: "",

    house_no: "",
    landmark: "",
    address_type: "",
  });

  const [countries, seCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [zip_country, setZip_Country] = useState({});
  const [zip_state, setZip_State] = useState({});
  const [zip_city, setZip_City] = useState({});

  const [errors, setErrors] = useState({});
  const [mapData, setMapData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [showIntrest, setShowIntrest] = useState([]);
  const [showlanguages, setShowLanguages] = useState([]);
  const [listingLanguage, setListingLanguage] = useState([]);

  const EditData = useSelector((data) => data?.editEventSlice?.editData);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyA3BYK_Siw8ebziSskitaA5YMjbvcO6n4U",
  });

  const onLoad = useCallback(
    function callback(map) {
      // This is just an example of getting and using the map instance!!! don't just blindly copy!
      const bounds = new window.google.maps.LatLngBounds(center);
      map.setZoom(16);
      map.fitBounds(bounds);
      setMapData(map);
    },
    [center]
  );

  const onUnmount = useCallback(function callback() {
    setMapData(null);
  }, []);

  const EditAddress = (cntry) => {
    if (cntry) {
      const selectedCountry = cntry?.find(
        (country) =>
          country?.country_name?.toLowerCase() == input?.country?.toLowerCase()
      );
      setStates(selectedCountry ? selectedCountry?.states : []);
      if (selectedCountry?.states) {
        const state = selectedCountry?.states
          ? selectedCountry?.states.find(
            (state) =>
              state?.state_name?.toLowerCase() == input?.state?.toLowerCase()
          )
          : null;
        setCities(state ? state?.cities : []);
      }
    }
  };

  const getCountryCityState = async () => {
    try {
      get_city_state_country().then((response) => {
        seCountries(response?.data?.data?.countries);
        EditAddress(response?.data?.data?.countries);
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    // setInput(EditData);
    getCountryCityState();

    const list = EditData?.tags?.map((item) => ({
      value: item?.user_interests?.id,
      label: item?.user_interests?.name,
    }));
    setShowIntrest(list);

    const list1 = list?.map((item) => item.value);
    // setInput({ ...EditData, tags: JSON?.stringify(list1) })
    //--------------
    setShowLanguages(
      EditData?.languages?.map((item) => {
        return {
          value: item?.languages,
          label: item?.languages,
        };
      })
    );

    const mngLang = EditData?.languages?.map((item) => item?.languages);
    // const genderStr = EditData.gender ? JSON.parse(EditData.gender) : '';

    const maleP = EditData.male ? parseInt(EditData.male) : "";
    const femaleP = EditData.female ? parseInt(EditData.female) : "";
    const otherP = EditData.other ? parseInt(EditData.other) : "";

    setInput({
      ...EditData,
      min_age: EditData?.min_age ? EditData?.min_age : 0,
      max_age: EditData?.max_age ? EditData?.max_age : 100,
      tags: JSON?.stringify(list1),
      languages: JSON?.stringify(mngLang),
      // gender: genderStr,
      male: maleP,
      female: femaleP,
      other: otherP,
    });
  }, [EditData]);

  const handlePinCodeChange = async (val, type) => {
    const pinc = val;

    if (isNaN(pinc)) {
      console.error("Invalid pin code. Please enter a valid number.");
      // You may set an error state or handle it in another way
      return;
    }
    setInput((prevInput) => ({ ...prevInput, pin_code: pinc }));
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${pinc}&key=AIzaSyA3BYK_Siw8ebziSskitaA5YMjbvcO6n4U`
      );
      const data = await response.json();
      if (data?.results && data?.results?.length > 0) {
        const addressComponents = data.results[0].address_components;
        const latLong = data?.results[0]?.geometry?.location;
        const fullAddress = addressComponents
          ?.map((component) => component.long_name)
          .join(", ");
        if (addressComponents) {
          const cityComponent = addressComponents.find((component) =>
            component?.types?.includes("locality")
          );
          const stateComponent = addressComponents.find((component) =>
            component?.types?.includes("administrative_area_level_1")
          );
          const countryComponent = addressComponents.find((component) =>
            component?.types?.includes("country")
          );

          if (countryComponent) setZip_Country(countryComponent);
          if (stateComponent) setZip_State(stateComponent);
          if (cityComponent) setZip_City(cityComponent);

          const country = countries.find(
            (country) => country.country_name == countryComponent?.long_name
          );
          setStates(country ? country?.states : []);

          const state = country
            ? country.states.find(
              (state) => state?.state_name == stateComponent?.long_name
            )
            : null;

          const city = state
            ? state.cities.find(
              (city) => city?.city_name == cityComponent?.long_name
            )
            : null;

          if (state) setCities(state.cities);

          if (addressComponents && latLong) {
            if (type == "map") {
              setInput((prevInput) => ({
                ...prevInput,
                country: country?.country_id,
                state: state?.state_id,
                city: city?.city_id,
              }));
            } else {
              setInput((prevInput) => ({
                ...prevInput,
                country: country?.country_id,
                state: state?.state_id,
                city: city?.city_id,
                address: fullAddress,
                latitude: latLong?.lat,
                longitude: latLong?.lng,
              }));
              setCenter(latLong);
              setMarker(latLong);
            }
          }
        } else {
          console.error(
            "No address components found in the Geocoding API response."
          );
        }
      } else {
        console.error("No results found in the Geocoding API response.");
      }
    } catch (error) {
      console.error("Error fetching location:", error);
      // Handle the error (e.g., set an error state)
    }
  };

  console.log();

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "event_title") {
      if (value?.length <= 100) {
        setInput({ ...input, event_title: value });
      } else {
        setInput({ ...input, event_title: value.slice(0, 100) });
        setErrors({ title: "Title name is Maximum 100 character only" });
      }
    } else if (name == "people_range") {
      if (value.length <= 6) {
        setInput({
          ...input,
          [name]: value.replace(/\D/g, ""), // This also ensures only numbers are allowed
        });
      }
    } else if (name === "banner" || name === "logo") {
      setInput({
        ...input,
        [name]: files[0],
      });
    } else {
      setInput({
        ...input,
        [name]: value,
      });
    }
  };

  const getAllDropdownData = async () => {
    try {
      setLoading(true);
      await getEventTypeData().then((response) => {
        setType(response?.data);
      });
    } catch (error) {
      console.log("err", error);
    } finally {
      setLoading(false);
    }

    try {
      await getEventPeopleRangeData().then((response) => {
        setEventPeopleRange(response?.data);
      });
    } catch (error) {
      console.log("err", error);
    }

    try {
      Get_UserInterest().then((response) => {
        const getInterest = response?.data;
        const options = getInterest?.map((topic) => ({
          value: topic.id,
          label: topic.name,
        }));
        setPredefineIntrest(options);
      });
    } catch (err) {
      console.log(err);
    }

    try {
      await Get_languages_list().then((response) => {
        const lan = response?.data?.data;
        setListingLanguage(
          lan.map((item) => {
            return {
              value: item?.name,
              label: item?.name,
            };
          })
        );
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleCountryChange = (e) => {
    const cntry = e?.target?.value;
    setInput({ ...input, country: cntry, state: "", city: "" });
    if (cntry) {
      const country = countries.find((country) => country.country_id == cntry);
      setStates(country ? country?.states : []);
    } else {
      const country = countries.find(
        (country) => country.country_id == "India"
      );
      setStates(country ? country?.states : []);
      setCities([]);
    }
  };

  const handleStateChange = (e) => {
    const sState = e.target.value;
    setInput({ ...input, state: sState, city: "" });
    if (sState) {
      // const country = countries.find(country => country?.country_name == selectedCountry);
      const state = states
        ? states.find((state) => state?.state_id == sState)
        : null;
      setCities(state ? state.cities : []);
    } else {
      setCities([]);
    }
  };

  const handleCityChange = (e) => {
    const cityid = e.target.value;
    setInput({ ...input, city: cityid });
  };

  useEffect(() => {
    EditEvents();
    getAllDropdownData();
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const Fdata = { ...input, gender: JSON?.stringify(input?.gender) };
      try {
        setBtnLoading(true);
        Update_Event(id, Fdata).then((result) => {
          if (result?.data?.success) {
            setTabValue("packages");
            Messages(result?.data?.message);
          }
        });
      } catch (error) {
        console.log(error);
      } finally {
        setBtnLoading(false);
      }
    }
  };

  const handleMapClick = (event) => {
    const { latLng } = event;
    const lat = latLng.lat();
    const lng = latLng.lng();
    setCenter({ lat, lng });
    setMarker({ lat, lng });
    getAddressFromLatLng(lat, lng);
  };

  const getAddressFromLatLng = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyA3BYK_Siw8ebziSskitaA5YMjbvcO6n4U`
      );
      if (response.ok) {
        const data = await response.json();
        if (data?.results && data?.results?.length > 0) {
          const full_address = data?.results[0]?.address_components;
          const location = full_address.find((loc) =>
            loc?.types?.includes("postal_code")
          );
          const location1 = data?.results[0]?.formatted_address;
          const let_lng = data?.results[0]?.geometry?.location;
          setInput({
            ...input,
            latitude: let_lng?.lat,
            longitude: let_lng?.lng,
            pin_code: location.long_name,
            address: location1,
          });
          handlePinCodeChange(location?.long_name, "map");
        } else {
          console.log("Address not found");
        }
      } else {
        console.log("Failed to fetch address");
      }
    } catch (error) {
      console.error("Error fetching address:", error);
    }
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    if (!input.banner) {
      newErrors.banner = "File is required";
      valid = false;
    }

    if (!input.logo) {
      newErrors.logo = "File is required";
      valid = false;
    }

    // if (input.host_id.trim() == '') {
    //   newErrors.host_id = 'User is required'
    //   valid = false;
    // }

    if (input?.event_title?.trim() == "") {
      newErrors.event_title = "Title is required";
      valid = false;
    }

    if (input?.event_type?.toString()?.trim() == "") {
      newErrors.event_type = "Type is required";
      valid = false;
    }

    if (input?.event_mode?.trim() == "") {
      newErrors.event_mode = "Event mode is required";
      valid = false;
    }

    if (
      input.event_mode == "Offline_Indoor" ||
      input.event_mode == "Offline_Outdoor"
    ) {
      if (
        !input.event_time_category ||
        input.event_time_category?.trim() == ""
      ) {
        newErrors.event_time_category = "Time Category is required";
        valid = false;
      }
    }

    if (input.address?.trim() == "") {
      newErrors.address = "Area, Street, Sector, Village is required";
      valid = false;
    }

    if (!input.country || input.country.toString()?.trim() == "") {
      newErrors.country = "Country is required";
      valid = false;
    }

    if (!input.state || input.state.toString()?.trim() == "") {
      newErrors.state = "State is required";
      valid = false;
    }

    if (!input.city || input.city.toString() == "") {
      newErrors.city = "City is required";
      valid = false;
    }

    if (input.pin_code?.trim() == "") {
      newErrors.pin_code = "Pin code is required";
      valid = false;
    }

    // if (input.contact_number?.trim() == "") {
    //   newErrors.contact_number = "Number is required";
    //   valid = false;
    // } else if (input.contact_number?.trim()?.length < 10) {
    //   newErrors.contact_number =
    //     "Password should be at least 10 characters long";
    //   valid = false;
    // }

    if (input.start_date?.trim() == "") {
      newErrors.start_date = "Date is required";
      valid = false;
    }

    if (input.start_time?.trim() == "") {
      newErrors.start_time = "Time is required";
      valid = false;
    }

    if (input.end_date?.trim() == "") {
      newErrors.end_date = "Date is required";
      valid = false;
    }

    if (input.end_time?.trim() == "") {
      newErrors.end_time = "Time is required";
      valid = false;
    }

    // if (input.people_range?.toString()?.trim() == "") {
    //   newErrors.people_range = "Guest is required";
    //   valid = false;
    // }

    if (input.description?.trim() == "") {
      newErrors.description = "Description is required";
      valid = false;
    }

    // if (input?.interest_ids?.length <= 0) {
    //   newErrors.interest_ids = "Select at least 5 interests";

    if (input?.people_range_min.trim() == "") {
      newErrors.people_range_min = "Min Guest Range is required";
      valid = false;
    }

    if (input?.people_range_max.trim() == "") {
      newErrors.people_range_max = "Max Guest Range is required";
      valid = false;
    }

    if (input?.tags?.length <= 0) {
      newErrors.tags = "Select interests";
      valid = false;
    } else {
      try {
        const parsedInterests = JSON.parse(input?.tags);

        if (!Array.isArray(parsedInterests) || parsedInterests?.length < 5) {
          newErrors.tags = "Select at least 5 interests";
          valid = false;
        } else if (parsedInterests?.length > 15) {
          newErrors.tags = "Select at most 15 interests";
          valid = false;
        }
      } catch (e) {
        newErrors.tags = "Invalid JSON format";
        valid = false;
      }
    }

    if (input?.status?.trim() == "") {
      newErrors.status = "Status is required";
      valid = false;
    }

    if (input?.total_budget?.trim() == "") {
      newErrors.total_budget = "Total budget is required";
      valid = false;
    }

    const urlRegex = /^https?|ftp:\/\/[^ "]+\/?$/;

    const trimmedWebsiteUrl = input.website_link?.trim();
    if (trimmedWebsiteUrl !== undefined && trimmedWebsiteUrl !== null) {
      if (trimmedWebsiteUrl !== "" && !urlRegex.test(trimmedWebsiteUrl)) {
        newErrors.website_link = "Invalid Website URL";
        valid = false;
      }
    }

    const trimmedFacebookUrl = input.facebook?.trim();
    if (trimmedFacebookUrl !== undefined && trimmedFacebookUrl !== null) {
      if (trimmedFacebookUrl !== "" && !urlRegex.test(trimmedFacebookUrl)) {
        newErrors.facebook = "Invalid Facebook URL";
        valid = false;
      }
    }

    const trimmedtwitterUrl = input.twitter?.trim();
    if (trimmedtwitterUrl !== undefined && trimmedtwitterUrl !== null) {
      if (trimmedtwitterUrl !== "" && !urlRegex.test(trimmedtwitterUrl)) {
        newErrors.twitter = "Invalid Twitter URL";
        valid = false;
      }
    }

    const trimmedlinkedinUrl = input.linkedin?.trim();
    if (trimmedlinkedinUrl !== undefined && trimmedlinkedinUrl !== null) {
      if (trimmedlinkedinUrl !== "" && !urlRegex.test(trimmedlinkedinUrl)) {
        newErrors.linkedin = "Invalid Linkedin URL";
        valid = false;
      }
    }

    const trimmedgoogleinUrl = input.instagram?.trim();
    if (trimmedgoogleinUrl !== undefined && trimmedgoogleinUrl !== null) {
      if (trimmedgoogleinUrl !== "" && !urlRegex.test(trimmedgoogleinUrl)) {
        newErrors.instagram = "Invalid Instagram URL";
        valid = false;
      }
    }

    if (!input.email || !input.email.trim() === "") {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(input.email)) {
      newErrors.email = "Email is invalid";
    } else {
      newErrors.email = "";
    }

    if (!input.house_no || !input.house_no.trim()) {
      newErrors.house_no = "Flat/House No., Building is required";
      valid = false;
    }

    if (!input.landmark || !input.landmark.trim()) {
      newErrors.landmark = "Landmark is required";
      valid = false;
    }

    if (!input.address_type || !input.address_type.trim()) {
      newErrors.address_type = "Address Type is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  {
    errors.event_time_category && (
      <span className="error">{errors.event_time_category}</span>
    );
  }

  const handleSelectIntrest = (selectedOption) => {
    setShowIntrest(selectedOption);
    const list = selectedOption?.map((item) => {
      return item.value;
    });
    setInput({ ...input, tags: JSON?.stringify(list) });
  };

  const handlePhoneNumberChange = (value, country) => {
    const phoneNum = value?.slice(2);
    const d_code = `${country.format.charAt(0)}${country?.dialCode}`;
    setInput({
      ...input,
      contact_number: phoneNum,
      country_code: d_code,
    });
  };

  const handleLanguageChange = (selectedOptions) => {
    const capitalizedLang = selectedOptions
      ?.map((item) => item?.value)
      .filter((value) => /^[a-zA-Z\s()\/]*$/.test(value))
      .map((value) => value.charAt(0).toUpperCase() + value.slice(1));
    setInput({ ...input, languages: JSON.stringify(capitalizedLang) });

    // const capitalizedLang = selectedOptions?.map((item) => {
    //   return item?.value.charAt(0).toUpperCase() + item?.value.slice(1);
    // });
    // setInput({ ...input, languages: JSON.stringify(capitalizedLang) });

    const showInCretable = selectedOptions?.filter((item) => /^[a-zA-Z\s()\/]*$/.test(item?.value))
      ?.map((item) => ({
        value: item?.value?.charAt(0).toUpperCase() + item?.value?.slice(1),
        label: item?.value?.charAt(0).toUpperCase() + item?.value?.slice(1),
      }));
    setShowLanguages(showInCretable);
    // setShowLanguages(selectedOptions?.map((item) => {
    //   return {
    //     value: item?.value.charAt(0).toUpperCase() + item?.value.slice(1),
    //     label: item?.label.charAt(0).toUpperCase() + item?.label.slice(1),
    //   }
    // }))
  };

  const handleGenderChange = (event) => {
    const value = event.target.value;
    const isSelected = input?.gender?.includes(value);
    // if (isSelected) {
    //   setInput({ ...input, gender: input?.gender?.filter((item) => item !== value) })
    // } else {
    //   setInput({ ...input, gender: [...input?.gender, value] })
    // }
    if (isSelected) {
      if (Array.isArray(input.gender)) {
        setInput({
          ...input,
          gender: input.gender?.filter((item) => item !== value),
        });
      } else {
        setInput({ ...input, gender: [] });
      }
    } else {
      if (Array.isArray(input.gender)) {
        setInput({ ...input, gender: [...input.gender, value] });
      } else {
        setInput({ ...input, gender: [value] });
      }
    }
  };

  const handleInputAgeChange = (e) => {
    const { name, value } = e.target;
    if (name == "min_age") {
      const minAge = parseInt(value);
      setInput({ ...input, min_age: minAge });
    } else {
      const maxAge = parseInt(value);
      setInput({ ...input, max_age: maxAge });
    }
  };

  const handlePercentageChange = (gender, value) => {
    // Parse the value to an integer, or default to 0 if it's not a valid number
    const intValue = parseInt(value, 10) || 0;
    // Ensure the value is between 0 and 100
    const validValue = Math.min(100, Math.max(0, intValue));
    setInput((current) => {
      const newInput = { ...current, [gender]: validValue };
      // Calculate the total sum of percentages
      const total =
        parseInt(newInput?.male || 0) +
        parseInt(newInput?.female || 0) +
        parseInt(newInput?.other || 0);
      const remaining = 100 - total;
      // If the sum exceeds 100, adjust the "Other" percentage
      if (remaining < 0) {
        const adjustGender =
          remaining < 0
            ? gender
            : Object.keys(newInput)?.find(
              (key) => key !== gender && key !== "other"
            );
        newInput[adjustGender] -= Math.abs(remaining);
      }
      // Update state with the adjusted percentages
      return newInput;
    });
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="basicInfo">
            <h2 className="basicinfo-head">The Basics</h2>
            <p className="basicinfo-p">
              Add a logo, title and other important information.
            </p>
            {profileinfo?.user_type ? (
              <div className="row">
                <label className="form-label">
                  User <span className="asterisk">*</span>
                </label>
                <div className="form-group col-4 ">
                  <h5 className="text-primary mb-0">
                    {EditData?.users?.f_name} {EditData?.users?.l_name}
                  </h5>
                  {/* {errors.host_id && <span className="error">{errors.host_id}</span>} */}
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="row">
              <div className="col-12 col-md-6">
                <p className="Bannerimg-head">
                  Banner Image<span className="asterisk">*</span>
                </p>
                <label className="bannerimg-div">
                  <input
                    className="d-none"
                    type="file"
                    name="banner"
                    multiple
                    onChange={handleInputChange}
                  />
                  <div className="upload-image-content">
                    <img src={upload} alt="upload" />
                    <p className="uploadimg-font mb-1">Upload Banner Image</p>
                    <p className="img-format-text mb-1">
                      Image format: .jpg or .png
                    </p>
                    <p className="img-format-text">
                      Recommended size: w: 1440px, H: 600px
                    </p>
                  </div>
                </label>
                {input?.banner !== "" && (
                  <div className="mt-2 ">
                    <div className="d-flex justify-content-end">
                      <button className="img-close-btn">
                        <IoClose
                          size={24}
                          onClick={() => setInput({ ...input, banner: "" })}
                        />
                      </button>
                    </div>

                    <div className="mt-2 d-flex justify-content-center">
                      {input?.banner instanceof File ? (
                        <img
                          className="event-logo-img"
                          src={URL.createObjectURL(input.banner)}
                          alt="updateimg"
                        />
                      ) : (
                        <img
                          className="event-logo-img"
                          src={`${baseUrls?.event_banner_image_url}/${input?.banner}`}
                          alt="updateimg"
                        />
                      )}
                    </div>
                  </div>
                )}
                {/* {errors.banner && <span className="error">{errors.banner}</span>} */}
              </div>
              <div className="col-12 col-md-6">
                <p className="Bannerimg-head">
                  Logo<span className="asterisk">*</span>
                </p>
                <label className="bannerimg-div ">
                  <input
                    className="d-none"
                    type="file"
                    name="logo"
                    multiple
                    onChange={handleInputChange}
                  />
                  <div className="upload-image-content">
                    <img src={upload} alt="upload" />
                    <p className="uploadimg-font mb-1">Upload Logo Image</p>
                    <p className="img-format-text mb-1">
                      Image format: .jpg or .png
                    </p>
                    <p className="img-format-text">
                      Recommended size: w: 274px, H: 330px
                    </p>
                  </div>
                </label>

                {input.logo !== "" && (
                  <div className="mt-2">
                    <div className="d-flex justify-content-end">
                      <button className="img-close-btn">
                        <IoClose
                          size={24}
                          onClick={() => setInput({ ...input, logo: "" })}
                        />
                      </button>
                    </div>

                    <div className="mt-2 d-flex justify-content-center">
                      {input.logo instanceof File ? (
                        <img
                          className="event-logo-img"
                          src={URL.createObjectURL(input.logo)}
                          alt="updateimg"
                        />
                      ) : (
                        <img
                          className="event-banner-img"
                          src={`${baseUrls?.logo_image_url}/${input?.logo}`}
                          alt="updateimg"
                        />
                      )}
                    </div>
                  </div>
                )}

                {/* {errors.logo && <span className="error">{errors.logo}</span>} */}
              </div>

              <div className="mt-4">
                <form onSubmit={onSubmit}>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label className="form-label">
                        Event Name<span className="asterisk">*</span>
                      </label>
                      <input
                        type="text"
                        value={input.event_title}
                        name="event_title"
                        className="form-control"
                        placeholder="james"
                        onChange={handleInputChange}
                      />
                      {errors.event_title && (
                        <span className="error">{errors.event_title}</span>
                      )}
                    </div>
                    <div className="form-group col-md-6">
                      <label className="form-label">
                        Event Type<span className="asterisk">*</span>
                      </label>
                      <select
                        className="form-control"
                        name="event_type"
                        onChange={handleInputChange}
                      >
                        <option disabled selected>
                          Select Type
                        </option>
                        {type?.map((val, index) => {
                          return (
                            <>
                              <option
                                key={val?.id}
                                value={val?.id}
                                selected={EditData?.event_type == val?.id}
                              >
                                {val?.name}
                              </option>
                            </>
                          );
                        })}
                      </select>
                      {errors.event_type && (
                        <span className="error">{errors.event_type}</span>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-12 col-md-6">
                      <label className="form-label">
                        Interest<span className="asterisk">*</span>
                      </label>

                      <Select
                        value={showIntrest}
                        isMulti
                        options={predefineIntrest}
                        onChange={handleSelectIntrest}
                        className=""
                        classNamePrefix="select"
                      />
                      <p className="text-primary form-label mb-0 mt-2">
                        <FaInfoCircle /> Minimum 5 Interest Select
                      </p>

                      {errors.tags && (
                        <span className="error">{errors.tags}</span>
                      )}
                    </div>
                    <div className="form-group col-6 col-md-3">
                      <label className="form-label">
                        Mode<span className="asterisk">*</span>
                      </label>
                      <select
                        className="form-control"
                        name="event_mode"
                        onChange={handleInputChange}
                      >
                        <option disabled selected>
                          Select Mode
                        </option>
                        <option
                          value="Online"
                          selected={input?.event_mode == "Online"}
                        >
                          Online
                        </option>
                        <option
                          value="Hybrid"
                          selected={input?.event_mode == "Hybrid"}
                        >
                          Hybrid
                        </option>
                        <option
                          value="Offline_Indoor"
                          selected={input?.event_mode == "Offline_Indoor"}
                        >
                          Offline Indoor
                        </option>
                        <option
                          value="Offline_Outdoor"
                          selected={input?.event_mode == "Offline_Outdoor"}
                        >
                          Offline Outdoor
                        </option>
                      </select>
                      {errors.event_mode && (
                        <span className="error">{errors.event_mode}</span>
                      )}
                    </div>
                    {input.event_mode == "Offline_Indoor" ||
                      input.event_mode == "Offline_Outdoor" ? (
                      <div className="form-group col-6 col-md-3">
                        <label className="form-label">
                          Time Category<span className="asterisk">*</span>
                        </label>
                        <select
                          className="form-control"
                          name="event_time_category"
                          onChange={handleInputChange}
                        >
                          <option selected disabled>
                            Select Time Category...
                          </option>
                          <option
                            value="Day_event"
                            selected={input?.event_time_category == "Day_event"}
                          >
                            Day Event
                          </option>
                          <option
                            value="Night_event"
                            selected={
                              input?.event_time_category == "Night_event"
                            }
                          >
                            Night Event
                          </option>
                          <option
                            selected={input?.event_time_category == "Full_day"}
                            value="Full_day"
                          >
                            Full Day
                          </option>
                        </select>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="row gap-md-4">
                    <div className="form-group col-6 col-md-3">
                      <label className="form-label">
                        Budget<span className="asterisk">*</span>
                      </label>
                      <input
                        type="number"
                        min='0'
                        value={input.total_budget}
                        name="total_budget"
                        className="form-control"
                        placeholder="Budget"
                        onChange={handleInputChange}
                      />
                    </div>
                    {liveSample == 0 ? '' :
                      <div className="form-group col-3">
                        <label className="form-label">
                          Status<span className="asterisk">*</span>
                        </label>

                        <div className="d-flex gap-4 mt-2">
                          <div className="form-check">
                            <label className="form-check-label">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="status"
                                value="Live"
                                onChange={handleInputChange}
                                checked={input?.status == "Live"}
                              />
                              Live
                            </label>
                          </div>
                          <div class="form-check">
                            <label className="form-check-label">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="status"
                                value="Sample"
                                onChange={handleInputChange}
                                checked={input?.status == "Sample"}
                              />
                              Sample
                            </label>
                          </div>
                        </div>
                      </div>
                    }
                  </div>

                  <div className="row">
                    <h5 className="my-3 form-section-heading">Address</h5>
                    <div className="form-group col-6 col-md-3 mt-2">
                      <label className="form-label">
                        Pin Code<span className="asterisk">*</span>
                      </label>
                      <input
                        type="number"
                        min='0'
                        value={input.pin_code}
                        name="pin_code"
                        onChange={(e) => handlePinCodeChange(e.target.value)}
                        className="form-control"
                        placeholder="Pin Code..."
                      />
                      {errors.pin_code && (
                        <span className="error">{errors.pin_code}</span>
                      )}
                    </div>
                    <div className="form-group col-6 col-md-3 mt-2">
                      <label className="form-label">
                        Country<span className="asterisk">*</span>
                      </label>
                      <select
                        className="form-control"
                        name="country"
                        onChange={handleCountryChange}
                      >
                        <option selected>Select Country...</option>

                        {countries &&
                          countries?.map((val, index) => {
                            return (
                              <option
                                key={index}
                                value={val?.country_id}
                                selected={
                                  Object?.keys(zip_country)?.length == 0
                                    ? val?.country_name?.toLowerCase() ==
                                    input?.country?.toLowerCase()
                                    : zip_country?.long_name ==
                                    val?.country_name
                                }>
                                {val?.country_name}
                              </option>
                            );
                          })}
                      </select>
                      {errors.country && (
                        <span className="error">{errors.country}</span>
                      )}
                    </div>

                    <div className="form-group col-6 col-md-3 mt-2">
                      <label className="form-label">
                        State<span className="asterisk">*</span>
                      </label>
                      <select
                        className="form-control"
                        name="state"
                        onChange={handleStateChange}
                      >
                        <option selected>Select State...</option>
                        {states &&
                          states?.map((val, index) => {
                            return (
                              <option
                                key={index}
                                value={val?.state_id}
                                selected={
                                  Object.keys(zip_state)?.length == 0
                                    ? input?.state?.toLowerCase() ==
                                    val?.state_name?.toLowerCase()
                                    : zip_state?.long_name == val?.state_name
                                }
                              >
                                {val?.state_name}
                              </option>
                            );
                          })}
                      </select>
                      {errors.state && (
                        <span className="error">{errors.state}</span>
                      )}
                    </div>

                    <div className="form-group col-6 col-md-3 mt-2">
                      <label className="form-label">
                        City<span className="asterisk">*</span>
                      </label>
                      <select
                        className="form-control"
                        name="city"
                        onChange={handleCityChange}
                      >
                        <option selected>Select City...</option>
                        {cities &&
                          cities?.map((val, index) => {
                            //  console.log("ccc",input?.city?.toLowerCase() == val?.city_name?.toLowerCase())
                            return (
                              <>
                                <option
                                  key={index}
                                  value={val?.city_id}
                                  selected={
                                    Object.keys(zip_city)?.length == 0
                                      ? input?.city?.toLowerCase() ==
                                      val?.city_name?.toLowerCase()
                                      : zip_city?.long_name == val?.city_name
                                  }
                                >
                                  {val?.city_name}
                                </option>
                              </>
                            );
                          })}
                      </select>
                      {errors.city && (
                        <span className="error">{errors.city}</span>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-md-3 col-sm-6">
                      <label className="form-label">
                      Flat/House No., Building
                        <span className="asterisk">*</span>
                      </label>

                      <input
                        type="text"
                        value={input.house_no}
                        name="house_no"
                        onChange={handleInputChange}
                        className="form-control"
                        placeholder="Flat/House No., Building"
                      />
                      {errors.house_no && (
                        <span className="error">{errors.house_no}</span>
                      )}
                    </div>

                    <div className="form-group col-md-6">
                      <label className="form-label">
                        Area, Street, Sector, Village
                        <span className="asterisk">*</span>
                      </label>
                      <input
                        type="text"
                        value={input?.address}
                        name="address"
                        onChange={handleInputChange}
                        className="form-control"
                        placeholder="Area, Street, Sector, Village..."
                      />
                      {errors.address && (
                        <span className="error">{errors.address}</span>
                      )}
                      {/* <p className="text-primary form-label mb-0 mt-2">
                        <FaInfoCircle /> Current Area, Street, Sector, Village Select With A Map
                      </p> */}
                    </div>
                    <div className="form-group col-md-3 col-sm-6">
                      <label className="form-label">
                        Landmark<span className="asterisk">*</span>
                      </label>

                      <input
                        type="text"
                        value={input.landmark}
                        name="landmark"
                        onChange={handleInputChange}
                        className="form-control"
                        placeholder="Landmark"
                      />
                      {errors.landmark && (
                        <span className="error">{errors.landmark}</span>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-md-3 col-sm-6">
                      <label className="form-label">
                        Address Type<span className="asterisk">*</span>
                      </label>
                      <select
                        className="form-control"
                        name="address_type"
                        onChange={handleInputChange}
                      >
                        <option value="" selected>
                          Select Address Type...
                        </option>
                        <option
                          value="Home"
                          selected={input.address_type == "Home"}
                        >
                          Home
                        </option>
                        <option
                          value="Office"
                          selected={input.address_type == "Office"}
                        >
                          Office
                        </option>
                      </select>
                      {errors.address_type && (
                        <span className="error">{errors.address_type}</span>
                      )}
                    </div>
                    <div className="form-group col-md-3">
                      <label className="form-label">
                        Contact Number<span className="asterisk">*</span>
                      </label>
                      <PhoneInput
                        className=""
                        country={"in"}
                        value={`${input?.country_code}${input?.contact_number}`}
                        onChange={handlePhoneNumberChange}
                      />
                      {errors.contact_number && (
                        <span className="error">{errors.contact_number}</span>
                      )}
                    </div>

                    <div className="col-md-3 form-group">
                      <label className="form-label">
                        Email Address<span className="asterisk">*</span>
                      </label>
                      <div className="position-relative d-flex align-items-center">
                        <input
                          type="email"
                          className="form-control rounded-3"
                          placeholder="Enter Email Address"
                          name="email"
                          value={input.email}
                          onChange={handleInputChange}
                        />
                      </div>

                      {errors.email && (
                        <span className="error">{errors.email} </span>
                      )}
                    </div>
                  </div>

                  {isLoaded ? (
                    <GoogleMap
                      mapContainerStyle={{ height: "400px", width: "100%" }}
                      center={center}
                      zoom={13}
                      // onLoad={onLoad}
                      onUnmount={onUnmount}
                      onClick={handleMapClick}
                    >
                      {marker && <Marker position={marker} />}
                    </GoogleMap>
                  ) : (
                    ""
                  )}

                  <div className="row">
                    <h5 className="my-3 form-section-heading">
                      When is the event happening?
                    </h5>
                    <div className="form-group col-6 col-md-3">
                      <label className="form-label">
                        Start Date<span className="asterisk">*</span>
                      </label>
                      <div>
                        <input
                          type="date"
                          className="form-control"
                          name="start_date"
                          value={input.start_date}
                          onChange={handleInputChange}
                        />
                      </div>
                      {errors.start_date && (
                        <span className="error">{errors.start_date}</span>
                      )}
                    </div>

                    <div className="form-group col-6 col-md-3">
                      <label className="form-label">
                        Start Time<span className="asterisk">*</span>
                      </label>
                      <div>
                        <input
                          type="time"
                          className="form-control"
                          step="900"
                          pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$"
                          name="start_time"
                          value={input.start_time}
                          onChange={handleInputChange}
                        />
                      </div>
                      {errors.start_time && (
                        <span className="error">{errors.start_time}</span>
                      )}
                    </div>

                    <div className="form-group col-6 col-md-3">
                      <label className="form-label">
                        End Date<span className="asterisk">*</span>
                      </label>

                      <div>
                        <input
                          type="date"
                          className="form-control"
                          name="end_date"
                          value={input.end_date}
                          onChange={handleInputChange}
                        />
                      </div>
                      {errors.end_date && (
                        <span className="error">{errors.end_date}</span>
                      )}
                    </div>

                    <div className="col-6 col-md-3">
                      <label className="form-label">
                        End Time<span className="asterisk">*</span>
                      </label>
                      <div className="">
                        <input
                          type="time"
                          className="form-control"
                          step="900"
                          pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$"
                          name="end_time"
                          value={input.end_time}
                          onChange={handleInputChange}
                        />
                      </div>
                      {errors.start_time && (
                        <span className="error">{errors.start_time}</span>
                      )}
                    </div>
                  </div>

                  <div className="row form-group">
                    <h5 className="my-3 form-section-heading">
                      How many guest are attending the event?
                    </h5>
                    <div className="col-12 col-md-6 row m-0 p-0">
                      <label className="form-label">
                        Guest Range<span className="asterisk">*</span>
                      </label>
                      <div className="col-6 col-md-6">
                        <input
                          type="number"
                          min='0'
                          className="form-control"
                          name="people_range_min"
                          placeholder="Min"
                          value={input.people_range_min}
                          onChange={handleInputChange}
                        />
                        {errors.people_range_min && (
                          <span className="error">
                            {errors.people_range_min}
                          </span>
                        )}
                      </div>

                      <div className="col-6 col-md-6">
                        <input
                          type="number"
                          min='0'
                          className="form-control"
                          name="people_range_max"
                          placeholder="Max"
                          value={input.people_range_max}
                          onChange={handleInputChange}
                        />
                        {errors.people_range_max && (
                          <span className="error">
                            {errors.people_range_max}
                          </span>
                        )}
                      </div>
                      {/* <input
                        type="number"
                         min='0'
                        className="form-control"
                        name="people_range"
                        placeholder="Ex: 1-100"
                        value={input?.people_range}
                        onChange={handleInputChange}
                      /> */}
                      {/* <select
                        className="form-control"
                        name="people_range"
                        onChange={handleInputChange}
                      >
                        <option disabled selected>
                          Select Guest
                        </option>
                        {eventPeopleRange?.map((val, index) => {
                          return (
                            <>
                              <option
                                key={val?.id}
                                value={val?.id}
                                selected={EditData?.people_range == val?.id}
                              >
                                {val?.people_range}
                              </option>
                            </>
                          );
                        })}
                      </select> */}
                      {errors.people_range && (
                        <span className="error">{errors.people_range}</span>
                      )}
                    </div>

                    <div className="col-6 col-md-3 mt-3 mt-md-0">
                      <label className="form-label">Age From:</label>
                      <select
                        className="form-control"
                        name="min_age"
                        value={input?.min_age}
                        onChange={handleInputAgeChange}
                      >
                        {[...Array(input?.max_age + 1)?.keys()]?.map((age) => (
                          <option key={age} value={age}>
                            {age}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-6 col-md-3 mt-3 mt-md-0">
                      <label className="form-label">Age To:</label>

                      <select
                        className="form-control"
                        name="max_age"
                        value={input?.max_age}
                        onChange={handleInputAgeChange}
                      >
                        {[...Array(100 - input?.min_age + 1)?.keys()]?.map(
                          (age) => (
                            <option
                              key={input?.min_age + age}
                              value={input?.min_age + age}
                            >
                              {input?.min_age + age}
                            </option>
                          )
                        )}
                      </select>
                    </div>

                    {/* <div className="col-md-4">
                      <label className="form-label">
                        Age From
                      </label>

                      <input
                        type="number"
                         min='0'
                        value={input.min_age}
                        name="min_age"
                        onChange={handleInputChange}
                        className="form-control"
                        placeholder="Minimum"
                      />
                    </div>

                    <div className="col-md-4 ">
                      <label className="form-label">
                        Age To
                      </label>
                      <input
                        type="number"
                         min='0'
                        value={input.max_age}
                        name="max_age"
                        onChange={handleInputChange}
                        className="form-control"
                        placeholder="Miximum"
                      />
                    </div> */}
                  </div>
                  {/* createtable */}
                  <div className="row mt-4">
                    <div className="form-group col-md-6">
                      <label className="form-label">Languages Known</label>
                      <CreatableSelect
                        isMulti
                        value={showlanguages}
                        classNamePrefix="select"
                        placeholder="Type Language and press enter..."
                        className=""
                        onChange={handleLanguageChange}
                        options={listingLanguage}
                      />
                      {errors.language && (
                        <span className="error">{errors.language}</span>
                      )}
                    </div>

                    <div className="form-group col-md-6">
                      {/* <label className="form-label">
                        Gender
                      </label> */}

                      <div className="row">
                        <div className="col-6 col-md-4">
                          <label className="form-label">
                            Male:
                            <input
                              className="form-control"
                              type="number"
                              min='0'
                              placeholder="Ex: 0-100%"
                              value={input.male}
                              onChange={(e) =>
                                handlePercentageChange("male", e.target.value)
                              }
                            />
                          </label>
                        </div>

                        <div className="col-6 col-md-4">
                          <label className="form-label">
                            Female:
                            <input
                              className="form-control"
                              type="number"
                              min='0'
                              placeholder="Ex: 0-100%"
                              value={input.female}
                              onChange={(e) =>
                                handlePercentageChange("female", e.target.value)
                              }
                            />
                          </label>
                        </div>

                        <div className="col-6 col-md-4">
                          <label className="form-label">
                            Other:
                            <input
                              className="form-control"
                              type="number"
                              min='0'
                              placeholder="Ex: 0-100%"
                              value={input.other}
                              onChange={(e) =>
                                handlePercentageChange("other", e.target.value)
                              }
                            />
                          </label>
                        </div>
                      </div>

                      {/* <div className="d-flex gap-3 mt-2">
                        <div className="form-check">
                          <label className="form-check-label">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="gender"
                              value="Male"
                              checked={input?.gender?.includes('Male')}
                              onChange={handleGenderChange}
                            />
                            Male
                          </label>
                        </div>
                        <div class="form-check">
                          <label className="form-check-label">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="gender"
                              value="Female"
                              checked={input?.gender?.includes('Female')}
                              onChange={handleGenderChange}
                            />
                            Female
                          </label>
                        </div>
                        <div class="form-check">
                          <label className="form-check-label">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="gender"
                              value="Other"
                              checked={input?.gender?.includes('Other')}
                              onChange={handleGenderChange}
                            />
                            Other
                          </label>
                        </div>
                      </div> */}
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-md-6">
                      <label className="form-label">
                        Description<span className="asterisk">*</span>
                      </label>
                      <div>
                        <textarea
                          className="form-control"
                          type="text"
                          name="description"
                          placeholder="Description.."
                          value={input.description}
                          onChange={handleInputChange}
                          rows="3"
                        ></textarea>
                      </div>
                      {errors.description && (
                        <span className="error">{errors.description}</span>
                      )}
                    </div>

                    <div className="col-md-6">
                      <label className="form-label">Background of Guest</label>
                      <div>
                        <textarea
                          className="form-control"
                          type="text"
                          name="background_of_guest"
                          placeholder="Background of Guest"
                          value={input.background_of_guest}
                          onChange={handleInputChange}
                          rows="3"
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <h5 className="my-3 form-section-heading">Links</h5>
                    <div className="form-group col-md-6">
                      <label className="form-label">Website URL</label>
                      <input
                        type="text"
                        value={input.website_link}
                        name="website_link"
                        onChange={handleInputChange}
                        className="form-control"
                        placeholder="url"
                      />
                      {errors.website_link && (
                        <span className="error">{errors.website_link}</span>
                      )}
                    </div>

                    {/* <div className="col-2 d-flex align-items-center">
                   <button className="btn addlink-btn ">Add Link</button>
                   </div> */}
                  </div>

                  <div className="row">
                    <h5 className="my-3 form-section-heading">Social Media</h5>
                    <div className="form-group col-md-6">
                      <label className="form-label">Facebook URL</label>
                      <input
                        type="text"
                        value={input?.facebook}
                        name="facebook"
                        onChange={handleInputChange}
                        className="form-control"
                        placeholder="url"
                      />
                      {errors.facebook && (
                        <span className="error">{errors.facebook}</span>
                      )}
                    </div>
                    <div className="form-group col-md-6">
                      <label className="form-label">Twitter URL</label>
                      <input
                        type="text"
                        value={input?.twitter}
                        name="twitter"
                        onChange={handleInputChange}
                        className="form-control"
                        placeholder="url"
                      />
                      {errors.twitter && (
                        <span className="error">{errors.twitter}</span>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-md-6">
                      <label className="form-label">Linkedin URL</label>
                      <input
                        type="text"
                        value={input?.linkedin}
                        name="linkedin"
                        onChange={handleInputChange}
                        className="form-control"
                        placeholder="url"
                      />
                      {errors.linkedin && (
                        <span className="error">{errors.linkedin}</span>
                      )}
                    </div>
                    <div className="form-group col-md-6">
                      <label className="form-label">Instagram URL</label>
                      <input
                        type="text"
                        value={input?.instagram}
                        name="instagram"
                        onChange={handleInputChange}
                        className="form-control"
                        placeholder="url"
                      />
                      {errors.instagram && (
                        <span className="error">{errors.instagram}</span>
                      )}
                    </div>
                  </div>
                  <div className="d-flex justify-content-end align-items-center">
                    {/* <button className="btn delete-event-btn">
                      Delete Event
                    </button> */}
                    <div className="">
                      <button className="btn saveandcontinue-btn" type="submit">
                        {btnLoading ? (
                          <div
                            className="spinner-border text-light"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          ""
                        )}
                        Save and Continue
                        <img className="arrow-img" src={arrow} alt="arrow" />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default EditBasicInformation;
