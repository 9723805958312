import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
// import 'swiper/swiper-bundle.min.css';  
import { Autoplay, Pagination, Navigation, Scrollbar, A11y } from 'swiper/modules'
import Loader from '../Loader/Loader';


const EventBanner = ({ midBannerList, loading, ConfigData }) => {

  // console.log("midBannerList",midBannerList)

  return (<>
    <div className='banner-div'>
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        modules={[Autoplay, Pagination, Navigation, Scrollbar, A11y]}
        navigation
        loop={true}
        autoplay={{
          delay: ConfigData?.banner_speed ? ConfigData?.banner_speed : 4000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
          bulletClass: 'swiper-pagination-bullet custom-bullet',
          bulletActiveClass: 'swiper-pagination-bullet-active custom-bullet-active', 
        }}
        effect="fade"
        speed={1000}
        className='container p-0'>
        {midBannerList?.map((item) => {
          return (
            <SwiperSlide key={item?.id}><div key={item?.id}>
              <img className='banner-img'
                loading='lazy'
                src={`${ConfigData?.base_urls?.banner_image_url}/${item?.photo}`} alt='img1' /></div></SwiperSlide>
          )
        })}
      </Swiper>
      <div className="custom-pagination"></div>
    </div>
  </>)
};

export default EventBanner;