import { IoWarningOutline } from "react-icons/io5";


const DeleteChatWarningModal=({msg,handleDeleteChat, setDeleteChatMdl})=>{
  return(<>
      <div className="warningModal">
          <div className="warning-mdl">
            {/* <span className="warning-close-btn" >&times;</span> */}
            <div className="d-flex justify-content-center mb-3">
              <IoWarningOutline color="#ffcc00" size={40} />
            </div>
            <h4 className="text-center warning-text">
              {msg}
            </h4>
            <div className="d-flex gap-2 mt-4  justify-content-end">
            <button
                className="mdl-cancel-btn"
                onClick={() =>setDeleteChatMdl(false)}>
                 No
              </button>
              <button
                className="warning-mdl-btn "
                onClick={handleDeleteChat}>
                 Yes
              </button>
            </div>
          </div>
        </div>  
  </>)
};

export default DeleteChatWarningModal;