import { GetOrderDetail } from "../../Services/Apis"



export const Get_Order_Detail=async(currentPage,sortValue,searchValue,statusValue)=>{
    try{
        const response = await GetOrderDetail(currentPage,sortValue,searchValue,statusValue).then((data) => data)
        return response
    }catch(error){
        console.log(error)
    }
} 
