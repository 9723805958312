import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    message:"",
    userType:""
}


export const Login = createSlice({
  name: 'login',
  initialState:initialState,
  reducers: {
    loginmessage: (state,action) => {
      const { userType, message } = action.payload;
      //  state.message = action.payload
       state.userType = userType;
       state.message = message;
    },
  },
})

// Action creators are generated for each case reducer function
export const { loginmessage } = Login.actions;

export default Login.reducer;