import React, { Suspense, lazy, useEffect, useState } from 'react';
// import WhyUs from '../../components/Home/WhyUs';
// import Speakers from '../../components/Home/Speakers';
// import EventSchedules from '../../components/Home/EventSchedules';
import FunFact from '../../components/Common/FunFact';
// import Pricing from '../../components/Home/Pricing';
// import LatesNews from '../../components/Home/LatestNews';
// import BuyTicket from '../../components/Common/BuyTicket';
// import Subscribe from '../../components/Common/Subscribe';
// import Footer from '../../components/Common/Footer';
// import GoTop from '../../components/Shared/GoTop';

import MainBanner from '../../components/Home/MainBanner';
import Featured from '../../components/Home/Featured';
import Recommended from '../../components/Home/Recommended';
import HotEvents from '../../components/Home/HotEvents';
import Eventbanner from '../../components/Home/Eventbanner';
import LastMinute from '../../components/Home/LastMinute';
import NewlyAdded from '../../components/Home/NewlyAdded';
import Partner from '../../components/Common/Partner';
// import UserTestimonials from '../../components/Home/UserTestimonials';
// import PastEvents from '../../components/Home/PastEvents';
import BottomBanner from '../../components/Home/BottomBanner';

import WhySponsorRingUsComp from '../../components/Home/WhySponsorRingUsComp';
import { Bannerdata } from "../../utils/homeManager/BannerManager";
import SocialFeeds from '../../components/Home/SocialFeeds';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { loginUserData } from '../../utils/user/UserManager';
import { useDispatch, useSelector } from 'react-redux';
import { loginmessage } from '../../redux/Login';
import { getSponsorList } from '../../utils/sponsordata/MySponsorData';
import { AuthHomeEvents, HomeEvents, Near_By_CuttoffDate } from '../../utils/homeManager/EventsManager';
import Loader from '../../components/Loader/Loader';
import ConfettiExplosion from 'react-confetti-explosion';
import { isTokenValid } from '../../AuthbyToken';
import NewUserSignupPopup from '../../components/Common/NewUserSignupPopup';
import EventCuttoffDateModifyPopup from '../../components/Common/Home/EventCuttoffDateModifyPopup';
import PublishEventPopUp from '../../components/Common/PublishEventPopUp';

const Home = () => {
    const loginData = useSelector((state) => state?.login);
    const ConfigData = useSelector((state) => state?.config_data?.configData);
    const baseUrls = ConfigData?.base_urls;

    const dispatch = useDispatch();
    const [mainBannerList, setMainBannerList] = useState([]);
    const [midBannerList, setmidBannerList] = useState([]);
    const [bottomBannerList, setbottomBannerList] = useState([]);
    const [partnerPostarList, setpartnerPostarList] = useState([]);
    const [FeaturedEvents, setFeaturedEvents] = useState([]);
    const [recommendedEvents, setRecommendedvEvents] = useState([]);
    const [HotEventsData, setHotEventsData] = useState([]);
    const [lastMinuteEvents, setLastMinuteEvents] = useState([]);
    const [newlyaddedEvent, setNewlyaddedEvent] = useState([]);
    const [funfactData, setFunFactData] = useState({
        total_events: '',
        total_host: '', total_sponsor: '', total_attendance: ''
    })
    const [pastEvent, setPastEvent] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hurrayAnimation, setHurrayAnimation] = useState(false);
    const [event_Data, setEvent_Data] = useState({
        id: "",
        title: "",
        event_status: "",
        publish_type: ""
    });
    const [publishMdl, setPublishMdl] = useState(false);
    const [nearByCuttoffDateData,setNearByCuttoffDateData]=useState([]);
    const [nearByCuttoffDateMdl,setNearByCuttoffDateMdl]=useState(false);

    const lat_longData = localStorage.getItem('let_long');
    const PastEvents = lazy(() => import('../../components/Home/PastEvents'));
    const UserTestimonials = lazy(() => import('../../components/Home/UserTestimonials'));
    const isValid = isTokenValid();


    const GetBanner = async () => {
        try {
            const result = await Bannerdata();
            setMainBannerList(result?.filter((data) => data?.banner_type === "Main Banner"));
            setmidBannerList(result?.filter((data) => data?.banner_type === "Mid Banner"));
            setbottomBannerList(result?.filter((data) => data?.banner_type === "Bottom Banner"));
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const GetEvents = async () => {
        const defaultLatLong = { lat: 40.7128, lng: -74.0060 };
        const latlongData = localStorage.getItem('let_long');
        const latlong = latlongData ? JSON.parse(latlongData) : defaultLatLong;

        if (isValid) {
            try {
                const res = await AuthHomeEvents(latlong)
                const EventsData = res?.data;
                setFunFactData({
                    total_events: EventsData?.total_events, total_host: EventsData?.total_host,
                    total_sponsor: EventsData?.total_sponsor, total_attendance: EventsData?.total_attendance,
                })
                setFeaturedEvents(EventsData?.featured_events);
                setRecommendedvEvents(EventsData?.recommended_events);
                setHotEventsData(EventsData?.hot_events);
                setLastMinuteEvents(EventsData?.last_minute_event);
                setNewlyaddedEvent(EventsData?.newly_added_event);
                setPastEvent(EventsData?.past_events);
            } catch (err) {
                console.log(err)
            }
        } else {
            try {
                const res = await HomeEvents(latlong)
                const EventsData = res?.data;
                setFunFactData({
                    total_events: EventsData?.total_events, total_host: EventsData?.total_host,
                    total_sponsor: EventsData?.total_sponsor, total_attendance: EventsData?.total_attendance,
                })
                setFeaturedEvents(EventsData?.featured_events);
                setRecommendedvEvents(EventsData?.recommended_events);
                setHotEventsData(EventsData?.hot_events);
                setLastMinuteEvents(EventsData?.last_minute_event);
                setNewlyaddedEvent(EventsData?.newly_added_event);
                setPastEvent(EventsData?.past_events);
            } catch (err) {
                console.log(err)
            }
        }
    }

    const SponsorList = async () => {
        try {
            await getSponsorList()
            .then((result) => {
              setpartnerPostarList(result?.data?.data)
            })

        } catch (error) {
            console.log(error)
        } 
    }


    const handleCheckMdl=()=>{
      const is_skip = localStorage.getItem('near_by_cuttoff_date_mdl');
       if(is_skip == "is_skip"){
        setNearByCuttoffDateMdl(false);
       }else{
        setNearByCuttoffDateMdl(true);
       }
       
      // if(data?.length > 0){
      //   setNearByCuttoffDateMdl(true);
      //  }else{
      //   setNearByCuttoffDateMdl(false);
      //  }

    }

    const GetNear_By_CuttoffDate=async()=>{
     try{
       const response = await Near_By_CuttoffDate();
       const data = response?.data?.data;
       setNearByCuttoffDateData(data);
       
       const ids = data?.map((val)=>{
        return val?.id
       });
       const localId = JSON.parse(localStorage.getItem('near_by_cuttoff_date_id'));
       const localAllId = localId ? localId : [];

       const arraysAreEqual = (arr1, arr2) => {
        if (arr1?.length !== arr2.length) return false;
         return arr1?.every((val, index) => val == arr2[index]);
        };
        
        if(data?.length > 0 & !arraysAreEqual(ids,localAllId)){
          handleCheckMdl();
          localStorage.setItem('near_by_cuttoff_date_mdl','');
        }else {
          console.log("IDs match, not calling handleCheckMdl()");
        }        
      }
      catch(err){
       console.log(err)
     }
    }
    // console.log("nearByCuttoffDateData",nearByCuttoffDateData)

    useEffect(() => {
      GetBanner();
      GetEvents();
      SponsorList();
      if(isValid){
        GetNear_By_CuttoffDate();   
       }
    }, [lat_longData]);

    useEffect(() => {
        if (loginData?.message !== "") {
            toast.success(loginData?.message, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              theme: "light",
            });
          dispatch(loginmessage({userType:loginData?.userType, message: "" }));
          setHurrayAnimation(true);
        }
    },[loginData]);
   
        return (<>
           { nearByCuttoffDateMdl && <EventCuttoffDateModifyPopup event={nearByCuttoffDateData} setEvent_Data={setEvent_Data} baseUrls={baseUrls} 
             publishMdl={publishMdl} setPublishMdl={setPublishMdl} setNearByCuttoffDateMdl={setNearByCuttoffDateMdl}/>
           }
           
           { publishMdl && <PublishEventPopUp event_Data={event_Data} setPublishMdl={setPublishMdl} getMyEvent={""} 
             setEvent_Data={setEvent_Data} setNearByCuttoffDateMdl={setNearByCuttoffDateMdl}/>}
           
           <div className="ConfettiExplosion-container">
              {hurrayAnimation && (
                <div className="ConfettiExplosion-center">
                 <ConfettiExplosion blast={true} duration={4000} numberOfPieces={5000} width={1600} />
                </div>
               )}
           </div>

            {false && <NewUserSignupPopup/>}
            
            {loading ? <Loader />
               :
               <>
               {/* 1 */}
               <MainBanner mainBannerList={mainBannerList} loading={loading} ConfigData={ConfigData} />
               {/* 2 */}
               {/* {FeaturedEvents?.length <= 0 ? "" : <Featured FeaturedEvents={FeaturedEvents} />} */}
               {/* 3 */}
               {recommendedEvents?.length <= 0 ? "" : <Recommended recommendedEvents={recommendedEvents} />}
               {/* 4 */}
               <Eventbanner midBannerList={midBannerList} loading={loading} ConfigData={ConfigData} />
               {/* 5 */}
               {HotEventsData?.length <= 0 ? "" : <HotEvents HotEventsData={HotEventsData} />}
               {/* 6 */}
               {/* {lastMinuteEvents?.length <= 0 ? "" : <LastMinute lastMinuteEvents={lastMinuteEvents} />} */}
               {/* 7 */}
               {newlyaddedEvent?.length <= 0 ? "" : <NewlyAdded newlyaddedEvent={newlyaddedEvent} />}
               {/* 8 */}
               {partnerPostarList?.length <= 0 ? "" : <Partner partnerPostarList={partnerPostarList} baseUrls={baseUrls} />}
               {/* 9 */}
               <Suspense fallback={<Loader />}>
                   <UserTestimonials />
               </Suspense>
               {/* 10 */}
               {pastEvent?.filter(
                 (event) => event?.past_event_gellary?.length > 0 &&
                  <Suspense fallback={<Loader />}>
                   <PastEvents pastEvent={pastEvent} />
                  </Suspense>
               )}

                    {/* 11 */}
                    {/* <FunFact funfactData={funfactData} /> */}
                    {/* 12 */}
                    < WhySponsorRingUsComp />
                    {/* 13 */}
                    <BottomBanner bottomBannerList={bottomBannerList} loading={loading} ConfigData={ConfigData} />
                    {/* 14 */}
                    < SocialFeeds />
                </>
            }

            {/* 15 */}

            {/* 16 */}

            {/* AboutArea */}
            {/* <About /> */}

            {/* Why Choose Us Area */}
            {/* <WhyUs /> */}

            {/* Speakers Area */}
            {/* <Speakers /> */}

            {/* Schedule Area */}
            {/* <EventSchedules /> */}

            {/* FunFacts Area */}
            {/* <FunFact /> */}

            {/* Pricing Area */}
            {/* <Pricing /> */}

            {/* Partner Area */}

            {/* Blog Area */}
            {/* <LatesNews /> */}

            {/* Buy Tickets Area */}
            {/* <BuyTicket /> */}

            {/* Subscribe Area */}
            {/* <Subscribe /> */}

            {/* Footer Area */}

            {/* Back Top top */}
            {/* <GoTop scrollStepInPx="50" delayInMs="16.66" /> */}
        </>
    );
};

export default Home;