
const getToken = () => {
    const token = localStorage.getItem('LoginToken');
    return token;
  };
  
  const isTokenValid = () => {
    const token = getToken();
    return token !== null && token !== undefined && token !== '';
  };
  
  export { isTokenValid };
  