import { useState } from 'react'
import AccountManagment from '../../components/SettingChild/AccountMangment';
import { useSelector } from 'react-redux';
import ChangePassword from '../../components/SettingChild/ChangePassword';
import { Link, useNavigate } from 'react-router-dom';
import AcountLoginActivity from '../../components/SettingChild/AccountLoginActivity';
import Notification from '../Notification/Notification';
import EventHostUi from '../../components/Common/EventDetail/EventHostUi';
import { TiArrowLeft } from 'react-icons/ti';

const Setting = () => {
    const counter = useSelector(state => state?.config_data?.configData)
    const profile = useSelector(state => state?.Profile_data?.ProfileData)
    const [tabValue, setTabValue] = useState('change-password')
    const navigate = useNavigate();
    
    return (
        <>
            <div>
             <div className="container my-4">
               <div className="d-flex justify-content-between pb-3">
                 <h3 className="">Settings</h3>
                 <button className="go-back-btn" onClick={() => navigate(-1)} type="button">
                  <TiArrowLeft size={20}/> Go Back
                 </button>
                </div>
                    <div className="row">
                        <div className="col-md-3 border p-0">
                            <div className="d-grid">
                                <button className={`${tabValue == "change-password" ? "active-create-event-tab p-3" : "create-event-tab p-3"
                                    } `}
                                    onClick={() => setTabValue("change-password")}>
                                    Change Password
                                </button>

                                <Link to={`/editprofile/${profile?.id}`} className={`${tabValue == "update-profile" ? "active-create-event-tab p-3" : "create-event-tab p-3"
                                    } `}
                                    onClick={() => setTabValue("update-profile")}>
                                    Update profile
                                </Link>

                                <button className={`${tabValue == "notification-settings" ? "active-create-event-tab p-3" : "create-event-tab p-3"
                                    } `}
                                    onClick={() => setTabValue("notification-settings")}>
                                    Notifications
                                </button>

                                <button className={`${tabValue == "account-login-activity" ? "active-create-event-tab p-3" : "create-event-tab p-3"
                                    } `}
                                    onClick={() => setTabValue("account-login-activity")}>
                                    Account / login activity
                                </button>
                                <button className={`${tabValue == "terms" ? "active-create-event-tab p-3" : "create-event-tab p-3"
                                    } `}
                                    onClick={() => setTabValue("terms")}>
                                    Terms of Use
                                </button>

                                <button className={`${tabValue == "privacy" ? "active-create-event-tab p-3" : "create-event-tab p-3"
                                    } `}
                                    onClick={() => setTabValue("privacy")}>
                                    Privacy Policy
                                </button>
                            </div>
                        </div>
                        <div className="col-md-9 border p-0 overflow-y-scroll setting-scroll-height">
                            {tabValue == "account-management" ? (
                                <div className="p-3">
                                    <AccountManagment />
                                </div>
                            ) : tabValue == "privacy" ? (
                                <div className="p-3">
                                    <div className="" style={{ fontFamily: 'poppins' }}>
                                        <div dangerouslySetInnerHTML={{ __html: counter['privacy_policy'] }}></div>
                                    </div>
                                </div>
                            ) : tabValue == "terms" ? (
                                <div className="p-3">
                                    <div className="" style={{ fontFamily: 'poppins' }}>
                                        <div dangerouslySetInnerHTML={{ __html: counter['terms_&_conditions'] }}></div>
                                    </div>
                                </div>
                            ) : tabValue == "feedback-help" ? (
                                <div className="p-3">
                                </div>
                            ) : tabValue == "change-password" ? (
                                <ChangePassword />
                            ) : tabValue == "account-login-activity" ? (
                                <AcountLoginActivity />
                            ) : tabValue == "notification-settings" ? (
                                <Notification />
                            ) : ""}
                        </div>
                    </div>
                    {/* <div className="">
                        <EventHostUi />
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default Setting;