
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
const NewsRoom = () => {
    return (

        <div className='main-div-newsroom'>
            <h4>LATEST NEWS</h4>
            {/* section 1 -------------------------- */}
            <div className="news-section-1 py-4 row">
                <div className="col-6">
                    <div className="newsroom-1">
                        <img className="img-newsroom" src="https://static.weblinkindia.net/images/new-blog-banner.jpg" />

                        <div className="text-block">
                            <p className="news-text1">This 23-year-old Nigerion is creating a digital collection of african stories for
                                children in diffrent languages.</p>
                        </div>
                    </div>
                </div>

                <div className="col-6 newsroom-2">
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <div className="news-card">
                                <img className="news-card-img" src="https://maannews2.acnoo.com/public/uploads/images/newsimages/maannewsimage23122023_112541_business-people-tablet.jpg" />
                                <div className="card-text-block">
                                    <p className="card-news-text1">US inflation decelerating in boost to economy</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="news-card">
                                <img className="news-card-img" src="https://maannews2.acnoo.com/public/uploads/images/newsimages/maannewsimage23122023_112013_1920-by-1080px%20(1)%20(1).jpg" />
                                <div className="card-text-block">
                                    <p className="card-news-text1">Democratic Gov.Andy Beshear will win second term</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="news-card">
                                <img className="news-card-img" src="https://maannews2.acnoo.com/public/uploads/images/newsimages/maannewsimage23122023_111317_d8cb8a5155b01b297c550d.jpg" />
                                <div className="card-text-block">
                                    <p className="card-news-text1">Neymar close to returning to Barcelona</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="news-card">
                                <img className="news-card-img" src="https://maannews2.acnoo.com/public/uploads/images/newsimages/maannewsimage20122023_120431_44-445587_stage-photos-hd.jpg" />
                                <div className="card-text-block">
                                    <p className="card-news-text1">Meghdol set to headline Moitree Concert in Kolkata</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* section 2 -------------------------- */}
            <div className="news-section-2 py-4">
                <h4>TRENDING NEWS</h4>
                <div className="row py-3">
                    <div className="col-md-3">
                        <div className="trending-div">
                            <img className="trd-img" src="https://maannews2.acnoo.com/public/uploads/images/newsimages/maannewsimage11062023_060238_[GetPaidStock.com]-64856357a373c.jpg" />
                            <div className='trd-text-1 py-2'>
                                <p className="m-0">LIFESTYLE</p>
                                <p className="m-0">2 MIN TO READ</p>
                            </div>
                            <p className="trd-text-2 p-0 m-0">Look Of The Week: Blackpink Headline Coachella In Korean Hanboks</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="trending-div">
                            <img className="trd-img" src="https://maannews2.acnoo.com/public/uploads/images/newsimages/maannewsimage23102021_115552_Chinese-Fan-Dance.jpg" />
                            <div className='trd-text-1  py-2 '>
                                <p className="m-0">ENTERTAINMENT</p>
                                <p className="m-0">2 MIN TO READ</p>
                            </div>
                            <p className="trd-text-2 p-0 m-0">Reating A Vision For The Future: How Technology Will Revolutionise The Business Finance Function</p>
                        </div>
                    </div>

                    <div className=" col-md-3">
                        <div className="trending-div">
                            <img className="trd-img" src="https://maannews2.acnoo.com/public/uploads/images/newsimages/maannewsimage21092021_100910_national2.jpg" />
                            <div className='trd-text-1 py-2'>
                                <p className="m-0">NATIONAl</p>
                                <p className="m-0">3 MIN TO READ</p>
                            </div>
                            <p className="trd-text-2 p-0 m-0">The Seven-Member Group Filmed A Music Video In The World Body’s New York Headquarters
                            </p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="trending-div">
                            <img className="trd-img" src="https://maannews2.acnoo.com/public/uploads/images/newsimages/maannewsimage23102021_065512_slider-1.jpg" />
                            <div className='trd-text-1 py-2 '>
                                <p className="m-0">NATIONAl</p>
                                <p className="m-0">2 MIN TO READ</p>
                            </div>
                            <p className="trd-text-2 p-0 m-0">Serena Williams On Her Star-Studded Gucci Bodysuit</p>
                        </div>
                    </div>
                </div>    
            </div>
            {/* section 3 -------------------------- */}
            <div className="news-section-3 py-4">
                <div className="d-flex justify-content-between">
                    <h4>WEEKLY REVIEW BUSINESS</h4>
                    <p>View All </p>
                </div>
                <Swiper
                    className=""
                    modules={[Pagination, Scrollbar, A11y, Autoplay]}
                    spaceBetween={200}
                    slidesPerView={4}
                    autoplay={true}
                    loop={true}
                    pagination={{
                        clickable: true,
                    }}
                >
                    {[...Array(8)]?.map((_, index) => (
                        <SwiperSlide key={index}>
                            <div className="py-3 mb-5">
                                <div className="">
                                    <div className="weekly-review">
                                        <img className="weekly-reviews-img" src="https://maannews2.acnoo.com/public/uploads/images/newsimages/maannewsimage11062023_060238_[GetPaidStock.com]-64856357a373c.jpg" />
                                        <div className="review-text-main px-2 py-2">
                                            <div className='review-text-1 py-2'>
                                                <p className="m-0">LIFESTYLE</p>
                                                <p className="m-0">2 MIN TO READ</p>
                                            </div>
                                            <p className="review-text-2 p-0 m-0">Look Of The Week: Blackpink Headline Coachella In Korean Hanboks</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>

                    ))}

                </Swiper>
            </div>
            {/* section 4 ------------------------- */}
            <div className="news-section-1 py-4 row">

                <div className="col-6 newsroom-2">
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <div className="news-card">
                                <img className="news-card-img" src="https://maannews2.acnoo.com/public/uploads/images/newsimages/maannewsimage23122023_112541_business-people-tablet.jpg" />
                                <div className="card-text-block">
                                    <p className="card-news-text1">US inflation decelerating in boost to economy</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="news-card">
                                <img className="news-card-img" src="https://maannews2.acnoo.com/public/uploads/images/newsimages/maannewsimage23122023_112013_1920-by-1080px%20(1)%20(1).jpg" />
                                <div className="card-text-block">
                                    <p className="card-news-text1">Democratic Gov.Andy Beshear will win second term</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="news-card">
                                <img className="news-card-img" src="https://maannews2.acnoo.com/public/uploads/images/newsimages/maannewsimage23122023_111317_d8cb8a5155b01b297c550d.jpg" />
                                <div className="card-text-block">
                                    <p className="card-news-text1">Neymar close to returning to Barcelona</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="news-card">
                                <img className="news-card-img" src="https://maannews2.acnoo.com/public/uploads/images/newsimages/maannewsimage20122023_120431_44-445587_stage-photos-hd.jpg" />
                                <div className="card-text-block">
                                    <p className="card-news-text1">Meghdol set to headline Moitree Concert in Kolkata</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="newsroom-1">
                        <img className="img-newsroom" src="https://static.weblinkindia.net/images/new-blog-banner.jpg" />

                        <div className="text-block">
                            <p className="news-text1">This 23-year-old Nigerion is creating a digital collection of african stories for
                                children in diffrent languages.</p>
                        </div>
                    </div>
                </div>

            </div>

        </div >
    )
}
export default NewsRoom;








