import React, { useState, } from "react";
import "react-quill/dist/quill.snow.css";
import imageicon from "../../../assets/images/image-upload-icon.svg";
import { useSelector } from "react-redux";
import { MdDelete } from "react-icons/md";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { toast } from "react-toastify";
import pdfimg from "../../../assets/images/pdf.png";
import Documenticon from "../../../assets/images/document-upload-icon.svg";
import { Event_Schedule_ImageDelete } from "../../../utils/CreateEvents/createEvent";

const UploadEventSchedule=({images, setImages,
  documents, setDocuments,
  showPdf, setShowPdf,
  showImages, setShowImages,
  baseUrls,
  GetEventSchedule,
  errors
})=>{

    const handleFileChangeImage = (event) => {
        const files = event.target.files;
        const imageArray = [];   
        if (images.length + files.length > 6) {
          toast.warn("You can only add up to 6 images", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            theme: "light",
          });
          return;
        }
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          imageArray.push(file);
        }
        setImages([...images, ...imageArray]);
    };

      const handleRemoveImage = (idx) => {
        const val = images?.filter((item, index) => index !== idx)
        setImages(val)
      }
 
      const handleFileChangeDocument = (event) => {
        const files = event.target.files;
        const documentArray = [];
    
        if (documents.length + files.length > 3) {
          toast.warn("You can only add up to 3 documents", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            theme: "light",
          });
          return;
        }
    
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          documentArray.push(file);
        }
        setDocuments([...documents, ...documentArray]);
      };
    
      const handleRemoveDocument = (idx) => {
        const val = documents.filter((item, index) => index !== idx)
        setDocuments(val)
      }
    
      const handleDltImageDocs = async (id) => {
        await Event_Schedule_ImageDelete(id).then((response) => {
          if (response?.data?.success == true) {
            toast.success(response?.data?.message, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              theme: "light",
            });
            GetEventSchedule();
          }
        });
      };
 
  return(<>
      <div className="mt-4">
        <div className="row">
          <h4 className="form-heading">Images
            {/* <span className="asterisk">*</span> */}
            {/* <IoMdInformationCircleOutline size={16} color="black" title="Add additional images to showcase different aspects of your event." /> */}
            </h4>
          <div className="d-flex flex-column align-items-center col-md-12">
            <label>
              <img className="upload-icon" src={imageicon} alt="img-upload" />
              <input
                className="d-none"
                type="file"
                accept="image/*"
                multiple
                onChange={handleFileChangeImage}
              />
            </label>
            <div className="">Choose Images</div>
            {errors?.images && (
                <span className="error">{errors?.images}</span>
            )}
            {/* <div className="pt-3">
                <button className="btn Addlink-btn ms-4">Add Images</button>
              </div> */}
          </div>
          <div className="row mt-3">
            {images?.map((file, index) => (
              <div key={index} className="col-2 position-relative py-1 px-1">
                <button className="image-remove-icon" onClick={() => handleRemoveImage(index)} type="button">
                  <MdDelete size={20} color="white" />
                </button>
                {file instanceof File &&
                  <img
                    src={URL?.createObjectURL(file)}
                    alt={`selected-${index}`}
                    className="event-gellary-img"
                  />
                }
              </div>
            ))}
            {showImages?.map((val, index) => {
              return (
                <>
                  <div
                    className="col-2 position-relative px-1 py-1"
                    key={index}>
                    <button
                      className="image-remove-icon"
                      type="button"
                      onClick={() => handleDltImageDocs(val?.id)}>
                      <MdDelete size={20} color="white" />
                    </button>
                    <img
                      className="event-gellary-img"
                      src={`${baseUrls?.event_schedule_document_url}/${val?.file}`}
                      alt="img1"
                    />
                  </div>
                </>
              );
            })}

          </div>
          {/* {errors.imagess && <span className="error">{errors.imagess}</span>} */}
        </div>
      </div>


      <div className="">
        <div className="row mt-4">
          <h4 className="form-heading">Documents
            {/* <span className="asterisk">*</span> */}
            {/* <IoMdInformationCircleOutline size={16} color="black" title="Upload any documents related to the event." /> */}
          </h4>
          <div className="d-flex flex-column align-items-center col-md-12">
            <label className="">
              <img
                className="upload-icon"
                src={Documenticon}
                alt="img-upload"
              />
              <input
                className="d-none"
                type="file"
                accept=".pdf,.doc,.docx,.xls,.xlsx,.txt"
                multiple
                onChange={handleFileChangeDocument}
              />
            </label>
            <div className="">Choose Documents</div>
            {errors?.documents && (
              <span className="error">{errors?.documents}</span>
            )}            
            {/* <div className="pt-3">
                <button className="btn Addlink-btn ms-4">Add Documents</button>
              </div> */}
          </div>
          <div className="row mt-3 mb-3">
            {documents?.map((file, index) => (
              <div className="px-1 py-1 col-2 position-relative" key={index}>
                <button className="image-remove-icon" onClick={()=>handleRemoveDocument(index)} type="button">
                  <MdDelete size={20} color="white" />
                </button>
                <div className="pdf-div">
                  <img src={pdfimg} alt="pdf" />
                  <p className="pdf-title">{file?.name}</p>
                </div>
              </div>
            ))}
           
            { showPdf?.map((val, index) => {
               return (
                <div
                  className="px-1 position-relative py-1 col-2" key={index}>
                  <button className="image-remove-icon" type="button"
                    onClick={()=>handleDltImageDocs(val?.id)}>
                    <MdDelete size={20} color="white" />
                  </button>
                  <div className="pdf-div">
                    <img src={pdfimg} alt="pdf" />
                    <p className="pdf-title">{val?.file}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
 </>)
};

export default UploadEventSchedule;