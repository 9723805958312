import { GetMembershipPlans, MembershipPlan, MembershipTransactions, UpdateMembershipPayment } from "../../Services/Apis"


export const Get_MembershipPlans=async(type)=>{
    try{
       const response = await GetMembershipPlans(type).then((data) => data);
          return response
    }catch(error){
        throw error
    }
}

export const Membership_Plan=async(fData)=>{
    try{
       const response = await MembershipPlan(fData).then((data) => data);
          return response
    }catch(error){
        throw error
    }
}


export const Update_MembershipPayment=async(fData)=>{
    try{
       const response = await UpdateMembershipPayment(fData).then((data) => data);
          return response
    }catch(error){
        throw error
    }
}

export const Membership_Transactions=async(fData)=>{
    try{
       const response = await MembershipTransactions(fData).then((data) => data);
          return response
    }catch(error){
        throw error
    }
}