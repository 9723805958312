import React, { useState, useEffect } from 'react';
import AppRouter from './Routes';
import 'animate.css';
import WOW from 'wowjs';
// import Preloader from './components/Shared/Preloader';
import './assets/css/bootstrap.min.css';
import './assets/css/icofont.min.css';
import './assets/css/animate.min.css';
// import '../node_modules/react-modal-video/css/modal-video.min.css';
import './assets/css/style.css';
import './assets/css/responsive.css';
import "./assets/css/eventdetail.css";
import "./assets/css/login.css";
import "./assets/css/signup.css";
import "./assets/css/createnewevent.css";
import "./assets/css/yourevent.css";
import "./assets/css/home.css";
import "./assets/css/footer.css";
import "./assets/css/navbar.css";
import "./assets/css/forgotpassword.css";
import "./assets/css/privacy-terms.css";
import "./assets/css/event.css";
import "./assets/css/howitworks.css";
import "./assets/css/profile.css";
import "./assets/css/notification.css";
import "./assets/css/chat.css";
import "./assets/css/managerequest.css";
import "./assets/css/notification.css";
import "./assets/css/chat.css";
import "./assets/css/testimonial.css";
import "./assets/css/EditPackagesFormPopup.css";
import "./assets/css/paynow.css";
import "./assets/css/myorders.css";
import "./assets/css/deleteConfirmation.css";
import "./assets/css/champdashboard.css";
import "./assets/css/sitemap.css";
import "./assets/css/newsroom.css"
import "./assets/css/setting.css"
import "./assets/css/isSponsorPopup.css"
import "./assets/css/scrollButton.css"
import "./assets/css/rejectPopup.css"
import "./assets/css/changePassword.css"
import "./assets/css/bannerBullet.css"
import './assets/css/progressBar.css'
import './assets/css/AttendeeStats.css'
import './assets/css/detailCoupons.css'
import './assets/css/cancellationRefund.css'
import './assets/css/NavbarSidebar.css'
import './assets/css/print.css'
import './assets/css/NewUserSignupPopup.css'

import { useDispatch, useSelector } from 'react-redux';
import { getConfigAllData } from './utils/configData/configallData';
import { configval } from './redux/config';
import { Auth0Provider } from '@auth0/auth0-react';
import 'react-tooltip/dist/react-tooltip.css'
import SessionExpiredMdl from './components/Common/Auth/SessionExpiredMdl';
import { setSessionExpired } from './redux/Auth/AuthCheck';
import { useNavigate } from 'react-router-dom';

const App = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authCheck = useSelector((state) => state?.AuthCheck?.isSessionExpired)
  

  // useEffect(() => {
  //     const wow = new WOW.WOW();
  //     wow.init();
  // }, []);

  // const demoAsyncCall = () => {
  //     return new Promise((resolve) => setTimeout(() => resolve(), 2000));
  // };

  // useEffect(() => {
  //     const fetchData = async () => {
  //         await demoAsyncCall();
  //         setLoading(false);
  //     };
  //     fetchData();
  // }, []);

  useEffect(() => {
    getConfigAllData()
      .then((result) => {
        dispatch(configval(result?.data))
      })
  }, []);

  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    const handleKeyDown = (e) => {
      if (e.ctrlKey && (e.key === 'c' || e.key === 'C') || e.metaKey && (e.key === 'c' || e.key === 'C')) {
        e.preventDefault();
      }
    };

    document.addEventListener('contextmenu', handleContextMenu);
    document.addEventListener('keydown', handleKeyDown);

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleClose = () => {
    dispatch(setSessionExpired(false));
    navigate('/');
    window.location.reload();
   
  }

  return (
    <>
      {authCheck && <SessionExpiredMdl handleClose={handleClose} />}
      <Auth0Provider
        // domain="dev-se7r0jhkkkwnm06a.us.auth0.com"
        // clientId="23lLk8uD98LegTsSTwuiSI2ERbw5xwNM"
        domain="dev-tklj3n5b4n1bpxgn.us.auth0.com"
        clientId="HSkPngq3SjkqJGQqqyRdy2uO2MM4tJy7"
        authorizationParams={{
          redirect_uri: window.location.origin
        }} 
      >
        <AppRouter />
      </Auth0Provider>
    </>
  );
};

export default App;
