import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Get_Coupons } from "../../utils/couponsmanage/GetCoupon";
import Loader2 from "../../components/Loader/Loader2";
import { Apply_Coupon } from "../../utils/couponsmanage/ApplyCoupon";
import { Event_Transaction } from "../../utils/payment/EventTransaction";
import { GetApplyCoupon } from "../../utils/couponsmanage/GetApplyCoupon";
import { UpdatePayment } from "../../utils/payment/PaymentNow";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { isTokenValid } from "../../AuthbyToken";
import GotItModal from "../../components/EventDetail/GotItModal";
import CopyToClipboard from "react-copy-to-clipboard";
import { TiArrowLeft } from "react-icons/ti";
import PackageInclusionModal from "../../components/Common/EventDetail/PackageInclusionModal";

const PayNow = () => {
  const [loading, setLoading] = useState(true);
  const [coupons, setCoupons] = useState([]);
  const [applyCouponData, setApplyCouponData] = useState({});
  const [couponCode, setCouponCode] = useState("");
  const [discountPrice, setDiscountPrice] = useState("");
  const [loadingApplyBtn, setloadingApplyBtn] = useState(false);
  const [loadinginputBtn, setLoadingInputBtn] = useState(false);
  const [inputErrorMsg, setInputErrorMsg] = useState("");
  const { state } = useLocation();
  const { data } = state || {};
  const [subTotalPrice, setSubTotalPrice] = useState(data?.amount);
  const [coupounId, setCouponId] = useState('');
  const [gotitModal, setItModal] = useState(false);
  // console.log("ddd",data);
  const [payNowBtnLoading, setPayNowBtnLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [currentOffer, setCurrentOffer] = useState(null);
  const [daysLeft, setDaysLeft] = useState(null);
  const [showMore, setShowMore] = useState(false);

  const profileinfo = useSelector((data) => data?.Profile_data?.ProfileData);
  const isValid = isTokenValid();
  const navigate = useNavigate();
  const currency = useSelector((val) => val?.CurrencyConvert);

  useEffect(() => {
    const updateOfferPrice = () => {
        // const today = new Date();
        // const lastMinuteStart = new Date(val?.last_minute_offer_start_date);
        // const earlyBirdEnd = new Date(val?.early_bird_offer_end_date);
        const today = new Date();
        const earlyBirdEnd = new Date(data?.early_bird_offer_end_date);
        const earlyBirdStart = today; // Assuming it starts today
        const lastMinuteStart = new Date(data?.last_minute_offer_start_date);
        const lastMinuteEnd = lastMinuteStart;

        if (data?.early_bird_offer && today <= earlyBirdEnd) {
          setCurrentOffer({
            amount: data?.early_bird_offer_amount,
            type: 'Early Bird Offer',
            startDate: earlyBirdStart,
            endDate: earlyBirdEnd,
          });
          setSubTotalPrice(data?.early_bird_offer_amount)
          const timeDiff = earlyBirdEnd - today;
          const daysRemaining = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
          setDaysLeft(daysRemaining);
        } else if (data?.last_minute_offer && today >= lastMinuteStart) {
          setCurrentOffer({
            amount: data?.last_minute_offer_amount,
            type: 'Last Minute Offer',
            startDate: lastMinuteStart,
            endDate: lastMinuteEnd,
          });
          setSubTotalPrice(data?.last_minute_offer_amount)
          setDaysLeft(null);
        } else {
          setCurrentOffer(null);
          setDaysLeft(null);
          setSubTotalPrice(data?.amount)
        }
     };
    updateOfferPrice();
    const intervalId = setInterval(updateOfferPrice, 24 * 60 * 60 * 1000); // Check daily
    return () => clearInterval(intervalId); // Clean up interval on unmount
}, [data?.early_bird_offer, data?.early_bird_offer_end_date, data?.early_bird_offer_amount, data?.last_minute_offer, 
    data?.last_minute_offer_amount, data?.last_minute_offer_start_date]);


  // useEffect(() => {
  //   const checkOffers = () => {
  //     const now = new Date();
  //     const earlyBirdStart = new Date(`${data?.early_bird_offer_start_date}T${data?.early_bird_offer_start_time}`);
  //     const earlyBirdEnd = new Date(`${data?.early_bird_offer_end_date}T${data?.early_bird_offer_end_time}`);
  //     const lastMinuteStart = new Date(`${data?.last_minute_offer_start_date}T${data?.last_minute_offer_start_time}`);
  //     const lastMinuteEnd = new Date(`${data?.last_minute_offer_end_date}T${data?.last_minute_offer_end_time}`);

  //     if (now >= earlyBirdStart && now < earlyBirdEnd) {
  //       setCurrentOffer({
  //         amount: data?.early_bird_offer_amount,
  //         type: 'Early Bird Offer',
  //         startDate: earlyBirdStart,
  //         endDate: earlyBirdEnd,
  //       });
  //     } else if (now >= lastMinuteStart && now < lastMinuteEnd) {
  //       setCurrentOffer({
  //         amount: data?.last_minute_offer_amount,
  //         type: 'Last Minute Offer',
  //         startDate: lastMinuteStart,
  //         endDate: lastMinuteEnd,
  //       });
  //     } else {
  //       setCurrentOffer(null);
  //     }
  //   };
  //   checkOffers();
  //   // const interval = setInterval(checkOffers, 1000); // Update every second
  //   // return () => clearInterval(interval); // Cleanup interval on component unmount
  // }, [data]);


  const GetCoupon = async () => {
    setLoading(true);
    try {
      const res = await Get_Coupons(data?.id);
      const resData = res?.data?.data;
      setCoupons(resData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    GetCoupon();
  }, []);

  const handleApplyInputCoupon = async () => {
    setLoadingInputBtn(true);
    const applyCouponFD = {
      bill_amount: subTotalPrice,
      coupon_name: couponCode,
    };
    if (couponCode == "") {
      setLoadingInputBtn(false);
      setInputErrorMsg("Please enter a coupon code");
    } else {
      setInputErrorMsg("");
      try {
        const apply_response = await Apply_Coupon(applyCouponFD);
        // console.log("apply", apply_response);
        setApplyCouponData(apply_response?.data?.data);
        setSubTotalPrice(apply_response?.data?.data?.bill_amount);
        setDiscountPrice(subTotalPrice - apply_response?.data?.data?.bill_amount);
        setCouponCode("");
      } catch (error) {
        toast?.error(error?.response?.data?.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
        });
        console.log("cError", error);
        setCouponCode("");
        setSubTotalPrice(subTotalPrice);
      } finally {
        setLoadingInputBtn(false);
      }
    }
  };

  const handleApplyCoupon = async (id) => {
    setCouponCode("");
    setCouponId(id)
    setloadingApplyBtn(true);
    const applyCouponFD = {
      id: id,
      bill_amount: subTotalPrice,
      coupon_name: '',
    };
    try {
      const response = await Apply_Coupon(applyCouponFD);
      setApplyCouponData(response?.data?.data);
      setSubTotalPrice(response?.data?.data?.bill_amount);
      setDiscountPrice(subTotalPrice - response?.data?.data?.bill_amount);
    } catch (error) {
      toast?.error(error?.response?.data?.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
      });
      console.log("cError", error);
      setCouponCode("");
      setSubTotalPrice(subTotalPrice);
    } finally {
      setloadingApplyBtn(false);
    }
  };

  const handlePayment = async (id, amt) => {
    const isObjectEmpty = Object.keys(applyCouponData).length == 0;
    const createOrderIdFD = {
      package_id: id,
      event_id: data?.event_id,
      coupon_id: isObjectEmpty ? '' : applyCouponData?.coupon_id
    };
    try {
      setPayNowBtnLoading(true);
      const response = await Event_Transaction(createOrderIdFD);
      if (response?.status == 200) {
        const orderid = response?.data?.order_id;
        const options = {
          key:"rzp_test_IcgTNsIE5vV4DM",
          key_secret:"yHf8cImq5DtzflsmjgsuJApS",
          //amount:2*100,
          //currency:"INR",
          order_id: orderid,
          handler: async (response) => {
            // console.log("ressss",response)
            if (response.razorpay_order_id && response?.razorpay_payment_id) {
              const payres = {
                razorpay_order_id: response?.razorpay_order_id,
                razorpay_payment_id: response?.razorpay_payment_id,
                razorpay_signature: response?.razorpay_signature,
              };
              const update_pay_FD = {
                payment_id: response?.razorpay_payment_id,
                order_id: orderid,
                payment_response: JSON.stringify(payres),
                status: "success",
              };
              try {
                setPayNowBtnLoading(true)
                const UpdatePay = await UpdatePayment(update_pay_FD);
                toast?.success(UpdatePay?.data?.message, {
                  position: "top-center",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                });
                navigate('/myorders')
                setApplyCouponData({});
                setSubTotalPrice(subTotalPrice);
                setDiscountPrice(0);
                // console.log("paaayyy",UpdatePay);
              } catch (error) {
                console.log(error);
                navigate('/myorders');
                setApplyCouponData({});
                setSubTotalPrice(subTotalPrice);
                setDiscountPrice(0);
              }
              finally {
                setApplyCouponData({});
                setSubTotalPrice(subTotalPrice);
                setDiscountPrice(0);
                setPayNowBtnLoading(false)
              }
            }
          },
          modal: {
            ondismiss: async () => {
              const payres = {
                razorpay_order_id: response?.razorpay_order_id,
                razorpay_payment_id: response?.razorpay_payment_id,
                razorpay_signature: response?.razorpay_signature,
              };
              const update_pay_FD = {
                payment_id: response?.razorpay_payment_id,
                order_id: orderid,
                payment_response: JSON.stringify(payres),
                status: "failed",
              };
              try {
                const UpdatePay = await UpdatePayment(update_pay_FD);
                setApplyCouponData({});
                setSubTotalPrice(subTotalPrice);
                setDiscountPrice(0);
                // console.log("paaayyy", UpdatePay);
              } catch (error) {
                console.log(error);
              }
              // finally{
              //   setPayNowBtnLoading(false)
              // }
            },
          },
        };
        const razorpayInstance = new window.Razorpay(options);
        razorpayInstance.open();
      }
    } catch (error) {
      toast?.error(error?.response?.data?.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
      });
    }
    finally {
      setPayNowBtnLoading(false)
    }
  };

  const get_Quirymdl = () => {
    if (isValid) {
      if (profileinfo?.i_want_to == 'is_sponsor') {
        // getitfunc(val);       
        setItModal(true);
      }
    } else {
      toast.warn("Please login first", {
        position: "top-center",
        autoClose: 2000,
      });
    }
  };


  return (
    <>
      {
        showMore && <PackageInclusionModal val={data} setShowMore={setShowMore}/>
      }

      <GotItModal
        currency={currency}
        gotitModal={gotitModal}
        gotItValue={data}
        setItModal={setItModal}
        //event={""}
       />
      <ToastContainer />
      <div className="container paynow-div pb-5">
        <div className="d-flex justify-content-between align-items-center mt-3">
          <h2 className="">Pay Now</h2>
          
          <div className="d-flex gap-3">
          <button className="go-back-btn" onClick={() => navigate(-1)} type="button">
              <TiArrowLeft size={20}/> Go Back
          </button>
          <button type="submit" className="btn Create-new-Event" onClick={get_Quirymdl}>
             Query
          </button>
          </div>
        </div>
        <div className="row mt-5">

          <div className="coupons-div col-12 col-lg-7 col-xl-8">
            <div className="">
              <h4 className="coupon-head-text">Coupons</h4>
              <div className="d-flex gap-2 mt-3 col-md-10">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Coupon Code.."
                  name="couponCode"
                  value={couponCode}
                  onChange={(e) => setCouponCode(e.target.value)}
                />
                <div>
                  <button
                    className="apply-coupon-btn"
                    onClick={handleApplyInputCoupon}>
                    {loadinginputBtn ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        loading
                      </>
                    ) : (
                      "Apply"
                    )}
                  </button>
                </div>
              </div>
              <p className="error-text">{inputErrorMsg}</p>
              <div className="col-md-10">
                {loading ? (
                  <Loader2 />
                ) : (
                  <>
                    { 
                    coupons?.length <= 0 ? 
                      <h2 className="no-data-msg">No Coupon Found</h2> :
                     coupons?.map((val) => {
                      return (
                        <>
                          <div className="coupon-card-div d-flex justify-content-between align-items-center gap-2 mt-4 ">
                            <div className="">
                              <div>
                                <h4 className="text-capitalize coupon-name-text">{val?.coupon_name}</h4>
                              </div>
                              <div>
                
                                <p className="coupon-disc-head-text"  dangerouslySetInnerHTML={{ __html:val?.coupon_details}}>
                                  </p>
                              </div>
                              <div className="coupon-code-div mt-1">
                                <p className="coupon-code-text">
                                  {val?.coupon_code}
                                </p>
                              </div>
                            </div>
                            <div>
                         {/* <CopyToClipboard
                          text={val?.coupon_code}
                          onCopy={() => setCopied(true)} >
                          <button
                            className="copy-code-btn">
                            {copied ? "COPIED":"TAP TO COPY CODE"}
                          </button>
                         </CopyToClipboard> */}
                            </div>
                            <div>
                              {applyCouponData?.coupon_id == val?.id ? (
                                <button className="applied-coupon-btn">
                                  Applied
                                </button>
                              ) : (
                                <button
                                  className="d-flex gap-2 align-items-center apply-coupon-btn"
                                  onClick={()=> handleApplyCoupon(val?.id, val?.coupon_name)}>
                                  {loadingApplyBtn && coupounId == val?.id ? (
                                    <>
                                      <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                      loading
                                    </>
                                  ) : (
                                    "Apply"
                                  )}
                                </button>
                              )}
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="col-12 mt-3 mt-lg-0 col-lg-5 col-xl-4">
            <div className="price-detail-div">
              <h4 className="coupon-head-text">Price Details</h4>
               <hr />
              <div className="mt-3">
            <div className="d-flex justify-content-between py-1">
              <div>
                <p className="price-coupon-text ">Package Name</p>
               </div>
              <div>
               <p className="coupon-text">{data?.title}</p>
             </div>
          </div>
          
          <div className="d-flex justify-content-between py-1">
            <p className="pack-content-text m-0">Available Packages:</p>
            <div className="d-flex gap-1">
             <p className="pack-bold-text">{data?.available_package}</p>
              {data?.total_available_packages ?
              <p className="pack-bold-text">/ {data?.total_available_packages}</p> :""}
            </div>
          </div>
           {data?.number_of_guests ? (
            <div className="d-flex justify-content-between py-1">
              <p className="pack-content-text">No of Guest:</p>
              <p className="pack-bold-text">{data?.number_of_guests}</p>
            </div>
          ) : (
            ""
          )}

          <div className="mtm-paragraph-height py-1">
             <div className="mtm-paragraph-home" dangerouslySetInnerHTML={{__html:`${data?.package_inclusions?.substring(0, 100)}
               ${data?.package_inclusions?.length > 100 ? '...' : ''}`}}
            ></div>
            {!showMore && data?.package_inclusions?.length > 100 && (
             <button onClick={() => setShowMore(true)} className="btn viewall-btn-p p-0 m-0">See More</button> )}
            {/* {showMore && <button onClick={() => setShowMore(false)} className="btn viewall-btn-p p-0 m-0">See Less</button>} */}
          </div>

          <div className="d-flex justify-content-between py-1"> 
            <p className="price-coupon-text">Amount</p>
          <div className="d-flex gap-1">
            
           {
             currentOffer &&
              <div>
                <p className={currentOffer?.type == "Last Minute Offer" ? "pack-offerprice-text2" : "pack-offerprice-text "}>
                  {currency?.currencySymbol
                    ? currency?.currencySymbol
                    : data?.currencies?.symbol}
                  {currency?.selectedCurrencyPrice
                    ? Math.ceil(currentOffer?.amount * currency?.selectedCurrencyPrice)
                    : Math.ceil(currentOffer?.amount)}
                </p>
              </div>
            }

            {daysLeft !== null && (
              <p className="daysleft-p">({daysLeft} Day{daysLeft > 1 ? 's' : ''} Left)</p>
            )}

            <div className=" d-flex gap-2">
              <p className={currentOffer ? "pack-strike-price-text d-flex" : "d-flex pack-price-text"}>
                {currency?.currencySymbol
                  ? currency?.currencySymbol
                  : data?.currencies?.symbol}
                {currency?.selectedCurrencyPrice
                  ? Math.ceil(data?.amount * currency?.selectedCurrencyPrice)
                  : Math.ceil(data?.amount)}
              </p>
            </div>
            </div>
                   {/* <p className="coupon-price-text">
                    {currency?.currencySymbol ? currency?.currencySymbol : data?.currencies?.symbol}
                    {currency?.selectedCurrencyPrice ? Math.ceil(data?.amount * currency?.selectedCurrencyPrice): Math.ceil(data?.amount)}
                  </p> */}
                </div>

                <div className="d-flex justify-content-between py-1">
                  <p className="price-coupon-text">Discount</p>
                  {/* coupon-disc-text */}
                  <p className="pack-offerprice-text d-flex align-items-center gap-1">
                    {data?.currencies?.symbol} {discountPrice == "" ? <p className="coupon-text">0.00</p> : Math.ceil(discountPrice)}
                  </p>
                </div>

                <hr />
                <div className="d-flex justify-content-between mt-2">
                  <p className="total-price-text">Sub Total</p>
                  <p className="total-price-text">
                    {currency?.currencySymbol ? currency?.currencySymbol : data?.currencies?.symbol}
                    {currency?.selectedCurrencyPrice ? Math.ceil(subTotalPrice * currency?.selectedCurrencyPrice) : Math.ceil(subTotalPrice)}
                    {/* {data?.currencies?.symbol} {subTotalPrice} */}
                  </p>
                </div>

                <div className="d-flex justify-content-center pt-4">
                  <button
                    className="pay-now-btn d-flex align-items-center gap-1"
                    onClick={() => handlePayment(data?.id)}>
                    {payNowBtnLoading &&
                      <div
                        className="spinner-border text-light "
                        role="status">
                        <span className="sr-only">Loading...</span>
                      </div>}
                    Pay Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PayNow;
