import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import facebook from "../../assets/images/facebook-icon.png";
import linkedin from "../../assets/images/linkedin-icon.png";
import google from "../../assets/images/google-icon.png";
import logo from "../../assets/images/sponsor logo - dark.png";
import instagram from "../../assets/images/icons8-instagram-48.png";
import twitter from "../../assets/images/icons8-twitter-50 (1).png";
import { useAuth0 } from "@auth0/auth0-react";
import { ToastContainer } from "react-toastify";
import LoginWithEmail from "../../components/LoginForms/LoginWithEmail";
import LoginWithMobile from "../../components/LoginForms/LoginWithMobile";
import { LoginSocialGoogle } from 'reactjs-social-login';
import { logActivityCheck, pass_systemIpAddress, systemIpAddress } from "../../utils/user/UserManager";
import {
  isMobile,
  isTablet,
  isDesktop,
  isAndroid,
  isIOS,
} from 'react-device-detect';

const Login = () => {
  const deviceType = {
    type: isMobile ? "Mobile" : isTablet ? "Tablet" : isDesktop ? "Desktop" : "Unknown",
    os: isAndroid ? "Android" : isIOS ? "iOS" : "Other"
  };


  const [loginOption, setLoginOption] = useState("email");
  const [informationIp, setInformationIp] = useState({});
  const { isAuthenticated, loginWithRedirect, getAccessTokenSilently, isLoading } = useAuth0();

  const SystemIp = async () => {
    try {
      await systemIpAddress()
        .then((response) => {
          passIpAddress(response?.data?.ip)
        })
    } catch (err) {
      console.log(err)
    }
  }

  const passIpAddress = async (ipv4) => {
    try {
      await pass_systemIpAddress(ipv4)
        .then((response) => {
          setInformationIp(response?.data)
        })
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    SystemIp();
  }, [])

  const loggedActivity = async (informationIp) => {

    const data = {
      ip_address: informationIp?.ip,
      lat: informationIp?.latitude,
      long: informationIp?.longitude,
      log_type: 'socialLogin',
      device_type: JSON.stringify(deviceType),
      city: informationIp?.city,
      country_code: informationIp?.country_code,
      region_code: informationIp?.region_code
    }
    try {
      await logActivityCheck(data)
        .then((response) => {
          console.log("activity", response)
          // console.log(response)
          // if (response?.status == 200) {
          //   localStorage.setItem("LoginToken", loginToken);
          //   navigate("/");
          // }
        })
    } catch (err) {
      console.log(err)
    }
  }

  const handleGoogleLogin = useCallback(() => {
    loginWithRedirect();
  }, [loginWithRedirect]);

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      loggedActivity(informationIp);
    }
  }, [isAuthenticated, isLoading, informationIp]);


  return (
    <>
      <section className="login-area">
        <div className="login-signup-header">
          <Link to="/">
            <div className="">
              <img src={logo} className='login-signup-logo' alt="logo" />
            </div>
          </Link>
        </div>

        <div className="d-table1">
        <ToastContainer/>
          <div className="container-fluid">
            <div className="login-form mt-xxl-5">
              <div className="d-sm-flex justify-content-between align-items-center">
                <h3 className="m-0">Sign In</h3>
                <div>
                  <p className="Dont-have-an-accoun">
                    Don’t have an account?
                    <Link to={"/signup"}>
                      <span className="text-style-1 ps-1"> Sign Up</span>
                    </Link>
                  </p>
                </div>
              </div>

              <div className="row mt-4">
                {/* <div className="mt-3 mt-sm-0 col-sm-6 col-md-5 order-2 order-sm-1">
                  <hr className="d-sm-none" />

                  <p className="login-p">Sign In With Social Account</p>
                  <div className=" d-flex flex-column align-items-md-center">
                    <div className="d-flex flex-column gap-4 mt-4">
                      <button className="login-with-google" onClick={handleGoogleLogin} target="_blank">
                        <img className="login-btn-icon" src={google} alt="linkedin" />
                        Social Authentication
                      </button>
                    </div>
                  </div>
                </div> */}

                {/* <div className="position-relative d-none d-md-block order-md-2 col-md-1">
                  <div className="center-line ms-3"></div>
                  <div className="or-div">
                    <span className="or-text">OR</span>
                  </div>
                </div> */}

                <div className="col-12">
                  <div className="d-flex gap-4">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input
                          className="form-check-input"
                          type="radio"
                          value="email"
                          checked={loginOption === "email"}
                          name="loginOption"
                          onChange={(e) => setLoginOption(e?.target?.value)}
                        />
                        Email</label>
                    </div>
                    <div className="form-check">
                      <label className="form-check-label">
                        <input
                          className="form-check-input"
                          type="radio"
                          value="mobile"
                          checked={loginOption === "mobile"}
                          name="loginOption"
                          onChange={(e) => setLoginOption(e?.target?.value)}
                        />
                        Mobile</label>
                    </div>
                  </div>

                  {loginOption == "email" ? (
                    <LoginWithEmail informationIp={informationIp} deviceType={deviceType} />
                  ) : (
                    <LoginWithMobile />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
