import { BiSolidDonateHeart } from "react-icons/bi";
import downarrrow from "../../assets/images/ic-downarrow.png";
import uparrow from "../../assets/images/ic-uparrow.png";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { isTokenValid } from "../../AuthbyToken";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditPackagesModelForm from "../EditEventForm/EditPackagesModelForm";
import moment from "moment";
import DateFormat from "../Common/DateFormat";
import IsHostSponsorPopup from "../Common/IsHostSponsorPopup";
import LiveSamplePopup from "../Common/LiveSamplePopup";
import PackageInclusionModal from "../Common/EventDetail/PackageInclusionModal";
import GotItModal from "./GotItModal";

const replacePTagsWithSpaces = (html) => {
  // Replace <p> and </p> tags with spaces
  return html.replace(/<\/?p>/g, ' ').replace(/\s+/g, ' ').trim();
};

const PackageCard = ({
  val,
  isEventExpired,
  getitfunc,
  userId,
  // setItModal,
  currency,
  profileinfo,
  event
}) => {
  const [dropdown, setDropdown] = useState(true);
  const [open, setOpen] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [sampleBtn, setSampleBtn] = useState(false)
  const [packages_id, setPackages_id] = useState("");
  const [showMore, setShowMore] = useState(false);
  const navigate = useNavigate();
  const isValid = isTokenValid();

  const [itModal, setItModal] = useState(false)
  const [packageData, setPackageData] = useState({});
  const earlyBirdEnd = new Date(`${val?.early_bird_offer_end_date}T${val?.early_bird_offer_end_time}`);
  const lastMinuteStart = new Date(`${val?.last_minute_offer_end_date}T${val?.last_minute_offer_end_time}`);
  const now = new Date();

  const cleanedText = replacePTagsWithSpaces(val?.package_inclusions || '');

  // const getOfferAmount = () => {
  //     if (now < earlyBirdEnd) {
  //       return val?.early_bird_offer_amount;
  //     } else {
  //       return val?.last_minute_offer_amount;
  //     }
  // };
  const [currentOffer, setCurrentOffer] = useState(null);
  const [daysLeft, setDaysLeft] = useState(null);

  useEffect(() => {
    const updateOfferPrice = () => {
      // const today = new Date();
      // const lastMinuteStart = new Date(val?.last_minute_offer_start_date);
      // const earlyBirdEnd = new Date(val?.early_bird_offer_end_date);
      const today = new Date();
      const earlyBirdEnd = new Date(val?.early_bird_offer_end_date);
      const earlyBirdStart = today; // Assuming it starts today
      const lastMinuteStart = new Date(val?.last_minute_offer_start_date);
      const lastMinuteEnd = lastMinuteStart;

      if (val?.early_bird_offer && today <= earlyBirdEnd) {
        setCurrentOffer({
          amount: val?.early_bird_offer_amount,
          type: 'Early Bird Offer',
          startDate: earlyBirdStart,
          endDate: earlyBirdEnd,
        });
        const timeDiff = earlyBirdEnd - today;
        const daysRemaining = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
        setDaysLeft(daysRemaining);
      } else if (val?.last_minute_offer && today >= lastMinuteStart) {
        setCurrentOffer({
          amount: val?.last_minute_offer_amount,
          type: 'Last Minute Offer',
          startDate: lastMinuteStart,
          endDate: lastMinuteEnd,
        });
        setDaysLeft(null);
      } else {
        setCurrentOffer(null);
        setDaysLeft(null);
      }
    };
    updateOfferPrice();
    const intervalId = setInterval(updateOfferPrice, 24 * 60 * 60 * 1000); // Check daily
    return () => clearInterval(intervalId); // Clean up interval on unmount
  }, [val?.early_bird_offer, val?.early_bird_offer_end_date, val?.early_bird_offer_amount, val?.last_minute_offer,
  val?.last_minute_offer_amount, val?.last_minute_offer_start_date]);

  //  offer check 
  // useEffect(() => {
  //   const checkOffers = () => {
  //     const now = new Date();
  //     const earlyBirdStart = new Date(`${val?.early_bird_offer_start_date}T${val?.early_bird_offer_start_time}`);
  //     const earlyBirdEnd = new Date(`${val?.early_bird_offer_end_date}T${val?.early_bird_offer_end_time}`);
  //     const lastMinuteStart = new Date(`${val?.last_minute_offer_start_date}T${val?.last_minute_offer_start_time}`);
  //     const lastMinuteEnd = new Date(`${val?.last_minute_offer_end_date}T${val?.last_minute_offer_end_time}`);

  //     if (now >= earlyBirdStart && now < earlyBirdEnd) {
  //       setCurrentOffer({
  //         amount: val?.early_bird_offer_amount,
  //         type: 'Early Bird Offer',
  //         startDate: earlyBirdStart,
  //         endDate: earlyBirdEnd,
  //       });
  //     } else if (now >= lastMinuteStart && now < lastMinuteEnd) {
  //       setCurrentOffer({
  //         amount: val?.last_minute_offer_amount,
  //         type: 'Last Minute Offer',
  //         startDate: lastMinuteStart,
  //         endDate: lastMinuteEnd,
  //       });
  //     } else {
  //       setCurrentOffer(null);
  //     }
  //   };
  //   checkOffers();
  //   // const interval = setInterval(checkOffers, 1000); // Update every second

  //   // return () => clearInterval(interval); // Cleanup interval on component unmount
  // }, [val]);

  const get_it_now = (val) => {
    if (isValid) {
      if (profileinfo?.i_want_to == "is_sponsor") {
        getitfunc(val);
        setItModal(true);
      } else {
        setIsShow(true);
      }
    } else {
      toast.warn("Please login first", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  };

  const handleNavigation = (val) => {
    if (isValid) {
      if (profileinfo?.i_want_to == "is_sponsor") {
        getitfunc(val);
        //setItModal(true);
        navigate('/paynow', { state: { data: val } });
      } else {
        setIsShow(true);
      }
    } else {
      toast.warn("Please login first", {
        position: "top-center",
        autoClose: 3000,
      });
    }
  };

  const handleEditPackage = (id) => {
    setOpen(!open);
    setPackages_id(id);
  };

  const handleLoginFirst = () => {
    toast.warn("Please login first", {
      position: "top-center",
      autoClose: 3000,
    });
  }


  return (
    <>
      {showMore && <PackageInclusionModal val={val} setShowMore={setShowMore} />}
      <ToastContainer />
      <div className={`package-card ${dropdown ? "open" : ""}`}>
        <div
          className="card-drop-btn"
          onClick={() => setDropdown(!dropdown)}>
          <div className="d-flex justify-content-between pe-3 cursor-pointer">
            <div className="d-flex gap-2">
              <BiSolidDonateHeart size={25} color="#f43254" />
              <h5 className="pack-card-heading text-capitalize">
                {val?.title}
              </h5>
            </div>
            <div>
              <img src={dropdown ? uparrow : downarrrow} alt="img" />
            </div>
          </div>
        </div>

        <hr className="w-100 mt-2 " />
        {/* {dropdown ? "h-0" : ""} */}
        <div className="drop-content">
          <div className="d-flex gap-1">
            {/* <p className="col-md-7 pack-content-text">
              Price
            </p> */}

            {/* pack-price-text */}

            {
              currentOffer &&
              <div>
                <p className={currentOffer?.type == "Last Minute Offer" ? "pack-offerprice-text2 " : "pack-offerprice-text "}>
                  {currency?.currencySymbol
                    ? currency?.currencySymbol
                    : val?.currencies?.symbol}
                  {currency?.selectedCurrencyPrice
                    ? Math.ceil(currentOffer?.amount * currency?.selectedCurrencyPrice)
                    : Math.ceil(currentOffer?.amount)}
                </p>
              </div>
            }
            {daysLeft !== null && (
              <p className="daysleft-p">({daysLeft} Day{daysLeft > 1 ? 's' : ''} Left)</p>
            )}

            <div className="d-flex gap-2">
              <p className={currentOffer ? "pack-strike-price-text d-flex " : "d-flex  pack-price-text"}>
                {currency?.currencySymbol
                  ? currency?.currencySymbol
                  : val?.currencies?.symbol}
                {currency?.selectedCurrencyPrice
                  ? Math.ceil(val?.amount * currency?.selectedCurrencyPrice)
                  : Math.ceil(val?.amount)}
              </p>
            </div>
          </div>
          {val?.deadline ? (
            <div className="row">
              <p className="col-md-7 pack-content-text">Last Date</p>
              <p className="col-md-5 pack-bold-text">
                <DateFormat date={val?.deadline} />
              </p>
            </div>
          ) : (
            ""
          )}

          <div className="row">
            <p className="col-md-7 pack-content-text">Available Packages:</p>
            <div className="col-md-5 d-flex gap-2">
              <p className="pack-bold-text">{val?.available_package}</p>
              {val?.total_available_packages ?
                <p className="pack-bold-text">/ {val?.total_available_packages}</p> : ""}
            </div>
          </div>
          {val?.number_of_guests ? (
            <div className="row">
              <p className="col-md-7 pack-content-text">No of Guest:</p>
              <p className="col-md-5 pack-bold-text">{val?.number_of_guests}</p>
            </div>
          ) : (
            ""
          )}

          <div className="package-inclusions-div">
            <div className="package-inclusions-text" dangerouslySetInnerHTML={{
              __html: `${cleanedText?.substring(0, 100)}
                ${cleanedText?.length > 1000000 ? '...' : ''}`
            }}
            ></div>
            {!showMore && cleanedText?.length > 100 && (
              <button onClick={() => setShowMore(true)} className="btn viewall-btn-p p-0 m-0">See More</button>)}
            {/* {showMore && <button onClick={() => setShowMore(false)} className="btn viewall-btn-p p-0 m-0">See Less</button>} */}
          </div>


          <div className="package-inclusions-div-print-mode">
            <div className="package-inclusions-text" dangerouslySetInnerHTML={{
              __html: val?.package_inclusions
            }}
            ></div>
          </div>

          {/* <div className="pack-content-text" dangerouslySetInnerHTML={{ __html:val?.package_inclusions}}>
          </div> */}

          {/* {userId == val?.user_id
            ? !isEventExpired && (
              <button
                className="get-it-btn"
                onClick={() => handleEditPackage(val?.id)}>Edit</button>)
            : null} */}

          {open && (
            <EditPackagesModelForm
              setOpen={setOpen}
              packages_id={packages_id}
            />
          )}

          {
            userId == val?.user_id ? (
              <button className="past-event-btn" disabled>
                You are a host
              </button>
            ) : isEventExpired ? (
              <button className="past-event-btn" disabled>
                This Is Past Event
              </button>
            ) :
              (<>
                {isValid ?
                  <button className="get-it-btn" onClick={() => { setItModal(true); setPackageData(val) }}>Enquire Now</button>
                  :
                  <button className="get-it-btn" onClick={handleLoginFirst}>Enquire Now</button>
                }
                {/* {event?.status == "Live" ?
                  <button className="get-it-btn" onClick={() => handleNavigation(val)}>
                    Get It Now
                  </button> :
                  event?.status == "Sample" ?
                    <button className="get-it-btn" onClick={() => setSampleBtn(true)}>Get It Now</button>
                    :
                    <button onClick={() => setIsShow(true)} className="get-it-btn">Get It Now</button>
                } */}
              </>
              )}
        </div>
      </div>

      <GotItModal gotitModal={itModal} gotItValue={packageData} setItModal={setItModal} event={event} />

      <div>
        <IsHostSponsorPopup
          type={"sponsor"}
          setIsShow={setIsShow}
          isShow={isShow} />
        <LiveSamplePopup sampleBtn={sampleBtn} setSampleBtn={setSampleBtn} />
      </div>
    </>
  );
};
export default PackageCard;
