import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    editData: {},
}


export const editEventSlice = createSlice({
    name: 'configValues',
    initialState: initialState,
    reducers: {
        setEditdata: (state, action) => {
            state.editData = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { setEditdata } = editEventSlice.actions;

export default editEventSlice.reducer;