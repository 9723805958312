import { DeletePartnershipPartner, Delete_Banner, EventessentialsEdit, GetEditEvent, GetEditEventCancelRefund, GetEditEventGuest, GetEditEventHost, GetEditEventMediaHub, GetEditEventPartnerShip, GetEditEventPitch, GetEditEventReference, GetEditEventSocialHub, GetEditEventSponsorPackage, UpdatePartnerData, getPartnershipPartner } from "../../Services/Apis"

//get eventessentials
export const Get_Edit_Event = async (id) => {
    try {
        return await GetEditEvent(id).then((data) => data)
    }
    catch (error) {
        console.log(error)
    }
}

// get event guest
export const Get_Edit_Event_Guest = async (id) => {
    try {
        return await GetEditEventGuest(id).then((data) => data)
    }
    catch (error) {
        console.log(error)
    }
}

// get event pitch
export const Get_Edit_Event_Pitch = async (id) => {
    try {
        return await GetEditEventPitch(id).then((data) => data)
    }
    catch (error) {
        console.log(error)
    }
}


// get event Host
export const Get_Edit_Event_Host = async (id) => {
    try {
        return await GetEditEventHost(id).then((data) => data)
    }
    catch (error) {
        console.log(error)
    }
}

// get event partership
export const Get_Edit_Event_PartnerShip = async (id) => {
    try {
        return await GetEditEventPartnerShip(id).then((data) => data)
    }
    catch (error) {
        console.log(error)
    }
}

//get event sponsorPackage
export const Get_Edit_Event_SponsorPackage = async (id) => {
    try {
        return await GetEditEventSponsorPackage(id).then((data) => data)
    }
    catch (error) {
        console.log(error)
    }
}

//get event media hub
export const Get_Edit_Event_Social_Hub = async (id) => {
    try {
        return await GetEditEventSocialHub(id).then((data) => data)
    }
    catch (error) {
        console.log(error)
    }
}

// get event social hub
export const Get_Edit_Event_Media_Hub = async (id) => {
    try {
        return await GetEditEventMediaHub(id).then((data) => data)
    }
    catch (error) {
        console.log(error)
    }
}

//get event cancle refund 
export const Get_Edit_Event_Cancel_Refund = async (id) => {
    try {
        return await GetEditEventCancelRefund(id).then((data) => data)
    }
    catch (error) {
        console.log(error)
    }
}
// get event reference

export const Get_Edit_Event_Reference = async (id) => {
    try {
        return await GetEditEventReference(id).then((data) => data)
    }
    catch (error) {
        console.log(error)
    }
}

export const EventEssentialsEdit = async (id, item) => {
    try {
        return await EventessentialsEdit(id, item).then((data) => data)
    } catch (err) {
        throw err
    }
}

// get single particular partnership partner
export const GetPartnerShipPartner = async (id) => {
    try {
        return await getPartnershipPartner(id).then((data) => data)
    }
    catch (error) {
        console.log(error)
    }
}

// particular partnership partner Update_PartnerData
export const Update_PartnerData = async (id, item) => {
    try {
        return await UpdatePartnerData(id, item).then((data) => data)
    } catch (err) {
        throw err
    }
}

// delete partner 
export const DeletePartners = async (id) => {
    try {
        return await DeletePartnershipPartner(id).then((data) => data)
    }
    catch (error) {
        console.log(error)
    }
}


export const DeleteBanner = async (id) => {
    try {
        return await Delete_Banner(id).then((data) => data)
    }
    catch (error) {
        console.log(error)
    }
}