// import eventimg from "../../assets/images/event2.jpg"
import React, { useState, useEffect } from "react";
import { FaLocationDot } from "react-icons/fa6";
import { FaStar } from "react-icons/fa";
import { FaRegCalendarAlt } from "react-icons/fa";
import { HiCurrencyDollar } from "react-icons/hi";
import { Link } from "react-router-dom";
import moment from "moment";
import { RiLiveFill } from "react-icons/ri";
import dImage from "../../assets/images/default-image.jpg";
import { BsFillTicketDetailedFill } from "react-icons/bs";

const RecommendedEventCard = ({ baseUrls, val }) => {
  const [currencyIcn, setCurrencyIcn] = useState('');

  const cardImageError = (event) => {
    event.target.src = dImage;
    event.target.onError = null;
  };

  const event_mode = val?.event_mode?.replace("_", " ");

  const minAmount = val?.min_amount ? parseFloat(val?.min_amount).toFixed(0) : null;
  const maxAmount = val?.max_amount ? parseFloat(val?.max_amount).toFixed(0) : null;

  useEffect(() => {
    if (val?.currency_code == "INR") {
      setCurrencyIcn("₹");
    } else if (val?.currency_code == "USD") {
      setCurrencyIcn("$");
    } else if (val?.currency_code == "EUR") {
      setCurrencyIcn("€");
    } else if (val?.currency_code == "GBP") {
      setCurrencyIcn("€");
    }
  }, [val?.currency_code]);

  return (
    <>
      <div className="eventcard">
        <Link className="navigate-detail-btn"
          to={`/eventdetail/${val?.id}`}
          state={{ type: "Home" }}>
          <div className="card-image-div">
            {val?.event_mode && (
              <div className="event-online-div ">{event_mode}</div>
            )}

            {val?.status ? (
              val?.status == "Live" ? (
                <div className="event-live-status-div d-flex align-items-center gap-1">
                  <RiLiveFill />
                  {val?.status}
                </div>
              ) : (
                <div className="home-event-status-div">{val?.status}</div>
              )
            ) : (
              ""
            )}

            {val?.event_type?.name && (
              <div className="event-type-div">{val?.event_type?.name}</div>
            )}

            <img
              className="card-img-top"
              loading="lazy"
              src={`${baseUrls?.logo_image_url}/${val?.logo}`}
              onError={cardImageError}
              alt="..."
            />
            <div className="featured-rectangle d-flex align-items-center">
              <FaRegCalendarAlt size={18} className="d-none d-md-flex" />
              <p className="date-text">
                {moment(val?.start_date).format("ddd, DD MMM")}
              </p>
              <p className="date-text">to</p>
              <p className="date-text">
                {moment(val?.end_date).format("ddd, DD MMM")}
              </p>
            </div>
          </div>
          <div className="mt-2">
            <h5
              className="card-heading text-capitalize mb-1"
              title={val?.event_title}>
              {val?.event_title}
            </h5>

            {/* {
            val?.address && 
            <div className="d-flex gap-1">
            <div>
              <FaLocationDot className="mt-1" color="black" size={15} />
            </div>
            <p className="feature-card-address text-capitalize" title={val?.address}>
              {val?.address}
            </p>
           </div>
          } */}

            {val?.address ? (
              <div className="d-flex gap-1 mt-0">
                <div className="">
                  <FaLocationDot className="mt-1" color="black" size={15} />
                </div>
                <p className="feature-card-address" title={`${val?.city?.city_name}, ${val?.state?.short_name}, ${val?.country?.short_name}`}>
                  {/* {val?.address}, {val?.city?.city_name}, {val?.state?.short_name}, {val?.country?.short_name} */}
                  {val?.city?.city_name}, {val?.state?.short_name}, {val?.country?.short_name}
                </p>
              </div>
            ) : (
              <div className="d-flex gap-1 mt-0">
                <div className="">
                  <FaLocationDot className="mt-1" color="black" size={15} />
                </div>
                <p className="feature-card-address" title={`${val?.city?.city_name}, ${val?.state?.short_name}, ${val?.country?.short_name}`}>
                  {val?.city?.city_name}, {val?.state?.short_name}, {val?.country?.short_name}
                </p>
              </div>
            )}

            <div className="d-flex align-items-center gap-1">
              <BsFillTicketDetailedFill color="black" size={15} />
              <p className="feature-card-cutoff-p">
                {/* { minAmount == maxAmount ? `${currencyIcn}${maxAmount}` : `${currencyIcn}${minAmount} - ${currencyIcn}${maxAmount}`} */}
                {`From ${currencyIcn}${minAmount}`}
              </p>
            </div>

            {val?.published_date == null ||
              val?.published_date == "0000-00-00" ? (
              ""
            ) : (
              <div className="d-flex align-items-center gap-1 ">
                <FaRegCalendarAlt size={15} color="black" className="d-none d-md-flex" />
                <p className="feature-card-cutoff-p " title={moment(val?.published_date).format("ddd, DD MMM YYYY")}>
                  Publish Date : {moment(val?.published_date).format("ddd, DD MMM YYYY")}
                </p>
              </div>
            )}

            {val?.published_end_date == null ||
              val?.published_end_date == "0000-00-00" ? (
              ""
            ) : (
              <div className="d-flex align-items-center gap-1 ">
                <FaRegCalendarAlt size={15} color="black" className="d-none d-md-flex" />
                <p className="feature-card-cutoff-p " title={moment(val?.published_end_date).format("ddd, DD MMM YYYY")}>
                  CutOff Date :{" "}
                  {moment(val?.published_end_date).format("ddd, DD MMM YYYY")}
                </p>
              </div>
            )}

            {val?.tags?.length > 0 && (
              <div className="Card-IntrestD ">
                {val?.tags?.length > 0 &&
                  val?.tags?.map((item) => {
                    return (
                      <Link to={`/events/${item?.interest_id}`}
                        state={{ type: "Interest" }}
                        key={item?.id}>
                        <p className="Card-IntrestP d-flex">
                          #{item?.user_interests?.name}
                        </p>
                      </Link>
                    );
                  })}
              </div>
            )}

          </div>
        </Link>
      </div>
    </>
  );
};

export default RecommendedEventCard;
